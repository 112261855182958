/* eslint-disable sonarjs/no-duplicate-string */
import {
  AccountTaskStatus,
  CreatePersonMutationVariables,
  PersonType,
  UpdatePersonData,
} from 'generated/graphql';
import logger from 'js-logger';

import backendClient from '../backend';

export const PERSON_TYPE_DISPLAY_NAME_ORDER: {
  [key in PersonType]?: number;
} = {
  APPLICANT: 1,
  PARTNER: 2,
  PARTNER_WORKING: 3,
  PARTNER_STUDYING: 4,
  PARTNER_LAW: 5,
  PARTNER_LAW_WORKING: 6,
  PARTNER_LAW_STUDYING: 7,
  CHILD: 8,
  CHILD_TEEN: 9,
  CHILD_ADULT: 10,
};
export const PERSON_TYPE_DISPLAY_NAME: {
  [key in PersonType]?: string;
} = {
  APPLICANT: 'Applicant',
  PARTNER: 'Spouse',
  PARTNER_WORKING: 'Spouse',
  PARTNER_STUDYING: 'Spouse',
  PARTNER_LAW: 'Common-law',
  PARTNER_LAW_WORKING: 'Common-law',
  PARTNER_LAW_STUDYING: 'Common-law',
  CHILD: 'Child',
  CHILD_TEEN: 'Child',
  CHILD_ADULT: 'Child',
};

export class Person {
  public static async get(
    id: number,
    taskLabels: string[] | null = null,
    taskStatus: AccountTaskStatus | null = null,
    type: PersonType | null = null
  ) {
    try {
      return (
        await backendClient.getPerson({ id, taskLabels, taskStatus, type })
      ).data?.Person;
    } catch (err) {
      logger.error(err);
    }
  }

  public static async getIncludes({
    id,
    type,
    accountId,
  }: {
    id?: number;
    type?: PersonType;
    accountId?: number;
  }) {
    try {
      return (await backendClient.getPersonIncludes({ id, type, accountId }))
        .data?.Person;
    } catch (err) {
      logger.error(err);
    }
  }

  public static async getByType(type: PersonType, accountId?: number) {
    try {
      return (await backendClient.getPerson({ type, accountId })).data?.Person;
    } catch (err) {
      logger.error(err);
    }
  }

  public static async getAll(accountId?: number, applicationId?: number) {
    try {
      return (await backendClient.Persons({ accountId, applicationId })).data
        ?.Persons;
    } catch (err) {
      logger.error(err);
    }
  }

  public static async getAllIncludes({
    accountId,
    applicationId,
    includes,
  }: {
    accountId?: number;
    applicationId?: number;
    includes?: {
      applications?: boolean;
    };
  }) {
    try {
      return (
        await backendClient.PersonsIncludes({
          accountId,
          applicationId,
          includeApplications: includes?.applications ?? false,
        })
      ).data?.Persons;
    } catch (err) {
      logger.error(err);
    }
  }

  public static async update(id: number, data: UpdatePersonData) {
    try {
      return (await backendClient.updatePerson({ id, data })).data
        ?.updatePerson;
    } catch (err) {
      logger.error(err);
    }
  }

  public static async create(args: CreatePersonMutationVariables) {
    try {
      return (await backendClient.createPerson(args)).data?.createPerson;
    } catch (err) {
      logger.error(err);
    }
  }
}
