import {
  Application as RawApplication,
  ApplicationsFilters,
  ApplicationsGetData,
  ApplicationTemplateType,
  ApplicationType,
  ApplicationUpdateData,
  CreateApplicationMeta,
  HandleApplicationAction,
  HandleApplicationData,
  NewAccountData,
} from 'generated/graphql';
import logger from 'js-logger';

import backendClient from '../backend';

export class Application {
  public static async get({
    key,
    id,
    token,
  }: {
    key?: string;
    id?: number | null;
    token?: string;
  }) {
    let requestHeaders = undefined;

    if (token) {
      requestHeaders = {
        Authorization: `Bearer ${token}`,
      };
    }

    try {
      return (await backendClient.getApplication({ key, id }, requestHeaders))
        .data?.Application as RawApplication;
    } catch (err) {
      logger.error(err);
    }
  }

  public static async getIncludes({
    key,
    id,
    includes,
    token,
  }: {
    key?: string;
    id?: number | null;
    token?: string;
    includes?: {
      representative?: boolean;
      ownerAccount?: boolean;
      lawyerAccount?: boolean;
      assignedUsers?: boolean;
      status?: boolean;
      client?: boolean;
      review?: boolean;
    };
  }) {
    let requestHeaders = undefined;

    if (token) {
      requestHeaders = {
        Authorization: `Bearer ${token}`,
      };
    }

    try {
      return (
        await backendClient.getApplicationIncludes(
          {
            key,
            id,
            includesRepresentative: includes?.representative ?? false,
            includesOwnerAccount: includes?.ownerAccount ?? false,
            includesLawyerAccount: includes?.lawyerAccount ?? false,
            includesAssignedUsers: includes?.assignedUsers ?? false,
            includesStatus: includes?.status ?? false,
            includesClient: includes?.client ?? false,
            includesReview: includes?.review ?? false,
          },
          requestHeaders
        )
      ).data?.Application as RawApplication;
    } catch (err) {
      logger.error(err);
    }
  }

  public static async getWithTemplate(id: number) {
    try {
      return (await backendClient.getApplicationWithTemplate({ id })).data
        ?.Application;
    } catch (err) {
      logger.error(err);
    }
  }

  // TODO: we no longer allow accountId and dont feel like refactoring the code that uses this right now lol
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public static async getByAccountId(accountId = 0) {
    try {
      return (await backendClient.getApplication()).data
        ?.Application as RawApplication;
    } catch (err) {
      logger.error(err);
    }
  }

  public static async getBasic({
    key,
    id,
  }: {
    key?: string;
    id?: number | null;
  }) {
    try {
      return (await backendClient.getApplicationBasic({ key, id })).data
        ?.Application as RawApplication;
    } catch (err) {
      logger.error(err);
    }
  }

  public static async getAll() {
    try {
      return (await backendClient.getApplications()).data
        ?.Applications as RawApplication[];
    } catch (err) {
      logger.error(err);
    }
  }

  public static async getAllIncludes({
    data,
    accountId,
    ownerAccountId,
    cursor,
    searchTerm,
    filters,
    include,
  }: Partial<{
    data?: ApplicationsGetData;
    accountId?: number;
    ownerAccountId?: number;
    cursor?: number;
    searchTerm?: string;
    filters?: ApplicationsFilters;
    include?: {
      ownerAccount?: boolean;
    };
  }> = {}) {
    try {
      return (
        await backendClient.getApplicationsIncludes({
          data,
          accountId,
          ownerAccountId,
          cursor,
          searchTerm,
          filters,
          includeOwnerAccount: include?.ownerAccount ?? false,
        })
      ).data?.Applications;
    } catch (err) {
      logger.error(err);
    }
  }

  public static async getApplicationForExport(id: number) {
    try {
      return (await backendClient.getApplicationForExport({ id })).data
        ?.Application;
    } catch (err) {
      logger.error(err);
    }
  }

  public static async delete(id: number) {
    try {
      return (await backendClient.deleteApplication({ id })).data
        ?.deleteApplication;
    } catch (err) {
      logger.error(err);
    }
  }

  public static async getAllOverview({
    data,
    accountId,
    ownerAccountId,
    cursor,
    searchTerm,
    filters,
  }: {
    data?: ApplicationsGetData;
    accountId?: number;
    ownerAccountId?: number;
    cursor?: number;
    searchTerm?: string;
    filters?: ApplicationsFilters;
  }) {
    try {
      return (
        await backendClient.getApplicationsOverview({
          data,
          accountId,
          ownerAccountId,
          cursor,
          searchTerm,
          filters,
        })
      ).data?.Applications;
    } catch (err) {
      logger.error(err);
    }
  }

  public static async create({
    ownerAccountId,
    lawyerAccountId,
    assignedLawyerUserIds,
    companyTalentIds,
    type,
    templateType,
    roleId,
    meta,
    applicationTemplateId,
    representativeId,
    newAccountData,
  }: {
    ownerAccountId?: number | null;
    lawyerAccountId?: number;
    type: ApplicationType;
    templateType?: ApplicationTemplateType;
    assignedLawyerUserIds?: number[];
    companyTalentIds?: number[];
    roleId?: number;
    meta?: CreateApplicationMeta;
    applicationTemplateId?: number;
    representativeId?: number;
    newAccountData?: NewAccountData;
  }) {
    try {
      return (
        await backendClient.createApplication({
          ownerAccountId,
          lawyerAccountId,
          companyTalentIds,
          assignedLawyerUserIds,
          type,
          templateType,
          roleId,
          meta,
          applicationTemplateId,
          representativeId,
          newAccountData,
        })
      ).data?.createApplication;
    } catch (err) {
      logger.error(err);
    }
  }

  public static async update({
    applicationId,
    targetAccountId,
    data,
  }: {
    applicationId: number;
    targetAccountId?: number;
    data: ApplicationUpdateData;
  }) {
    if (data.companyTalentIds) {
      data.companyTalentIds = JSON.stringify(data.companyTalentIds);
    }

    try {
      return (
        await backendClient.updateApplication({
          applicationId,
          targetAccountId,
          data,
        })
      ).data?.updateApplication;
    } catch (err) {
      logger.error(err);
    }
  }

  public static async handleApplication(
    action: HandleApplicationAction,
    data?: HandleApplicationData,
    targetAccountId?: number
  ) {
    try {
      return (
        await backendClient.handleApplication({ action, data, targetAccountId })
      ).data?.handleApplication;
    } catch (err) {
      logger.error(err);
    }
  }
}
