import { observer } from 'mobx-react-lite';
import { FC } from 'react';

export const Checkbox: FC<JSX.IntrinsicElements['input']> = observer(
  ({ children, className, ...props }) => {
    return (
      <div className="flex items-center">
        <input
          type="checkbox"
          {...props}
          className={`rounded-sm border border-black mr-3 cursor-pointer ${className}`}
        />
        <label className="fs14 cursor-pointer" htmlFor={props.id}>
          {children}
        </label>
      </div>
    );
  }
);
