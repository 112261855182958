import { FormManager } from '@visto-tech/forms';
import { FormSubmitReview } from 'components/DynamicApiForm/FormSubmitReview';
import { GettingStartedQuestionGroupContext } from 'components/DynamicApiForm/variation-components/GettingStartedQuestionGroupContext';
import { useMultiStageFormController } from 'components/MultiStageForm/MultiStageFormController';
import Form from 'dataLayer/Form';
import { QUESTION_INPUT_VARIATIONS } from 'dataLayer/Question';
import ProgressBar from 'elements/ProgressBar/ProgressBar';
import React, { FC, useState } from 'react';

import { GettingStartedQuestionGroupContainer } from '../DynamicApiForm/variation-components/GettingStartedQuestionGroupContainer';
import { MultiPartFormNavigation } from '../MultiStageForm/MultiPartFormNavigation';

export const QuestionGroups: FC<{
  variation: QUESTION_INPUT_VARIATIONS;
  formManager: FormManager<any>;
  formName: string;
  afterOnClick?: () => any;
  form?: Form | null;
}> = ({ children, variation, formManager, formName, afterOnClick, form }) => {
  const [isReview, setIsReview] = useState<boolean>(false);

  const multiStageFormController = useMultiStageFormController(
    React.Children.toArray(children).length
  );

  // We want to add an additional step for the review screen
  const progressBarValue = Math.floor(
    ((multiStageFormController.currentPage - 1 + (isReview ? 1 : 0)) /
      (multiStageFormController.maxPage + 1)) *
      100
  );

  if (variation === QUESTION_INPUT_VARIATIONS.GETTING_STARTED)
    return (
      <GettingStartedQuestionGroupContext.Provider
        value={multiStageFormController}
      >
        <ProgressBar className="mb-3" value={progressBarValue} />
        <GettingStartedQuestionGroupContainer>
          {!isReview ? (
            children
          ) : (
            <FormSubmitReview
              formManager={formManager}
              setIsReview={setIsReview}
              formName={formName}
              form={form}
            />
          )}
        </GettingStartedQuestionGroupContainer>
        {!isReview && (
          <MultiPartFormNavigation
            formManager={formManager}
            formName={formName}
            afterOnClick={afterOnClick}
            setIsReview={setIsReview}
          />
        )}
      </GettingStartedQuestionGroupContext.Provider>
    );

  return <>{children}</>;
};
