/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-identical-functions */
import {
  DropdownWithLabel,
  FormManager,
  InputWithLabel,
  SubmitHandler,
} from '@visto-tech/forms';
import {
  childRelationshipList,
  countryCitizenshipList,
  countryResidenceList,
} from '@visto-tech/seed';
import { Application } from 'dataLayer/Application';
import { Person, PERSON_TYPE_DISPLAY_NAME } from 'dataLayer/Person';
import Button from 'elements/Button';
import ErrorDisplay from 'elements/ErrorDisplay/ErrorDisplay';
import { DatePickerInputSecondary } from 'elements/Forms/DatePickerInput/DatePickerInputSecondary';
import Loading from 'elements/Loading';
import LoadingSpinner from 'elements/Loading/LoadingSpinner';
import { Modal } from 'elements/Modal/Modal';
import Text from 'elements/Text';
import {
  Application as ApplicationGQL,
  Person as PersonGQL,
  PersonType,
  SubmitFormMutationArgs,
} from 'generated/graphql';
import { useAsyncEffect } from 'hooks/useAsyncEffect';
import { useFormManager } from 'hooks/useFormManager';
import { get, isBoolean, isEmpty } from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { FC, useEffect, useState } from 'react';
import { AlertTriangle, MinusCircle, PlusCircle } from 'react-feather';
import toast from 'react-hot-toast';
import * as yup from 'yup';

export interface FamilyMembersRepeaterV2Props {
  targetAccountId?: number;
  submitFormArgs?: SubmitFormMutationArgs;
  label: string;
  formManager: FormManager<any>;
}

export const PERSON_TYPES: { type: PersonType; label: string }[] = [
  { type: PersonType.Partner, label: 'Spouse (legally married)' },
  { type: PersonType.PartnerLaw, label: 'Common-law Partner (not married)' },
  { type: PersonType.Child, label: 'Child' },
];

export const FamilyMembersRepeaterV2: FC<FamilyMembersRepeaterV2Props> =
  observer(({ targetAccountId, submitFormArgs, label, formManager }) => {
    const is = {
      accompanying: ['CUS_1'].includes(label),
      accompanyingChildrenOnly: ['CUS_13'].includes(label),
      notAccompanyingChildrenOnly: ['CUS_11'].includes(label),
      notAccompanyingAll: ['CUS_14'].includes(label),
      spouseOnlyRequired: ['CUS_12'].includes(label),
    };

    const applicationId = submitFormArgs?.applicationId;

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [existingPersons, setExistingPersons] = useState<PersonGQL[]>([]);
    const [application, setApplication] = useState<ApplicationGQL | null>(null);
    const [isLoadingPersonId, setIsLoadingPersonId] = useState<number | null>(
      null
    );

    const refreshData = async (status: { isMounted: boolean }) => {
      if (!targetAccountId) {
        setIsLoading(false);
        return null;
      }

      const application = await Application.get({ id: applicationId });

      let persons = (await Person.getAll(targetAccountId))?.filter(
        (person) => person?.type !== PersonType.Applicant
      );

      // We want to filter out all of the children who were already added to the Application
      // to be included in it
      if (is.notAccompanyingChildrenOnly) {
        persons = persons?.filter(
          (person) =>
            person?.type === PersonType.Child &&
            (!person.applications?.some(
              (application) => application?.id === applicationId
            ) ||
              application?.nonAccompanyingPersonIds?.includes(person.id))
        );
      } else if (is.notAccompanyingAll) {
        persons = persons?.filter(
          (person) =>
            !person?.applications?.some(
              (application) => application?.id === applicationId
            ) || application?.nonAccompanyingPersonIds?.includes(person.id)
        );
      } else if (is.spouseOnlyRequired) {
        // Show only the partners
        persons = persons?.filter(
          (person) =>
            person?.type === PersonType.Partner ||
            person?.type === PersonType.PartnerLaw
        );
      } else if (is.accompanyingChildrenOnly) {
        // Show only the children
        persons = persons?.filter(
          (person) =>
            person?.type === PersonType.Child &&
            !application?.nonAccompanyingPersonIds?.includes(
              person?.id as number
            )
        );
      } else {
        // We want to filter out all the persons who are NOT accompanying them to Canada
        // and who were already added to the application
        persons = persons?.filter(
          (person) =>
            person?.id &&
            !application?.nonAccompanyingPersonIds?.includes(person.id)
        );
      }

      if (status.isMounted) {
        setExistingPersons(persons as PersonGQL[]);
        setApplication(application as ApplicationGQL);
      } else {
        setExistingPersons([]);
        setApplication(null);
      }

      setIsLoading(false);
    };

    useEffect(() => {
      // If we have a partner already assigned to this application, add a value so it passes validation
      if (is.spouseOnlyRequired) {
        const applicationHasSpouse = application?.persons?.some(
          (person) =>
            person?.type === PersonType.Partner ||
            person?.type === PersonType.PartnerLaw
        );

        formManager.formData[label] = applicationHasSpouse ? 'true' : '';
      }
    }, [application]);

    const handleSelectedPersons = async (personId: number) => {
      let text =
        'Please confirm that this family member will be coming to Canada with you and you want them included in this application';

      const isIncluded = application?.persons?.some(
        (person) => person?.id === personId
      );

      if (is.notAccompanyingChildrenOnly) {
        text =
          'Please confirm that this child will NOT be coming to Canada with you';
      }

      if (is.notAccompanyingAll) {
        text =
          'Please confirm that this family member will NOT be coming to Canada with you';
      }

      if (isIncluded) {
        text = 'Are you sure you want to remove them from the application?';
      }

      if (confirm(text)) {
        setIsLoadingPersonId(personId);

        const personUpdated = await Person.update(personId, {
          applicationId,
          isANotAccompanyingPerson:
            is.notAccompanyingChildrenOnly || is.notAccompanyingAll,
        });

        if (!personUpdated) {
          toast.error('Failed to update Person');
          setIsLoadingPersonId(null);

          return null;
        }

        toast.success('Family members updated!');

        await refreshData({ isMounted: true });

        formManager.formData[label] = !isIncluded ? 'true' : '';

        formManager.clearErrors(label);

        setIsLoadingPersonId(null);
      }
    };

    useAsyncEffect(refreshData, [targetAccountId]);

    if (isLoading) {
      return <Loading.Default size={3} />;
    }

    let ALLOWED_PERSON_TYPES = undefined;
    const COPY = {
      heading: (
        <>
          Select all family members that you would like to{' '}
          <span className="font-bold underline">
            include in this application
          </span>
          , that{' '}
          <span className="font-bold underline">will be coming to Canada</span>{' '}
          with you:
        </>
      ),
    };

    if (is.notAccompanyingChildrenOnly) {
      ALLOWED_PERSON_TYPES = [PersonType.Child];
      COPY.heading = (
        <>
          Select all your children that{' '}
          <span className="font-bold underline whitespace-nowrap">
            will NOT be coming to Canada
          </span>{' '}
          with you:
        </>
      );
    } else if (is.notAccompanyingAll) {
      ALLOWED_PERSON_TYPES = [
        PersonType.Child,
        PersonType.PartnerLaw,
        PersonType.Partner,
      ];
      COPY.heading = (
        <>
          Select all your family members that{' '}
          <span className="font-bold underline whitespace-nowrap">
            will NOT be coming to Canada
          </span>{' '}
          with you:
        </>
      );
    } else if (is.accompanyingChildrenOnly) {
      ALLOWED_PERSON_TYPES = [PersonType.Child];
      COPY.heading = (
        <>
          Select all of your children that{' '}
          <span className="font-bold underline"> will be coming to Canada</span>{' '}
          with you:
        </>
      );
    } else if (is.spouseOnlyRequired) {
      ALLOWED_PERSON_TYPES = [PersonType.PartnerLaw, PersonType.Partner];
      COPY.heading = (
        <>
          Select the person who will be{' '}
          <span className="font-bold underline">
            {' '}
            sponsoring your application:
          </span>
        </>
      );
    }

    return (
      <>
        <ErrorDisplay formManager={formManager} />
        <div className="visto-component">
          <Text.Paragraph className="text-center">
            {COPY.heading}
          </Text.Paragraph>
          {isEmpty(existingPersons) ? (
            <div className="bg-gray-100 border border-gray-200 p-4 rounded-md mt-8">
              <AlertTriangle className="text-gray-600 mx-auto mb-2" />
              <Text.Paragraph className="text-center fs14 text-gray-600">
                Looks like you do not have any family members assigned to your
                profile. Add a new family member below!
              </Text.Paragraph>
            </div>
          ) : (
            <div className="mt-4 space-y-2">
              {existingPersons?.map((person) => {
                const assignedApplicationIds = person.applications?.map(
                  (application) => application?.id
                );

                const isSelected = applicationId
                  ? assignedApplicationIds?.includes(applicationId)
                  : false;

                const isLoading = isLoadingPersonId === person.id;

                return (
                  <SelectFamilyMemberItem
                    key={`person-${person.id}`}
                    person={person}
                    onClick={async () => handleSelectedPersons(person.id)}
                    isSelected={isSelected ?? false}
                    isLoading={isLoading}
                  />
                );
              })}
            </div>
          )}
          <Button
            type="button"
            className="mx-auto mt-8"
            onClick={() => setIsModalOpen(true)}
            format="outline"
            isPinging
          >
            Add New Family Member
          </Button>
        </div>
        <AddFamilyMember
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          targetAccountId={targetAccountId}
          existingPersons={existingPersons}
          refreshPersons={refreshData}
          applicationId={applicationId}
          isANotAccompanyingPerson={
            is.notAccompanyingChildrenOnly || is.notAccompanyingAll
          }
          allowedPersonTypes={ALLOWED_PERSON_TYPES}
          onFinish={() => {
            formManager.formData[label] = 'true';
          }}
        />
      </>
    );
  });

const SelectFamilyMemberItem: FC<{
  person: PersonGQL;
  isSelected: boolean;
  className?: string;
  onClick: JSX.IntrinsicElements['button']['onClick'];
  isLoading: boolean;
}> = ({ person, isSelected, className, onClick, isLoading }) => {
  return (
    <div
      className={`bg-gray-200 rounded-md w-full text-left border-gray-300 border relative ${
        isSelected
          ? 'border border-green-300 bg-green-100'
          : 'hover:bg-gray-300 hover:border-gray-400'
      } ${className ?? ''}`}
    >
      <div className="flex items-center">
        <button
          className="flex justify-between items-center fs14 font-semibold w-full p-3"
          onClick={onClick}
          type="button"
        >
          <span className="flex justify-start items-center">
            {!isSelected && !isLoading && <PlusCircle className="mr-3" />}
            {isSelected && !isLoading && <MinusCircle className="mr-3" />}
            {isLoading ? (
              <LoadingSpinner style={{ marginLeft: '3px', height: '24px' }} />
            ) : (
              ''
            )}
            <div>
              <Text.Paragraph className="font-semibold text-left">
                {person.firstName} {person.lastName}
              </Text.Paragraph>
              <Text.Paragraph className="fs12 text-left">
                {get(PERSON_TYPE_DISPLAY_NAME, `${person.type}`)}
              </Text.Paragraph>
            </div>
          </span>
          <span className="fs12">
            {isSelected
              ? 'Included in application'
              : 'Not included in application'}
          </span>
        </button>
      </div>
    </div>
  );
};

export const AddFamilyMember: FC<{
  isModalOpen?: boolean;
  setIsModalOpen?: (args: boolean) => void;
  existingPersons: PersonGQL[];
  targetAccountId?: number;
  refreshPersons: (status: {
    isMounted: boolean;
  }) => Promise<null | undefined | void>;
  applicationId?: number | null;
  onFinish?: (person: PersonGQL) => void;
  isANotAccompanyingPerson?: boolean;
  allowedPersonTypes?: PersonType[];
}> = observer(
  ({
    targetAccountId,
    isModalOpen,
    setIsModalOpen,
    existingPersons,
    refreshPersons,
    applicationId,
    onFinish,
    isANotAccompanyingPerson,
    allowedPersonTypes,
  }) => {
    const formData = {
      type: '',
      childRelationship: '',
      firstName: '',
      lastName: '',
      email: '',
      countryCitizenship: '',
      countryResidence: '',
      countryResidenceLast6Months: '',
      dob: '',
    };

    const formManager = useFormManager({
      formData,
    });

    const handleSubmit: SubmitHandler<typeof formData> = async (
      _,
      {
        type,
        firstName,
        lastName,
        email,
        childRelationship,
        countryCitizenship,
        countryResidence,
        countryResidenceLast6Months,
        dob,
      }
    ) => {
      if (!targetAccountId) {
        toast.error('Missing required options');
        return null;
      }

      const personCreated = await Person.create({
        accountId: targetAccountId,
        type: type as PersonType,
        firstName,
        lastName,
        email,
        meta: {
          childRelationship,
          countryCitizenship,
          countryResidence,
          countryResidenceLast6Months,
          email,
          dob,
        },
      });

      if (!personCreated) {
        toast.error('Failed to create Person');
        return null;
      }

      toast.success('Family member created!');

      await Person.update(personCreated.id, {
        applicationId,
        isANotAccompanyingPerson: isBoolean(isANotAccompanyingPerson)
          ? isANotAccompanyingPerson
          : undefined,
      });

      await refreshPersons({ isMounted: true });

      formManager.reset();

      setIsModalOpen && setIsModalOpen(false);

      onFinish && onFinish(personCreated);

      return null;
    };

    const selectedPersonType = formManager.formData.type as PersonType;

    const isPartnerType = (type: PersonType) => {
      return [PersonType.Partner, PersonType.PartnerLaw].includes(type);
    };

    const isChild = () => {
      return [PersonType.Child].includes(selectedPersonType);
    };

    const partnerExists = existingPersons?.some((person) =>
      isPartnerType(person.type as PersonType)
    );

    formManager.setValidationSchema(
      yup.object().shape(
        {
          type: yup.string().required('Please select a family member type'),
          childRelationship: isChild()
            ? yup.string().required('Please select a relationship type')
            : yup.string().optional(),
          firstName: yup.string().when('lastName', {
            is: (lastName: any) => !lastName || lastName.length === 0,
            then: yup.string().required('A first or last name is required.'),
            otherwise: yup.string(),
          }),
          lastName: yup.string().when('firstName', {
            is: (firstName: any) => !firstName || firstName.length === 0,
            then: yup.string().required('A first or last name is required.'),
            otherwise: yup.string(),
          }),
          dob: yup.string().required('Please select an date of birth'),
          email: yup
            .string()
            .email('You must enter a single (one) valid email.')
            .required('You must enter a single (one) valid email.'),
          countryCitizenship: yup
            .string()
            .required('Please select a country of citizenship'),
          countryResidence: yup
            .string()
            .required('Please select a country of residence'),
          countryResidenceLast6Months: yup
            .string()
            .required(
              'Please select a country of residence for the last 6 months'
            ),
        },
        [['firstName', 'lastName']]
      )
    );

    useEffect(() => {
      formManager.formData.email = '';
      formManager.formData.childRelationship = '';
    }, [formManager.formData.type]);

    let filteredPersonTypes = PERSON_TYPES;

    if (allowedPersonTypes) {
      filteredPersonTypes = PERSON_TYPES.filter((personType) =>
        allowedPersonTypes.includes(personType.type)
      );
    }

    const Content = (
      <>
        <Text.Paragraph className="text-center fs20 font-bold mb-1">
          Add new Family Member
        </Text.Paragraph>
        <Text.Paragraph className="text-center mb-4">
          Please fill in all the information below to add a new family member
        </Text.Paragraph>
        <div>
          <div className="mb-3">
            <DropdownWithLabel.WithFormManager
              formManager={formManager}
              id="type"
              name="type"
              dropdownProps={
                {
                  children: [
                    <option key={`initial-empty-value`} value="" disabled>
                      Select one
                    </option>,
                    ...filteredPersonTypes.map((personType: any, i: number) => {
                      if (isPartnerType(personType.type) && partnerExists) {
                        return null;
                      }

                      return (
                        <option key={i} value={personType.type}>
                          {personType.label}
                        </option>
                      );
                    }),
                  ],
                } as any
              }
            >
              Relationship
            </DropdownWithLabel.WithFormManager>
          </div>

          {selectedPersonType && (
            <div>
              {isChild() && (
                <div className="mb-3">
                  <DropdownWithLabel.WithFormManager
                    id="childRelationship"
                    name="childRelationship"
                    value=""
                    formManager={formManager}
                    dropdownProps={
                      {
                        children: [
                          <option key={`initial-empty-value`} value="" disabled>
                            Select one
                          </option>,
                          ...childRelationshipList.map(
                            (relationship: any, i: number) => (
                              <option key={i} value={relationship.label}>
                                {relationship.label}
                              </option>
                            )
                          ),
                        ],
                      } as any
                    }
                  >
                    Child relationship
                  </DropdownWithLabel.WithFormManager>
                </div>
              )}

              <div className="mb-3">
                <InputWithLabel.WithFormManager
                  id="firstName"
                  name="firstName"
                  formManager={formManager}
                  tooltip="As shown on passport"
                >
                  First name (given name)
                </InputWithLabel.WithFormManager>
              </div>

              <div className="mb-3">
                <InputWithLabel.WithFormManager
                  id="lastName"
                  name="lastName"
                  formManager={formManager}
                  tooltip="As shown on passport"
                >
                  Last name (family name)
                </InputWithLabel.WithFormManager>
              </div>

              <div className="mb-3">
                <DatePickerInputSecondary label="dob" formManager={formManager}>
                  Date of birth
                </DatePickerInputSecondary>
              </div>

              <div className="mb-3">
                <InputWithLabel.WithFormManager
                  id="email"
                  name="email"
                  formManager={formManager}
                  tooltip={
                    selectedPersonType === PersonType.Child
                      ? `If your child is not of age to have an email, please use your email.`
                      : ''
                  }
                >
                  Email
                </InputWithLabel.WithFormManager>
              </div>

              <div className="mb-3">
                <DropdownWithLabel.WithFormManager
                  id="countryCitizenship"
                  name="countryCitizenship"
                  formManager={formManager}
                  dropdownProps={
                    {
                      children: [
                        <option key={`initial-empty-value`} value="" disabled>
                          Select one
                        </option>,
                        ...countryCitizenshipList
                          ?.filter((country) => country.value !== '204')
                          .map((country: any, i: number) => (
                            <option key={i} value={country.label}>
                              {country.label}
                            </option>
                          )),
                      ],
                    } as any
                  }
                >
                  Country of citizenship
                </DropdownWithLabel.WithFormManager>
              </div>

              <div className="mb-3">
                <DropdownWithLabel.WithFormManager
                  id="countryResidence"
                  name="countryResidence"
                  formManager={formManager}
                  dropdownProps={
                    {
                      children: [
                        <option key={`initial-empty-value`} value="" disabled>
                          Select one
                        </option>,
                        ...countryResidenceList?.map(
                          (country: any, i: number) => (
                            <option key={i} value={country.label}>
                              {country.label}
                            </option>
                          )
                        ),
                      ],
                    } as any
                  }
                >
                  Current country of residence
                </DropdownWithLabel.WithFormManager>
              </div>

              <div className="mb-3">
                <DropdownWithLabel.WithFormManager
                  id="countryResidenceLast6Months"
                  name="countryResidenceLast6Months"
                  formManager={formManager}
                  tooltip="Select your same country of residence again if you've only lived in that country for the last 12 months, or for at least 6 of the last 12 months."
                  dropdownProps={
                    {
                      children: [
                        <option key={`initial-empty-value`} value="" disabled>
                          Select one
                        </option>,
                        ...countryResidenceList?.map(
                          (country: any, i: number) => (
                            <option key={i} value={country.label}>
                              {country.label}
                            </option>
                          )
                        ),
                      ],
                    } as any
                  }
                >
                  Which country have they lived in for at least 6 of the last 12
                  months?
                </DropdownWithLabel.WithFormManager>
              </div>
            </div>
          )}

          <Button
            size="large"
            className="mx-auto mt-6"
            isLoading={formManager.isSubmitting}
            disabled={formManager.isSubmitting}
            type="button"
            onClick={formManager.createSubmitHandler(handleSubmit) as any}
          >
            Add Family Member
          </Button>
        </div>
      </>
    );

    if (setIsModalOpen === undefined || isModalOpen === undefined) {
      return Content;
    }

    return (
      <Modal
        modalOpen={isModalOpen}
        setModalOpen={setIsModalOpen}
        variation="medium"
        onModalClose={() => {
          formManager.reset();
        }}
      >
        {Content}
      </Modal>
    );
  }
);
