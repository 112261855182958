import { QuestionOptionsQueryVariables } from 'generated/graphql';
import logger from 'js-logger';

import backendClient from '../backend';

export class QuestionOptions {
  static async findMany(args: QuestionOptionsQueryVariables) {
    try {
      return (await backendClient.QuestionOptions(args)).data?.QuestionOptions;
    } catch (e) {
      logger.error(e);
    }
  }
}
