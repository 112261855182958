import React, { FC } from 'react';
import { Menu as MenuIcon } from 'react-feather';

export const BurgerMenu: FC<
  {
    setSidebarOpen: (b: boolean) => void;
    sidebarOpen: boolean;
  } & JSX.IntrinsicElements['div']
> = ({ setSidebarOpen, sidebarOpen, className }) => {
  return (
    <div
      className={`flex items-center pr-4 mr-4 border-r border-gray-200 h-full ${className}`}
    >
      <button
        className={`focus:outline-none`}
        onClick={() => setSidebarOpen(!sidebarOpen)}
      >
        <span className="sr-only">Open sidebar</span>
        <MenuIcon
          className="h-6 w-6 text-gray-400 hover:text-gray-800"
          aria-hidden="true"
        />
      </button>
    </div>
  );
};
