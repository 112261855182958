import '../styles/global/globals.scss';
import '../backend';
import 'draft-js/dist/Draft.css';
import 'react-datepicker/dist/react-datepicker.css';

import { ApolloProvider } from '@apollo/client';
import { setUser } from '@sentry/nextjs';
import { hasCookie } from 'cookies-next';
import { Crisp } from 'crisp-sdk-web';
import environment from 'dataLayer/Environment';
import { LayoutContext } from 'dataLayer/LayoutContext';
import { FullScreenLoading } from 'elements/Loading/FullScreenLoading';
import { useAsyncEffect } from 'hooks/useAsyncEffect';
import { useManagedLayout } from 'hooks/useLayout';
import { configure } from 'mobx';
import { enableStaticRendering } from 'mobx-react-lite';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import { NextRouter, useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { UIDReset } from 'react-uid';
import { gaPageView } from 'utils/analytics';
import {
  GOOGLE_TRANSLATE_COOKIE,
  ignoreGoogleTranslateError,
} from 'utils/google';
import { supabase } from 'utils/supabase';

import backendClient from '../backend';
import { AppLayout } from '../components/AppLayout';
import { ActiveUserContext } from '../dataLayer/ActiveUserContext';
import { User } from '../dataLayer/User';

const ToasterWithNoSSR = dynamic(
  async () => (await import('react-hot-toast')).Toaster,
  {
    ssr: false,
  }
);

configure({
  enforceActions: 'never',
});

enableStaticRendering(typeof window === 'undefined');

type ComponentWithPageLayout = AppProps & {
  Component: AppProps['Component'] & {
    PageLayout?: React.ComponentType;
  };
  csrfToken: string;
  user: any;
  isMaintenanceMode: boolean;
};

const Visto = ({ Component, pageProps }: ComponentWithPageLayout) => {
  const layoutController = useManagedLayout({ layout: 'Base', title: 'Visto' });

  const router = useRouter();

  const [activeUser, setActiveUser] = useState<null | User>(null);
  const [isFetching, setIsFetching] = useState(true);
  const [isMfaVerify, setIsMfaVerify] = useState(false);

  useAsyncEffect(async (status: { isMounted: boolean }) => {
    const user = await User.getActiveUser({ ignoreError: true });

    if (status.isMounted) {
      setActiveUser(user ?? null);

      if (user) {
        handleUserSetup(user);
      }
    }

    setIsFetching(false);
  }, []);

  useEffect(() => {
    setIsMfaVerify(router.query.action === 'mfa-verify');
  }, [router.query.action]);

  useEffect(() => {
    const { data: authListener } = handleSupabaseOnAuthStateChange(
      router,
      isMfaVerify
    );

    return () => {
      authListener?.subscription.unsubscribe();
    };
  }, [isMfaVerify, activeUser?.id]);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (!environment.isProd()) {
        return null;
      }

      gaPageView(url, activeUser?.id);
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  const googleTranslateActive = hasCookie(GOOGLE_TRANSLATE_COOKIE);

  if (googleTranslateActive && typeof Node === 'function' && Node.prototype) {
    ignoreGoogleTranslateError();
  }

  if (isFetching) {
    return <FullScreenLoading show type="logo" />;
  }

  return (
    <UIDReset>
      <ApolloProvider client={backendClient.apolloClient}>
        <LayoutContext.Provider value={layoutController}>
          <ActiveUserContext.Provider
            value={{ user: activeUser, setUser: setActiveUser }}
          >
            <AppLayout pageProps={pageProps} Component={Component} />
            <ToasterWithNoSSR position="top-right" />
          </ActiveUserContext.Provider>
        </LayoutContext.Provider>
      </ApolloProvider>
    </UIDReset>
  );
};

const handleSupabaseOnAuthStateChange = (
  router: NextRouter,
  isMfaVerify: boolean
) => {
  return supabase.auth.onAuthStateChange((event, session) => {
    const is = {
      login: router.pathname === '/login',
      leads: router.pathname === '/leads/embed/[leadEmbedId]',
    };

    if (is.login && event === 'SIGNED_IN' && typeof $crisp !== 'undefined') {
      $crisp.push(['do', 'session:reset']);
    }

    if (
      (event === 'SIGNED_IN' || event === 'SIGNED_OUT') &&
      !isMfaVerify &&
      !is.leads
    ) {
      // Send session to /api/auth route to set the auth cookie.
      fetch('/api/auth', {
        method: 'POST',
        headers: new Headers({ 'Content-Type': 'application/json' }),
        credentials: 'same-origin',
        body: JSON.stringify({ event, session }),
      })
        .then(async (res) => {
          const data = await res.json();

          if (data.cookieExists && router.pathname !== '/login') {
            return null;
          }

          if (typeof window !== 'undefined') {
            let redirectUrl =
              typeof router.query?.redirectTo === 'string'
                ? decodeURIComponent(router.query?.redirectTo)
                : '/';

            if (data?.redirectToMfa === true) {
              redirectUrl = '/login?action=mfa-verify';
            }

            window.location.href = redirectUrl;
          }
        })
        // eslint-disable-next-line no-console
        .catch(console.log);
    }
  });
};

const handleUserSetup = (user: User) => {
  if (environment.isProd() && !user?.isType('SUPER_ADMIN')) {
    Crisp.configure('9c90a278-3c11-4124-8e04-007341df3100');

    if (user?.email) {
      Crisp.user.setEmail(user?.email ?? '');
      Crisp.user.setNickname(`${user?.firstName} ${user?.lastName}`);
      Crisp.session.setData({ account_type: user?.account.type ?? '' });
    }
  }

  if ((environment.isProd() || environment.isStg()) && user?.id) {
    setUser({
      id: user?.id,
      email: user?.email,
    });
  }

  if (
    typeof $crisp !== 'undefined' &&
    (user?.isType('APPLICANT') || user?.isType('COMPANY'))
  ) {
    $crisp.push([
      'on',
      'chat:opened',
      () => {
        $crisp.push([
          'set',
          'session:event',
          ['support-chat-applicant-company'],
        ]);
      },
    ]);
  }
};

export default Visto;
