import { FC } from 'react';

export const ProgressTracker: FC<
  { size?: number } & JSX.IntrinsicElements['div']
> = ({ size = 6, className }) => {
  return (
    <div
      id="application-progress-tracker"
      className={`animate-pulse ${className}`}
    >
      <div className="border border-gray-300 rounded-md p-4 w-full">
        <div className="md:flex justify-between items-center mb-6">
          <div className="w-full mb-4 md:mb-0">
            <div
              className="h-3 bg-gray-300 rounded w-full mb-3"
              style={{ maxWidth: '100px' }}
            ></div>
            <div
              className="h-2 bg-gray-300 rounded "
              style={{ maxWidth: '300px' }}
            ></div>
          </div>
          <div className="w-auto" style={{ minWidth: '150px' }}>
            <div
              className="border border-gray-300 rounded-3xl px-5 py-2"
              style={{ maxWidth: '150px' }}
            >
              <div className="flex space-x-2">
                <div className="flex-1 space-y-2 py-1">
                  <div className="h-1 bg-gray-300 rounded w-full"></div>
                  <div className="h-1 bg-gray-300 rounded w-full"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ul className="progressTracker">
          {Array(size)
            .fill(0)
            .map((_, i: number) => (
              <li key={`size-${i}`} className="progressTracker__itemCompleted">
                <span className="progressTracker__circleWrapper opacity-20 mb-1.5 pt-1">
                  <span className="progressTracker__circle"></span>
                </span>
                <div className="progressTracker__label h-2 bg-gray-300 rounded w-full"></div>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

ProgressTracker.displayName = 'ProgressTracker';

export default ProgressTracker;
