import { useCallback, useEffect, useState } from 'react';

export interface MultiStageFormController {
  createPageId: (n: number) => string;
  visiblePageID: null | string;
  changePage: (n: number) => void;
  nextPage: () => void;
  prevPage: () => void;
  maxPage: number;
  isOnLastPage: boolean;
  isOnFirstPage: boolean;
  currentPage: number;
  labels: string[];
  setLabels: (labels: string[]) => any;
}

export const useMultiStageFormController = (
  numberOfFormStages: number
): MultiStageFormController => {
  const [activeIndex, _setActiveIndex] = useState<number>(0);
  const [maxPage, setMaxPage] = useState(numberOfFormStages);
  const [labels, setLabels] = useState<string[]>([]);
  const setActiveIndex = useCallback(
    (n: number) => {
      if (n >= maxPage) return;
      if (n < 0) return;
      _setActiveIndex(n);
    },
    [maxPage]
  );

  useEffect(() => {
    setActiveIndex(0);
    setMaxPage(numberOfFormStages);
  }, [numberOfFormStages, setActiveIndex]);

  const createPageId = (n: number) => {
    return `${n}__PAGE_ID`;
  };

  const changePage = (n: number) => setActiveIndex(n);

  const nextPage = () => setActiveIndex(activeIndex + 1);
  const prevPage = () => setActiveIndex(activeIndex - 1);

  return {
    createPageId,
    changePage,
    nextPage,
    prevPage,
    visiblePageID: createPageId(activeIndex),
    maxPage,
    isOnLastPage: activeIndex >= maxPage - 1,
    isOnFirstPage: activeIndex === 0,
    currentPage: activeIndex + 1,
    labels,
    setLabels,
  };
};
