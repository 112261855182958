import backendClient from 'backend';
import logger from 'js-logger';
import { toArray } from 'lodash';

import {
  CreateQuestionGroupingMutationVariables,
  Form,
  QuestionGrouping as RawQuestionGroup,
  QuestionGroupingQueryVariables,
  QuestionGroupingsQueryVariables,
  UpdateQuestionGroupingMutationVariables,
} from '../generated/graphql';
import Question from './Question';

export class QuestionGroup implements RawQuestionGroup {
  id: number;
  title?: string | null | undefined;
  subtitle?: string | null | undefined;
  questions?: Question[] | null | undefined;
  forms?: (Form | null)[] | null | undefined;
  accountId?: number | null | undefined;

  constructor(rawQuestion: RawQuestionGroup) {
    this.id = rawQuestion.id;
    this.title = rawQuestion.title;
    this.subtitle = rawQuestion.subtitle;

    this.questions = toArray(rawQuestion.questions).map((q) => new Question(q));
  }

  public static async create(args: CreateQuestionGroupingMutationVariables) {
    try {
      const { data } = await backendClient.createQuestionGrouping(args);

      if (!data || !data.createQuestionGrouping) {
        return null;
      }

      return new QuestionGroup(data.createQuestionGrouping as RawQuestionGroup);
    } catch (error) {
      logger.error(error);
    }
  }

  public static async findMany(args: QuestionGroupingsQueryVariables) {
    try {
      return (await backendClient.QuestionGroupings(args)).data
        ?.QuestionGroupings;
    } catch (err) {
      logger.error(err);
    }
  }

  public static async findFirst(args: QuestionGroupingQueryVariables) {
    try {
      return (await backendClient.QuestionGrouping(args)).data
        ?.QuestionGrouping;
    } catch (err) {
      logger.error(err);
    }
  }

  public static async update(args: UpdateQuestionGroupingMutationVariables) {
    try {
      return (await backendClient.updateQuestionGrouping(args)).data
        ?.updateQuestionGrouping;
    } catch (err) {
      logger.error(err);
    }
  }

  isQuestion() {
    return false;
  }

  isQuestionGroup() {
    return true;
  }
}
