/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-identical-functions */
import { FormManager } from '@visto-tech/forms';
import { InputWithLabel } from '@visto-tech/forms';
import { Button } from 'elements/Button';
import { DatePickerInputSecondary } from 'elements/Forms/DatePickerInput/DatePickerInputSecondary';
import Loading from 'elements/Loading';
import { Text } from 'elements/Text';
import { useQuestionRepeater } from 'hooks/useQuestionRepeater';
import { observer } from 'mobx-react-lite';
import React, { FC, useEffect } from 'react';
import { ProjecteRepeaterStructure } from 'types/QuestionRepeaterManagerTypes';

interface ProjectsRepeaterPropTypes {
  formManager: FormManager<any>;
  label: string;
}

export const ProjectsRepeater: FC<ProjectsRepeaterPropTypes> = observer(
  ({ formManager, label }) => {
    const requiredEl = (
      <span className="italic font-light fs12 ml-0.5">(required)</span>
    );
    const initial = formManager._initialData[label];

    const { questionManager } = useQuestionRepeater({
      initialData: initial ? initial : [],
      structure: {
        title: '',
        company: {
          validate: false,
        },
        startDate: '',
        endDate: '',
        link: {
          validate: false,
        },
        description: '',
      },
      formManager,
      label,
    });

    useEffect(() => {
      if (questionManager && questionManager.getState().length === 0) {
        questionManager.add();
      }
    }, [questionManager]);

    if (!questionManager) return <Loading.Default />;

    formManager.formData[label] = questionManager.stringify();

    return (
      <div className="w-full">
        {questionManager
          .getState()
          .map((project: ProjecteRepeaterStructure, i: number) => {
            const isLast = questionManager.getState().length === i + 1;
            return (
              <div
                key={i}
                className={`${
                  !isLast && 'mb-8 pb-8 border-b border-visto-gray'
                }`}
              >
                <div className="w-full flex justify-between items-start mb-2">
                  <Text.Paragraph className="fs12 text-gray-400">
                    Project #{i + 1}
                  </Text.Paragraph>
                  <button
                    className="fs12 text-right text-gray-400 hover:text-gray-800"
                    type="button"
                    onClick={() => questionManager.delete(i)}
                  >
                    Delete
                  </button>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-3 md:gap-4 mb-3 md:mb-4">
                  <InputWithLabel
                    key={`id-title-${i}-input-${label}`}
                    id={`id-title-${i}-input-${label}`}
                    name="title"
                    value={project.title.value ?? ''}
                    error={questionManager.hasError('title', i, formManager)}
                    errorMessage={questionManager.errorMessage(
                      'title',
                      i,
                      formManager
                    )}
                    formFieldSetProps={{
                      className: 'w-full',
                    }}
                    labelProps={{
                      isFocused: true,
                    }}
                    inputProps={
                      {
                        'data-group': i,
                        onChange: questionManager.handleOnChange,
                        type: 'text',
                        placeholder: 'Ex: Shipping Calculator',
                        isFocused: true,
                        className: 'placeholder:text-slate-400',
                      } as any
                    }
                  >
                    Project title {requiredEl}
                  </InputWithLabel>
                  <InputWithLabel
                    key={`id-company-${i}-input-${label}`}
                    id={`id-company-${i}-input-${label}`}
                    name="company"
                    value={project.company.value ?? ''}
                    error={questionManager.hasError('company', i, formManager)}
                    errorMessage={questionManager.errorMessage(
                      'company',
                      i,
                      formManager
                    )}
                    formFieldSetProps={{
                      className: 'w-full',
                    }}
                    labelProps={{
                      isFocused: true,
                    }}
                    inputProps={
                      {
                        'data-group': i,
                        onChange: questionManager.handleOnChange,
                        type: 'text',
                        placeholder: 'Ex: Shopify',
                        isFocused: true,
                        className: 'placeholder:text-slate-400',
                      } as any
                    }
                  >
                    Company completed for
                  </InputWithLabel>
                  <InputWithLabel
                    key={`id-link-${i}-input-${label}`}
                    id={`id-link-${i}-input-${label}`}
                    name="link"
                    value={project.link.value ?? ''}
                    error={questionManager.hasError('link', i, formManager)}
                    errorMessage={questionManager.errorMessage(
                      'link',
                      i,
                      formManager
                    )}
                    formFieldSetProps={{
                      className: 'w-full',
                    }}
                    labelProps={{
                      isFocused: true,
                    }}
                    inputProps={
                      {
                        'data-group': i,
                        onChange: questionManager.handleOnChange,
                        type: 'text',
                        placeholder: 'Ex: visto.ai/project-one',
                        isFocused: true,
                        className: 'placeholder:text-slate-400',
                      } as any
                    }
                  >
                    Project URL
                  </InputWithLabel>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-4 mb-3 md:mb-4">
                  <DatePickerInputSecondary
                    key={`id-startDate-${i}-select-${label}`}
                    label="startDate"
                    initValue={project.startDate.value ?? ''}
                    onChange={questionManager.handleOnChange}
                    inputError={questionManager.errorMessage(
                      'startDate',
                      i,
                      formManager
                    )}
                    dateProps={{
                      name: 'startDate',
                      groupId: i,
                    }}
                  >
                    Date started {requiredEl}
                  </DatePickerInputSecondary>
                  <DatePickerInputSecondary
                    key={`id-endDate-${i}-select-${label}`}
                    label="endDate"
                    initValue={project?.endDate.value ?? ''}
                    onChange={questionManager.handleOnChange}
                    inputError={questionManager.errorMessage(
                      'endDate',
                      i,
                      formManager
                    )}
                    dateProps={{
                      name: 'endDate',
                      groupId: i,
                    }}
                  >
                    Date ended {requiredEl}
                  </DatePickerInputSecondary>
                </div>

                <div className="mb-3">
                  <InputWithLabel
                    key={`id-description-${i}-input-${label}`}
                    id={`id-description-${i}-input-${label}`}
                    name="description"
                    value={project.description.value ?? ''}
                    error={questionManager.hasError(
                      'description',
                      i,
                      formManager
                    )}
                    errorMessage={questionManager.errorMessage(
                      'description',
                      i,
                      formManager
                    )}
                    formFieldSetProps={{
                      className: 'w-full',
                    }}
                    labelProps={{
                      isFocused: true,
                    }}
                    inputProps={
                      {
                        textarea: true,
                        'data-group': i,
                        onChange: questionManager.handleOnChange,
                        type: 'text',
                        isFocused: true,
                      } as any
                    }
                  >
                    Please describe your project {requiredEl}
                  </InputWithLabel>
                </div>
              </div>
            );
          })}

        <div className="flex justify-center mt-6">
          <Button
            type="button"
            format="basic"
            onClick={() => {
              questionManager.add();
            }}
          >
            Add more work experiences
          </Button>
        </div>
      </div>
    );
  }
);
