import { FC } from 'react';

interface DefaultProps {
  show?: boolean;
  size?: number;
  heading?: boolean;
}

export const Default: FC<DefaultProps & JSX.IntrinsicElements['div']> = ({
  show = true,
  size = 3,
  heading = false,
  className,
}) => {
  if (!show) return null;

  return (
    <div className={`animate-pulse ${className}`}>
      {heading && <div className="h-2 bg-gray-300 rounded mb-3 w-16"></div>}
      {Array(size)
        .fill(0)
        .map((_, i: number) => (
          <div
            key={i}
            className="border border-gray-300 rounded-md p-4 w-full mb-3"
          >
            <div className="flex space-x-4">
              <div className="flex-1 space-y-6 py-1">
                <div className="h-2 bg-gray-300 rounded"></div>
                <div className="space-y-3">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 bg-gray-300 rounded col-span-2"></div>
                    <div className="h-2 bg-gray-300 rounded col-span-1"></div>
                  </div>
                  <div className="h-2 bg-gray-300 rounded"></div>
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

Default.displayName = 'Default';

export default Default;
