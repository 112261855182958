/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-identical-functions */
import { DropdownWithLabel, FormManager } from '@visto-tech/forms';
import { InputWithLabel } from '@visto-tech/forms';
import { countryResidenceList } from '@visto-tech/seed';
import { QuestionOptions } from 'dataLayer/QuestionOptions';
import { Button } from 'elements/Button';
import { Checkbox } from 'elements/Forms/Checkbox/Checkbox';
import { DatePickerInputSecondary } from 'elements/Forms/DatePickerInput/DatePickerInputSecondary';
import Loading from 'elements/Loading';
import { Text } from 'elements/Text';
import { QuestionOptions as QuestionOptionsRaw } from 'generated/graphql';
import { useAsyncEffect } from 'hooks/useAsyncEffect';
import { useQuestionRepeater } from 'hooks/useQuestionRepeater';
import { observer } from 'mobx-react-lite';
import React, { FC, useEffect, useState } from 'react';
import { WorkExperienceRepeaterStructure } from 'types/QuestionRepeaterManagerTypes';
import logger from 'utils/logger';

interface WorkExperienceRepeaterPropTypes {
  formManager: FormManager<any>;
  label: string;
}

export const WorkExperienceRepeater: FC<WorkExperienceRepeaterPropTypes> =
  observer(({ formManager, label }) => {
    const [jobRoles, setJobRoles] = useState<QuestionOptionsRaw[] | null>(null);
    const [employmentTypes, setEmploymentTypes] = useState<
      QuestionOptionsRaw[] | null
    >(null);
    const [employmentIndustries, setEmploymentIndustries] = useState<
      QuestionOptionsRaw[] | null
    >(null);

    const refresh = async (status: { isMounted: boolean }) => {
      try {
        const jobRoles = await QuestionOptions.findMany({
          options: { groupName: 'JOB_ROLE_TITLE' },
        });
        const employmentTypes = await QuestionOptions.findMany({
          options: {
            groupName: 'EMPLOYMENT_TYPE',
          },
        });
        const employmentIndustries = await QuestionOptions.findMany({
          options: {
            groupName: 'EMPLOYMENT_INDUSTRY',
          },
        });

        if (status.isMounted) {
          setJobRoles(jobRoles?.data as QuestionOptionsRaw[]);
          setEmploymentTypes(employmentTypes?.data as QuestionOptionsRaw[]);
          setEmploymentIndustries(
            employmentIndustries?.data as QuestionOptionsRaw[]
          );
        }
      } catch (err) {
        logger.error(err);

        if (status.isMounted) {
          setJobRoles(null);
          setEmploymentTypes(null);
          setEmploymentIndustries(null);
        }
      }
    };

    useAsyncEffect(refresh, []);

    const requiredEl = (
      <span className="italic font-light fs12 ml-0.5">(required)</span>
    );
    const initial = formManager._initialData[label];

    const { questionManager } = useQuestionRepeater({
      initialData: initial ? initial : [],
      structure: {
        jobTitle: '',
        company: '',
        location: '',
        type: {
          validate: false,
        },
        industry: {
          validate: false,
        },
        startDate: '',
        endDate: {
          validate: false,
        },
        description: {
          validate: false,
        },
        currentJob: {
          validate: false,
        },
      },
      formManager,
      label,
    });

    useEffect(() => {
      if (questionManager && questionManager.getState().length === 0) {
        questionManager.add();
      }
    }, [questionManager]);

    if (
      !questionManager ||
      !jobRoles ||
      !employmentIndustries ||
      !employmentTypes
    )
      return <Loading.Default />;

    formManager.formData[label] = questionManager.stringify();

    return (
      <div className="w-full">
        {questionManager
          .getState()
          .map((job: WorkExperienceRepeaterStructure, i: number) => {
            const isLast = questionManager.getState().length === i + 1;

            return (
              <div
                key={i}
                className={`${
                  !isLast && 'mb-8 pb-8 border-b border-visto-gray'
                }`}
              >
                <div className="w-full flex justify-between items-start mb-2">
                  <Text.Paragraph className="fs12 text-gray-400">
                    Work Experience #{i + 1}
                  </Text.Paragraph>
                  <button
                    className="fs12 text-right text-gray-400 hover:text-gray-800"
                    type="button"
                    onClick={() => questionManager.delete(i)}
                  >
                    Delete
                  </button>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-3 md:gap-4 mb-3 md:mb-4">
                  <DropdownWithLabel
                    key={`id-jobTitle-${i}-select-${label}`}
                    id={`id-jobTitle-${i}-select-${label}`}
                    name="jobTitle"
                    value={job.jobTitle.value ?? ''}
                    error={questionManager.hasError('jobTitle', i, formManager)}
                    errorMessage={questionManager.errorMessage(
                      'jobTitle',
                      i,
                      formManager
                    )}
                    dropdownProps={
                      {
                        'data-group': i,
                        onChange: questionManager.handleOnChange,
                        children: [
                          <option
                            key={`initial-empty-value`}
                            value=""
                            disabled
                            selected
                          >
                            Select role
                          </option>,
                          ...jobRoles.map((q, index) => (
                            <option
                              key={`opt-jobTitle-${index}`}
                              value={q.option}
                            >
                              {q.option}
                            </option>
                          )),
                        ],
                      } as any
                    }
                  >
                    Role title {requiredEl}
                  </DropdownWithLabel>
                  <InputWithLabel
                    key={`id-company-${i}-input-${label}`}
                    id={`id-company-${i}-input-${label}`}
                    name="company"
                    value={job.company.value ?? ''}
                    error={questionManager.hasError('company', i, formManager)}
                    errorMessage={questionManager.errorMessage(
                      'company',
                      i,
                      formManager
                    )}
                    formFieldSetProps={{
                      className: 'w-full',
                    }}
                    labelProps={{
                      isFocused: true,
                    }}
                    inputProps={
                      {
                        'data-group': i,
                        onChange: questionManager.handleOnChange,
                        type: 'text',
                        placeholder: 'Ex: Shopify',
                        isFocused: true,
                        className: 'placeholder:text-slate-400',
                      } as any
                    }
                  >
                    Company name {requiredEl}
                  </InputWithLabel>
                  <DropdownWithLabel
                    key={`id-location-${i}-select-${label}`}
                    id={`id-location-${i}-select-${label}`}
                    name="location"
                    value={job.location.value ?? ''}
                    error={questionManager.hasError('location', i, formManager)}
                    errorMessage={questionManager.errorMessage(
                      'location',
                      i,
                      formManager
                    )}
                    dropdownProps={
                      {
                        'data-group': i,
                        onChange: questionManager.handleOnChange,
                        children: [
                          <option
                            key={`initial-empty-value`}
                            value=""
                            disabled
                            selected
                          >
                            Select country
                          </option>,
                          ...countryResidenceList.map((q, index) => (
                            <option
                              key={`opt-location-${index}`}
                              value={q.label}
                            >
                              {q.label}
                            </option>
                          )),
                        ],
                      } as any
                    }
                  >
                    Location {requiredEl}
                  </DropdownWithLabel>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-4 mb-3 md:mb-4">
                  <DropdownWithLabel
                    key={`id-type-${i}-select-${label}`}
                    id={`id-type-${i}-select-${label}`}
                    name="type"
                    value={job.type.value ?? ''}
                    error={questionManager.hasError('type', i, formManager)}
                    errorMessage={questionManager.errorMessage(
                      'type',
                      i,
                      formManager
                    )}
                    dropdownProps={
                      {
                        'data-group': i,
                        onChange: questionManager.handleOnChange,
                        children: [
                          <option
                            key={`initial-empty-value`}
                            value=""
                            disabled
                            selected
                          >
                            Select one
                          </option>,
                          ...employmentTypes.map((q, index) => (
                            <option key={`opt-type-${index}`} value={q.option}>
                              {q.option}
                            </option>
                          )),
                        ],
                      } as any
                    }
                  >
                    Employment type
                  </DropdownWithLabel>

                  <DropdownWithLabel
                    key={`id-industry-${i}-select-${label}`}
                    id={`id-industry-${i}-select-${label}`}
                    name="industry"
                    value={job.industry.value ?? ''}
                    error={questionManager.hasError('industry', i, formManager)}
                    errorMessage={questionManager.errorMessage(
                      'industry',
                      i,
                      formManager
                    )}
                    dropdownProps={
                      {
                        'data-group': i,
                        onChange: questionManager.handleOnChange,
                        children: [
                          <option
                            key={`initial-empty-value`}
                            value=""
                            disabled
                            selected
                          >
                            Select one
                          </option>,
                          ...employmentIndustries.map((q, index) => (
                            <option
                              key={`opt-industry-${index}`}
                              value={q.option}
                            >
                              {q.option}
                            </option>
                          )),
                        ],
                      } as any
                    }
                  >
                    Industry
                  </DropdownWithLabel>
                </div>
                <div className="mb-3">
                  <Checkbox
                    id={`currentJob-${i}`}
                    name="currentJob"
                    onChange={(e) => questionManager.handleOnChange(e)}
                    checked={Boolean(job.currentJob.value)}
                    data-group={i}
                  >
                    I am currently working in this role
                  </Checkbox>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-4 mb-3 md:mb-4">
                  <DatePickerInputSecondary
                    key={`id-startDate-${i}-select-${label}`}
                    label="startDate"
                    initValue={job.startDate.value ?? ''}
                    onChange={questionManager.handleOnChange}
                    inputError={questionManager.errorMessage(
                      'startDate',
                      i,
                      formManager
                    )}
                    dateProps={{
                      name: 'startDate',
                      groupId: i,
                    }}
                  >
                    Date started {requiredEl}
                  </DatePickerInputSecondary>

                  <DatePickerInputSecondary
                    key={`id-endDate-${i}-select-${label}`}
                    label="endDate"
                    initValue={job?.endDate.value ?? ''}
                    onChange={questionManager.handleOnChange}
                    inputError={questionManager.errorMessage(
                      'endDate',
                      i,
                      formManager
                    )}
                    dateProps={{
                      name: 'endDate',
                      groupId: i,
                    }}
                    className={`${
                      Boolean(job.currentJob.value) &&
                      'opacity-40 pointer-events-none'
                    }`}
                  >
                    Date ended
                  </DatePickerInputSecondary>
                </div>

                <div className="mb-3">
                  <InputWithLabel
                    key={`id-description-${i}-input-${label}`}
                    id={`id-description-${i}-input-${label}`}
                    name="description"
                    value={job.description.value ?? ''}
                    error={questionManager.hasError(
                      'description',
                      i,
                      formManager
                    )}
                    errorMessage={questionManager.errorMessage(
                      'description',
                      i,
                      formManager
                    )}
                    formFieldSetProps={{
                      className: 'w-full',
                    }}
                    labelProps={{
                      isFocused: true,
                    }}
                    inputProps={
                      {
                        textarea: true,
                        'data-group': i,
                        onChange: questionManager.handleOnChange,
                        type: 'text',
                        isFocused: true,
                      } as any
                    }
                  >
                    Description
                  </InputWithLabel>
                </div>
              </div>
            );
          })}

        <div className="flex justify-center mt-6">
          <Button
            type="button"
            format="basic"
            onClick={() => {
              questionManager.add();
            }}
          >
            Add more work experiences
          </Button>
        </div>
      </div>
    );
  });
