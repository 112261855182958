/* eslint-disable sonarjs/no-duplicate-string */
import { LayoutContext } from 'dataLayer/LayoutContext';
import { FullScreenLoading } from 'elements/Loading/FullScreenLoading';
import { useActiveUser } from 'hooks/useActiveUser';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { FC, useContext, useEffect, useState } from 'react';

import { Brand } from './Brand';
import { BurgerMenu } from './BurgerMenu';
import { NavNotificationBar } from './NavNotificationBar';
import { SideNav } from './SideNav/SideNav';
import { TopNav } from './TopNav';

export const MD_BREAKPOINT = 768;

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export const MainLayout: FC = ({ children }) => {
  const router = useRouter();
  const user = useActiveUser();

  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [isMobile, setIsMobile] = useState<boolean>(true);
  const { isLoading, title, sidebarLocation } = useContext(LayoutContext);

  useEffect(() => {
    setWidth(window.innerWidth);

    let hideMenu = [
      '/builder/forms/[formId]',
      '/builder/applications/[applicationTemplateId]',
      '/messenger',
      '/copilot/chat',
      '/copilot/draft/[draftId]',
    ].includes(router.pathname);

    if (user?.isType('COPILOT') && router.pathname === '/') {
      hideMenu = true;
    }

    const showMenu = width > MD_BREAKPOINT;

    if (showMenu && !hideMenu) {
      setIsMobile(false);

      if (!sidebarOpen) {
        setSidebarOpen(true);
      }
    } else {
      setSidebarOpen(false);
    }
  }, [router.pathname]);

  const main = {
    overflowHidden: [
      '/messenger',
      '/copilot/chat',
      '/builder/forms/[formId]',
      '/builder/applications/[applicationTemplateId]',
    ].includes(router.pathname),
    disablePadding: [
      '/messenger',
      '/copilot/chat',
      '/builder/forms/[formId]',
      '/builder/applications/[applicationTemplateId]',
    ].includes(router.pathname),
  };

  if (user?.isType('COPILOT') && router.pathname === '/') {
    main.overflowHidden = true;
    main.disablePadding = true;
  }

  return (
    <>
      <Head>
        <title>{`${title ? title + ' - ' : ''}Visto`}</title>
      </Head>
      <div className="min-h-screen bg-body font-sans">
        <NavNotificationBar />
        <header
          id="mainNav"
          className="flex flex-cols h-16 w-full bg-white border-b border-visto-gray justify-between relative z-10 px-4"
        >
          <div className="flex items-center h-full">
            <BurgerMenu
              setSidebarOpen={setSidebarOpen}
              sidebarOpen={sidebarOpen}
            />
            <Brand />
          </div>
          <TopNav />
        </header>
        <div
          id="contentArea"
          className="flex flex-row items-stretch min-w-full content-area"
        >
          <SideNav
            sidebarOpen={sidebarOpen}
            setSidebarOpen={isMobile ? setSidebarOpen : undefined}
          />
          <main
            id="mainContentArea"
            className={`main-content relative w-full overflow-x-visible focus:outline-none md:transition-all md:duration-1000 ${
              !main.disablePadding ? 'p-4 pt-6 md:p-8' : ''
            } ${
              sidebarOpen
                ? 'md:ml-265px md:ease-out-in'
                : 'md:ml-0 md:ease-in-out'
            } ${
              isLoading || main.overflowHidden
                ? 'overflow-y-hidden'
                : 'overflow-y-scroll'
            }`}
          >
            <div className={'w-full'}>
              <MainLayoutHeader />
              <MainLayoutBanner />
              <div
                className={`flex ${
                  sidebarLocation === 'bottom' ? 'flex-col' : 'flex-col-reverse'
                } lg:flex-row`}
              >
                <div className="w-full">{children}</div>
                <MainLayoutSidebar
                  className={`w-full lg:w-5/12 ${
                    sidebarLocation === 'bottom' ? 'mt-6' : 'mt-1'
                  } md:mt-0 md:min-w-[300px]`}
                />
              </div>
            </div>
            <MainLayoutContentLoading className="lg:pl-20" />
          </main>
        </div>
      </div>
    </>
  );
};

const MainLayoutHeader: FC<JSX.IntrinsicElements['div']> = ({ className }) => {
  const { Header } = useContext(LayoutContext);

  if (!Header) return null;

  return <div className={className}>{Header}</div>;
};

const MainLayoutSidebar: FC<JSX.IntrinsicElements['div']> = ({ className }) => {
  const { Sidebar } = useContext(LayoutContext);
  if (!Sidebar) return null;
  return (
    <div className={`${className} ml-0 mb-6 lg:mb-0 lg:ml-6`}>
      <div className="sticky top-0">{Sidebar}</div>
    </div>
  );
};

const MainLayoutBanner: FC<JSX.IntrinsicElements['div']> = ({ className }) => {
  const { Banner } = useContext(LayoutContext);

  if (!Banner) return null;

  return <div className={className}>{Banner}</div>;
};

const MainLayoutContentLoading: FC<JSX.IntrinsicElements['div']> = ({
  className,
}) => {
  const { isLoading, loadingMessage } = useContext(LayoutContext);

  return (
    <FullScreenLoading
      show={isLoading}
      message={loadingMessage}
      className={className}
    />
  );
};

export default MainLayout;
