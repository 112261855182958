import { FC } from 'react';

interface InputProps {
  show?: boolean;
}

export const Input: FC<InputProps & JSX.IntrinsicElements['div']> = ({
  show = true,
  className,
}) => {
  if (!show) return null;

  return (
    <div className={`animate-pulse w-36 ${className ?? ''}`}>
      <div className="border border-gray-300 rounded-md p-2 w-full mb-3">
        <div className="flex space-x-2">
          <div className="flex-1 py-1">
            <div className="h-2 bg-gray-300 rounded w-32"></div>
            <div className="h-2 bg-gray-300 rounded w-full mt-5"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

Input.displayName = 'Input';

export default Input;
