import { makeAutoObservable } from 'mobx';

type ConditionalClassMap = { [ClassName: string]: () => boolean };
export class CSSClassManager {
  private classes: Set<string>;
  public initialClasses: string[];
  private conditionalClasses: ConditionalClassMap = {};
  private withClasses = '';

  constructor(baseClasses?: string[]) {
    this.classes = baseClasses ? new Set(baseClasses) : new Set();
    this.initialClasses = baseClasses ? baseClasses : [];

    makeAutoObservable(this, { toString: false } as any);
  }

  push(...args: (string | undefined)[]) {
    const filteredArgs: string[] = args.filter(
      (arg) => arg !== undefined
    ) as string[];
    filteredArgs.forEach((arg) => {
      if (!this.classes.has(arg)) this.classes.add(arg);
    });
    return this;
  }

  remove(className: string) {
    this.classes.delete(className);
    return this;
  }

  /**
   * Update many classes at once by passing an object mapping the className (string) to if
   * it should be included or not (true/false)
   *
   * Updates in one operation to allow for animations, etc
   * @param updates
   */
  bulkUpdate(updates: { [className: string]: boolean }) {
    const newSet = new Set(Array.from(this.classes));
    Object.entries(updates).forEach(([className, shouldIncludeInResult]) => {
      if (shouldIncludeInResult) newSet.add(className);
      else newSet.delete(className);
    });
    this.classes = newSet;
  }

  /**
   *
   * Output the classes managed by this observable as a string.
   *
   * Optionally, supply additional classes via the "withClasses" option:
   * -- Specify some className(s) to be added to the output of this class manager's toString call
   * -- Useful for adding classes passed into a component from above to whatever dynamic styles are already being managed
   *
   * @param options
   * @returns
   */
  toString(options?: {
    withClasses?: string;
    withConditionalClasses?: ConditionalClassMap;
  }) {
    const conditionalClasses = Object.entries(
      Object.assign(
        {},
        this.conditionalClasses,
        options?.withConditionalClasses || {}
      )
    )
      .filter(([, val]) => val())
      .map(([key]) => key)
      .join(' ');

    return (
      Array.from(this.classes).join(' ') +
      ' ' +
      (options?.withClasses || '') +
      ' ' +
      conditionalClasses
    );
  }

  registerConditionalClass(className: string, check: () => boolean) {
    this.conditionalClasses[className] = check;
  }
}

export type UniversalClassesInputs = (string | string[])[];

export const createClassManager = (
  ...classNamesArr: UniversalClassesInputs
) => {
  const classNames = classNamesArr.flat();
  return new CSSClassManager(classNames);
};
