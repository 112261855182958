import { FormManager } from '@visto-tech/forms';
import Form from 'dataLayer/Form';
import { Button } from 'elements/Button';
import { Text } from 'elements/Text';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';

export const FormSubmitReview: FC<{
  formManager: FormManager<any>;
  setIsReview: (status: boolean) => void;
  formName: string;
  form?: Form | null;
}> = observer(({ formManager, setIsReview, formName, form }) => {
  return (
    <div className="max-w-xl mx-auto">
      {formManager.hasErrors() ? (
        <>
          <Text.Paragraph className="text-center mb-6">
            Please close the pop-up and fix your errors before submitting the
            form.
          </Text.Paragraph>
          <div className="flex justify-center items-center">
            <Button
              className="fs14"
              type="button"
              onClick={() => {
                setIsReview(false);

                if (formManager.hasErrors()) {
                  (document as Document)
                    .querySelector(`.mt-1.text-red-600.fs12`)
                    ?.scrollIntoView({
                      behavior: 'smooth',
                    });
                }
              }}
              format="outline"
            >
              Fix Errors
            </Button>
          </div>
        </>
      ) : (
        <div>
          <div className="visto-component mb-8">
            <div className="mx-auto">
              <Text.Heading
                variant="none"
                className="fs24 text-center font-body font-bold mb-1"
              >
                Review Details
              </Text.Heading>
              <Text.Paragraph className="text-center mb-1">
                Please carefully review the information before submitting.
              </Text.Paragraph>
              <Text.Paragraph className="text-center fs14">
                When you are done, click "Submit" at the bottom of this page.
              </Text.Paragraph>
              {form && (
                <div className="space-y-2 pt-4">
                  {form?.renderDisplay({
                    formManager,
                    showCopyButton: false,
                  })}
                </div>
              )}
            </div>
          </div>
          <div>
            <Button
              isLoading={formManager.isSubmitting}
              disabled={formManager.isSubmitting}
              value="PUBLISHED"
              className="mx-auto"
              type="submit"
              form={`form-${formName}`}
              size="large"
            >
              Submit
            </Button>
            <Button
              format="basic"
              className="mx-auto !pb-0"
              type="button"
              onClick={(e) => {
                e.preventDefault();

                setIsReview(false);
              }}
            >
              Back
            </Button>
          </div>
        </div>
      )}
    </div>
  );
});
