import Text from 'elements/Text';
import { FC } from 'react';

import LoadingSpinner from '../LoadingSpinner';

export const Form: FC<JSX.IntrinsicElements['div']> = ({ className }) => {
  return (
    <div
      className={`border border-gray-300 rounded-md p-8 w-full flex justify-center flex-col items-center ${
        className ?? ''
      }`}
    >
      <LoadingSpinner variation="medium" className="mb-5" />
      <Text.Paragraph className="text-center font-bold fs18 mb-1">
        Loading form...
      </Text.Paragraph>
      <Text.Paragraph className="text-center">
        Please wait, this may take a few seconds.
      </Text.Paragraph>
    </div>
  );
};

Form.displayName = 'Form';

export default Form;
