import { FC } from 'react';

interface ButtonProps {
  show?: boolean;
}

export const Button: FC<ButtonProps & JSX.IntrinsicElements['div']> = ({
  show = true,
  className,
}) => {
  if (!show) return null;

  return (
    <div className={`${className ?? ''} animate-pulse w-32`}>
      <div className="border border-gray-300 rounded-3xl px-5 py-2">
        <div className="flex space-x-2">
          <div className="flex-1 space-y-2 py-1">
            <div className="h-1 bg-gray-300 rounded w-full"></div>
            <div className="h-1 bg-gray-300 rounded w-full"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

Button.displayName = 'Button';

export default Button;
