import React, { useEffect } from 'react';

export type useAsyncEffectInput = {
  isMounted: boolean;
};

export const useAsyncEffect = (
  func: (status: useAsyncEffectInput) => Promise<any>,
  deps: React.DependencyList
) => {
  useEffect(() => {
    const status = { isMounted: true };
    func(status);
    return () => {
      status.isMounted = false;
    };
  }, deps);
};
