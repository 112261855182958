import { camelCase, kebabCase } from 'lodash';

export const toDataAttrs = (obj: {
  [Key: string]: null | undefined | string;
}) =>
  Object.entries(obj).reduce(
    (acc, [key, val]) => ({
      ...acc,
      [`data-${kebabCase(key).toLowerCase()}`]: val?.toString(),
    }),
    {}
  );

export const formatServerEnv = (obj: {
  [Key: string]: null | undefined | string;
}) =>
  Object.entries(obj).reduce(
    (acc, [key, val]) => ({
      ...acc,
      [`${camelCase(key)}`]: process.env.IS_DOCKER
        ? val?.toString().replace('localhost', 'host.docker.internal')
        : val?.toString(),
    }),
    {}
  );
