import BaseLayout from './BaseLayout';
import EmbedLayout from './EmbedLayout';
import MainLayout from './MainLayout/MainLayout';

export const DefaultLayout = BaseLayout;

export const Layout = {
  Main: MainLayout,
  Base: BaseLayout,
  Embed: EmbedLayout,
};

export default Layout;
