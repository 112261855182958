import { FC } from 'react';

interface CircleProps {
  show?: boolean;
}

export const Circle: FC<CircleProps & JSX.IntrinsicElements['div']> = ({
  show = true,
  className,
}) => {
  if (!show) return null;

  return (
    <div
      className={`w-8 h-8 min-w-8 rounded-full bg-gray-300 ${className ?? ''}`}
    ></div>
  );
};

Circle.displayName = 'Circle';

export default Circle;
