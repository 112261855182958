import { LayoutContext } from 'dataLayer/LayoutContext';
import { useActiveUser } from 'hooks/useActiveUser';
import Head from 'next/head';
import { FC, useContext } from 'react';
import { supabase } from 'utils/supabase';

import { Brand } from './MainLayout/Brand';

export type LayoutProps = {
  children: React.ReactNode;
};

const BaseLayout: FC<LayoutProps> = ({ children }) => {
  const { title } = useContext(LayoutContext);
  const user = useActiveUser();

  return (
    <>
      <Head>
        <title>{`${title ? title + ' - ' : ''}Visto`}</title>
      </Head>
      <div className="min-h-screen font-sans bg-gray-100">
        <header className="flex items-center h-16 justify-between px-4">
          <Brand />
          {user && (
            <button
              className="fs14 text-gray-400"
              onClick={() => supabase.auth.signOut()}
            >
              Sign Out
            </button>
          )}
        </header>
        <main className="flex flex-row justify-center items-stretch h-auto min-w-full">
          {children}
        </main>
      </div>
    </>
  );
};

export default BaseLayout;
