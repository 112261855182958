/* eslint-disable react-hooks/rules-of-hooks */
import { format } from 'date-fns';
import Logger from 'js-logger';

export const createLogger = (name: string) => {
  Logger.useDefaults({
    defaultLevel:
      process.env.DEPLOYMENT_ENVIRONMENT === 'prod'
        ? Logger.ERROR
        : Logger.TRACE,
    formatter: function (messages, context) {
      const dt = format(new Date(), 'hh:mmaa');

      messages.unshift(`[${dt}]`, `[${context.level.name}]:`);
    },
  });
  return Logger.get(name);
};

export const logger = createLogger('DEFAULT');
export default logger;
