import { FC } from 'react';

type HTMLHeadingProps =
  | JSX.IntrinsicElements['h1']
  | JSX.IntrinsicElements['h2']
  | JSX.IntrinsicElements['h3']
  | JSX.IntrinsicElements['h4']
  | JSX.IntrinsicElements['h5']
  | JSX.IntrinsicElements['h6'];

interface HeadingProps {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  variant?: 'page-heading' | 'form-heading' | 'none';
}

const getVariantClasses = (variant: HeadingProps['variant']) => {
  if (variant === 'page-heading') return ['fs21 md:fs36'];
  if (variant === 'form-heading') return ['fs18 md:fs21'];
  return '';
};

export const Heading: FC<HeadingProps & HTMLHeadingProps> = ({
  as = 'h2',
  variant = 'page-heading',
  children,
  className = '',
}) => {
  const Tag = as;
  const classes = getVariantClasses(variant);
  return <Tag className={classes + ' ' + className}>{children}</Tag>;
};

Heading.displayName = 'Heading';

export default Heading;
