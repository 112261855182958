import { DropdownWithLabel, FormManager } from '@visto-tech/forms';
import { InputWithLabel } from '@visto-tech/forms';
import {
  countryResidenceList,
  residenceStatusOptionsTransformed,
} from '@visto-tech/seed';
import { Button } from 'elements/Button';
import { DatePickerInputSecondary } from 'elements/Forms/DatePickerInput/DatePickerInputSecondary';
import Loading from 'elements/Loading';
import { Text } from 'elements/Text';
import { useQuestionRepeater } from 'hooks/useQuestionRepeater';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { Plus } from 'react-feather';
import { PreviousCountry } from 'types/QuestionRepeaterManagerTypes';

interface PreviousCountryRepeaterPropTypes {
  formManager: FormManager<any>;
  label: string;
}

export const PreviousCountryRepeater: FC<PreviousCountryRepeaterPropTypes> =
  observer(({ formManager, label, children }) => {
    const initial = formManager._initialData[label];

    const { questionManager } = useQuestionRepeater({
      initialData: initial ? initial : [],
      structure: {
        country: '',
        status: '',
        statusOther: '',
        fromDate: '',
        toDate: '',
      },
      formManager: formManager,
      label,
    });

    if (!questionManager) return <Loading.Default />;

    formManager.formData[label] = questionManager.stringify();

    return (
      <div className="w-full">
        <Text.Paragraph className="fs14 font-semibold mb-2">
          {children}
        </Text.Paragraph>
        {questionManager.state.length === 0 && (
          <div className="bg-gray-100 p-6 mb-3 rounded-lg flex justify-center">
            <Button
              type="button"
              onClick={() => {
                questionManager.add();
              }}
            >
              <Plus className="mr-2" size={18} /> Add Previous Country of
              Residence
            </Button>
          </div>
        )}
        {questionManager.state.map(
          (prevCountry: PreviousCountry, i: number) => {
            return (
              <div key={i} className="bg-gray-100 p-3 mb-3 rounded-lg">
                <div className="w-full flex justify-between items-start mb-1">
                  <Text.Paragraph className="fs14 font-semibold mb-2">
                    Previous Country of Residence #{i + 1}
                  </Text.Paragraph>
                  <button
                    className="fs12 text-right text-gray-400 hover:text-gray-800"
                    type="button"
                    onClick={() => {
                      questionManager.delete(i);
                    }}
                  >
                    Delete
                  </button>
                </div>
                <div className="mb-3">
                  <DropdownWithLabel
                    key={`id-country-${i}-select-${label}`}
                    id={`id-country-${i}-select-${label}`}
                    name="country"
                    value={prevCountry.country.value ?? ''}
                    error={questionManager.hasError('country', i, formManager)}
                    errorMessage={questionManager.errorMessage(
                      'country',
                      i,
                      formManager
                    )}
                    dropdownProps={
                      {
                        'data-group': i,
                        onChange: questionManager.handleOnChange,
                        children: [
                          <option key={`initial-empty-value`} value="" disabled>
                            -
                          </option>,
                          ...countryResidenceList.map((q, index) => (
                            <option
                              key={`opt-prevCountry-${index}`}
                              value={q.label}
                            >
                              {q.label}
                            </option>
                          )),
                        ],
                      } as any
                    }
                  >
                    Country
                  </DropdownWithLabel>
                </div>
                <div className="mb-3">
                  <DropdownWithLabel
                    key={`id-status-${i}-select-${label}`}
                    id={`id-status-${i}-select-${label}`}
                    name="status"
                    error={questionManager.hasError('status', i, formManager)}
                    errorMessage={questionManager.errorMessage(
                      'status',
                      i,
                      formManager
                    )}
                    value={prevCountry.status.value ?? ''}
                    dropdownProps={
                      {
                        'data-group': i,
                        onChange: questionManager.handleOnChange,
                        children: [
                          <option key={`initial-empty-value`} value="" disabled>
                            -
                          </option>,
                          ...residenceStatusOptionsTransformed.map(
                            (q, index) => (
                              <option
                                key={`opt-status-${index}`}
                                value={q.option}
                              >
                                {q.option}
                              </option>
                            )
                          ),
                        ],
                      } as any
                    }
                  >
                    Status
                  </DropdownWithLabel>
                </div>
                {prevCountry.status.value === 'Other' && (
                  <div className="mb-3">
                    <InputWithLabel
                      key={`id-statusOther-${i}-input-${label}`}
                      id={`id-statusOther-${i}-input-${label}`}
                      name="statusOther"
                      value={prevCountry.statusOther.value}
                      error={questionManager.hasError(
                        'statusOther',
                        i,
                        formManager
                      )}
                      errorMessage={questionManager.errorMessage(
                        'statusOther',
                        i,
                        formManager
                      )}
                      formFieldSetProps={{
                        className: 'w-full',
                      }}
                      inputProps={
                        {
                          'data-group': i,
                          onChange: questionManager.handleOnChange,
                          type: 'text',
                        } as any
                      }
                    >
                      Other Status
                    </InputWithLabel>
                  </div>
                )}
                <div className="mb-3">
                  <DatePickerInputSecondary
                    key={`id-fromDate-${i}-select-${label}`}
                    label="fromDate"
                    initValue={prevCountry.fromDate.value}
                    onChange={questionManager.handleOnChange}
                    inputError={questionManager.errorMessage(
                      'fromDate',
                      i,
                      formManager
                    )}
                    dateProps={{
                      name: 'fromDate',
                      groupId: i,
                    }}
                  >
                    From Date
                  </DatePickerInputSecondary>
                </div>
                <div className="mb-3">
                  <DatePickerInputSecondary
                    key={`id-toDate-${i}-select-${label}`}
                    label="toDate"
                    initValue={prevCountry.toDate.value}
                    onChange={questionManager.handleOnChange}
                    inputError={questionManager.errorMessage(
                      'toDate',
                      i,
                      formManager
                    )}
                    dateProps={{
                      name: 'toDate',
                      groupId: i,
                    }}
                  >
                    To Date
                  </DatePickerInputSecondary>
                </div>
              </div>
            );
          }
        )}

        {questionManager.state.length > 0 && (
          <div className="flex justify-end mt-3">
            <Button
              type="button"
              format="outline"
              onClick={() => {
                questionManager.add();
              }}
            >
              Add Previous Country of Residence
            </Button>
          </div>
        )}
      </div>
    );
  });
