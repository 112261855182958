import { AllowedFormTypes, FormManager } from '@visto-tech/forms';
import { useState } from 'react';
import * as yup from 'yup';

export const useFormManager = <
  T extends {
    [Key: string]: AllowedFormTypes;
  }
>({
  formData,
  validationSchema,
}: {
  formData: T;
  validationSchema?: yup.ObjectSchema<any>;
}) => {
  const [formManager] = useState(
    () => new FormManager(formData, validationSchema)
  );
  return formManager;
};
