import { AccountSetting } from 'dataLayer/AccountSetting';
import { LawyerAssociation } from 'dataLayer/LawyerAssociation';
import { StorageFile } from 'dataLayer/StorageFile';
import { StorageFileType } from 'generated/graphql';
import { useAccountSubscription } from 'hooks/useAccountSubscription';
import { useActiveUser } from 'hooks/useActiveUser';
import { useAsyncEffect } from 'hooks/useAsyncEffect';
import Link from 'next/link';
import { FC, useRef, useState } from 'react';

import { LOGO } from './LOGO';

export const Brand: FC = () => {
  const user = useActiveUser();
  const { subscription, isFetchingSub } = useAccountSubscription();

  const [fileUrl, setFileUrl] = useState<string | null | undefined>(null);

  const getFileUrl = async (status: { isMounted: boolean }) => {
    if (!user?.id) {
      return;
    }

    let storageFile = null;

    if (subscription?.access?.customization?.allowed) {
      storageFile = await StorageFile.getUrl({
        type: StorageFileType.LawyerAssociationLogo,
      });
    }

    let lawyerAssociation = null;

    if (user?.hasLawyerAssociation) {
      lawyerAssociation = await LawyerAssociation.findFirst({
        accountId: user?.account?.id,
      });
    }

    const setting = await AccountSetting.get({
      key: 'display_logo_main_navigation',
      targetAccountId: lawyerAssociation?.lawyerAccount?.id
        ? lawyerAssociation?.lawyerAccount?.id
        : undefined,
    });

    if (status.isMounted) {
      setFileUrl(setting?.value !== 'false' ? storageFile?.url : null);
    } else {
      setFileUrl(null);
    }
  };

  useAsyncEffect(getFileUrl, [user?.id, isFetchingSub]);

  return (
    <div className="flex items-center">
      <Link href="/">
        <img
          className="flex-shrink-0 w-24"
          style={{ maxWidth: '90px', maxHeight: '45px' }}
          src={LOGO}
          alt="Visto's logo"
        />
      </Link>
      {fileUrl && <LawyerLogo fileUrl={fileUrl} />}
    </div>
  );
};

const LawyerLogo: FC<{ fileUrl: string }> = ({ fileUrl }) => {
  const [fileHeight, setFileHeight] = useState<number>(0);
  const logoRef = useRef(null) as any;

  const handleImageLoad = () => {
    const elementHeight = logoRef?.current?.clientHeight;
    setFileHeight(elementHeight);
  };

  return (
    <div className={`flex items-center ${fileHeight < 30 ? 'mt-2' : ''}`}>
      <div
        className={`bg-gray-300 mx-3`}
        style={{ width: '1px', height: '20px' }}
      ></div>
      <img
        style={{
          maxWidth: '90px',
          maxHeight: '45px',
        }}
        src={fileUrl}
        alt="Immigration Professional Logo"
        ref={logoRef}
        onLoad={handleImageLoad}
      />
    </div>
  );
};
