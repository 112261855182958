import { FormManager } from '@visto-tech/forms';
import { FormSubmitModal } from 'components/DynamicApiForm/FormSubmitModal';
import { Text } from 'elements/Text';
import { toJS } from 'mobx';
import React, { FC, useContext, useState } from 'react';

import Button from '../../elements/Button/Button';
import { GettingStartedQuestionGroupContext } from '../DynamicApiForm/variation-components/GettingStartedQuestionGroupContext';

export const MultiPartFormNavigation: FC<{
  formManager: FormManager<any>;
  formName: string;
  afterOnClick?: () => any;
  setIsReview: (arg: boolean) => void;
}> = ({ formManager, formName, afterOnClick, setIsReview }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const multiStageFormController = useContext(
    GettingStartedQuestionGroupContext
  );

  return (
    <>
      <div className="pt-3">
        {multiStageFormController.isOnLastPage ? (
          <Button
            size="large"
            className="mx-auto"
            onClick={() => {
              formManager
                .validateFields(multiStageFormController.labels)
                .then((_errors) => {
                  const errors = toJS(_errors);

                  if (errors.length > 0) {
                    return;
                  }

                  setIsReview(true);
                  afterOnClick && afterOnClick();
                });
            }}
            type="button"
          >
            Continue
          </Button>
        ) : (
          <Button
            size="large"
            className="w-min mx-auto"
            onClick={(e) => {
              e.preventDefault();

              formManager
                .validateFields(multiStageFormController.labels)
                .then((_errors) => {
                  const errors = toJS(_errors);
                  if (errors.length > 0) return;
                  multiStageFormController.nextPage();
                });

              afterOnClick && afterOnClick();
            }}
            type="button"
          >
            Continue
          </Button>
        )}
        {!multiStageFormController.isOnFirstPage && (
          <Button
            format="basic"
            className="mx-auto !pb-0"
            type="button"
            onClick={(e) => {
              e.preventDefault();

              formManager.clearErrors();
              multiStageFormController.prevPage();
              afterOnClick && afterOnClick();
            }}
          >
            Back
          </Button>
        )}
      </div>
      <FormSubmitModal
        formManager={formManager}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        formName={formName}
      >
        <Text.Paragraph className="text-center mb-6">
          Please ensure that you have carefully reviewed the information before
          submitting the form.
        </Text.Paragraph>
      </FormSubmitModal>
    </>
  );
};
