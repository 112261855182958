import Text from 'elements/Text';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

export const HeadingInput: FC<{ tooltip?: string | null }> = observer(
  ({ children, tooltip }) => {
    return (
      <>
        <Text.Heading
          as="h5"
          className={`fs14 leading-snug font-body font-bold mt-5 w-full flex items-center justify-start heading-input translate ${
            !tooltip && '-mb-2'
          }`}
          variant="none"
        >
          {children}
        </Text.Heading>
        {tooltip && (
          <Text.Paragraph className="fs14 leading-snug mt-1 -mb-2 w-full font-light">
            {tooltip}
          </Text.Paragraph>
        )}
      </>
    );
  }
);
