import User from 'dataLayer/User';
import Link from 'next/link';
import { FC } from 'react';
import { Bell as BellIcon } from 'react-feather';

export const NotificationsIcon: FC<{
  user: User | null;
  className?: string;
}> = ({ user, className }) => {
  const hasNotifications = user?.hasNewNotifications;
  const color = hasNotifications ? `text-visto` : `text-gray-400`;

  if (!user) return null;

  return (
    <Link
      href="/activity"
      className={`bg-white p-1 rounded-full hover:text-gray-800 focus:outline-none flex justify-center items-center ${color} ${className}`}
      id="top-nav-item-activity"
    >
      <span className="sr-only">View notifications</span>
      <span className="relative inline-flex">
        <BellIcon size="24" aria-hidden="true" />
        {hasNotifications && (
          <span className="flex absolute h-3 w-3 top-0.5 right-0.5 -mt-1 -mr-1">
            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-visto-gray-blue opacity-75"></span>
            <span className="relative inline-flex rounded-full h-3 w-3 bg-visto"></span>
          </span>
        )}
      </span>
    </Link>
  );
};
