import { FormManager } from '@visto-tech/forms';
import QuestionRepeaterManager from 'dataLayer/QuestionRepeaterManager';
import { useCallback, useState } from 'react';

import { useAsyncEffect } from './useAsyncEffect';

export const useQuestionRepeater = ({
  initialData,
  structure,
  formManager,
  label,
}: {
  initialData: any;
  structure: Record<string, string | Record<string, string | boolean>>;
  formManager: FormManager<any>;
  label: string;
}) => {
  const [questionManager, setQuestionManager] =
    useState<QuestionRepeaterManager | null>(null);

  const refresh = useCallback(async () => {
    const questionManager = new QuestionRepeaterManager(
      initialData,
      structure,
      formManager,
      label
    );

    setQuestionManager(questionManager);
  }, [structure, formManager, initialData, label]);

  useAsyncEffect(
    async ({ isMounted }) => {
      if (!isMounted) return;
      await refresh();
    },
    [label]
  );

  return {
    questionManager,
  };
};
