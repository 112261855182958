/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-identical-functions */
import { FormManager } from '@visto-tech/forms';
import { InputWithLabel } from '@visto-tech/forms';
import { Button } from 'elements/Button';
import { Checkbox } from 'elements/Forms/Checkbox/Checkbox';
import { DatePickerInputSecondary } from 'elements/Forms/DatePickerInput/DatePickerInputSecondary';
import Loading from 'elements/Loading';
import { Text } from 'elements/Text';
import { useQuestionRepeater } from 'hooks/useQuestionRepeater';
import { observer } from 'mobx-react-lite';
import React, { FC, useEffect } from 'react';
import { EducationBackgroundRepeaterStructure } from 'types/QuestionRepeaterManagerTypes';

interface EducationBackgroundRepeaterPropTypes {
  formManager: FormManager<any>;
  label: string;
}

export const EducationBackgroundRepeater: FC<EducationBackgroundRepeaterPropTypes> =
  observer(({ formManager, label }) => {
    const requiredEl = (
      <span className="italic font-light fs12 ml-0.5">(required)</span>
    );
    const initial = formManager._initialData[label];

    const { questionManager } = useQuestionRepeater({
      initialData: initial ? initial : [],
      structure: {
        school: '',
        degree: '',
        fieldOfStudy: '',
        startDate: '',
        endDate: {
          validate: false,
        },
        description: {
          validate: false,
        },
        currentEdu: {
          validate: false,
        },
      },
      formManager,
      label,
    });

    useEffect(() => {
      if (questionManager && questionManager.getState().length === 0) {
        questionManager.add();
      }
    }, [questionManager]);

    if (!questionManager) return <Loading.Default />;

    formManager.formData[label] = questionManager.stringify();

    return (
      <div className="w-full">
        {questionManager
          .getState()
          .map((edu: EducationBackgroundRepeaterStructure, i: number) => {
            const isLast = questionManager.getState().length === i + 1;

            return (
              <div
                key={i}
                className={`${
                  !isLast && 'mb-8 pb-8 border-b border-visto-gray'
                }`}
              >
                <div className="w-full flex justify-between items-start mb-2">
                  <Text.Paragraph className="fs12 text-gray-400">
                    Education #{i + 1}
                  </Text.Paragraph>
                  <button
                    className="fs12 text-right text-gray-400 hover:text-gray-800"
                    type="button"
                    onClick={() => questionManager.delete(i)}
                  >
                    Delete
                  </button>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-3 md:gap-4 mb-3 md:mb-4">
                  <InputWithLabel
                    key={`id-school-${i}-input-${label}`}
                    id={`id-school-${i}-input-${label}`}
                    name="school"
                    value={edu.school.value ?? ''}
                    error={questionManager.hasError('school', i, formManager)}
                    errorMessage={questionManager.errorMessage(
                      'school',
                      i,
                      formManager
                    )}
                    formFieldSetProps={{
                      className: 'w-full',
                    }}
                    labelProps={{
                      isFocused: true,
                    }}
                    inputProps={
                      {
                        'data-group': i,
                        onChange: questionManager.handleOnChange,
                        type: 'text',
                        placeholder: 'Ex: University of Toronto',
                        isFocused: true,
                        className: 'placeholder:text-slate-400',
                      } as any
                    }
                  >
                    School name {requiredEl}
                  </InputWithLabel>
                  <InputWithLabel
                    key={`id-degree-${i}-input-${label}`}
                    id={`id-degree-${i}-input-${label}`}
                    name="degree"
                    value={edu.degree.value ?? ''}
                    error={questionManager.hasError('degree', i, formManager)}
                    errorMessage={questionManager.errorMessage(
                      'degree',
                      i,
                      formManager
                    )}
                    formFieldSetProps={{
                      className: 'w-full',
                    }}
                    labelProps={{
                      isFocused: true,
                    }}
                    inputProps={
                      {
                        'data-group': i,
                        onChange: questionManager.handleOnChange,
                        type: 'text',
                        placeholder: 'Ex: Honours Bachelor of Science',
                        isFocused: true,
                        className: 'placeholder:text-slate-400',
                      } as any
                    }
                  >
                    Degree {requiredEl}
                  </InputWithLabel>
                  <InputWithLabel
                    key={`id-fieldOfStudy-${i}-input-${label}`}
                    id={`id-fieldOfStudy-${i}-input-${label}`}
                    name="fieldOfStudy"
                    value={edu.fieldOfStudy.value ?? ''}
                    error={questionManager.hasError(
                      'fieldOfStudy',
                      i,
                      formManager
                    )}
                    errorMessage={questionManager.errorMessage(
                      'fieldOfStudy',
                      i,
                      formManager
                    )}
                    formFieldSetProps={{
                      className: 'w-full',
                    }}
                    labelProps={{
                      isFocused: true,
                    }}
                    inputProps={
                      {
                        'data-group': i,
                        onChange: questionManager.handleOnChange,
                        type: 'text',
                        placeholder: 'Ex: Business',
                        isFocused: true,
                        className: 'placeholder:text-slate-400',
                      } as any
                    }
                  >
                    Field of study {requiredEl}
                  </InputWithLabel>
                </div>
                <div className="mb-3">
                  <Checkbox
                    id={`currentEdu-${i}`}
                    name="currentEdu"
                    onChange={(e) => questionManager.handleOnChange(e)}
                    checked={Boolean(edu.currentEdu.value)}
                    data-group={i}
                  >
                    I am currently attending this program
                  </Checkbox>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-4 mb-3 md:mb-4">
                  <DatePickerInputSecondary
                    key={`id-startDate-${i}-select-${label}`}
                    label="startDate"
                    initValue={edu.startDate.value ?? ''}
                    onChange={questionManager.handleOnChange}
                    inputError={questionManager.errorMessage(
                      'startDate',
                      i,
                      formManager
                    )}
                    dateProps={{
                      name: 'startDate',
                      groupId: i,
                    }}
                  >
                    Date started {requiredEl}
                  </DatePickerInputSecondary>
                  <DatePickerInputSecondary
                    key={`id-endDate-${i}-select-${label}`}
                    label="endDate"
                    initValue={edu.endDate.value ?? ''}
                    onChange={questionManager.handleOnChange}
                    inputError={questionManager.errorMessage(
                      'endDate',
                      i,
                      formManager
                    )}
                    dateProps={{
                      name: 'endDate',
                      groupId: i,
                    }}
                    className={`${
                      Boolean(edu.currentEdu.value) &&
                      'opacity-40 pointer-events-none'
                    }`}
                  >
                    Date ended
                  </DatePickerInputSecondary>
                </div>

                <div className="mb-3">
                  <InputWithLabel
                    key={`id-description-${i}-input-${label}`}
                    id={`id-description-${i}-input-${label}`}
                    name="description"
                    value={edu.description.value ?? ''}
                    error={questionManager.hasError(
                      'description',
                      i,
                      formManager
                    )}
                    errorMessage={questionManager.errorMessage(
                      'description',
                      i,
                      formManager
                    )}
                    formFieldSetProps={{
                      className: 'w-full',
                    }}
                    labelProps={{
                      isFocused: true,
                    }}
                    inputProps={
                      {
                        textarea: true,
                        'data-group': i,
                        onChange: questionManager.handleOnChange,
                        type: 'text',
                        isFocused: true,
                      } as any
                    }
                  >
                    Description
                  </InputWithLabel>
                </div>
              </div>
            );
          })}

        <div className="flex justify-center mt-6">
          <Button
            type="button"
            format="basic"
            onClick={() => {
              questionManager.add();
            }}
          >
            Add more education
          </Button>
        </div>
      </div>
    );
  });
