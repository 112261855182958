import { Transition } from '@headlessui/react';
import { FormManager } from '@visto-tech/forms';
import Form from 'dataLayer/Form';
import { QUESTION_INPUT_VARIATIONS } from 'dataLayer/Question';
import { BlockHeader } from 'elements/BlockHeader/BlockHeader';
import { Pill } from 'elements/Pill/Pill';
import { Text } from 'elements/Text';
import Tooltip from 'rc-tooltip';
import React, { FC } from 'react';
import { CheckCircle, HelpCircle } from 'react-feather';

import Button from '../../elements/Button/Button';

interface DynamicFormHeaderPropTypes {
  formManager: FormManager<any>;
  currentStatus?: string;
  hasDraft: boolean;
  form: Form | null;
  setIsModalOpen: (e: boolean) => void;
  disabled?: boolean;
  variation: QUESTION_INPUT_VARIATIONS;
}

export const DynamicFormHeader: FC<DynamicFormHeaderPropTypes> = ({
  formManager,
  currentStatus,
  hasDraft,
  form,
  setIsModalOpen,
  disabled = false,
  variation,
}) => {
  const isBasic = variation === QUESTION_INPUT_VARIATIONS.DYNAMIC_FORM_BASIC;
  const hasNoFormSubmission = currentStatus === undefined;

  const Cta = (
    <Button
      className={`${
        currentStatus == 'DRAFT' || hasDraft ? '' : 'mx-auto md:mx-0'
      }`}
      disabled={!hasDraft}
      onClick={() => {
        formManager.clearErrors();
        setIsModalOpen(true);
      }}
      type="button"
    >
      Save
    </Button>
  );

  return (
    <div
      className={`flex flex-col md:flex-row justify-between items-center w-full bg-white rounded-lg ${
        !isBasic
          ? 'sticky -top-3 md:top-0 z-30 border border-visto-gray px-6 py-4 mb-3'
          : 'mb-5'
      }`}
    >
      <div className="mb-3 md:mb-0 w-full">
        {!isBasic && (
          <Text.Paragraph className="fs12 text-gray-500 translate">
            Form
          </Text.Paragraph>
        )}
        {!isBasic ? (
          <Text.Paragraph className="w-full flex items-center mr-3 font-bold fs14 md:fs18 translate">
            {form?.title}
          </Text.Paragraph>
        ) : (
          <BlockHeader>
            <span className="translate">{form?.title}</span>
          </BlockHeader>
        )}
      </div>
      <div className="flex items-center w-full md:w-auto">
        {!disabled ? (
          <div className="flex items-center justify-between w-full md:w-auto">
            <Transition
              show={!hasNoFormSubmission}
              enter="transition-opacity duration-75"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-150"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <DynamicApiFormStatus
                hasDraft={hasDraft}
                currentStatus={currentStatus as string}
              />
            </Transition>
            {!isBasic && <div>{Cta}</div>}
          </div>
        ) : (
          <Pill
            as="div"
            variation="default-light"
            className="fs12 mr-3 flex justify-center items-center"
          >
            View Only Mode
            <Tooltip
              placement="top"
              overlay={
                <span>
                  Since you have submitted your form, you are no longer able to
                  update it and is in view only mode.
                </span>
              }
            >
              <HelpCircle size={14} className="ml-1 cursor-pointer" />
            </Tooltip>
          </Pill>
        )}
      </div>
    </div>
  );
};

export const DynamicApiFormStatus: FC<{
  currentStatus: string;
  hasDraft: boolean;
  textOnly?: boolean;
}> = ({ currentStatus, hasDraft, textOnly = false }) => {
  const isDraft = currentStatus === 'DRAFT' || hasDraft;

  const Body = (
    <>
      {isDraft ? 'Unsaved Changes' : 'Changes Saved'}
      <Tooltip
        placement="top"
        overlay={
          <span>
            {isDraft
              ? 'All form changes are automatically saved as a draft, so you can come back anytime to complete the form.'
              : 'Your form changes have been saved and published. New changes will be saved as a draft until you save them.'}
          </span>
        }
      >
        {isDraft ? (
          <HelpCircle size={14} className="ml-1 cursor-pointer" />
        ) : (
          <CheckCircle size={14} className="ml-1 cursor-pointer" />
        )}
      </Tooltip>
    </>
  );

  if (textOnly)
    return (
      <Text.Paragraph className="fs12 font-medium flex items-center mr-3">
        {Body}
      </Text.Paragraph>
    );

  return (
    <Pill
      as="div"
      variation={isDraft ? 'warning' : 'light-green'}
      className="fs11 mr-3 flex justify-center items-center"
    >
      {Body}
    </Pill>
  );
};
