import { DeepPartial } from 'utility-types';
import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import { print } from 'graphql'
import gql from 'graphql-tag';
export type Maybe<T> = T extends PromiseLike<infer U> ? Promise<U | null> : T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  Decimal: any;
  JSON: any;
  Upload: any;
};

export type Account = {
  __typename?: 'Account';
  accountPermissionGroups?: Maybe<AccountPermissionGroup>;
  accountSubType?: Maybe<AccountSubType>;
  accountTask?: Maybe<Array<Maybe<AccountTask>>>;
  accountType?: Maybe<AccountType>;
  active?: Maybe<Scalars['Boolean']>;
  applicationDocuments?: Maybe<Array<Maybe<ApplicationDocument>>>;
  checkAddUser?: Maybe<AccountAddUsersData>;
  companyApplications?: Maybe<Array<Maybe<Application>>>;
  companyCompanyTalents?: Maybe<Array<Maybe<CompanyTalent>>>;
  conversations?: Maybe<Array<Maybe<Conversation>>>;
  conversationsOwned?: Maybe<Array<Maybe<Conversation>>>;
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Int']>;
  leadsEmbedId?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  name?: Maybe<Scalars['String']>;
  ownerApplications?: Maybe<Array<Maybe<Application>>>;
  persons?: Maybe<Array<Maybe<Person>>>;
  primaryUser?: Maybe<User>;
  primaryUserId?: Maybe<Scalars['Int']>;
  roles?: Maybe<Array<Maybe<Role>>>;
  settings?: Maybe<Scalars['JSON']>;
  storage?: Maybe<Scalars['JSON']>;
  subscription?: Maybe<AccountSubscriptionData>;
  users?: Maybe<Array<Maybe<User>>>;
  viewed?: Maybe<Scalars['String']>;
};

export type AccountAddUsersData = {
  __typename?: 'AccountAddUsersData';
  canAdd: Scalars['Boolean'];
  usersAllowed: Scalars['Int'];
  usersCount: Scalars['Int'];
};

export type AccountDocument = {
  __typename?: 'AccountDocument';
  account?: Maybe<Account>;
  createdAt?: Maybe<Scalars['Date']>;
  document?: Maybe<Document>;
  documentType?: Maybe<DocumentType>;
  documents?: Maybe<Array<Document>>;
  id?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  templateDocument?: Maybe<TemplateDocument>;
  updatedAt?: Maybe<Scalars['Date']>;
  user?: Maybe<User>;
};

export enum AccountDocumentType {
  Default = 'DEFAULT',
  Retainer = 'RETAINER'
}

export type AccountEmbed = {
  __typename?: 'AccountEmbed';
  account?: Maybe<Account>;
  accountId: Scalars['Int'];
  createdAt: Scalars['Date'];
  form?: Maybe<Form>;
  id: Scalars['Int'];
  key: Scalars['String'];
  updatedAt?: Maybe<Scalars['Date']>;
};

export type AccountPermission = {
  __typename?: 'AccountPermission';
  account?: Maybe<Account>;
  active: Scalars['Boolean'];
  createdAt: Scalars['Date'];
  id: Scalars['Int'];
  permission: Permission;
  updatedAt: Scalars['Date'];
};

export type AccountPermissionGroup = {
  __typename?: 'AccountPermissionGroup';
  account?: Maybe<Account>;
  createdAt: Scalars['Date'];
  id: Scalars['Int'];
  permissionGroup?: Maybe<PermissionGroup>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type AccountSetting = {
  __typename?: 'AccountSetting';
  account?: Maybe<Account>;
  accountId: Scalars['Int'];
  createdAt: Scalars['Date'];
  id: Scalars['Int'];
  setting?: Maybe<Setting>;
  settingId: Scalars['Int'];
  updatedAt: Scalars['Date'];
  value?: Maybe<Scalars['String']>;
};

export type AccountSubType = {
  __typename?: 'AccountSubType';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type AccountSubscriptionData = {
  __typename?: 'AccountSubscriptionData';
  access: AccountSubscriptionDataAccess;
  details?: Maybe<AccountSubscriptionDataDetails>;
  gateWithBilling?: Maybe<Scalars['Boolean']>;
  hasFreeTrial?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isPastDue?: Maybe<Scalars['Boolean']>;
  subscription?: Maybe<Scalars['JSON']>;
  usage?: Maybe<AccountSubscriptionDataUsage>;
};

export type AccountSubscriptionDataAccess = {
  __typename?: 'AccountSubscriptionDataAccess';
  applications: AccountSubscriptionDataAccessFeature;
  customization: AccountSubscriptionDataAccessFeature;
  drafting: AccountSubscriptionDataAccessFeature;
  forms: AccountSubscriptionDataAccessFeature;
  leads: AccountSubscriptionDataAccessFeature;
  mfa: AccountSubscriptionDataAccessFeature;
  representatives: AccountSubscriptionDataAccessFeature;
  tasks: AccountSubscriptionDataAccessFeature;
  templates: AccountSubscriptionDataAccessFeature;
};

export type AccountSubscriptionDataAccessFeature = {
  __typename?: 'AccountSubscriptionDataAccessFeature';
  allowed: Scalars['Boolean'];
};

export type AccountSubscriptionDataDetails = {
  __typename?: 'AccountSubscriptionDataDetails';
  periodEndDate?: Maybe<Scalars['String']>;
  periodStartDate?: Maybe<Scalars['String']>;
};

export type AccountSubscriptionDataUsage = {
  __typename?: 'AccountSubscriptionDataUsage';
  applications: AccountSubscriptionDataUsageFeature;
  representatives: AccountSubscriptionDataUsageFeature;
  retainers: AccountSubscriptionDataUsageFeature;
  storage: AccountSubscriptionDataUsageFeature;
  templates: AccountSubscriptionDataUsageFeature;
  users: AccountSubscriptionDataUsageFeature;
};

export type AccountSubscriptionDataUsageFeature = {
  __typename?: 'AccountSubscriptionDataUsageFeature';
  allowed: Scalars['Int'];
  remaining: Scalars['Int'];
  used: Scalars['Int'];
};

export type AccountTag = {
  __typename?: 'AccountTag';
  account?: Maybe<Account>;
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  order?: Maybe<Scalars['Int']>;
  tag?: Maybe<Tag>;
  updatedAt?: Maybe<Scalars['Date']>;
  years?: Maybe<Scalars['Int']>;
};

export type AccountTask = {
  __typename?: 'AccountTask';
  account?: Maybe<Account>;
  application?: Maybe<Application>;
  applicationId?: Maybe<Scalars['Int']>;
  assigneeUser?: Maybe<User>;
  createdAt: Scalars['Date'];
  deleted?: Maybe<Scalars['Boolean']>;
  dueAt?: Maybe<Scalars['Date']>;
  embedSignature?: Maybe<Scalars['JSON']>;
  feedback?: Maybe<Array<Maybe<Feedback>>>;
  id: Scalars['Int'];
  isDue?: Maybe<Scalars['Boolean']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  key: Scalars['String'];
  meta?: Maybe<Scalars['JSON']>;
  ownerUser?: Maybe<User>;
  person?: Maybe<Person>;
  priority?: Maybe<AccountTaskPriority>;
  signatureRequestSigners?: Maybe<Array<Maybe<SignatureRequestSigners>>>;
  status?: Maybe<AccountTaskStatus>;
  task?: Maybe<Task>;
  updatedAt: Scalars['Date'];
};

export enum AccountTaskPriority {
  High = 'HIGH',
  Low = 'LOW',
  Normal = 'NORMAL'
}

export enum AccountTaskStatus {
  Completed = 'COMPLETED',
  FeedbackRequired = 'FEEDBACK_REQUIRED',
  Pending = 'PENDING',
  Submitted = 'SUBMITTED'
}

export type AccountTaskUpdateData = {
  status?: Maybe<AccountTaskStatus>;
};

export type AccountTaskUpdateWhere = {
  accountId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<AccountTaskStatus>;
};

export type AccountTasksListFilters = {
  assignedToUserId?: Maybe<Scalars['Int']>;
  status?: Maybe<AccountTaskStatus>;
};

export type AccountTasksListReturn = {
  __typename?: 'AccountTasksListReturn';
  count?: Maybe<Scalars['Int']>;
  cursorId?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<AccountTask>>>;
  hasMore?: Maybe<Scalars['Boolean']>;
};

export type AccountType = {
  __typename?: 'AccountType';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type AccountsFilters = {
  type?: Maybe<Scalars['String']>;
};

export type AccountsReturn = {
  __typename?: 'AccountsReturn';
  count?: Maybe<Scalars['JSON']>;
  cursorId?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<Account>>>;
  hasMore?: Maybe<Scalars['Boolean']>;
};

export type ActiveUserPermission = {
  __typename?: 'ActiveUserPermission';
  operation: PermissionOperation;
  resource: Scalars['String'];
};

export type ActivityLog = {
  __typename?: 'ActivityLog';
  account: Account;
  accountId: Scalars['Int'];
  accountTask?: Maybe<AccountTask>;
  accountTaskId?: Maybe<Scalars['Int']>;
  application?: Maybe<Application>;
  applicationDocument?: Maybe<ApplicationDocument>;
  applicationDocumentId?: Maybe<Scalars['Int']>;
  applicationId?: Maybe<Scalars['Int']>;
  comment?: Maybe<Comment>;
  commentId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['Date'];
  details?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  person?: Maybe<Person>;
  personId?: Maybe<Scalars['Int']>;
  type: ActivityLogType;
  url?: Maybe<Scalars['String']>;
  user: User;
  userId: Scalars['Int'];
  userPermission?: Maybe<UserPermission>;
  userPermissionId?: Maybe<Scalars['Int']>;
};

export enum ActivityLogType {
  CreateComment = 'CREATE_COMMENT',
  DeleteTask = 'DELETE_TASK',
  DisableUserPermission = 'DISABLE_USER_PERMISSION',
  EnableUserPermission = 'ENABLE_USER_PERMISSION',
  PublishForm = 'PUBLISH_FORM',
  SignIn = 'SIGN_IN',
  SubmitTask = 'SUBMIT_TASK',
  UploadDocument = 'UPLOAD_DOCUMENT'
}

export type ActivityLogsData = {
  targetAccountId?: Maybe<Scalars['Int']>;
};

export type ActivityLogsReturn = {
  __typename?: 'ActivityLogsReturn';
  count?: Maybe<Scalars['Int']>;
  cursorId?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<ActivityLog>>>;
  hasMore?: Maybe<Scalars['Boolean']>;
};

export enum AdminDeleteAction {
  Person = 'PERSON'
}

export type AdminDeleteData = {
  personId?: Maybe<Scalars['Int']>;
  targetAccountId?: Maybe<Scalars['Int']>;
};

export enum AdminUpdateAction {
  AddPerson = 'ADD_PERSON',
  ConvertCopilotToLawyer = 'CONVERT_COPILOT_TO_LAWYER',
  UpdateCopilotMessage = 'UPDATE_COPILOT_MESSAGE',
  UpdateUserPassword = 'UPDATE_USER_PASSWORD'
}

export type AdminUpdateData = {
  newPassword?: Maybe<Scalars['String']>;
  newPersonDetails?: Maybe<Scalars['JSON']>;
  targetAccountId?: Maybe<Scalars['Int']>;
  targetUserId?: Maybe<Scalars['Int']>;
};

export type Answer = {
  __typename?: 'Answer';
  accountId: Scalars['Int'];
  answer: Scalars['String'];
  answerOptions: Array<AnswerOptions>;
  createdAt: Scalars['Date'];
  formSubmissions?: Maybe<Array<Maybe<FormSubmission>>>;
  groupingId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  person?: Maybe<Person>;
  question?: Maybe<Question>;
  updatedAt?: Maybe<Scalars['Date']>;
  user?: Maybe<User>;
};

export type AnswerOptions = {
  __typename?: 'AnswerOptions';
  answer?: Maybe<Answer>;
  createdAt: Scalars['Date'];
  id: Scalars['Int'];
  type: Scalars['String'];
  updatedAt?: Maybe<Scalars['Date']>;
};

export type AnswersAdminReturn = {
  __typename?: 'AnswersAdminReturn';
  count?: Maybe<Scalars['Int']>;
  cursorId?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Answer>>;
  hasMore?: Maybe<Scalars['Boolean']>;
};

export type Application = {
  __typename?: 'Application';
  accountTasks?: Maybe<Array<Maybe<AccountTask>>>;
  accounts?: Maybe<Array<Maybe<Account>>>;
  applicationItems?: Maybe<Array<Maybe<ApplicationItem>>>;
  applicationTemplate?: Maybe<ApplicationTemplate>;
  applicationTemplateId?: Maybe<Scalars['Int']>;
  assignedUsers?: Maybe<Array<Maybe<User>>>;
  client?: Maybe<ApplicationClient>;
  createdAt?: Maybe<Scalars['Date']>;
  deleted?: Maybe<Scalars['Boolean']>;
  displayName?: Maybe<Scalars['String']>;
  fileExportHistory?: Maybe<Scalars['JSON']>;
  id: Scalars['Int'];
  key: Scalars['String'];
  lawyerAccount?: Maybe<Account>;
  lawyerAccountId?: Maybe<Scalars['Int']>;
  lmiaConditions?: Maybe<ApplicationLmiaConditions>;
  meta?: Maybe<Scalars['JSON']>;
  nonAccompanyingPersonIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  ownerAccount?: Maybe<Account>;
  ownerAccountId?: Maybe<Scalars['Int']>;
  persons?: Maybe<Array<Maybe<Person>>>;
  representative?: Maybe<Representative>;
  reviewEngine?: Maybe<Scalars['JSON']>;
  roles?: Maybe<Array<Maybe<Role>>>;
  status?: Maybe<Status>;
  statuses?: Maybe<Array<Status>>;
  studentDirectStream?: Maybe<Scalars['Boolean']>;
  talent?: Maybe<Array<Maybe<CompanyTalent>>>;
  templateType?: Maybe<ApplicationTemplateType>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type ApplicationClient = {
  __typename?: 'ApplicationClient';
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ApplicationDocument = {
  __typename?: 'ApplicationDocument';
  account?: Maybe<Account>;
  application?: Maybe<Application>;
  applicationId?: Maybe<Scalars['Int']>;
  associatedAccountTask?: Maybe<AccountTask>;
  associations?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
  document?: Maybe<Document>;
  documentId?: Maybe<Scalars['Int']>;
  documents?: Maybe<Array<Document>>;
  feedback?: Maybe<Array<Maybe<Feedback>>>;
  history?: Maybe<Scalars['JSON']>;
  id?: Maybe<Scalars['Int']>;
  meta?: Maybe<Scalars['JSON']>;
  person?: Maybe<Person>;
  personId?: Maybe<Scalars['Int']>;
  supportingDocumentApplications?: Maybe<Array<Application>>;
  templateDocument?: Maybe<TemplateDocument>;
  type?: Maybe<DocumentType>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type ApplicationDocumentsDocumentTypeFilters = {
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  required?: Maybe<Scalars['Boolean']>;
};

export type ApplicationItem = {
  __typename?: 'ApplicationItem';
  application: Application;
  applicationId: Scalars['Int'];
  conditions?: Maybe<Array<Condition>>;
  createdAt: Scalars['Date'];
  documentType?: Maybe<DocumentType>;
  documentTypeId?: Maybe<Scalars['Int']>;
  form?: Maybe<Form>;
  formId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  isOnboardingForm: Scalars['Boolean'];
  isPdfForm: Scalars['Boolean'];
  manualPdf?: Maybe<Scalars['String']>;
  onlineForm?: Maybe<GovernmentOnlineForm>;
  pdf?: Maybe<GovernmentApplicationForm>;
  personTypes: Array<Maybe<PersonType>>;
  stepStatusValue?: Maybe<StatusValue>;
  task?: Maybe<Task>;
  taskId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type ApplicationLmiaConditions = {
  __typename?: 'ApplicationLmiaConditions';
  isRoleFormCompleted?: Maybe<Scalars['Boolean']>;
};

export type ApplicationTemplate = {
  __typename?: 'ApplicationTemplate';
  account?: Maybe<Account>;
  accountId: Scalars['Int'];
  applicationTemplateItems?: Maybe<Array<Maybe<ApplicationTemplateItem>>>;
  createdAt: Scalars['Date'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isMultiStep?: Maybe<Scalars['Boolean']>;
  key: Scalars['String'];
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['Date']>;
};

export type ApplicationTemplateItem = {
  __typename?: 'ApplicationTemplateItem';
  applicationTemplate: ApplicationTemplate;
  applicationTemplateId: Scalars['Int'];
  conditions?: Maybe<Array<Condition>>;
  createdAt: Scalars['Date'];
  documentType?: Maybe<DocumentType>;
  documentTypeId?: Maybe<Scalars['Int']>;
  documentTypes?: Maybe<Array<DocumentType>>;
  form?: Maybe<Form>;
  formId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  isOnboardingForm: Scalars['Boolean'];
  isPdfForm: Scalars['Boolean'];
  manualPdf?: Maybe<Scalars['String']>;
  onlineForm?: Maybe<GovernmentOnlineForm>;
  pdf?: Maybe<GovernmentApplicationForm>;
  personTypes: Array<Maybe<PersonType>>;
  stepStatusValue?: Maybe<StatusValue>;
  task?: Maybe<Task>;
  taskId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type ApplicationTemplateItemCreateCondition = {
  comparativeValue?: Maybe<Scalars['String']>;
  conditionId?: Maybe<Scalars['Int']>;
  determiningProfileAttribute?: Maybe<DeterminingProfileAttribute>;
  operator?: Maybe<Operator>;
  scopeId?: Maybe<Scalars['Int']>;
};

export enum ApplicationTemplateType {
  ExpressEntry = 'EXPRESS_ENTRY',
  HumanitarianAndCompassionate = 'HUMANITARIAN_AND_COMPASSIONATE',
  SpousalSponsorship = 'SPOUSAL_SPONSORSHIP',
  UsaTn = 'USA_TN'
}

export type ApplicationTemplatesReturn = {
  __typename?: 'ApplicationTemplatesReturn';
  count?: Maybe<Scalars['Int']>;
  cursorId?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<ApplicationTemplate>>>;
  hasMore?: Maybe<Scalars['Boolean']>;
};

export enum ApplicationType {
  General = 'GENERAL',
  Gts = 'GTS',
  Lmia = 'LMIA',
  Study = 'STUDY',
  StudyCanada = 'STUDY_CANADA',
  Template = 'TEMPLATE',
  Visitor = 'VISITOR',
  VisitorCanada = 'VISITOR_CANADA',
  WorkPermit = 'WORK_PERMIT',
  WorkPermitCanada = 'WORK_PERMIT_CANADA'
}

export type ApplicationUpdateData = {
  companyTalentId?: Maybe<Scalars['Int']>;
  companyTalentIds?: Maybe<Scalars['String']>;
  coverLetterHtml?: Maybe<Scalars['String']>;
  documentTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  isPersonAccompanying?: Maybe<Scalars['Boolean']>;
  lmiaEmptySlots?: Maybe<Scalars['Int']>;
  lmiaStreamType?: Maybe<Scalars['String']>;
  personId?: Maybe<Scalars['Int']>;
  representativeId?: Maybe<Scalars['Int']>;
  roleId?: Maybe<Scalars['Int']>;
  showEditTemplateModal?: Maybe<Scalars['Boolean']>;
  studentDirectStream?: Maybe<Scalars['Boolean']>;
};

export type ApplicationsFilters = {
  applicationTemplateId?: Maybe<Scalars['Int']>;
  assignedUserIds?: Maybe<Array<Scalars['Int']>>;
  type?: Maybe<ApplicationType>;
};

export type ApplicationsGetData = {
  endDate?: Maybe<Scalars['Date']>;
  orderByNewest?: Maybe<Scalars['Boolean']>;
  startDate?: Maybe<Scalars['Date']>;
  take?: Maybe<Scalars['Int']>;
};

export type ApplicationsReturn = {
  __typename?: 'ApplicationsReturn';
  count?: Maybe<Scalars['Int']>;
  cursorId?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<Application>>>;
  hasMore?: Maybe<Scalars['Boolean']>;
  templates?: Maybe<Array<Maybe<ApplicationTemplate>>>;
};

export enum CastableType {
  Boolean = 'BOOLEAN',
  Null = 'NULL',
  Number = 'NUMBER',
  String = 'STRING'
}

export type Comment = {
  __typename?: 'Comment';
  account?: Maybe<Account>;
  children: Array<Comment>;
  commentAssociations?: Maybe<Array<Maybe<CommentAssociation>>>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Int']>;
  ownedBy?: Maybe<User>;
  ownedById?: Maybe<Scalars['Int']>;
  parent?: Maybe<Comment>;
  parentId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type CommentAssociation = {
  __typename?: 'CommentAssociation';
  accountTask?: Maybe<AccountTask>;
  accountTaskId?: Maybe<Scalars['Int']>;
  application?: Maybe<Application>;
  applicationDocument?: Maybe<ApplicationDocument>;
  applicationDocumentId?: Maybe<Scalars['Int']>;
  applicationId?: Maybe<Scalars['Int']>;
  companyTalent?: Maybe<CompanyTalent>;
  companyTalentId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  role?: Maybe<Role>;
  roleId?: Maybe<Scalars['Int']>;
};

export type CommentsListFilters = {
  applicationId?: Maybe<Scalars['Int']>;
};

export type CommentsListReturn = {
  __typename?: 'CommentsListReturn';
  count?: Maybe<Scalars['Int']>;
  cursorId?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<Comment>>>;
  hasMore?: Maybe<Scalars['Boolean']>;
};

export type CompanyCompliance = {
  __typename?: 'CompanyCompliance';
  account?: Maybe<Account>;
  approvedAt?: Maybe<Scalars['Date']>;
  compliances?: Maybe<Array<Maybe<Compliance>>>;
  createdAt: Scalars['Date'];
  expiresAt?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  nextAuditAt?: Maybe<Scalars['Date']>;
  updatedAt: Scalars['Date'];
};

export type CompanyComplianceUpdateData = {
  approvedAt?: Maybe<Scalars['Date']>;
};

export type CompanyTalent = {
  __typename?: 'CompanyTalent';
  application?: Maybe<Application>;
  applications?: Maybe<Array<Maybe<Application>>>;
  companyAccount?: Maybe<Account>;
  companyAccountId?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  feedback?: Maybe<Array<Maybe<Feedback>>>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  invitation?: Maybe<Invitation>;
  key: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  role?: Maybe<Role>;
  statuses?: Maybe<Array<Maybe<Status>>>;
  talentAccount?: Maybe<Account>;
  talentAccountId?: Maybe<Scalars['Int']>;
  talentType?: Maybe<TalentType>;
};


export type CompanyTalentApplicationArgs = {
  roleKey?: Maybe<Scalars['String']>;
};

export type CompanyTalentUpdateData = {
  countryOfResidence?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  roleId?: Maybe<Scalars['Int']>;
  talentType?: Maybe<TalentType>;
};

export type Compliance = {
  __typename?: 'Compliance';
  commitment: Scalars['String'];
  companyCompliance?: Maybe<CompanyCompliance>;
  createdAt: Scalars['Date'];
  documents: Array<Document>;
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  requiredDocuments: Scalars['String'];
  target: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type Condition = {
  __typename?: 'Condition';
  comparativeValue: Scalars['String'];
  determiningProfileAttribute?: Maybe<DeterminingProfileAttribute>;
  id: Scalars['Int'];
  operator?: Maybe<Operator>;
  scope?: Maybe<Scope>;
  scopeId?: Maybe<Scalars['Int']>;
};

export type ConditionFormula = {
  __typename?: 'ConditionFormula';
  expect?: Maybe<Scalars['String']>;
  operation?: Maybe<Scalars['String']>;
  question?: Maybe<QuestionUniqueIdentifiers>;
};

export enum ContentType {
  DbFaqCompany = 'DB_FAQ_COMPANY',
  DbFaqTalent = 'DB_FAQ_TALENT',
  GenerateCoverLetter = 'GENERATE_COVER_LETTER',
  GenerateStudyPlan = 'GENERATE_STUDY_PLAN',
  PageLmbpGuide = 'PAGE_LMBP_GUIDE',
  UpdateGeneralLetter = 'UPDATE_GENERAL_LETTER',
  UpdateStudyPlan = 'UPDATE_STUDY_PLAN'
}

export type Conversation = {
  __typename?: 'Conversation';
  accountOwner?: Maybe<Account>;
  createdAt?: Maybe<Scalars['Date']>;
  deleted?: Maybe<Scalars['Boolean']>;
  disableNotification?: Maybe<Scalars['Boolean']>;
  disabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  messages: Array<Message>;
  mostRecentMessage?: Maybe<Message>;
  updatedAt?: Maybe<Scalars['Date']>;
  users: Array<User>;
};

export type CreateAccountTaskData = {
  applicationId?: Maybe<Scalars['Int']>;
  assigneeUserId: Scalars['Int'];
  description: Scalars['String'];
  dueAt: Scalars['Date'];
  isPrivate: Scalars['Boolean'];
  lawyerAssociationId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  ownerUserId: Scalars['Int'];
  priority: AccountTaskPriority;
};

export type CreateApplicationMeta = {
  isPurchasingAdditional?: Maybe<Scalars['Boolean']>;
  paymentMethodId?: Maybe<Scalars['String']>;
  primaryApplicantIrccForm?: Maybe<Scalars['String']>;
  streamType?: Maybe<Scalars['String']>;
  supportingDocumentTypes?: Maybe<Array<Scalars['String']>>;
  supportsNotAccompanyingPersons?: Maybe<Scalars['Boolean']>;
  workPermitType?: Maybe<Scalars['String']>;
};

export type CreateQuestionOptions = {
  questionGroupId?: Maybe<Scalars['Int']>;
  questionOptions?: Maybe<Array<Scalars['String']>>;
};

export type CreateTransactionData = {
  invoiceId?: Maybe<Scalars['Int']>;
  method?: Maybe<TransactionPaymentMethod>;
  notes?: Maybe<Scalars['String']>;
};

export type Customer = {
  __typename?: 'Customer';
  account?: Maybe<Account>;
  createdAt: Scalars['Date'];
  id: Scalars['Int'];
  stripeId: Scalars['String'];
  updatedAt?: Maybe<Scalars['Date']>;
};



export enum DeterminingProfileAttribute {
  Age = 'AGE',
  CountryCitizenship = 'COUNTRY_CITIZENSHIP',
  CountryResidence = 'COUNTRY_RESIDENCE',
  IsAccompanying = 'IS_ACCOMPANYING'
}

export type Document = {
  __typename?: 'Document';
  applicationDocument?: Maybe<ApplicationDocument>;
  applicationDocuments?: Maybe<Array<ApplicationDocument>>;
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  signatureRequest?: Maybe<SignatureRequest>;
};

export type DocumentCustomFields = {
  name: Scalars['String'];
  value: Scalars['String'];
};

export type DocumentSigner = {
  email_address: Scalars['String'];
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  role?: Maybe<DocumentSignerRole>;
};

export enum DocumentSignerRole {
  Company = 'Company',
  Talent = 'Talent'
}

export type DocumentType = {
  __typename?: 'DocumentType';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['Int']>;
  countryInstructions?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  instructions?: Maybe<Scalars['JSON']>;
  name: Scalars['String'];
  required: Scalars['Boolean'];
  tip?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export enum DocumentTypeGroup {
  Account = 'ACCOUNT',
  Application = 'APPLICATION',
  Company = 'COMPANY'
}

export type DocumentTypesListFilters = {
  excludeDocumentTypes?: Maybe<Array<Scalars['String']>>;
  isAccountDocuments?: Maybe<Scalars['Boolean']>;
};

export type DocumentTypesListReturn = {
  __typename?: 'DocumentTypesListReturn';
  count?: Maybe<Scalars['Int']>;
  cursorId?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<DocumentType>>>;
  hasMore?: Maybe<Scalars['Boolean']>;
};

export type DocumentTypesUserData = {
  accountId?: Maybe<Scalars['Int']>;
  applicationId?: Maybe<Scalars['Int']>;
  personId?: Maybe<Scalars['Int']>;
};

export type EmbeddedSignUrl = {
  __typename?: 'EmbeddedSignUrl';
  auditUrl?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['Date']>;
  documentUrl?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type EvaluatedCondition = {
  __typename?: 'EvaluatedCondition';
  formula?: Maybe<ConditionFormula>;
  id: Scalars['Int'];
  isParentCondition?: Maybe<Scalars['Boolean']>;
  parentConditions?: Maybe<Array<Maybe<Condition>>>;
  scopeId?: Maybe<Scalars['Int']>;
  value: Scalars['Boolean'];
};

export enum ExportAction {
  Form = 'FORM'
}

export type ExportData = {
  associations?: Maybe<Scalars['JSON']>;
  formId?: Maybe<Scalars['Int']>;
  targetAccountId?: Maybe<Scalars['Int']>;
};

export enum FeatureType {
  CompanyProfile = 'COMPANY_PROFILE',
  TalentProfile = 'TALENT_PROFILE'
}

export type Feedback = {
  __typename?: 'Feedback';
  account: Account;
  accountTask?: Maybe<AccountTask>;
  accountTaskId?: Maybe<Scalars['Int']>;
  application?: Maybe<Application>;
  applicationDocument?: Maybe<ApplicationDocument>;
  companyTalent?: Maybe<CompanyTalent>;
  content: Scalars['String'];
  createdAt: Scalars['Date'];
  id: Scalars['Int'];
  ownedBy: User;
  role?: Maybe<Role>;
  status: FeedbackStatus;
  updatedAt: Scalars['Date'];
};

export enum FeedbackStatus {
  Completed = 'COMPLETED',
  Pending = 'PENDING'
}

export type FeedbacksFilters = {
  accountTaskId?: Maybe<Scalars['Int']>;
  applicationDocumentId?: Maybe<Scalars['Int']>;
  applicationId?: Maybe<Scalars['Int']>;
  companyTalentId?: Maybe<Scalars['Int']>;
  roleId?: Maybe<Scalars['Int']>;
};

export type FeedbacksReturn = {
  __typename?: 'FeedbacksReturn';
  count?: Maybe<Scalars['Int']>;
  cursorId?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<Feedback>>>;
  hasMore?: Maybe<Scalars['Boolean']>;
};

export type Form = {
  __typename?: 'Form';
  accountId?: Maybe<Scalars['Int']>;
  applicationTemplates?: Maybe<Array<Maybe<ApplicationTemplate>>>;
  belongsToRepeaterQuestion?: Maybe<Question>;
  belongsToRepeaterQuestionId?: Maybe<Scalars['Int']>;
  content: Array<QuestionsOrQuestionGroup>;
  description?: Maybe<Scalars['String']>;
  formSubmissions: Array<FormSubmission>;
  groups?: Maybe<Array<Maybe<QuestionGrouping>>>;
  id: Scalars['Int'];
  meta?: Maybe<Scalars['JSON']>;
  name: Scalars['String'];
  questions?: Maybe<Array<Question>>;
  title: Scalars['String'];
};


export type FormFormSubmissionsArgs = {
  associations?: Maybe<Scalars['String']>;
};

export enum FormBuilderGetAction {
  GetGroup = 'GET_GROUP',
  GetQuestions = 'GET_QUESTIONS'
}

export type FormBuilderReturn = {
  __typename?: 'FormBuilderReturn';
  form?: Maybe<Form>;
  forms?: Maybe<Array<Maybe<Form>>>;
  options?: Maybe<Array<Maybe<QuestionOptions>>>;
  questionGroup?: Maybe<QuestionGrouping>;
  questionGroups?: Maybe<Array<Maybe<QuestionGrouping>>>;
  questions?: Maybe<Array<Maybe<Question>>>;
};

export type FormManagerUpdateCreateCondition = {
  comparativeValue: Scalars['String'];
  conditionalQuestionLabel: Scalars['String'];
  determiningQuestionLabel: Scalars['String'];
  operator: Operator;
};

export type FormManagerUpdateCreateForm = {
  name: Scalars['String'];
  title: Scalars['String'];
};

export type FormQueryInput = {
  associations?: Maybe<Scalars['JSON']>;
  groupName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  options?: Maybe<FormQueryInputOptions>;
  targetAccountId?: Maybe<Scalars['Int']>;
};

export type FormQueryInputOptions = {
  excludeFormId?: Maybe<Scalars['Boolean']>;
  formSubmissionStatus?: Maybe<FormSubmissionStatus>;
  onlyMostRecentFormSubmission?: Maybe<Scalars['Boolean']>;
  questionLabelsToRemove?: Maybe<Array<Maybe<Scalars['String']>>>;
  searchTerm?: Maybe<Scalars['String']>;
};

export type FormSubmission = {
  __typename?: 'FormSubmission';
  answers: Array<Answer>;
  associations: Scalars['String'];
  createdAt: Scalars['Date'];
  form?: Maybe<Form>;
  group?: Maybe<QuestionGrouping>;
  id: Scalars['Int'];
  status: Scalars['String'];
  version: Scalars['Int'];
};


export type FormSubmissionAnswersArgs = {
  associations?: Maybe<Scalars['String']>;
};

export enum FormSubmissionGetAction {
  MostRecent = 'MOST_RECENT'
}

export type FormSubmissionGetData = {
  associations?: Maybe<Scalars['JSON']>;
  formName?: Maybe<Scalars['String']>;
  targetAccountId?: Maybe<Scalars['Int']>;
};

export enum FormSubmissionStatus {
  Draft = 'DRAFT',
  Published = 'PUBLISHED'
}

export type FormUpdateData = {
  description?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type FormsFilters = {
  excludeIds?: Maybe<Array<Scalars['Int']>>;
  includeNames?: Maybe<Array<Scalars['String']>>;
  isAccountForms?: Maybe<Scalars['Boolean']>;
};

export type FormsReturn = {
  __typename?: 'FormsReturn';
  count?: Maybe<Scalars['Int']>;
  cursorId?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<Form>>>;
  hasMore?: Maybe<Scalars['Boolean']>;
};

export type GenerateApplicationDocumentsMeta = {
  additionalForms?: Maybe<Array<Maybe<GovernmentApplicationForm>>>;
  creditCardDetails?: Maybe<Scalars['JSON']>;
  familyInformationForm?: Maybe<GovernmentApplicationForm>;
};

export type GetStorageFileReturn = {
  __typename?: 'GetStorageFileReturn';
  storageFile: StorageFile;
  url: Scalars['String'];
};

export enum GovernmentApplicationForm {
  Emp_5575 = 'EMP_5575',
  Emp_5624 = 'EMP_5624',
  Emp_5625 = 'EMP_5625',
  G_28 = 'G_28',
  Imm_1294 = 'IMM_1294',
  Imm_1295 = 'IMM_1295',
  Imm_1344E = 'IMM_1344_E',
  Imm_5257 = 'IMM_5257',
  Imm_5257B1 = 'IMM_5257_B1',
  Imm_5283E = 'IMM_5283_E',
  Imm_5476 = 'IMM_5476',
  Imm_5532E = 'IMM_5532_E',
  Imm_5645E = 'IMM_5645_E',
  Imm_5646E = 'IMM_5646_E',
  Imm_5707 = 'IMM_5707',
  Imm_5708E = 'IMM_5708_E',
  Imm_5709E = 'IMM_5709_E',
  Imm_5710E = 'IMM_5710_E',
  Imm_5713E = 'IMM_5713_E',
  MinorConsent = 'MINOR_CONSENT'
}

export enum GovernmentOnlineForm {
  Ds_160 = 'DS_160',
  Imm_0008E = 'IMM_0008_E',
  PermanentResidentAll = 'PERMANENT_RESIDENT_ALL'
}

export enum HandleAccountEmbedAction {
  GetLeadsFormEmbedId = 'GET_LEADS_FORM_EMBED_ID'
}

export type HandleAccountEmbedData = {
  formName?: Maybe<Scalars['String']>;
};

export enum HandleAccountTaskAction {
  AddToApplication = 'ADD_TO_APPLICATION',
  AddToClient = 'ADD_TO_CLIENT',
  CloneForm = 'CLONE_FORM',
  CreateAdditionalDocuments = 'CREATE_ADDITIONAL_DOCUMENTS',
  UpdateInternalTask = 'UPDATE_INTERNAL_TASK',
  UpdateSupportingDocuments = 'UPDATE_SUPPORTING_DOCUMENTS'
}

export type HandleAccountTaskData = {
  addDocumentTypeNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  applicationId?: Maybe<Scalars['Int']>;
  assigneeUserId?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  documentMessage?: Maybe<Scalars['String']>;
  dueAt?: Maybe<Scalars['Date']>;
  formId?: Maybe<Scalars['Int']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  lawyerAssociationId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  ownerUserId?: Maybe<Scalars['Int']>;
  priority?: Maybe<AccountTaskPriority>;
  removeDocumentTypeNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  requestedByUserId?: Maybe<Scalars['Int']>;
  sendNotification?: Maybe<Scalars['Boolean']>;
  taskId?: Maybe<Scalars['Int']>;
};

export enum HandleApplicationAction {
  ApplicationApproved = 'APPLICATION_APPROVED',
  ApplicationRejected = 'APPLICATION_REJECTED',
  BiometricsCompleted = 'BIOMETRICS_COMPLETED',
  CompletedItaTasks = 'COMPLETED_ITA_TASKS',
  DocumentsReviewed = 'DOCUMENTS_REVIEWED',
  ItaReceived = 'ITA_RECEIVED',
  ProfileCreated = 'PROFILE_CREATED',
  SkipEsign = 'SKIP_ESIGN',
  SubmitApplication = 'SUBMIT_APPLICATION',
  SubmitTasks = 'SUBMIT_TASKS'
}

export type HandleApplicationData = {
  applicationId?: Maybe<Scalars['Int']>;
};

export enum HandleAuthAction {
  LogSignInEvent = 'LOG_SIGN_IN_EVENT',
  RequestResetPassword = 'REQUEST_RESET_PASSWORD',
  ResendVerificationEmail = 'RESEND_VERIFICATION_EMAIL',
  ResetPassword = 'RESET_PASSWORD'
}

export type HandleAuthData = {
  email?: Maybe<Scalars['String']>;
  newPassword?: Maybe<Scalars['String']>;
  newPasswordConfirm?: Maybe<Scalars['String']>;
  recoveryToken?: Maybe<Scalars['String']>;
};

export enum HandleFormBuilderAction {
  AddRemoveGroup = 'ADD_REMOVE_GROUP',
  AddRemoveQuestion = 'ADD_REMOVE_QUESTION',
  CloneGroup = 'CLONE_GROUP',
  CloneQuestion = 'CLONE_QUESTION',
  CreateCondition = 'CREATE_CONDITION',
  DeleteCondition = 'DELETE_CONDITION',
  OrderGroups = 'ORDER_GROUPS',
  OrderQuestions = 'ORDER_QUESTIONS'
}

export type HandleFormBuilderData = {
  conditionData?: Maybe<FormManagerUpdateCreateCondition>;
  conditionId?: Maybe<Scalars['Int']>;
  entityIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  question?: Maybe<HandleFormBuilderDataQuestion>;
  scopeId?: Maybe<Scalars['Int']>;
  subTitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type HandleFormBuilderDataQuestion = {
  label?: Maybe<Scalars['String']>;
  question?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
  tip?: Maybe<Scalars['String']>;
};

export enum HandleLawyerAssociationAction {
  CalculateScore = 'CALCULATE_SCORE',
  Reinstate = 'REINSTATE',
  UpdateAccess = 'UPDATE_ACCESS',
  UpdateEmail = 'UPDATE_EMAIL'
}

export type HandleLawyerAssociationData = {
  email?: Maybe<Scalars['String']>;
};

export enum HandleLeadAction {
  CalculateScore = 'CALCULATE_SCORE',
  ConvertToClient = 'CONVERT_TO_CLIENT'
}

export type HandleLeadData = {
  userIds?: Maybe<Array<Scalars['Int']>>;
};

export enum HandlePublicAction {
  LeadsGoogleSheetSubmission = 'LEADS_GOOGLE_SHEET_SUBMISSION'
}

export type HandlePublicData = {
  googleSheetInput?: Maybe<Scalars['JSON']>;
};

export enum HandleTemplateDocumentAction {
  ConvertDocxToHtml = 'CONVERT_DOCX_TO_HTML',
  Generate = 'GENERATE',
  GetDocumentUrl = 'GET_DOCUMENT_URL',
  PreviewFaker = 'PREVIEW_FAKER',
  PreviewHtml = 'PREVIEW_HTML',
  SendRetainer = 'SEND_RETAINER'
}

export type HandleTemplateDocumentData = {
  applicationId?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['String']>;
  docuSealSubmitters?: Maybe<Scalars['JSON']>;
  docuSealTemplateId?: Maybe<Scalars['Int']>;
  file?: Maybe<Scalars['Upload']>;
  lawyerAssociationId?: Maybe<Scalars['Int']>;
  options?: Maybe<HandleTemplateDocumentDataOptions>;
  personId?: Maybe<Scalars['Int']>;
  representativeId?: Maybe<Scalars['Int']>;
  targetAccountId?: Maybe<Scalars['Int']>;
};

export type HandleTemplateDocumentDataOptions = {
  ignoreSignatureVariables?: Maybe<Scalars['Boolean']>;
};

export type HelloSignEvent = {
  signatureId?: Maybe<Scalars['String']>;
  signatureRequestId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type Invitation = {
  __typename?: 'Invitation';
  accountType?: Maybe<Scalars['String']>;
  companyTalent?: Maybe<CompanyTalent>;
  companyTalentId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Date']>;
  email: Scalars['String'];
  expires: Scalars['Date'];
  id: Scalars['Int'];
  isExpired: Scalars['Boolean'];
  meta?: Maybe<Scalars['JSON']>;
  permissionGroups?: Maybe<Array<Maybe<PermissionGroup>>>;
  slug: Scalars['String'];
  status: Scalars['String'];
  targetAccountId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type InvitationUpdateData = {
  email?: Maybe<Scalars['String']>;
};


export type LawyerAssociation = {
  __typename?: 'LawyerAssociation';
  assignedUsers?: Maybe<Array<Maybe<User>>>;
  associationAccount: Account;
  associationAccountId: Scalars['Int'];
  contactInfo?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['Date'];
  deleted?: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  key: Scalars['String'];
  lawyerAccount: Account;
  lawyerAccountId: Scalars['Int'];
  meta?: Maybe<Scalars['JSON']>;
  updatedAt?: Maybe<Scalars['Date']>;
  welcomeMessage?: Maybe<LawyerAssociationWelcomeMessage>;
};

export type LawyerAssociationWelcomeMessage = {
  __typename?: 'LawyerAssociationWelcomeMessage';
  enabled: Scalars['Boolean'];
  fromName: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

export type LawyerAssociationsFilters = {
  accountType?: Maybe<Scalars['String']>;
};

export type LawyerAssociationsReturn = {
  __typename?: 'LawyerAssociationsReturn';
  count?: Maybe<Scalars['Int']>;
  cursorId?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<LawyerAssociation>>>;
  hasMore?: Maybe<Scalars['Boolean']>;
};

export type Lead = {
  __typename?: 'Lead';
  account: Account;
  accountEmbed?: Maybe<AccountEmbed>;
  accountId: Scalars['Int'];
  createdAt: Scalars['Date'];
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  key: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type LeadsReturn = {
  __typename?: 'LeadsReturn';
  count?: Maybe<Scalars['Int']>;
  cursorId?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<Lead>>>;
  hasMore?: Maybe<Scalars['Boolean']>;
};

export enum MfaLevel {
  Aal1 = 'aal1',
  Aal2 = 'aal2'
}

export type ManualEntryForm = {
  __typename?: 'ManualEntryForm';
  documentTypeName: Scalars['String'];
  id: Scalars['String'];
  lang: Scalars['String'];
  number: Scalars['String'];
  title: Scalars['String'];
  url: Scalars['String'];
};

export type Message = {
  __typename?: 'Message';
  content?: Maybe<Scalars['String']>;
  conversationId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Date']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  ownedBy?: Maybe<User>;
  ownedById?: Maybe<Scalars['Int']>;
  readBy?: Maybe<Array<Maybe<User>>>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type Meta = {
  __typename?: 'Meta';
  id: Scalars['Int'];
  key: Scalars['String'];
  value: Scalars['JSON'];
};

export type Mutation = {
  __typename?: 'Mutation';
  adminDelete?: Maybe<Scalars['Boolean']>;
  adminImpersonateUser?: Maybe<Scalars['String']>;
  adminUpdate?: Maybe<Scalars['Boolean']>;
  completeInvitation?: Maybe<User>;
  createAccountTask?: Maybe<AccountTask>;
  createApplication?: Maybe<Application>;
  createApplicationItem?: Maybe<ApplicationItem>;
  createApplicationTemplate?: Maybe<ApplicationTemplate>;
  createApplicationTemplateItem?: Maybe<ApplicationTemplateItem>;
  createChangeRequest?: Maybe<Scalars['Boolean']>;
  createComment?: Maybe<Scalars['Boolean']>;
  createCompanyTalent?: Maybe<CompanyTalent>;
  createCompliance?: Maybe<Compliance>;
  createConversation?: Maybe<Conversation>;
  createDocumentType?: Maybe<DocumentType>;
  createForm?: Maybe<Form>;
  createLawyerAssociation?: Maybe<LawyerAssociation>;
  createMessage?: Maybe<Message>;
  createNote?: Maybe<Note>;
  createPaymentIntent: PaymentIntent;
  createPermit?: Maybe<Permit>;
  createPerson?: Maybe<Person>;
  createQuestionGrouping?: Maybe<QuestionGrouping>;
  createReferrerCode: ReferrerCode;
  createRepresentative?: Maybe<Representative>;
  createRole: Role;
  createStorageFile?: Maybe<StorageFile>;
  createTemplateDocument?: Maybe<TemplateDocument>;
  deleteAccountTag?: Maybe<Scalars['Boolean']>;
  deleteAccountTask?: Maybe<Scalars['Boolean']>;
  deleteApplication?: Maybe<Scalars['Boolean']>;
  deleteApplicationItem?: Maybe<ApplicationItem>;
  deleteApplicationTemplate?: Maybe<Scalars['Boolean']>;
  deleteApplicationTemplateItem?: Maybe<ApplicationTemplateItem>;
  deleteComment?: Maybe<Scalars['Boolean']>;
  deleteComplianceDocuments?: Maybe<Compliance>;
  deleteDocument?: Maybe<Scalars['Boolean']>;
  deleteDocumentType?: Maybe<DocumentType>;
  deleteForm?: Maybe<Scalars['Boolean']>;
  deleteInvitation?: Maybe<Scalars['Boolean']>;
  deleteLawyerAssociation?: Maybe<Scalars['Boolean']>;
  deleteNote?: Maybe<Scalars['Boolean']>;
  deleteRepresentative?: Maybe<Scalars['Boolean']>;
  deleteRole?: Maybe<Scalars['Boolean']>;
  deleteStorageFile?: Maybe<Scalars['Boolean']>;
  duplicateRole?: Maybe<Role>;
  export?: Maybe<Scalars['JSON']>;
  generateApplicationDocument?: Maybe<ApplicationDocument>;
  generateApplicationDocuments?: Maybe<Scalars['Boolean']>;
  generateContent?: Maybe<Scalars['Boolean']>;
  handleAccountEmbed?: Maybe<Scalars['JSON']>;
  handleAccountTask?: Maybe<Scalars['Boolean']>;
  handleApplication?: Maybe<Scalars['Boolean']>;
  handleAuth?: Maybe<Scalars['JSON']>;
  handleDocuSealWebhook?: Maybe<Scalars['Boolean']>;
  handleFormBuilder?: Maybe<Scalars['Boolean']>;
  handleHelloSignWebhook?: Maybe<Scalars['Boolean']>;
  handleLawyerAssociation?: Maybe<Scalars['Boolean']>;
  handleLead?: Maybe<Scalars['Boolean']>;
  handlePublic?: Maybe<Scalars['JSON']>;
  handleSendGrid?: Maybe<Scalars['Boolean']>;
  handleStripeWebhook?: Maybe<Scalars['Boolean']>;
  handleTemplateDocument?: Maybe<Scalars['JSON']>;
  publicSignUp?: Maybe<PublicSignUpReturn>;
  sendInvitation?: Maybe<Invitation>;
  signApplicationDocuments?: Maybe<Scalars['Boolean']>;
  submitForm?: Maybe<FormSubmission>;
  submitLeadsForm?: Maybe<Scalars['Boolean']>;
  updateAccount?: Maybe<Account>;
  updateAccountDocument?: Maybe<AccountDocument>;
  updateAccountPermission?: Maybe<Scalars['Boolean']>;
  updateAccountSettings?: Maybe<User>;
  updateAccountTag?: Maybe<Scalars['Boolean']>;
  updateAccountTasks?: Maybe<Scalars['Boolean']>;
  updateAnswers?: Maybe<Scalars['Boolean']>;
  updateApplication?: Maybe<Scalars['Boolean']>;
  updateApplicationDocuments?: Maybe<Scalars['Boolean']>;
  updateApplicationItem?: Maybe<ApplicationItem>;
  updateApplicationTemplate?: Maybe<Scalars['Boolean']>;
  updateApplicationTemplateItem?: Maybe<ApplicationTemplateItem>;
  updateChangeRequest?: Maybe<Scalars['Boolean']>;
  updateComment?: Maybe<Scalars['Boolean']>;
  updateCompanyTalent?: Maybe<CompanyTalent>;
  updateCompliance?: Maybe<Scalars['Boolean']>;
  updateConversation?: Maybe<Scalars['Boolean']>;
  updateDocumentType?: Maybe<DocumentType>;
  updateForm?: Maybe<Form>;
  updateInvitation?: Maybe<Invitation>;
  updateMessage?: Maybe<Message>;
  updateMostRecentFormDraft?: Maybe<FormSubmission>;
  updateNote?: Maybe<Note>;
  updateNotification?: Maybe<Scalars['Boolean']>;
  updatePaymentIntent: PaymentIntent;
  updatePermit?: Maybe<Permit>;
  updatePerson?: Maybe<Person>;
  updateQuestionGrouping?: Maybe<Scalars['Boolean']>;
  updateRole?: Maybe<Role>;
  updateStatus?: Maybe<Status>;
  updateTemplateDocument?: Maybe<TemplateDocument>;
  updateUser?: Maybe<Scalars['Boolean']>;
  updateUserPermission?: Maybe<Scalars['Boolean']>;
  updateUsersAccess?: Maybe<UsersAccess>;
  uploadAccountDocument?: Maybe<AccountDocument>;
  uploadApplicationDocument?: Maybe<ApplicationDocument>;
  uploadComplianceDocument?: Maybe<Compliance>;
  upsertCompanyCompliance?: Maybe<CompanyCompliance>;
  upsertQuestion?: Maybe<Question>;
  upsertReminder?: Maybe<Scalars['Boolean']>;
  upsertTask?: Maybe<Task>;
};


export type MutationAdminDeleteArgs = {
  action: AdminDeleteAction;
  data?: Maybe<AdminDeleteData>;
};


export type MutationAdminImpersonateUserArgs = {
  userId: Scalars['Int'];
};


export type MutationAdminUpdateArgs = {
  action: AdminUpdateAction;
  data?: Maybe<AdminUpdateData>;
};


export type MutationCompleteInvitationArgs = {
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};


export type MutationCreateAccountTaskArgs = {
  data: CreateAccountTaskData;
};


export type MutationCreateApplicationArgs = {
  applicationTemplateId?: Maybe<Scalars['Int']>;
  assignedLawyerUserIds?: Maybe<Array<Scalars['Int']>>;
  companyTalentIds?: Maybe<Array<Scalars['Int']>>;
  lawyerAccountId?: Maybe<Scalars['Int']>;
  meta?: Maybe<CreateApplicationMeta>;
  newAccountData?: Maybe<NewAccountData>;
  ownerAccountId?: Maybe<Scalars['Int']>;
  representativeId?: Maybe<Scalars['Int']>;
  roleId?: Maybe<Scalars['Int']>;
  templateType?: Maybe<ApplicationTemplateType>;
  type?: Maybe<ApplicationType>;
};


export type MutationCreateApplicationItemArgs = {
  applicationId: Scalars['Int'];
  documentTypeId?: Maybe<Scalars['Int']>;
  formId?: Maybe<Scalars['Int']>;
  manualPdf?: Maybe<Scalars['String']>;
  onlineForm?: Maybe<GovernmentOnlineForm>;
  pdf?: Maybe<GovernmentApplicationForm>;
  personTypes: Array<PersonType>;
  stepStatusValue?: Maybe<StatusValue>;
  taskId?: Maybe<Scalars['Int']>;
};


export type MutationCreateApplicationTemplateArgs = {
  applicationType?: Maybe<ApplicationType>;
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  templateType?: Maybe<ApplicationTemplateType>;
};


export type MutationCreateApplicationTemplateItemArgs = {
  applicationTemplateId: Scalars['Int'];
  documentTypeId?: Maybe<Scalars['Int']>;
  formId?: Maybe<Scalars['Int']>;
  manualPdf?: Maybe<Scalars['String']>;
  onlineForm?: Maybe<GovernmentOnlineForm>;
  pdf?: Maybe<GovernmentApplicationForm>;
  personTypes: Array<PersonType>;
  stepStatusValue?: Maybe<StatusValue>;
  taskId?: Maybe<Scalars['Int']>;
};


export type MutationCreateChangeRequestArgs = {
  accountId: Scalars['Int'];
  accountTaskId?: Maybe<Scalars['Int']>;
  applicationId?: Maybe<Scalars['Int']>;
  companyTalentId?: Maybe<Scalars['Int']>;
  documentType?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  personId?: Maybe<Scalars['Int']>;
  roleId?: Maybe<Scalars['Int']>;
};


export type MutationCreateCommentArgs = {
  accountTaskId?: Maybe<Scalars['Int']>;
  applicationDocumentId?: Maybe<Scalars['Int']>;
  applicationId?: Maybe<Scalars['Int']>;
  companyTalentId?: Maybe<Scalars['Int']>;
  content: Scalars['String'];
  mentions: Array<Scalars['Int']>;
  parentId?: Maybe<Scalars['Int']>;
  roleId?: Maybe<Scalars['Int']>;
};


export type MutationCreateCompanyTalentArgs = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  meta?: Maybe<Scalars['JSON']>;
  roleId?: Maybe<Scalars['Int']>;
  targetAccountId?: Maybe<Scalars['Int']>;
};


export type MutationCreateComplianceArgs = {
  commitment: Scalars['String'];
  name: Scalars['String'];
  requiredDocuments: Scalars['String'];
  target: Scalars['String'];
};


export type MutationCreateConversationArgs = {
  userIds: Array<Scalars['Int']>;
};


export type MutationCreateDocumentTypeArgs = {
  displayName: Scalars['String'];
  required: Scalars['Boolean'];
  tip: Scalars['String'];
};


export type MutationCreateFormArgs = {
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  templateFormId?: Maybe<Scalars['Int']>;
};


export type MutationCreateLawyerAssociationArgs = {
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};


export type MutationCreateMessageArgs = {
  content: Scalars['String'];
  conversationId: Scalars['Int'];
};


export type MutationCreateNoteArgs = {
  accountDocumentId?: Maybe<Scalars['Int']>;
  accountTaskId?: Maybe<Scalars['Int']>;
  applicationDocumentId?: Maybe<Scalars['Int']>;
  applicationId?: Maybe<Scalars['Int']>;
  companyComplianceId?: Maybe<Scalars['Int']>;
  companyTalentId?: Maybe<Scalars['Int']>;
  content: Scalars['String'];
  lawyerAssociationId?: Maybe<Scalars['Int']>;
  mentionedUserIds?: Maybe<Array<Scalars['Int']>>;
  roleId?: Maybe<Scalars['Int']>;
};


export type MutationCreatePaymentIntentArgs = {
  stripeProductId: Scalars['String'];
};


export type MutationCreatePermitArgs = {
  data: PermitCreateData;
};


export type MutationCreatePersonArgs = {
  accountId?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  meta?: Maybe<Scalars['JSON']>;
  type: PersonType;
};


export type MutationCreateQuestionGroupingArgs = {
  description?: Maybe<Scalars['String']>;
  formId: Scalars['Int'];
  title: Scalars['String'];
};


export type MutationCreateReferrerCodeArgs = {
  code: Scalars['String'];
};


export type MutationCreateRepresentativeArgs = {
  name: Scalars['String'];
};


export type MutationCreateRoleArgs = {
  targetAccountId?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
};


export type MutationCreateStorageFileArgs = {
  file: Scalars['Upload'];
  type: StorageFileType;
};


export type MutationCreateTemplateDocumentArgs = {
  content?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};


export type MutationDeleteAccountTagArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteAccountTaskArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteApplicationArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteApplicationItemArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteApplicationTemplateArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteApplicationTemplateItemArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteCommentArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteComplianceDocumentsArgs = {
  complianceId: Scalars['Int'];
};


export type MutationDeleteDocumentArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteDocumentTypeArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteFormArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteInvitationArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteLawyerAssociationArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteNoteArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteRepresentativeArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteRoleArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteStorageFileArgs = {
  id: Scalars['Int'];
};


export type MutationDuplicateRoleArgs = {
  id: Scalars['Int'];
  title: Scalars['String'];
};


export type MutationExportArgs = {
  action: ExportAction;
  data?: Maybe<ExportData>;
};


export type MutationGenerateApplicationDocumentArgs = {
  applicationId: Scalars['Int'];
  documentName: GovernmentApplicationForm;
  personId?: Maybe<Scalars['Int']>;
};


export type MutationGenerateApplicationDocumentsArgs = {
  applicationId: Scalars['Int'];
  applicationType?: Maybe<ApplicationType>;
  meta?: Maybe<GenerateApplicationDocumentsMeta>;
  talentId?: Maybe<Scalars['Int']>;
};


export type MutationGenerateContentArgs = {
  applicationId?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['String']>;
  documentTypeName?: Maybe<Scalars['String']>;
  personId?: Maybe<Scalars['Int']>;
  targetAccountId?: Maybe<Scalars['Int']>;
  type: ContentType;
};


export type MutationHandleAccountEmbedArgs = {
  action: HandleAccountEmbedAction;
  data?: Maybe<HandleAccountEmbedData>;
};


export type MutationHandleAccountTaskArgs = {
  accountTaskId?: Maybe<Scalars['Int']>;
  action: HandleAccountTaskAction;
  applicationId?: Maybe<Scalars['Int']>;
  data?: Maybe<HandleAccountTaskData>;
  personId?: Maybe<Scalars['Int']>;
  targetAccountId?: Maybe<Scalars['Int']>;
};


export type MutationHandleApplicationArgs = {
  action: HandleApplicationAction;
  data?: Maybe<HandleApplicationData>;
  targetAccountId?: Maybe<Scalars['Int']>;
};


export type MutationHandleAuthArgs = {
  action: HandleAuthAction;
  data?: Maybe<HandleAuthData>;
};


export type MutationHandleDocuSealWebhookArgs = {
  data?: Maybe<Scalars['JSON']>;
};


export type MutationHandleFormBuilderArgs = {
  action: HandleFormBuilderAction;
  data?: Maybe<HandleFormBuilderData>;
  formId?: Maybe<Scalars['Int']>;
  groupId?: Maybe<Scalars['Int']>;
  questionId?: Maybe<Scalars['Int']>;
};


export type MutationHandleHelloSignWebhookArgs = {
  event?: Maybe<HelloSignEvent>;
};


export type MutationHandleLawyerAssociationArgs = {
  action: HandleLawyerAssociationAction;
  data?: Maybe<HandleLawyerAssociationData>;
  lawyerAssociationId: Scalars['Int'];
};


export type MutationHandleLeadArgs = {
  action: HandleLeadAction;
  data?: Maybe<HandleLeadData>;
  leadId: Scalars['Int'];
};


export type MutationHandlePublicArgs = {
  action: HandlePublicAction;
  data?: Maybe<HandlePublicData>;
};


export type MutationHandleSendGridArgs = {
  action: SendGridAction;
  data?: Maybe<SendGridData>;
};


export type MutationHandleStripeWebhookArgs = {
  event?: Maybe<Scalars['JSON']>;
};


export type MutationHandleTemplateDocumentArgs = {
  action: HandleTemplateDocumentAction;
  data?: Maybe<HandleTemplateDocumentData>;
  id: Scalars['Int'];
};


export type MutationPublicSignUpArgs = {
  confirmPassword: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  recaptchaToken: Scalars['String'];
};


export type MutationSendInvitationArgs = {
  accountType?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  permissionGroup?: Maybe<Scalars['String']>;
  resendSlug?: Maybe<Scalars['String']>;
  targetAccountId?: Maybe<Scalars['Int']>;
};


export type MutationSignApplicationDocumentsArgs = {
  applicationId: Scalars['Int'];
  applicationType?: Maybe<ApplicationType>;
  documentsToSignByPerson?: Maybe<Scalars['JSON']>;
  talentId?: Maybe<Scalars['Int']>;
};


export type MutationSubmitFormArgs = {
  args?: Maybe<SubmitFormMutationArgs>;
  formSubmission: SubmitFormMutationInput;
  targetAccountId?: Maybe<Scalars['Int']>;
};


export type MutationSubmitLeadsFormArgs = {
  formSubmission: SubmitFormMutationInput;
  leadEmbedId: Scalars['String'];
  recaptchaToken: Scalars['String'];
};


export type MutationUpdateAccountArgs = {
  accountId?: Maybe<Scalars['Int']>;
  data: Scalars['String'];
  refreshUser?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateAccountDocumentArgs = {
  action: UpdateAccountDocumentAction;
  data?: Maybe<UpdateAccountDocumentData>;
  id: Scalars['Int'];
};


export type MutationUpdateAccountPermissionArgs = {
  active: Scalars['Boolean'];
  permissionId: Scalars['Int'];
  targetAccountId: Scalars['Int'];
};


export type MutationUpdateAccountSettingsArgs = {
  data: Scalars['String'];
  file?: Maybe<Scalars['Upload']>;
};


export type MutationUpdateAccountTagArgs = {
  group?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  tagId?: Maybe<Scalars['Int']>;
  years?: Maybe<Scalars['Int']>;
};


export type MutationUpdateAccountTasksArgs = {
  data: AccountTaskUpdateData;
  where?: Maybe<AccountTaskUpdateWhere>;
};


export type MutationUpdateAnswersArgs = {
  accountId?: Maybe<Scalars['Int']>;
  data: Array<UpdateAnswersData>;
  personId?: Maybe<Scalars['Int']>;
};


export type MutationUpdateApplicationArgs = {
  applicationId: Scalars['Int'];
  data?: Maybe<ApplicationUpdateData>;
  targetAccountId?: Maybe<Scalars['Int']>;
};


export type MutationUpdateApplicationDocumentsArgs = {
  action: UpdateApplicationDocumentsAction;
  data?: Maybe<UpdateApplicationDocumentsData>;
};


export type MutationUpdateApplicationItemArgs = {
  action: UpdateApplicationTemplateItemAction;
  data?: Maybe<UpdateApplicationTemplateItemData>;
  id: Scalars['Int'];
};


export type MutationUpdateApplicationTemplateArgs = {
  data?: Maybe<UpdateApplicationTemplateData>;
  id: Scalars['Int'];
};


export type MutationUpdateApplicationTemplateItemArgs = {
  action: UpdateApplicationTemplateItemAction;
  data?: Maybe<UpdateApplicationTemplateItemData>;
  id: Scalars['Int'];
};


export type MutationUpdateChangeRequestArgs = {
  accountId: Scalars['Int'];
  accountTaskId?: Maybe<Scalars['Int']>;
  targetStatus?: Maybe<AccountTaskStatus>;
};


export type MutationUpdateCommentArgs = {
  content: Scalars['String'];
  id: Scalars['Int'];
};


export type MutationUpdateCompanyTalentArgs = {
  data: CompanyTalentUpdateData;
  id: Scalars['Int'];
};


export type MutationUpdateComplianceArgs = {
  commitment?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  requiredDocuments?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['String']>;
};


export type MutationUpdateConversationArgs = {
  deleted?: Maybe<Scalars['Boolean']>;
  disableNotification?: Maybe<Scalars['Boolean']>;
  disabled?: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  userId?: Maybe<Scalars['Int']>;
};


export type MutationUpdateDocumentTypeArgs = {
  displayName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  required?: Maybe<Scalars['Boolean']>;
  tip?: Maybe<Scalars['String']>;
};


export type MutationUpdateFormArgs = {
  data: FormUpdateData;
  id: Scalars['Int'];
};


export type MutationUpdateInvitationArgs = {
  data: InvitationUpdateData;
  id: Scalars['Int'];
};


export type MutationUpdateMessageArgs = {
  content?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
};


export type MutationUpdateMostRecentFormDraftArgs = {
  accountId?: Maybe<Scalars['Int']>;
  formSubmission: SubmitFormMutationInput;
};


export type MutationUpdateNoteArgs = {
  content: Scalars['String'];
  id: Scalars['Int'];
  mentionedUserIds?: Maybe<Array<Scalars['Int']>>;
};


export type MutationUpdateNotificationArgs = {
  ids: Array<Maybe<Scalars['Int']>>;
  status: Scalars['String'];
};


export type MutationUpdatePaymentIntentArgs = {
  coupon?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  province?: Maybe<Scalars['String']>;
};


export type MutationUpdatePermitArgs = {
  approvedOn: Scalars['Date'];
  expiresOn: Scalars['Date'];
  id: Scalars['Int'];
};


export type MutationUpdatePersonArgs = {
  data?: Maybe<UpdatePersonData>;
  id: Scalars['Int'];
};


export type MutationUpdateQuestionGroupingArgs = {
  data?: Maybe<UpdateQuestionGroupingData>;
  id: Scalars['Int'];
};


export type MutationUpdateRoleArgs = {
  data: RoleUpdateData;
  id: Scalars['Int'];
};


export type MutationUpdateStatusArgs = {
  data: StatusUpdateData;
  id: Scalars['Int'];
};


export type MutationUpdateTemplateDocumentArgs = {
  data: TemplateDocumentUpdateData;
  id: Scalars['Int'];
};


export type MutationUpdateUserArgs = {
  action: UpdateUserAction;
  data?: Maybe<UpdateUserData>;
  targetUserId?: Maybe<Scalars['Int']>;
};


export type MutationUpdateUserPermissionArgs = {
  active: Scalars['Boolean'];
  permissionId: Scalars['Int'];
  userId: Scalars['Int'];
};


export type MutationUpdateUsersAccessArgs = {
  applicationId?: Maybe<Scalars['Int']>;
  lawyerAssociationId?: Maybe<Scalars['Int']>;
  userId: Scalars['Int'];
};


export type MutationUploadAccountDocumentArgs = {
  data?: Maybe<UploadAccountDocumentData>;
  documentTypeName: Scalars['String'];
  file?: Maybe<Scalars['Upload']>;
  files?: Maybe<Array<Scalars['Upload']>>;
  targetAccountId?: Maybe<Scalars['Int']>;
};


export type MutationUploadApplicationDocumentArgs = {
  accountId?: Maybe<Scalars['Int']>;
  applicationId?: Maybe<Scalars['Int']>;
  associations?: Maybe<Scalars['JSON']>;
  document?: Maybe<Scalars['Upload']>;
  documents?: Maybe<Array<Scalars['Upload']>>;
  personId?: Maybe<Scalars['Int']>;
  supportingDocumentApplicationId?: Maybe<Scalars['Int']>;
  typeName: Scalars['String'];
};


export type MutationUploadComplianceDocumentArgs = {
  complianceId: Scalars['Int'];
  documentId?: Maybe<Scalars['Int']>;
  file: Scalars['Upload'];
};


export type MutationUpsertCompanyComplianceArgs = {
  data: CompanyComplianceUpdateData;
};


export type MutationUpsertQuestionArgs = {
  createExplainQuestion?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  options?: Maybe<CreateQuestionOptions>;
  question?: Maybe<Scalars['String']>;
  repeaterQuestionIds?: Maybe<Array<Scalars['Int']>>;
  required?: Maybe<Scalars['Boolean']>;
  tip?: Maybe<Scalars['String']>;
  type?: Maybe<QuestionInputType>;
};


export type MutationUpsertReminderArgs = {
  data: ReminderUpsertData;
  id?: Maybe<Scalars['Int']>;
};


export type MutationUpsertTaskArgs = {
  documentTypeIds?: Maybe<Array<Scalars['Int']>>;
  documentsLinkedToApplication?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  instructions: Scalars['String'];
  required: Scalars['Boolean'];
  title: Scalars['String'];
};

export type NewAccountData = {
  accountType?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type Note = {
  __typename?: 'Note';
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Int']>;
  noteAssociations?: Maybe<Array<NoteAssociation>>;
  ownedBy?: Maybe<User>;
  ownedById?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type NoteAssociation = {
  __typename?: 'NoteAssociation';
  accountTask?: Maybe<AccountTask>;
  accountTaskId?: Maybe<Scalars['Int']>;
  application?: Maybe<Application>;
  applicationDocument?: Maybe<ApplicationDocument>;
  applicationDocumentId?: Maybe<Scalars['Int']>;
  applicationId?: Maybe<Scalars['Int']>;
  companyTalent?: Maybe<CompanyTalent>;
  companyTalentId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type NotesListFilters = {
  applicationId?: Maybe<Scalars['Int']>;
};

export type NotesListReturn = {
  __typename?: 'NotesListReturn';
  count?: Maybe<Scalars['Int']>;
  cursorId?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<Note>>>;
  hasMore?: Maybe<Scalars['Boolean']>;
};

export type Notification = {
  __typename?: 'Notification';
  body?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Int']>;
  status: NotificationStatus;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  url?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export enum NotificationStatus {
  Done = 'DONE',
  Read = 'READ',
  Unread = 'UNREAD'
}

export type NotificationsReturn = {
  __typename?: 'NotificationsReturn';
  count?: Maybe<Scalars['Int']>;
  cursorId?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<Notification>>>;
  hasMore?: Maybe<Scalars['Boolean']>;
};

export enum Operator {
  Contains = 'CONTAINS',
  EqualTo = 'EQUAL_TO',
  GreaterThan = 'GREATER_THAN',
  HasNoValue = 'HAS_NO_VALUE',
  HasValue = 'HAS_VALUE',
  LessThan = 'LESS_THAN',
  NotContains = 'NOT_CONTAINS',
  NotEqualTo = 'NOT_EQUAL_TO'
}

export type PaymentIntent = {
  __typename?: 'PaymentIntent';
  createdAt: Scalars['Date'];
  customer?: Maybe<Customer>;
  id: Scalars['Int'];
  state?: Maybe<Scalars['String']>;
  status?: Maybe<PaymentIntentStatus>;
  stripeCouponCode?: Maybe<Scalars['String']>;
  stripeId: Scalars['String'];
  stripePaymentIntent?: Maybe<StripePaymentIntent>;
  stripeProductId: Scalars['String'];
  stripePromoCode?: Maybe<Scalars['String']>;
  tax?: Maybe<Scalars['JSON']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export enum PaymentIntentStatus {
  Canceled = 'CANCELED',
  Processing = 'PROCESSING',
  RequiresAction = 'REQUIRES_ACTION',
  RequiresCapture = 'REQUIRES_CAPTURE',
  RequiresConfirmation = 'REQUIRES_CONFIRMATION',
  RequiresPaymentMethod = 'REQUIRES_PAYMENT_METHOD',
  Succeeded = 'SUCCEEDED',
  SuccessCouponFree = 'SUCCESS_COUPON_FREE'
}

export type Permission = {
  __typename?: 'Permission';
  category?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  operation: PermissionOperation;
  resource: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  type: PermissionType;
  updatedAt: Scalars['Date'];
};

export type PermissionGroup = {
  __typename?: 'PermissionGroup';
  createdAt: Scalars['Date'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  permissions?: Maybe<Array<Maybe<Permission>>>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export enum PermissionOperation {
  All = 'ALL',
  Create = 'CREATE',
  Delete = 'DELETE',
  Read = 'READ',
  Update = 'UPDATE'
}

export enum PermissionType {
  Account = 'ACCOUNT',
  User = 'USER'
}

export type Permit = {
  __typename?: 'Permit';
  account?: Maybe<Account>;
  applicationId?: Maybe<Scalars['Int']>;
  approvedOn?: Maybe<Scalars['Date']>;
  companyTalent?: Maybe<CompanyTalent>;
  createdAt?: Maybe<Scalars['Date']>;
  expiresOn?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Int']>;
  type?: Maybe<PermitType>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type PermitCreateData = {
  accountId?: Maybe<Scalars['Int']>;
  applicationId?: Maybe<Scalars['Int']>;
  approvedOn: Scalars['Date'];
  companyTalentId?: Maybe<Scalars['Int']>;
  expiresOn?: Maybe<Scalars['Date']>;
  type: PermitType;
};

export enum PermitType {
  Lmia = 'LMIA',
  WorkPermit = 'WORK_PERMIT'
}

export type Person = {
  __typename?: 'Person';
  accountId?: Maybe<Scalars['Int']>;
  accountTasks?: Maybe<Array<Maybe<AccountTask>>>;
  age?: Maybe<Scalars['Int']>;
  applicationDocuments?: Maybe<Array<Maybe<ApplicationDocument>>>;
  applicationId?: Maybe<Scalars['Int']>;
  applications?: Maybe<Array<Maybe<Application>>>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  lastName?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  type?: Maybe<Scalars['String']>;
};

export type PersonInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  type: PersonType;
};

export enum PersonType {
  Applicant = 'APPLICANT',
  Child = 'CHILD',
  ChildAdult = 'CHILD_ADULT',
  ChildTeen = 'CHILD_TEEN',
  Partner = 'PARTNER',
  PartnerLaw = 'PARTNER_LAW',
  PartnerLawStudying = 'PARTNER_LAW_STUDYING',
  PartnerLawWorking = 'PARTNER_LAW_WORKING',
  PartnerStudying = 'PARTNER_STUDYING',
  PartnerWorking = 'PARTNER_WORKING',
  PrimaryContact = 'PRIMARY_CONTACT',
  SecondaryContact = 'SECONDARY_CONTACT'
}

export type Profile = {
  __typename?: 'Profile';
  account?: Maybe<Account>;
  airtableProfileId?: Maybe<Scalars['Int']>;
  completed?: Maybe<ProfileItemsComplete>;
  createdAt: Scalars['Date'];
  id: Scalars['Int'];
  isSavedProfile?: Maybe<Scalars['Boolean']>;
  key: Scalars['String'];
  updatedAt: Scalars['Date'];
  visible?: Maybe<Scalars['Boolean']>;
};

export type ProfileItemsComplete = {
  __typename?: 'ProfileItemsComplete';
  education?: Maybe<Scalars['Boolean']>;
  geographical?: Maybe<Scalars['Boolean']>;
  introduction_text?: Maybe<Scalars['Boolean']>;
  introduction_video?: Maybe<Scalars['Boolean']>;
  overview?: Maybe<Scalars['Boolean']>;
  projects?: Maybe<Scalars['Boolean']>;
  resume?: Maybe<Scalars['Boolean']>;
  skills?: Maybe<Scalars['Boolean']>;
  social?: Maybe<Scalars['Boolean']>;
  workExperience?: Maybe<Scalars['Boolean']>;
};

export type PublicSignUpReturn = {
  __typename?: 'PublicSignUpReturn';
  errorMessage?: Maybe<Scalars['String']>;
  errorName?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export enum PublicSignUpType {
  Applicant = 'APPLICANT',
  TalentProfile = 'TALENT_PROFILE'
}

export type PublicUser = {
  __typename?: 'PublicUser';
  avatarKey?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  Account?: Maybe<Account>;
  AccountDocument?: Maybe<AccountDocument>;
  AccountDocuments?: Maybe<Array<Maybe<AccountDocument>>>;
  AccountPermissions: Array<AccountPermission>;
  AccountSetting?: Maybe<AccountSetting>;
  AccountTags?: Maybe<Array<Maybe<AccountTag>>>;
  AccountTask?: Maybe<AccountTask>;
  AccountTasks: Array<AccountTask>;
  AccountTasksList?: Maybe<AccountTasksListReturn>;
  Accounts?: Maybe<AccountsReturn>;
  ActivityLogs?: Maybe<ActivityLogsReturn>;
  Answers?: Maybe<Array<Maybe<Answer>>>;
  AnswersAdmin?: Maybe<AnswersAdminReturn>;
  Application?: Maybe<Application>;
  ApplicationDocument?: Maybe<ApplicationDocument>;
  ApplicationDocuments?: Maybe<Array<Maybe<ApplicationDocument>>>;
  ApplicationTemplate?: Maybe<ApplicationTemplate>;
  ApplicationTemplates?: Maybe<ApplicationTemplatesReturn>;
  Applications?: Maybe<ApplicationsReturn>;
  Comments: Array<Comment>;
  CommentsList?: Maybe<CommentsListReturn>;
  CompanyCompliance?: Maybe<CompanyCompliance>;
  CompanyTalent?: Maybe<CompanyTalent>;
  CompanyTalents: Array<CompanyTalent>;
  Compliance?: Maybe<Array<Maybe<Compliance>>>;
  Content?: Maybe<Scalars['String']>;
  Conversation?: Maybe<Conversation>;
  Conversations: Array<Conversation>;
  DocumentType?: Maybe<DocumentType>;
  DocumentTypes?: Maybe<Array<Maybe<DocumentType>>>;
  DocumentTypesList?: Maybe<DocumentTypesListReturn>;
  Feedbacks?: Maybe<FeedbacksReturn>;
  Form?: Maybe<Form>;
  FormSubmissions?: Maybe<Array<Maybe<FormSubmission>>>;
  Forms?: Maybe<FormsReturn>;
  Invitation?: Maybe<Invitation>;
  Invitations?: Maybe<Array<Maybe<Invitation>>>;
  LawyerAssociation?: Maybe<LawyerAssociation>;
  LawyerAssociations?: Maybe<LawyerAssociationsReturn>;
  Lead?: Maybe<Lead>;
  Leads?: Maybe<LeadsReturn>;
  LeadsForm?: Maybe<Form>;
  Meta?: Maybe<Meta>;
  Notes: Array<Note>;
  NotesList?: Maybe<NotesListReturn>;
  Notifications?: Maybe<NotificationsReturn>;
  PaymentIntent?: Maybe<PaymentIntent>;
  PaymentIntents: Array<PaymentIntent>;
  PermissionGroups: Array<PermissionGroup>;
  Permissions: Array<Permission>;
  Permit?: Maybe<Permit>;
  Permits?: Maybe<Array<Maybe<Permit>>>;
  Person?: Maybe<Person>;
  Persons?: Maybe<Array<Maybe<Person>>>;
  PublicUser?: Maybe<PublicUser>;
  Question?: Maybe<Question>;
  QuestionGrouping?: Maybe<QuestionGrouping>;
  QuestionGroupings?: Maybe<QuestionGroupingsReturn>;
  QuestionOptions?: Maybe<QuestionOptionsReturn>;
  Questions?: Maybe<QuestionsReturn>;
  Referrer?: Maybe<Referrer>;
  Reminder?: Maybe<Reminder>;
  Representative?: Maybe<Representative>;
  Representatives?: Maybe<RepresentativesReturn>;
  Role?: Maybe<Role>;
  Roles: Array<Role>;
  SignatureRequestSigner?: Maybe<Array<Maybe<SignatureRequestSigners>>>;
  Status?: Maybe<Status>;
  Statuses?: Maybe<Array<Maybe<Status>>>;
  Tags?: Maybe<Array<Maybe<Tag>>>;
  Task?: Maybe<Task>;
  Tasks?: Maybe<TasksReturn>;
  TemplateDocument?: Maybe<TemplateDocument>;
  TemplateDocuments?: Maybe<TemplateDocumentsReturn>;
  User?: Maybe<User>;
  UserExists?: Maybe<Scalars['Boolean']>;
  UserPermissions: Array<Maybe<UserPermission>>;
  Users?: Maybe<Array<Maybe<User>>>;
  antivirusStatus?: Maybe<Scalars['String']>;
  getEmbeddedSignUrl?: Maybe<EmbeddedSignUrl>;
  getHelloSignFiles?: Maybe<Scalars['String']>;
  getLeadEmbed?: Maybe<Scalars['JSON']>;
  getStorageFileUrl?: Maybe<GetStorageFileReturn>;
  getStripeCheckoutUrl?: Maybe<Scalars['String']>;
  getStripeCustomer?: Maybe<Scalars['JSON']>;
  getStripePortalUrl?: Maybe<Scalars['String']>;
  getUrlDocument: Scalars['String'];
  getUrlDocuments: Scalars['String'];
  getUrlDownloadAccountDocument?: Maybe<Scalars['String']>;
  getUrlDownloadApplicationDocument?: Maybe<Scalars['String']>;
  ping?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
};


export type QueryAccountArgs = {
  id?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};


export type QueryAccountDocumentArgs = {
  documentTypeName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  targetAccountId?: Maybe<Scalars['Int']>;
  templateDocumentId?: Maybe<Scalars['Int']>;
};


export type QueryAccountDocumentsArgs = {
  targetAccountId?: Maybe<Scalars['Int']>;
  type?: Maybe<AccountDocumentType>;
};


export type QueryAccountPermissionsArgs = {
  accountId: Scalars['Int'];
};


export type QueryAccountSettingArgs = {
  key: Scalars['String'];
  targetAccountId?: Maybe<Scalars['Int']>;
};


export type QueryAccountTagsArgs = {
  accountId?: Maybe<Scalars['Int']>;
  groups?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryAccountTaskArgs = {
  accountId?: Maybe<Scalars['Int']>;
  applicationId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  isInternal?: Maybe<Scalars['Boolean']>;
  isSupportingDocument?: Maybe<Scalars['Boolean']>;
  key?: Maybe<Scalars['String']>;
  personId?: Maybe<Scalars['Int']>;
};


export type QueryAccountTasksArgs = {
  accountId?: Maybe<Scalars['Int']>;
  applicationId?: Maybe<Scalars['Int']>;
  isInternal?: Maybe<Scalars['Boolean']>;
  isSupportingDocuments?: Maybe<Scalars['Boolean']>;
  personId?: Maybe<Scalars['Int']>;
  status?: Maybe<AccountTaskStatus>;
  taskLabels?: Maybe<Array<Scalars['String']>>;
  type?: Maybe<TaskAction>;
};


export type QueryAccountTasksListArgs = {
  cursor?: Maybe<Scalars['Int']>;
  filters?: Maybe<AccountTasksListFilters>;
  searchTerm?: Maybe<Scalars['String']>;
  sortBy?: Maybe<QuerySortBy>;
};


export type QueryAccountsArgs = {
  cursor?: Maybe<Scalars['Int']>;
  filters?: Maybe<AccountsFilters>;
  searchTerm?: Maybe<Scalars['String']>;
};


export type QueryActivityLogsArgs = {
  cursor?: Maybe<Scalars['Int']>;
  data?: Maybe<ActivityLogsData>;
  searchTerm?: Maybe<Scalars['String']>;
  sortBy?: Maybe<QuerySortBy>;
};


export type QueryAnswersArgs = {
  accountId?: Maybe<Scalars['Int']>;
  associations?: Maybe<Scalars['String']>;
  feature?: Maybe<FeatureType>;
  personId?: Maybe<Scalars['Int']>;
  questionLabels: Array<Scalars['String']>;
  status?: Maybe<FormSubmissionStatus>;
};


export type QueryAnswersAdminArgs = {
  accountId: Scalars['Int'];
  associations?: Maybe<Scalars['String']>;
  cursor?: Maybe<Scalars['Int']>;
  personId?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
  status?: Maybe<FormSubmissionStatus>;
};


export type QueryApplicationArgs = {
  id?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  type?: Maybe<ApplicationType>;
};


export type QueryApplicationDocumentArgs = {
  accountId?: Maybe<Scalars['Int']>;
  applicationId?: Maybe<Scalars['Int']>;
  associations?: Maybe<Scalars['JSON']>;
  documentTypeName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  personId?: Maybe<Scalars['Int']>;
  templateDocumentId?: Maybe<Scalars['Int']>;
};


export type QueryApplicationDocumentsArgs = {
  accountId?: Maybe<Scalars['Int']>;
  accountTaskId?: Maybe<Scalars['Int']>;
  allSupportingDocuments?: Maybe<Scalars['Boolean']>;
  applicationId?: Maybe<Scalars['Int']>;
  associations?: Maybe<Scalars['JSON']>;
  documentTypeFilters?: Maybe<ApplicationDocumentsDocumentTypeFilters>;
  hasDocuments?: Maybe<Scalars['Boolean']>;
  includeTemplateDocuments?: Maybe<Scalars['Boolean']>;
  personId?: Maybe<Scalars['Int']>;
};


export type QueryApplicationTemplateArgs = {
  key: Scalars['String'];
};


export type QueryApplicationTemplatesArgs = {
  cursor?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
  sortBy?: Maybe<QuerySortBy>;
};


export type QueryApplicationsArgs = {
  accountId?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['Int']>;
  data?: Maybe<ApplicationsGetData>;
  filters?: Maybe<ApplicationsFilters>;
  ownerAccountId?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
};


export type QueryCommentsArgs = {
  accountTaskId?: Maybe<Scalars['Int']>;
  applicationDocumentId?: Maybe<Scalars['Int']>;
  applicationId?: Maybe<Scalars['Int']>;
  companyTalentId?: Maybe<Scalars['Int']>;
  roleId?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryCommentsListArgs = {
  cursor?: Maybe<Scalars['Int']>;
  filters?: Maybe<CommentsListFilters>;
  searchTerm?: Maybe<Scalars['String']>;
  sortBy?: Maybe<QuerySortBy>;
};


export type QueryCompanyTalentArgs = {
  accountId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
};


export type QueryCompanyTalentsArgs = {
  accountId?: Maybe<Scalars['Int']>;
  applicationId?: Maybe<Scalars['Int']>;
};


export type QueryContentArgs = {
  type: ContentType;
};


export type QueryConversationArgs = {
  key?: Maybe<Scalars['String']>;
  targetUserId?: Maybe<Scalars['Int']>;
};


export type QueryDocumentTypeArgs = {
  name: Scalars['String'];
};


export type QueryDocumentTypesArgs = {
  names?: Maybe<Array<Maybe<Scalars['String']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeGroup?: Maybe<DocumentTypeGroup>;
  userData?: Maybe<DocumentTypesUserData>;
};


export type QueryDocumentTypesListArgs = {
  cursor?: Maybe<Scalars['Int']>;
  filters?: Maybe<DocumentTypesListFilters>;
  searchTerm?: Maybe<Scalars['String']>;
  sortBy?: Maybe<QuerySortBy>;
};


export type QueryFeedbacksArgs = {
  cursor?: Maybe<Scalars['Int']>;
  filters?: Maybe<FeedbacksFilters>;
  searchTerm?: Maybe<Scalars['String']>;
  sortBy?: Maybe<QuerySortBy>;
};


export type QueryFormArgs = {
  formQuery?: Maybe<FormQueryInput>;
};


export type QueryFormSubmissionsArgs = {
  action?: Maybe<FormSubmissionGetAction>;
  data?: Maybe<FormSubmissionGetData>;
};


export type QueryFormsArgs = {
  cursor?: Maybe<Scalars['Int']>;
  filters?: Maybe<FormsFilters>;
  searchTerm?: Maybe<Scalars['String']>;
  sortBy?: Maybe<QuerySortBy>;
};


export type QueryInvitationArgs = {
  email?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};


export type QueryInvitationsArgs = {
  email?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  targetAccountId?: Maybe<Scalars['Int']>;
};


export type QueryLawyerAssociationArgs = {
  accountId?: Maybe<Scalars['Int']>;
  deleted?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
};


export type QueryLawyerAssociationsArgs = {
  cursor?: Maybe<Scalars['Int']>;
  filters?: Maybe<LawyerAssociationsFilters>;
  searchTerm?: Maybe<Scalars['String']>;
  sortBy?: Maybe<QuerySortBy>;
};


export type QueryLeadArgs = {
  key: Scalars['String'];
};


export type QueryLeadsArgs = {
  cursor?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
  sortBy?: Maybe<QuerySortBy>;
};


export type QueryLeadsFormArgs = {
  formName: Scalars['String'];
};


export type QueryMetaArgs = {
  key: Scalars['String'];
};


export type QueryNotesArgs = {
  accountDocumentId?: Maybe<Scalars['Int']>;
  accountTaskId?: Maybe<Scalars['Int']>;
  applicationDocumentId?: Maybe<Scalars['Int']>;
  applicationId?: Maybe<Scalars['Int']>;
  companyComplianceId?: Maybe<Scalars['Int']>;
  companyTalentId?: Maybe<Scalars['Int']>;
  lawyerAssociationId?: Maybe<Scalars['Int']>;
  roleId?: Maybe<Scalars['Int']>;
};


export type QueryNotesListArgs = {
  cursor?: Maybe<Scalars['Int']>;
  filters?: Maybe<NotesListFilters>;
  searchTerm?: Maybe<Scalars['String']>;
  sortBy?: Maybe<QuerySortBy>;
};


export type QueryNotificationsArgs = {
  applicationId?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['Int']>;
  status?: Maybe<NotificationStatus>;
};


export type QueryPaymentIntentArgs = {
  id: Scalars['Int'];
};


export type QueryPermissionsArgs = {
  type?: Maybe<PermissionType>;
};


export type QueryPermitArgs = {
  accountId?: Maybe<Scalars['Int']>;
  applicationId?: Maybe<Scalars['Int']>;
  companyTalentId?: Maybe<Scalars['Int']>;
  type?: Maybe<PermitType>;
};


export type QueryPermitsArgs = {
  isExpiring?: Maybe<Scalars['Boolean']>;
  type?: Maybe<PermitType>;
};


export type QueryPersonArgs = {
  accountId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  taskLabels?: Maybe<Array<Scalars['String']>>;
  taskStatus?: Maybe<AccountTaskStatus>;
  type?: Maybe<PersonType>;
};


export type QueryPersonsArgs = {
  accountId?: Maybe<Scalars['Int']>;
  applicationId?: Maybe<Scalars['Int']>;
};


export type QueryPublicUserArgs = {
  userId: Scalars['Int'];
};


export type QueryQuestionArgs = {
  label: Scalars['String'];
};


export type QueryQuestionGroupingArgs = {
  id?: Maybe<Scalars['Int']>;
  uniqueKey?: Maybe<Scalars['String']>;
};


export type QueryQuestionGroupingsArgs = {
  cursor?: Maybe<Scalars['Int']>;
  options?: Maybe<QuestionGroupingsOptions>;
  searchTerm?: Maybe<Scalars['String']>;
  sortBy?: Maybe<QuerySortBy>;
};


export type QueryQuestionOptionsArgs = {
  cursor?: Maybe<Scalars['Int']>;
  length?: Maybe<Scalars['Int']>;
  options?: Maybe<QuestionOptionsOptions>;
  searchTerm?: Maybe<Scalars['String']>;
  sortBy?: Maybe<QuerySortBy>;
};


export type QueryQuestionsArgs = {
  cursor?: Maybe<Scalars['Int']>;
  options?: Maybe<QuestionsOptions>;
  searchTerm?: Maybe<Scalars['String']>;
  sortBy?: Maybe<QuerySortBy>;
  sortByColumn?: Maybe<Scalars['String']>;
};


export type QueryReminderArgs = {
  data?: Maybe<ReminderData>;
  id?: Maybe<Scalars['Int']>;
};


export type QueryRepresentativeArgs = {
  key: Scalars['String'];
};


export type QueryRepresentativesArgs = {
  cursor?: Maybe<Scalars['Int']>;
  filters?: Maybe<RepresentativesFilters>;
  searchTerm?: Maybe<Scalars['String']>;
  sortBy?: Maybe<QuerySortBy>;
};


export type QueryRoleArgs = {
  applicationId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};


export type QueryRolesArgs = {
  accountId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};


export type QuerySignatureRequestSignerArgs = {
  signtaureRequestSigners: Array<SignatureRequestSignerData>;
};


export type QueryStatusArgs = {
  applicationId?: Maybe<Scalars['Int']>;
  companyAccountId?: Maybe<Scalars['Int']>;
  companyTalentId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  type?: Maybe<StatusType>;
};


export type QueryStatusesArgs = {
  applicationId: Scalars['Int'];
  type?: Maybe<StatusType>;
};


export type QueryTagsArgs = {
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryTaskArgs = {
  accountTaskKey?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
};


export type QueryTasksArgs = {
  cursor?: Maybe<Scalars['Int']>;
  filters?: Maybe<TasksFilters>;
  searchTerm?: Maybe<Scalars['String']>;
  sortBy?: Maybe<QuerySortBy>;
};


export type QueryTemplateDocumentArgs = {
  key: Scalars['String'];
};


export type QueryTemplateDocumentsArgs = {
  cursor?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
  sortBy?: Maybe<QuerySortBy>;
};


export type QueryUserExistsArgs = {
  email: Scalars['String'];
};


export type QueryUserPermissionsArgs = {
  userId: Scalars['Int'];
};


export type QueryUsersArgs = {
  filters?: Maybe<UsersFilters>;
  targetAccountId?: Maybe<Scalars['Int']>;
};


export type QueryGetEmbeddedSignUrlArgs = {
  signatureRequestId: Scalars['Int'];
};


export type QueryGetHelloSignFilesArgs = {
  signatureRequestId: Scalars['String'];
};


export type QueryGetLeadEmbedArgs = {
  embedId: Scalars['String'];
};


export type QueryGetStorageFileUrlArgs = {
  type: StorageFileType;
};


export type QueryGetStripeCheckoutUrlArgs = {
  priceId: Scalars['String'];
  quantity?: Maybe<Scalars['Int']>;
};


export type QueryGetUrlDocumentArgs = {
  key: Scalars['String'];
};


export type QueryGetUrlDocumentsArgs = {
  accountId?: Maybe<Scalars['Int']>;
  applicationId: Scalars['Int'];
  documentTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  personId?: Maybe<Scalars['Int']>;
};


export type QueryGetUrlDownloadAccountDocumentArgs = {
  documentId?: Maybe<Scalars['Int']>;
  documentTypeName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  merge?: Maybe<Scalars['Boolean']>;
  targetAccountId?: Maybe<Scalars['Int']>;
  templateDocumentId?: Maybe<Scalars['Int']>;
};


export type QueryGetUrlDownloadApplicationDocumentArgs = {
  accountId?: Maybe<Scalars['Int']>;
  applicationId?: Maybe<Scalars['Int']>;
  associations?: Maybe<Scalars['JSON']>;
  documentId?: Maybe<Scalars['Int']>;
  documentTypeName?: Maybe<Scalars['String']>;
  historyDocumentId?: Maybe<Scalars['Int']>;
  merge?: Maybe<Scalars['Boolean']>;
  personId?: Maybe<Scalars['Int']>;
  templateDocumentId?: Maybe<Scalars['Int']>;
};


export type QuerySearchArgs = {
  query: Scalars['String'];
  type: SearchType;
};

export enum QuerySortBy {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Question = {
  __typename?: 'Question';
  accountId?: Maybe<Scalars['Int']>;
  answers: Array<Answer>;
  conditionsRequiredToRenderThisQuestion: Array<EvaluatedCondition>;
  createdAt: Scalars['Date'];
  id: Scalars['Int'];
  inputType: Scalars['String'];
  label: Scalars['String'];
  maxCharacters?: Maybe<Scalars['Int']>;
  ordinal?: Maybe<Scalars['Int']>;
  question: Scalars['String'];
  questionOptions: Array<QuestionOptions>;
  repeaterForm?: Maybe<Form>;
  repeaterFormId?: Maybe<Scalars['Int']>;
  tip?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  validationRules?: Maybe<Scalars['String']>;
};


export type QuestionAnswersArgs = {
  associations?: Maybe<Scalars['String']>;
};


export type QuestionConditionsRequiredToRenderThisQuestionArgs = {
  associations?: Maybe<Scalars['String']>;
};

export type QuestionGrouping = {
  __typename?: 'QuestionGrouping';
  accountId?: Maybe<Scalars['Int']>;
  forms?: Maybe<Array<Maybe<Form>>>;
  id: Scalars['Int'];
  ordinal?: Maybe<Scalars['Int']>;
  questions?: Maybe<Array<Question>>;
  submissionStatus?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  uniqueKey?: Maybe<Scalars['String']>;
};

export type QuestionGroupingsOptions = {
  excludeQuestionGroupIds?: Maybe<Array<Scalars['Int']>>;
  includeKeys?: Maybe<Array<Scalars['String']>>;
  isAccountQuestionGroups?: Maybe<Scalars['Boolean']>;
};

export type QuestionGroupingsReturn = {
  __typename?: 'QuestionGroupingsReturn';
  count?: Maybe<Scalars['Int']>;
  cursorId?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<QuestionGrouping>>>;
  hasMore?: Maybe<Scalars['Boolean']>;
};

export enum QuestionInputType {
  Checkbox = 'CHECKBOX',
  Date = 'DATE',
  Email = 'EMAIL',
  FamilyMembers = 'FAMILY_MEMBERS',
  Heading = 'HEADING',
  MultiCheckbox = 'MULTI_CHECKBOX',
  Number = 'NUMBER',
  Radio = 'RADIO',
  Repeater = 'REPEATER',
  RepeaterChildInfo = 'REPEATER_CHILD_INFO',
  RepeaterEduBackground = 'REPEATER_EDU_BACKGROUND',
  RepeaterEmploymentInfo = 'REPEATER_EMPLOYMENT_INFO',
  RepeaterMilitaryService = 'REPEATER_MILITARY_SERVICE',
  RepeaterPoliticalGroup = 'REPEATER_POLITICAL_GROUP',
  RepeaterPreviousResidence = 'REPEATER_PREVIOUS_RESIDENCE',
  RepeaterPrisonerTreatment = 'REPEATER_PRISONER_TREATMENT',
  RepeaterProjects = 'REPEATER_PROJECTS',
  RepeaterWorkExperience = 'REPEATER_WORK_EXPERIENCE',
  Select = 'SELECT',
  Text = 'TEXT',
  Textarea = 'TEXTAREA',
  Wysiwyg = 'WYSIWYG'
}

export type QuestionOptions = {
  __typename?: 'QuestionOptions';
  accounts?: Maybe<Array<Maybe<Account>>>;
  group?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  option: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
};

export type QuestionOptionsOptions = {
  excludeQuestionOptionIds?: Maybe<Array<Scalars['Int']>>;
  groupName?: Maybe<Scalars['String']>;
  optionValues?: Maybe<Array<Scalars['String']>>;
  questionLabel?: Maybe<Scalars['String']>;
};

export type QuestionOptionsReturn = {
  __typename?: 'QuestionOptionsReturn';
  count?: Maybe<Scalars['Int']>;
  cursorId?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<QuestionOptions>>>;
  hasMore?: Maybe<Scalars['Boolean']>;
};

export type QuestionUniqueIdentifiers = {
  __typename?: 'QuestionUniqueIdentifiers';
  id: Scalars['Int'];
  label: Scalars['String'];
};

export type QuestionsOptions = {
  excludeInputTypes?: Maybe<Array<QuestionInputType>>;
  excludeQuestionIds?: Maybe<Array<Scalars['Int']>>;
  filterByInputType?: Maybe<QuestionInputType>;
  includeQuestionLabels?: Maybe<Array<Scalars['String']>>;
  isAccountQuestions?: Maybe<Scalars['Boolean']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type QuestionsOrQuestionGroup = Question | QuestionGrouping;

export type QuestionsReturn = {
  __typename?: 'QuestionsReturn';
  count?: Maybe<Scalars['Int']>;
  cursorId?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<Question>>>;
  hasMore?: Maybe<Scalars['Boolean']>;
};

export type Referrer = {
  __typename?: 'Referrer';
  account?: Maybe<Account>;
  createdAt: Scalars['Date'];
  id: Scalars['Int'];
  invitedBy?: Maybe<Referrer>;
  invitedByReferrerId?: Maybe<Scalars['Int']>;
  invites?: Maybe<Array<Referrer>>;
  referrerCodes?: Maybe<Array<ReferrerCode>>;
  referrerConversions?: Maybe<Array<ReferrerConversion>>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type ReferrerCode = {
  __typename?: 'ReferrerCode';
  code?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  id: Scalars['Int'];
  updatedAt?: Maybe<Scalars['Date']>;
};

export type ReferrerConversion = {
  __typename?: 'ReferrerConversion';
  createdAt: Scalars['Date'];
  id: Scalars['Int'];
  paymentIntent?: Maybe<PaymentIntent>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type Reminder = {
  __typename?: 'Reminder';
  accountId: Scalars['Int'];
  active: Scalars['Boolean'];
  cadenceDaysAfter?: Maybe<Scalars['JSON']>;
  cadenceDaysBefore?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['Date'];
  id: Scalars['Int'];
  type: ReminderType;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type ReminderData = {
  invoiceId?: Maybe<Scalars['Int']>;
  type: ReminderType;
};

export enum ReminderType {
  ApplicationsTasks = 'APPLICATIONS_TASKS',
  InvoiceDue = 'INVOICE_DUE'
}

export type ReminderUpsertData = {
  cadenceDaysAfter?: Maybe<Scalars['JSON']>;
  cadenceDaysBefore?: Maybe<Scalars['JSON']>;
  invoiceId?: Maybe<Scalars['Int']>;
  type: ReminderType;
};

export type Representative = {
  __typename?: 'Representative';
  accountId: Scalars['Int'];
  active: Scalars['Boolean'];
  applications?: Maybe<Array<Maybe<Application>>>;
  createdAt: Scalars['Date'];
  deleted: Scalars['Boolean'];
  documentId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  key: Scalars['String'];
  meta?: Maybe<Scalars['JSON']>;
  name: Scalars['String'];
  signatureDocumentId?: Maybe<Scalars['Int']>;
  signatureDocumentUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type RepresentativesFilters = {
  active?: Maybe<Scalars['Boolean']>;
  excludeIds?: Maybe<Array<Scalars['Int']>>;
};

export type RepresentativesReturn = {
  __typename?: 'RepresentativesReturn';
  count?: Maybe<Scalars['Int']>;
  cursorId?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<Representative>>>;
  hasMore?: Maybe<Scalars['Boolean']>;
};

export type Role = {
  __typename?: 'Role';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['Int']>;
  application?: Maybe<Application>;
  applications?: Maybe<Array<Maybe<Application>>>;
  companyTalents?: Maybe<Array<Maybe<CompanyTalent>>>;
  createdAt: Scalars['Date'];
  feedback?: Maybe<Array<Maybe<Feedback>>>;
  id: Scalars['Int'];
  key: Scalars['String'];
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['Date']>;
};

export type RoleUpdateData = {
  title?: Maybe<Scalars['String']>;
};

export type Scope = {
  __typename?: 'Scope';
  conditions?: Maybe<Array<Condition>>;
  id: Scalars['Int'];
  scope: Scalars['Int'];
  value: Scopes;
};

export enum Scopes {
  ApplicationItem = 'APPLICATION_ITEM',
  ApplicationTemplateItem = 'APPLICATION_TEMPLATE_ITEM',
  Form = 'FORM',
  Group = 'GROUP'
}

export enum SearchType {
  Account = 'ACCOUNT',
  Articles = 'ARTICLES'
}

export type SendEmailData = {
  fromEmail?: Maybe<Scalars['String']>;
  fromName?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  toEmail?: Maybe<Scalars['String']>;
};

export enum SendGridAction {
  AddToList = 'ADD_TO_LIST'
}

export type SendGridData = {
  contact?: Maybe<SendGridDataContact>;
  listLabel?: Maybe<Scalars['String']>;
};

export type SendGridDataContact = {
  customFields?: Maybe<Scalars['JSON']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type Setting = {
  __typename?: 'Setting';
  accountSettings: Array<Maybe<AccountSetting>>;
  id: Scalars['Int'];
  key: Scalars['String'];
};

export type Signature = {
  __typename?: 'Signature';
  signature_id: Scalars['String'];
  signer_email_address: Scalars['String'];
  signer_name: Scalars['String'];
  status_code: Scalars['String'];
};

export type SignatureRequest = {
  __typename?: 'SignatureRequest';
  createdAt?: Maybe<Scalars['Date']>;
  details?: Maybe<Scalars['JSON']>;
  id: Scalars['Int'];
  signatureRequestId: Scalars['String'];
  signatureRequestSigners?: Maybe<Array<Maybe<SignatureRequestSigners>>>;
  type?: Maybe<SignatureRequestType>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type SignatureRequestSignerData = {
  accountId?: Maybe<Scalars['Int']>;
  personId?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
  userId?: Maybe<Scalars['Int']>;
};

export type SignatureRequestSigners = {
  __typename?: 'SignatureRequestSigners';
  createdAt: Scalars['Date'];
  email: Scalars['String'];
  id: Scalars['Int'];
  signatureId: Scalars['String'];
  signatureRequest?: Maybe<SignatureRequest>;
  status: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export enum SignatureRequestStatus {
  AwaitingSignature = 'AWAITING_SIGNATURE',
  Signed = 'SIGNED'
}

export type SignatureRequestType = {
  __typename?: 'SignatureRequestType';
  id: Scalars['Int'];
  message: Scalars['String'];
  name: Scalars['String'];
  signatureRequest?: Maybe<Array<Maybe<SignatureRequest>>>;
  subject: Scalars['String'];
  taskAssociation?: Maybe<Array<Maybe<TaskAssociation>>>;
  templateId?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type Status = {
  __typename?: 'Status';
  application?: Maybe<Application>;
  applicationId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['Date'];
  deleted?: Maybe<Scalars['Boolean']>;
  documentsToSign?: Maybe<Scalars['JSON']>;
  history?: Maybe<Scalars['JSON']>;
  id: Scalars['Int'];
  ownerAccount?: Maybe<Account>;
  ownerAccountId?: Maybe<Scalars['Int']>;
  requirements?: Maybe<Scalars['JSON']>;
  talent?: Maybe<CompanyTalent>;
  type: StatusType;
  updatedAt: Scalars['Date'];
  value: StatusValue;
};

export enum StatusType {
  General = 'GENERAL',
  Lmia = 'LMIA',
  StudyPermit = 'STUDY_PERMIT',
  StudyPermitCanada = 'STUDY_PERMIT_CANADA',
  TemplateDocuments = 'TEMPLATE_DOCUMENTS',
  TemplateGeneral = 'TEMPLATE_GENERAL',
  TemplateMultiStep = 'TEMPLATE_MULTI_STEP',
  VisitorVisa = 'VISITOR_VISA',
  VisitorVisaCanada = 'VISITOR_VISA_CANADA',
  WorkPermit = 'WORK_PERMIT',
  WorkPermitCanada = 'WORK_PERMIT_CANADA'
}

export type StatusUpdateData = {
  value?: Maybe<StatusValue>;
};

export enum StatusValue {
  Approved = 'APPROVED',
  BiometricsSubmitted = 'BIOMETRICS_SUBMITTED',
  Cancelled = 'CANCELLED',
  CompletedDefaultTasks = 'COMPLETED_DEFAULT_TASKS',
  CompletedItaTasks = 'COMPLETED_ITA_TASKS',
  DocumentsGenerated = 'DOCUMENTS_GENERATED',
  FinalReview = 'FINAL_REVIEW',
  InitialReview = 'INITIAL_REVIEW',
  ItaReceived = 'ITA_RECEIVED',
  Landed = 'LANDED',
  OnboardingCompleted = 'ONBOARDING_COMPLETED',
  Preparation = 'PREPARATION',
  ProfileCreated = 'PROFILE_CREATED',
  Purchased = 'PURCHASED',
  Registered = 'REGISTERED',
  Rejected = 'REJECTED',
  SentSigning = 'SENT_SIGNING',
  SkipBiometrics = 'SKIP_BIOMETRICS',
  SkipSigning = 'SKIP_SIGNING',
  Submitted = 'SUBMITTED'
}

export type StorageFile = {
  __typename?: 'StorageFile';
  account?: Maybe<Account>;
  accountId: Scalars['Int'];
  createdAt: Scalars['Date'];
  deleted?: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  key: Scalars['String'];
  type: StorageFileType;
  updatedAt?: Maybe<Scalars['Date']>;
};

export enum StorageFileType {
  LawyerAssociationLogo = 'LAWYER_ASSOCIATION_LOGO',
  WelcomeMessage = 'WELCOME_MESSAGE'
}

export type StripePaymentIntent = {
  __typename?: 'StripePaymentIntent';
  amount: Scalars['Int'];
  clientSecret: Scalars['String'];
  couponAmount?: Maybe<Scalars['Int']>;
  couponIsPercentage?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  productAmount: Scalars['Int'];
  productName: Scalars['String'];
  receiptUrl: Scalars['String'];
  status: Scalars['String'];
};

export type SubmitFormMutationArgs = {
  accountTaskId?: Maybe<Scalars['Int']>;
  applicationId?: Maybe<Scalars['Int']>;
  applicationType?: Maybe<ApplicationType>;
};

export type Subscription = {
  __typename?: 'Subscription';
  ActiveUser?: Maybe<User>;
  Conversation?: Maybe<Conversation>;
  ping?: Maybe<Scalars['String']>;
};


export type SubscriptionConversationArgs = {
  id?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
};

export type TableFilter = {
  __typename?: 'TableFilter';
  account?: Maybe<Account>;
  accountId: Scalars['Int'];
  createdAt: Scalars['Date'];
  filter: Scalars['String'];
  id: Scalars['Int'];
  label: Scalars['String'];
  tableType: TableType;
  updatedAt: Scalars['Date'];
};

export enum TableType {
  FindTalent = 'FIND_TALENT'
}

export type Tag = {
  __typename?: 'Tag';
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  tagGroups?: Maybe<Array<Maybe<TagGroup>>>;
  updatedAt?: Maybe<Scalars['Date']>;
  value: Scalars['String'];
};

export type TagGroup = {
  __typename?: 'TagGroup';
  createdAt?: Maybe<Scalars['Date']>;
  group: Scalars['String'];
  id: Scalars['Int'];
  updatedAt?: Maybe<Scalars['Date']>;
};

export type TalentConnect = {
  __typename?: 'TalentConnect';
  Profile: Profile;
  account: Account;
  createdAt: Scalars['Date'];
  id: Scalars['Int'];
  message?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Date'];
};

export enum TalentType {
  Candidate = 'CANDIDATE',
  Employee = 'EMPLOYEE'
}

export type Task = {
  __typename?: 'Task';
  accountId?: Maybe<Scalars['Int']>;
  action: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  label: Scalars['String'];
  meta?: Maybe<Scalars['JSON']>;
  required: Scalars['Boolean'];
  taskAssociation?: Maybe<TaskAssociation>;
  title?: Maybe<Scalars['String']>;
};

export enum TaskAction {
  CompleteExternal = 'COMPLETE_EXTERNAL',
  CompleteForm = 'COMPLETE_FORM',
  Custom = 'CUSTOM',
  Internal = 'INTERNAL',
  InternalLink = 'INTERNAL_LINK',
  Letter = 'LETTER',
  ManualDownloadUpload = 'MANUAL_DOWNLOAD_UPLOAD',
  SignDocumentEmail = 'SIGN_DOCUMENT_EMAIL',
  SignDocumentEmbed = 'SIGN_DOCUMENT_EMBED',
  UploadDocument = 'UPLOAD_DOCUMENT'
}

export type TaskAssociation = {
  __typename?: 'TaskAssociation';
  application?: Maybe<Application>;
  documentTypes?: Maybe<Array<Maybe<DocumentType>>>;
  forms?: Maybe<Array<Maybe<Form>>>;
  id: Scalars['Int'];
  lawyerAssociation?: Maybe<LawyerAssociation>;
  signatureRequestTypes?: Maybe<Array<Maybe<SignatureRequestType>>>;
};

export type TasksFilters = {
  excludeIds?: Maybe<Array<Scalars['Int']>>;
  includeLabels?: Maybe<Array<Scalars['String']>>;
  isAccountForms?: Maybe<Scalars['Boolean']>;
};

export type TasksReturn = {
  __typename?: 'TasksReturn';
  count?: Maybe<Scalars['Int']>;
  cursorId?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<Task>>>;
  hasMore?: Maybe<Scalars['Boolean']>;
};

export type TemplateDocument = {
  __typename?: 'TemplateDocument';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  key: Scalars['String'];
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['Date']>;
};

export type TemplateDocumentUpdateData = {
  content?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type TemplateDocumentsReturn = {
  __typename?: 'TemplateDocumentsReturn';
  count?: Maybe<Scalars['Int']>;
  cursorId?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<TemplateDocument>>>;
  hasMore?: Maybe<Scalars['Boolean']>;
};

export enum TransactionEntryType {
  Credit = 'CREDIT',
  Debit = 'DEBIT'
}

export enum TransactionPaymentMethod {
  BankPayment = 'BANK_PAYMENT',
  Cash = 'CASH',
  Cheque = 'CHEQUE',
  CreditCard = 'CREDIT_CARD',
  Other = 'OTHER'
}

export enum TransactionType {
  InvoicePayment = 'INVOICE_PAYMENT'
}

export enum UpdateAccountDocumentAction {
  Delete = 'DELETE',
  OrderDocuments = 'ORDER_DOCUMENTS',
  ResendSignatureEmail = 'RESEND_SIGNATURE_EMAIL'
}

export type UpdateAccountDocumentData = {
  documentsOrdered?: Maybe<Array<Scalars['Int']>>;
  signatureRequestId?: Maybe<Scalars['Int']>;
};

export type UpdateAnswersData = {
  answerValue: Scalars['String'];
  associations?: Maybe<Scalars['JSON']>;
  questionLabel: Scalars['String'];
};

export enum UpdateApplicationDocumentsAction {
  OrderDocuments = 'ORDER_DOCUMENTS'
}

export type UpdateApplicationDocumentsData = {
  applicationDocumentId?: Maybe<Scalars['Int']>;
  documentsOrdered?: Maybe<Array<Scalars['Int']>>;
  targetAccountId?: Maybe<Scalars['Int']>;
};

export type UpdateApplicationTemplateData = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export enum UpdateApplicationTemplateItemAction {
  AddCondition = 'ADD_CONDITION',
  DeleteCondition = 'DELETE_CONDITION'
}

export type UpdateApplicationTemplateItemData = {
  condition?: Maybe<ApplicationTemplateItemCreateCondition>;
};

export type UpdatePersonData = {
  applicationId?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  isANotAccompanyingPerson?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
};

export type UpdateQuestionGroupingData = {
  subTitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  uniqueKey?: Maybe<Scalars['String']>;
};

export enum UpdateUserAction {
  Disable = 'DISABLE',
  Enable = 'ENABLE',
  MfaEnroll = 'MFA_ENROLL',
  MfaUnenroll = 'MFA_UNENROLL',
  Name = 'NAME',
  Viewed = 'VIEWED'
}

export type UpdateUserData = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  mfaFactorId?: Maybe<Scalars['String']>;
  mfaTopt?: Maybe<Scalars['String']>;
  viewedProperty?: Maybe<Scalars['String']>;
  viewedValue?: Maybe<Scalars['Boolean']>;
};


export type UploadAccountDocumentData = {
  representativeId?: Maybe<Scalars['Int']>;
};

export type User = {
  __typename?: 'User';
  account: Account;
  active?: Maybe<Scalars['Boolean']>;
  assignedApplications?: Maybe<Array<Maybe<Application>>>;
  avatarKey?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  hasCompletedGettingStarted?: Maybe<Scalars['Boolean']>;
  hasLawyerAssociation?: Maybe<Scalars['Boolean']>;
  hasNewNotifications?: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  isPrimaryUser?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  messages?: Maybe<Array<Maybe<Message>>>;
  mfaActive?: Maybe<Scalars['Boolean']>;
  permissionGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  permissions?: Maybe<Array<Maybe<ActiveUserPermission>>>;
  productsPurchased?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['Date']>;
  userPermissions?: Maybe<Array<Maybe<ActiveUserPermission>>>;
  viewed?: Maybe<Scalars['JSON']>;
};

export type UserPermission = {
  __typename?: 'UserPermission';
  active: Scalars['Boolean'];
  createdAt: Scalars['Date'];
  id: Scalars['Int'];
  permission: Permission;
  updatedAt?: Maybe<Scalars['Date']>;
  user?: Maybe<User>;
};

export type UsersAccess = {
  __typename?: 'UsersAccess';
  accountId: Scalars['Int'];
  applicationId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['Date'];
  id: Scalars['Int'];
  lawyerAssociationId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type UsersFilters = {
  assignedToAccountId?: Maybe<Scalars['Int']>;
  includeAllStatuses?: Maybe<Scalars['Boolean']>;
};

export type SubmitFormMutationAnswers = {
  answer?: Maybe<Scalars['String']>;
  answerGroupingId?: Maybe<Scalars['Int']>;
  questionLabel?: Maybe<Scalars['String']>;
  repeaterFormQuestionLabel?: Maybe<Scalars['String']>;
};

export type SubmitFormMutationInput = {
  answers: Array<SubmitFormMutationAnswers>;
  associations?: Maybe<Scalars['String']>;
  formName: Scalars['String'];
  groupName?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  talentId?: Maybe<Scalars['Int']>;
};

export type AccountsQueryVariables = Exact<{
  cursor?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
  filters?: Maybe<AccountsFilters>;
}>;


export type AccountsQuery = (
  { __typename?: 'Query' }
  & { Accounts?: Maybe<(
    { __typename?: 'AccountsReturn' }
    & Pick<AccountsReturn, 'cursorId' | 'hasMore' | 'count'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'Account' }
      & Pick<Account, 'id' | 'name' | 'active' | 'createdAt'>
      & { accountType?: Maybe<(
        { __typename?: 'AccountType' }
        & Pick<AccountType, 'id' | 'name'>
      )>, primaryUser?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'firstName' | 'lastName'>
      )>, users?: Maybe<Array<Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'firstName' | 'lastName'>
      )>>> }
    )>>> }
  )> }
);

export type AccountQueryVariables = Exact<{
  accountId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
}>;


export type AccountQuery = (
  { __typename?: 'Query' }
  & { Account?: Maybe<(
    { __typename?: 'Account' }
    & Pick<Account, 'id' | 'name' | 'active' | 'createdAt' | 'meta' | 'viewed' | 'settings' | 'leadsEmbedId' | 'storage'>
    & { subscription?: Maybe<(
      { __typename?: 'AccountSubscriptionData' }
      & SubscriptionDetailsFragment
    )>, checkAddUser?: Maybe<(
      { __typename?: 'AccountAddUsersData' }
      & Pick<AccountAddUsersData, 'canAdd' | 'usersCount' | 'usersAllowed'>
    )>, accountType?: Maybe<(
      { __typename?: 'AccountType' }
      & Pick<AccountType, 'id' | 'name'>
    )>, users?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'firstName' | 'lastName'>
    )>>>, primaryUser?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'firstName' | 'lastName'>
    )>, accountTask?: Maybe<Array<Maybe<(
      { __typename?: 'AccountTask' }
      & Pick<AccountTask, 'id' | 'key' | 'status'>
      & { account?: Maybe<(
        { __typename?: 'Account' }
        & Pick<Account, 'id'>
      )>, task?: Maybe<(
        { __typename?: 'Task' }
        & Pick<Task, 'id' | 'label' | 'action' | 'title'>
      )> }
    )>>>, applicationDocuments?: Maybe<Array<Maybe<(
      { __typename?: 'ApplicationDocument' }
      & Pick<ApplicationDocument, 'id'>
      & { type?: Maybe<(
        { __typename?: 'DocumentType' }
        & Pick<DocumentType, 'displayName'>
      )>, person?: Maybe<(
        { __typename?: 'Person' }
        & Pick<Person, 'id' | 'firstName' | 'lastName' | 'type'>
      )> }
    )>>>, roles?: Maybe<Array<Maybe<(
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'key' | 'title'>
    )>>>, companyCompanyTalents?: Maybe<Array<Maybe<(
      { __typename?: 'CompanyTalent' }
      & Pick<CompanyTalent, 'id' | 'key'>
    )>>>, companyApplications?: Maybe<Array<Maybe<(
      { __typename?: 'Application' }
      & Pick<Application, 'id' | 'key'>
    )>>>, accountPermissionGroups?: Maybe<(
      { __typename?: 'AccountPermissionGroup' }
      & { permissionGroup?: Maybe<(
        { __typename?: 'PermissionGroup' }
        & Pick<PermissionGroup, 'name'>
      )> }
    )> }
  )> }
);

export type AccountIncludesQueryVariables = Exact<{
  accountId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  includesPrimaryUser: Scalars['Boolean'];
  includesSubscription: Scalars['Boolean'];
  includesCheckAddUser: Scalars['Boolean'];
}>;


export type AccountIncludesQuery = (
  { __typename?: 'Query' }
  & { Account?: Maybe<(
    { __typename?: 'Account' }
    & Pick<Account, 'id' | 'name' | 'active' | 'createdAt' | 'meta' | 'viewed' | 'leadsEmbedId' | 'storage'>
    & { accountType?: Maybe<(
      { __typename?: 'AccountType' }
      & Pick<AccountType, 'name'>
    )>, primaryUser?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'firstName' | 'lastName'>
    )>, subscription?: Maybe<(
      { __typename?: 'AccountSubscriptionData' }
      & SubscriptionDetailsFragment
    )>, checkAddUser?: Maybe<(
      { __typename?: 'AccountAddUsersData' }
      & Pick<AccountAddUsersData, 'canAdd' | 'usersCount' | 'usersAllowed'>
    )> }
  )> }
);

export type AccountSubscriptionQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountSubscriptionQuery = (
  { __typename?: 'Query' }
  & { Account?: Maybe<(
    { __typename?: 'Account' }
    & { subscription?: Maybe<(
      { __typename?: 'AccountSubscriptionData' }
      & SubscriptionDetailsFragment
    )> }
  )> }
);

export type UpdateAccountMutationVariables = Exact<{
  accountId?: Maybe<Scalars['Int']>;
  data: Scalars['String'];
  refreshUser?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateAccountMutation = (
  { __typename?: 'Mutation' }
  & { updateAccount?: Maybe<(
    { __typename?: 'Account' }
    & Pick<Account, 'id' | 'name'>
  )> }
);

export type PublicSignUpMutationVariables = Exact<{
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  confirmPassword: Scalars['String'];
  recaptchaToken: Scalars['String'];
}>;


export type PublicSignUpMutation = (
  { __typename?: 'Mutation' }
  & { publicSignUp?: Maybe<(
    { __typename?: 'PublicSignUpReturn' }
    & Pick<PublicSignUpReturn, 'errorMessage' | 'errorName' | 'success'>
  )> }
);

export type AccountDocumentQueryVariables = Exact<{
  id?: Maybe<Scalars['Int']>;
  documentTypeName?: Maybe<Scalars['String']>;
  templateDocumentId?: Maybe<Scalars['Int']>;
  targetAccountId?: Maybe<Scalars['Int']>;
}>;


export type AccountDocumentQuery = (
  { __typename?: 'Query' }
  & { AccountDocument?: Maybe<(
    { __typename?: 'AccountDocument' }
    & Pick<AccountDocument, 'id' | 'key' | 'createdAt' | 'updatedAt'>
    & { document?: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id' | 'key' | 'createdAt' | 'order'>
    )>, documents?: Maybe<Array<(
      { __typename?: 'Document' }
      & Pick<Document, 'id' | 'key' | 'createdAt' | 'order'>
    )>>, account?: Maybe<(
      { __typename?: 'Account' }
      & Pick<Account, 'id'>
    )>, templateDocument?: Maybe<(
      { __typename?: 'TemplateDocument' }
      & Pick<TemplateDocument, 'id' | 'name'>
    )> }
  )> }
);

export type AccountDocumentRetainerQueryVariables = Exact<{
  id?: Maybe<Scalars['Int']>;
  targetAccountId?: Maybe<Scalars['Int']>;
}>;


export type AccountDocumentRetainerQuery = (
  { __typename?: 'Query' }
  & { AccountDocument?: Maybe<(
    { __typename?: 'AccountDocument' }
    & Pick<AccountDocument, 'id' | 'key' | 'createdAt' | 'updatedAt'>
    & { document?: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id' | 'key' | 'createdAt' | 'order'>
      & { signatureRequest?: Maybe<(
        { __typename?: 'SignatureRequest' }
        & Pick<SignatureRequest, 'id' | 'details'>
        & { signatureRequestSigners?: Maybe<Array<Maybe<(
          { __typename?: 'SignatureRequestSigners' }
          & Pick<SignatureRequestSigners, 'email' | 'id' | 'status' | 'updatedAt'>
        )>>> }
      )> }
    )>, documents?: Maybe<Array<(
      { __typename?: 'Document' }
      & Pick<Document, 'id' | 'key' | 'createdAt' | 'order'>
    )>>, account?: Maybe<(
      { __typename?: 'Account' }
      & Pick<Account, 'id'>
    )>, templateDocument?: Maybe<(
      { __typename?: 'TemplateDocument' }
      & Pick<TemplateDocument, 'id' | 'name'>
    )> }
  )> }
);

export type AccountDocumentsQueryVariables = Exact<{
  targetAccountId?: Maybe<Scalars['Int']>;
  type?: Maybe<AccountDocumentType>;
  includeSignatureRequest: Scalars['Boolean'];
}>;


export type AccountDocumentsQuery = (
  { __typename?: 'Query' }
  & { AccountDocuments?: Maybe<Array<Maybe<(
    { __typename?: 'AccountDocument' }
    & Pick<AccountDocument, 'id' | 'key' | 'createdAt' | 'updatedAt'>
    & { document?: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id' | 'key' | 'createdAt' | 'order'>
      & { signatureRequest?: Maybe<(
        { __typename?: 'SignatureRequest' }
        & Pick<SignatureRequest, 'id'>
        & { signatureRequestSigners?: Maybe<Array<Maybe<(
          { __typename?: 'SignatureRequestSigners' }
          & Pick<SignatureRequestSigners, 'email' | 'id' | 'status' | 'updatedAt'>
        )>>> }
      )> }
    )>, documents?: Maybe<Array<(
      { __typename?: 'Document' }
      & Pick<Document, 'id' | 'key' | 'createdAt' | 'order'>
    )>>, documentType?: Maybe<(
      { __typename?: 'DocumentType' }
      & Pick<DocumentType, 'name' | 'displayName' | 'title' | 'tip'>
    )>, account?: Maybe<(
      { __typename?: 'Account' }
      & Pick<Account, 'id'>
    )>, templateDocument?: Maybe<(
      { __typename?: 'TemplateDocument' }
      & Pick<TemplateDocument, 'id' | 'name'>
    )> }
  )>>> }
);

export type GetUrlDownloadAccountDocumentQueryVariables = Exact<{
  documentTypeName?: Maybe<Scalars['String']>;
  targetAccountId?: Maybe<Scalars['Int']>;
  documentId?: Maybe<Scalars['Int']>;
  merge?: Maybe<Scalars['Boolean']>;
  templateDocumentId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
}>;


export type GetUrlDownloadAccountDocumentQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getUrlDownloadAccountDocument'>
);

export type UploadAccountDocumentMutationVariables = Exact<{
  documentTypeName: Scalars['String'];
  file?: Maybe<Scalars['Upload']>;
  files?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
  data?: Maybe<UploadAccountDocumentData>;
  targetAccountId?: Maybe<Scalars['Int']>;
}>;


export type UploadAccountDocumentMutation = (
  { __typename?: 'Mutation' }
  & { uploadAccountDocument?: Maybe<(
    { __typename?: 'AccountDocument' }
    & Pick<AccountDocument, 'id' | 'createdAt' | 'updatedAt'>
    & { document?: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id'>
    )>, documentType?: Maybe<(
      { __typename?: 'DocumentType' }
      & Pick<DocumentType, 'name' | 'displayName' | 'title' | 'tip'>
    )> }
  )> }
);

export type UpdateAccountDocumentMutationVariables = Exact<{
  id: Scalars['Int'];
  action: UpdateAccountDocumentAction;
  data?: Maybe<UpdateAccountDocumentData>;
}>;


export type UpdateAccountDocumentMutation = (
  { __typename?: 'Mutation' }
  & { updateAccountDocument?: Maybe<(
    { __typename?: 'AccountDocument' }
    & Pick<AccountDocument, 'id'>
  )> }
);

export type HandleAccountEmbedMutationVariables = Exact<{
  action: HandleAccountEmbedAction;
  data?: Maybe<HandleAccountEmbedData>;
}>;


export type HandleAccountEmbedMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'handleAccountEmbed'>
);

export type AccountPermissionsQueryVariables = Exact<{
  accountId: Scalars['Int'];
}>;


export type AccountPermissionsQuery = (
  { __typename?: 'Query' }
  & { AccountPermissions: Array<(
    { __typename?: 'AccountPermission' }
    & Pick<AccountPermission, 'id' | 'active' | 'createdAt' | 'updatedAt'>
    & { account?: Maybe<(
      { __typename?: 'Account' }
      & Pick<Account, 'id'>
    )>, permission: (
      { __typename?: 'Permission' }
      & Pick<Permission, 'id' | 'resource' | 'operation' | 'description'>
    ) }
  )> }
);

export type UpdateAccountPermissionMutationVariables = Exact<{
  permissionId: Scalars['Int'];
  targetAccountId: Scalars['Int'];
  active: Scalars['Boolean'];
}>;


export type UpdateAccountPermissionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateAccountPermission'>
);

export type AccountSettingQueryVariables = Exact<{
  key: Scalars['String'];
  targetAccountId?: Maybe<Scalars['Int']>;
}>;


export type AccountSettingQuery = (
  { __typename?: 'Query' }
  & { AccountSetting?: Maybe<(
    { __typename?: 'AccountSetting' }
    & Pick<AccountSetting, 'id' | 'value' | 'settingId' | 'accountId' | 'createdAt' | 'updatedAt'>
    & { setting?: Maybe<(
      { __typename?: 'Setting' }
      & Pick<Setting, 'id' | 'key'>
    )> }
  )> }
);

export type UpdateAccountSettingsMutationVariables = Exact<{
  data: Scalars['String'];
  file?: Maybe<Scalars['Upload']>;
}>;


export type UpdateAccountSettingsMutation = (
  { __typename?: 'Mutation' }
  & { updateAccountSettings?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'email'>
  )> }
);

export type AccountTagsQueryVariables = Exact<{
  groups?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  accountId?: Maybe<Scalars['Int']>;
}>;


export type AccountTagsQuery = (
  { __typename?: 'Query' }
  & { AccountTags?: Maybe<Array<Maybe<(
    { __typename?: 'AccountTag' }
    & Pick<AccountTag, 'id' | 'years' | 'order' | 'createdAt' | 'updatedAt'>
    & { tag?: Maybe<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'value'>
      & { tagGroups?: Maybe<Array<Maybe<(
        { __typename?: 'TagGroup' }
        & Pick<TagGroup, 'id' | 'group'>
      )>>> }
    )> }
  )>>> }
);

export type UpdateAccountTagMutationVariables = Exact<{
  tagId?: Maybe<Scalars['Int']>;
  years?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  group?: Maybe<Scalars['String']>;
}>;


export type UpdateAccountTagMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateAccountTag'>
);

export type DeleteAccountTagMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteAccountTagMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAccountTag'>
);

export type GetAccountTaskQueryVariables = Exact<{
  id?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['Int']>;
  personId?: Maybe<Scalars['Int']>;
  applicationId?: Maybe<Scalars['Int']>;
  isSupportingDocument?: Maybe<Scalars['Boolean']>;
}>;


export type GetAccountTaskQuery = (
  { __typename?: 'Query' }
  & { AccountTask?: Maybe<(
    { __typename?: 'AccountTask' }
    & Pick<AccountTask, 'id' | 'key' | 'createdAt' | 'updatedAt' | 'status' | 'meta' | 'applicationId'>
    & { application?: Maybe<(
      { __typename?: 'Application' }
      & Pick<Application, 'id' | 'key' | 'type'>
    )>, task?: Maybe<(
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'action' | 'label' | 'title' | 'meta' | 'required'>
      & { taskAssociation?: Maybe<(
        { __typename?: 'TaskAssociation' }
        & Pick<TaskAssociation, 'id'>
        & { forms?: Maybe<Array<Maybe<(
          { __typename?: 'Form' }
          & Pick<Form, 'id' | 'name' | 'title' | 'accountId'>
        )>>>, documentTypes?: Maybe<Array<Maybe<(
          { __typename?: 'DocumentType' }
          & Pick<DocumentType, 'name' | 'id' | 'displayName' | 'title' | 'tip' | 'instructions' | 'required' | 'accountId'>
        )>>>, signatureRequestTypes?: Maybe<Array<Maybe<(
          { __typename?: 'SignatureRequestType' }
          & Pick<SignatureRequestType, 'id' | 'name' | 'title' | 'subject' | 'message' | 'templateId'>
        )>>> }
      )> }
    )>, account?: Maybe<(
      { __typename?: 'Account' }
      & Pick<Account, 'id' | 'name'>
    )>, person?: Maybe<(
      { __typename?: 'Person' }
      & Pick<Person, 'firstName' | 'lastName' | 'type' | 'id' | 'email'>
    )>, signatureRequestSigners?: Maybe<Array<Maybe<(
      { __typename?: 'SignatureRequestSigners' }
      & Pick<SignatureRequestSigners, 'signatureId' | 'status' | 'createdAt' | 'updatedAt' | 'email'>
      & { signatureRequest?: Maybe<(
        { __typename?: 'SignatureRequest' }
        & Pick<SignatureRequest, 'id'>
        & { type?: Maybe<(
          { __typename?: 'SignatureRequestType' }
          & Pick<SignatureRequestType, 'id' | 'name' | 'title' | 'subject' | 'message'>
        )> }
      )> }
    )>>>, feedback?: Maybe<Array<Maybe<(
      { __typename?: 'Feedback' }
      & Pick<Feedback, 'id' | 'content' | 'status' | 'createdAt' | 'updatedAt'>
      & { ownedBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName'>
      ), applicationDocument?: Maybe<(
        { __typename?: 'ApplicationDocument' }
        & Pick<ApplicationDocument, 'id'>
        & { account?: Maybe<(
          { __typename?: 'Account' }
          & Pick<Account, 'id'>
        )>, type?: Maybe<(
          { __typename?: 'DocumentType' }
          & Pick<DocumentType, 'name'>
        )> }
      )> }
    )>>> }
  )> }
);

export type GetAccountTaskIncludesQueryVariables = Exact<{
  id?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['Int']>;
  personId?: Maybe<Scalars['Int']>;
  applicationId?: Maybe<Scalars['Int']>;
  isSupportingDocument?: Maybe<Scalars['Boolean']>;
  isInternal?: Maybe<Scalars['Boolean']>;
  includeAccount: Scalars['Boolean'];
  includePerson: Scalars['Boolean'];
  includeSignatureRequestSigners: Scalars['Boolean'];
  includeFeedback: Scalars['Boolean'];
  includeApplication: Scalars['Boolean'];
  includeTask: Scalars['Boolean'];
  includeForms: Scalars['Boolean'];
  includeDocumentTypes: Scalars['Boolean'];
  includeSignatureRequestTypes: Scalars['Boolean'];
  includeUsers: Scalars['Boolean'];
  includeLawyerAssociation: Scalars['Boolean'];
  includeTaskAssociationApplication: Scalars['Boolean'];
}>;


export type GetAccountTaskIncludesQuery = (
  { __typename?: 'Query' }
  & { AccountTask?: Maybe<(
    { __typename?: 'AccountTask' }
    & Pick<AccountTask, 'id' | 'key' | 'createdAt' | 'updatedAt' | 'dueAt' | 'status' | 'meta' | 'applicationId' | 'isDue' | 'isPrivate' | 'priority'>
    & { assigneeUser?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'email' | 'id' | 'lastName'>
    )>, ownerUser?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'email' | 'id' | 'lastName'>
    )>, application?: Maybe<(
      { __typename?: 'Application' }
      & Pick<Application, 'id' | 'key' | 'type'>
    )>, task?: Maybe<(
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'action' | 'label' | 'title' | 'description' | 'meta' | 'required'>
      & { taskAssociation?: Maybe<(
        { __typename?: 'TaskAssociation' }
        & Pick<TaskAssociation, 'id'>
        & { forms?: Maybe<Array<Maybe<(
          { __typename?: 'Form' }
          & Pick<Form, 'id' | 'name' | 'title' | 'accountId'>
        )>>>, documentTypes?: Maybe<Array<Maybe<(
          { __typename?: 'DocumentType' }
          & Pick<DocumentType, 'name' | 'id' | 'displayName' | 'title' | 'tip' | 'instructions' | 'required' | 'accountId'>
        )>>>, signatureRequestTypes?: Maybe<Array<Maybe<(
          { __typename?: 'SignatureRequestType' }
          & Pick<SignatureRequestType, 'id' | 'name' | 'title' | 'subject' | 'message' | 'templateId'>
        )>>>, lawyerAssociation?: Maybe<(
          { __typename?: 'LawyerAssociation' }
          & Pick<LawyerAssociation, 'key' | 'id'>
          & { associationAccount: (
            { __typename?: 'Account' }
            & { primaryUser?: Maybe<(
              { __typename?: 'User' }
              & Pick<User, 'firstName' | 'lastName'>
            )> }
          ) }
        )>, application?: Maybe<(
          { __typename?: 'Application' }
          & Pick<Application, 'id' | 'key'>
          & { ownerAccount?: Maybe<(
            { __typename?: 'Account' }
            & { primaryUser?: Maybe<(
              { __typename?: 'User' }
              & Pick<User, 'firstName' | 'lastName'>
            )> }
          )> }
        )> }
      )> }
    )>, account?: Maybe<(
      { __typename?: 'Account' }
      & Pick<Account, 'id' | 'name'>
    )>, person?: Maybe<(
      { __typename?: 'Person' }
      & Pick<Person, 'firstName' | 'lastName' | 'type' | 'id' | 'email'>
    )>, signatureRequestSigners?: Maybe<Array<Maybe<(
      { __typename?: 'SignatureRequestSigners' }
      & Pick<SignatureRequestSigners, 'signatureId' | 'status' | 'createdAt' | 'updatedAt' | 'email'>
      & { signatureRequest?: Maybe<(
        { __typename?: 'SignatureRequest' }
        & Pick<SignatureRequest, 'id' | 'signatureRequestId'>
        & { type?: Maybe<(
          { __typename?: 'SignatureRequestType' }
          & Pick<SignatureRequestType, 'id' | 'name' | 'title' | 'subject' | 'message'>
        )> }
      )> }
    )>>>, feedback?: Maybe<Array<Maybe<(
      { __typename?: 'Feedback' }
      & Pick<Feedback, 'id' | 'content' | 'status' | 'createdAt' | 'updatedAt'>
      & { ownedBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName'>
      ), applicationDocument?: Maybe<(
        { __typename?: 'ApplicationDocument' }
        & Pick<ApplicationDocument, 'id'>
        & { account?: Maybe<(
          { __typename?: 'Account' }
          & Pick<Account, 'id'>
        )>, type?: Maybe<(
          { __typename?: 'DocumentType' }
          & Pick<DocumentType, 'name'>
        )> }
      )> }
    )>>> }
  )> }
);

export type GetAccountTasksQueryVariables = Exact<{
  taskLabels?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  accountId?: Maybe<Scalars['Int']>;
  personId?: Maybe<Scalars['Int']>;
  status?: Maybe<AccountTaskStatus>;
  applicationId?: Maybe<Scalars['Int']>;
  type?: Maybe<TaskAction>;
}>;


export type GetAccountTasksQuery = (
  { __typename?: 'Query' }
  & { AccountTasks: Array<(
    { __typename?: 'AccountTask' }
    & Pick<AccountTask, 'id' | 'key' | 'createdAt' | 'updatedAt' | 'status' | 'meta' | 'applicationId'>
    & { account?: Maybe<(
      { __typename?: 'Account' }
      & Pick<Account, 'id' | 'name'>
    )>, application?: Maybe<(
      { __typename?: 'Application' }
      & Pick<Application, 'id'>
    )>, task?: Maybe<(
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'action' | 'label' | 'title' | 'required'>
      & { taskAssociation?: Maybe<(
        { __typename?: 'TaskAssociation' }
        & Pick<TaskAssociation, 'id'>
        & { forms?: Maybe<Array<Maybe<(
          { __typename?: 'Form' }
          & Pick<Form, 'name' | 'title' | 'id'>
        )>>>, documentTypes?: Maybe<Array<Maybe<(
          { __typename?: 'DocumentType' }
          & Pick<DocumentType, 'name' | 'id' | 'displayName' | 'title' | 'tip'>
        )>>>, signatureRequestTypes?: Maybe<Array<Maybe<(
          { __typename?: 'SignatureRequestType' }
          & Pick<SignatureRequestType, 'id' | 'name' | 'title' | 'subject' | 'message' | 'templateId'>
        )>>> }
      )> }
    )>, person?: Maybe<(
      { __typename?: 'Person' }
      & Pick<Person, 'firstName' | 'lastName' | 'type' | 'id' | 'email'>
    )>, feedback?: Maybe<Array<Maybe<(
      { __typename?: 'Feedback' }
      & Pick<Feedback, 'id' | 'status'>
      & { role?: Maybe<(
        { __typename?: 'Role' }
        & Pick<Role, 'key'>
      )>, companyTalent?: Maybe<(
        { __typename?: 'CompanyTalent' }
        & Pick<CompanyTalent, 'key'>
      )> }
    )>>> }
  )> }
);

export type GetAccountTasksIncludesQueryVariables = Exact<{
  taskLabels?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  accountId?: Maybe<Scalars['Int']>;
  personId?: Maybe<Scalars['Int']>;
  status?: Maybe<AccountTaskStatus>;
  applicationId?: Maybe<Scalars['Int']>;
  type?: Maybe<TaskAction>;
  includeTask: Scalars['Boolean'];
  includePerson: Scalars['Boolean'];
  includeTaskForm: Scalars['Boolean'];
}>;


export type GetAccountTasksIncludesQuery = (
  { __typename?: 'Query' }
  & { AccountTasks: Array<(
    { __typename?: 'AccountTask' }
    & Pick<AccountTask, 'id' | 'key' | 'createdAt' | 'updatedAt' | 'status' | 'meta' | 'applicationId'>
    & { person?: Maybe<(
      { __typename?: 'Person' }
      & Pick<Person, 'firstName' | 'lastName' | 'type' | 'id' | 'email'>
    )>, task?: Maybe<(
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'action' | 'label' | 'title' | 'required' | 'meta'>
      & { taskAssociation?: Maybe<(
        { __typename?: 'TaskAssociation' }
        & Pick<TaskAssociation, 'id'>
        & { forms?: Maybe<Array<Maybe<(
          { __typename?: 'Form' }
          & Pick<Form, 'name' | 'title' | 'id'>
        )>>> }
      )> }
    )> }
  )> }
);

export type GetAccountTasksOverviewQueryVariables = Exact<{
  taskLabels?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  accountId?: Maybe<Scalars['Int']>;
  personId?: Maybe<Scalars['Int']>;
  status?: Maybe<AccountTaskStatus>;
  applicationId?: Maybe<Scalars['Int']>;
  isSupportingDocuments?: Maybe<Scalars['Boolean']>;
  isInternal?: Maybe<Scalars['Boolean']>;
}>;


export type GetAccountTasksOverviewQuery = (
  { __typename?: 'Query' }
  & { AccountTasks: Array<(
    { __typename?: 'AccountTask' }
    & Pick<AccountTask, 'id' | 'key' | 'createdAt' | 'updatedAt' | 'status' | 'applicationId' | 'dueAt' | 'isDue'>
    & { task?: Maybe<(
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'action' | 'label' | 'title' | 'required'>
    )>, person?: Maybe<(
      { __typename?: 'Person' }
      & Pick<Person, 'firstName' | 'lastName' | 'type' | 'id'>
    )> }
  )> }
);

export type UpdateAccountTasksMutationVariables = Exact<{
  data: AccountTaskUpdateData;
  where?: Maybe<AccountTaskUpdateWhere>;
}>;


export type UpdateAccountTasksMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateAccountTasks'>
);

export type CreateChangeRequestMutationVariables = Exact<{
  accountId: Scalars['Int'];
  message: Scalars['String'];
  personId?: Maybe<Scalars['Int']>;
  documentType?: Maybe<Scalars['String']>;
  accountTaskId?: Maybe<Scalars['Int']>;
  roleId?: Maybe<Scalars['Int']>;
  companyTalentId?: Maybe<Scalars['Int']>;
  applicationId?: Maybe<Scalars['Int']>;
}>;


export type CreateChangeRequestMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createChangeRequest'>
);

export type UpdateChangeRequestMutationVariables = Exact<{
  accountId: Scalars['Int'];
  accountTaskId?: Maybe<Scalars['Int']>;
  targetStatus?: Maybe<AccountTaskStatus>;
}>;


export type UpdateChangeRequestMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateChangeRequest'>
);

export type HandleAccountTaskMutationVariables = Exact<{
  action: HandleAccountTaskAction;
  data?: Maybe<HandleAccountTaskData>;
  targetAccountId?: Maybe<Scalars['Int']>;
  personId?: Maybe<Scalars['Int']>;
  accountTaskId?: Maybe<Scalars['Int']>;
  applicationId?: Maybe<Scalars['Int']>;
}>;


export type HandleAccountTaskMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'handleAccountTask'>
);

export type DeleteAccountTaskMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteAccountTaskMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAccountTask'>
);

export type CreateAccountTaskMutationVariables = Exact<{
  data: CreateAccountTaskData;
}>;


export type CreateAccountTaskMutation = (
  { __typename?: 'Mutation' }
  & { createAccountTask?: Maybe<(
    { __typename?: 'AccountTask' }
    & Pick<AccountTask, 'createdAt' | 'deleted' | 'id' | 'key' | 'status' | 'updatedAt'>
  )> }
);

export type FindManyInternalAccountTasksQueryVariables = Exact<{
  isInternal?: Maybe<Scalars['Boolean']>;
}>;


export type FindManyInternalAccountTasksQuery = (
  { __typename?: 'Query' }
  & { AccountTasks: Array<(
    { __typename?: 'AccountTask' }
    & Pick<AccountTask, 'createdAt' | 'dueAt' | 'id' | 'key' | 'priority' | 'status' | 'updatedAt'>
    & { assigneeUser?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'email' | 'id' | 'lastName'>
    )>, ownerUser?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'email' | 'id' | 'lastName'>
    )>, task?: Maybe<(
      { __typename?: 'Task' }
      & Pick<Task, 'action' | 'description' | 'title' | 'id' | 'label'>
      & { taskAssociation?: Maybe<(
        { __typename?: 'TaskAssociation' }
        & { lawyerAssociation?: Maybe<(
          { __typename?: 'LawyerAssociation' }
          & Pick<LawyerAssociation, 'key' | 'id'>
        )>, application?: Maybe<(
          { __typename?: 'Application' }
          & Pick<Application, 'id' | 'key'>
        )> }
      )> }
    )> }
  )> }
);

export type AccountTasksListQueryVariables = Exact<{
  cursor?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
  sortBy?: Maybe<QuerySortBy>;
  filters?: Maybe<AccountTasksListFilters>;
}>;


export type AccountTasksListQuery = (
  { __typename?: 'Query' }
  & { AccountTasksList?: Maybe<(
    { __typename?: 'AccountTasksListReturn' }
    & Pick<AccountTasksListReturn, 'count' | 'cursorId' | 'hasMore'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'AccountTask' }
      & Pick<AccountTask, 'createdAt' | 'dueAt' | 'isDue' | 'id' | 'key' | 'priority' | 'status' | 'updatedAt'>
      & { assigneeUser?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'firstName' | 'email' | 'id' | 'lastName'>
      )>, ownerUser?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'firstName' | 'email' | 'id' | 'lastName'>
      )>, task?: Maybe<(
        { __typename?: 'Task' }
        & Pick<Task, 'action' | 'description' | 'title' | 'id' | 'label'>
        & { taskAssociation?: Maybe<(
          { __typename?: 'TaskAssociation' }
          & { lawyerAssociation?: Maybe<(
            { __typename?: 'LawyerAssociation' }
            & Pick<LawyerAssociation, 'key' | 'id'>
          )>, application?: Maybe<(
            { __typename?: 'Application' }
            & Pick<Application, 'id' | 'key'>
          )> }
        )> }
      )> }
    )>>> }
  )> }
);

export type ActivityLogsQueryVariables = Exact<{
  cursor?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<QuerySortBy>;
  searchTerm?: Maybe<Scalars['String']>;
  data?: Maybe<ActivityLogsData>;
}>;


export type ActivityLogsQuery = (
  { __typename?: 'Query' }
  & { ActivityLogs?: Maybe<(
    { __typename?: 'ActivityLogsReturn' }
    & Pick<ActivityLogsReturn, 'count' | 'cursorId' | 'hasMore'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'ActivityLog' }
      & Pick<ActivityLog, 'id' | 'createdAt' | 'type' | 'url' | 'details'>
      & { accountTask?: Maybe<(
        { __typename?: 'AccountTask' }
        & Pick<AccountTask, 'key'>
      )>, application?: Maybe<(
        { __typename?: 'Application' }
        & Pick<Application, 'key'>
      )>, applicationDocument?: Maybe<(
        { __typename?: 'ApplicationDocument' }
        & Pick<ApplicationDocument, 'id'>
      )>, comment?: Maybe<(
        { __typename?: 'Comment' }
        & Pick<Comment, 'id'>
      )>, person?: Maybe<(
        { __typename?: 'Person' }
        & Pick<Person, 'id' | 'firstName' | 'lastName'>
      )>, user: (
        { __typename?: 'User' }
        & Pick<User, 'email' | 'firstName' | 'lastName'>
      ) }
    )>>> }
  )> }
);

export type GetAnswersQueryVariables = Exact<{
  questionLabels: Array<Scalars['String']> | Scalars['String'];
  accountId?: Maybe<Scalars['Int']>;
  personId?: Maybe<Scalars['Int']>;
  associations?: Maybe<Scalars['String']>;
  status?: Maybe<FormSubmissionStatus>;
  feature?: Maybe<FeatureType>;
}>;


export type GetAnswersQuery = (
  { __typename?: 'Query' }
  & { Answers?: Maybe<Array<Maybe<(
    { __typename?: 'Answer' }
    & Pick<Answer, 'id' | 'answer'>
    & { question?: Maybe<(
      { __typename?: 'Question' }
      & Pick<Question, 'label' | 'id'>
    )>, answerOptions: Array<(
      { __typename?: 'AnswerOptions' }
      & Pick<AnswerOptions, 'type' | 'id'>
    )> }
  )>>> }
);

export type UpdateAnswersMutationVariables = Exact<{
  personId?: Maybe<Scalars['Int']>;
  accountId?: Maybe<Scalars['Int']>;
  data: Array<UpdateAnswersData> | UpdateAnswersData;
}>;


export type UpdateAnswersMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateAnswers'>
);

export type GetAnswersAdminQueryVariables = Exact<{
  accountId: Scalars['Int'];
  cursor?: Maybe<Scalars['Int']>;
  personId?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
  associations?: Maybe<Scalars['String']>;
  status?: Maybe<FormSubmissionStatus>;
}>;


export type GetAnswersAdminQuery = (
  { __typename?: 'Query' }
  & { AnswersAdmin?: Maybe<(
    { __typename?: 'AnswersAdminReturn' }
    & Pick<AnswersAdminReturn, 'count' | 'hasMore' | 'cursorId'>
    & { data?: Maybe<Array<(
      { __typename?: 'Answer' }
      & Pick<Answer, 'id' | 'answer' | 'createdAt'>
      & { person?: Maybe<(
        { __typename?: 'Person' }
        & Pick<Person, 'id' | 'firstName' | 'lastName'>
      )>, question?: Maybe<(
        { __typename?: 'Question' }
        & Pick<Question, 'id' | 'label' | 'question'>
      )> }
    )>> }
  )> }
);

export type GetApplicationQueryVariables = Exact<{
  key?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  type?: Maybe<ApplicationType>;
}>;


export type GetApplicationQuery = (
  { __typename?: 'Query' }
  & { Application?: Maybe<(
    { __typename?: 'Application' }
    & Pick<Application, 'id' | 'key' | 'createdAt' | 'updatedAt' | 'type' | 'studentDirectStream' | 'meta' | 'displayName' | 'nonAccompanyingPersonIds'>
    & { lmiaConditions?: Maybe<(
      { __typename?: 'ApplicationLmiaConditions' }
      & Pick<ApplicationLmiaConditions, 'isRoleFormCompleted'>
    )>, assignedUsers?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'id'>
    )>>>, applicationTemplate?: Maybe<(
      { __typename?: 'ApplicationTemplate' }
      & Pick<ApplicationTemplate, 'name' | 'key' | 'isMultiStep'>
    )>, applicationItems?: Maybe<Array<Maybe<(
      { __typename?: 'ApplicationItem' }
      & Pick<ApplicationItem, 'personTypes' | 'isPdfForm' | 'pdf' | 'manualPdf' | 'formId' | 'taskId' | 'documentTypeId' | 'stepStatusValue' | 'isOnboardingForm' | 'onlineForm'>
      & { form?: Maybe<(
        { __typename?: 'Form' }
        & Pick<Form, 'title' | 'name'>
      )>, documentType?: Maybe<(
        { __typename?: 'DocumentType' }
        & Pick<DocumentType, 'name' | 'displayName'>
      )>, task?: Maybe<(
        { __typename?: 'Task' }
        & Pick<Task, 'title'>
      )>, conditions?: Maybe<Array<(
        { __typename?: 'Condition' }
        & Pick<Condition, 'id' | 'determiningProfileAttribute' | 'operator' | 'comparativeValue'>
        & { scope?: Maybe<(
          { __typename?: 'Scope' }
          & Pick<Scope, 'id'>
        )> }
      )>> }
    )>>>, talent?: Maybe<Array<Maybe<(
      { __typename?: 'CompanyTalent' }
      & Pick<CompanyTalent, 'id' | 'key' | 'companyAccountId' | 'firstName' | 'lastName' | 'meta'>
      & { talentAccount?: Maybe<(
        { __typename?: 'Account' }
        & Pick<Account, 'id' | 'name'>
        & { persons?: Maybe<Array<Maybe<(
          { __typename?: 'Person' }
          & Pick<Person, 'id' | 'firstName' | 'lastName' | 'email' | 'type'>
        )>>>, users?: Maybe<Array<Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'createdAt' | 'updatedAt'>
        )>>> }
      )>, role?: Maybe<(
        { __typename?: 'Role' }
        & Pick<Role, 'id' | 'title' | 'key'>
      )> }
    )>>>, ownerAccount?: Maybe<(
      { __typename?: 'Account' }
      & Pick<Account, 'id' | 'name'>
      & { accountType?: Maybe<(
        { __typename?: 'AccountType' }
        & Pick<AccountType, 'name'>
      )>, primaryUser?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
      )> }
    )>, lawyerAccount?: Maybe<(
      { __typename?: 'Account' }
      & Pick<Account, 'id' | 'name'>
      & { accountType?: Maybe<(
        { __typename?: 'AccountType' }
        & Pick<AccountType, 'name'>
      )> }
    )>, status?: Maybe<(
      { __typename?: 'Status' }
      & Pick<Status, 'id' | 'type' | 'value' | 'history' | 'createdAt' | 'updatedAt'>
    )>, statuses?: Maybe<Array<(
      { __typename?: 'Status' }
      & Pick<Status, 'id' | 'type' | 'value' | 'history' | 'createdAt' | 'updatedAt'>
      & { ownerAccount?: Maybe<(
        { __typename?: 'Account' }
        & Pick<Account, 'id'>
      )> }
    )>>, roles?: Maybe<Array<Maybe<(
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'title' | 'key'>
    )>>>, persons?: Maybe<Array<Maybe<(
      { __typename?: 'Person' }
      & Pick<Person, 'id' | 'firstName' | 'lastName' | 'type'>
    )>>>, client?: Maybe<(
      { __typename?: 'ApplicationClient' }
      & Pick<ApplicationClient, 'key' | 'name'>
    )>, representative?: Maybe<(
      { __typename?: 'Representative' }
      & Pick<Representative, 'id' | 'key' | 'name'>
    )> }
  )> }
);

export type GetApplicationIncludesQueryVariables = Exact<{
  key?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  type?: Maybe<ApplicationType>;
  includesRepresentative: Scalars['Boolean'];
  includesOwnerAccount: Scalars['Boolean'];
  includesLawyerAccount: Scalars['Boolean'];
  includesAssignedUsers: Scalars['Boolean'];
  includesStatus: Scalars['Boolean'];
  includesClient: Scalars['Boolean'];
  includesReview: Scalars['Boolean'];
}>;


export type GetApplicationIncludesQuery = (
  { __typename?: 'Query' }
  & { Application?: Maybe<(
    { __typename?: 'Application' }
    & MakeOptional<Pick<Application, 'id' | 'key' | 'createdAt' | 'updatedAt' | 'type' | 'studentDirectStream' | 'meta' | 'displayName' | 'nonAccompanyingPersonIds' | 'reviewEngine'>, 'reviewEngine'>
    & { representative?: Maybe<(
      { __typename?: 'Representative' }
      & Pick<Representative, 'id' | 'key' | 'name'>
    )>, ownerAccount?: Maybe<(
      { __typename?: 'Account' }
      & Pick<Account, 'id' | 'name'>
      & { accountType?: Maybe<(
        { __typename?: 'AccountType' }
        & Pick<AccountType, 'name'>
      )>, primaryUser?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
      )> }
    )>, lawyerAccount?: Maybe<(
      { __typename?: 'Account' }
      & Pick<Account, 'id' | 'name'>
    )>, assignedUsers?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'id'>
    )>>>, status?: Maybe<(
      { __typename?: 'Status' }
      & Pick<Status, 'id' | 'type' | 'value' | 'history' | 'createdAt' | 'updatedAt'>
    )>, client?: Maybe<(
      { __typename?: 'ApplicationClient' }
      & Pick<ApplicationClient, 'key' | 'name'>
    )> }
  )> }
);

export type GetApplicationWithTemplateQueryVariables = Exact<{
  id?: Maybe<Scalars['Int']>;
}>;


export type GetApplicationWithTemplateQuery = (
  { __typename?: 'Query' }
  & { Application?: Maybe<(
    { __typename?: 'Application' }
    & Pick<Application, 'id' | 'key' | 'createdAt' | 'updatedAt' | 'type' | 'displayName' | 'meta'>
    & { status?: Maybe<(
      { __typename?: 'Status' }
      & Pick<Status, 'id' | 'type' | 'value' | 'history' | 'createdAt' | 'updatedAt'>
    )>, applicationTemplate?: Maybe<(
      { __typename?: 'ApplicationTemplate' }
      & Pick<ApplicationTemplate, 'id' | 'name' | 'key' | 'isMultiStep'>
    )>, applicationItems?: Maybe<Array<Maybe<(
      { __typename?: 'ApplicationItem' }
      & Pick<ApplicationItem, 'id' | 'personTypes' | 'formId' | 'taskId' | 'documentTypeId' | 'isPdfForm' | 'manualPdf' | 'pdf' | 'stepStatusValue' | 'isOnboardingForm' | 'onlineForm'>
      & { form?: Maybe<(
        { __typename?: 'Form' }
        & Pick<Form, 'id' | 'name' | 'title' | 'accountId' | 'meta'>
      )>, documentType?: Maybe<(
        { __typename?: 'DocumentType' }
        & Pick<DocumentType, 'id' | 'displayName' | 'name' | 'title' | 'tip' | 'required' | 'accountId'>
      )>, task?: Maybe<(
        { __typename?: 'Task' }
        & Pick<Task, 'id' | 'title' | 'label' | 'accountId' | 'meta'>
      )>, conditions?: Maybe<Array<(
        { __typename?: 'Condition' }
        & Pick<Condition, 'id' | 'determiningProfileAttribute' | 'operator' | 'comparativeValue'>
        & { scope?: Maybe<(
          { __typename?: 'Scope' }
          & Pick<Scope, 'id'>
        )> }
      )>> }
    )>>> }
  )> }
);

export type GetApplicationBasicQueryVariables = Exact<{
  key?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  type?: Maybe<ApplicationType>;
}>;


export type GetApplicationBasicQuery = (
  { __typename?: 'Query' }
  & { Application?: Maybe<(
    { __typename?: 'Application' }
    & Pick<Application, 'id' | 'key' | 'createdAt' | 'updatedAt' | 'type' | 'studentDirectStream' | 'meta' | 'displayName' | 'nonAccompanyingPersonIds'>
  )> }
);

export type GetApplicationForExportQueryVariables = Exact<{
  id?: Maybe<Scalars['Int']>;
}>;


export type GetApplicationForExportQuery = (
  { __typename?: 'Query' }
  & { Application?: Maybe<(
    { __typename?: 'Application' }
    & Pick<Application, 'id' | 'key' | 'fileExportHistory'>
  )> }
);

export type GetApplicationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetApplicationsQuery = (
  { __typename?: 'Query' }
  & { Applications?: Maybe<(
    { __typename?: 'ApplicationsReturn' }
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'Application' }
      & Pick<Application, 'id' | 'key' | 'type' | 'createdAt' | 'updatedAt' | 'displayName'>
      & { talent?: Maybe<Array<Maybe<(
        { __typename?: 'CompanyTalent' }
        & Pick<CompanyTalent, 'id' | 'key'>
        & { talentAccount?: Maybe<(
          { __typename?: 'Account' }
          & Pick<Account, 'id' | 'name'>
          & { persons?: Maybe<Array<Maybe<(
            { __typename?: 'Person' }
            & Pick<Person, 'id' | 'firstName' | 'lastName' | 'email' | 'type'>
          )>>>, users?: Maybe<Array<Maybe<(
            { __typename?: 'User' }
            & Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'createdAt' | 'updatedAt'>
          )>>> }
        )>, role?: Maybe<(
          { __typename?: 'Role' }
          & Pick<Role, 'id' | 'title' | 'key'>
        )> }
      )>>>, ownerAccount?: Maybe<(
        { __typename?: 'Account' }
        & Pick<Account, 'id' | 'name'>
        & { accountType?: Maybe<(
          { __typename?: 'AccountType' }
          & Pick<AccountType, 'name'>
        )>, users?: Maybe<Array<Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email'>
        )>>> }
      )>, lawyerAccount?: Maybe<(
        { __typename?: 'Account' }
        & Pick<Account, 'id' | 'name'>
        & { accountType?: Maybe<(
          { __typename?: 'AccountType' }
          & Pick<AccountType, 'name'>
        )> }
      )>, statuses?: Maybe<Array<(
        { __typename?: 'Status' }
        & Pick<Status, 'id' | 'value' | 'type' | 'updatedAt'>
        & { application?: Maybe<(
          { __typename?: 'Application' }
          & Pick<Application, 'id' | 'key'>
          & { lawyerAccount?: Maybe<(
            { __typename?: 'Account' }
            & Pick<Account, 'name'>
          )>, ownerAccount?: Maybe<(
            { __typename?: 'Account' }
            & Pick<Account, 'name'>
          )> }
        )>, ownerAccount?: Maybe<(
          { __typename?: 'Account' }
          & Pick<Account, 'id' | 'name'>
        )>, talent?: Maybe<(
          { __typename?: 'CompanyTalent' }
          & Pick<CompanyTalent, 'key'>
          & { role?: Maybe<(
            { __typename?: 'Role' }
            & Pick<Role, 'id' | 'title'>
          )>, talentAccount?: Maybe<(
            { __typename?: 'Account' }
            & Pick<Account, 'id'>
            & { users?: Maybe<Array<Maybe<(
              { __typename?: 'User' }
              & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
            )>>> }
          )> }
        )> }
      )>> }
    )>>> }
  )> }
);

export type GetApplicationsOverviewQueryVariables = Exact<{
  accountId?: Maybe<Scalars['Int']>;
  ownerAccountId?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
  data?: Maybe<ApplicationsGetData>;
  filters?: Maybe<ApplicationsFilters>;
}>;


export type GetApplicationsOverviewQuery = (
  { __typename?: 'Query' }
  & { Applications?: Maybe<(
    { __typename?: 'ApplicationsReturn' }
    & Pick<ApplicationsReturn, 'cursorId' | 'hasMore' | 'count'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'Application' }
      & Pick<Application, 'id' | 'key' | 'createdAt' | 'updatedAt' | 'type' | 'displayName'>
      & { ownerAccount?: Maybe<(
        { __typename?: 'Account' }
        & Pick<Account, 'id' | 'name'>
        & { primaryUser?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'firstName' | 'lastName'>
        )> }
      )>, lawyerAccount?: Maybe<(
        { __typename?: 'Account' }
        & Pick<Account, 'name' | 'id'>
        & { primaryUser?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'email'>
        )> }
      )>, talent?: Maybe<Array<Maybe<(
        { __typename?: 'CompanyTalent' }
        & Pick<CompanyTalent, 'firstName' | 'lastName'>
        & { talentAccount?: Maybe<(
          { __typename?: 'Account' }
          & { primaryUser?: Maybe<(
            { __typename?: 'User' }
            & Pick<User, 'firstName' | 'lastName' | 'email'>
          )> }
        )> }
      )>>>, persons?: Maybe<Array<Maybe<(
        { __typename?: 'Person' }
        & Pick<Person, 'id' | 'firstName' | 'lastName' | 'type'>
      )>>>, statuses?: Maybe<Array<(
        { __typename?: 'Status' }
        & Pick<Status, 'id' | 'value' | 'type'>
        & { application?: Maybe<(
          { __typename?: 'Application' }
          & Pick<Application, 'type'>
        )> }
      )>>, status?: Maybe<(
        { __typename?: 'Status' }
        & Pick<Status, 'id' | 'type' | 'value' | 'history' | 'createdAt' | 'updatedAt'>
      )>, assignedUsers?: Maybe<Array<Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName'>
      )>>>, representative?: Maybe<(
        { __typename?: 'Representative' }
        & Pick<Representative, 'id' | 'name'>
      )> }
    )>>>, templates?: Maybe<Array<Maybe<(
      { __typename?: 'ApplicationTemplate' }
      & Pick<ApplicationTemplate, 'id' | 'key' | 'name'>
    )>>> }
  )> }
);

export type GetApplicationsIncludesQueryVariables = Exact<{
  accountId?: Maybe<Scalars['Int']>;
  ownerAccountId?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
  data?: Maybe<ApplicationsGetData>;
  filters?: Maybe<ApplicationsFilters>;
  includeOwnerAccount: Scalars['Boolean'];
}>;


export type GetApplicationsIncludesQuery = (
  { __typename?: 'Query' }
  & { Applications?: Maybe<(
    { __typename?: 'ApplicationsReturn' }
    & Pick<ApplicationsReturn, 'cursorId' | 'hasMore' | 'count'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'Application' }
      & Pick<Application, 'id' | 'key' | 'createdAt' | 'updatedAt' | 'type' | 'displayName'>
      & { ownerAccount?: Maybe<(
        { __typename?: 'Account' }
        & Pick<Account, 'id' | 'name'>
        & { primaryUser?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'firstName' | 'lastName'>
        )> }
      )> }
    )>>> }
  )> }
);

export type CreateApplicationMutationVariables = Exact<{
  lawyerAccountId?: Maybe<Scalars['Int']>;
  assignedLawyerUserIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
  companyTalentIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
  ownerAccountId?: Maybe<Scalars['Int']>;
  type?: Maybe<ApplicationType>;
  templateType?: Maybe<ApplicationTemplateType>;
  roleId?: Maybe<Scalars['Int']>;
  meta?: Maybe<CreateApplicationMeta>;
  applicationTemplateId?: Maybe<Scalars['Int']>;
  representativeId?: Maybe<Scalars['Int']>;
  newAccountData?: Maybe<NewAccountData>;
}>;


export type CreateApplicationMutation = (
  { __typename?: 'Mutation' }
  & { createApplication?: Maybe<(
    { __typename?: 'Application' }
    & Pick<Application, 'id' | 'key' | 'createdAt' | 'updatedAt' | 'type'>
  )> }
);

export type UpdateApplicationMutationVariables = Exact<{
  applicationId: Scalars['Int'];
  targetAccountId?: Maybe<Scalars['Int']>;
  data?: Maybe<ApplicationUpdateData>;
}>;


export type UpdateApplicationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateApplication'>
);

export type HandleApplicationMutationVariables = Exact<{
  action: HandleApplicationAction;
  targetAccountId?: Maybe<Scalars['Int']>;
  data?: Maybe<HandleApplicationData>;
}>;


export type HandleApplicationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'handleApplication'>
);

export type DeleteApplicationMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteApplicationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteApplication'>
);

export type ApplicationDocumentQueryVariables = Exact<{
  id?: Maybe<Scalars['Int']>;
  personId?: Maybe<Scalars['Int']>;
  documentTypeName?: Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['Int']>;
  applicationId?: Maybe<Scalars['Int']>;
  associations?: Maybe<Scalars['JSON']>;
  templateDocumentId?: Maybe<Scalars['Int']>;
}>;


export type ApplicationDocumentQuery = (
  { __typename?: 'Query' }
  & { ApplicationDocument?: Maybe<(
    { __typename?: 'ApplicationDocument' }
    & Pick<ApplicationDocument, 'id' | 'applicationId' | 'history' | 'meta' | 'createdAt' | 'updatedAt'>
    & { account?: Maybe<(
      { __typename?: 'Account' }
      & Pick<Account, 'id'>
    )>, type?: Maybe<(
      { __typename?: 'DocumentType' }
      & Pick<DocumentType, 'name' | 'id' | 'displayName' | 'title' | 'tip' | 'instructions'>
    )>, person?: Maybe<(
      { __typename?: 'Person' }
      & Pick<Person, 'firstName' | 'lastName' | 'type'>
    )>, document?: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id' | 'key' | 'createdAt'>
    )>, documents?: Maybe<Array<(
      { __typename?: 'Document' }
      & Pick<Document, 'id' | 'key' | 'createdAt' | 'order'>
    )>>, feedback?: Maybe<Array<Maybe<(
      { __typename?: 'Feedback' }
      & Pick<Feedback, 'id' | 'content' | 'status' | 'createdAt' | 'updatedAt'>
    )>>>, templateDocument?: Maybe<(
      { __typename?: 'TemplateDocument' }
      & Pick<TemplateDocument, 'id' | 'name'>
    )> }
  )> }
);

export type ApplicationDocumentsQueryVariables = Exact<{
  accountId?: Maybe<Scalars['Int']>;
  personId?: Maybe<Scalars['Int']>;
  applicationId?: Maybe<Scalars['Int']>;
  documentTypeFilters?: Maybe<ApplicationDocumentsDocumentTypeFilters>;
  associations?: Maybe<Scalars['JSON']>;
  accountTaskId?: Maybe<Scalars['Int']>;
  allSupportingDocuments?: Maybe<Scalars['Boolean']>;
  includeTemplateDocuments?: Maybe<Scalars['Boolean']>;
  hasDocuments?: Maybe<Scalars['Boolean']>;
}>;


export type ApplicationDocumentsQuery = (
  { __typename?: 'Query' }
  & { ApplicationDocuments?: Maybe<Array<Maybe<(
    { __typename?: 'ApplicationDocument' }
    & Pick<ApplicationDocument, 'id' | 'createdAt' | 'updatedAt' | 'applicationId' | 'history' | 'associations'>
    & { account?: Maybe<(
      { __typename?: 'Account' }
      & Pick<Account, 'id'>
    )>, document?: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id' | 'key' | 'createdAt'>
    )>, documents?: Maybe<Array<(
      { __typename?: 'Document' }
      & Pick<Document, 'id' | 'key' | 'createdAt' | 'order'>
    )>>, type?: Maybe<(
      { __typename?: 'DocumentType' }
      & Pick<DocumentType, 'id' | 'name' | 'displayName' | 'title' | 'tip' | 'required'>
    )>, person?: Maybe<(
      { __typename?: 'Person' }
      & Pick<Person, 'id' | 'firstName' | 'lastName' | 'type'>
    )>, templateDocument?: Maybe<(
      { __typename?: 'TemplateDocument' }
      & Pick<TemplateDocument, 'id' | 'name'>
    )> }
  )>>> }
);

export type GetUrlDocumentsQueryVariables = Exact<{
  applicationId: Scalars['Int'];
  accountId?: Maybe<Scalars['Int']>;
  personId?: Maybe<Scalars['Int']>;
  documentTypes?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;


export type GetUrlDocumentsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getUrlDocuments'>
);

export type GetUrlDownloadApplicationDocumentQueryVariables = Exact<{
  personId?: Maybe<Scalars['Int']>;
  documentTypeName?: Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['Int']>;
  applicationId?: Maybe<Scalars['Int']>;
  historyDocumentId?: Maybe<Scalars['Int']>;
  documentId?: Maybe<Scalars['Int']>;
  associations?: Maybe<Scalars['JSON']>;
  merge?: Maybe<Scalars['Boolean']>;
  templateDocumentId?: Maybe<Scalars['Int']>;
}>;


export type GetUrlDownloadApplicationDocumentQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getUrlDownloadApplicationDocument'>
);

export type UploadApplicationDocumentMutationVariables = Exact<{
  document?: Maybe<Scalars['Upload']>;
  documents?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
  personId?: Maybe<Scalars['Int']>;
  typeName: Scalars['String'];
  accountId?: Maybe<Scalars['Int']>;
  applicationId?: Maybe<Scalars['Int']>;
  supportingDocumentApplicationId?: Maybe<Scalars['Int']>;
  associations?: Maybe<Scalars['JSON']>;
}>;


export type UploadApplicationDocumentMutation = (
  { __typename?: 'Mutation' }
  & { uploadApplicationDocument?: Maybe<(
    { __typename?: 'ApplicationDocument' }
    & Pick<ApplicationDocument, 'id'>
    & { account?: Maybe<(
      { __typename?: 'Account' }
      & Pick<Account, 'id'>
    )>, document?: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id' | 'key'>
    )>, documents?: Maybe<Array<(
      { __typename?: 'Document' }
      & Pick<Document, 'id' | 'key' | 'createdAt'>
    )>>, type?: Maybe<(
      { __typename?: 'DocumentType' }
      & Pick<DocumentType, 'id' | 'name'>
    )> }
  )> }
);

export type GenerateApplicationDocumentsMutationVariables = Exact<{
  applicationId: Scalars['Int'];
  applicationType?: Maybe<ApplicationType>;
  talentId?: Maybe<Scalars['Int']>;
  meta?: Maybe<GenerateApplicationDocumentsMeta>;
}>;


export type GenerateApplicationDocumentsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'generateApplicationDocuments'>
);

export type SignApplicationDocumentsMutationVariables = Exact<{
  applicationId: Scalars['Int'];
  applicationType?: Maybe<ApplicationType>;
  talentId?: Maybe<Scalars['Int']>;
  documentsToSignByPerson?: Maybe<Scalars['JSON']>;
}>;


export type SignApplicationDocumentsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'signApplicationDocuments'>
);

export type UpdateApplicationDocumentsMutationVariables = Exact<{
  action: UpdateApplicationDocumentsAction;
  data?: Maybe<UpdateApplicationDocumentsData>;
}>;


export type UpdateApplicationDocumentsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateApplicationDocuments'>
);

export type GenerateApplicationDocumentMutationVariables = Exact<{
  applicationId: Scalars['Int'];
  documentName: GovernmentApplicationForm;
  personId?: Maybe<Scalars['Int']>;
}>;


export type GenerateApplicationDocumentMutation = (
  { __typename?: 'Mutation' }
  & { generateApplicationDocument?: Maybe<(
    { __typename?: 'ApplicationDocument' }
    & Pick<ApplicationDocument, 'id' | 'applicationId' | 'history' | 'createdAt' | 'updatedAt'>
    & { account?: Maybe<(
      { __typename?: 'Account' }
      & Pick<Account, 'id'>
    )>, type?: Maybe<(
      { __typename?: 'DocumentType' }
      & Pick<DocumentType, 'name' | 'id' | 'displayName' | 'title' | 'tip' | 'instructions'>
    )>, person?: Maybe<(
      { __typename?: 'Person' }
      & Pick<Person, 'firstName' | 'lastName' | 'type'>
    )>, document?: Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id' | 'key' | 'createdAt'>
    )>, documents?: Maybe<Array<(
      { __typename?: 'Document' }
      & Pick<Document, 'id' | 'key' | 'createdAt' | 'order'>
    )>>, feedback?: Maybe<Array<Maybe<(
      { __typename?: 'Feedback' }
      & Pick<Feedback, 'id' | 'content' | 'status' | 'createdAt' | 'updatedAt'>
    )>>> }
  )> }
);

export type CreateApplicationItemMutationVariables = Exact<{
  applicationId: Scalars['Int'];
  personTypes: Array<PersonType> | PersonType;
  formId?: Maybe<Scalars['Int']>;
  documentTypeId?: Maybe<Scalars['Int']>;
  taskId?: Maybe<Scalars['Int']>;
  pdf?: Maybe<GovernmentApplicationForm>;
  manualPdf?: Maybe<Scalars['String']>;
  onlineForm?: Maybe<GovernmentOnlineForm>;
  stepStatusValue?: Maybe<StatusValue>;
}>;


export type CreateApplicationItemMutation = (
  { __typename?: 'Mutation' }
  & { createApplicationItem?: Maybe<(
    { __typename?: 'ApplicationItem' }
    & Pick<ApplicationItem, 'id'>
  )> }
);

export type DeleteApplicationItemMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteApplicationItemMutation = (
  { __typename?: 'Mutation' }
  & { deleteApplicationItem?: Maybe<(
    { __typename?: 'ApplicationItem' }
    & Pick<ApplicationItem, 'id'>
  )> }
);

export type UpdateApplicationItemMutationVariables = Exact<{
  id: Scalars['Int'];
  action: UpdateApplicationTemplateItemAction;
  data?: Maybe<UpdateApplicationTemplateItemData>;
}>;


export type UpdateApplicationItemMutation = (
  { __typename?: 'Mutation' }
  & { updateApplicationItem?: Maybe<(
    { __typename?: 'ApplicationItem' }
    & Pick<ApplicationItem, 'id' | 'createdAt'>
  )> }
);

export type ApplicationTemplatesQueryVariables = Exact<{
  cursor?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
  sortBy?: Maybe<QuerySortBy>;
}>;


export type ApplicationTemplatesQuery = (
  { __typename?: 'Query' }
  & { ApplicationTemplates?: Maybe<(
    { __typename?: 'ApplicationTemplatesReturn' }
    & Pick<ApplicationTemplatesReturn, 'count' | 'cursorId' | 'hasMore'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'ApplicationTemplate' }
      & Pick<ApplicationTemplate, 'accountId' | 'createdAt' | 'description' | 'id' | 'key' | 'name' | 'updatedAt'>
    )>>> }
  )> }
);

export type ApplicationTemplateQueryVariables = Exact<{
  key: Scalars['String'];
}>;


export type ApplicationTemplateQuery = (
  { __typename?: 'Query' }
  & { ApplicationTemplate?: Maybe<(
    { __typename?: 'ApplicationTemplate' }
    & Pick<ApplicationTemplate, 'accountId' | 'createdAt' | 'description' | 'id' | 'key' | 'name' | 'updatedAt' | 'isMultiStep'>
    & { applicationTemplateItems?: Maybe<Array<Maybe<(
      { __typename?: 'ApplicationTemplateItem' }
      & Pick<ApplicationTemplateItem, 'id' | 'applicationTemplateId' | 'personTypes' | 'formId' | 'taskId' | 'documentTypeId' | 'isPdfForm' | 'isOnboardingForm' | 'onlineForm' | 'pdf' | 'manualPdf' | 'stepStatusValue'>
      & { form?: Maybe<(
        { __typename?: 'Form' }
        & Pick<Form, 'id' | 'name' | 'title' | 'accountId' | 'meta'>
      )>, documentType?: Maybe<(
        { __typename?: 'DocumentType' }
        & Pick<DocumentType, 'id' | 'displayName' | 'name' | 'title' | 'tip' | 'required' | 'accountId'>
      )>, task?: Maybe<(
        { __typename?: 'Task' }
        & Pick<Task, 'id' | 'title' | 'label' | 'accountId' | 'meta'>
      )>, conditions?: Maybe<Array<(
        { __typename?: 'Condition' }
        & Pick<Condition, 'id' | 'determiningProfileAttribute' | 'operator' | 'comparativeValue'>
        & { scope?: Maybe<(
          { __typename?: 'Scope' }
          & Pick<Scope, 'id'>
        )> }
      )>> }
    )>>> }
  )> }
);

export type CreateApplicationTemplateMutationVariables = Exact<{
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  applicationType?: Maybe<ApplicationType>;
  templateType?: Maybe<ApplicationTemplateType>;
}>;


export type CreateApplicationTemplateMutation = (
  { __typename?: 'Mutation' }
  & { createApplicationTemplate?: Maybe<(
    { __typename?: 'ApplicationTemplate' }
    & Pick<ApplicationTemplate, 'accountId' | 'createdAt' | 'description' | 'id' | 'key' | 'name' | 'updatedAt'>
  )> }
);

export type UpdateApplicationTemplateMutationVariables = Exact<{
  id: Scalars['Int'];
  data?: Maybe<UpdateApplicationTemplateData>;
}>;


export type UpdateApplicationTemplateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateApplicationTemplate'>
);

export type DeleteApplicationTemplateMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteApplicationTemplateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteApplicationTemplate'>
);

export type CreateApplicationTemplateItemMutationVariables = Exact<{
  applicationTemplateId: Scalars['Int'];
  personTypes: Array<PersonType> | PersonType;
  formId?: Maybe<Scalars['Int']>;
  documentTypeId?: Maybe<Scalars['Int']>;
  taskId?: Maybe<Scalars['Int']>;
  pdf?: Maybe<GovernmentApplicationForm>;
  manualPdf?: Maybe<Scalars['String']>;
  onlineForm?: Maybe<GovernmentOnlineForm>;
  stepStatusValue?: Maybe<StatusValue>;
}>;


export type CreateApplicationTemplateItemMutation = (
  { __typename?: 'Mutation' }
  & { createApplicationTemplateItem?: Maybe<(
    { __typename?: 'ApplicationTemplateItem' }
    & Pick<ApplicationTemplateItem, 'id'>
  )> }
);

export type DeleteApplicationTemplateItemMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteApplicationTemplateItemMutation = (
  { __typename?: 'Mutation' }
  & { deleteApplicationTemplateItem?: Maybe<(
    { __typename?: 'ApplicationTemplateItem' }
    & Pick<ApplicationTemplateItem, 'id'>
  )> }
);

export type UpdateApplicationTemplateItemMutationVariables = Exact<{
  id: Scalars['Int'];
  action: UpdateApplicationTemplateItemAction;
  data?: Maybe<UpdateApplicationTemplateItemData>;
}>;


export type UpdateApplicationTemplateItemMutation = (
  { __typename?: 'Mutation' }
  & { updateApplicationTemplateItem?: Maybe<(
    { __typename?: 'ApplicationTemplateItem' }
    & Pick<ApplicationTemplateItem, 'id' | 'createdAt'>
  )> }
);

export type HandleAuthMutationVariables = Exact<{
  action: HandleAuthAction;
  data?: Maybe<HandleAuthData>;
}>;


export type HandleAuthMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'handleAuth'>
);

export type DeleteCommentMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteCommentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteComment'>
);

export type UpdateCommentMutationVariables = Exact<{
  id: Scalars['Int'];
  content: Scalars['String'];
}>;


export type UpdateCommentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateComment'>
);

export type CreateCommentMutationVariables = Exact<{
  accountTaskId?: Maybe<Scalars['Int']>;
  applicationDocumentId?: Maybe<Scalars['Int']>;
  applicationId?: Maybe<Scalars['Int']>;
  companyTalentId?: Maybe<Scalars['Int']>;
  roleId?: Maybe<Scalars['Int']>;
  parentId?: Maybe<Scalars['Int']>;
  content: Scalars['String'];
  notifies: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type CreateCommentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createComment'>
);

export type GetCommentsQueryVariables = Exact<{
  accountTaskId?: Maybe<Scalars['Int']>;
  applicationDocumentId?: Maybe<Scalars['Int']>;
  applicationId?: Maybe<Scalars['Int']>;
  companyTalentId?: Maybe<Scalars['Int']>;
  roleId?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type GetCommentsQuery = (
  { __typename?: 'Query' }
  & { Comments: Array<(
    { __typename?: 'Comment' }
    & Pick<Comment, 'id' | 'content' | 'parentId' | 'ownedById' | 'createdAt' | 'updatedAt'>
    & { parent?: Maybe<(
      { __typename?: 'Comment' }
      & Pick<Comment, 'id' | 'content' | 'ownedById' | 'createdAt' | 'updatedAt'>
      & { ownedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
      )>, account?: Maybe<(
        { __typename?: 'Account' }
        & Pick<Account, 'id' | 'name'>
        & { accountType?: Maybe<(
          { __typename?: 'AccountType' }
          & Pick<AccountType, 'name'>
        )> }
      )> }
    )>, children: Array<(
      { __typename?: 'Comment' }
      & Pick<Comment, 'id' | 'content' | 'parentId' | 'ownedById' | 'createdAt' | 'updatedAt'>
      & { ownedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
      )>, account?: Maybe<(
        { __typename?: 'Account' }
        & Pick<Account, 'name'>
        & { accountType?: Maybe<(
          { __typename?: 'AccountType' }
          & Pick<AccountType, 'name'>
        )> }
      )> }
    )>, account?: Maybe<(
      { __typename?: 'Account' }
      & Pick<Account, 'id' | 'name'>
      & { accountType?: Maybe<(
        { __typename?: 'AccountType' }
        & Pick<AccountType, 'name'>
      )> }
    )>, commentAssociations?: Maybe<Array<Maybe<(
      { __typename?: 'CommentAssociation' }
      & Pick<CommentAssociation, 'id' | 'accountTaskId' | 'applicationDocumentId' | 'applicationId' | 'companyTalentId' | 'roleId'>
      & { accountTask?: Maybe<(
        { __typename?: 'AccountTask' }
        & Pick<AccountTask, 'key'>
      )>, applicationDocument?: Maybe<(
        { __typename?: 'ApplicationDocument' }
        & { associatedAccountTask?: Maybe<(
          { __typename?: 'AccountTask' }
          & Pick<AccountTask, 'key'>
        )> }
      )>, application?: Maybe<(
        { __typename?: 'Application' }
        & Pick<Application, 'key'>
      )>, companyTalent?: Maybe<(
        { __typename?: 'CompanyTalent' }
        & Pick<CompanyTalent, 'key'>
      )>, role?: Maybe<(
        { __typename?: 'Role' }
        & Pick<Role, 'key'>
      )> }
    )>>>, ownedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
    )> }
  )> }
);

export type GetCommentsIncludesQueryVariables = Exact<{
  accountTaskId?: Maybe<Scalars['Int']>;
  applicationDocumentId?: Maybe<Scalars['Int']>;
  applicationId?: Maybe<Scalars['Int']>;
  companyTalentId?: Maybe<Scalars['Int']>;
  roleId?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  includeAssociations: Scalars['Boolean'];
}>;


export type GetCommentsIncludesQuery = (
  { __typename?: 'Query' }
  & { Comments: Array<(
    { __typename?: 'Comment' }
    & Pick<Comment, 'id' | 'content' | 'createdAt' | 'updatedAt'>
    & { commentAssociations?: Maybe<Array<Maybe<(
      { __typename?: 'CommentAssociation' }
      & Pick<CommentAssociation, 'id' | 'accountTaskId' | 'applicationDocumentId' | 'applicationId' | 'companyTalentId' | 'roleId'>
      & { accountTask?: Maybe<(
        { __typename?: 'AccountTask' }
        & Pick<AccountTask, 'key'>
      )>, applicationDocument?: Maybe<(
        { __typename?: 'ApplicationDocument' }
        & { associatedAccountTask?: Maybe<(
          { __typename?: 'AccountTask' }
          & Pick<AccountTask, 'key'>
        )> }
      )>, application?: Maybe<(
        { __typename?: 'Application' }
        & Pick<Application, 'key'>
      )>, companyTalent?: Maybe<(
        { __typename?: 'CompanyTalent' }
        & Pick<CompanyTalent, 'key'>
      )>, role?: Maybe<(
        { __typename?: 'Role' }
        & Pick<Role, 'key'>
      )> }
    )>>>, ownedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
    )> }
  )> }
);

export type CommentsListQueryVariables = Exact<{
  cursor?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
  sortBy?: Maybe<QuerySortBy>;
  filters?: Maybe<CommentsListFilters>;
}>;


export type CommentsListQuery = (
  { __typename?: 'Query' }
  & { CommentsList?: Maybe<(
    { __typename?: 'CommentsListReturn' }
    & Pick<CommentsListReturn, 'count' | 'cursorId' | 'hasMore'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'Comment' }
      & Pick<Comment, 'content' | 'createdAt' | 'id'>
      & { commentAssociations?: Maybe<Array<Maybe<(
        { __typename?: 'CommentAssociation' }
        & Pick<CommentAssociation, 'id' | 'accountTaskId' | 'applicationDocumentId' | 'applicationId' | 'companyTalentId' | 'roleId'>
        & { accountTask?: Maybe<(
          { __typename?: 'AccountTask' }
          & Pick<AccountTask, 'key'>
        )>, applicationDocument?: Maybe<(
          { __typename?: 'ApplicationDocument' }
          & { associatedAccountTask?: Maybe<(
            { __typename?: 'AccountTask' }
            & Pick<AccountTask, 'key'>
          )> }
        )>, application?: Maybe<(
          { __typename?: 'Application' }
          & Pick<Application, 'key'>
        )>, companyTalent?: Maybe<(
          { __typename?: 'CompanyTalent' }
          & Pick<CompanyTalent, 'key'>
        )>, role?: Maybe<(
          { __typename?: 'Role' }
          & Pick<Role, 'key'>
        )> }
      )>>> }
    )>>> }
  )> }
);

export type CompanyComplianceQueryVariables = Exact<{ [key: string]: never; }>;


export type CompanyComplianceQuery = (
  { __typename?: 'Query' }
  & { CompanyCompliance?: Maybe<(
    { __typename?: 'CompanyCompliance' }
    & Pick<CompanyCompliance, 'id' | 'approvedAt' | 'expiresAt' | 'nextAuditAt' | 'createdAt' | 'updatedAt'>
  )> }
);

export type UpsertCompanyComplianceMutationVariables = Exact<{
  data: CompanyComplianceUpdateData;
}>;


export type UpsertCompanyComplianceMutation = (
  { __typename?: 'Mutation' }
  & { upsertCompanyCompliance?: Maybe<(
    { __typename?: 'CompanyCompliance' }
    & Pick<CompanyCompliance, 'id' | 'expiresAt' | 'createdAt' | 'updatedAt'>
  )> }
);

export type GetCompanyTalentQueryVariables = Exact<{
  id?: Maybe<Scalars['Int']>;
  accountId?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
}>;


export type GetCompanyTalentQuery = (
  { __typename?: 'Query' }
  & { CompanyTalent?: Maybe<(
    { __typename?: 'CompanyTalent' }
    & Pick<CompanyTalent, 'id' | 'key' | 'companyAccountId' | 'talentAccountId' | 'talentType' | 'firstName' | 'lastName' | 'email' | 'meta'>
    & { applications?: Maybe<Array<Maybe<(
      { __typename?: 'Application' }
      & Pick<Application, 'key'>
    )>>>, companyAccount?: Maybe<(
      { __typename?: 'Account' }
      & Pick<Account, 'id' | 'name'>
      & { persons?: Maybe<Array<Maybe<(
        { __typename?: 'Person' }
        & Pick<Person, 'id' | 'firstName' | 'lastName' | 'email' | 'type'>
      )>>>, users?: Maybe<Array<Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'createdAt' | 'updatedAt'>
      )>>> }
    )>, talentAccount?: Maybe<(
      { __typename?: 'Account' }
      & Pick<Account, 'id' | 'name'>
      & { persons?: Maybe<Array<Maybe<(
        { __typename?: 'Person' }
        & Pick<Person, 'id' | 'firstName' | 'lastName' | 'email' | 'type'>
      )>>>, users?: Maybe<Array<Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'createdAt' | 'updatedAt'>
      )>>> }
    )>, invitation?: Maybe<(
      { __typename?: 'Invitation' }
      & Pick<Invitation, 'id' | 'slug' | 'email' | 'status' | 'expires'>
    )>, role?: Maybe<(
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'key' | 'title' | 'createdAt' | 'updatedAt'>
    )>, application?: Maybe<(
      { __typename?: 'Application' }
      & Pick<Application, 'id' | 'key'>
      & { statuses?: Maybe<Array<(
        { __typename?: 'Status' }
        & Pick<Status, 'id' | 'value' | 'type'>
        & { talent?: Maybe<(
          { __typename?: 'CompanyTalent' }
          & Pick<CompanyTalent, 'id'>
          & { role?: Maybe<(
            { __typename?: 'Role' }
            & Pick<Role, 'id' | 'title'>
          )> }
        )> }
      )>> }
    )>, feedback?: Maybe<Array<Maybe<(
      { __typename?: 'Feedback' }
      & Pick<Feedback, 'content' | 'status' | 'updatedAt' | 'createdAt'>
      & { ownedBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName'>
      ), accountTask?: Maybe<(
        { __typename?: 'AccountTask' }
        & Pick<AccountTask, 'id'>
        & { account?: Maybe<(
          { __typename?: 'Account' }
          & Pick<Account, 'id'>
        )> }
      )> }
    )>>> }
  )> }
);

export type GetCompanyTalentsQueryVariables = Exact<{
  accountId?: Maybe<Scalars['Int']>;
  applicationId?: Maybe<Scalars['Int']>;
}>;


export type GetCompanyTalentsQuery = (
  { __typename?: 'Query' }
  & { CompanyTalents: Array<(
    { __typename?: 'CompanyTalent' }
    & Pick<CompanyTalent, 'id' | 'key' | 'companyAccountId' | 'talentAccountId' | 'talentType' | 'firstName' | 'lastName' | 'email' | 'meta'>
    & { companyAccount?: Maybe<(
      { __typename?: 'Account' }
      & Pick<Account, 'id' | 'name'>
      & { persons?: Maybe<Array<Maybe<(
        { __typename?: 'Person' }
        & Pick<Person, 'id' | 'firstName' | 'lastName' | 'email' | 'type'>
      )>>>, users?: Maybe<Array<Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'createdAt' | 'updatedAt'>
      )>>> }
    )>, talentAccount?: Maybe<(
      { __typename?: 'Account' }
      & Pick<Account, 'id' | 'name'>
      & { persons?: Maybe<Array<Maybe<(
        { __typename?: 'Person' }
        & Pick<Person, 'id' | 'firstName' | 'lastName' | 'email' | 'type'>
      )>>>, users?: Maybe<Array<Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'createdAt' | 'updatedAt'>
      )>>> }
    )>, invitation?: Maybe<(
      { __typename?: 'Invitation' }
      & Pick<Invitation, 'id' | 'slug' | 'email' | 'status' | 'expires'>
    )>, role?: Maybe<(
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'key' | 'title' | 'createdAt' | 'updatedAt'>
    )>, application?: Maybe<(
      { __typename?: 'Application' }
      & Pick<Application, 'id' | 'key'>
    )> }
  )> }
);

export type CreateCompanyTalentMutationVariables = Exact<{
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  roleId?: Maybe<Scalars['Int']>;
  targetAccountId?: Maybe<Scalars['Int']>;
  meta?: Maybe<Scalars['JSON']>;
}>;


export type CreateCompanyTalentMutation = (
  { __typename?: 'Mutation' }
  & { createCompanyTalent?: Maybe<(
    { __typename?: 'CompanyTalent' }
    & Pick<CompanyTalent, 'id' | 'key' | 'companyAccountId' | 'talentAccountId'>
  )> }
);

export type UpdateCompanyTalentMutationVariables = Exact<{
  id: Scalars['Int'];
  data: CompanyTalentUpdateData;
}>;


export type UpdateCompanyTalentMutation = (
  { __typename?: 'Mutation' }
  & { updateCompanyTalent?: Maybe<(
    { __typename?: 'CompanyTalent' }
    & Pick<CompanyTalent, 'id' | 'key' | 'companyAccountId' | 'talentAccountId'>
  )> }
);

export type GetCompliancesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCompliancesQuery = (
  { __typename?: 'Query' }
  & { Compliance?: Maybe<Array<Maybe<(
    { __typename?: 'Compliance' }
    & Pick<Compliance, 'id' | 'name' | 'commitment' | 'target' | 'requiredDocuments' | 'createdAt' | 'updatedAt'>
    & { documents: Array<(
      { __typename?: 'Document' }
      & Pick<Document, 'id' | 'key' | 'createdAt'>
    )> }
  )>>> }
);

export type CreateComplianceMutationVariables = Exact<{
  name: Scalars['String'];
  commitment: Scalars['String'];
  target: Scalars['String'];
  requiredDocuments: Scalars['String'];
}>;


export type CreateComplianceMutation = (
  { __typename?: 'Mutation' }
  & { createCompliance?: Maybe<(
    { __typename?: 'Compliance' }
    & Pick<Compliance, 'id' | 'name' | 'commitment' | 'target' | 'requiredDocuments' | 'createdAt' | 'updatedAt'>
  )> }
);

export type UpdateComplianceMutationVariables = Exact<{
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  commitment?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['String']>;
  requiredDocuments?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateComplianceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateCompliance'>
);

export type UploadComplianceDocumentMutationVariables = Exact<{
  file: Scalars['Upload'];
  complianceId: Scalars['Int'];
  documentId?: Maybe<Scalars['Int']>;
}>;


export type UploadComplianceDocumentMutation = (
  { __typename?: 'Mutation' }
  & { uploadComplianceDocument?: Maybe<(
    { __typename?: 'Compliance' }
    & Pick<Compliance, 'id'>
    & { documents: Array<(
      { __typename?: 'Document' }
      & Pick<Document, 'id' | 'key' | 'createdAt'>
    )> }
  )> }
);

export type DeleteComplianceDocumentsMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteComplianceDocumentsMutation = (
  { __typename?: 'Mutation' }
  & { deleteComplianceDocuments?: Maybe<(
    { __typename?: 'Compliance' }
    & Pick<Compliance, 'id'>
    & { documents: Array<(
      { __typename?: 'Document' }
      & Pick<Document, 'id' | 'key' | 'createdAt'>
    )> }
  )> }
);

export type GenerateContentMutationVariables = Exact<{
  type: ContentType;
  targetAccountId?: Maybe<Scalars['Int']>;
  applicationId?: Maybe<Scalars['Int']>;
  personId?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['String']>;
  documentTypeName?: Maybe<Scalars['String']>;
}>;


export type GenerateContentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'generateContent'>
);

export type ConversationQueryVariables = Exact<{
  key?: Maybe<Scalars['String']>;
  targetUserId?: Maybe<Scalars['Int']>;
}>;


export type ConversationQuery = (
  { __typename?: 'Query' }
  & { Conversation?: Maybe<(
    { __typename?: 'Conversation' }
    & Pick<Conversation, 'id' | 'key' | 'createdAt' | 'disabled' | 'deleted' | 'disableNotification'>
    & { accountOwner?: Maybe<(
      { __typename?: 'Account' }
      & Pick<Account, 'id' | 'name'>
      & { accountType?: Maybe<(
        { __typename?: 'AccountType' }
        & Pick<AccountType, 'name'>
      )>, users?: Maybe<Array<Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'avatarKey' | 'active'>
      )>>> }
    )>, users: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'avatarKey'>
      & { account: (
        { __typename?: 'Account' }
        & Pick<Account, 'id' | 'name'>
        & { accountType?: Maybe<(
          { __typename?: 'AccountType' }
          & Pick<AccountType, 'name'>
        )> }
      ) }
    )>, messages: Array<(
      { __typename?: 'Message' }
      & Pick<Message, 'id' | 'deleted' | 'content' | 'createdAt' | 'ownedById'>
      & { ownedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'avatarKey'>
        & { account: (
          { __typename?: 'Account' }
          & { accountType?: Maybe<(
            { __typename?: 'AccountType' }
            & Pick<AccountType, 'name'>
          )> }
        ) }
      )>, readBy?: Maybe<Array<Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'firstName' | 'lastName'>
      )>>> }
    )> }
  )> }
);

export type ConversationsQueryVariables = Exact<{ [key: string]: never; }>;


export type ConversationsQuery = (
  { __typename?: 'Query' }
  & { Conversations: Array<(
    { __typename?: 'Conversation' }
    & Pick<Conversation, 'id' | 'key' | 'deleted' | 'disabled' | 'disableNotification' | 'createdAt'>
    & { mostRecentMessage?: Maybe<(
      { __typename?: 'Message' }
      & Pick<Message, 'id' | 'content' | 'createdAt'>
    )>, accountOwner?: Maybe<(
      { __typename?: 'Account' }
      & Pick<Account, 'id' | 'name'>
      & { accountType?: Maybe<(
        { __typename?: 'AccountType' }
        & Pick<AccountType, 'name'>
      )>, users?: Maybe<Array<Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'avatarKey'>
        & { account: (
          { __typename?: 'Account' }
          & { accountType?: Maybe<(
            { __typename?: 'AccountType' }
            & Pick<AccountType, 'name'>
          )> }
        ) }
      )>>> }
    )>, users: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'avatarKey'>
      & { account: (
        { __typename?: 'Account' }
        & Pick<Account, 'id' | 'name'>
        & { accountType?: Maybe<(
          { __typename?: 'AccountType' }
          & Pick<AccountType, 'name'>
        )> }
      ) }
    )> }
  )> }
);

export type CreateConversationMutationVariables = Exact<{
  userIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type CreateConversationMutation = (
  { __typename?: 'Mutation' }
  & { createConversation?: Maybe<(
    { __typename?: 'Conversation' }
    & Pick<Conversation, 'id' | 'key'>
    & { accountOwner?: Maybe<(
      { __typename?: 'Account' }
      & Pick<Account, 'id' | 'name'>
      & { accountType?: Maybe<(
        { __typename?: 'AccountType' }
        & Pick<AccountType, 'name'>
      )> }
    )>, users: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    )> }
  )> }
);

export type CreateMessageMutationVariables = Exact<{
  conversationId: Scalars['Int'];
  content: Scalars['String'];
}>;


export type CreateMessageMutation = (
  { __typename?: 'Mutation' }
  & { createMessage?: Maybe<(
    { __typename?: 'Message' }
    & Pick<Message, 'id' | 'deleted' | 'content'>
    & { ownedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    )> }
  )> }
);

export type UpdateMessageMutationVariables = Exact<{
  id: Scalars['Int'];
  content?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateMessageMutation = (
  { __typename?: 'Mutation' }
  & { updateMessage?: Maybe<(
    { __typename?: 'Message' }
    & Pick<Message, 'id' | 'deleted' | 'content'>
    & { ownedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    )> }
  )> }
);

export type UpdateConversationMutationVariables = Exact<{
  conversationId: Scalars['Int'];
  deleted?: Maybe<Scalars['Boolean']>;
  disabled?: Maybe<Scalars['Boolean']>;
  userId?: Maybe<Scalars['Int']>;
}>;


export type UpdateConversationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateConversation'>
);

export type DisableNotificationMutationVariables = Exact<{
  conversationId: Scalars['Int'];
  disableNotification: Scalars['Boolean'];
}>;


export type DisableNotificationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateConversation'>
);

export type GetUrlDocumentQueryVariables = Exact<{
  key: Scalars['String'];
}>;


export type GetUrlDocumentQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getUrlDocument'>
);

export type DeleteDocumentMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteDocumentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDocument'>
);

export type DocumentTypeQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type DocumentTypeQuery = (
  { __typename?: 'Query' }
  & { DocumentType?: Maybe<(
    { __typename?: 'DocumentType' }
    & Pick<DocumentType, 'id' | 'name' | 'displayName' | 'title' | 'tip' | 'instructions' | 'accountId'>
  )> }
);

export type DocumentTypesListQueryVariables = Exact<{
  cursor?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
  sortBy?: Maybe<QuerySortBy>;
  filters?: Maybe<DocumentTypesListFilters>;
}>;


export type DocumentTypesListQuery = (
  { __typename?: 'Query' }
  & { DocumentTypesList?: Maybe<(
    { __typename?: 'DocumentTypesListReturn' }
    & Pick<DocumentTypesListReturn, 'count' | 'cursorId' | 'hasMore'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'DocumentType' }
      & Pick<DocumentType, 'displayName' | 'instructions' | 'id' | 'name' | 'required' | 'tip' | 'title'>
    )>>> }
  )> }
);

export type DocumentTypesQueryVariables = Exact<{
  notIn?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  names?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  userData?: Maybe<DocumentTypesUserData>;
  typeGroup?: Maybe<DocumentTypeGroup>;
}>;


export type DocumentTypesQuery = (
  { __typename?: 'Query' }
  & { DocumentTypes?: Maybe<Array<Maybe<(
    { __typename?: 'DocumentType' }
    & Pick<DocumentType, 'displayName' | 'id' | 'name' | 'required' | 'tip' | 'title' | 'countryInstructions' | 'accountId'>
  )>>> }
);

export type CreateDocumentTypeMutationVariables = Exact<{
  displayName: Scalars['String'];
  tip: Scalars['String'];
  required: Scalars['Boolean'];
}>;


export type CreateDocumentTypeMutation = (
  { __typename?: 'Mutation' }
  & { createDocumentType?: Maybe<(
    { __typename?: 'DocumentType' }
    & Pick<DocumentType, 'displayName' | 'id' | 'name' | 'required' | 'tip' | 'title'>
  )> }
);

export type DeleteDocumentTypeMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteDocumentTypeMutation = (
  { __typename?: 'Mutation' }
  & { deleteDocumentType?: Maybe<(
    { __typename?: 'DocumentType' }
    & Pick<DocumentType, 'id'>
  )> }
);

export type UpdateDocumentTypeMutationVariables = Exact<{
  id: Scalars['Int'];
  displayName?: Maybe<Scalars['String']>;
  tip?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateDocumentTypeMutation = (
  { __typename?: 'Mutation' }
  & { updateDocumentType?: Maybe<(
    { __typename?: 'DocumentType' }
    & Pick<DocumentType, 'displayName' | 'id' | 'name' | 'required' | 'tip' | 'title'>
  )> }
);

export type ExportMutationVariables = Exact<{
  action: ExportAction;
  data?: Maybe<ExportData>;
}>;


export type ExportMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'export'>
);

export type FeedbacksQueryVariables = Exact<{
  cursor?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
  sortBy?: Maybe<QuerySortBy>;
  filters?: Maybe<FeedbacksFilters>;
}>;


export type FeedbacksQuery = (
  { __typename?: 'Query' }
  & { Feedbacks?: Maybe<(
    { __typename?: 'FeedbacksReturn' }
    & Pick<FeedbacksReturn, 'count' | 'cursorId' | 'hasMore'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'Feedback' }
      & Pick<Feedback, 'id' | 'content' | 'status' | 'createdAt' | 'updatedAt'>
      & { ownedBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName'>
      ), accountTask?: Maybe<(
        { __typename?: 'AccountTask' }
        & Pick<AccountTask, 'key'>
      )>, application?: Maybe<(
        { __typename?: 'Application' }
        & Pick<Application, 'key'>
      )>, role?: Maybe<(
        { __typename?: 'Role' }
        & Pick<Role, 'key'>
      )>, companyTalent?: Maybe<(
        { __typename?: 'CompanyTalent' }
        & Pick<CompanyTalent, 'key'>
      )>, applicationDocument?: Maybe<(
        { __typename?: 'ApplicationDocument' }
        & Pick<ApplicationDocument, 'id'>
        & { account?: Maybe<(
          { __typename?: 'Account' }
          & Pick<Account, 'id'>
        )>, type?: Maybe<(
          { __typename?: 'DocumentType' }
          & Pick<DocumentType, 'name'>
        )>, associatedAccountTask?: Maybe<(
          { __typename?: 'AccountTask' }
          & Pick<AccountTask, 'key'>
        )> }
      )> }
    )>>> }
  )> }
);

export type CreateFormMutationVariables = Exact<{
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  templateFormId?: Maybe<Scalars['Int']>;
}>;


export type CreateFormMutation = (
  { __typename?: 'Mutation' }
  & { createForm?: Maybe<(
    { __typename?: 'Form' }
    & Pick<Form, 'id' | 'name' | 'title'>
  )> }
);

export type FormsQueryVariables = Exact<{
  cursor?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
  sortBy?: Maybe<QuerySortBy>;
  filters?: Maybe<FormsFilters>;
}>;


export type FormsQuery = (
  { __typename?: 'Query' }
  & { Forms?: Maybe<(
    { __typename?: 'FormsReturn' }
    & Pick<FormsReturn, 'count' | 'cursorId' | 'hasMore'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'Form' }
      & Pick<Form, 'id' | 'name' | 'title' | 'description'>
    )>>> }
  )> }
);

export type GetFormForQuestionGroupsQueryVariables = Exact<{
  query?: Maybe<FormQueryInput>;
}>;


export type GetFormForQuestionGroupsQuery = (
  { __typename?: 'Query' }
  & { Form?: Maybe<(
    { __typename?: 'Form' }
    & Pick<Form, 'name' | 'title' | 'id' | 'accountId'>
    & { groups?: Maybe<Array<Maybe<(
      { __typename?: 'QuestionGrouping' }
      & Pick<QuestionGrouping, 'id' | 'uniqueKey' | 'subtitle' | 'title' | 'submissionStatus'>
    )>>> }
  )> }
);

export type GetFormForBuilderQueryVariables = Exact<{
  query?: Maybe<FormQueryInput>;
}>;


export type GetFormForBuilderQuery = (
  { __typename?: 'Query' }
  & { Form?: Maybe<(
    { __typename?: 'Form' }
    & Pick<Form, 'name' | 'title' | 'id' | 'accountId'>
    & { groups?: Maybe<Array<Maybe<(
      { __typename?: 'QuestionGrouping' }
      & Pick<QuestionGrouping, 'id' | 'uniqueKey' | 'subtitle' | 'title' | 'submissionStatus'>
    )>>>, applicationTemplates?: Maybe<Array<Maybe<(
      { __typename?: 'ApplicationTemplate' }
      & Pick<ApplicationTemplate, 'id' | 'key' | 'name'>
    )>>> }
  )> }
);

export type GetFormIncludesQueryVariables = Exact<{
  query?: Maybe<FormQueryInput>;
}>;


export type GetFormIncludesQuery = (
  { __typename?: 'Query' }
  & { Form?: Maybe<(
    { __typename?: 'Form' }
    & Pick<Form, 'name' | 'title' | 'id' | 'accountId'>
  )> }
);

export type GetLeadsFormQueryVariables = Exact<{
  formName: Scalars['String'];
}>;


export type GetLeadsFormQuery = (
  { __typename?: 'Query' }
  & { LeadsForm?: Maybe<(
    { __typename?: 'Form' }
    & Pick<Form, 'name' | 'title' | 'id'>
    & { content: Array<{ __typename: 'Question' } | (
      { __typename: 'QuestionGrouping' }
      & Pick<QuestionGrouping, 'id' | 'title' | 'subtitle'>
      & { questions?: Maybe<Array<(
        { __typename?: 'Question' }
        & Pick<Question, 'id' | 'question' | 'label' | 'tip' | 'inputType' | 'validationRules' | 'createdAt' | 'updatedAt' | 'maxCharacters'>
        & { questionOptions: Array<(
          { __typename?: 'QuestionOptions' }
          & Pick<QuestionOptions, 'id' | 'option'>
        )>, conditionsRequiredToRenderThisQuestion: Array<(
          { __typename?: 'EvaluatedCondition' }
          & Pick<EvaluatedCondition, 'id' | 'value' | 'scopeId' | 'isParentCondition'>
          & { formula?: Maybe<(
            { __typename?: 'ConditionFormula' }
            & Pick<ConditionFormula, 'operation' | 'expect'>
            & { question?: Maybe<(
              { __typename?: 'QuestionUniqueIdentifiers' }
              & Pick<QuestionUniqueIdentifiers, 'id' | 'label'>
            )> }
          )>, parentConditions?: Maybe<Array<Maybe<(
            { __typename?: 'Condition' }
            & Pick<Condition, 'id' | 'scopeId'>
          )>>> }
        )> }
      )>> }
    )> }
  )> }
);

export type GetFormByNameQueryVariables = Exact<{
  query?: Maybe<FormQueryInput>;
  associations?: Maybe<Scalars['String']>;
  excludeFormSubmissionAnswers: Scalars['Boolean'];
}>;


export type GetFormByNameQuery = (
  { __typename?: 'Query' }
  & { Form?: Maybe<(
    { __typename?: 'Form' }
    & Pick<Form, 'id' | 'name' | 'title'>
    & { formSubmissions: Array<(
      { __typename?: 'FormSubmission' }
      & Pick<FormSubmission, 'id' | 'version' | 'status' | 'createdAt'>
      & { answers?: Maybe<Array<(
        { __typename?: 'Answer' }
        & Pick<Answer, 'id' | 'answer'>
        & { question?: Maybe<(
          { __typename?: 'Question' }
          & Pick<Question, 'label'>
        )>, answerOptions: Array<(
          { __typename?: 'AnswerOptions' }
          & Pick<AnswerOptions, 'id' | 'type'>
        )> }
      )>>, form?: Maybe<(
        { __typename?: 'Form' }
        & Pick<Form, 'id' | 'name'>
      )> }
    )>, content: Array<(
      { __typename: 'Question' }
      & Pick<Question, 'id' | 'question' | 'label' | 'tip' | 'inputType' | 'validationRules' | 'createdAt' | 'updatedAt' | 'maxCharacters'>
      & { questionOptions: Array<(
        { __typename?: 'QuestionOptions' }
        & Pick<QuestionOptions, 'id' | 'option'>
      )>, conditionsRequiredToRenderThisQuestion: Array<(
        { __typename?: 'EvaluatedCondition' }
        & Pick<EvaluatedCondition, 'id' | 'value' | 'scopeId' | 'isParentCondition'>
        & { parentConditions?: Maybe<Array<Maybe<(
          { __typename?: 'Condition' }
          & Pick<Condition, 'id' | 'scopeId'>
        )>>>, formula?: Maybe<(
          { __typename?: 'ConditionFormula' }
          & Pick<ConditionFormula, 'operation' | 'expect'>
          & { question?: Maybe<(
            { __typename?: 'QuestionUniqueIdentifiers' }
            & Pick<QuestionUniqueIdentifiers, 'id' | 'label'>
          )> }
        )> }
      )>, answers: Array<(
        { __typename?: 'Answer' }
        & Pick<Answer, 'id' | 'answer' | 'updatedAt' | 'createdAt'>
        & { question?: Maybe<(
          { __typename?: 'Question' }
          & Pick<Question, 'label'>
        )> }
      )>, repeaterForm?: Maybe<(
        { __typename?: 'Form' }
        & Pick<Form, 'id' | 'name' | 'title'>
        & { formSubmissions: Array<(
          { __typename?: 'FormSubmission' }
          & Pick<FormSubmission, 'id' | 'version' | 'status' | 'createdAt'>
          & { answers: Array<(
            { __typename?: 'Answer' }
            & Pick<Answer, 'groupingId' | 'id' | 'answer'>
            & { question?: Maybe<(
              { __typename?: 'Question' }
              & Pick<Question, 'label'>
            )>, answerOptions: Array<(
              { __typename?: 'AnswerOptions' }
              & Pick<AnswerOptions, 'id' | 'type'>
            )> }
          )>, form?: Maybe<(
            { __typename?: 'Form' }
            & Pick<Form, 'id' | 'name'>
          )> }
        )>, content: Array<(
          { __typename: 'Question' }
          & Pick<Question, 'id' | 'question' | 'label' | 'tip' | 'inputType' | 'validationRules' | 'createdAt' | 'updatedAt' | 'maxCharacters'>
          & { questionOptions: Array<(
            { __typename?: 'QuestionOptions' }
            & Pick<QuestionOptions, 'id' | 'option'>
          )>, conditionsRequiredToRenderThisQuestion: Array<(
            { __typename?: 'EvaluatedCondition' }
            & Pick<EvaluatedCondition, 'id' | 'value' | 'scopeId' | 'isParentCondition'>
            & { parentConditions?: Maybe<Array<Maybe<(
              { __typename?: 'Condition' }
              & Pick<Condition, 'id' | 'scopeId'>
            )>>>, formula?: Maybe<(
              { __typename?: 'ConditionFormula' }
              & Pick<ConditionFormula, 'operation' | 'expect'>
              & { question?: Maybe<(
                { __typename?: 'QuestionUniqueIdentifiers' }
                & Pick<QuestionUniqueIdentifiers, 'id' | 'label'>
              )> }
            )> }
          )>, answers: Array<(
            { __typename?: 'Answer' }
            & Pick<Answer, 'id' | 'groupingId' | 'answer' | 'updatedAt' | 'createdAt'>
            & { question?: Maybe<(
              { __typename?: 'Question' }
              & Pick<Question, 'label'>
            )> }
          )> }
        ) | (
          { __typename: 'QuestionGrouping' }
          & Pick<QuestionGrouping, 'id' | 'title' | 'subtitle'>
          & { questions?: Maybe<Array<(
            { __typename?: 'Question' }
            & Pick<Question, 'id' | 'question' | 'label' | 'tip' | 'inputType' | 'validationRules' | 'createdAt' | 'updatedAt' | 'maxCharacters'>
            & { questionOptions: Array<(
              { __typename?: 'QuestionOptions' }
              & Pick<QuestionOptions, 'id' | 'option'>
            )>, answers: Array<(
              { __typename?: 'Answer' }
              & Pick<Answer, 'id' | 'answer' | 'groupingId'>
              & { question?: Maybe<(
                { __typename?: 'Question' }
                & Pick<Question, 'label'>
              )>, answerOptions: Array<(
                { __typename?: 'AnswerOptions' }
                & Pick<AnswerOptions, 'id' | 'type'>
              )> }
            )>, conditionsRequiredToRenderThisQuestion: Array<(
              { __typename?: 'EvaluatedCondition' }
              & Pick<EvaluatedCondition, 'id' | 'value' | 'scopeId' | 'isParentCondition'>
              & { parentConditions?: Maybe<Array<Maybe<(
                { __typename?: 'Condition' }
                & Pick<Condition, 'id' | 'scopeId'>
              )>>>, formula?: Maybe<(
                { __typename?: 'ConditionFormula' }
                & Pick<ConditionFormula, 'operation' | 'expect'>
                & { question?: Maybe<(
                  { __typename?: 'QuestionUniqueIdentifiers' }
                  & Pick<QuestionUniqueIdentifiers, 'id' | 'label'>
                )> }
              )> }
            )> }
          )>> }
        )> }
      )> }
    ) | (
      { __typename: 'QuestionGrouping' }
      & Pick<QuestionGrouping, 'id' | 'title' | 'subtitle'>
      & { questions?: Maybe<Array<(
        { __typename?: 'Question' }
        & Pick<Question, 'id' | 'question' | 'label' | 'tip' | 'inputType' | 'validationRules' | 'createdAt' | 'updatedAt' | 'maxCharacters'>
        & { questionOptions: Array<(
          { __typename?: 'QuestionOptions' }
          & Pick<QuestionOptions, 'id' | 'option'>
        )>, answers: Array<(
          { __typename?: 'Answer' }
          & Pick<Answer, 'id' | 'answer'>
          & { question?: Maybe<(
            { __typename?: 'Question' }
            & Pick<Question, 'label'>
          )>, answerOptions: Array<(
            { __typename?: 'AnswerOptions' }
            & Pick<AnswerOptions, 'id' | 'type'>
          )> }
        )>, conditionsRequiredToRenderThisQuestion: Array<(
          { __typename?: 'EvaluatedCondition' }
          & Pick<EvaluatedCondition, 'id' | 'value' | 'scopeId' | 'isParentCondition'>
          & { parentConditions?: Maybe<Array<Maybe<(
            { __typename?: 'Condition' }
            & Pick<Condition, 'id' | 'scopeId'>
          )>>>, formula?: Maybe<(
            { __typename?: 'ConditionFormula' }
            & Pick<ConditionFormula, 'operation' | 'expect'>
            & { question?: Maybe<(
              { __typename?: 'QuestionUniqueIdentifiers' }
              & Pick<QuestionUniqueIdentifiers, 'id' | 'label'>
            )> }
          )> }
        )>, repeaterForm?: Maybe<(
          { __typename?: 'Form' }
          & Pick<Form, 'id' | 'name' | 'title'>
          & { formSubmissions: Array<(
            { __typename?: 'FormSubmission' }
            & Pick<FormSubmission, 'id' | 'version' | 'status' | 'createdAt'>
            & { answers: Array<(
              { __typename?: 'Answer' }
              & Pick<Answer, 'groupingId' | 'id' | 'answer'>
              & { question?: Maybe<(
                { __typename?: 'Question' }
                & Pick<Question, 'label'>
              )>, answerOptions: Array<(
                { __typename?: 'AnswerOptions' }
                & Pick<AnswerOptions, 'id' | 'type'>
              )> }
            )>, form?: Maybe<(
              { __typename?: 'Form' }
              & Pick<Form, 'id' | 'name'>
            )> }
          )>, content: Array<(
            { __typename: 'Question' }
            & Pick<Question, 'id' | 'question' | 'label' | 'tip' | 'inputType' | 'validationRules' | 'createdAt' | 'updatedAt' | 'maxCharacters'>
            & { questionOptions: Array<(
              { __typename?: 'QuestionOptions' }
              & Pick<QuestionOptions, 'id' | 'option'>
            )>, conditionsRequiredToRenderThisQuestion: Array<(
              { __typename?: 'EvaluatedCondition' }
              & Pick<EvaluatedCondition, 'id' | 'value' | 'scopeId' | 'isParentCondition'>
              & { parentConditions?: Maybe<Array<Maybe<(
                { __typename?: 'Condition' }
                & Pick<Condition, 'id' | 'scopeId'>
              )>>>, formula?: Maybe<(
                { __typename?: 'ConditionFormula' }
                & Pick<ConditionFormula, 'operation' | 'expect'>
                & { question?: Maybe<(
                  { __typename?: 'QuestionUniqueIdentifiers' }
                  & Pick<QuestionUniqueIdentifiers, 'id' | 'label'>
                )> }
              )> }
            )>, answers: Array<(
              { __typename?: 'Answer' }
              & Pick<Answer, 'groupingId' | 'id' | 'answer' | 'updatedAt' | 'createdAt'>
              & { question?: Maybe<(
                { __typename?: 'Question' }
                & Pick<Question, 'label'>
              )> }
            )> }
          ) | (
            { __typename: 'QuestionGrouping' }
            & Pick<QuestionGrouping, 'id' | 'title' | 'subtitle'>
            & { questions?: Maybe<Array<(
              { __typename?: 'Question' }
              & Pick<Question, 'id' | 'question' | 'label' | 'tip' | 'inputType' | 'validationRules' | 'createdAt' | 'updatedAt' | 'maxCharacters'>
              & { questionOptions: Array<(
                { __typename?: 'QuestionOptions' }
                & Pick<QuestionOptions, 'id' | 'option'>
              )>, answers: Array<(
                { __typename?: 'Answer' }
                & Pick<Answer, 'groupingId' | 'id' | 'answer'>
                & { question?: Maybe<(
                  { __typename?: 'Question' }
                  & Pick<Question, 'label'>
                )>, answerOptions: Array<(
                  { __typename?: 'AnswerOptions' }
                  & Pick<AnswerOptions, 'id' | 'type'>
                )> }
              )>, conditionsRequiredToRenderThisQuestion: Array<(
                { __typename?: 'EvaluatedCondition' }
                & Pick<EvaluatedCondition, 'id' | 'value' | 'scopeId' | 'isParentCondition'>
                & { parentConditions?: Maybe<Array<Maybe<(
                  { __typename?: 'Condition' }
                  & Pick<Condition, 'id' | 'scopeId'>
                )>>>, formula?: Maybe<(
                  { __typename?: 'ConditionFormula' }
                  & Pick<ConditionFormula, 'operation' | 'expect'>
                  & { question?: Maybe<(
                    { __typename?: 'QuestionUniqueIdentifiers' }
                    & Pick<QuestionUniqueIdentifiers, 'id' | 'label'>
                  )> }
                )> }
              )> }
            )>> }
          )> }
        )> }
      )>> }
    )> }
  )> }
);

export type GetFormByNamePreviewQueryVariables = Exact<{
  query?: Maybe<FormQueryInput>;
  associations?: Maybe<Scalars['String']>;
}>;


export type GetFormByNamePreviewQuery = (
  { __typename?: 'Query' }
  & { Form?: Maybe<(
    { __typename?: 'Form' }
    & Pick<Form, 'id' | 'name' | 'title'>
    & { formSubmissions: Array<(
      { __typename?: 'FormSubmission' }
      & Pick<FormSubmission, 'id' | 'version' | 'status' | 'createdAt'>
      & { form?: Maybe<(
        { __typename?: 'Form' }
        & Pick<Form, 'id' | 'name'>
      )> }
    )>, content: Array<(
      { __typename: 'Question' }
      & Pick<Question, 'id' | 'question' | 'label' | 'tip' | 'inputType' | 'validationRules' | 'createdAt' | 'updatedAt' | 'maxCharacters'>
      & { questionOptions: Array<(
        { __typename?: 'QuestionOptions' }
        & Pick<QuestionOptions, 'id' | 'option'>
      )>, conditionsRequiredToRenderThisQuestion: Array<(
        { __typename?: 'EvaluatedCondition' }
        & Pick<EvaluatedCondition, 'id' | 'value' | 'scopeId' | 'isParentCondition'>
        & { parentConditions?: Maybe<Array<Maybe<(
          { __typename?: 'Condition' }
          & Pick<Condition, 'id' | 'scopeId'>
        )>>>, formula?: Maybe<(
          { __typename?: 'ConditionFormula' }
          & Pick<ConditionFormula, 'operation' | 'expect'>
          & { question?: Maybe<(
            { __typename?: 'QuestionUniqueIdentifiers' }
            & Pick<QuestionUniqueIdentifiers, 'id' | 'label'>
          )> }
        )> }
      )>, repeaterForm?: Maybe<(
        { __typename?: 'Form' }
        & Pick<Form, 'id' | 'name' | 'title'>
        & { content: Array<(
          { __typename: 'Question' }
          & Pick<Question, 'id' | 'question' | 'label' | 'tip' | 'inputType' | 'validationRules' | 'createdAt' | 'updatedAt' | 'maxCharacters'>
          & { questionOptions: Array<(
            { __typename?: 'QuestionOptions' }
            & Pick<QuestionOptions, 'id' | 'option'>
          )>, conditionsRequiredToRenderThisQuestion: Array<(
            { __typename?: 'EvaluatedCondition' }
            & Pick<EvaluatedCondition, 'id' | 'value' | 'scopeId' | 'isParentCondition'>
            & { parentConditions?: Maybe<Array<Maybe<(
              { __typename?: 'Condition' }
              & Pick<Condition, 'id' | 'scopeId'>
            )>>>, formula?: Maybe<(
              { __typename?: 'ConditionFormula' }
              & Pick<ConditionFormula, 'operation' | 'expect'>
              & { question?: Maybe<(
                { __typename?: 'QuestionUniqueIdentifiers' }
                & Pick<QuestionUniqueIdentifiers, 'id' | 'label'>
              )> }
            )> }
          )> }
        ) | (
          { __typename: 'QuestionGrouping' }
          & Pick<QuestionGrouping, 'id' | 'title' | 'subtitle'>
          & { questions?: Maybe<Array<(
            { __typename?: 'Question' }
            & Pick<Question, 'id' | 'question' | 'label' | 'tip' | 'inputType' | 'validationRules' | 'createdAt' | 'updatedAt' | 'maxCharacters'>
            & { questionOptions: Array<(
              { __typename?: 'QuestionOptions' }
              & Pick<QuestionOptions, 'id' | 'option'>
            )>, conditionsRequiredToRenderThisQuestion: Array<(
              { __typename?: 'EvaluatedCondition' }
              & Pick<EvaluatedCondition, 'id' | 'value' | 'scopeId' | 'isParentCondition'>
              & { parentConditions?: Maybe<Array<Maybe<(
                { __typename?: 'Condition' }
                & Pick<Condition, 'id' | 'scopeId'>
              )>>>, formula?: Maybe<(
                { __typename?: 'ConditionFormula' }
                & Pick<ConditionFormula, 'operation' | 'expect'>
                & { question?: Maybe<(
                  { __typename?: 'QuestionUniqueIdentifiers' }
                  & Pick<QuestionUniqueIdentifiers, 'id' | 'label'>
                )> }
              )> }
            )> }
          )>> }
        )> }
      )> }
    ) | (
      { __typename: 'QuestionGrouping' }
      & Pick<QuestionGrouping, 'id' | 'title' | 'subtitle'>
      & { questions?: Maybe<Array<(
        { __typename?: 'Question' }
        & Pick<Question, 'id' | 'question' | 'label' | 'tip' | 'inputType' | 'validationRules' | 'createdAt' | 'updatedAt' | 'maxCharacters'>
        & { questionOptions: Array<(
          { __typename?: 'QuestionOptions' }
          & Pick<QuestionOptions, 'id' | 'option'>
        )>, conditionsRequiredToRenderThisQuestion: Array<(
          { __typename?: 'EvaluatedCondition' }
          & Pick<EvaluatedCondition, 'id' | 'value' | 'scopeId' | 'isParentCondition'>
          & { parentConditions?: Maybe<Array<Maybe<(
            { __typename?: 'Condition' }
            & Pick<Condition, 'id' | 'scopeId'>
          )>>>, formula?: Maybe<(
            { __typename?: 'ConditionFormula' }
            & Pick<ConditionFormula, 'operation' | 'expect'>
            & { question?: Maybe<(
              { __typename?: 'QuestionUniqueIdentifiers' }
              & Pick<QuestionUniqueIdentifiers, 'id' | 'label'>
            )> }
          )> }
        )>, repeaterForm?: Maybe<(
          { __typename?: 'Form' }
          & Pick<Form, 'id' | 'name' | 'title'>
          & { content: Array<(
            { __typename: 'Question' }
            & Pick<Question, 'id' | 'question' | 'label' | 'tip' | 'inputType' | 'validationRules' | 'createdAt' | 'updatedAt' | 'maxCharacters'>
            & { questionOptions: Array<(
              { __typename?: 'QuestionOptions' }
              & Pick<QuestionOptions, 'id' | 'option'>
            )>, conditionsRequiredToRenderThisQuestion: Array<(
              { __typename?: 'EvaluatedCondition' }
              & Pick<EvaluatedCondition, 'id' | 'value' | 'scopeId' | 'isParentCondition'>
              & { parentConditions?: Maybe<Array<Maybe<(
                { __typename?: 'Condition' }
                & Pick<Condition, 'id' | 'scopeId'>
              )>>>, formula?: Maybe<(
                { __typename?: 'ConditionFormula' }
                & Pick<ConditionFormula, 'operation' | 'expect'>
                & { question?: Maybe<(
                  { __typename?: 'QuestionUniqueIdentifiers' }
                  & Pick<QuestionUniqueIdentifiers, 'id' | 'label'>
                )> }
              )> }
            )> }
          ) | (
            { __typename: 'QuestionGrouping' }
            & Pick<QuestionGrouping, 'id' | 'title' | 'subtitle'>
            & { questions?: Maybe<Array<(
              { __typename?: 'Question' }
              & Pick<Question, 'id' | 'question' | 'label' | 'tip' | 'inputType' | 'validationRules' | 'createdAt' | 'updatedAt' | 'maxCharacters'>
              & { questionOptions: Array<(
                { __typename?: 'QuestionOptions' }
                & Pick<QuestionOptions, 'id' | 'option'>
              )>, conditionsRequiredToRenderThisQuestion: Array<(
                { __typename?: 'EvaluatedCondition' }
                & Pick<EvaluatedCondition, 'id' | 'value' | 'scopeId' | 'isParentCondition'>
                & { parentConditions?: Maybe<Array<Maybe<(
                  { __typename?: 'Condition' }
                  & Pick<Condition, 'id' | 'scopeId'>
                )>>>, formula?: Maybe<(
                  { __typename?: 'ConditionFormula' }
                  & Pick<ConditionFormula, 'operation' | 'expect'>
                  & { question?: Maybe<(
                    { __typename?: 'QuestionUniqueIdentifiers' }
                    & Pick<QuestionUniqueIdentifiers, 'id' | 'label'>
                  )> }
                )> }
              )> }
            )>> }
          )> }
        )> }
      )>> }
    )> }
  )> }
);

export type DeleteFormMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteFormMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteForm'>
);

export type UpdateFormMutationVariables = Exact<{
  id: Scalars['Int'];
  data: FormUpdateData;
}>;


export type UpdateFormMutation = (
  { __typename?: 'Mutation' }
  & { updateForm?: Maybe<(
    { __typename?: 'Form' }
    & Pick<Form, 'accountId' | 'id' | 'name'>
  )> }
);

export type HandleFormBuilderMutationVariables = Exact<{
  action: HandleFormBuilderAction;
  formId?: Maybe<Scalars['Int']>;
  groupId?: Maybe<Scalars['Int']>;
  questionId?: Maybe<Scalars['Int']>;
  data?: Maybe<HandleFormBuilderData>;
}>;


export type HandleFormBuilderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'handleFormBuilder'>
);

export type SubmitFormMutationVariables = Exact<{
  input: SubmitFormMutationInput;
  targetAccountId?: Maybe<Scalars['Int']>;
  args?: Maybe<SubmitFormMutationArgs>;
}>;


export type SubmitFormMutation = (
  { __typename?: 'Mutation' }
  & { submitForm?: Maybe<(
    { __typename?: 'FormSubmission' }
    & Pick<FormSubmission, 'id' | 'createdAt' | 'status' | 'version'>
  )> }
);

export type SubmitLeadsFormMutationVariables = Exact<{
  input: SubmitFormMutationInput;
  leadEmbedId: Scalars['String'];
  recaptchaToken: Scalars['String'];
}>;


export type SubmitLeadsFormMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'submitLeadsForm'>
);

export type UpdateMostRecentFormDraftMutationVariables = Exact<{
  input: SubmitFormMutationInput;
  accountId?: Maybe<Scalars['Int']>;
}>;


export type UpdateMostRecentFormDraftMutation = (
  { __typename?: 'Mutation' }
  & { updateMostRecentFormDraft?: Maybe<(
    { __typename?: 'FormSubmission' }
    & Pick<FormSubmission, 'id' | 'createdAt' | 'status' | 'version'>
  )> }
);

export type FormSubmissionsQueryVariables = Exact<{
  action?: Maybe<FormSubmissionGetAction>;
  data?: Maybe<FormSubmissionGetData>;
}>;


export type FormSubmissionsQuery = (
  { __typename?: 'Query' }
  & { FormSubmissions?: Maybe<Array<Maybe<(
    { __typename?: 'FormSubmission' }
    & Pick<FormSubmission, 'id'>
    & { group?: Maybe<(
      { __typename?: 'QuestionGrouping' }
      & Pick<QuestionGrouping, 'id'>
    )> }
  )>>> }
);

export type GetInvitationQueryVariables = Exact<{
  slug?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
}>;


export type GetInvitationQuery = (
  { __typename?: 'Query' }
  & { Invitation?: Maybe<(
    { __typename?: 'Invitation' }
    & Pick<Invitation, 'id' | 'slug' | 'email' | 'status' | 'expires' | 'isExpired' | 'accountType'>
  )> }
);

export type GetInvitationsQueryVariables = Exact<{
  status?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  targetAccountId?: Maybe<Scalars['Int']>;
}>;


export type GetInvitationsQuery = (
  { __typename?: 'Query' }
  & { Invitations?: Maybe<Array<Maybe<(
    { __typename?: 'Invitation' }
    & Pick<Invitation, 'id' | 'slug' | 'email' | 'status' | 'expires' | 'createdAt' | 'isExpired' | 'targetAccountId' | 'meta' | 'accountType'>
  )>>> }
);

export type SendInvitationMutationVariables = Exact<{
  email?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  targetAccountId?: Maybe<Scalars['Int']>;
  resendSlug?: Maybe<Scalars['String']>;
  permissionGroup?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
}>;


export type SendInvitationMutation = (
  { __typename?: 'Mutation' }
  & { sendInvitation?: Maybe<(
    { __typename?: 'Invitation' }
    & Pick<Invitation, 'id' | 'slug' | 'email' | 'status' | 'meta'>
  )> }
);

export type CompleteInvitationMutationVariables = Exact<{
  email?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
}>;


export type CompleteInvitationMutation = (
  { __typename?: 'Mutation' }
  & { completeInvitation?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
  )> }
);

export type UpdateInvitationMutationVariables = Exact<{
  id: Scalars['Int'];
  data: InvitationUpdateData;
}>;


export type UpdateInvitationMutation = (
  { __typename?: 'Mutation' }
  & { updateInvitation?: Maybe<(
    { __typename?: 'Invitation' }
    & Pick<Invitation, 'id' | 'slug' | 'email' | 'status' | 'expires' | 'createdAt' | 'updatedAt'>
  )> }
);

export type DeleteInvitationMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteInvitationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteInvitation'>
);

export type LawyerAssociationsQueryVariables = Exact<{
  cursor?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
  sortBy?: Maybe<QuerySortBy>;
  filters?: Maybe<LawyerAssociationsFilters>;
}>;


export type LawyerAssociationsQuery = (
  { __typename?: 'Query' }
  & { LawyerAssociations?: Maybe<(
    { __typename?: 'LawyerAssociationsReturn' }
    & Pick<LawyerAssociationsReturn, 'cursorId' | 'hasMore' | 'count'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'LawyerAssociation' }
      & Pick<LawyerAssociation, 'id' | 'key' | 'createdAt' | 'meta'>
      & { associationAccount: (
        { __typename?: 'Account' }
        & Pick<Account, 'id' | 'name' | 'active'>
        & { primaryUser?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'firstName' | 'lastName' | 'active'>
        )>, accountType?: Maybe<(
          { __typename?: 'AccountType' }
          & Pick<AccountType, 'name'>
        )>, ownerApplications?: Maybe<Array<Maybe<(
          { __typename?: 'Application' }
          & Pick<Application, 'createdAt' | 'key'>
        )>>>, companyCompanyTalents?: Maybe<Array<Maybe<(
          { __typename?: 'CompanyTalent' }
          & Pick<CompanyTalent, 'id' | 'key'>
          & { role?: Maybe<(
            { __typename?: 'Role' }
            & Pick<Role, 'title' | 'key'>
          )>, talentAccount?: Maybe<(
            { __typename?: 'Account' }
            & { primaryUser?: Maybe<(
              { __typename?: 'User' }
              & Pick<User, 'firstName' | 'lastName' | 'email'>
            )> }
          )> }
        )>>>, roles?: Maybe<Array<Maybe<(
          { __typename?: 'Role' }
          & Pick<Role, 'key' | 'title'>
        )>>> }
      ), lawyerAccount: (
        { __typename?: 'Account' }
        & Pick<Account, 'id' | 'name'>
      ) }
    )>>> }
  )> }
);

export type LawyerAssociationQueryVariables = Exact<{
  key?: Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
}>;


export type LawyerAssociationQuery = (
  { __typename?: 'Query' }
  & { LawyerAssociation?: Maybe<(
    { __typename?: 'LawyerAssociation' }
    & Pick<LawyerAssociation, 'id' | 'key' | 'contactInfo' | 'meta'>
    & { lawyerAccount: (
      { __typename?: 'Account' }
      & Pick<Account, 'id' | 'name'>
      & { primaryUser?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'firstName' | 'lastName'>
      )>, accountPermissionGroups?: Maybe<(
        { __typename?: 'AccountPermissionGroup' }
        & { permissionGroup?: Maybe<(
          { __typename?: 'PermissionGroup' }
          & Pick<PermissionGroup, 'name'>
        )> }
      )> }
    ), associationAccount: (
      { __typename?: 'Account' }
      & Pick<Account, 'id' | 'name' | 'active'>
      & { accountType?: Maybe<(
        { __typename?: 'AccountType' }
        & Pick<AccountType, 'name'>
      )>, primaryUser?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'id' | 'email' | 'firstName' | 'lastName'>
      )>, ownerApplications?: Maybe<Array<Maybe<(
        { __typename?: 'Application' }
        & Pick<Application, 'key' | 'createdAt'>
        & { statuses?: Maybe<Array<(
          { __typename?: 'Status' }
          & Pick<Status, 'type' | 'value' | 'updatedAt'>
        )>>, talent?: Maybe<Array<Maybe<(
          { __typename?: 'CompanyTalent' }
          & { talentAccount?: Maybe<(
            { __typename?: 'Account' }
            & { primaryUser?: Maybe<(
              { __typename?: 'User' }
              & Pick<User, 'firstName' | 'lastName'>
            )> }
          )> }
        )>>> }
      )>>>, companyCompanyTalents?: Maybe<Array<Maybe<(
        { __typename?: 'CompanyTalent' }
        & Pick<CompanyTalent, 'id' | 'key' | 'firstName' | 'lastName' | 'email'>
        & { role?: Maybe<(
          { __typename?: 'Role' }
          & Pick<Role, 'title' | 'key'>
        )>, talentAccount?: Maybe<(
          { __typename?: 'Account' }
          & { primaryUser?: Maybe<(
            { __typename?: 'User' }
            & Pick<User, 'firstName' | 'lastName' | 'email'>
          )>, persons?: Maybe<Array<Maybe<(
            { __typename?: 'Person' }
            & Pick<Person, 'firstName' | 'lastName' | 'type'>
          )>>> }
        )> }
      )>>>, roles?: Maybe<Array<Maybe<(
        { __typename?: 'Role' }
        & Pick<Role, 'key' | 'title'>
        & { companyTalents?: Maybe<Array<Maybe<(
          { __typename?: 'CompanyTalent' }
          & Pick<CompanyTalent, 'firstName' | 'lastName' | 'email'>
          & { talentAccount?: Maybe<(
            { __typename?: 'Account' }
            & { primaryUser?: Maybe<(
              { __typename?: 'User' }
              & Pick<User, 'firstName' | 'lastName'>
            )> }
          )> }
        )>>> }
      )>>> }
    ), assignedUsers?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'active'>
    )>>>, welcomeMessage?: Maybe<(
      { __typename?: 'LawyerAssociationWelcomeMessage' }
      & Pick<LawyerAssociationWelcomeMessage, 'fromName' | 'imageUrl' | 'message' | 'enabled'>
    )> }
  )> }
);

export type LawyerAssociationIncludesQueryVariables = Exact<{
  accountId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  includeAssignedUsers: Scalars['Boolean'];
  includeAssociationAccount: Scalars['Boolean'];
  includeLawyerAccount: Scalars['Boolean'];
}>;


export type LawyerAssociationIncludesQuery = (
  { __typename?: 'Query' }
  & { LawyerAssociation?: Maybe<(
    { __typename?: 'LawyerAssociation' }
    & Pick<LawyerAssociation, 'id' | 'key' | 'meta'>
    & { assignedUsers?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'active'>
    )>>>, associationAccount?: Maybe<(
      { __typename?: 'Account' }
      & Pick<Account, 'id' | 'name' | 'active'>
      & { accountType?: Maybe<(
        { __typename?: 'AccountType' }
        & Pick<AccountType, 'name'>
      )>, primaryUser?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'id' | 'email' | 'firstName' | 'lastName'>
      )> }
    )>, lawyerAccount?: Maybe<(
      { __typename?: 'Account' }
      & Pick<Account, 'id' | 'name'>
      & { primaryUser?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'firstName' | 'lastName'>
      )> }
    )> }
  )> }
);

export type LawyerAssociationAssignedLawyersQueryVariables = Exact<{
  key?: Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['Int']>;
}>;


export type LawyerAssociationAssignedLawyersQuery = (
  { __typename?: 'Query' }
  & { LawyerAssociation?: Maybe<(
    { __typename?: 'LawyerAssociation' }
    & Pick<LawyerAssociation, 'id' | 'key'>
    & { assignedUsers?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'active'>
    )>>> }
  )> }
);

export type DeleteLawyerAssociationMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteLawyerAssociationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteLawyerAssociation'>
);

export type CreateLawyerAssociationMutationVariables = Exact<{
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
}>;


export type CreateLawyerAssociationMutation = (
  { __typename?: 'Mutation' }
  & { createLawyerAssociation?: Maybe<(
    { __typename?: 'LawyerAssociation' }
    & Pick<LawyerAssociation, 'id' | 'key'>
  )> }
);

export type HandleLawyerAssociationMutationVariables = Exact<{
  action: HandleLawyerAssociationAction;
  lawyerAssociationId: Scalars['Int'];
  data?: Maybe<HandleLawyerAssociationData>;
}>;


export type HandleLawyerAssociationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'handleLawyerAssociation'>
);

export type GetLeadEmbedQueryVariables = Exact<{
  embedId: Scalars['String'];
}>;


export type GetLeadEmbedQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getLeadEmbed'>
);

export type LeadsQueryVariables = Exact<{
  cursor?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
  sortBy?: Maybe<QuerySortBy>;
}>;


export type LeadsQuery = (
  { __typename?: 'Query' }
  & { Leads?: Maybe<(
    { __typename?: 'LeadsReturn' }
    & Pick<LeadsReturn, 'count' | 'cursorId' | 'hasMore'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'Lead' }
      & Pick<Lead, 'firstName' | 'id' | 'key' | 'lastName' | 'email' | 'updatedAt' | 'createdAt'>
    )>>> }
  )> }
);

export type LeadQueryVariables = Exact<{
  key: Scalars['String'];
}>;


export type LeadQuery = (
  { __typename?: 'Query' }
  & { Lead?: Maybe<(
    { __typename?: 'Lead' }
    & Pick<Lead, 'firstName' | 'id' | 'key' | 'lastName' | 'email' | 'updatedAt' | 'createdAt' | 'meta'>
    & { accountEmbed?: Maybe<(
      { __typename?: 'AccountEmbed' }
      & { form?: Maybe<(
        { __typename?: 'Form' }
        & Pick<Form, 'name'>
      )> }
    )> }
  )> }
);

export type HandleLeadMutationVariables = Exact<{
  action: HandleLeadAction;
  leadId: Scalars['Int'];
  data?: Maybe<HandleLeadData>;
}>;


export type HandleLeadMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'handleLead'>
);

export type MetaQueryVariables = Exact<{
  key: Scalars['String'];
}>;


export type MetaQuery = (
  { __typename?: 'Query' }
  & { Meta?: Maybe<(
    { __typename?: 'Meta' }
    & Pick<Meta, 'id' | 'key' | 'value'>
  )> }
);

export type UpdateNoteMutationVariables = Exact<{
  id: Scalars['Int'];
  content: Scalars['String'];
  mentionedUserIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type UpdateNoteMutation = (
  { __typename?: 'Mutation' }
  & { updateNote?: Maybe<(
    { __typename?: 'Note' }
    & Pick<Note, 'id' | 'content' | 'createdAt' | 'updatedAt'>
    & { ownedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'email'>
    )> }
  )> }
);

export type DeleteNoteMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteNoteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteNote'>
);

export type CreateApplicationDocumentNoteMutationVariables = Exact<{
  applicationDocumentId: Scalars['Int'];
  content: Scalars['String'];
  mentionedUserIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
  applicationId?: Maybe<Scalars['Int']>;
  accountTaskId?: Maybe<Scalars['Int']>;
}>;


export type CreateApplicationDocumentNoteMutation = (
  { __typename?: 'Mutation' }
  & { createNote?: Maybe<(
    { __typename?: 'Note' }
    & Pick<Note, 'id' | 'content' | 'createdAt' | 'updatedAt'>
    & { ownedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'email'>
    )> }
  )> }
);

export type CreateAccountDocumentNoteMutationVariables = Exact<{
  accountDocumentId: Scalars['Int'];
  content: Scalars['String'];
  mentionedUserIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type CreateAccountDocumentNoteMutation = (
  { __typename?: 'Mutation' }
  & { createNote?: Maybe<(
    { __typename?: 'Note' }
    & Pick<Note, 'id' | 'content' | 'createdAt' | 'updatedAt'>
    & { ownedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'email'>
    )> }
  )> }
);

export type CreateAccountTaskNoteMutationVariables = Exact<{
  accountTaskId: Scalars['Int'];
  content: Scalars['String'];
  mentionedUserIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type CreateAccountTaskNoteMutation = (
  { __typename?: 'Mutation' }
  & { createNote?: Maybe<(
    { __typename?: 'Note' }
    & Pick<Note, 'id' | 'content' | 'createdAt' | 'updatedAt'>
    & { ownedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'email'>
    )> }
  )> }
);

export type CreateLawyerAssociationNoteMutationVariables = Exact<{
  lawyerAssociationId: Scalars['Int'];
  content: Scalars['String'];
  mentionedUserIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type CreateLawyerAssociationNoteMutation = (
  { __typename?: 'Mutation' }
  & { createNote?: Maybe<(
    { __typename?: 'Note' }
    & Pick<Note, 'id' | 'content' | 'createdAt' | 'updatedAt'>
    & { ownedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'email'>
    )> }
  )> }
);

export type CreateApplicationNoteMutationVariables = Exact<{
  applicationId: Scalars['Int'];
  content: Scalars['String'];
  mentionedUserIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type CreateApplicationNoteMutation = (
  { __typename?: 'Mutation' }
  & { createNote?: Maybe<(
    { __typename?: 'Note' }
    & Pick<Note, 'id' | 'content' | 'createdAt' | 'updatedAt'>
    & { ownedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'email'>
    )> }
  )> }
);

export type CreateCompanyTalentNoteMutationVariables = Exact<{
  companyTalentId: Scalars['Int'];
  content: Scalars['String'];
  mentionedUserIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type CreateCompanyTalentNoteMutation = (
  { __typename?: 'Mutation' }
  & { createNote?: Maybe<(
    { __typename?: 'Note' }
    & Pick<Note, 'id' | 'content' | 'createdAt' | 'updatedAt'>
    & { ownedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'email'>
    )> }
  )> }
);

export type CreateCompanyComplianceNoteMutationVariables = Exact<{
  companyComplianceId: Scalars['Int'];
  content: Scalars['String'];
}>;


export type CreateCompanyComplianceNoteMutation = (
  { __typename?: 'Mutation' }
  & { createNote?: Maybe<(
    { __typename?: 'Note' }
    & Pick<Note, 'id' | 'content' | 'createdAt' | 'updatedAt'>
    & { ownedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'email'>
    )> }
  )> }
);

export type GetAllNotesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllNotesQuery = (
  { __typename?: 'Query' }
  & { Notes: Array<(
    { __typename?: 'Note' }
    & Pick<Note, 'id' | 'content' | 'createdAt' | 'updatedAt'>
    & { ownedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'email'>
    )> }
  )> }
);

export type GetAllByCompanyComplianceQueryVariables = Exact<{
  companyComplianceId: Scalars['Int'];
}>;


export type GetAllByCompanyComplianceQuery = (
  { __typename?: 'Query' }
  & { Notes: Array<(
    { __typename?: 'Note' }
    & Pick<Note, 'id' | 'content' | 'ownedById' | 'createdAt' | 'updatedAt'>
    & { ownedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'email'>
    )> }
  )> }
);

export type GetAllByApplicationQueryVariables = Exact<{
  applicationId: Scalars['Int'];
}>;


export type GetAllByApplicationQuery = (
  { __typename?: 'Query' }
  & { Notes: Array<(
    { __typename?: 'Note' }
    & Pick<Note, 'id' | 'content' | 'ownedById' | 'createdAt' | 'updatedAt'>
    & { ownedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'email'>
    )> }
  )> }
);

export type GetAllByApplicationDocumentQueryVariables = Exact<{
  applicationDocumentId: Scalars['Int'];
}>;


export type GetAllByApplicationDocumentQuery = (
  { __typename?: 'Query' }
  & { Notes: Array<(
    { __typename?: 'Note' }
    & Pick<Note, 'id' | 'content' | 'ownedById' | 'createdAt' | 'updatedAt'>
    & { ownedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'email'>
    )> }
  )> }
);

export type GetAllByAccountDocumentQueryVariables = Exact<{
  accountDocumentId: Scalars['Int'];
}>;


export type GetAllByAccountDocumentQuery = (
  { __typename?: 'Query' }
  & { Notes: Array<(
    { __typename?: 'Note' }
    & Pick<Note, 'id' | 'content' | 'ownedById' | 'createdAt' | 'updatedAt'>
    & { ownedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'email'>
    )> }
  )> }
);

export type GetAllByAccountTaskQueryVariables = Exact<{
  accountTaskId: Scalars['Int'];
}>;


export type GetAllByAccountTaskQuery = (
  { __typename?: 'Query' }
  & { Notes: Array<(
    { __typename?: 'Note' }
    & Pick<Note, 'id' | 'content' | 'ownedById' | 'createdAt' | 'updatedAt'>
    & { ownedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'email'>
    )> }
  )> }
);

export type GetAllByLawyerAssociationQueryVariables = Exact<{
  lawyerAssociationId: Scalars['Int'];
}>;


export type GetAllByLawyerAssociationQuery = (
  { __typename?: 'Query' }
  & { Notes: Array<(
    { __typename?: 'Note' }
    & Pick<Note, 'id' | 'content' | 'ownedById' | 'createdAt' | 'updatedAt'>
    & { ownedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'email'>
    )> }
  )> }
);

export type GetAllByCompanyTalentQueryVariables = Exact<{
  companyTalentId: Scalars['Int'];
}>;


export type GetAllByCompanyTalentQuery = (
  { __typename?: 'Query' }
  & { Notes: Array<(
    { __typename?: 'Note' }
    & Pick<Note, 'id' | 'content' | 'ownedById' | 'createdAt' | 'updatedAt'>
    & { ownedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'email'>
    )> }
  )> }
);

export type NotesListQueryVariables = Exact<{
  cursor?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
  sortBy?: Maybe<QuerySortBy>;
  filters?: Maybe<NotesListFilters>;
}>;


export type NotesListQuery = (
  { __typename?: 'Query' }
  & { NotesList?: Maybe<(
    { __typename?: 'NotesListReturn' }
    & Pick<NotesListReturn, 'count' | 'cursorId' | 'hasMore'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'Note' }
      & Pick<Note, 'content' | 'createdAt' | 'id'>
      & { noteAssociations?: Maybe<Array<(
        { __typename?: 'NoteAssociation' }
        & Pick<NoteAssociation, 'accountTaskId' | 'applicationId' | 'companyTalentId' | 'applicationDocumentId'>
        & { accountTask?: Maybe<(
          { __typename?: 'AccountTask' }
          & Pick<AccountTask, 'key'>
        )>, application?: Maybe<(
          { __typename?: 'Application' }
          & Pick<Application, 'key'>
        )>, companyTalent?: Maybe<(
          { __typename?: 'CompanyTalent' }
          & Pick<CompanyTalent, 'key'>
        )> }
      )>> }
    )>>> }
  )> }
);

export type NotificationsQueryVariables = Exact<{
  status?: Maybe<NotificationStatus>;
  cursor?: Maybe<Scalars['Int']>;
  applicationId?: Maybe<Scalars['Int']>;
}>;


export type NotificationsQuery = (
  { __typename?: 'Query' }
  & { Notifications?: Maybe<(
    { __typename?: 'NotificationsReturn' }
    & Pick<NotificationsReturn, 'cursorId' | 'hasMore' | 'count'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'Notification' }
      & Pick<Notification, 'id' | 'body' | 'status' | 'title' | 'url' | 'createdAt' | 'updatedAt'>
    )>>> }
  )> }
);

export type UpdateNotificationMutationVariables = Exact<{
  ids: Array<Scalars['Int']> | Scalars['Int'];
  status: Scalars['String'];
}>;


export type UpdateNotificationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateNotification'>
);

export type PaymentIntentsQueryVariables = Exact<{ [key: string]: never; }>;


export type PaymentIntentsQuery = (
  { __typename?: 'Query' }
  & { PaymentIntents: Array<(
    { __typename?: 'PaymentIntent' }
    & Pick<PaymentIntent, 'id' | 'stripeId' | 'stripeProductId' | 'stripePromoCode' | 'stripeCouponCode' | 'tax' | 'status' | 'state' | 'createdAt' | 'updatedAt'>
    & { stripePaymentIntent?: Maybe<(
      { __typename?: 'StripePaymentIntent' }
      & Pick<StripePaymentIntent, 'id' | 'clientSecret' | 'amount' | 'productName' | 'productAmount' | 'receiptUrl' | 'status' | 'couponAmount' | 'couponIsPercentage'>
    )> }
  )> }
);

export type PaymentIntentQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type PaymentIntentQuery = (
  { __typename?: 'Query' }
  & { PaymentIntent?: Maybe<(
    { __typename?: 'PaymentIntent' }
    & Pick<PaymentIntent, 'id' | 'stripeId' | 'stripeProductId' | 'stripePromoCode' | 'stripeCouponCode' | 'tax' | 'status' | 'state' | 'createdAt' | 'updatedAt'>
    & { stripePaymentIntent?: Maybe<(
      { __typename?: 'StripePaymentIntent' }
      & Pick<StripePaymentIntent, 'id' | 'clientSecret' | 'amount' | 'productName' | 'productAmount' | 'receiptUrl' | 'status' | 'couponAmount' | 'couponIsPercentage'>
    )> }
  )> }
);

export type CreatePaymentIntentMutationVariables = Exact<{
  stripeProductId: Scalars['String'];
}>;


export type CreatePaymentIntentMutation = (
  { __typename?: 'Mutation' }
  & { createPaymentIntent: (
    { __typename?: 'PaymentIntent' }
    & Pick<PaymentIntent, 'id' | 'stripeId' | 'stripeProductId' | 'stripePromoCode' | 'stripeCouponCode' | 'tax' | 'status' | 'state' | 'createdAt' | 'updatedAt'>
    & { stripePaymentIntent?: Maybe<(
      { __typename?: 'StripePaymentIntent' }
      & Pick<StripePaymentIntent, 'id' | 'clientSecret' | 'amount' | 'productName' | 'productAmount' | 'receiptUrl' | 'status' | 'couponAmount' | 'couponIsPercentage'>
    )> }
  ) }
);

export type UpdatePaymentIntentMutationVariables = Exact<{
  id: Scalars['Int'];
  coupon?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
}>;


export type UpdatePaymentIntentMutation = (
  { __typename?: 'Mutation' }
  & { updatePaymentIntent: (
    { __typename?: 'PaymentIntent' }
    & Pick<PaymentIntent, 'id' | 'stripeId' | 'stripeProductId' | 'stripePromoCode' | 'stripeCouponCode' | 'tax' | 'status' | 'state' | 'createdAt' | 'updatedAt'>
    & { stripePaymentIntent?: Maybe<(
      { __typename?: 'StripePaymentIntent' }
      & Pick<StripePaymentIntent, 'id' | 'clientSecret' | 'amount' | 'productName' | 'productAmount' | 'receiptUrl' | 'status' | 'couponAmount' | 'couponIsPercentage'>
    )> }
  ) }
);

export type PermissionsQueryVariables = Exact<{
  type?: Maybe<PermissionType>;
}>;


export type PermissionsQuery = (
  { __typename?: 'Query' }
  & { Permissions: Array<(
    { __typename?: 'Permission' }
    & Pick<Permission, 'id' | 'resource' | 'operation' | 'description' | 'category' | 'createdAt' | 'updatedAt'>
  )> }
);

export type PermissionGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type PermissionGroupsQuery = (
  { __typename?: 'Query' }
  & { PermissionGroups: Array<(
    { __typename?: 'PermissionGroup' }
    & Pick<PermissionGroup, 'id' | 'name' | 'description'>
    & { permissions?: Maybe<Array<Maybe<(
      { __typename?: 'Permission' }
      & Pick<Permission, 'id' | 'resource' | 'operation' | 'description' | 'category'>
    )>>> }
  )> }
);

export type PermitQueryVariables = Exact<{
  accountId?: Maybe<Scalars['Int']>;
  type?: Maybe<PermitType>;
  applicationId?: Maybe<Scalars['Int']>;
  companyTalentId?: Maybe<Scalars['Int']>;
}>;


export type PermitQuery = (
  { __typename?: 'Query' }
  & { Permit?: Maybe<(
    { __typename?: 'Permit' }
    & Pick<Permit, 'id' | 'type' | 'approvedOn' | 'expiresOn' | 'createdAt' | 'updatedAt' | 'applicationId'>
    & { account?: Maybe<(
      { __typename?: 'Account' }
      & Pick<Account, 'id'>
      & { accountType?: Maybe<(
        { __typename?: 'AccountType' }
        & Pick<AccountType, 'name'>
      )> }
    )>, companyTalent?: Maybe<(
      { __typename?: 'CompanyTalent' }
      & Pick<CompanyTalent, 'id' | 'key' | 'companyAccountId' | 'talentAccountId'>
      & { talentAccount?: Maybe<(
        { __typename?: 'Account' }
        & Pick<Account, 'id'>
        & { users?: Maybe<Array<Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'firstName' | 'lastName'>
        )>>> }
      )> }
    )> }
  )> }
);

export type PermitsQueryVariables = Exact<{
  type?: Maybe<PermitType>;
  isExpiring?: Maybe<Scalars['Boolean']>;
}>;


export type PermitsQuery = (
  { __typename?: 'Query' }
  & { Permits?: Maybe<Array<Maybe<(
    { __typename?: 'Permit' }
    & Pick<Permit, 'id' | 'approvedOn' | 'expiresOn' | 'createdAt' | 'updatedAt' | 'type'>
    & { companyTalent?: Maybe<(
      { __typename?: 'CompanyTalent' }
      & Pick<CompanyTalent, 'id' | 'key' | 'companyAccountId' | 'talentAccountId'>
      & { talentAccount?: Maybe<(
        { __typename?: 'Account' }
        & Pick<Account, 'id'>
        & { users?: Maybe<Array<Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'firstName' | 'lastName'>
        )>>> }
      )>, invitation?: Maybe<(
        { __typename?: 'Invitation' }
        & Pick<Invitation, 'id' | 'slug' | 'email' | 'status' | 'expires'>
      )> }
    )>, account?: Maybe<(
      { __typename?: 'Account' }
      & Pick<Account, 'id' | 'name'>
      & { users?: Maybe<Array<Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email'>
      )>>> }
    )> }
  )>>> }
);

export type CreatePermitMutationVariables = Exact<{
  data: PermitCreateData;
}>;


export type CreatePermitMutation = (
  { __typename?: 'Mutation' }
  & { createPermit?: Maybe<(
    { __typename?: 'Permit' }
    & Pick<Permit, 'id' | 'type' | 'createdAt' | 'updatedAt'>
  )> }
);

export type UpdatePermitMutationVariables = Exact<{
  id: Scalars['Int'];
  approvedOn: Scalars['Date'];
  expiresOn: Scalars['Date'];
}>;


export type UpdatePermitMutation = (
  { __typename?: 'Mutation' }
  & { updatePermit?: Maybe<(
    { __typename?: 'Permit' }
    & Pick<Permit, 'id' | 'type' | 'createdAt' | 'updatedAt'>
  )> }
);

export type GetPersonQueryVariables = Exact<{
  id?: Maybe<Scalars['Int']>;
  taskLabels?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  taskStatus?: Maybe<AccountTaskStatus>;
  type?: Maybe<PersonType>;
  accountId?: Maybe<Scalars['Int']>;
}>;


export type GetPersonQuery = (
  { __typename?: 'Query' }
  & { Person?: Maybe<(
    { __typename?: 'Person' }
    & Pick<Person, 'id' | 'firstName' | 'lastName' | 'email' | 'type' | 'accountId'>
    & { accountTasks?: Maybe<Array<Maybe<(
      { __typename?: 'AccountTask' }
      & Pick<AccountTask, 'id' | 'key' | 'createdAt' | 'updatedAt' | 'status'>
      & { task?: Maybe<(
        { __typename?: 'Task' }
        & Pick<Task, 'id' | 'action' | 'label' | 'title'>
        & { taskAssociation?: Maybe<(
          { __typename?: 'TaskAssociation' }
          & Pick<TaskAssociation, 'id'>
          & { forms?: Maybe<Array<Maybe<(
            { __typename?: 'Form' }
            & Pick<Form, 'id' | 'name' | 'title'>
          )>>>, documentTypes?: Maybe<Array<Maybe<(
            { __typename?: 'DocumentType' }
            & Pick<DocumentType, 'name' | 'id'>
          )>>>, signatureRequestTypes?: Maybe<Array<Maybe<(
            { __typename?: 'SignatureRequestType' }
            & Pick<SignatureRequestType, 'id' | 'name'>
            & { signatureRequest?: Maybe<Array<Maybe<(
              { __typename?: 'SignatureRequest' }
              & Pick<SignatureRequest, 'id' | 'signatureRequestId'>
              & { signatureRequestSigners?: Maybe<Array<Maybe<(
                { __typename?: 'SignatureRequestSigners' }
                & Pick<SignatureRequestSigners, 'signatureId' | 'status'>
              )>>> }
            )>>> }
          )>>> }
        )> }
      )> }
    )>>> }
  )> }
);

export type GetPersonIncludesQueryVariables = Exact<{
  id?: Maybe<Scalars['Int']>;
  type?: Maybe<PersonType>;
  accountId?: Maybe<Scalars['Int']>;
}>;


export type GetPersonIncludesQuery = (
  { __typename?: 'Query' }
  & { Person?: Maybe<(
    { __typename?: 'Person' }
    & Pick<Person, 'id' | 'firstName' | 'lastName' | 'email' | 'type' | 'accountId'>
  )> }
);

export type UpdatePersonMutationVariables = Exact<{
  id: Scalars['Int'];
  data?: Maybe<UpdatePersonData>;
}>;


export type UpdatePersonMutation = (
  { __typename?: 'Mutation' }
  & { updatePerson?: Maybe<(
    { __typename?: 'Person' }
    & Pick<Person, 'id' | 'firstName' | 'lastName' | 'email' | 'type'>
  )> }
);

export type PersonsQueryVariables = Exact<{
  accountId?: Maybe<Scalars['Int']>;
  applicationId?: Maybe<Scalars['Int']>;
}>;


export type PersonsQuery = (
  { __typename?: 'Query' }
  & { Persons?: Maybe<Array<Maybe<(
    { __typename?: 'Person' }
    & Pick<Person, 'id' | 'firstName' | 'lastName' | 'email' | 'type' | 'meta' | 'age'>
    & { applications?: Maybe<Array<Maybe<(
      { __typename?: 'Application' }
      & Pick<Application, 'id'>
    )>>> }
  )>>> }
);

export type PersonsIncludesQueryVariables = Exact<{
  accountId?: Maybe<Scalars['Int']>;
  applicationId?: Maybe<Scalars['Int']>;
  includeApplications: Scalars['Boolean'];
}>;


export type PersonsIncludesQuery = (
  { __typename?: 'Query' }
  & { Persons?: Maybe<Array<Maybe<(
    { __typename?: 'Person' }
    & Pick<Person, 'id' | 'firstName' | 'lastName' | 'email' | 'type' | 'meta' | 'age'>
    & { applications?: Maybe<Array<Maybe<(
      { __typename?: 'Application' }
      & Pick<Application, 'id'>
    )>>> }
  )>>> }
);

export type CreatePersonMutationVariables = Exact<{
  type: PersonType;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['Int']>;
  meta?: Maybe<Scalars['JSON']>;
}>;


export type CreatePersonMutation = (
  { __typename?: 'Mutation' }
  & { createPerson?: Maybe<(
    { __typename?: 'Person' }
    & Pick<Person, 'id' | 'firstName' | 'email' | 'lastName' | 'type'>
  )> }
);

export type HandlePublicMutationVariables = Exact<{
  action: HandlePublicAction;
  data?: Maybe<HandlePublicData>;
}>;


export type HandlePublicMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'handlePublic'>
);

export type QuestionsQueryVariables = Exact<{
  cursor?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
  sortBy?: Maybe<QuerySortBy>;
  sortByColumn?: Maybe<Scalars['String']>;
  options?: Maybe<QuestionsOptions>;
}>;


export type QuestionsQuery = (
  { __typename?: 'Query' }
  & { Questions?: Maybe<(
    { __typename?: 'QuestionsReturn' }
    & Pick<QuestionsReturn, 'count' | 'cursorId' | 'hasMore'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'Question' }
      & Pick<Question, 'id' | 'inputType' | 'label' | 'question' | 'tip' | 'validationRules' | 'maxCharacters'>
    )>>> }
  )> }
);

export type QuestionQueryVariables = Exact<{
  label: Scalars['String'];
}>;


export type QuestionQuery = (
  { __typename?: 'Query' }
  & { Question?: Maybe<(
    { __typename?: 'Question' }
    & Pick<Question, 'id' | 'label' | 'question' | 'tip' | 'createdAt' | 'updatedAt'>
  )> }
);

export type QuestionIncludesQueryVariables = Exact<{
  label: Scalars['String'];
  includeOptions: Scalars['Boolean'];
}>;


export type QuestionIncludesQuery = (
  { __typename?: 'Query' }
  & { Question?: Maybe<(
    { __typename?: 'Question' }
    & Pick<Question, 'id' | 'label' | 'question' | 'tip' | 'createdAt' | 'updatedAt' | 'inputType'>
    & { questionOptions?: Maybe<Array<(
      { __typename?: 'QuestionOptions' }
      & Pick<QuestionOptions, 'option'>
    )>> }
  )> }
);

export type UpsertQuestionMutationVariables = Exact<{
  id?: Maybe<Scalars['Int']>;
  question?: Maybe<Scalars['String']>;
  type?: Maybe<QuestionInputType>;
  required?: Maybe<Scalars['Boolean']>;
  tip?: Maybe<Scalars['String']>;
  options?: Maybe<CreateQuestionOptions>;
  repeaterQuestionIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  createExplainQuestion?: Maybe<Scalars['Boolean']>;
}>;


export type UpsertQuestionMutation = (
  { __typename?: 'Mutation' }
  & { upsertQuestion?: Maybe<(
    { __typename?: 'Question' }
    & Pick<Question, 'id' | 'label' | 'question' | 'inputType' | 'tip' | 'validationRules'>
  )> }
);

export type CreateQuestionGroupingMutationVariables = Exact<{
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  formId: Scalars['Int'];
}>;


export type CreateQuestionGroupingMutation = (
  { __typename?: 'Mutation' }
  & { createQuestionGrouping?: Maybe<(
    { __typename?: 'QuestionGrouping' }
    & Pick<QuestionGrouping, 'id' | 'title' | 'subtitle'>
  )> }
);

export type QuestionGroupingsQueryVariables = Exact<{
  cursor?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
  sortBy?: Maybe<QuerySortBy>;
  options?: Maybe<QuestionGroupingsOptions>;
}>;


export type QuestionGroupingsQuery = (
  { __typename?: 'Query' }
  & { QuestionGroupings?: Maybe<(
    { __typename?: 'QuestionGroupingsReturn' }
    & Pick<QuestionGroupingsReturn, 'count' | 'cursorId' | 'hasMore'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'QuestionGrouping' }
      & Pick<QuestionGrouping, 'id' | 'uniqueKey' | 'title'>
    )>>> }
  )> }
);

export type QuestionGroupingQueryVariables = Exact<{
  id?: Maybe<Scalars['Int']>;
  uniqueKey?: Maybe<Scalars['String']>;
}>;


export type QuestionGroupingQuery = (
  { __typename?: 'Query' }
  & { QuestionGrouping?: Maybe<(
    { __typename?: 'QuestionGrouping' }
    & Pick<QuestionGrouping, 'id' | 'ordinal' | 'title' | 'subtitle' | 'uniqueKey' | 'accountId'>
    & { forms?: Maybe<Array<Maybe<(
      { __typename?: 'Form' }
      & Pick<Form, 'id' | 'title' | 'name'>
    )>>>, questions?: Maybe<Array<(
      { __typename?: 'Question' }
      & Pick<Question, 'id' | 'inputType' | 'label' | 'ordinal' | 'question' | 'tip' | 'validationRules' | 'accountId' | 'maxCharacters'>
      & { conditionsRequiredToRenderThisQuestion: Array<(
        { __typename?: 'EvaluatedCondition' }
        & Pick<EvaluatedCondition, 'id' | 'value' | 'isParentCondition' | 'scopeId'>
        & { formula?: Maybe<(
          { __typename?: 'ConditionFormula' }
          & Pick<ConditionFormula, 'expect' | 'operation'>
          & { question?: Maybe<(
            { __typename?: 'QuestionUniqueIdentifiers' }
            & Pick<QuestionUniqueIdentifiers, 'id' | 'label'>
          )> }
        )>, parentConditions?: Maybe<Array<Maybe<(
          { __typename?: 'Condition' }
          & Pick<Condition, 'id' | 'scopeId'>
        )>>> }
      )>, repeaterForm?: Maybe<(
        { __typename?: 'Form' }
        & Pick<Form, 'id' | 'name' | 'title'>
        & { questions?: Maybe<Array<(
          { __typename?: 'Question' }
          & Pick<Question, 'id' | 'inputType' | 'label' | 'ordinal' | 'question' | 'tip' | 'validationRules' | 'accountId' | 'maxCharacters'>
        )>>, content: Array<(
          { __typename: 'Question' }
          & Pick<Question, 'id' | 'question' | 'label' | 'tip' | 'inputType' | 'validationRules' | 'createdAt' | 'updatedAt' | 'maxCharacters'>
          & { questionOptions: Array<(
            { __typename?: 'QuestionOptions' }
            & Pick<QuestionOptions, 'id' | 'option'>
          )>, conditionsRequiredToRenderThisQuestion: Array<(
            { __typename?: 'EvaluatedCondition' }
            & Pick<EvaluatedCondition, 'id' | 'value' | 'scopeId' | 'isParentCondition'>
            & { parentConditions?: Maybe<Array<Maybe<(
              { __typename?: 'Condition' }
              & Pick<Condition, 'id' | 'scopeId'>
            )>>>, formula?: Maybe<(
              { __typename?: 'ConditionFormula' }
              & Pick<ConditionFormula, 'operation' | 'expect'>
              & { question?: Maybe<(
                { __typename?: 'QuestionUniqueIdentifiers' }
                & Pick<QuestionUniqueIdentifiers, 'id' | 'label'>
              )> }
            )> }
          )> }
        ) | { __typename: 'QuestionGrouping' }> }
      )> }
    )>> }
  )> }
);

export type UpdateQuestionGroupingMutationVariables = Exact<{
  id: Scalars['Int'];
  data?: Maybe<UpdateQuestionGroupingData>;
}>;


export type UpdateQuestionGroupingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateQuestionGrouping'>
);

export type QuestionOptionsQueryVariables = Exact<{
  cursor?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
  sortBy?: Maybe<QuerySortBy>;
  length?: Maybe<Scalars['Int']>;
  options?: Maybe<QuestionOptionsOptions>;
}>;


export type QuestionOptionsQuery = (
  { __typename?: 'Query' }
  & { QuestionOptions?: Maybe<(
    { __typename?: 'QuestionOptionsReturn' }
    & Pick<QuestionOptionsReturn, 'count' | 'cursorId' | 'hasMore'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'QuestionOptions' }
      & Pick<QuestionOptions, 'id' | 'option'>
    )>>> }
  )> }
);

export type GetReferrerCodesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetReferrerCodesQuery = (
  { __typename?: 'Query' }
  & { Referrer?: Maybe<(
    { __typename?: 'Referrer' }
    & { referrerCodes?: Maybe<Array<(
      { __typename?: 'ReferrerCode' }
      & Pick<ReferrerCode, 'id' | 'code' | 'createdAt' | 'updatedAt'>
    )>> }
  )> }
);

export type ReferrerQueryVariables = Exact<{ [key: string]: never; }>;


export type ReferrerQuery = (
  { __typename?: 'Query' }
  & { Referrer?: Maybe<(
    { __typename?: 'Referrer' }
    & Pick<Referrer, 'id' | 'createdAt' | 'updatedAt'>
    & { account?: Maybe<(
      { __typename?: 'Account' }
      & Pick<Account, 'id'>
    )>, invites?: Maybe<Array<(
      { __typename?: 'Referrer' }
      & Pick<Referrer, 'id' | 'invitedByReferrerId'>
    )>>, referrerConversions?: Maybe<Array<(
      { __typename?: 'ReferrerConversion' }
      & Pick<ReferrerConversion, 'id'>
      & { paymentIntent?: Maybe<(
        { __typename?: 'PaymentIntent' }
        & Pick<PaymentIntent, 'id'>
        & { customer?: Maybe<(
          { __typename?: 'Customer' }
          & { account?: Maybe<(
            { __typename?: 'Account' }
            & Pick<Account, 'id'>
            & { primaryUser?: Maybe<(
              { __typename?: 'User' }
              & Pick<User, 'email'>
            )> }
          )> }
        )> }
      )> }
    )>>, referrerCodes?: Maybe<Array<(
      { __typename?: 'ReferrerCode' }
      & Pick<ReferrerCode, 'id' | 'code'>
    )>> }
  )> }
);

export type CreateReferrerCodeMutationVariables = Exact<{
  code: Scalars['String'];
}>;


export type CreateReferrerCodeMutation = (
  { __typename?: 'Mutation' }
  & { createReferrerCode: (
    { __typename?: 'ReferrerCode' }
    & Pick<ReferrerCode, 'id' | 'code'>
  ) }
);

export type UpsertReminderMutationVariables = Exact<{
  data: ReminderUpsertData;
  id?: Maybe<Scalars['Int']>;
}>;


export type UpsertReminderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'upsertReminder'>
);

export type ReminderQueryVariables = Exact<{
  reminderId?: Maybe<Scalars['Int']>;
  data?: Maybe<ReminderData>;
}>;


export type ReminderQuery = (
  { __typename?: 'Query' }
  & { Reminder?: Maybe<(
    { __typename?: 'Reminder' }
    & Pick<Reminder, 'id' | 'active' | 'cadenceDaysAfter' | 'cadenceDaysBefore' | 'type' | 'accountId' | 'createdAt' | 'updatedAt'>
  )> }
);

export type RepresentativesQueryVariables = Exact<{
  cursor?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
  sortBy?: Maybe<QuerySortBy>;
  filters?: Maybe<RepresentativesFilters>;
}>;


export type RepresentativesQuery = (
  { __typename?: 'Query' }
  & { Representatives?: Maybe<(
    { __typename?: 'RepresentativesReturn' }
    & Pick<RepresentativesReturn, 'count' | 'cursorId' | 'hasMore'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'Representative' }
      & Pick<Representative, 'accountId' | 'createdAt' | 'deleted' | 'active' | 'documentId' | 'id' | 'key' | 'name' | 'signatureDocumentId' | 'updatedAt' | 'meta'>
    )>>> }
  )> }
);

export type RepresentativeQueryVariables = Exact<{
  key: Scalars['String'];
}>;


export type RepresentativeQuery = (
  { __typename?: 'Query' }
  & { Representative?: Maybe<(
    { __typename?: 'Representative' }
    & Pick<Representative, 'accountId' | 'createdAt' | 'deleted' | 'active' | 'documentId' | 'id' | 'key' | 'name' | 'updatedAt' | 'signatureDocumentId' | 'signatureDocumentUrl' | 'meta'>
  )> }
);

export type RepresentativeWithApplicationsQueryVariables = Exact<{
  key: Scalars['String'];
}>;


export type RepresentativeWithApplicationsQuery = (
  { __typename?: 'Query' }
  & { Representative?: Maybe<(
    { __typename?: 'Representative' }
    & Pick<Representative, 'accountId' | 'createdAt' | 'deleted' | 'active' | 'documentId' | 'id' | 'key' | 'name' | 'updatedAt' | 'signatureDocumentId' | 'signatureDocumentUrl' | 'meta'>
    & { applications?: Maybe<Array<Maybe<(
      { __typename?: 'Application' }
      & Pick<Application, 'key' | 'id'>
    )>>> }
  )> }
);

export type CreateRepresentativeMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type CreateRepresentativeMutation = (
  { __typename?: 'Mutation' }
  & { createRepresentative?: Maybe<(
    { __typename?: 'Representative' }
    & Pick<Representative, 'accountId' | 'createdAt' | 'deleted' | 'active' | 'documentId' | 'id' | 'key' | 'name' | 'signatureDocumentId' | 'updatedAt'>
  )> }
);

export type DeleteRepresentativeMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteRepresentativeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteRepresentative'>
);

export type GetRoleByIdQueryVariables = Exact<{
  id?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  applicationId?: Maybe<Scalars['Int']>;
}>;


export type GetRoleByIdQuery = (
  { __typename?: 'Query' }
  & { Role?: Maybe<(
    { __typename?: 'Role' }
    & Pick<Role, 'id' | 'key' | 'title' | 'updatedAt' | 'createdAt' | 'accountId'>
    & { account?: Maybe<(
      { __typename?: 'Account' }
      & Pick<Account, 'id'>
    )>, applications?: Maybe<Array<Maybe<(
      { __typename?: 'Application' }
      & Pick<Application, 'key'>
    )>>>, companyTalents?: Maybe<Array<Maybe<(
      { __typename?: 'CompanyTalent' }
      & Pick<CompanyTalent, 'id' | 'key' | 'companyAccountId' | 'talentAccountId' | 'firstName' | 'lastName' | 'email'>
      & { talentAccount?: Maybe<(
        { __typename?: 'Account' }
        & Pick<Account, 'id'>
        & { users?: Maybe<Array<Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'firstName' | 'lastName' | 'email'>
        )>>> }
      )>, invitation?: Maybe<(
        { __typename?: 'Invitation' }
        & Pick<Invitation, 'id' | 'email' | 'status'>
      )>, statuses?: Maybe<Array<Maybe<(
        { __typename?: 'Status' }
        & Pick<Status, 'id' | 'value'>
        & { talent?: Maybe<(
          { __typename?: 'CompanyTalent' }
          & Pick<CompanyTalent, 'id'>
          & { role?: Maybe<(
            { __typename?: 'Role' }
            & Pick<Role, 'id' | 'title'>
          )> }
        )> }
      )>>>, application?: Maybe<(
        { __typename?: 'Application' }
        & { statuses?: Maybe<Array<(
          { __typename?: 'Status' }
          & Pick<Status, 'id' | 'value' | 'type'>
          & { talent?: Maybe<(
            { __typename?: 'CompanyTalent' }
            & Pick<CompanyTalent, 'id'>
            & { role?: Maybe<(
              { __typename?: 'Role' }
              & Pick<Role, 'id' | 'title'>
            )> }
          )> }
        )>> }
      )> }
    )>>>, feedback?: Maybe<Array<Maybe<(
      { __typename?: 'Feedback' }
      & Pick<Feedback, 'content' | 'status' | 'updatedAt' | 'createdAt'>
      & { ownedBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName'>
      ), accountTask?: Maybe<(
        { __typename?: 'AccountTask' }
        & Pick<AccountTask, 'id'>
        & { account?: Maybe<(
          { __typename?: 'Account' }
          & Pick<Account, 'id'>
        )> }
      )> }
    )>>> }
  )> }
);

export type GetRolesQueryVariables = Exact<{
  accountId?: Maybe<Scalars['Int']>;
}>;


export type GetRolesQuery = (
  { __typename?: 'Query' }
  & { Roles: Array<(
    { __typename?: 'Role' }
    & Pick<Role, 'id' | 'key' | 'title' | 'updatedAt' | 'createdAt'>
    & { applications?: Maybe<Array<Maybe<(
      { __typename?: 'Application' }
      & Pick<Application, 'key'>
    )>>>, companyTalents?: Maybe<Array<Maybe<(
      { __typename?: 'CompanyTalent' }
      & Pick<CompanyTalent, 'id' | 'firstName' | 'lastName'>
    )>>> }
  )> }
);

export type CreateRoleMutationVariables = Exact<{
  title: Scalars['String'];
  targetAccountId?: Maybe<Scalars['Int']>;
}>;


export type CreateRoleMutation = (
  { __typename?: 'Mutation' }
  & { createRole: (
    { __typename?: 'Role' }
    & Pick<Role, 'id' | 'key' | 'title' | 'updatedAt' | 'createdAt'>
  ) }
);

export type DeleteRoleMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteRoleMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteRole'>
);

export type UpdateRoleMutationVariables = Exact<{
  id: Scalars['Int'];
  data: RoleUpdateData;
}>;


export type UpdateRoleMutation = (
  { __typename?: 'Mutation' }
  & { updateRole?: Maybe<(
    { __typename?: 'Role' }
    & Pick<Role, 'id' | 'key' | 'title'>
  )> }
);

export type DuplicateRoleMutationVariables = Exact<{
  id: Scalars['Int'];
  title: Scalars['String'];
}>;


export type DuplicateRoleMutation = (
  { __typename?: 'Mutation' }
  & { duplicateRole?: Maybe<(
    { __typename?: 'Role' }
    & Pick<Role, 'id' | 'key' | 'title' | 'createdAt' | 'updatedAt'>
  )> }
);

export type SearchQueryVariables = Exact<{
  query: Scalars['String'];
  type: SearchType;
}>;


export type SearchQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'search'>
);

export type HandleSendGridMutationVariables = Exact<{
  action: SendGridAction;
  data?: Maybe<SendGridData>;
}>;


export type HandleSendGridMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'handleSendGrid'>
);

export type GetEmbeddedSignUrlQueryVariables = Exact<{
  signatureRequestId: Scalars['Int'];
}>;


export type GetEmbeddedSignUrlQuery = (
  { __typename?: 'Query' }
  & { getEmbeddedSignUrl?: Maybe<(
    { __typename?: 'EmbeddedSignUrl' }
    & Pick<EmbeddedSignUrl, 'auditUrl' | 'status' | 'slug' | 'documentUrl' | 'completedAt'>
  )> }
);

export type GetHelloSignFilesQueryVariables = Exact<{
  signatureRequestId: Scalars['String'];
}>;


export type GetHelloSignFilesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getHelloSignFiles'>
);

export type HandleHelloSignWebhookMutationVariables = Exact<{
  event?: Maybe<HelloSignEvent>;
}>;


export type HandleHelloSignWebhookMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'handleHelloSignWebhook'>
);

export type HandleDocuSealWebhookMutationVariables = Exact<{
  data?: Maybe<Scalars['JSON']>;
}>;


export type HandleDocuSealWebhookMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'handleDocuSealWebhook'>
);

export type SignatureRequestSignerQueryVariables = Exact<{
  signtaureRequestSigners: Array<SignatureRequestSignerData> | SignatureRequestSignerData;
}>;


export type SignatureRequestSignerQuery = (
  { __typename?: 'Query' }
  & { SignatureRequestSigner?: Maybe<Array<Maybe<(
    { __typename?: 'SignatureRequestSigners' }
    & Pick<SignatureRequestSigners, 'signatureId' | 'status' | 'createdAt' | 'updatedAt' | 'email'>
    & { signatureRequest?: Maybe<(
      { __typename?: 'SignatureRequest' }
      & Pick<SignatureRequest, 'signatureRequestId'>
      & { type?: Maybe<(
        { __typename?: 'SignatureRequestType' }
        & Pick<SignatureRequestType, 'id' | 'name' | 'title' | 'subject' | 'message'>
      )> }
    )> }
  )>>> }
);

export type StatusQueryVariables = Exact<{
  id?: Maybe<Scalars['Int']>;
  applicationId?: Maybe<Scalars['Int']>;
  type?: Maybe<StatusType>;
  companyAccountId?: Maybe<Scalars['Int']>;
  companyTalentId?: Maybe<Scalars['Int']>;
}>;


export type StatusQuery = (
  { __typename?: 'Query' }
  & { Status?: Maybe<(
    { __typename?: 'Status' }
    & Pick<Status, 'id' | 'type' | 'value' | 'history' | 'createdAt' | 'updatedAt' | 'requirements' | 'ownerAccountId' | 'documentsToSign'>
    & { application?: Maybe<(
      { __typename?: 'Application' }
      & Pick<Application, 'id' | 'key' | 'type' | 'meta'>
      & { assignedUsers?: Maybe<Array<Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'firstName' | 'lastName'>
      )>>>, ownerAccount?: Maybe<(
        { __typename?: 'Account' }
        & Pick<Account, 'name'>
        & { primaryUser?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'firstName' | 'lastName'>
        )> }
      )>, lawyerAccount?: Maybe<(
        { __typename?: 'Account' }
        & Pick<Account, 'name'>
      )> }
    )> }
  )> }
);

export type StatusesQueryVariables = Exact<{
  applicationId: Scalars['Int'];
  type?: Maybe<StatusType>;
}>;


export type StatusesQuery = (
  { __typename?: 'Query' }
  & { Statuses?: Maybe<Array<Maybe<(
    { __typename?: 'Status' }
    & Pick<Status, 'id' | 'type' | 'value' | 'history' | 'createdAt' | 'updatedAt'>
  )>>> }
);

export type UpdateStatusMutationVariables = Exact<{
  id: Scalars['Int'];
  data: StatusUpdateData;
}>;


export type UpdateStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateStatus?: Maybe<(
    { __typename?: 'Status' }
    & Pick<Status, 'id' | 'type' | 'value' | 'history' | 'createdAt' | 'updatedAt'>
  )> }
);

export type CreateStorageFileMutationVariables = Exact<{
  type: StorageFileType;
  file: Scalars['Upload'];
}>;


export type CreateStorageFileMutation = (
  { __typename?: 'Mutation' }
  & { createStorageFile?: Maybe<(
    { __typename?: 'StorageFile' }
    & Pick<StorageFile, 'accountId' | 'createdAt' | 'id' | 'key' | 'type'>
  )> }
);

export type GetStorageFileUrlQueryVariables = Exact<{
  type: StorageFileType;
}>;


export type GetStorageFileUrlQuery = (
  { __typename?: 'Query' }
  & { getStorageFileUrl?: Maybe<(
    { __typename?: 'GetStorageFileReturn' }
    & Pick<GetStorageFileReturn, 'url'>
    & { storageFile: (
      { __typename?: 'StorageFile' }
      & Pick<StorageFile, 'accountId' | 'id' | 'key'>
    ) }
  )> }
);

export type DeleteStorageFileMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteStorageFileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteStorageFile'>
);

export type GetStripeCustomerQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStripeCustomerQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getStripeCustomer'>
);

export type GetStripeCheckoutUrlQueryVariables = Exact<{
  priceId: Scalars['String'];
  quantity?: Maybe<Scalars['Int']>;
}>;


export type GetStripeCheckoutUrlQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getStripeCheckoutUrl'>
);

export type GetStripePortalUrlQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStripePortalUrlQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getStripePortalUrl'>
);

export type HandleStripeWebhookMutationVariables = Exact<{
  event?: Maybe<Scalars['JSON']>;
}>;


export type HandleStripeWebhookMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'handleStripeWebhook'>
);

export type AdminImpersonateUserMutationVariables = Exact<{
  userId: Scalars['Int'];
}>;


export type AdminImpersonateUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'adminImpersonateUser'>
);

export type AdminDeleteMutationVariables = Exact<{
  action: AdminDeleteAction;
  data?: Maybe<AdminDeleteData>;
}>;


export type AdminDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'adminDelete'>
);

export type AdminUpdateMutationVariables = Exact<{
  action: AdminUpdateAction;
  data?: Maybe<AdminUpdateData>;
}>;


export type AdminUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'adminUpdate'>
);

export type TagsQueryVariables = Exact<{
  group?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;


export type TagsQuery = (
  { __typename?: 'Query' }
  & { Tags?: Maybe<Array<Maybe<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'value'>
  )>>> }
);

export type TasksQueryVariables = Exact<{
  cursor?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
  sortBy?: Maybe<QuerySortBy>;
  filters?: Maybe<TasksFilters>;
}>;


export type TasksQuery = (
  { __typename?: 'Query' }
  & { Tasks?: Maybe<(
    { __typename?: 'TasksReturn' }
    & Pick<TasksReturn, 'count' | 'cursorId' | 'hasMore'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'Task' }
      & Pick<Task, 'action' | 'id' | 'label' | 'meta' | 'required' | 'title'>
    )>>> }
  )> }
);

export type TaskQueryVariables = Exact<{
  label?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
}>;


export type TaskQuery = (
  { __typename?: 'Query' }
  & { Task?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'action' | 'id' | 'label' | 'meta' | 'required' | 'title' | 'accountId'>
    & { taskAssociation?: Maybe<(
      { __typename?: 'TaskAssociation' }
      & Pick<TaskAssociation, 'id'>
      & { documentTypes?: Maybe<Array<Maybe<(
        { __typename?: 'DocumentType' }
        & Pick<DocumentType, 'name' | 'id' | 'displayName' | 'title' | 'tip' | 'instructions' | 'required' | 'accountId'>
      )>>> }
    )> }
  )> }
);

export type TaskActionQueryVariables = Exact<{
  label?: Maybe<Scalars['String']>;
  accountTaskKey?: Maybe<Scalars['String']>;
}>;


export type TaskActionQuery = (
  { __typename?: 'Query' }
  & { Task?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'action'>
  )> }
);

export type UpsertTaskMutationVariables = Exact<{
  id?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  instructions: Scalars['String'];
  required: Scalars['Boolean'];
  documentTypeIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
  documentsLinkedToApplication?: Maybe<Scalars['Boolean']>;
}>;


export type UpsertTaskMutation = (
  { __typename?: 'Mutation' }
  & { upsertTask?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'action' | 'id' | 'label' | 'meta' | 'required' | 'title'>
  )> }
);

export type TemplateDocumentQueryVariables = Exact<{
  key: Scalars['String'];
}>;


export type TemplateDocumentQuery = (
  { __typename?: 'Query' }
  & { TemplateDocument?: Maybe<(
    { __typename?: 'TemplateDocument' }
    & Pick<TemplateDocument, 'accountId' | 'content' | 'createdAt' | 'description' | 'id' | 'key' | 'name'>
  )> }
);

export type TemplateDocumentsQueryVariables = Exact<{
  cursor?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
  sortBy?: Maybe<QuerySortBy>;
}>;


export type TemplateDocumentsQuery = (
  { __typename?: 'Query' }
  & { TemplateDocuments?: Maybe<(
    { __typename?: 'TemplateDocumentsReturn' }
    & Pick<TemplateDocumentsReturn, 'count' | 'cursorId' | 'hasMore'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'TemplateDocument' }
      & Pick<TemplateDocument, 'accountId' | 'content' | 'createdAt' | 'description' | 'id' | 'key' | 'name'>
    )>>> }
  )> }
);

export type CreateTemplateDocumentMutationVariables = Exact<{
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
}>;


export type CreateTemplateDocumentMutation = (
  { __typename?: 'Mutation' }
  & { createTemplateDocument?: Maybe<(
    { __typename?: 'TemplateDocument' }
    & Pick<TemplateDocument, 'accountId' | 'description' | 'id' | 'key' | 'name'>
  )> }
);

export type UpdateTemplateDocumentMutationVariables = Exact<{
  id: Scalars['Int'];
  data: TemplateDocumentUpdateData;
}>;


export type UpdateTemplateDocumentMutation = (
  { __typename?: 'Mutation' }
  & { updateTemplateDocument?: Maybe<(
    { __typename?: 'TemplateDocument' }
    & Pick<TemplateDocument, 'accountId' | 'content' | 'createdAt' | 'description' | 'id' | 'key' | 'name'>
  )> }
);

export type HandleTemplateDocumentMutationVariables = Exact<{
  id: Scalars['Int'];
  action: HandleTemplateDocumentAction;
  data?: Maybe<HandleTemplateDocumentData>;
}>;


export type HandleTemplateDocumentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'handleTemplateDocument'>
);

export type GetActiveUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetActiveUserQuery = (
  { __typename?: 'Query' }
  & { User?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'createdAt' | 'updatedAt' | 'hasCompletedGettingStarted' | 'hasNewNotifications' | 'avatarKey' | 'isPrimaryUser' | 'permissionGroups' | 'productsPurchased' | 'hasLawyerAssociation' | 'viewed' | 'mfaActive'>
    & { permissions?: Maybe<Array<Maybe<(
      { __typename?: 'ActiveUserPermission' }
      & Pick<ActiveUserPermission, 'resource' | 'operation'>
    )>>>, userPermissions?: Maybe<Array<Maybe<(
      { __typename?: 'ActiveUserPermission' }
      & Pick<ActiveUserPermission, 'resource' | 'operation'>
    )>>>, account: (
      { __typename?: 'Account' }
      & Pick<Account, 'name' | 'id' | 'viewed' | 'settings' | 'leadsEmbedId'>
      & { accountType?: Maybe<(
        { __typename?: 'AccountType' }
        & Pick<AccountType, 'id' | 'name'>
      )>, accountSubType?: Maybe<(
        { __typename?: 'AccountSubType' }
        & Pick<AccountSubType, 'id' | 'name'>
      )>, subscription?: Maybe<(
        { __typename?: 'AccountSubscriptionData' }
        & Pick<AccountSubscriptionData, 'gateWithBilling' | 'isActive'>
      )> }
    ) }
  )> }
);

export type UsersQueryVariables = Exact<{
  targetAccountId?: Maybe<Scalars['Int']>;
  filters?: Maybe<UsersFilters>;
}>;


export type UsersQuery = (
  { __typename?: 'Query' }
  & { Users?: Maybe<Array<Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'active' | 'firstName' | 'lastName' | 'email' | 'createdAt' | 'updatedAt' | 'isPrimaryUser'>
    & { account: (
      { __typename?: 'Account' }
      & Pick<Account, 'id' | 'active' | 'name'>
      & { accountType?: Maybe<(
        { __typename?: 'AccountType' }
        & Pick<AccountType, 'id' | 'name'>
      )>, accountSubType?: Maybe<(
        { __typename?: 'AccountSubType' }
        & Pick<AccountSubType, 'id' | 'name'>
      )> }
    ) }
  )>>> }
);

export type UserIncludesQueryVariables = Exact<{
  includesSubscription: Scalars['Boolean'];
  includesPermissions: Scalars['Boolean'];
  includesUserPermissions: Scalars['Boolean'];
}>;


export type UserIncludesQuery = (
  { __typename?: 'Query' }
  & { User?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'avatarKey' | 'isPrimaryUser' | 'viewed' | 'mfaActive' | 'active'>
    & { account: (
      { __typename?: 'Account' }
      & Pick<Account, 'id' | 'active' | 'name' | 'viewed'>
      & { accountType?: Maybe<(
        { __typename?: 'AccountType' }
        & Pick<AccountType, 'id' | 'name'>
      )>, subscription?: Maybe<(
        { __typename?: 'AccountSubscriptionData' }
        & SubscriptionDetailsFragment
      )> }
    ), permissions?: Maybe<Array<Maybe<(
      { __typename?: 'ActiveUserPermission' }
      & Pick<ActiveUserPermission, 'resource' | 'operation'>
    )>>>, userPermissions?: Maybe<Array<Maybe<(
      { __typename?: 'ActiveUserPermission' }
      & Pick<ActiveUserPermission, 'operation' | 'resource'>
    )>>> }
  )> }
);

export type UsersIncludesQueryVariables = Exact<{
  targetAccountId?: Maybe<Scalars['Int']>;
  filters?: Maybe<UsersFilters>;
  includeAccount: Scalars['Boolean'];
}>;


export type UsersIncludesQuery = (
  { __typename?: 'Query' }
  & { Users?: Maybe<Array<Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'active' | 'firstName' | 'lastName' | 'email' | 'createdAt' | 'updatedAt'>
    & { account?: Maybe<(
      { __typename?: 'Account' }
      & Pick<Account, 'id' | 'active' | 'name'>
      & { accountType?: Maybe<(
        { __typename?: 'AccountType' }
        & Pick<AccountType, 'id' | 'name'>
      )>, accountSubType?: Maybe<(
        { __typename?: 'AccountSubType' }
        & Pick<AccountSubType, 'id' | 'name'>
      )> }
    )> }
  )>>> }
);

export type PublicUserQueryVariables = Exact<{
  userId: Scalars['Int'];
}>;


export type PublicUserQuery = (
  { __typename?: 'Query' }
  & { PublicUser?: Maybe<(
    { __typename?: 'PublicUser' }
    & Pick<PublicUser, 'id' | 'avatarKey' | 'firstName'>
  )> }
);

export type UpdateUserMutationVariables = Exact<{
  action: UpdateUserAction;
  targetUserId?: Maybe<Scalars['Int']>;
  data?: Maybe<UpdateUserData>;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateUser'>
);

export type UserExistsQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type UserExistsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'UserExists'>
);

export type UserPermissionsQueryVariables = Exact<{
  userId: Scalars['Int'];
}>;


export type UserPermissionsQuery = (
  { __typename?: 'Query' }
  & { UserPermissions: Array<Maybe<(
    { __typename?: 'UserPermission' }
    & Pick<UserPermission, 'id' | 'active' | 'createdAt' | 'updatedAt'>
    & { permission: (
      { __typename?: 'Permission' }
      & Pick<Permission, 'id' | 'operation' | 'resource' | 'description' | 'title'>
    ) }
  )>> }
);

export type UpdateUserPermissionMutationVariables = Exact<{
  permissionId: Scalars['Int'];
  userId: Scalars['Int'];
  active: Scalars['Boolean'];
}>;


export type UpdateUserPermissionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateUserPermission'>
);

export type UpdateUsersAccessMutationVariables = Exact<{
  userId: Scalars['Int'];
  applicationId?: Maybe<Scalars['Int']>;
  lawyerAssociationId?: Maybe<Scalars['Int']>;
}>;


export type UpdateUsersAccessMutation = (
  { __typename?: 'Mutation' }
  & { updateUsersAccess?: Maybe<(
    { __typename?: 'UsersAccess' }
    & Pick<UsersAccess, 'accountId' | 'applicationId' | 'createdAt' | 'id' | 'lawyerAssociationId' | 'updatedAt'>
  )> }
);

export type SubscriptionDetailsFragment = (
  { __typename?: 'AccountSubscriptionData' }
  & Pick<AccountSubscriptionData, 'isActive' | 'isPastDue' | 'hasFreeTrial' | 'gateWithBilling'>
  & { details?: Maybe<(
    { __typename?: 'AccountSubscriptionDataDetails' }
    & Pick<AccountSubscriptionDataDetails, 'periodStartDate' | 'periodEndDate'>
  )>, access: (
    { __typename?: 'AccountSubscriptionDataAccess' }
    & { applications: (
      { __typename?: 'AccountSubscriptionDataAccessFeature' }
      & Pick<AccountSubscriptionDataAccessFeature, 'allowed'>
    ), representatives: (
      { __typename?: 'AccountSubscriptionDataAccessFeature' }
      & Pick<AccountSubscriptionDataAccessFeature, 'allowed'>
    ), templates: (
      { __typename?: 'AccountSubscriptionDataAccessFeature' }
      & Pick<AccountSubscriptionDataAccessFeature, 'allowed'>
    ), leads: (
      { __typename?: 'AccountSubscriptionDataAccessFeature' }
      & Pick<AccountSubscriptionDataAccessFeature, 'allowed'>
    ), customization: (
      { __typename?: 'AccountSubscriptionDataAccessFeature' }
      & Pick<AccountSubscriptionDataAccessFeature, 'allowed'>
    ), mfa: (
      { __typename?: 'AccountSubscriptionDataAccessFeature' }
      & Pick<AccountSubscriptionDataAccessFeature, 'allowed'>
    ), tasks: (
      { __typename?: 'AccountSubscriptionDataAccessFeature' }
      & Pick<AccountSubscriptionDataAccessFeature, 'allowed'>
    ), forms: (
      { __typename?: 'AccountSubscriptionDataAccessFeature' }
      & Pick<AccountSubscriptionDataAccessFeature, 'allowed'>
    ), drafting: (
      { __typename?: 'AccountSubscriptionDataAccessFeature' }
      & Pick<AccountSubscriptionDataAccessFeature, 'allowed'>
    ) }
  ), usage?: Maybe<(
    { __typename?: 'AccountSubscriptionDataUsage' }
    & { retainers: (
      { __typename?: 'AccountSubscriptionDataUsageFeature' }
      & Pick<AccountSubscriptionDataUsageFeature, 'allowed' | 'used' | 'remaining'>
    ) }
  )> }
);

export const SubscriptionDetailsFragmentDoc = gql`
    fragment SubscriptionDetails on AccountSubscriptionData {
  isActive
  isPastDue
  hasFreeTrial
  gateWithBilling
  details {
    periodStartDate
    periodEndDate
  }
  access {
    applications {
      allowed
    }
    representatives {
      allowed
    }
    templates {
      allowed
    }
    leads {
      allowed
    }
    customization {
      allowed
    }
    mfa {
      allowed
    }
    tasks {
      allowed
    }
    forms {
      allowed
    }
    drafting {
      allowed
    }
  }
  usage {
    retainers {
      allowed
      used
      remaining
    }
  }
}
    `;
export const AccountsDocument = gql`
    query Accounts($cursor: Int, $searchTerm: String, $filters: AccountsFilters) {
  Accounts(cursor: $cursor, searchTerm: $searchTerm, filters: $filters) {
    data {
      id
      name
      active
      createdAt
      accountType {
        id
        name
      }
      primaryUser {
        id
        email
        firstName
        lastName
      }
      users {
        id
        email
        firstName
        lastName
      }
    }
    cursorId
    hasMore
    count
  }
}
    `;
export const AccountDocument = gql`
    query Account($accountId: Int, $userId: Int) {
  Account(id: $accountId, userId: $userId) {
    id
    name
    active
    createdAt
    meta
    viewed
    subscription {
      ...SubscriptionDetails
    }
    checkAddUser {
      canAdd
      usersCount
      usersAllowed
    }
    accountType {
      id
      name
    }
    users {
      id
      email
      firstName
      lastName
    }
    primaryUser {
      id
      email
      firstName
      lastName
    }
    accountTask {
      id
      key
      status
      account {
        id
      }
      task {
        id
        label
        action
        title
      }
    }
    applicationDocuments {
      id
      type {
        displayName
      }
      person {
        id
        firstName
        lastName
        type
      }
    }
    roles {
      id
      key
      title
    }
    companyCompanyTalents {
      id
      key
    }
    companyApplications {
      id
      key
    }
    accountPermissionGroups {
      permissionGroup {
        name
      }
    }
    settings
    leadsEmbedId
    storage
  }
}
    ${SubscriptionDetailsFragmentDoc}`;
export const AccountIncludesDocument = gql`
    query AccountIncludes($accountId: Int, $userId: Int, $includesPrimaryUser: Boolean!, $includesSubscription: Boolean!, $includesCheckAddUser: Boolean!) {
  Account(id: $accountId, userId: $userId) {
    id
    name
    active
    createdAt
    meta
    viewed
    leadsEmbedId
    storage
    accountType {
      name
    }
    primaryUser @include(if: $includesPrimaryUser) {
      id
      email
      firstName
      lastName
    }
    subscription @include(if: $includesSubscription) {
      ...SubscriptionDetails
    }
    checkAddUser @include(if: $includesCheckAddUser) {
      canAdd
      usersCount
      usersAllowed
    }
  }
}
    ${SubscriptionDetailsFragmentDoc}`;
export const AccountSubscriptionDocument = gql`
    query AccountSubscription {
  Account {
    subscription {
      ...SubscriptionDetails
    }
  }
}
    ${SubscriptionDetailsFragmentDoc}`;
export const UpdateAccountDocument = gql`
    mutation updateAccount($accountId: Int, $data: String!, $refreshUser: Boolean) {
  updateAccount(accountId: $accountId, data: $data, refreshUser: $refreshUser) {
    id
    name
  }
}
    `;
export const PublicSignUpDocument = gql`
    mutation publicSignUp($firstName: String!, $lastName: String!, $email: String!, $password: String!, $confirmPassword: String!, $recaptchaToken: String!) {
  publicSignUp(
    firstName: $firstName
    lastName: $lastName
    email: $email
    password: $password
    confirmPassword: $confirmPassword
    recaptchaToken: $recaptchaToken
  ) {
    errorMessage
    errorName
    success
  }
}
    `;
export const AccountDocumentDocument = gql`
    query AccountDocument($id: Int, $documentTypeName: String, $templateDocumentId: Int, $targetAccountId: Int) {
  AccountDocument(
    id: $id
    documentTypeName: $documentTypeName
    templateDocumentId: $templateDocumentId
    targetAccountId: $targetAccountId
  ) {
    id
    key
    document {
      id
      key
      createdAt
      order
    }
    documents {
      id
      key
      createdAt
      order
    }
    account {
      id
    }
    templateDocument {
      id
      name
    }
    createdAt
    updatedAt
  }
}
    `;
export const AccountDocumentRetainerDocument = gql`
    query AccountDocumentRetainer($id: Int, $targetAccountId: Int) {
  AccountDocument(id: $id, targetAccountId: $targetAccountId) {
    id
    key
    document {
      id
      key
      createdAt
      order
      signatureRequest {
        id
        details
        signatureRequestSigners {
          email
          id
          status
          updatedAt
        }
      }
    }
    documents {
      id
      key
      createdAt
      order
    }
    account {
      id
    }
    templateDocument {
      id
      name
    }
    createdAt
    updatedAt
  }
}
    `;
export const AccountDocumentsDocument = gql`
    query AccountDocuments($targetAccountId: Int, $type: AccountDocumentType, $includeSignatureRequest: Boolean!) {
  AccountDocuments(targetAccountId: $targetAccountId, type: $type) {
    id
    key
    document {
      id
      key
      createdAt
      order
      signatureRequest @include(if: $includeSignatureRequest) {
        id
        signatureRequestSigners {
          email
          id
          status
          updatedAt
        }
      }
    }
    documents {
      id
      key
      createdAt
      order
    }
    documentType {
      name
      displayName
      title
      tip
    }
    account {
      id
    }
    templateDocument {
      id
      name
    }
    createdAt
    updatedAt
  }
}
    `;
export const GetUrlDownloadAccountDocumentDocument = gql`
    query getUrlDownloadAccountDocument($documentTypeName: String, $targetAccountId: Int, $documentId: Int, $merge: Boolean, $templateDocumentId: Int, $id: Int) {
  getUrlDownloadAccountDocument(
    documentTypeName: $documentTypeName
    targetAccountId: $targetAccountId
    documentId: $documentId
    merge: $merge
    templateDocumentId: $templateDocumentId
    id: $id
  )
}
    `;
export const UploadAccountDocumentDocument = gql`
    mutation uploadAccountDocument($documentTypeName: String!, $file: Upload, $files: [Upload!], $data: UploadAccountDocumentData, $targetAccountId: Int) {
  uploadAccountDocument(
    documentTypeName: $documentTypeName
    file: $file
    files: $files
    data: $data
    targetAccountId: $targetAccountId
  ) {
    id
    document {
      id
    }
    documentType {
      name
      displayName
      title
      tip
    }
    createdAt
    updatedAt
  }
}
    `;
export const UpdateAccountDocumentDocument = gql`
    mutation updateAccountDocument($id: Int!, $action: UpdateAccountDocumentAction!, $data: UpdateAccountDocumentData) {
  updateAccountDocument(id: $id, action: $action, data: $data) {
    id
  }
}
    `;
export const HandleAccountEmbedDocument = gql`
    mutation handleAccountEmbed($action: HandleAccountEmbedAction!, $data: HandleAccountEmbedData) {
  handleAccountEmbed(action: $action, data: $data)
}
    `;
export const AccountPermissionsDocument = gql`
    query AccountPermissions($accountId: Int!) {
  AccountPermissions(accountId: $accountId) {
    id
    active
    account {
      id
    }
    permission {
      id
      resource
      operation
      description
    }
    createdAt
    updatedAt
  }
}
    `;
export const UpdateAccountPermissionDocument = gql`
    mutation updateAccountPermission($permissionId: Int!, $targetAccountId: Int!, $active: Boolean!) {
  updateAccountPermission(
    permissionId: $permissionId
    targetAccountId: $targetAccountId
    active: $active
  )
}
    `;
export const AccountSettingDocument = gql`
    query AccountSetting($key: String!, $targetAccountId: Int) {
  AccountSetting(key: $key, targetAccountId: $targetAccountId) {
    id
    value
    settingId
    accountId
    setting {
      id
      key
    }
    createdAt
    updatedAt
  }
}
    `;
export const UpdateAccountSettingsDocument = gql`
    mutation updateAccountSettings($data: String!, $file: Upload) {
  updateAccountSettings(data: $data, file: $file) {
    id
    firstName
    email
  }
}
    `;
export const AccountTagsDocument = gql`
    query AccountTags($groups: [String], $accountId: Int) {
  AccountTags(groups: $groups, accountId: $accountId) {
    id
    tag {
      id
      value
      tagGroups {
        id
        group
      }
    }
    years
    order
    createdAt
    updatedAt
  }
}
    `;
export const UpdateAccountTagDocument = gql`
    mutation updateAccountTag($tagId: Int, $years: Int, $order: Int, $group: String) {
  updateAccountTag(tagId: $tagId, years: $years, order: $order, group: $group)
}
    `;
export const DeleteAccountTagDocument = gql`
    mutation deleteAccountTag($id: Int!) {
  deleteAccountTag(id: $id)
}
    `;
export const GetAccountTaskDocument = gql`
    query getAccountTask($id: Int, $key: String, $accountId: Int, $personId: Int, $applicationId: Int, $isSupportingDocument: Boolean) {
  AccountTask(
    id: $id
    key: $key
    accountId: $accountId
    personId: $personId
    applicationId: $applicationId
    isSupportingDocument: $isSupportingDocument
  ) {
    id
    key
    createdAt
    updatedAt
    status
    meta
    applicationId
    application {
      id
      key
      type
    }
    task {
      id
      action
      label
      title
      meta
      required
      taskAssociation {
        id
        forms {
          id
          name
          title
          accountId
        }
        documentTypes {
          name
          id
          displayName
          title
          tip
          instructions
          required
          accountId
        }
        signatureRequestTypes {
          id
          name
          title
          subject
          message
          templateId
        }
      }
    }
    account {
      id
      name
    }
    person {
      firstName
      lastName
      type
      id
      email
    }
    signatureRequestSigners {
      signatureId
      status
      createdAt
      updatedAt
      email
      signatureRequest {
        id
        type {
          id
          name
          title
          subject
          message
        }
      }
    }
    feedback {
      id
      content
      status
      createdAt
      updatedAt
      ownedBy {
        id
        firstName
        lastName
      }
      applicationDocument {
        id
        account {
          id
        }
        type {
          name
        }
      }
    }
  }
}
    `;
export const GetAccountTaskIncludesDocument = gql`
    query getAccountTaskIncludes($id: Int, $key: String, $accountId: Int, $personId: Int, $applicationId: Int, $isSupportingDocument: Boolean, $isInternal: Boolean, $includeAccount: Boolean!, $includePerson: Boolean!, $includeSignatureRequestSigners: Boolean!, $includeFeedback: Boolean!, $includeApplication: Boolean!, $includeTask: Boolean!, $includeForms: Boolean!, $includeDocumentTypes: Boolean!, $includeSignatureRequestTypes: Boolean!, $includeUsers: Boolean!, $includeLawyerAssociation: Boolean!, $includeTaskAssociationApplication: Boolean!) {
  AccountTask(
    id: $id
    key: $key
    accountId: $accountId
    personId: $personId
    applicationId: $applicationId
    isSupportingDocument: $isSupportingDocument
    isInternal: $isInternal
  ) {
    id
    key
    createdAt
    updatedAt
    dueAt
    status
    meta
    applicationId
    isDue
    isPrivate
    priority
    assigneeUser @include(if: $includeUsers) {
      firstName
      email
      id
      lastName
    }
    id
    key
    ownerUser @include(if: $includeUsers) {
      firstName
      email
      id
      lastName
    }
    application @include(if: $includeApplication) {
      id
      key
      type
    }
    task @include(if: $includeTask) {
      id
      action
      label
      title
      description
      meta
      required
      taskAssociation {
        id
        forms @include(if: $includeForms) {
          id
          name
          title
          accountId
        }
        documentTypes @include(if: $includeDocumentTypes) {
          name
          id
          displayName
          title
          tip
          instructions
          required
          accountId
        }
        signatureRequestTypes @include(if: $includeSignatureRequestTypes) {
          id
          name
          title
          subject
          message
          templateId
        }
        lawyerAssociation @include(if: $includeLawyerAssociation) {
          key
          id
          associationAccount {
            primaryUser {
              firstName
              lastName
            }
          }
        }
        application @include(if: $includeTaskAssociationApplication) {
          id
          key
          ownerAccount {
            primaryUser {
              firstName
              lastName
            }
          }
        }
      }
    }
    account @include(if: $includeAccount) {
      id
      name
    }
    person @include(if: $includePerson) {
      firstName
      lastName
      type
      id
      email
    }
    signatureRequestSigners @include(if: $includeSignatureRequestSigners) {
      signatureId
      status
      createdAt
      updatedAt
      email
      signatureRequest {
        id
        signatureRequestId
        type {
          id
          name
          title
          subject
          message
        }
      }
    }
    feedback @include(if: $includeFeedback) {
      id
      content
      status
      createdAt
      updatedAt
      ownedBy {
        id
        firstName
        lastName
      }
      applicationDocument {
        id
        account {
          id
        }
        type {
          name
        }
      }
    }
  }
}
    `;
export const GetAccountTasksDocument = gql`
    query getAccountTasks($taskLabels: [String!], $accountId: Int, $personId: Int, $status: AccountTaskStatus, $applicationId: Int, $type: TaskAction) {
  AccountTasks(
    taskLabels: $taskLabels
    accountId: $accountId
    personId: $personId
    status: $status
    applicationId: $applicationId
    type: $type
  ) {
    id
    key
    createdAt
    updatedAt
    status
    meta
    account {
      id
      name
    }
    applicationId
    application {
      id
    }
    task {
      id
      action
      label
      title
      required
      taskAssociation {
        id
        forms {
          name
          title
          id
        }
        documentTypes {
          name
          id
          displayName
          title
          tip
        }
        signatureRequestTypes {
          id
          name
          title
          subject
          message
          templateId
        }
      }
    }
    person {
      firstName
      lastName
      type
      id
      email
    }
    feedback {
      id
      status
      role {
        key
      }
      companyTalent {
        key
      }
    }
  }
}
    `;
export const GetAccountTasksIncludesDocument = gql`
    query getAccountTasksIncludes($taskLabels: [String!], $accountId: Int, $personId: Int, $status: AccountTaskStatus, $applicationId: Int, $type: TaskAction, $includeTask: Boolean!, $includePerson: Boolean!, $includeTaskForm: Boolean!) {
  AccountTasks(
    taskLabels: $taskLabels
    accountId: $accountId
    personId: $personId
    status: $status
    applicationId: $applicationId
    type: $type
  ) {
    id
    key
    createdAt
    updatedAt
    status
    meta
    applicationId
    person @include(if: $includePerson) {
      firstName
      lastName
      type
      id
      email
    }
    task @include(if: $includeTask) {
      id
      action
      label
      title
      required
      meta
      taskAssociation @include(if: $includeTaskForm) {
        id
        forms {
          name
          title
          id
        }
      }
    }
  }
}
    `;
export const GetAccountTasksOverviewDocument = gql`
    query getAccountTasksOverview($taskLabels: [String!], $accountId: Int, $personId: Int, $status: AccountTaskStatus, $applicationId: Int, $isSupportingDocuments: Boolean, $isInternal: Boolean) {
  AccountTasks(
    taskLabels: $taskLabels
    accountId: $accountId
    personId: $personId
    status: $status
    applicationId: $applicationId
    isSupportingDocuments: $isSupportingDocuments
    isInternal: $isInternal
  ) {
    id
    key
    createdAt
    updatedAt
    status
    applicationId
    dueAt
    isDue
    task {
      id
      action
      label
      title
      required
    }
    person {
      firstName
      lastName
      type
      id
    }
  }
}
    `;
export const UpdateAccountTasksDocument = gql`
    mutation updateAccountTasks($data: AccountTaskUpdateData!, $where: AccountTaskUpdateWhere) {
  updateAccountTasks(data: $data, where: $where)
}
    `;
export const CreateChangeRequestDocument = gql`
    mutation createChangeRequest($accountId: Int!, $message: String!, $personId: Int, $documentType: String, $accountTaskId: Int, $roleId: Int, $companyTalentId: Int, $applicationId: Int) {
  createChangeRequest(
    accountId: $accountId
    message: $message
    personId: $personId
    documentType: $documentType
    accountTaskId: $accountTaskId
    roleId: $roleId
    companyTalentId: $companyTalentId
    applicationId: $applicationId
  )
}
    `;
export const UpdateChangeRequestDocument = gql`
    mutation updateChangeRequest($accountId: Int!, $accountTaskId: Int, $targetStatus: AccountTaskStatus) {
  updateChangeRequest(
    accountId: $accountId
    accountTaskId: $accountTaskId
    targetStatus: $targetStatus
  )
}
    `;
export const HandleAccountTaskDocument = gql`
    mutation handleAccountTask($action: HandleAccountTaskAction!, $data: HandleAccountTaskData, $targetAccountId: Int, $personId: Int, $accountTaskId: Int, $applicationId: Int) {
  handleAccountTask(
    accountTaskId: $accountTaskId
    action: $action
    data: $data
    targetAccountId: $targetAccountId
    personId: $personId
    applicationId: $applicationId
  )
}
    `;
export const DeleteAccountTaskDocument = gql`
    mutation deleteAccountTask($id: Int!) {
  deleteAccountTask(id: $id)
}
    `;
export const CreateAccountTaskDocument = gql`
    mutation createAccountTask($data: CreateAccountTaskData!) {
  createAccountTask(data: $data) {
    createdAt
    deleted
    id
    key
    status
    updatedAt
  }
}
    `;
export const FindManyInternalAccountTasksDocument = gql`
    query findManyInternalAccountTasks($isInternal: Boolean) {
  AccountTasks(isInternal: $isInternal) {
    createdAt
    dueAt
    assigneeUser {
      firstName
      email
      id
      lastName
    }
    id
    key
    ownerUser {
      firstName
      email
      id
      lastName
    }
    priority
    status
    updatedAt
    task {
      action
      description
      title
      id
      label
      taskAssociation {
        lawyerAssociation {
          key
          id
        }
        application {
          id
          key
        }
      }
    }
  }
}
    `;
export const AccountTasksListDocument = gql`
    query AccountTasksList($cursor: Int, $searchTerm: String, $sortBy: QuerySortBy, $filters: AccountTasksListFilters) {
  AccountTasksList(
    cursor: $cursor
    searchTerm: $searchTerm
    sortBy: $sortBy
    filters: $filters
  ) {
    count
    cursorId
    hasMore
    data {
      createdAt
      dueAt
      isDue
      assigneeUser {
        firstName
        email
        id
        lastName
      }
      id
      key
      ownerUser {
        firstName
        email
        id
        lastName
      }
      priority
      status
      updatedAt
      task {
        action
        description
        title
        id
        label
        taskAssociation {
          lawyerAssociation {
            key
            id
          }
          application {
            id
            key
          }
        }
      }
    }
  }
}
    `;
export const ActivityLogsDocument = gql`
    query ActivityLogs($cursor: Int, $sortBy: QuerySortBy, $searchTerm: String, $data: ActivityLogsData) {
  ActivityLogs(
    cursor: $cursor
    sortBy: $sortBy
    data: $data
    searchTerm: $searchTerm
  ) {
    count
    cursorId
    hasMore
    data {
      id
      createdAt
      type
      url
      details
      accountTask {
        key
      }
      application {
        key
      }
      applicationDocument {
        id
      }
      comment {
        id
      }
      person {
        id
        firstName
        lastName
      }
      user {
        email
        firstName
        lastName
      }
    }
  }
}
    `;
export const GetAnswersDocument = gql`
    query getAnswers($questionLabels: [String!]!, $accountId: Int, $personId: Int, $associations: String, $status: FormSubmissionStatus, $feature: FeatureType) {
  Answers(
    questionLabels: $questionLabels
    accountId: $accountId
    personId: $personId
    associations: $associations
    status: $status
    feature: $feature
  ) {
    id
    answer
    question {
      label
      id
    }
    answerOptions {
      type
      id
    }
  }
}
    `;
export const UpdateAnswersDocument = gql`
    mutation updateAnswers($personId: Int, $accountId: Int, $data: [UpdateAnswersData!]!) {
  updateAnswers(personId: $personId, accountId: $accountId, data: $data)
}
    `;
export const GetAnswersAdminDocument = gql`
    query getAnswersAdmin($accountId: Int!, $cursor: Int, $personId: Int, $searchTerm: String, $associations: String, $status: FormSubmissionStatus) {
  AnswersAdmin(
    accountId: $accountId
    cursor: $cursor
    personId: $personId
    searchTerm: $searchTerm
    associations: $associations
    status: $status
  ) {
    count
    hasMore
    cursorId
    data {
      id
      person {
        id
        firstName
        lastName
      }
      question {
        id
        label
        question
      }
      answer
      createdAt
    }
  }
}
    `;
export const GetApplicationDocument = gql`
    query getApplication($key: String, $id: Int, $type: ApplicationType) {
  Application(key: $key, id: $id, type: $type) {
    id
    key
    createdAt
    updatedAt
    type
    studentDirectStream
    meta
    displayName
    nonAccompanyingPersonIds
    lmiaConditions {
      isRoleFormCompleted
    }
    assignedUsers {
      firstName
      lastName
      id
    }
    applicationTemplate {
      name
      key
      isMultiStep
    }
    applicationItems {
      personTypes
      isPdfForm
      pdf
      manualPdf
      formId
      taskId
      documentTypeId
      stepStatusValue
      isOnboardingForm
      onlineForm
      form {
        title
        name
      }
      documentType {
        name
        displayName
      }
      task {
        title
      }
      conditions {
        id
        determiningProfileAttribute
        operator
        comparativeValue
        scope {
          id
        }
      }
    }
    talent {
      id
      key
      companyAccountId
      firstName
      lastName
      meta
      talentAccount {
        id
        name
        persons {
          id
          firstName
          lastName
          email
          type
        }
        users {
          id
          firstName
          lastName
          email
          createdAt
          updatedAt
        }
      }
      role {
        id
        title
        key
      }
    }
    ownerAccount {
      id
      name
      accountType {
        name
      }
      primaryUser {
        id
        firstName
        lastName
        email
      }
    }
    lawyerAccount {
      id
      name
      accountType {
        name
      }
    }
    status {
      id
      type
      value
      history
      createdAt
      updatedAt
    }
    statuses {
      id
      type
      value
      history
      createdAt
      updatedAt
      ownerAccount {
        id
      }
    }
    roles {
      id
      title
      key
    }
    persons {
      id
      firstName
      lastName
      type
    }
    client {
      key
      name
    }
    representative {
      id
      key
      name
    }
  }
}
    `;
export const GetApplicationIncludesDocument = gql`
    query getApplicationIncludes($key: String, $id: Int, $type: ApplicationType, $includesRepresentative: Boolean!, $includesOwnerAccount: Boolean!, $includesLawyerAccount: Boolean!, $includesAssignedUsers: Boolean!, $includesStatus: Boolean!, $includesClient: Boolean!, $includesReview: Boolean!) {
  Application(key: $key, id: $id, type: $type) {
    id
    key
    createdAt
    updatedAt
    type
    studentDirectStream
    meta
    displayName
    nonAccompanyingPersonIds
    representative @include(if: $includesRepresentative) {
      id
      key
      name
    }
    ownerAccount @include(if: $includesOwnerAccount) {
      id
      name
      accountType {
        name
      }
      primaryUser {
        id
        firstName
        lastName
        email
      }
    }
    lawyerAccount @include(if: $includesLawyerAccount) {
      id
      name
    }
    assignedUsers @include(if: $includesAssignedUsers) {
      firstName
      lastName
      id
    }
    status @include(if: $includesStatus) {
      id
      type
      value
      history
      createdAt
      updatedAt
    }
    client @include(if: $includesClient) {
      key
      name
    }
    reviewEngine @include(if: $includesReview)
  }
}
    `;
export const GetApplicationWithTemplateDocument = gql`
    query getApplicationWithTemplate($id: Int) {
  Application(id: $id) {
    id
    key
    createdAt
    updatedAt
    type
    displayName
    meta
    status {
      id
      type
      value
      history
      createdAt
      updatedAt
    }
    applicationTemplate {
      id
      name
      key
      isMultiStep
    }
    applicationItems {
      id
      personTypes
      formId
      taskId
      documentTypeId
      isPdfForm
      manualPdf
      pdf
      stepStatusValue
      isOnboardingForm
      onlineForm
      form {
        id
        name
        title
        accountId
        meta
      }
      documentType {
        id
        displayName
        name
        title
        tip
        required
        accountId
      }
      task {
        id
        title
        label
        accountId
        meta
      }
      conditions {
        id
        determiningProfileAttribute
        operator
        comparativeValue
        scope {
          id
        }
      }
    }
  }
}
    `;
export const GetApplicationBasicDocument = gql`
    query getApplicationBasic($key: String, $id: Int, $type: ApplicationType) {
  Application(key: $key, id: $id, type: $type) {
    id
    key
    createdAt
    updatedAt
    type
    studentDirectStream
    meta
    displayName
    nonAccompanyingPersonIds
  }
}
    `;
export const GetApplicationForExportDocument = gql`
    query getApplicationForExport($id: Int) {
  Application(id: $id) {
    id
    key
    fileExportHistory
  }
}
    `;
export const GetApplicationsDocument = gql`
    query getApplications {
  Applications {
    data {
      id
      key
      type
      createdAt
      updatedAt
      displayName
      talent {
        id
        key
        talentAccount {
          id
          name
          persons {
            id
            firstName
            lastName
            email
            type
          }
          users {
            id
            firstName
            lastName
            email
            createdAt
            updatedAt
          }
        }
        role {
          id
          title
          key
        }
      }
      ownerAccount {
        id
        name
        accountType {
          name
        }
        users {
          id
          email
        }
      }
      lawyerAccount {
        id
        name
        accountType {
          name
        }
      }
      statuses {
        id
        value
        type
        updatedAt
        application {
          id
          key
          lawyerAccount {
            name
          }
          ownerAccount {
            name
          }
        }
        ownerAccount {
          id
          name
        }
        talent {
          key
          role {
            id
            title
          }
          talentAccount {
            id
            users {
              id
              firstName
              lastName
              email
            }
          }
        }
      }
    }
  }
}
    `;
export const GetApplicationsOverviewDocument = gql`
    query getApplicationsOverview($accountId: Int, $ownerAccountId: Int, $cursor: Int, $searchTerm: String, $data: ApplicationsGetData, $filters: ApplicationsFilters) {
  Applications(
    accountId: $accountId
    ownerAccountId: $ownerAccountId
    cursor: $cursor
    searchTerm: $searchTerm
    data: $data
    filters: $filters
  ) {
    data {
      id
      key
      createdAt
      updatedAt
      type
      displayName
      ownerAccount {
        id
        name
        primaryUser {
          id
          email
          firstName
          lastName
        }
      }
      lawyerAccount {
        name
        id
        primaryUser {
          email
        }
      }
      talent {
        firstName
        lastName
        talentAccount {
          primaryUser {
            firstName
            lastName
            email
          }
        }
      }
      persons {
        id
        firstName
        lastName
        type
      }
      statuses {
        id
        value
        type
        application {
          type
        }
      }
      status {
        id
        type
        value
        history
        createdAt
        updatedAt
      }
      assignedUsers {
        id
        firstName
        lastName
      }
      representative {
        id
        name
      }
    }
    cursorId
    hasMore
    count
    templates {
      id
      key
      name
    }
  }
}
    `;
export const GetApplicationsIncludesDocument = gql`
    query getApplicationsIncludes($accountId: Int, $ownerAccountId: Int, $cursor: Int, $searchTerm: String, $data: ApplicationsGetData, $filters: ApplicationsFilters, $includeOwnerAccount: Boolean!) {
  Applications(
    accountId: $accountId
    ownerAccountId: $ownerAccountId
    cursor: $cursor
    searchTerm: $searchTerm
    data: $data
    filters: $filters
  ) {
    data {
      id
      key
      createdAt
      updatedAt
      type
      displayName
      ownerAccount @include(if: $includeOwnerAccount) {
        id
        name
        primaryUser {
          id
          email
          firstName
          lastName
        }
      }
    }
    cursorId
    hasMore
    count
  }
}
    `;
export const CreateApplicationDocument = gql`
    mutation createApplication($lawyerAccountId: Int, $assignedLawyerUserIds: [Int!], $companyTalentIds: [Int!], $ownerAccountId: Int, $type: ApplicationType, $templateType: ApplicationTemplateType, $roleId: Int, $meta: CreateApplicationMeta, $applicationTemplateId: Int, $representativeId: Int, $newAccountData: NewAccountData) {
  createApplication(
    lawyerAccountId: $lawyerAccountId
    assignedLawyerUserIds: $assignedLawyerUserIds
    companyTalentIds: $companyTalentIds
    ownerAccountId: $ownerAccountId
    type: $type
    templateType: $templateType
    roleId: $roleId
    meta: $meta
    applicationTemplateId: $applicationTemplateId
    representativeId: $representativeId
    newAccountData: $newAccountData
  ) {
    id
    key
    createdAt
    updatedAt
    type
  }
}
    `;
export const UpdateApplicationDocument = gql`
    mutation updateApplication($applicationId: Int!, $targetAccountId: Int, $data: ApplicationUpdateData) {
  updateApplication(
    applicationId: $applicationId
    targetAccountId: $targetAccountId
    data: $data
  )
}
    `;
export const HandleApplicationDocument = gql`
    mutation handleApplication($action: HandleApplicationAction!, $targetAccountId: Int, $data: HandleApplicationData) {
  handleApplication(
    action: $action
    targetAccountId: $targetAccountId
    data: $data
  )
}
    `;
export const DeleteApplicationDocument = gql`
    mutation deleteApplication($id: Int!) {
  deleteApplication(id: $id)
}
    `;
export const ApplicationDocumentDocument = gql`
    query ApplicationDocument($id: Int, $personId: Int, $documentTypeName: String, $accountId: Int, $applicationId: Int, $associations: JSON, $templateDocumentId: Int) {
  ApplicationDocument(
    id: $id
    personId: $personId
    documentTypeName: $documentTypeName
    accountId: $accountId
    applicationId: $applicationId
    associations: $associations
    templateDocumentId: $templateDocumentId
  ) {
    id
    applicationId
    history
    meta
    account {
      id
    }
    type {
      name
      id
      displayName
      title
      tip
      instructions
    }
    person {
      firstName
      lastName
      type
    }
    createdAt
    updatedAt
    document {
      id
      key
      createdAt
    }
    documents {
      id
      key
      createdAt
      order
    }
    feedback {
      id
      content
      status
      createdAt
      updatedAt
    }
    account {
      id
    }
    templateDocument {
      id
      name
    }
  }
}
    `;
export const ApplicationDocumentsDocument = gql`
    query ApplicationDocuments($accountId: Int, $personId: Int, $applicationId: Int, $documentTypeFilters: ApplicationDocumentsDocumentTypeFilters, $associations: JSON, $accountTaskId: Int, $allSupportingDocuments: Boolean, $includeTemplateDocuments: Boolean, $hasDocuments: Boolean) {
  ApplicationDocuments(
    accountId: $accountId
    personId: $personId
    applicationId: $applicationId
    documentTypeFilters: $documentTypeFilters
    associations: $associations
    accountTaskId: $accountTaskId
    allSupportingDocuments: $allSupportingDocuments
    includeTemplateDocuments: $includeTemplateDocuments
    hasDocuments: $hasDocuments
  ) {
    id
    createdAt
    updatedAt
    applicationId
    history
    associations
    account {
      id
    }
    document {
      id
      key
      createdAt
    }
    documents {
      id
      key
      createdAt
      order
    }
    type {
      id
      name
      displayName
      title
      tip
      required
    }
    person {
      id
      firstName
      lastName
      type
    }
    templateDocument {
      id
      name
    }
  }
}
    `;
export const GetUrlDocumentsDocument = gql`
    query getUrlDocuments($applicationId: Int!, $accountId: Int, $personId: Int, $documentTypes: [String]) {
  getUrlDocuments(
    applicationId: $applicationId
    accountId: $accountId
    personId: $personId
    documentTypes: $documentTypes
  )
}
    `;
export const GetUrlDownloadApplicationDocumentDocument = gql`
    query getUrlDownloadApplicationDocument($personId: Int, $documentTypeName: String, $accountId: Int, $applicationId: Int, $historyDocumentId: Int, $documentId: Int, $associations: JSON, $merge: Boolean, $templateDocumentId: Int) {
  getUrlDownloadApplicationDocument(
    personId: $personId
    documentTypeName: $documentTypeName
    accountId: $accountId
    applicationId: $applicationId
    historyDocumentId: $historyDocumentId
    documentId: $documentId
    associations: $associations
    merge: $merge
    templateDocumentId: $templateDocumentId
  )
}
    `;
export const UploadApplicationDocumentDocument = gql`
    mutation uploadApplicationDocument($document: Upload, $documents: [Upload!], $personId: Int, $typeName: String!, $accountId: Int, $applicationId: Int, $supportingDocumentApplicationId: Int, $associations: JSON) {
  uploadApplicationDocument(
    document: $document
    documents: $documents
    personId: $personId
    typeName: $typeName
    accountId: $accountId
    applicationId: $applicationId
    supportingDocumentApplicationId: $supportingDocumentApplicationId
    associations: $associations
  ) {
    id
    account {
      id
    }
    document {
      id
      key
    }
    documents {
      id
      key
      createdAt
    }
    type {
      id
      name
    }
  }
}
    `;
export const GenerateApplicationDocumentsDocument = gql`
    mutation generateApplicationDocuments($applicationId: Int!, $applicationType: ApplicationType, $talentId: Int, $meta: GenerateApplicationDocumentsMeta) {
  generateApplicationDocuments(
    applicationId: $applicationId
    applicationType: $applicationType
    talentId: $talentId
    meta: $meta
  )
}
    `;
export const SignApplicationDocumentsDocument = gql`
    mutation signApplicationDocuments($applicationId: Int!, $applicationType: ApplicationType, $talentId: Int, $documentsToSignByPerson: JSON) {
  signApplicationDocuments(
    applicationId: $applicationId
    applicationType: $applicationType
    talentId: $talentId
    documentsToSignByPerson: $documentsToSignByPerson
  )
}
    `;
export const UpdateApplicationDocumentsDocument = gql`
    mutation updateApplicationDocuments($action: UpdateApplicationDocumentsAction!, $data: UpdateApplicationDocumentsData) {
  updateApplicationDocuments(action: $action, data: $data)
}
    `;
export const GenerateApplicationDocumentDocument = gql`
    mutation generateApplicationDocument($applicationId: Int!, $documentName: GovernmentApplicationForm!, $personId: Int) {
  generateApplicationDocument(
    applicationId: $applicationId
    documentName: $documentName
    personId: $personId
  ) {
    id
    applicationId
    history
    account {
      id
    }
    type {
      name
      id
      displayName
      title
      tip
      instructions
    }
    person {
      firstName
      lastName
      type
    }
    createdAt
    updatedAt
    document {
      id
      key
      createdAt
    }
    documents {
      id
      key
      createdAt
      order
    }
    feedback {
      id
      content
      status
      createdAt
      updatedAt
    }
    account {
      id
    }
  }
}
    `;
export const CreateApplicationItemDocument = gql`
    mutation createApplicationItem($applicationId: Int!, $personTypes: [PersonType!]!, $formId: Int, $documentTypeId: Int, $taskId: Int, $pdf: GovernmentApplicationForm, $manualPdf: String, $onlineForm: GovernmentOnlineForm, $stepStatusValue: StatusValue) {
  createApplicationItem(
    applicationId: $applicationId
    personTypes: $personTypes
    formId: $formId
    documentTypeId: $documentTypeId
    taskId: $taskId
    pdf: $pdf
    manualPdf: $manualPdf
    onlineForm: $onlineForm
    stepStatusValue: $stepStatusValue
  ) {
    id
  }
}
    `;
export const DeleteApplicationItemDocument = gql`
    mutation deleteApplicationItem($id: Int!) {
  deleteApplicationItem(id: $id) {
    id
  }
}
    `;
export const UpdateApplicationItemDocument = gql`
    mutation updateApplicationItem($id: Int!, $action: UpdateApplicationTemplateItemAction!, $data: UpdateApplicationTemplateItemData) {
  updateApplicationItem(id: $id, action: $action, data: $data) {
    id
    createdAt
  }
}
    `;
export const ApplicationTemplatesDocument = gql`
    query ApplicationTemplates($cursor: Int, $searchTerm: String, $sortBy: QuerySortBy) {
  ApplicationTemplates(cursor: $cursor, searchTerm: $searchTerm, sortBy: $sortBy) {
    count
    cursorId
    hasMore
    data {
      accountId
      createdAt
      description
      id
      key
      name
      updatedAt
    }
  }
}
    `;
export const ApplicationTemplateDocument = gql`
    query ApplicationTemplate($key: String!) {
  ApplicationTemplate(key: $key) {
    accountId
    createdAt
    description
    id
    key
    name
    updatedAt
    isMultiStep
    applicationTemplateItems {
      id
      applicationTemplateId
      personTypes
      formId
      taskId
      documentTypeId
      isPdfForm
      isOnboardingForm
      onlineForm
      pdf
      manualPdf
      stepStatusValue
      form {
        id
        name
        title
        accountId
        meta
      }
      documentType {
        id
        displayName
        name
        title
        tip
        required
        accountId
      }
      task {
        id
        title
        label
        accountId
        meta
      }
      conditions {
        id
        determiningProfileAttribute
        operator
        comparativeValue
        scope {
          id
        }
      }
    }
  }
}
    `;
export const CreateApplicationTemplateDocument = gql`
    mutation createApplicationTemplate($name: String!, $description: String, $applicationType: ApplicationType, $templateType: ApplicationTemplateType) {
  createApplicationTemplate(
    name: $name
    description: $description
    templateType: $templateType
    applicationType: $applicationType
  ) {
    accountId
    createdAt
    description
    id
    key
    name
    updatedAt
  }
}
    `;
export const UpdateApplicationTemplateDocument = gql`
    mutation updateApplicationTemplate($id: Int!, $data: UpdateApplicationTemplateData) {
  updateApplicationTemplate(id: $id, data: $data)
}
    `;
export const DeleteApplicationTemplateDocument = gql`
    mutation deleteApplicationTemplate($id: Int!) {
  deleteApplicationTemplate(id: $id)
}
    `;
export const CreateApplicationTemplateItemDocument = gql`
    mutation createApplicationTemplateItem($applicationTemplateId: Int!, $personTypes: [PersonType!]!, $formId: Int, $documentTypeId: Int, $taskId: Int, $pdf: GovernmentApplicationForm, $manualPdf: String, $onlineForm: GovernmentOnlineForm, $stepStatusValue: StatusValue) {
  createApplicationTemplateItem(
    applicationTemplateId: $applicationTemplateId
    personTypes: $personTypes
    formId: $formId
    documentTypeId: $documentTypeId
    taskId: $taskId
    pdf: $pdf
    manualPdf: $manualPdf
    onlineForm: $onlineForm
    stepStatusValue: $stepStatusValue
  ) {
    id
  }
}
    `;
export const DeleteApplicationTemplateItemDocument = gql`
    mutation deleteApplicationTemplateItem($id: Int!) {
  deleteApplicationTemplateItem(id: $id) {
    id
  }
}
    `;
export const UpdateApplicationTemplateItemDocument = gql`
    mutation updateApplicationTemplateItem($id: Int!, $action: UpdateApplicationTemplateItemAction!, $data: UpdateApplicationTemplateItemData) {
  updateApplicationTemplateItem(id: $id, action: $action, data: $data) {
    id
    createdAt
  }
}
    `;
export const HandleAuthDocument = gql`
    mutation handleAuth($action: HandleAuthAction!, $data: HandleAuthData) {
  handleAuth(action: $action, data: $data)
}
    `;
export const DeleteCommentDocument = gql`
    mutation deleteComment($id: Int!) {
  deleteComment(id: $id)
}
    `;
export const UpdateCommentDocument = gql`
    mutation updateComment($id: Int!, $content: String!) {
  updateComment(id: $id, content: $content)
}
    `;
export const CreateCommentDocument = gql`
    mutation createComment($accountTaskId: Int, $applicationDocumentId: Int, $applicationId: Int, $companyTalentId: Int, $roleId: Int, $parentId: Int, $content: String!, $notifies: [Int!]!) {
  createComment(
    accountTaskId: $accountTaskId
    applicationDocumentId: $applicationDocumentId
    applicationId: $applicationId
    companyTalentId: $companyTalentId
    roleId: $roleId
    parentId: $parentId
    content: $content
    mentions: $notifies
  )
}
    `;
export const GetCommentsDocument = gql`
    query getComments($accountTaskId: Int, $applicationDocumentId: Int, $applicationId: Int, $companyTalentId: Int, $roleId: Int, $take: Int) {
  Comments(
    accountTaskId: $accountTaskId
    applicationDocumentId: $applicationDocumentId
    applicationId: $applicationId
    companyTalentId: $companyTalentId
    roleId: $roleId
    take: $take
  ) {
    id
    content
    parentId
    parent {
      id
      content
      ownedById
      ownedBy {
        id
        firstName
        lastName
        email
      }
      account {
        id
        name
        accountType {
          name
        }
      }
      createdAt
      updatedAt
    }
    children {
      id
      content
      parentId
      ownedById
      ownedBy {
        id
        firstName
        lastName
        email
      }
      account {
        name
        accountType {
          name
        }
      }
      createdAt
      updatedAt
    }
    account {
      id
      name
      accountType {
        name
      }
    }
    commentAssociations {
      id
      accountTaskId
      accountTask {
        key
      }
      applicationDocumentId
      applicationDocument {
        associatedAccountTask {
          key
        }
      }
      applicationId
      application {
        key
      }
      companyTalentId
      companyTalent {
        key
      }
      roleId
      role {
        key
      }
    }
    ownedById
    ownedBy {
      id
      firstName
      lastName
      email
    }
    createdAt
    updatedAt
  }
}
    `;
export const GetCommentsIncludesDocument = gql`
    query getCommentsIncludes($accountTaskId: Int, $applicationDocumentId: Int, $applicationId: Int, $companyTalentId: Int, $roleId: Int, $take: Int, $includeAssociations: Boolean!) {
  Comments(
    accountTaskId: $accountTaskId
    applicationDocumentId: $applicationDocumentId
    applicationId: $applicationId
    companyTalentId: $companyTalentId
    roleId: $roleId
    take: $take
  ) {
    id
    content
    commentAssociations @include(if: $includeAssociations) {
      id
      accountTaskId
      accountTask {
        key
      }
      applicationDocumentId
      applicationDocument {
        associatedAccountTask {
          key
        }
      }
      applicationId
      application {
        key
      }
      companyTalentId
      companyTalent {
        key
      }
      roleId
      role {
        key
      }
    }
    ownedBy {
      id
      firstName
      lastName
      email
    }
    createdAt
    updatedAt
  }
}
    `;
export const CommentsListDocument = gql`
    query CommentsList($cursor: Int, $searchTerm: String, $sortBy: QuerySortBy, $filters: CommentsListFilters) {
  CommentsList(
    cursor: $cursor
    searchTerm: $searchTerm
    sortBy: $sortBy
    filters: $filters
  ) {
    count
    cursorId
    hasMore
    data {
      content
      createdAt
      id
      commentAssociations {
        id
        accountTaskId
        accountTask {
          key
        }
        applicationDocumentId
        applicationDocument {
          associatedAccountTask {
            key
          }
        }
        applicationId
        application {
          key
        }
        companyTalentId
        companyTalent {
          key
        }
        roleId
        role {
          key
        }
      }
    }
  }
}
    `;
export const CompanyComplianceDocument = gql`
    query companyCompliance {
  CompanyCompliance {
    id
    approvedAt
    expiresAt
    nextAuditAt
    createdAt
    updatedAt
  }
}
    `;
export const UpsertCompanyComplianceDocument = gql`
    mutation upsertCompanyCompliance($data: CompanyComplianceUpdateData!) {
  upsertCompanyCompliance(data: $data) {
    id
    expiresAt
    createdAt
    updatedAt
  }
}
    `;
export const GetCompanyTalentDocument = gql`
    query getCompanyTalent($id: Int, $accountId: Int, $key: String) {
  CompanyTalent(id: $id, accountId: $accountId, key: $key) {
    id
    key
    companyAccountId
    talentAccountId
    talentType
    firstName
    lastName
    email
    meta
    applications {
      key
    }
    companyAccount {
      id
      name
      persons {
        id
        firstName
        lastName
        email
        type
      }
      users {
        id
        firstName
        lastName
        email
        createdAt
        updatedAt
      }
    }
    talentAccount {
      id
      name
      persons {
        id
        firstName
        lastName
        email
        type
      }
      users {
        id
        firstName
        lastName
        email
        createdAt
        updatedAt
      }
    }
    invitation {
      id
      slug
      email
      status
      expires
    }
    role {
      id
      key
      title
      createdAt
      updatedAt
    }
    application {
      id
      key
      statuses {
        id
        value
        type
        talent {
          id
          role {
            id
            title
          }
        }
      }
    }
    feedback {
      content
      status
      updatedAt
      createdAt
      ownedBy {
        id
        firstName
      }
      accountTask {
        id
        account {
          id
        }
      }
    }
  }
}
    `;
export const GetCompanyTalentsDocument = gql`
    query getCompanyTalents($accountId: Int, $applicationId: Int) {
  CompanyTalents(accountId: $accountId, applicationId: $applicationId) {
    id
    key
    companyAccountId
    talentAccountId
    talentType
    firstName
    lastName
    email
    meta
    companyAccount {
      id
      name
      persons {
        id
        firstName
        lastName
        email
        type
      }
      users {
        id
        firstName
        lastName
        email
        createdAt
        updatedAt
      }
    }
    talentAccount {
      id
      name
      persons {
        id
        firstName
        lastName
        email
        type
      }
      users {
        id
        firstName
        lastName
        email
        createdAt
        updatedAt
      }
    }
    invitation {
      id
      slug
      email
      status
      expires
    }
    role {
      id
      key
      title
      createdAt
      updatedAt
    }
    application {
      id
      key
    }
  }
}
    `;
export const CreateCompanyTalentDocument = gql`
    mutation createCompanyTalent($email: String!, $firstName: String!, $lastName: String!, $roleId: Int, $targetAccountId: Int, $meta: JSON) {
  createCompanyTalent(
    email: $email
    firstName: $firstName
    lastName: $lastName
    roleId: $roleId
    targetAccountId: $targetAccountId
    meta: $meta
  ) {
    id
    key
    companyAccountId
    talentAccountId
  }
}
    `;
export const UpdateCompanyTalentDocument = gql`
    mutation updateCompanyTalent($id: Int!, $data: CompanyTalentUpdateData!) {
  updateCompanyTalent(id: $id, data: $data) {
    id
    key
    companyAccountId
    talentAccountId
  }
}
    `;
export const GetCompliancesDocument = gql`
    query getCompliances {
  Compliance {
    id
    name
    commitment
    target
    requiredDocuments
    createdAt
    updatedAt
    documents {
      id
      key
      createdAt
    }
  }
}
    `;
export const CreateComplianceDocument = gql`
    mutation createCompliance($name: String!, $commitment: String!, $target: String!, $requiredDocuments: String!) {
  createCompliance(
    name: $name
    commitment: $commitment
    target: $target
    requiredDocuments: $requiredDocuments
  ) {
    id
    name
    commitment
    target
    requiredDocuments
    createdAt
    updatedAt
  }
}
    `;
export const UpdateComplianceDocument = gql`
    mutation updateCompliance($id: Int!, $name: String, $commitment: String, $target: String, $requiredDocuments: String, $deleted: Boolean) {
  updateCompliance(
    id: $id
    name: $name
    commitment: $commitment
    target: $target
    requiredDocuments: $requiredDocuments
    deleted: $deleted
  )
}
    `;
export const UploadComplianceDocumentDocument = gql`
    mutation uploadComplianceDocument($file: Upload!, $complianceId: Int!, $documentId: Int) {
  uploadComplianceDocument(
    file: $file
    complianceId: $complianceId
    documentId: $documentId
  ) {
    id
    documents {
      id
      key
      createdAt
    }
  }
}
    `;
export const DeleteComplianceDocumentsDocument = gql`
    mutation deleteComplianceDocuments($id: Int!) {
  deleteComplianceDocuments(complianceId: $id) {
    id
    documents {
      id
      key
      createdAt
    }
  }
}
    `;
export const GenerateContentDocument = gql`
    mutation generateContent($type: ContentType!, $targetAccountId: Int, $applicationId: Int, $personId: Int, $content: String, $documentTypeName: String) {
  generateContent(
    type: $type
    targetAccountId: $targetAccountId
    applicationId: $applicationId
    personId: $personId
    content: $content
    documentTypeName: $documentTypeName
  )
}
    `;
export const ConversationDocument = gql`
    query Conversation($key: String, $targetUserId: Int) {
  Conversation(key: $key, targetUserId: $targetUserId) {
    id
    key
    createdAt
    disabled
    deleted
    disableNotification
    accountOwner {
      id
      name
      accountType {
        name
      }
      users {
        id
        firstName
        lastName
        avatarKey
        active
      }
    }
    users {
      id
      firstName
      lastName
      avatarKey
      account {
        id
        name
        accountType {
          name
        }
      }
    }
    messages {
      id
      deleted
      content
      createdAt
      ownedById
      ownedBy {
        id
        firstName
        lastName
        avatarKey
        account {
          accountType {
            name
          }
        }
      }
      readBy {
        firstName
        lastName
      }
    }
  }
}
    `;
export const ConversationsDocument = gql`
    query Conversations {
  Conversations {
    id
    key
    deleted
    disabled
    disableNotification
    createdAt
    mostRecentMessage {
      id
      content
      createdAt
    }
    accountOwner {
      id
      name
      accountType {
        name
      }
      users {
        id
        firstName
        lastName
        avatarKey
        account {
          accountType {
            name
          }
        }
      }
    }
    users {
      id
      firstName
      lastName
      avatarKey
      account {
        id
        name
        accountType {
          name
        }
      }
    }
  }
}
    `;
export const CreateConversationDocument = gql`
    mutation createConversation($userIds: [Int!]!) {
  createConversation(userIds: $userIds) {
    id
    key
    accountOwner {
      id
      name
      accountType {
        name
      }
    }
    users {
      id
      firstName
      lastName
    }
  }
}
    `;
export const CreateMessageDocument = gql`
    mutation createMessage($conversationId: Int!, $content: String!) {
  createMessage(conversationId: $conversationId, content: $content) {
    id
    deleted
    content
    ownedBy {
      id
      firstName
      lastName
    }
  }
}
    `;
export const UpdateMessageDocument = gql`
    mutation updateMessage($id: Int!, $content: String, $deleted: Boolean) {
  updateMessage(id: $id, content: $content, deleted: $deleted) {
    id
    deleted
    content
    ownedBy {
      id
      firstName
      lastName
    }
  }
}
    `;
export const UpdateConversationDocument = gql`
    mutation updateConversation($conversationId: Int!, $deleted: Boolean, $disabled: Boolean, $userId: Int) {
  updateConversation(
    id: $conversationId
    deleted: $deleted
    disabled: $disabled
    userId: $userId
  )
}
    `;
export const DisableNotificationDocument = gql`
    mutation disableNotification($conversationId: Int!, $disableNotification: Boolean!) {
  updateConversation(
    id: $conversationId
    disableNotification: $disableNotification
  )
}
    `;
export const GetUrlDocumentDocument = gql`
    query getUrlDocument($key: String!) {
  getUrlDocument(key: $key)
}
    `;
export const DeleteDocumentDocument = gql`
    mutation deleteDocument($id: Int!) {
  deleteDocument(id: $id)
}
    `;
export const DocumentTypeDocument = gql`
    query DocumentType($name: String!) {
  DocumentType(name: $name) {
    id
    name
    displayName
    title
    tip
    instructions
    accountId
  }
}
    `;
export const DocumentTypesListDocument = gql`
    query DocumentTypesList($cursor: Int, $searchTerm: String, $sortBy: QuerySortBy, $filters: DocumentTypesListFilters) {
  DocumentTypesList(
    cursor: $cursor
    searchTerm: $searchTerm
    sortBy: $sortBy
    filters: $filters
  ) {
    count
    cursorId
    hasMore
    data {
      displayName
      instructions
      id
      name
      required
      tip
      title
    }
  }
}
    `;
export const DocumentTypesDocument = gql`
    query DocumentTypes($notIn: [String], $names: [String], $userData: DocumentTypesUserData, $typeGroup: DocumentTypeGroup) {
  DocumentTypes(
    notIn: $notIn
    names: $names
    userData: $userData
    typeGroup: $typeGroup
  ) {
    displayName
    id
    name
    required
    tip
    title
    countryInstructions
    accountId
  }
}
    `;
export const CreateDocumentTypeDocument = gql`
    mutation createDocumentType($displayName: String!, $tip: String!, $required: Boolean!) {
  createDocumentType(displayName: $displayName, tip: $tip, required: $required) {
    displayName
    id
    name
    required
    tip
    title
  }
}
    `;
export const DeleteDocumentTypeDocument = gql`
    mutation deleteDocumentType($id: Int!) {
  deleteDocumentType(id: $id) {
    id
  }
}
    `;
export const UpdateDocumentTypeDocument = gql`
    mutation updateDocumentType($id: Int!, $displayName: String, $tip: String, $required: Boolean) {
  updateDocumentType(
    id: $id
    displayName: $displayName
    tip: $tip
    required: $required
  ) {
    displayName
    id
    name
    required
    tip
    title
  }
}
    `;
export const ExportDocument = gql`
    mutation export($action: ExportAction!, $data: ExportData) {
  export(action: $action, data: $data)
}
    `;
export const FeedbacksDocument = gql`
    query Feedbacks($cursor: Int, $searchTerm: String, $sortBy: QuerySortBy, $filters: FeedbacksFilters) {
  Feedbacks(
    cursor: $cursor
    searchTerm: $searchTerm
    sortBy: $sortBy
    filters: $filters
  ) {
    count
    cursorId
    hasMore
    data {
      id
      content
      status
      createdAt
      updatedAt
      ownedBy {
        id
        firstName
        lastName
      }
      accountTask {
        key
      }
      application {
        key
      }
      role {
        key
      }
      companyTalent {
        key
      }
      applicationDocument {
        id
        account {
          id
        }
        type {
          name
        }
        associatedAccountTask {
          key
        }
      }
    }
  }
}
    `;
export const CreateFormDocument = gql`
    mutation createForm($name: String!, $description: String, $templateFormId: Int) {
  createForm(
    name: $name
    description: $description
    templateFormId: $templateFormId
  ) {
    id
    name
    title
  }
}
    `;
export const FormsDocument = gql`
    query Forms($cursor: Int, $searchTerm: String, $sortBy: QuerySortBy, $filters: FormsFilters) {
  Forms(
    cursor: $cursor
    searchTerm: $searchTerm
    sortBy: $sortBy
    filters: $filters
  ) {
    count
    cursorId
    hasMore
    data {
      id
      name
      title
      description
    }
  }
}
    `;
export const GetFormForQuestionGroupsDocument = gql`
    query getFormForQuestionGroups($query: FormQueryInput) {
  Form(formQuery: $query) {
    name
    title
    id
    accountId
    groups {
      id
      uniqueKey
      subtitle
      title
      submissionStatus
    }
  }
}
    `;
export const GetFormForBuilderDocument = gql`
    query getFormForBuilder($query: FormQueryInput) {
  Form(formQuery: $query) {
    name
    title
    id
    accountId
    groups {
      id
      uniqueKey
      subtitle
      title
      submissionStatus
    }
    applicationTemplates {
      id
      key
      name
    }
  }
}
    `;
export const GetFormIncludesDocument = gql`
    query getFormIncludes($query: FormQueryInput) {
  Form(formQuery: $query) {
    name
    title
    id
    accountId
  }
}
    `;
export const GetLeadsFormDocument = gql`
    query getLeadsForm($formName: String!) {
  LeadsForm(formName: $formName) {
    name
    title
    id
    content {
      __typename
      ... on QuestionGrouping {
        id
        title
        subtitle
        questions {
          id
          question
          label
          tip
          inputType
          validationRules
          createdAt
          updatedAt
          maxCharacters
          questionOptions {
            id
            option
          }
          conditionsRequiredToRenderThisQuestion {
            id
            value
            scopeId
            isParentCondition
            formula {
              question {
                id
                label
              }
              operation
              expect
            }
            parentConditions {
              id
              scopeId
            }
          }
        }
      }
    }
  }
}
    `;
export const GetFormByNameDocument = gql`
    query getFormByName($query: FormQueryInput, $associations: String, $excludeFormSubmissionAnswers: Boolean!) {
  Form(formQuery: $query) {
    id
    name
    title
    formSubmissions(associations: $associations) {
      id
      version
      status
      createdAt
      answers @skip(if: $excludeFormSubmissionAnswers) {
        question {
          label
        }
        id
        answer
        answerOptions {
          id
          type
        }
      }
      form {
        id
        name
      }
    }
    content {
      __typename
      ... on Question {
        id
        question
        label
        tip
        inputType
        validationRules
        createdAt
        updatedAt
        maxCharacters
        questionOptions {
          id
          option
        }
        conditionsRequiredToRenderThisQuestion(associations: $associations) {
          id
          value
          scopeId
          isParentCondition
          parentConditions {
            id
            scopeId
          }
          formula {
            question {
              id
              label
            }
            operation
            expect
          }
        }
        answers(associations: $associations) {
          question {
            label
          }
          id
          answer
          updatedAt
          createdAt
        }
        repeaterForm {
          id
          name
          title
          formSubmissions(associations: $associations) {
            id
            version
            status
            createdAt
            answers {
              question {
                label
              }
              groupingId
              id
              answer
              answerOptions {
                id
                type
              }
            }
            form {
              id
              name
            }
          }
          content {
            __typename
            ... on Question {
              id
              question
              label
              tip
              inputType
              validationRules
              createdAt
              updatedAt
              maxCharacters
              questionOptions {
                id
                option
              }
              conditionsRequiredToRenderThisQuestion(associations: $associations) {
                id
                value
                scopeId
                isParentCondition
                parentConditions {
                  id
                  scopeId
                }
                formula {
                  question {
                    id
                    label
                  }
                  operation
                  expect
                }
              }
              answers(associations: $associations) {
                question {
                  label
                }
                id
                groupingId
                answer
                updatedAt
                createdAt
              }
            }
            ... on QuestionGrouping {
              id
              title
              subtitle
              questions {
                id
                question
                label
                tip
                inputType
                validationRules
                createdAt
                updatedAt
                maxCharacters
                questionOptions {
                  id
                  option
                }
                answers(associations: $associations) {
                  id
                  question {
                    label
                  }
                  answer
                  groupingId
                  answerOptions {
                    id
                    type
                  }
                }
                conditionsRequiredToRenderThisQuestion(associations: $associations) {
                  id
                  value
                  scopeId
                  isParentCondition
                  parentConditions {
                    id
                    scopeId
                  }
                  formula {
                    question {
                      id
                      label
                    }
                    operation
                    expect
                  }
                }
              }
            }
          }
        }
      }
      ... on QuestionGrouping {
        id
        title
        subtitle
        questions {
          id
          question
          label
          tip
          inputType
          validationRules
          createdAt
          updatedAt
          maxCharacters
          questionOptions {
            id
            option
          }
          answers(associations: $associations) {
            id
            question {
              label
            }
            answer
            answerOptions {
              id
              type
            }
          }
          conditionsRequiredToRenderThisQuestion(associations: $associations) {
            id
            value
            scopeId
            isParentCondition
            parentConditions {
              id
              scopeId
            }
            formula {
              question {
                id
                label
              }
              operation
              expect
            }
          }
          repeaterForm {
            id
            name
            title
            formSubmissions(associations: $associations) {
              id
              version
              status
              createdAt
              answers {
                groupingId
                question {
                  label
                }
                id
                answer
                answerOptions {
                  id
                  type
                }
              }
              form {
                id
                name
              }
            }
            content {
              __typename
              ... on Question {
                id
                question
                label
                tip
                inputType
                validationRules
                createdAt
                updatedAt
                maxCharacters
                questionOptions {
                  id
                  option
                }
                conditionsRequiredToRenderThisQuestion(associations: $associations) {
                  id
                  value
                  scopeId
                  isParentCondition
                  parentConditions {
                    id
                    scopeId
                  }
                  formula {
                    question {
                      id
                      label
                    }
                    operation
                    expect
                  }
                }
                answers(associations: $associations) {
                  groupingId
                  question {
                    label
                  }
                  id
                  answer
                  updatedAt
                  createdAt
                }
              }
              ... on QuestionGrouping {
                id
                title
                subtitle
                questions {
                  id
                  question
                  label
                  tip
                  inputType
                  validationRules
                  createdAt
                  updatedAt
                  maxCharacters
                  questionOptions {
                    id
                    option
                  }
                  answers(associations: $associations) {
                    groupingId
                    id
                    question {
                      label
                    }
                    answer
                    answerOptions {
                      id
                      type
                    }
                  }
                  conditionsRequiredToRenderThisQuestion(associations: $associations) {
                    id
                    value
                    scopeId
                    isParentCondition
                    parentConditions {
                      id
                      scopeId
                    }
                    formula {
                      question {
                        id
                        label
                      }
                      operation
                      expect
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const GetFormByNamePreviewDocument = gql`
    query getFormByNamePreview($query: FormQueryInput, $associations: String) {
  Form(formQuery: $query) {
    id
    name
    title
    formSubmissions(associations: $associations) {
      id
      version
      status
      createdAt
      form {
        id
        name
      }
    }
    content {
      __typename
      ... on Question {
        id
        question
        label
        tip
        inputType
        validationRules
        createdAt
        updatedAt
        maxCharacters
        questionOptions {
          id
          option
        }
        conditionsRequiredToRenderThisQuestion(associations: $associations) {
          id
          value
          scopeId
          isParentCondition
          parentConditions {
            id
            scopeId
          }
          formula {
            question {
              id
              label
            }
            operation
            expect
          }
        }
        repeaterForm {
          id
          name
          title
          content {
            __typename
            ... on Question {
              id
              question
              label
              tip
              inputType
              validationRules
              createdAt
              updatedAt
              maxCharacters
              questionOptions {
                id
                option
              }
              conditionsRequiredToRenderThisQuestion(associations: $associations) {
                id
                value
                scopeId
                isParentCondition
                parentConditions {
                  id
                  scopeId
                }
                formula {
                  question {
                    id
                    label
                  }
                  operation
                  expect
                }
              }
            }
            ... on QuestionGrouping {
              id
              title
              subtitle
              questions {
                id
                question
                label
                tip
                inputType
                validationRules
                createdAt
                updatedAt
                maxCharacters
                questionOptions {
                  id
                  option
                }
                conditionsRequiredToRenderThisQuestion(associations: $associations) {
                  id
                  value
                  scopeId
                  isParentCondition
                  parentConditions {
                    id
                    scopeId
                  }
                  formula {
                    question {
                      id
                      label
                    }
                    operation
                    expect
                  }
                }
              }
            }
          }
        }
      }
      ... on QuestionGrouping {
        id
        title
        subtitle
        questions {
          id
          question
          label
          tip
          inputType
          validationRules
          createdAt
          updatedAt
          maxCharacters
          questionOptions {
            id
            option
          }
          conditionsRequiredToRenderThisQuestion(associations: $associations) {
            id
            value
            scopeId
            isParentCondition
            parentConditions {
              id
              scopeId
            }
            formula {
              question {
                id
                label
              }
              operation
              expect
            }
          }
          repeaterForm {
            id
            name
            title
            content {
              __typename
              ... on Question {
                id
                question
                label
                tip
                inputType
                validationRules
                createdAt
                updatedAt
                maxCharacters
                questionOptions {
                  id
                  option
                }
                conditionsRequiredToRenderThisQuestion(associations: $associations) {
                  id
                  value
                  scopeId
                  isParentCondition
                  parentConditions {
                    id
                    scopeId
                  }
                  formula {
                    question {
                      id
                      label
                    }
                    operation
                    expect
                  }
                }
              }
              ... on QuestionGrouping {
                id
                title
                subtitle
                questions {
                  id
                  question
                  label
                  tip
                  inputType
                  validationRules
                  createdAt
                  updatedAt
                  maxCharacters
                  questionOptions {
                    id
                    option
                  }
                  conditionsRequiredToRenderThisQuestion(associations: $associations) {
                    id
                    value
                    scopeId
                    isParentCondition
                    parentConditions {
                      id
                      scopeId
                    }
                    formula {
                      question {
                        id
                        label
                      }
                      operation
                      expect
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const DeleteFormDocument = gql`
    mutation deleteForm($id: Int!) {
  deleteForm(id: $id)
}
    `;
export const UpdateFormDocument = gql`
    mutation updateForm($id: Int!, $data: FormUpdateData!) {
  updateForm(id: $id, data: $data) {
    accountId
    id
    name
  }
}
    `;
export const HandleFormBuilderDocument = gql`
    mutation handleFormBuilder($action: HandleFormBuilderAction!, $formId: Int, $groupId: Int, $questionId: Int, $data: HandleFormBuilderData) {
  handleFormBuilder(
    action: $action
    formId: $formId
    groupId: $groupId
    questionId: $questionId
    data: $data
  )
}
    `;
export const SubmitFormDocument = gql`
    mutation submitForm($input: submitFormMutationInput!, $targetAccountId: Int, $args: SubmitFormMutationArgs) {
  submitForm(
    formSubmission: $input
    targetAccountId: $targetAccountId
    args: $args
  ) {
    id
    createdAt
    status
    version
  }
}
    `;
export const SubmitLeadsFormDocument = gql`
    mutation submitLeadsForm($input: submitFormMutationInput!, $leadEmbedId: String!, $recaptchaToken: String!) {
  submitLeadsForm(
    formSubmission: $input
    leadEmbedId: $leadEmbedId
    recaptchaToken: $recaptchaToken
  )
}
    `;
export const UpdateMostRecentFormDraftDocument = gql`
    mutation updateMostRecentFormDraft($input: submitFormMutationInput!, $accountId: Int) {
  updateMostRecentFormDraft(formSubmission: $input, accountId: $accountId) {
    id
    createdAt
    status
    version
  }
}
    `;
export const FormSubmissionsDocument = gql`
    query FormSubmissions($action: FormSubmissionGetAction, $data: FormSubmissionGetData) {
  FormSubmissions(action: $action, data: $data) {
    id
    group {
      id
    }
  }
}
    `;
export const GetInvitationDocument = gql`
    query getInvitation($slug: String, $email: String, $status: String) {
  Invitation(slug: $slug, email: $email, status: $status) {
    id
    slug
    email
    status
    expires
    isExpired
    accountType
  }
}
    `;
export const GetInvitationsDocument = gql`
    query getInvitations($status: String, $email: String, $targetAccountId: Int) {
  Invitations(status: $status, email: $email, targetAccountId: $targetAccountId) {
    id
    slug
    email
    status
    expires
    createdAt
    isExpired
    targetAccountId
    meta
    accountType
  }
}
    `;
export const SendInvitationDocument = gql`
    mutation sendInvitation($email: String, $accountType: String, $targetAccountId: Int, $resendSlug: String, $permissionGroup: String, $meta: JSON) {
  sendInvitation(
    email: $email
    accountType: $accountType
    targetAccountId: $targetAccountId
    resendSlug: $resendSlug
    permissionGroup: $permissionGroup
    meta: $meta
  ) {
    id
    slug
    email
    status
    meta
  }
}
    `;
export const CompleteInvitationDocument = gql`
    mutation completeInvitation($email: String, $slug: String, $password: String) {
  completeInvitation(email: $email, slug: $slug, password: $password) {
    id
    firstName
    lastName
    email
  }
}
    `;
export const UpdateInvitationDocument = gql`
    mutation updateInvitation($id: Int!, $data: InvitationUpdateData!) {
  updateInvitation(id: $id, data: $data) {
    id
    slug
    email
    status
    expires
    createdAt
    updatedAt
  }
}
    `;
export const DeleteInvitationDocument = gql`
    mutation deleteInvitation($id: Int!) {
  deleteInvitation(id: $id)
}
    `;
export const LawyerAssociationsDocument = gql`
    query LawyerAssociations($cursor: Int, $searchTerm: String, $sortBy: QuerySortBy, $filters: LawyerAssociationsFilters) {
  LawyerAssociations(
    cursor: $cursor
    searchTerm: $searchTerm
    sortBy: $sortBy
    filters: $filters
  ) {
    data {
      id
      key
      createdAt
      meta
      associationAccount {
        id
        name
        active
        primaryUser {
          id
          email
          firstName
          lastName
          active
        }
        accountType {
          name
        }
        ownerApplications {
          createdAt
          key
        }
        companyCompanyTalents {
          id
          key
          role {
            title
            key
          }
          talentAccount {
            primaryUser {
              firstName
              lastName
              email
            }
          }
        }
        roles {
          key
          title
        }
      }
      lawyerAccount {
        id
        name
      }
    }
    cursorId
    hasMore
    count
  }
}
    `;
export const LawyerAssociationDocument = gql`
    query LawyerAssociation($key: String, $accountId: Int, $email: String, $id: Int) {
  LawyerAssociation(key: $key, accountId: $accountId, email: $email, id: $id) {
    id
    key
    contactInfo
    meta
    lawyerAccount {
      id
      name
      primaryUser {
        firstName
        lastName
      }
      accountPermissionGroups {
        permissionGroup {
          name
        }
      }
    }
    associationAccount {
      id
      name
      active
      accountType {
        name
      }
      primaryUser {
        active
        id
        email
        firstName
        lastName
      }
      ownerApplications {
        key
        createdAt
        statuses {
          type
          value
          updatedAt
        }
        talent {
          talentAccount {
            primaryUser {
              firstName
              lastName
            }
          }
        }
      }
      companyCompanyTalents {
        id
        key
        firstName
        lastName
        email
        role {
          title
          key
        }
        talentAccount {
          primaryUser {
            firstName
            lastName
            email
          }
          persons {
            firstName
            lastName
            type
          }
        }
      }
      roles {
        key
        title
        companyTalents {
          firstName
          lastName
          email
          talentAccount {
            primaryUser {
              firstName
              lastName
            }
          }
        }
      }
    }
    assignedUsers {
      id
      firstName
      lastName
      email
      active
    }
    welcomeMessage {
      fromName
      imageUrl
      message
      enabled
    }
  }
}
    `;
export const LawyerAssociationIncludesDocument = gql`
    query LawyerAssociationIncludes($accountId: Int, $id: Int, $key: String, $email: String, $deleted: Boolean, $includeAssignedUsers: Boolean!, $includeAssociationAccount: Boolean!, $includeLawyerAccount: Boolean!) {
  LawyerAssociation(
    accountId: $accountId
    id: $id
    key: $key
    email: $email
    deleted: $deleted
  ) {
    id
    key
    meta
    assignedUsers @include(if: $includeAssignedUsers) {
      id
      firstName
      lastName
      email
      active
    }
    associationAccount @include(if: $includeAssociationAccount) {
      id
      name
      active
      accountType {
        name
      }
      primaryUser {
        active
        id
        email
        firstName
        lastName
      }
    }
    lawyerAccount @include(if: $includeLawyerAccount) {
      id
      name
      primaryUser {
        firstName
        lastName
      }
    }
  }
}
    `;
export const LawyerAssociationAssignedLawyersDocument = gql`
    query LawyerAssociationAssignedLawyers($key: String, $accountId: Int) {
  LawyerAssociation(key: $key, accountId: $accountId) {
    id
    key
    assignedUsers {
      id
      firstName
      lastName
      email
      active
    }
  }
}
    `;
export const DeleteLawyerAssociationDocument = gql`
    mutation deleteLawyerAssociation($id: Int!) {
  deleteLawyerAssociation(id: $id)
}
    `;
export const CreateLawyerAssociationDocument = gql`
    mutation createLawyerAssociation($firstName: String, $lastName: String, $email: String!) {
  createLawyerAssociation(
    firstName: $firstName
    lastName: $lastName
    email: $email
  ) {
    id
    key
  }
}
    `;
export const HandleLawyerAssociationDocument = gql`
    mutation handleLawyerAssociation($action: HandleLawyerAssociationAction!, $lawyerAssociationId: Int!, $data: HandleLawyerAssociationData) {
  handleLawyerAssociation(
    action: $action
    lawyerAssociationId: $lawyerAssociationId
    data: $data
  )
}
    `;
export const GetLeadEmbedDocument = gql`
    query getLeadEmbed($embedId: String!) {
  getLeadEmbed(embedId: $embedId)
}
    `;
export const LeadsDocument = gql`
    query Leads($cursor: Int, $searchTerm: String, $sortBy: QuerySortBy) {
  Leads(cursor: $cursor, searchTerm: $searchTerm, sortBy: $sortBy) {
    count
    cursorId
    hasMore
    data {
      firstName
      id
      key
      lastName
      email
      updatedAt
      createdAt
    }
  }
}
    `;
export const LeadDocument = gql`
    query Lead($key: String!) {
  Lead(key: $key) {
    firstName
    id
    key
    lastName
    email
    updatedAt
    createdAt
    meta
    accountEmbed {
      form {
        name
      }
    }
  }
}
    `;
export const HandleLeadDocument = gql`
    mutation handleLead($action: HandleLeadAction!, $leadId: Int!, $data: HandleLeadData) {
  handleLead(action: $action, leadId: $leadId, data: $data)
}
    `;
export const MetaDocument = gql`
    query Meta($key: String!) {
  Meta(key: $key) {
    id
    key
    value
  }
}
    `;
export const UpdateNoteDocument = gql`
    mutation updateNote($id: Int!, $content: String!, $mentionedUserIds: [Int!]) {
  updateNote(id: $id, content: $content, mentionedUserIds: $mentionedUserIds) {
    id
    content
    ownedBy {
      firstName
      lastName
      email
    }
    createdAt
    updatedAt
  }
}
    `;
export const DeleteNoteDocument = gql`
    mutation deleteNote($id: Int!) {
  deleteNote(id: $id)
}
    `;
export const CreateApplicationDocumentNoteDocument = gql`
    mutation createApplicationDocumentNote($applicationDocumentId: Int!, $content: String!, $mentionedUserIds: [Int!], $applicationId: Int, $accountTaskId: Int) {
  createNote(
    applicationDocumentId: $applicationDocumentId
    content: $content
    mentionedUserIds: $mentionedUserIds
    applicationId: $applicationId
    accountTaskId: $accountTaskId
  ) {
    id
    content
    ownedBy {
      firstName
      lastName
      email
    }
    createdAt
    updatedAt
  }
}
    `;
export const CreateAccountDocumentNoteDocument = gql`
    mutation createAccountDocumentNote($accountDocumentId: Int!, $content: String!, $mentionedUserIds: [Int!]) {
  createNote(
    accountDocumentId: $accountDocumentId
    content: $content
    mentionedUserIds: $mentionedUserIds
  ) {
    id
    content
    ownedBy {
      firstName
      lastName
      email
    }
    createdAt
    updatedAt
  }
}
    `;
export const CreateAccountTaskNoteDocument = gql`
    mutation createAccountTaskNote($accountTaskId: Int!, $content: String!, $mentionedUserIds: [Int!]) {
  createNote(
    accountTaskId: $accountTaskId
    content: $content
    mentionedUserIds: $mentionedUserIds
  ) {
    id
    content
    ownedBy {
      firstName
      lastName
      email
    }
    createdAt
    updatedAt
  }
}
    `;
export const CreateLawyerAssociationNoteDocument = gql`
    mutation createLawyerAssociationNote($lawyerAssociationId: Int!, $content: String!, $mentionedUserIds: [Int!]) {
  createNote(
    lawyerAssociationId: $lawyerAssociationId
    content: $content
    mentionedUserIds: $mentionedUserIds
  ) {
    id
    content
    ownedBy {
      firstName
      lastName
      email
    }
    createdAt
    updatedAt
  }
}
    `;
export const CreateApplicationNoteDocument = gql`
    mutation createApplicationNote($applicationId: Int!, $content: String!, $mentionedUserIds: [Int!]) {
  createNote(
    applicationId: $applicationId
    content: $content
    mentionedUserIds: $mentionedUserIds
  ) {
    id
    content
    ownedBy {
      firstName
      lastName
      email
    }
    createdAt
    updatedAt
  }
}
    `;
export const CreateCompanyTalentNoteDocument = gql`
    mutation createCompanyTalentNote($companyTalentId: Int!, $content: String!, $mentionedUserIds: [Int!]) {
  createNote(
    companyTalentId: $companyTalentId
    content: $content
    mentionedUserIds: $mentionedUserIds
  ) {
    id
    content
    ownedBy {
      firstName
      lastName
      email
    }
    createdAt
    updatedAt
  }
}
    `;
export const CreateCompanyComplianceNoteDocument = gql`
    mutation createCompanyComplianceNote($companyComplianceId: Int!, $content: String!) {
  createNote(companyComplianceId: $companyComplianceId, content: $content) {
    id
    content
    ownedBy {
      firstName
      lastName
      email
    }
    createdAt
    updatedAt
  }
}
    `;
export const GetAllNotesDocument = gql`
    query getAllNotes {
  Notes {
    id
    content
    ownedBy {
      firstName
      lastName
      email
    }
    createdAt
    updatedAt
  }
}
    `;
export const GetAllByCompanyComplianceDocument = gql`
    query getAllByCompanyCompliance($companyComplianceId: Int!) {
  Notes(companyComplianceId: $companyComplianceId) {
    id
    content
    ownedById
    ownedBy {
      firstName
      lastName
      email
    }
    createdAt
    updatedAt
  }
}
    `;
export const GetAllByApplicationDocument = gql`
    query getAllByApplication($applicationId: Int!) {
  Notes(applicationId: $applicationId) {
    id
    content
    ownedById
    ownedBy {
      firstName
      lastName
      email
    }
    createdAt
    updatedAt
  }
}
    `;
export const GetAllByApplicationDocumentDocument = gql`
    query getAllByApplicationDocument($applicationDocumentId: Int!) {
  Notes(applicationDocumentId: $applicationDocumentId) {
    id
    content
    ownedById
    ownedBy {
      firstName
      lastName
      email
    }
    createdAt
    updatedAt
  }
}
    `;
export const GetAllByAccountDocumentDocument = gql`
    query getAllByAccountDocument($accountDocumentId: Int!) {
  Notes(accountDocumentId: $accountDocumentId) {
    id
    content
    ownedById
    ownedBy {
      firstName
      lastName
      email
    }
    createdAt
    updatedAt
  }
}
    `;
export const GetAllByAccountTaskDocument = gql`
    query getAllByAccountTask($accountTaskId: Int!) {
  Notes(accountTaskId: $accountTaskId) {
    id
    content
    ownedById
    ownedBy {
      firstName
      lastName
      email
    }
    createdAt
    updatedAt
  }
}
    `;
export const GetAllByLawyerAssociationDocument = gql`
    query getAllByLawyerAssociation($lawyerAssociationId: Int!) {
  Notes(lawyerAssociationId: $lawyerAssociationId) {
    id
    content
    ownedById
    ownedBy {
      firstName
      lastName
      email
    }
    createdAt
    updatedAt
  }
}
    `;
export const GetAllByCompanyTalentDocument = gql`
    query getAllByCompanyTalent($companyTalentId: Int!) {
  Notes(companyTalentId: $companyTalentId) {
    id
    content
    ownedById
    ownedBy {
      firstName
      lastName
      email
    }
    createdAt
    updatedAt
  }
}
    `;
export const NotesListDocument = gql`
    query NotesList($cursor: Int, $searchTerm: String, $sortBy: QuerySortBy, $filters: NotesListFilters) {
  NotesList(
    cursor: $cursor
    searchTerm: $searchTerm
    sortBy: $sortBy
    filters: $filters
  ) {
    count
    cursorId
    hasMore
    data {
      content
      createdAt
      id
      noteAssociations {
        accountTaskId
        applicationId
        companyTalentId
        applicationDocumentId
        accountTask {
          key
        }
        application {
          key
        }
        companyTalent {
          key
        }
      }
    }
  }
}
    `;
export const NotificationsDocument = gql`
    query Notifications($status: NotificationStatus, $cursor: Int, $applicationId: Int) {
  Notifications(status: $status, cursor: $cursor, applicationId: $applicationId) {
    data {
      id
      body
      status
      title
      url
      createdAt
      updatedAt
    }
    cursorId
    hasMore
    count
  }
}
    `;
export const UpdateNotificationDocument = gql`
    mutation updateNotification($ids: [Int!]!, $status: String!) {
  updateNotification(ids: $ids, status: $status)
}
    `;
export const PaymentIntentsDocument = gql`
    query PaymentIntents {
  PaymentIntents {
    id
    stripeId
    stripeProductId
    stripePromoCode
    stripeCouponCode
    tax
    stripePaymentIntent {
      id
      clientSecret
      amount
      productName
      productAmount
      receiptUrl
      status
      couponAmount
      couponIsPercentage
    }
    status
    state
    createdAt
    updatedAt
  }
}
    `;
export const PaymentIntentDocument = gql`
    query PaymentIntent($id: Int!) {
  PaymentIntent(id: $id) {
    id
    stripeId
    stripeProductId
    stripePromoCode
    stripeCouponCode
    tax
    stripePaymentIntent {
      id
      clientSecret
      amount
      productName
      productAmount
      receiptUrl
      status
      couponAmount
      couponIsPercentage
    }
    status
    state
    createdAt
    updatedAt
  }
}
    `;
export const CreatePaymentIntentDocument = gql`
    mutation createPaymentIntent($stripeProductId: String!) {
  createPaymentIntent(stripeProductId: $stripeProductId) {
    id
    stripeId
    stripeProductId
    stripePromoCode
    stripeCouponCode
    tax
    stripePaymentIntent {
      id
      clientSecret
      amount
      productName
      productAmount
      receiptUrl
      status
      couponAmount
      couponIsPercentage
    }
    status
    state
    createdAt
    updatedAt
  }
}
    `;
export const UpdatePaymentIntentDocument = gql`
    mutation updatePaymentIntent($id: Int!, $coupon: String, $province: String) {
  updatePaymentIntent(id: $id, coupon: $coupon, province: $province) {
    id
    stripeId
    stripeProductId
    stripePromoCode
    stripeCouponCode
    tax
    stripePaymentIntent {
      id
      clientSecret
      amount
      productName
      productAmount
      receiptUrl
      status
      couponAmount
      couponIsPercentage
    }
    status
    state
    createdAt
    updatedAt
  }
}
    `;
export const PermissionsDocument = gql`
    query Permissions($type: PermissionType) {
  Permissions(type: $type) {
    id
    resource
    operation
    description
    category
    createdAt
    updatedAt
  }
}
    `;
export const PermissionGroupsDocument = gql`
    query PermissionGroups {
  PermissionGroups {
    id
    name
    permissions {
      id
      resource
      operation
      description
      category
    }
    description
  }
}
    `;
export const PermitDocument = gql`
    query Permit($accountId: Int, $type: PermitType, $applicationId: Int, $companyTalentId: Int) {
  Permit(
    accountId: $accountId
    type: $type
    applicationId: $applicationId
    companyTalentId: $companyTalentId
  ) {
    id
    type
    approvedOn
    expiresOn
    createdAt
    updatedAt
    applicationId
    account {
      id
      accountType {
        name
      }
    }
    companyTalent {
      id
      key
      companyAccountId
      talentAccountId
      talentAccount {
        id
        users {
          firstName
          lastName
        }
      }
    }
  }
}
    `;
export const PermitsDocument = gql`
    query Permits($type: PermitType, $isExpiring: Boolean) {
  Permits(type: $type, isExpiring: $isExpiring) {
    id
    companyTalent {
      id
      key
      companyAccountId
      talentAccountId
      talentAccount {
        id
        users {
          firstName
          lastName
        }
      }
      invitation {
        id
        slug
        email
        status
        expires
      }
    }
    account {
      id
      name
      users {
        id
        email
      }
    }
    approvedOn
    expiresOn
    createdAt
    updatedAt
    type
  }
}
    `;
export const CreatePermitDocument = gql`
    mutation createPermit($data: PermitCreateData!) {
  createPermit(data: $data) {
    id
    type
    createdAt
    updatedAt
  }
}
    `;
export const UpdatePermitDocument = gql`
    mutation updatePermit($id: Int!, $approvedOn: Date!, $expiresOn: Date!) {
  updatePermit(id: $id, approvedOn: $approvedOn, expiresOn: $expiresOn) {
    id
    type
    createdAt
    updatedAt
  }
}
    `;
export const GetPersonDocument = gql`
    query getPerson($id: Int, $taskLabels: [String!], $taskStatus: AccountTaskStatus, $type: PersonType, $accountId: Int) {
  Person(
    id: $id
    taskLabels: $taskLabels
    taskStatus: $taskStatus
    type: $type
    accountId: $accountId
  ) {
    id
    firstName
    lastName
    email
    type
    accountId
    accountTasks {
      id
      key
      createdAt
      updatedAt
      status
      task {
        id
        action
        label
        title
        taskAssociation {
          id
          forms {
            id
            name
            title
          }
          documentTypes {
            name
            id
          }
          signatureRequestTypes {
            id
            name
            signatureRequest {
              id
              signatureRequestId
              signatureRequestSigners {
                signatureId
                status
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const GetPersonIncludesDocument = gql`
    query getPersonIncludes($id: Int, $type: PersonType, $accountId: Int) {
  Person(id: $id, type: $type, accountId: $accountId) {
    id
    firstName
    lastName
    email
    type
    accountId
  }
}
    `;
export const UpdatePersonDocument = gql`
    mutation updatePerson($id: Int!, $data: UpdatePersonData) {
  updatePerson(id: $id, data: $data) {
    id
    firstName
    lastName
    email
    type
  }
}
    `;
export const PersonsDocument = gql`
    query Persons($accountId: Int, $applicationId: Int) {
  Persons(accountId: $accountId, applicationId: $applicationId) {
    id
    firstName
    lastName
    email
    type
    meta
    age
    applications {
      id
    }
  }
}
    `;
export const PersonsIncludesDocument = gql`
    query PersonsIncludes($accountId: Int, $applicationId: Int, $includeApplications: Boolean!) {
  Persons(accountId: $accountId, applicationId: $applicationId) {
    id
    firstName
    lastName
    email
    type
    meta
    age
    applications @include(if: $includeApplications) {
      id
    }
  }
}
    `;
export const CreatePersonDocument = gql`
    mutation createPerson($type: PersonType!, $firstName: String!, $lastName: String!, $email: String, $accountId: Int, $meta: JSON) {
  createPerson(
    type: $type
    firstName: $firstName
    lastName: $lastName
    email: $email
    accountId: $accountId
    meta: $meta
  ) {
    id
    firstName
    email
    lastName
    type
  }
}
    `;
export const HandlePublicDocument = gql`
    mutation handlePublic($action: HandlePublicAction!, $data: HandlePublicData) {
  handlePublic(action: $action, data: $data)
}
    `;
export const QuestionsDocument = gql`
    query Questions($cursor: Int, $searchTerm: String, $sortBy: QuerySortBy, $sortByColumn: String, $options: QuestionsOptions) {
  Questions(
    cursor: $cursor
    searchTerm: $searchTerm
    sortBy: $sortBy
    sortByColumn: $sortByColumn
    options: $options
  ) {
    count
    cursorId
    hasMore
    data {
      id
      inputType
      label
      question
      tip
      validationRules
      maxCharacters
    }
  }
}
    `;
export const QuestionDocument = gql`
    query Question($label: String!) {
  Question(label: $label) {
    id
    label
    question
    tip
    createdAt
    updatedAt
  }
}
    `;
export const QuestionIncludesDocument = gql`
    query QuestionIncludes($label: String!, $includeOptions: Boolean!) {
  Question(label: $label) {
    id
    label
    question
    tip
    createdAt
    updatedAt
    inputType
    questionOptions @include(if: $includeOptions) {
      option
    }
  }
}
    `;
export const UpsertQuestionDocument = gql`
    mutation upsertQuestion($id: Int, $question: String, $type: QuestionInputType, $required: Boolean, $tip: String, $options: CreateQuestionOptions, $repeaterQuestionIds: [Int!], $label: String, $createExplainQuestion: Boolean) {
  upsertQuestion(
    id: $id
    question: $question
    type: $type
    required: $required
    tip: $tip
    options: $options
    repeaterQuestionIds: $repeaterQuestionIds
    label: $label
    createExplainQuestion: $createExplainQuestion
  ) {
    id
    label
    question
    inputType
    tip
    validationRules
  }
}
    `;
export const CreateQuestionGroupingDocument = gql`
    mutation createQuestionGrouping($title: String!, $description: String, $formId: Int!) {
  createQuestionGrouping(
    title: $title
    description: $description
    formId: $formId
  ) {
    id
    title
    subtitle
  }
}
    `;
export const QuestionGroupingsDocument = gql`
    query QuestionGroupings($cursor: Int, $searchTerm: String, $sortBy: QuerySortBy, $options: QuestionGroupingsOptions) {
  QuestionGroupings(
    cursor: $cursor
    searchTerm: $searchTerm
    sortBy: $sortBy
    options: $options
  ) {
    count
    cursorId
    hasMore
    data {
      id
      uniqueKey
      title
    }
  }
}
    `;
export const QuestionGroupingDocument = gql`
    query QuestionGrouping($id: Int, $uniqueKey: String) {
  QuestionGrouping(id: $id, uniqueKey: $uniqueKey) {
    id
    ordinal
    title
    subtitle
    uniqueKey
    accountId
    forms {
      id
      title
      name
    }
    questions {
      id
      inputType
      label
      ordinal
      question
      tip
      validationRules
      accountId
      maxCharacters
      conditionsRequiredToRenderThisQuestion {
        formula {
          expect
          operation
          question {
            id
            label
          }
        }
        id
        value
        isParentCondition
        parentConditions {
          id
          scopeId
        }
        scopeId
      }
      repeaterForm {
        id
        name
        title
        questions {
          id
          inputType
          label
          ordinal
          question
          tip
          validationRules
          accountId
          maxCharacters
        }
        content {
          __typename
          ... on Question {
            id
            question
            label
            tip
            inputType
            validationRules
            createdAt
            updatedAt
            maxCharacters
            questionOptions {
              id
              option
            }
            conditionsRequiredToRenderThisQuestion {
              id
              value
              scopeId
              isParentCondition
              parentConditions {
                id
                scopeId
              }
              formula {
                question {
                  id
                  label
                }
                operation
                expect
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const UpdateQuestionGroupingDocument = gql`
    mutation updateQuestionGrouping($id: Int!, $data: UpdateQuestionGroupingData) {
  updateQuestionGrouping(id: $id, data: $data)
}
    `;
export const QuestionOptionsDocument = gql`
    query QuestionOptions($cursor: Int, $searchTerm: String, $sortBy: QuerySortBy, $length: Int, $options: QuestionOptionsOptions) {
  QuestionOptions(
    cursor: $cursor
    searchTerm: $searchTerm
    sortBy: $sortBy
    length: $length
    options: $options
  ) {
    count
    cursorId
    hasMore
    data {
      id
      option
    }
  }
}
    `;
export const GetReferrerCodesDocument = gql`
    query getReferrerCodes {
  Referrer {
    referrerCodes {
      id
      code
      createdAt
      updatedAt
    }
  }
}
    `;
export const ReferrerDocument = gql`
    query Referrer {
  Referrer {
    id
    account {
      id
    }
    invites {
      id
      invitedByReferrerId
    }
    referrerConversions {
      id
      paymentIntent {
        id
        customer {
          account {
            id
            primaryUser {
              email
            }
          }
        }
      }
    }
    referrerCodes {
      id
      code
    }
    createdAt
    updatedAt
  }
}
    `;
export const CreateReferrerCodeDocument = gql`
    mutation createReferrerCode($code: String!) {
  createReferrerCode(code: $code) {
    id
    code
  }
}
    `;
export const UpsertReminderDocument = gql`
    mutation upsertReminder($data: ReminderUpsertData!, $id: Int) {
  upsertReminder(data: $data, id: $id)
}
    `;
export const ReminderDocument = gql`
    query Reminder($reminderId: Int, $data: ReminderData) {
  Reminder(id: $reminderId, data: $data) {
    id
    active
    cadenceDaysAfter
    cadenceDaysBefore
    type
    accountId
    createdAt
    updatedAt
  }
}
    `;
export const RepresentativesDocument = gql`
    query Representatives($cursor: Int, $searchTerm: String, $sortBy: QuerySortBy, $filters: RepresentativesFilters) {
  Representatives(
    cursor: $cursor
    searchTerm: $searchTerm
    sortBy: $sortBy
    filters: $filters
  ) {
    count
    cursorId
    hasMore
    data {
      accountId
      createdAt
      deleted
      active
      documentId
      id
      key
      name
      signatureDocumentId
      updatedAt
      meta
    }
  }
}
    `;
export const RepresentativeDocument = gql`
    query Representative($key: String!) {
  Representative(key: $key) {
    accountId
    createdAt
    deleted
    active
    documentId
    id
    key
    name
    updatedAt
    signatureDocumentId
    signatureDocumentUrl
    meta
  }
}
    `;
export const RepresentativeWithApplicationsDocument = gql`
    query RepresentativeWithApplications($key: String!) {
  Representative(key: $key) {
    accountId
    createdAt
    deleted
    active
    documentId
    id
    key
    name
    updatedAt
    signatureDocumentId
    signatureDocumentUrl
    meta
    applications {
      key
      id
    }
  }
}
    `;
export const CreateRepresentativeDocument = gql`
    mutation createRepresentative($name: String!) {
  createRepresentative(name: $name) {
    accountId
    createdAt
    deleted
    active
    documentId
    id
    key
    name
    signatureDocumentId
    updatedAt
  }
}
    `;
export const DeleteRepresentativeDocument = gql`
    mutation deleteRepresentative($id: Int!) {
  deleteRepresentative(id: $id)
}
    `;
export const GetRoleByIdDocument = gql`
    query getRoleById($id: Int, $key: String, $applicationId: Int) {
  Role(id: $id, key: $key, applicationId: $applicationId) {
    id
    key
    title
    updatedAt
    createdAt
    accountId
    account {
      id
    }
    applications {
      key
    }
    companyTalents {
      id
      key
      companyAccountId
      talentAccountId
      firstName
      lastName
      email
      talentAccount {
        id
        users {
          firstName
          lastName
          email
        }
      }
      invitation {
        id
        email
        status
      }
      statuses {
        id
        value
        talent {
          id
          role {
            id
            title
          }
        }
      }
      application(roleKey: $key) {
        statuses {
          id
          value
          type
          talent {
            id
            role {
              id
              title
            }
          }
        }
      }
    }
    feedback {
      content
      status
      updatedAt
      createdAt
      ownedBy {
        id
        firstName
      }
      accountTask {
        id
        account {
          id
        }
      }
    }
  }
}
    `;
export const GetRolesDocument = gql`
    query getRoles($accountId: Int) {
  Roles(accountId: $accountId) {
    id
    key
    title
    updatedAt
    createdAt
    applications {
      key
    }
    companyTalents {
      id
      firstName
      lastName
    }
  }
}
    `;
export const CreateRoleDocument = gql`
    mutation createRole($title: String!, $targetAccountId: Int) {
  createRole(title: $title, targetAccountId: $targetAccountId) {
    id
    key
    title
    updatedAt
    createdAt
  }
}
    `;
export const DeleteRoleDocument = gql`
    mutation deleteRole($id: Int!) {
  deleteRole(id: $id)
}
    `;
export const UpdateRoleDocument = gql`
    mutation updateRole($id: Int!, $data: RoleUpdateData!) {
  updateRole(id: $id, data: $data) {
    id
    key
    title
  }
}
    `;
export const DuplicateRoleDocument = gql`
    mutation duplicateRole($id: Int!, $title: String!) {
  duplicateRole(id: $id, title: $title) {
    id
    key
    title
    createdAt
    updatedAt
  }
}
    `;
export const SearchDocument = gql`
    query search($query: String!, $type: SearchType!) {
  search(query: $query, type: $type)
}
    `;
export const HandleSendGridDocument = gql`
    mutation handleSendGrid($action: SendGridAction!, $data: SendGridData) {
  handleSendGrid(action: $action, data: $data)
}
    `;
export const GetEmbeddedSignUrlDocument = gql`
    query getEmbeddedSignUrl($signatureRequestId: Int!) {
  getEmbeddedSignUrl(signatureRequestId: $signatureRequestId) {
    auditUrl
    status
    slug
    documentUrl
    completedAt
  }
}
    `;
export const GetHelloSignFilesDocument = gql`
    query getHelloSignFiles($signatureRequestId: String!) {
  getHelloSignFiles(signatureRequestId: $signatureRequestId)
}
    `;
export const HandleHelloSignWebhookDocument = gql`
    mutation handleHelloSignWebhook($event: HelloSignEvent) {
  handleHelloSignWebhook(event: $event)
}
    `;
export const HandleDocuSealWebhookDocument = gql`
    mutation handleDocuSealWebhook($data: JSON) {
  handleDocuSealWebhook(data: $data)
}
    `;
export const SignatureRequestSignerDocument = gql`
    query SignatureRequestSigner($signtaureRequestSigners: [SignatureRequestSignerData!]!) {
  SignatureRequestSigner(signtaureRequestSigners: $signtaureRequestSigners) {
    signatureId
    status
    createdAt
    updatedAt
    email
    signatureRequest {
      signatureRequestId
      type {
        id
        name
        title
        subject
        message
      }
    }
  }
}
    `;
export const StatusDocument = gql`
    query Status($id: Int, $applicationId: Int, $type: StatusType, $companyAccountId: Int, $companyTalentId: Int) {
  Status(
    id: $id
    applicationId: $applicationId
    type: $type
    companyAccountId: $companyAccountId
    companyTalentId: $companyTalentId
  ) {
    id
    type
    value
    history
    createdAt
    updatedAt
    requirements
    ownerAccountId
    documentsToSign
    application {
      id
      key
      type
      meta
      assignedUsers {
        firstName
        lastName
      }
      ownerAccount {
        name
        primaryUser {
          id
          email
          firstName
          lastName
        }
      }
      lawyerAccount {
        name
      }
    }
  }
}
    `;
export const StatusesDocument = gql`
    query Statuses($applicationId: Int!, $type: StatusType) {
  Statuses(applicationId: $applicationId, type: $type) {
    id
    type
    value
    history
    createdAt
    updatedAt
  }
}
    `;
export const UpdateStatusDocument = gql`
    mutation updateStatus($id: Int!, $data: StatusUpdateData!) {
  updateStatus(id: $id, data: $data) {
    id
    type
    value
    history
    createdAt
    updatedAt
  }
}
    `;
export const CreateStorageFileDocument = gql`
    mutation createStorageFile($type: StorageFileType!, $file: Upload!) {
  createStorageFile(type: $type, file: $file) {
    accountId
    createdAt
    id
    key
    type
  }
}
    `;
export const GetStorageFileUrlDocument = gql`
    query getStorageFileUrl($type: StorageFileType!) {
  getStorageFileUrl(type: $type) {
    storageFile {
      accountId
      id
      key
    }
    url
  }
}
    `;
export const DeleteStorageFileDocument = gql`
    mutation deleteStorageFile($id: Int!) {
  deleteStorageFile(id: $id)
}
    `;
export const GetStripeCustomerDocument = gql`
    query getStripeCustomer {
  getStripeCustomer
}
    `;
export const GetStripeCheckoutUrlDocument = gql`
    query getStripeCheckoutUrl($priceId: String!, $quantity: Int) {
  getStripeCheckoutUrl(priceId: $priceId, quantity: $quantity)
}
    `;
export const GetStripePortalUrlDocument = gql`
    query getStripePortalUrl {
  getStripePortalUrl
}
    `;
export const HandleStripeWebhookDocument = gql`
    mutation handleStripeWebhook($event: JSON) {
  handleStripeWebhook(event: $event)
}
    `;
export const AdminImpersonateUserDocument = gql`
    mutation adminImpersonateUser($userId: Int!) {
  adminImpersonateUser(userId: $userId)
}
    `;
export const AdminDeleteDocument = gql`
    mutation adminDelete($action: AdminDeleteAction!, $data: AdminDeleteData) {
  adminDelete(action: $action, data: $data)
}
    `;
export const AdminUpdateDocument = gql`
    mutation adminUpdate($action: AdminUpdateAction!, $data: AdminUpdateData) {
  adminUpdate(action: $action, data: $data)
}
    `;
export const TagsDocument = gql`
    query Tags($group: [String]) {
  Tags(group: $group) {
    id
    value
  }
}
    `;
export const TasksDocument = gql`
    query Tasks($cursor: Int, $searchTerm: String, $sortBy: QuerySortBy, $filters: TasksFilters) {
  Tasks(
    cursor: $cursor
    searchTerm: $searchTerm
    sortBy: $sortBy
    filters: $filters
  ) {
    count
    cursorId
    data {
      action
      id
      label
      meta
      required
      title
    }
    hasMore
  }
}
    `;
export const TaskDocument = gql`
    query Task($label: String, $id: Int) {
  Task(label: $label, id: $id) {
    action
    id
    label
    meta
    required
    title
    accountId
    taskAssociation {
      id
      documentTypes {
        name
        id
        displayName
        title
        tip
        instructions
        required
        accountId
      }
    }
  }
}
    `;
export const TaskActionDocument = gql`
    query TaskAction($label: String, $accountTaskKey: String) {
  Task(label: $label, accountTaskKey: $accountTaskKey) {
    action
  }
}
    `;
export const UpsertTaskDocument = gql`
    mutation upsertTask($id: Int, $title: String!, $instructions: String!, $required: Boolean!, $documentTypeIds: [Int!], $documentsLinkedToApplication: Boolean) {
  upsertTask(
    id: $id
    title: $title
    instructions: $instructions
    required: $required
    documentTypeIds: $documentTypeIds
    documentsLinkedToApplication: $documentsLinkedToApplication
  ) {
    action
    id
    label
    meta
    required
    title
  }
}
    `;
export const TemplateDocumentDocument = gql`
    query TemplateDocument($key: String!) {
  TemplateDocument(key: $key) {
    accountId
    content
    createdAt
    description
    id
    key
    name
  }
}
    `;
export const TemplateDocumentsDocument = gql`
    query TemplateDocuments($cursor: Int, $searchTerm: String, $sortBy: QuerySortBy) {
  TemplateDocuments(cursor: $cursor, searchTerm: $searchTerm, sortBy: $sortBy) {
    count
    cursorId
    hasMore
    data {
      accountId
      content
      createdAt
      description
      id
      key
      name
    }
  }
}
    `;
export const CreateTemplateDocumentDocument = gql`
    mutation createTemplateDocument($name: String!, $description: String, $content: String) {
  createTemplateDocument(
    name: $name
    description: $description
    content: $content
  ) {
    accountId
    description
    id
    key
    name
  }
}
    `;
export const UpdateTemplateDocumentDocument = gql`
    mutation updateTemplateDocument($id: Int!, $data: TemplateDocumentUpdateData!) {
  updateTemplateDocument(id: $id, data: $data) {
    accountId
    content
    createdAt
    description
    id
    key
    name
  }
}
    `;
export const HandleTemplateDocumentDocument = gql`
    mutation handleTemplateDocument($id: Int!, $action: HandleTemplateDocumentAction!, $data: HandleTemplateDocumentData) {
  handleTemplateDocument(id: $id, action: $action, data: $data)
}
    `;
export const GetActiveUserDocument = gql`
    query getActiveUser {
  User {
    id
    firstName
    lastName
    email
    createdAt
    updatedAt
    permissions {
      resource
      operation
    }
    userPermissions {
      resource
      operation
    }
    account {
      name
      id
      viewed
      settings
      leadsEmbedId
      accountType {
        id
        name
      }
      accountSubType {
        id
        name
      }
      subscription {
        gateWithBilling
        isActive
      }
    }
    hasCompletedGettingStarted
    hasNewNotifications
    avatarKey
    isPrimaryUser
    permissionGroups
    productsPurchased
    hasLawyerAssociation
    viewed
    mfaActive
  }
}
    `;
export const UsersDocument = gql`
    query Users($targetAccountId: Int, $filters: UsersFilters) {
  Users(targetAccountId: $targetAccountId, filters: $filters) {
    id
    active
    firstName
    lastName
    email
    createdAt
    updatedAt
    isPrimaryUser
    account {
      id
      active
      name
      accountType {
        id
        name
      }
      accountSubType {
        id
        name
      }
    }
  }
}
    `;
export const UserIncludesDocument = gql`
    query UserIncludes($includesSubscription: Boolean!, $includesPermissions: Boolean!, $includesUserPermissions: Boolean!) {
  User {
    id
    firstName
    lastName
    email
    avatarKey
    isPrimaryUser
    viewed
    mfaActive
    active
    account {
      id
      active
      name
      viewed
      accountType {
        id
        name
      }
      subscription @include(if: $includesSubscription) {
        ...SubscriptionDetails
      }
    }
    permissions @include(if: $includesPermissions) {
      resource
      operation
    }
    userPermissions @include(if: $includesUserPermissions) {
      operation
      resource
    }
  }
}
    ${SubscriptionDetailsFragmentDoc}`;
export const UsersIncludesDocument = gql`
    query UsersIncludes($targetAccountId: Int, $filters: UsersFilters, $includeAccount: Boolean!) {
  Users(targetAccountId: $targetAccountId, filters: $filters) {
    id
    active
    firstName
    lastName
    email
    createdAt
    updatedAt
    account @include(if: $includeAccount) {
      id
      active
      name
      accountType {
        id
        name
      }
      accountSubType {
        id
        name
      }
    }
  }
}
    `;
export const PublicUserDocument = gql`
    query PublicUser($userId: Int!) {
  PublicUser(userId: $userId) {
    id
    avatarKey
    firstName
  }
}
    `;
export const UpdateUserDocument = gql`
    mutation updateUser($action: UpdateUserAction!, $targetUserId: Int, $data: UpdateUserData) {
  updateUser(action: $action, targetUserId: $targetUserId, data: $data)
}
    `;
export const UserExistsDocument = gql`
    query UserExists($email: String!) {
  UserExists(email: $email)
}
    `;
export const UserPermissionsDocument = gql`
    query UserPermissions($userId: Int!) {
  UserPermissions(userId: $userId) {
    id
    active
    createdAt
    updatedAt
    permission {
      id
      operation
      resource
      description
      title
    }
  }
}
    `;
export const UpdateUserPermissionDocument = gql`
    mutation updateUserPermission($permissionId: Int!, $userId: Int!, $active: Boolean!) {
  updateUserPermission(
    permissionId: $permissionId
    userId: $userId
    active: $active
  )
}
    `;
export const UpdateUsersAccessDocument = gql`
    mutation updateUsersAccess($userId: Int!, $applicationId: Int, $lawyerAssociationId: Int) {
  updateUsersAccess(
    userId: $userId
    applicationId: $applicationId
    lawyerAssociationId: $lawyerAssociationId
  ) {
    accountId
    applicationId
    createdAt
    id
    lawyerAssociationId
    updatedAt
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();
const AccountsDocumentString = print(AccountsDocument);
const AccountDocumentString = print(AccountDocument);
const AccountIncludesDocumentString = print(AccountIncludesDocument);
const AccountSubscriptionDocumentString = print(AccountSubscriptionDocument);
const UpdateAccountDocumentString = print(UpdateAccountDocument);
const PublicSignUpDocumentString = print(PublicSignUpDocument);
const AccountDocumentDocumentString = print(AccountDocumentDocument);
const AccountDocumentRetainerDocumentString = print(AccountDocumentRetainerDocument);
const AccountDocumentsDocumentString = print(AccountDocumentsDocument);
const GetUrlDownloadAccountDocumentDocumentString = print(GetUrlDownloadAccountDocumentDocument);
const UploadAccountDocumentDocumentString = print(UploadAccountDocumentDocument);
const UpdateAccountDocumentDocumentString = print(UpdateAccountDocumentDocument);
const HandleAccountEmbedDocumentString = print(HandleAccountEmbedDocument);
const AccountPermissionsDocumentString = print(AccountPermissionsDocument);
const UpdateAccountPermissionDocumentString = print(UpdateAccountPermissionDocument);
const AccountSettingDocumentString = print(AccountSettingDocument);
const UpdateAccountSettingsDocumentString = print(UpdateAccountSettingsDocument);
const AccountTagsDocumentString = print(AccountTagsDocument);
const UpdateAccountTagDocumentString = print(UpdateAccountTagDocument);
const DeleteAccountTagDocumentString = print(DeleteAccountTagDocument);
const GetAccountTaskDocumentString = print(GetAccountTaskDocument);
const GetAccountTaskIncludesDocumentString = print(GetAccountTaskIncludesDocument);
const GetAccountTasksDocumentString = print(GetAccountTasksDocument);
const GetAccountTasksIncludesDocumentString = print(GetAccountTasksIncludesDocument);
const GetAccountTasksOverviewDocumentString = print(GetAccountTasksOverviewDocument);
const UpdateAccountTasksDocumentString = print(UpdateAccountTasksDocument);
const CreateChangeRequestDocumentString = print(CreateChangeRequestDocument);
const UpdateChangeRequestDocumentString = print(UpdateChangeRequestDocument);
const HandleAccountTaskDocumentString = print(HandleAccountTaskDocument);
const DeleteAccountTaskDocumentString = print(DeleteAccountTaskDocument);
const CreateAccountTaskDocumentString = print(CreateAccountTaskDocument);
const FindManyInternalAccountTasksDocumentString = print(FindManyInternalAccountTasksDocument);
const AccountTasksListDocumentString = print(AccountTasksListDocument);
const ActivityLogsDocumentString = print(ActivityLogsDocument);
const GetAnswersDocumentString = print(GetAnswersDocument);
const UpdateAnswersDocumentString = print(UpdateAnswersDocument);
const GetAnswersAdminDocumentString = print(GetAnswersAdminDocument);
const GetApplicationDocumentString = print(GetApplicationDocument);
const GetApplicationIncludesDocumentString = print(GetApplicationIncludesDocument);
const GetApplicationWithTemplateDocumentString = print(GetApplicationWithTemplateDocument);
const GetApplicationBasicDocumentString = print(GetApplicationBasicDocument);
const GetApplicationForExportDocumentString = print(GetApplicationForExportDocument);
const GetApplicationsDocumentString = print(GetApplicationsDocument);
const GetApplicationsOverviewDocumentString = print(GetApplicationsOverviewDocument);
const GetApplicationsIncludesDocumentString = print(GetApplicationsIncludesDocument);
const CreateApplicationDocumentString = print(CreateApplicationDocument);
const UpdateApplicationDocumentString = print(UpdateApplicationDocument);
const HandleApplicationDocumentString = print(HandleApplicationDocument);
const DeleteApplicationDocumentString = print(DeleteApplicationDocument);
const ApplicationDocumentDocumentString = print(ApplicationDocumentDocument);
const ApplicationDocumentsDocumentString = print(ApplicationDocumentsDocument);
const GetUrlDocumentsDocumentString = print(GetUrlDocumentsDocument);
const GetUrlDownloadApplicationDocumentDocumentString = print(GetUrlDownloadApplicationDocumentDocument);
const UploadApplicationDocumentDocumentString = print(UploadApplicationDocumentDocument);
const GenerateApplicationDocumentsDocumentString = print(GenerateApplicationDocumentsDocument);
const SignApplicationDocumentsDocumentString = print(SignApplicationDocumentsDocument);
const UpdateApplicationDocumentsDocumentString = print(UpdateApplicationDocumentsDocument);
const GenerateApplicationDocumentDocumentString = print(GenerateApplicationDocumentDocument);
const CreateApplicationItemDocumentString = print(CreateApplicationItemDocument);
const DeleteApplicationItemDocumentString = print(DeleteApplicationItemDocument);
const UpdateApplicationItemDocumentString = print(UpdateApplicationItemDocument);
const ApplicationTemplatesDocumentString = print(ApplicationTemplatesDocument);
const ApplicationTemplateDocumentString = print(ApplicationTemplateDocument);
const CreateApplicationTemplateDocumentString = print(CreateApplicationTemplateDocument);
const UpdateApplicationTemplateDocumentString = print(UpdateApplicationTemplateDocument);
const DeleteApplicationTemplateDocumentString = print(DeleteApplicationTemplateDocument);
const CreateApplicationTemplateItemDocumentString = print(CreateApplicationTemplateItemDocument);
const DeleteApplicationTemplateItemDocumentString = print(DeleteApplicationTemplateItemDocument);
const UpdateApplicationTemplateItemDocumentString = print(UpdateApplicationTemplateItemDocument);
const HandleAuthDocumentString = print(HandleAuthDocument);
const DeleteCommentDocumentString = print(DeleteCommentDocument);
const UpdateCommentDocumentString = print(UpdateCommentDocument);
const CreateCommentDocumentString = print(CreateCommentDocument);
const GetCommentsDocumentString = print(GetCommentsDocument);
const GetCommentsIncludesDocumentString = print(GetCommentsIncludesDocument);
const CommentsListDocumentString = print(CommentsListDocument);
const CompanyComplianceDocumentString = print(CompanyComplianceDocument);
const UpsertCompanyComplianceDocumentString = print(UpsertCompanyComplianceDocument);
const GetCompanyTalentDocumentString = print(GetCompanyTalentDocument);
const GetCompanyTalentsDocumentString = print(GetCompanyTalentsDocument);
const CreateCompanyTalentDocumentString = print(CreateCompanyTalentDocument);
const UpdateCompanyTalentDocumentString = print(UpdateCompanyTalentDocument);
const GetCompliancesDocumentString = print(GetCompliancesDocument);
const CreateComplianceDocumentString = print(CreateComplianceDocument);
const UpdateComplianceDocumentString = print(UpdateComplianceDocument);
const UploadComplianceDocumentDocumentString = print(UploadComplianceDocumentDocument);
const DeleteComplianceDocumentsDocumentString = print(DeleteComplianceDocumentsDocument);
const GenerateContentDocumentString = print(GenerateContentDocument);
const ConversationDocumentString = print(ConversationDocument);
const ConversationsDocumentString = print(ConversationsDocument);
const CreateConversationDocumentString = print(CreateConversationDocument);
const CreateMessageDocumentString = print(CreateMessageDocument);
const UpdateMessageDocumentString = print(UpdateMessageDocument);
const UpdateConversationDocumentString = print(UpdateConversationDocument);
const DisableNotificationDocumentString = print(DisableNotificationDocument);
const GetUrlDocumentDocumentString = print(GetUrlDocumentDocument);
const DeleteDocumentDocumentString = print(DeleteDocumentDocument);
const DocumentTypeDocumentString = print(DocumentTypeDocument);
const DocumentTypesListDocumentString = print(DocumentTypesListDocument);
const DocumentTypesDocumentString = print(DocumentTypesDocument);
const CreateDocumentTypeDocumentString = print(CreateDocumentTypeDocument);
const DeleteDocumentTypeDocumentString = print(DeleteDocumentTypeDocument);
const UpdateDocumentTypeDocumentString = print(UpdateDocumentTypeDocument);
const ExportDocumentString = print(ExportDocument);
const FeedbacksDocumentString = print(FeedbacksDocument);
const CreateFormDocumentString = print(CreateFormDocument);
const FormsDocumentString = print(FormsDocument);
const GetFormForQuestionGroupsDocumentString = print(GetFormForQuestionGroupsDocument);
const GetFormForBuilderDocumentString = print(GetFormForBuilderDocument);
const GetFormIncludesDocumentString = print(GetFormIncludesDocument);
const GetLeadsFormDocumentString = print(GetLeadsFormDocument);
const GetFormByNameDocumentString = print(GetFormByNameDocument);
const GetFormByNamePreviewDocumentString = print(GetFormByNamePreviewDocument);
const DeleteFormDocumentString = print(DeleteFormDocument);
const UpdateFormDocumentString = print(UpdateFormDocument);
const HandleFormBuilderDocumentString = print(HandleFormBuilderDocument);
const SubmitFormDocumentString = print(SubmitFormDocument);
const SubmitLeadsFormDocumentString = print(SubmitLeadsFormDocument);
const UpdateMostRecentFormDraftDocumentString = print(UpdateMostRecentFormDraftDocument);
const FormSubmissionsDocumentString = print(FormSubmissionsDocument);
const GetInvitationDocumentString = print(GetInvitationDocument);
const GetInvitationsDocumentString = print(GetInvitationsDocument);
const SendInvitationDocumentString = print(SendInvitationDocument);
const CompleteInvitationDocumentString = print(CompleteInvitationDocument);
const UpdateInvitationDocumentString = print(UpdateInvitationDocument);
const DeleteInvitationDocumentString = print(DeleteInvitationDocument);
const LawyerAssociationsDocumentString = print(LawyerAssociationsDocument);
const LawyerAssociationDocumentString = print(LawyerAssociationDocument);
const LawyerAssociationIncludesDocumentString = print(LawyerAssociationIncludesDocument);
const LawyerAssociationAssignedLawyersDocumentString = print(LawyerAssociationAssignedLawyersDocument);
const DeleteLawyerAssociationDocumentString = print(DeleteLawyerAssociationDocument);
const CreateLawyerAssociationDocumentString = print(CreateLawyerAssociationDocument);
const HandleLawyerAssociationDocumentString = print(HandleLawyerAssociationDocument);
const GetLeadEmbedDocumentString = print(GetLeadEmbedDocument);
const LeadsDocumentString = print(LeadsDocument);
const LeadDocumentString = print(LeadDocument);
const HandleLeadDocumentString = print(HandleLeadDocument);
const MetaDocumentString = print(MetaDocument);
const UpdateNoteDocumentString = print(UpdateNoteDocument);
const DeleteNoteDocumentString = print(DeleteNoteDocument);
const CreateApplicationDocumentNoteDocumentString = print(CreateApplicationDocumentNoteDocument);
const CreateAccountDocumentNoteDocumentString = print(CreateAccountDocumentNoteDocument);
const CreateAccountTaskNoteDocumentString = print(CreateAccountTaskNoteDocument);
const CreateLawyerAssociationNoteDocumentString = print(CreateLawyerAssociationNoteDocument);
const CreateApplicationNoteDocumentString = print(CreateApplicationNoteDocument);
const CreateCompanyTalentNoteDocumentString = print(CreateCompanyTalentNoteDocument);
const CreateCompanyComplianceNoteDocumentString = print(CreateCompanyComplianceNoteDocument);
const GetAllNotesDocumentString = print(GetAllNotesDocument);
const GetAllByCompanyComplianceDocumentString = print(GetAllByCompanyComplianceDocument);
const GetAllByApplicationDocumentString = print(GetAllByApplicationDocument);
const GetAllByApplicationDocumentDocumentString = print(GetAllByApplicationDocumentDocument);
const GetAllByAccountDocumentDocumentString = print(GetAllByAccountDocumentDocument);
const GetAllByAccountTaskDocumentString = print(GetAllByAccountTaskDocument);
const GetAllByLawyerAssociationDocumentString = print(GetAllByLawyerAssociationDocument);
const GetAllByCompanyTalentDocumentString = print(GetAllByCompanyTalentDocument);
const NotesListDocumentString = print(NotesListDocument);
const NotificationsDocumentString = print(NotificationsDocument);
const UpdateNotificationDocumentString = print(UpdateNotificationDocument);
const PaymentIntentsDocumentString = print(PaymentIntentsDocument);
const PaymentIntentDocumentString = print(PaymentIntentDocument);
const CreatePaymentIntentDocumentString = print(CreatePaymentIntentDocument);
const UpdatePaymentIntentDocumentString = print(UpdatePaymentIntentDocument);
const PermissionsDocumentString = print(PermissionsDocument);
const PermissionGroupsDocumentString = print(PermissionGroupsDocument);
const PermitDocumentString = print(PermitDocument);
const PermitsDocumentString = print(PermitsDocument);
const CreatePermitDocumentString = print(CreatePermitDocument);
const UpdatePermitDocumentString = print(UpdatePermitDocument);
const GetPersonDocumentString = print(GetPersonDocument);
const GetPersonIncludesDocumentString = print(GetPersonIncludesDocument);
const UpdatePersonDocumentString = print(UpdatePersonDocument);
const PersonsDocumentString = print(PersonsDocument);
const PersonsIncludesDocumentString = print(PersonsIncludesDocument);
const CreatePersonDocumentString = print(CreatePersonDocument);
const HandlePublicDocumentString = print(HandlePublicDocument);
const QuestionsDocumentString = print(QuestionsDocument);
const QuestionDocumentString = print(QuestionDocument);
const QuestionIncludesDocumentString = print(QuestionIncludesDocument);
const UpsertQuestionDocumentString = print(UpsertQuestionDocument);
const CreateQuestionGroupingDocumentString = print(CreateQuestionGroupingDocument);
const QuestionGroupingsDocumentString = print(QuestionGroupingsDocument);
const QuestionGroupingDocumentString = print(QuestionGroupingDocument);
const UpdateQuestionGroupingDocumentString = print(UpdateQuestionGroupingDocument);
const QuestionOptionsDocumentString = print(QuestionOptionsDocument);
const GetReferrerCodesDocumentString = print(GetReferrerCodesDocument);
const ReferrerDocumentString = print(ReferrerDocument);
const CreateReferrerCodeDocumentString = print(CreateReferrerCodeDocument);
const UpsertReminderDocumentString = print(UpsertReminderDocument);
const ReminderDocumentString = print(ReminderDocument);
const RepresentativesDocumentString = print(RepresentativesDocument);
const RepresentativeDocumentString = print(RepresentativeDocument);
const RepresentativeWithApplicationsDocumentString = print(RepresentativeWithApplicationsDocument);
const CreateRepresentativeDocumentString = print(CreateRepresentativeDocument);
const DeleteRepresentativeDocumentString = print(DeleteRepresentativeDocument);
const GetRoleByIdDocumentString = print(GetRoleByIdDocument);
const GetRolesDocumentString = print(GetRolesDocument);
const CreateRoleDocumentString = print(CreateRoleDocument);
const DeleteRoleDocumentString = print(DeleteRoleDocument);
const UpdateRoleDocumentString = print(UpdateRoleDocument);
const DuplicateRoleDocumentString = print(DuplicateRoleDocument);
const SearchDocumentString = print(SearchDocument);
const HandleSendGridDocumentString = print(HandleSendGridDocument);
const GetEmbeddedSignUrlDocumentString = print(GetEmbeddedSignUrlDocument);
const GetHelloSignFilesDocumentString = print(GetHelloSignFilesDocument);
const HandleHelloSignWebhookDocumentString = print(HandleHelloSignWebhookDocument);
const HandleDocuSealWebhookDocumentString = print(HandleDocuSealWebhookDocument);
const SignatureRequestSignerDocumentString = print(SignatureRequestSignerDocument);
const StatusDocumentString = print(StatusDocument);
const StatusesDocumentString = print(StatusesDocument);
const UpdateStatusDocumentString = print(UpdateStatusDocument);
const CreateStorageFileDocumentString = print(CreateStorageFileDocument);
const GetStorageFileUrlDocumentString = print(GetStorageFileUrlDocument);
const DeleteStorageFileDocumentString = print(DeleteStorageFileDocument);
const GetStripeCustomerDocumentString = print(GetStripeCustomerDocument);
const GetStripeCheckoutUrlDocumentString = print(GetStripeCheckoutUrlDocument);
const GetStripePortalUrlDocumentString = print(GetStripePortalUrlDocument);
const HandleStripeWebhookDocumentString = print(HandleStripeWebhookDocument);
const AdminImpersonateUserDocumentString = print(AdminImpersonateUserDocument);
const AdminDeleteDocumentString = print(AdminDeleteDocument);
const AdminUpdateDocumentString = print(AdminUpdateDocument);
const TagsDocumentString = print(TagsDocument);
const TasksDocumentString = print(TasksDocument);
const TaskDocumentString = print(TaskDocument);
const TaskActionDocumentString = print(TaskActionDocument);
const UpsertTaskDocumentString = print(UpsertTaskDocument);
const TemplateDocumentDocumentString = print(TemplateDocumentDocument);
const TemplateDocumentsDocumentString = print(TemplateDocumentsDocument);
const CreateTemplateDocumentDocumentString = print(CreateTemplateDocumentDocument);
const UpdateTemplateDocumentDocumentString = print(UpdateTemplateDocumentDocument);
const HandleTemplateDocumentDocumentString = print(HandleTemplateDocumentDocument);
const GetActiveUserDocumentString = print(GetActiveUserDocument);
const UsersDocumentString = print(UsersDocument);
const UserIncludesDocumentString = print(UserIncludesDocument);
const UsersIncludesDocumentString = print(UsersIncludesDocument);
const PublicUserDocumentString = print(PublicUserDocument);
const UpdateUserDocumentString = print(UpdateUserDocument);
const UserExistsDocumentString = print(UserExistsDocument);
const UserPermissionsDocumentString = print(UserPermissionsDocument);
const UpdateUserPermissionDocumentString = print(UpdateUserPermissionDocument);
const UpdateUsersAccessDocumentString = print(UpdateUsersAccessDocument);
export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    Accounts(variables?: AccountsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: AccountsQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<AccountsQuery>(AccountsDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Accounts', 'query');
    },
    Account(variables?: AccountQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: AccountQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<AccountQuery>(AccountDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Account', 'query');
    },
    AccountIncludes(variables: AccountIncludesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: AccountIncludesQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<AccountIncludesQuery>(AccountIncludesDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AccountIncludes', 'query');
    },
    AccountSubscription(variables?: AccountSubscriptionQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: AccountSubscriptionQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<AccountSubscriptionQuery>(AccountSubscriptionDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AccountSubscription', 'query');
    },
    updateAccount(variables: UpdateAccountMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: UpdateAccountMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<UpdateAccountMutation>(UpdateAccountDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateAccount', 'mutation');
    },
    publicSignUp(variables: PublicSignUpMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: PublicSignUpMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<PublicSignUpMutation>(PublicSignUpDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'publicSignUp', 'mutation');
    },
    AccountDocument(variables?: AccountDocumentQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: AccountDocumentQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<AccountDocumentQuery>(AccountDocumentDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AccountDocument', 'query');
    },
    AccountDocumentRetainer(variables?: AccountDocumentRetainerQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: AccountDocumentRetainerQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<AccountDocumentRetainerQuery>(AccountDocumentRetainerDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AccountDocumentRetainer', 'query');
    },
    AccountDocuments(variables: AccountDocumentsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: AccountDocumentsQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<AccountDocumentsQuery>(AccountDocumentsDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AccountDocuments', 'query');
    },
    getUrlDownloadAccountDocument(variables?: GetUrlDownloadAccountDocumentQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: GetUrlDownloadAccountDocumentQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GetUrlDownloadAccountDocumentQuery>(GetUrlDownloadAccountDocumentDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getUrlDownloadAccountDocument', 'query');
    },
    uploadAccountDocument(variables: UploadAccountDocumentMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: UploadAccountDocumentMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<UploadAccountDocumentMutation>(UploadAccountDocumentDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'uploadAccountDocument', 'mutation');
    },
    updateAccountDocument(variables: UpdateAccountDocumentMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: UpdateAccountDocumentMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<UpdateAccountDocumentMutation>(UpdateAccountDocumentDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateAccountDocument', 'mutation');
    },
    handleAccountEmbed(variables: HandleAccountEmbedMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: HandleAccountEmbedMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<HandleAccountEmbedMutation>(HandleAccountEmbedDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'handleAccountEmbed', 'mutation');
    },
    AccountPermissions(variables: AccountPermissionsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: AccountPermissionsQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<AccountPermissionsQuery>(AccountPermissionsDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AccountPermissions', 'query');
    },
    updateAccountPermission(variables: UpdateAccountPermissionMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: UpdateAccountPermissionMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<UpdateAccountPermissionMutation>(UpdateAccountPermissionDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateAccountPermission', 'mutation');
    },
    AccountSetting(variables: AccountSettingQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: AccountSettingQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<AccountSettingQuery>(AccountSettingDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AccountSetting', 'query');
    },
    updateAccountSettings(variables: UpdateAccountSettingsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: UpdateAccountSettingsMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<UpdateAccountSettingsMutation>(UpdateAccountSettingsDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateAccountSettings', 'mutation');
    },
    AccountTags(variables?: AccountTagsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: AccountTagsQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<AccountTagsQuery>(AccountTagsDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AccountTags', 'query');
    },
    updateAccountTag(variables?: UpdateAccountTagMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: UpdateAccountTagMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<UpdateAccountTagMutation>(UpdateAccountTagDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateAccountTag', 'mutation');
    },
    deleteAccountTag(variables: DeleteAccountTagMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: DeleteAccountTagMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<DeleteAccountTagMutation>(DeleteAccountTagDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteAccountTag', 'mutation');
    },
    getAccountTask(variables?: GetAccountTaskQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: GetAccountTaskQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GetAccountTaskQuery>(GetAccountTaskDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getAccountTask', 'query');
    },
    getAccountTaskIncludes(variables: GetAccountTaskIncludesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: GetAccountTaskIncludesQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GetAccountTaskIncludesQuery>(GetAccountTaskIncludesDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getAccountTaskIncludes', 'query');
    },
    getAccountTasks(variables?: GetAccountTasksQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: GetAccountTasksQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GetAccountTasksQuery>(GetAccountTasksDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getAccountTasks', 'query');
    },
    getAccountTasksIncludes(variables: GetAccountTasksIncludesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: GetAccountTasksIncludesQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GetAccountTasksIncludesQuery>(GetAccountTasksIncludesDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getAccountTasksIncludes', 'query');
    },
    getAccountTasksOverview(variables?: GetAccountTasksOverviewQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: GetAccountTasksOverviewQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GetAccountTasksOverviewQuery>(GetAccountTasksOverviewDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getAccountTasksOverview', 'query');
    },
    updateAccountTasks(variables: UpdateAccountTasksMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: UpdateAccountTasksMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<UpdateAccountTasksMutation>(UpdateAccountTasksDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateAccountTasks', 'mutation');
    },
    createChangeRequest(variables: CreateChangeRequestMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: CreateChangeRequestMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<CreateChangeRequestMutation>(CreateChangeRequestDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createChangeRequest', 'mutation');
    },
    updateChangeRequest(variables: UpdateChangeRequestMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: UpdateChangeRequestMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<UpdateChangeRequestMutation>(UpdateChangeRequestDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateChangeRequest', 'mutation');
    },
    handleAccountTask(variables: HandleAccountTaskMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: HandleAccountTaskMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<HandleAccountTaskMutation>(HandleAccountTaskDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'handleAccountTask', 'mutation');
    },
    deleteAccountTask(variables: DeleteAccountTaskMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: DeleteAccountTaskMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<DeleteAccountTaskMutation>(DeleteAccountTaskDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteAccountTask', 'mutation');
    },
    createAccountTask(variables: CreateAccountTaskMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: CreateAccountTaskMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<CreateAccountTaskMutation>(CreateAccountTaskDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createAccountTask', 'mutation');
    },
    findManyInternalAccountTasks(variables?: FindManyInternalAccountTasksQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: FindManyInternalAccountTasksQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<FindManyInternalAccountTasksQuery>(FindManyInternalAccountTasksDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'findManyInternalAccountTasks', 'query');
    },
    AccountTasksList(variables?: AccountTasksListQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: AccountTasksListQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<AccountTasksListQuery>(AccountTasksListDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AccountTasksList', 'query');
    },
    ActivityLogs(variables?: ActivityLogsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: ActivityLogsQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<ActivityLogsQuery>(ActivityLogsDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ActivityLogs', 'query');
    },
    getAnswers(variables: GetAnswersQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: GetAnswersQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GetAnswersQuery>(GetAnswersDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getAnswers', 'query');
    },
    updateAnswers(variables: UpdateAnswersMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: UpdateAnswersMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<UpdateAnswersMutation>(UpdateAnswersDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateAnswers', 'mutation');
    },
    getAnswersAdmin(variables: GetAnswersAdminQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: GetAnswersAdminQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GetAnswersAdminQuery>(GetAnswersAdminDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getAnswersAdmin', 'query');
    },
    getApplication(variables?: GetApplicationQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: GetApplicationQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GetApplicationQuery>(GetApplicationDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getApplication', 'query');
    },
    getApplicationIncludes(variables: GetApplicationIncludesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: GetApplicationIncludesQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GetApplicationIncludesQuery>(GetApplicationIncludesDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getApplicationIncludes', 'query');
    },
    getApplicationWithTemplate(variables?: GetApplicationWithTemplateQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: GetApplicationWithTemplateQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GetApplicationWithTemplateQuery>(GetApplicationWithTemplateDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getApplicationWithTemplate', 'query');
    },
    getApplicationBasic(variables?: GetApplicationBasicQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: GetApplicationBasicQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GetApplicationBasicQuery>(GetApplicationBasicDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getApplicationBasic', 'query');
    },
    getApplicationForExport(variables?: GetApplicationForExportQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: GetApplicationForExportQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GetApplicationForExportQuery>(GetApplicationForExportDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getApplicationForExport', 'query');
    },
    getApplications(variables?: GetApplicationsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: GetApplicationsQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GetApplicationsQuery>(GetApplicationsDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getApplications', 'query');
    },
    getApplicationsOverview(variables?: GetApplicationsOverviewQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: GetApplicationsOverviewQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GetApplicationsOverviewQuery>(GetApplicationsOverviewDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getApplicationsOverview', 'query');
    },
    getApplicationsIncludes(variables: GetApplicationsIncludesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: GetApplicationsIncludesQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GetApplicationsIncludesQuery>(GetApplicationsIncludesDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getApplicationsIncludes', 'query');
    },
    createApplication(variables?: CreateApplicationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: CreateApplicationMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<CreateApplicationMutation>(CreateApplicationDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createApplication', 'mutation');
    },
    updateApplication(variables: UpdateApplicationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: UpdateApplicationMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<UpdateApplicationMutation>(UpdateApplicationDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateApplication', 'mutation');
    },
    handleApplication(variables: HandleApplicationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: HandleApplicationMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<HandleApplicationMutation>(HandleApplicationDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'handleApplication', 'mutation');
    },
    deleteApplication(variables: DeleteApplicationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: DeleteApplicationMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<DeleteApplicationMutation>(DeleteApplicationDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteApplication', 'mutation');
    },
    ApplicationDocument(variables?: ApplicationDocumentQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: ApplicationDocumentQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<ApplicationDocumentQuery>(ApplicationDocumentDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ApplicationDocument', 'query');
    },
    ApplicationDocuments(variables?: ApplicationDocumentsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: ApplicationDocumentsQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<ApplicationDocumentsQuery>(ApplicationDocumentsDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ApplicationDocuments', 'query');
    },
    getUrlDocuments(variables: GetUrlDocumentsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: GetUrlDocumentsQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GetUrlDocumentsQuery>(GetUrlDocumentsDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getUrlDocuments', 'query');
    },
    getUrlDownloadApplicationDocument(variables?: GetUrlDownloadApplicationDocumentQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: GetUrlDownloadApplicationDocumentQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GetUrlDownloadApplicationDocumentQuery>(GetUrlDownloadApplicationDocumentDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getUrlDownloadApplicationDocument', 'query');
    },
    uploadApplicationDocument(variables: UploadApplicationDocumentMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: UploadApplicationDocumentMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<UploadApplicationDocumentMutation>(UploadApplicationDocumentDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'uploadApplicationDocument', 'mutation');
    },
    generateApplicationDocuments(variables: GenerateApplicationDocumentsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: GenerateApplicationDocumentsMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GenerateApplicationDocumentsMutation>(GenerateApplicationDocumentsDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'generateApplicationDocuments', 'mutation');
    },
    signApplicationDocuments(variables: SignApplicationDocumentsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: SignApplicationDocumentsMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<SignApplicationDocumentsMutation>(SignApplicationDocumentsDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'signApplicationDocuments', 'mutation');
    },
    updateApplicationDocuments(variables: UpdateApplicationDocumentsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: UpdateApplicationDocumentsMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<UpdateApplicationDocumentsMutation>(UpdateApplicationDocumentsDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateApplicationDocuments', 'mutation');
    },
    generateApplicationDocument(variables: GenerateApplicationDocumentMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: GenerateApplicationDocumentMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GenerateApplicationDocumentMutation>(GenerateApplicationDocumentDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'generateApplicationDocument', 'mutation');
    },
    createApplicationItem(variables: CreateApplicationItemMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: CreateApplicationItemMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<CreateApplicationItemMutation>(CreateApplicationItemDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createApplicationItem', 'mutation');
    },
    deleteApplicationItem(variables: DeleteApplicationItemMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: DeleteApplicationItemMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<DeleteApplicationItemMutation>(DeleteApplicationItemDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteApplicationItem', 'mutation');
    },
    updateApplicationItem(variables: UpdateApplicationItemMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: UpdateApplicationItemMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<UpdateApplicationItemMutation>(UpdateApplicationItemDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateApplicationItem', 'mutation');
    },
    ApplicationTemplates(variables?: ApplicationTemplatesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: ApplicationTemplatesQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<ApplicationTemplatesQuery>(ApplicationTemplatesDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ApplicationTemplates', 'query');
    },
    ApplicationTemplate(variables: ApplicationTemplateQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: ApplicationTemplateQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<ApplicationTemplateQuery>(ApplicationTemplateDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ApplicationTemplate', 'query');
    },
    createApplicationTemplate(variables: CreateApplicationTemplateMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: CreateApplicationTemplateMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<CreateApplicationTemplateMutation>(CreateApplicationTemplateDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createApplicationTemplate', 'mutation');
    },
    updateApplicationTemplate(variables: UpdateApplicationTemplateMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: UpdateApplicationTemplateMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<UpdateApplicationTemplateMutation>(UpdateApplicationTemplateDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateApplicationTemplate', 'mutation');
    },
    deleteApplicationTemplate(variables: DeleteApplicationTemplateMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: DeleteApplicationTemplateMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<DeleteApplicationTemplateMutation>(DeleteApplicationTemplateDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteApplicationTemplate', 'mutation');
    },
    createApplicationTemplateItem(variables: CreateApplicationTemplateItemMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: CreateApplicationTemplateItemMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<CreateApplicationTemplateItemMutation>(CreateApplicationTemplateItemDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createApplicationTemplateItem', 'mutation');
    },
    deleteApplicationTemplateItem(variables: DeleteApplicationTemplateItemMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: DeleteApplicationTemplateItemMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<DeleteApplicationTemplateItemMutation>(DeleteApplicationTemplateItemDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteApplicationTemplateItem', 'mutation');
    },
    updateApplicationTemplateItem(variables: UpdateApplicationTemplateItemMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: UpdateApplicationTemplateItemMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<UpdateApplicationTemplateItemMutation>(UpdateApplicationTemplateItemDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateApplicationTemplateItem', 'mutation');
    },
    handleAuth(variables: HandleAuthMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: HandleAuthMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<HandleAuthMutation>(HandleAuthDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'handleAuth', 'mutation');
    },
    deleteComment(variables: DeleteCommentMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: DeleteCommentMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<DeleteCommentMutation>(DeleteCommentDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteComment', 'mutation');
    },
    updateComment(variables: UpdateCommentMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: UpdateCommentMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<UpdateCommentMutation>(UpdateCommentDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateComment', 'mutation');
    },
    createComment(variables: CreateCommentMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: CreateCommentMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<CreateCommentMutation>(CreateCommentDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createComment', 'mutation');
    },
    getComments(variables?: GetCommentsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: GetCommentsQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GetCommentsQuery>(GetCommentsDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getComments', 'query');
    },
    getCommentsIncludes(variables: GetCommentsIncludesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: GetCommentsIncludesQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GetCommentsIncludesQuery>(GetCommentsIncludesDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getCommentsIncludes', 'query');
    },
    CommentsList(variables?: CommentsListQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: CommentsListQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<CommentsListQuery>(CommentsListDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CommentsList', 'query');
    },
    companyCompliance(variables?: CompanyComplianceQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: CompanyComplianceQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<CompanyComplianceQuery>(CompanyComplianceDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'companyCompliance', 'query');
    },
    upsertCompanyCompliance(variables: UpsertCompanyComplianceMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: UpsertCompanyComplianceMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<UpsertCompanyComplianceMutation>(UpsertCompanyComplianceDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'upsertCompanyCompliance', 'mutation');
    },
    getCompanyTalent(variables?: GetCompanyTalentQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: GetCompanyTalentQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GetCompanyTalentQuery>(GetCompanyTalentDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getCompanyTalent', 'query');
    },
    getCompanyTalents(variables?: GetCompanyTalentsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: GetCompanyTalentsQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GetCompanyTalentsQuery>(GetCompanyTalentsDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getCompanyTalents', 'query');
    },
    createCompanyTalent(variables: CreateCompanyTalentMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: CreateCompanyTalentMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<CreateCompanyTalentMutation>(CreateCompanyTalentDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createCompanyTalent', 'mutation');
    },
    updateCompanyTalent(variables: UpdateCompanyTalentMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: UpdateCompanyTalentMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<UpdateCompanyTalentMutation>(UpdateCompanyTalentDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateCompanyTalent', 'mutation');
    },
    getCompliances(variables?: GetCompliancesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: GetCompliancesQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GetCompliancesQuery>(GetCompliancesDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getCompliances', 'query');
    },
    createCompliance(variables: CreateComplianceMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: CreateComplianceMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<CreateComplianceMutation>(CreateComplianceDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createCompliance', 'mutation');
    },
    updateCompliance(variables: UpdateComplianceMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: UpdateComplianceMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<UpdateComplianceMutation>(UpdateComplianceDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateCompliance', 'mutation');
    },
    uploadComplianceDocument(variables: UploadComplianceDocumentMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: UploadComplianceDocumentMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<UploadComplianceDocumentMutation>(UploadComplianceDocumentDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'uploadComplianceDocument', 'mutation');
    },
    deleteComplianceDocuments(variables: DeleteComplianceDocumentsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: DeleteComplianceDocumentsMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<DeleteComplianceDocumentsMutation>(DeleteComplianceDocumentsDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteComplianceDocuments', 'mutation');
    },
    generateContent(variables: GenerateContentMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: GenerateContentMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GenerateContentMutation>(GenerateContentDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'generateContent', 'mutation');
    },
    Conversation(variables?: ConversationQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: ConversationQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<ConversationQuery>(ConversationDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Conversation', 'query');
    },
    Conversations(variables?: ConversationsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: ConversationsQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<ConversationsQuery>(ConversationsDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Conversations', 'query');
    },
    createConversation(variables: CreateConversationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: CreateConversationMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<CreateConversationMutation>(CreateConversationDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createConversation', 'mutation');
    },
    createMessage(variables: CreateMessageMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: CreateMessageMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<CreateMessageMutation>(CreateMessageDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createMessage', 'mutation');
    },
    updateMessage(variables: UpdateMessageMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: UpdateMessageMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<UpdateMessageMutation>(UpdateMessageDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateMessage', 'mutation');
    },
    updateConversation(variables: UpdateConversationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: UpdateConversationMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<UpdateConversationMutation>(UpdateConversationDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateConversation', 'mutation');
    },
    disableNotification(variables: DisableNotificationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: DisableNotificationMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<DisableNotificationMutation>(DisableNotificationDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'disableNotification', 'mutation');
    },
    getUrlDocument(variables: GetUrlDocumentQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: GetUrlDocumentQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GetUrlDocumentQuery>(GetUrlDocumentDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getUrlDocument', 'query');
    },
    deleteDocument(variables: DeleteDocumentMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: DeleteDocumentMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<DeleteDocumentMutation>(DeleteDocumentDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteDocument', 'mutation');
    },
    DocumentType(variables: DocumentTypeQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: DocumentTypeQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<DocumentTypeQuery>(DocumentTypeDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DocumentType', 'query');
    },
    DocumentTypesList(variables?: DocumentTypesListQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: DocumentTypesListQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<DocumentTypesListQuery>(DocumentTypesListDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DocumentTypesList', 'query');
    },
    DocumentTypes(variables?: DocumentTypesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: DocumentTypesQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<DocumentTypesQuery>(DocumentTypesDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DocumentTypes', 'query');
    },
    createDocumentType(variables: CreateDocumentTypeMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: CreateDocumentTypeMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<CreateDocumentTypeMutation>(CreateDocumentTypeDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createDocumentType', 'mutation');
    },
    deleteDocumentType(variables: DeleteDocumentTypeMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: DeleteDocumentTypeMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<DeleteDocumentTypeMutation>(DeleteDocumentTypeDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteDocumentType', 'mutation');
    },
    updateDocumentType(variables: UpdateDocumentTypeMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: UpdateDocumentTypeMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<UpdateDocumentTypeMutation>(UpdateDocumentTypeDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateDocumentType', 'mutation');
    },
    export(variables: ExportMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: ExportMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<ExportMutation>(ExportDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'export', 'mutation');
    },
    Feedbacks(variables?: FeedbacksQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: FeedbacksQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<FeedbacksQuery>(FeedbacksDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Feedbacks', 'query');
    },
    createForm(variables: CreateFormMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: CreateFormMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<CreateFormMutation>(CreateFormDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createForm', 'mutation');
    },
    Forms(variables?: FormsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: FormsQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<FormsQuery>(FormsDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Forms', 'query');
    },
    getFormForQuestionGroups(variables?: GetFormForQuestionGroupsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: GetFormForQuestionGroupsQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GetFormForQuestionGroupsQuery>(GetFormForQuestionGroupsDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getFormForQuestionGroups', 'query');
    },
    getFormForBuilder(variables?: GetFormForBuilderQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: GetFormForBuilderQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GetFormForBuilderQuery>(GetFormForBuilderDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getFormForBuilder', 'query');
    },
    getFormIncludes(variables?: GetFormIncludesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: GetFormIncludesQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GetFormIncludesQuery>(GetFormIncludesDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getFormIncludes', 'query');
    },
    getLeadsForm(variables: GetLeadsFormQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: GetLeadsFormQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GetLeadsFormQuery>(GetLeadsFormDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getLeadsForm', 'query');
    },
    getFormByName(variables: GetFormByNameQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: GetFormByNameQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GetFormByNameQuery>(GetFormByNameDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getFormByName', 'query');
    },
    getFormByNamePreview(variables?: GetFormByNamePreviewQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: GetFormByNamePreviewQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GetFormByNamePreviewQuery>(GetFormByNamePreviewDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getFormByNamePreview', 'query');
    },
    deleteForm(variables: DeleteFormMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: DeleteFormMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<DeleteFormMutation>(DeleteFormDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteForm', 'mutation');
    },
    updateForm(variables: UpdateFormMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: UpdateFormMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<UpdateFormMutation>(UpdateFormDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateForm', 'mutation');
    },
    handleFormBuilder(variables: HandleFormBuilderMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: HandleFormBuilderMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<HandleFormBuilderMutation>(HandleFormBuilderDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'handleFormBuilder', 'mutation');
    },
    submitForm(variables: SubmitFormMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: SubmitFormMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<SubmitFormMutation>(SubmitFormDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'submitForm', 'mutation');
    },
    submitLeadsForm(variables: SubmitLeadsFormMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: SubmitLeadsFormMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<SubmitLeadsFormMutation>(SubmitLeadsFormDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'submitLeadsForm', 'mutation');
    },
    updateMostRecentFormDraft(variables: UpdateMostRecentFormDraftMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: UpdateMostRecentFormDraftMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<UpdateMostRecentFormDraftMutation>(UpdateMostRecentFormDraftDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateMostRecentFormDraft', 'mutation');
    },
    FormSubmissions(variables?: FormSubmissionsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: FormSubmissionsQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<FormSubmissionsQuery>(FormSubmissionsDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'FormSubmissions', 'query');
    },
    getInvitation(variables?: GetInvitationQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: GetInvitationQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GetInvitationQuery>(GetInvitationDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getInvitation', 'query');
    },
    getInvitations(variables?: GetInvitationsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: GetInvitationsQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GetInvitationsQuery>(GetInvitationsDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getInvitations', 'query');
    },
    sendInvitation(variables?: SendInvitationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: SendInvitationMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<SendInvitationMutation>(SendInvitationDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'sendInvitation', 'mutation');
    },
    completeInvitation(variables?: CompleteInvitationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: CompleteInvitationMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<CompleteInvitationMutation>(CompleteInvitationDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'completeInvitation', 'mutation');
    },
    updateInvitation(variables: UpdateInvitationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: UpdateInvitationMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<UpdateInvitationMutation>(UpdateInvitationDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateInvitation', 'mutation');
    },
    deleteInvitation(variables: DeleteInvitationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: DeleteInvitationMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<DeleteInvitationMutation>(DeleteInvitationDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteInvitation', 'mutation');
    },
    LawyerAssociations(variables?: LawyerAssociationsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: LawyerAssociationsQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<LawyerAssociationsQuery>(LawyerAssociationsDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'LawyerAssociations', 'query');
    },
    LawyerAssociation(variables?: LawyerAssociationQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: LawyerAssociationQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<LawyerAssociationQuery>(LawyerAssociationDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'LawyerAssociation', 'query');
    },
    LawyerAssociationIncludes(variables: LawyerAssociationIncludesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: LawyerAssociationIncludesQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<LawyerAssociationIncludesQuery>(LawyerAssociationIncludesDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'LawyerAssociationIncludes', 'query');
    },
    LawyerAssociationAssignedLawyers(variables?: LawyerAssociationAssignedLawyersQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: LawyerAssociationAssignedLawyersQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<LawyerAssociationAssignedLawyersQuery>(LawyerAssociationAssignedLawyersDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'LawyerAssociationAssignedLawyers', 'query');
    },
    deleteLawyerAssociation(variables: DeleteLawyerAssociationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: DeleteLawyerAssociationMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<DeleteLawyerAssociationMutation>(DeleteLawyerAssociationDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteLawyerAssociation', 'mutation');
    },
    createLawyerAssociation(variables: CreateLawyerAssociationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: CreateLawyerAssociationMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<CreateLawyerAssociationMutation>(CreateLawyerAssociationDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createLawyerAssociation', 'mutation');
    },
    handleLawyerAssociation(variables: HandleLawyerAssociationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: HandleLawyerAssociationMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<HandleLawyerAssociationMutation>(HandleLawyerAssociationDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'handleLawyerAssociation', 'mutation');
    },
    getLeadEmbed(variables: GetLeadEmbedQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: GetLeadEmbedQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GetLeadEmbedQuery>(GetLeadEmbedDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getLeadEmbed', 'query');
    },
    Leads(variables?: LeadsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: LeadsQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<LeadsQuery>(LeadsDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Leads', 'query');
    },
    Lead(variables: LeadQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: LeadQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<LeadQuery>(LeadDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Lead', 'query');
    },
    handleLead(variables: HandleLeadMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: HandleLeadMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<HandleLeadMutation>(HandleLeadDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'handleLead', 'mutation');
    },
    Meta(variables: MetaQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: MetaQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<MetaQuery>(MetaDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Meta', 'query');
    },
    updateNote(variables: UpdateNoteMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: UpdateNoteMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<UpdateNoteMutation>(UpdateNoteDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateNote', 'mutation');
    },
    deleteNote(variables: DeleteNoteMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: DeleteNoteMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<DeleteNoteMutation>(DeleteNoteDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteNote', 'mutation');
    },
    createApplicationDocumentNote(variables: CreateApplicationDocumentNoteMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: CreateApplicationDocumentNoteMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<CreateApplicationDocumentNoteMutation>(CreateApplicationDocumentNoteDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createApplicationDocumentNote', 'mutation');
    },
    createAccountDocumentNote(variables: CreateAccountDocumentNoteMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: CreateAccountDocumentNoteMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<CreateAccountDocumentNoteMutation>(CreateAccountDocumentNoteDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createAccountDocumentNote', 'mutation');
    },
    createAccountTaskNote(variables: CreateAccountTaskNoteMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: CreateAccountTaskNoteMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<CreateAccountTaskNoteMutation>(CreateAccountTaskNoteDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createAccountTaskNote', 'mutation');
    },
    createLawyerAssociationNote(variables: CreateLawyerAssociationNoteMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: CreateLawyerAssociationNoteMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<CreateLawyerAssociationNoteMutation>(CreateLawyerAssociationNoteDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createLawyerAssociationNote', 'mutation');
    },
    createApplicationNote(variables: CreateApplicationNoteMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: CreateApplicationNoteMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<CreateApplicationNoteMutation>(CreateApplicationNoteDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createApplicationNote', 'mutation');
    },
    createCompanyTalentNote(variables: CreateCompanyTalentNoteMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: CreateCompanyTalentNoteMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<CreateCompanyTalentNoteMutation>(CreateCompanyTalentNoteDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createCompanyTalentNote', 'mutation');
    },
    createCompanyComplianceNote(variables: CreateCompanyComplianceNoteMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: CreateCompanyComplianceNoteMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<CreateCompanyComplianceNoteMutation>(CreateCompanyComplianceNoteDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createCompanyComplianceNote', 'mutation');
    },
    getAllNotes(variables?: GetAllNotesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: GetAllNotesQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GetAllNotesQuery>(GetAllNotesDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getAllNotes', 'query');
    },
    getAllByCompanyCompliance(variables: GetAllByCompanyComplianceQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: GetAllByCompanyComplianceQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GetAllByCompanyComplianceQuery>(GetAllByCompanyComplianceDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getAllByCompanyCompliance', 'query');
    },
    getAllByApplication(variables: GetAllByApplicationQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: GetAllByApplicationQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GetAllByApplicationQuery>(GetAllByApplicationDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getAllByApplication', 'query');
    },
    getAllByApplicationDocument(variables: GetAllByApplicationDocumentQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: GetAllByApplicationDocumentQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GetAllByApplicationDocumentQuery>(GetAllByApplicationDocumentDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getAllByApplicationDocument', 'query');
    },
    getAllByAccountDocument(variables: GetAllByAccountDocumentQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: GetAllByAccountDocumentQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GetAllByAccountDocumentQuery>(GetAllByAccountDocumentDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getAllByAccountDocument', 'query');
    },
    getAllByAccountTask(variables: GetAllByAccountTaskQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: GetAllByAccountTaskQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GetAllByAccountTaskQuery>(GetAllByAccountTaskDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getAllByAccountTask', 'query');
    },
    getAllByLawyerAssociation(variables: GetAllByLawyerAssociationQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: GetAllByLawyerAssociationQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GetAllByLawyerAssociationQuery>(GetAllByLawyerAssociationDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getAllByLawyerAssociation', 'query');
    },
    getAllByCompanyTalent(variables: GetAllByCompanyTalentQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: GetAllByCompanyTalentQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GetAllByCompanyTalentQuery>(GetAllByCompanyTalentDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getAllByCompanyTalent', 'query');
    },
    NotesList(variables?: NotesListQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: NotesListQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<NotesListQuery>(NotesListDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'NotesList', 'query');
    },
    Notifications(variables?: NotificationsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: NotificationsQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<NotificationsQuery>(NotificationsDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Notifications', 'query');
    },
    updateNotification(variables: UpdateNotificationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: UpdateNotificationMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<UpdateNotificationMutation>(UpdateNotificationDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateNotification', 'mutation');
    },
    PaymentIntents(variables?: PaymentIntentsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: PaymentIntentsQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<PaymentIntentsQuery>(PaymentIntentsDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'PaymentIntents', 'query');
    },
    PaymentIntent(variables: PaymentIntentQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: PaymentIntentQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<PaymentIntentQuery>(PaymentIntentDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'PaymentIntent', 'query');
    },
    createPaymentIntent(variables: CreatePaymentIntentMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: CreatePaymentIntentMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<CreatePaymentIntentMutation>(CreatePaymentIntentDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createPaymentIntent', 'mutation');
    },
    updatePaymentIntent(variables: UpdatePaymentIntentMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: UpdatePaymentIntentMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<UpdatePaymentIntentMutation>(UpdatePaymentIntentDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updatePaymentIntent', 'mutation');
    },
    Permissions(variables?: PermissionsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: PermissionsQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<PermissionsQuery>(PermissionsDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Permissions', 'query');
    },
    PermissionGroups(variables?: PermissionGroupsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: PermissionGroupsQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<PermissionGroupsQuery>(PermissionGroupsDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'PermissionGroups', 'query');
    },
    Permit(variables?: PermitQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: PermitQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<PermitQuery>(PermitDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Permit', 'query');
    },
    Permits(variables?: PermitsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: PermitsQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<PermitsQuery>(PermitsDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Permits', 'query');
    },
    createPermit(variables: CreatePermitMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: CreatePermitMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<CreatePermitMutation>(CreatePermitDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createPermit', 'mutation');
    },
    updatePermit(variables: UpdatePermitMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: UpdatePermitMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<UpdatePermitMutation>(UpdatePermitDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updatePermit', 'mutation');
    },
    getPerson(variables?: GetPersonQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: GetPersonQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GetPersonQuery>(GetPersonDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getPerson', 'query');
    },
    getPersonIncludes(variables?: GetPersonIncludesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: GetPersonIncludesQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GetPersonIncludesQuery>(GetPersonIncludesDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getPersonIncludes', 'query');
    },
    updatePerson(variables: UpdatePersonMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: UpdatePersonMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<UpdatePersonMutation>(UpdatePersonDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updatePerson', 'mutation');
    },
    Persons(variables?: PersonsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: PersonsQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<PersonsQuery>(PersonsDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Persons', 'query');
    },
    PersonsIncludes(variables: PersonsIncludesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: PersonsIncludesQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<PersonsIncludesQuery>(PersonsIncludesDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'PersonsIncludes', 'query');
    },
    createPerson(variables: CreatePersonMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: CreatePersonMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<CreatePersonMutation>(CreatePersonDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createPerson', 'mutation');
    },
    handlePublic(variables: HandlePublicMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: HandlePublicMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<HandlePublicMutation>(HandlePublicDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'handlePublic', 'mutation');
    },
    Questions(variables?: QuestionsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: QuestionsQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<QuestionsQuery>(QuestionsDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Questions', 'query');
    },
    Question(variables: QuestionQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: QuestionQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<QuestionQuery>(QuestionDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Question', 'query');
    },
    QuestionIncludes(variables: QuestionIncludesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: QuestionIncludesQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<QuestionIncludesQuery>(QuestionIncludesDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'QuestionIncludes', 'query');
    },
    upsertQuestion(variables?: UpsertQuestionMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: UpsertQuestionMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<UpsertQuestionMutation>(UpsertQuestionDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'upsertQuestion', 'mutation');
    },
    createQuestionGrouping(variables: CreateQuestionGroupingMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: CreateQuestionGroupingMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<CreateQuestionGroupingMutation>(CreateQuestionGroupingDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createQuestionGrouping', 'mutation');
    },
    QuestionGroupings(variables?: QuestionGroupingsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: QuestionGroupingsQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<QuestionGroupingsQuery>(QuestionGroupingsDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'QuestionGroupings', 'query');
    },
    QuestionGrouping(variables?: QuestionGroupingQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: QuestionGroupingQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<QuestionGroupingQuery>(QuestionGroupingDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'QuestionGrouping', 'query');
    },
    updateQuestionGrouping(variables: UpdateQuestionGroupingMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: UpdateQuestionGroupingMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<UpdateQuestionGroupingMutation>(UpdateQuestionGroupingDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateQuestionGrouping', 'mutation');
    },
    QuestionOptions(variables?: QuestionOptionsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: QuestionOptionsQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<QuestionOptionsQuery>(QuestionOptionsDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'QuestionOptions', 'query');
    },
    getReferrerCodes(variables?: GetReferrerCodesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: GetReferrerCodesQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GetReferrerCodesQuery>(GetReferrerCodesDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getReferrerCodes', 'query');
    },
    Referrer(variables?: ReferrerQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: ReferrerQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<ReferrerQuery>(ReferrerDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Referrer', 'query');
    },
    createReferrerCode(variables: CreateReferrerCodeMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: CreateReferrerCodeMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<CreateReferrerCodeMutation>(CreateReferrerCodeDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createReferrerCode', 'mutation');
    },
    upsertReminder(variables: UpsertReminderMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: UpsertReminderMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<UpsertReminderMutation>(UpsertReminderDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'upsertReminder', 'mutation');
    },
    Reminder(variables?: ReminderQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: ReminderQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<ReminderQuery>(ReminderDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Reminder', 'query');
    },
    Representatives(variables?: RepresentativesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: RepresentativesQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<RepresentativesQuery>(RepresentativesDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Representatives', 'query');
    },
    Representative(variables: RepresentativeQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: RepresentativeQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<RepresentativeQuery>(RepresentativeDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Representative', 'query');
    },
    RepresentativeWithApplications(variables: RepresentativeWithApplicationsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: RepresentativeWithApplicationsQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<RepresentativeWithApplicationsQuery>(RepresentativeWithApplicationsDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RepresentativeWithApplications', 'query');
    },
    createRepresentative(variables: CreateRepresentativeMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: CreateRepresentativeMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<CreateRepresentativeMutation>(CreateRepresentativeDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createRepresentative', 'mutation');
    },
    deleteRepresentative(variables: DeleteRepresentativeMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: DeleteRepresentativeMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<DeleteRepresentativeMutation>(DeleteRepresentativeDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteRepresentative', 'mutation');
    },
    getRoleById(variables?: GetRoleByIdQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: GetRoleByIdQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GetRoleByIdQuery>(GetRoleByIdDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getRoleById', 'query');
    },
    getRoles(variables?: GetRolesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: GetRolesQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GetRolesQuery>(GetRolesDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getRoles', 'query');
    },
    createRole(variables: CreateRoleMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: CreateRoleMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<CreateRoleMutation>(CreateRoleDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createRole', 'mutation');
    },
    deleteRole(variables: DeleteRoleMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: DeleteRoleMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<DeleteRoleMutation>(DeleteRoleDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteRole', 'mutation');
    },
    updateRole(variables: UpdateRoleMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: UpdateRoleMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<UpdateRoleMutation>(UpdateRoleDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateRole', 'mutation');
    },
    duplicateRole(variables: DuplicateRoleMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: DuplicateRoleMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<DuplicateRoleMutation>(DuplicateRoleDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'duplicateRole', 'mutation');
    },
    search(variables: SearchQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: SearchQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<SearchQuery>(SearchDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'search', 'query');
    },
    handleSendGrid(variables: HandleSendGridMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: HandleSendGridMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<HandleSendGridMutation>(HandleSendGridDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'handleSendGrid', 'mutation');
    },
    getEmbeddedSignUrl(variables: GetEmbeddedSignUrlQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: GetEmbeddedSignUrlQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GetEmbeddedSignUrlQuery>(GetEmbeddedSignUrlDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getEmbeddedSignUrl', 'query');
    },
    getHelloSignFiles(variables: GetHelloSignFilesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: GetHelloSignFilesQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GetHelloSignFilesQuery>(GetHelloSignFilesDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getHelloSignFiles', 'query');
    },
    handleHelloSignWebhook(variables?: HandleHelloSignWebhookMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: HandleHelloSignWebhookMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<HandleHelloSignWebhookMutation>(HandleHelloSignWebhookDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'handleHelloSignWebhook', 'mutation');
    },
    handleDocuSealWebhook(variables?: HandleDocuSealWebhookMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: HandleDocuSealWebhookMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<HandleDocuSealWebhookMutation>(HandleDocuSealWebhookDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'handleDocuSealWebhook', 'mutation');
    },
    SignatureRequestSigner(variables: SignatureRequestSignerQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: SignatureRequestSignerQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<SignatureRequestSignerQuery>(SignatureRequestSignerDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'SignatureRequestSigner', 'query');
    },
    Status(variables?: StatusQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: StatusQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<StatusQuery>(StatusDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Status', 'query');
    },
    Statuses(variables: StatusesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: StatusesQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<StatusesQuery>(StatusesDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Statuses', 'query');
    },
    updateStatus(variables: UpdateStatusMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: UpdateStatusMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<UpdateStatusMutation>(UpdateStatusDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateStatus', 'mutation');
    },
    createStorageFile(variables: CreateStorageFileMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: CreateStorageFileMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<CreateStorageFileMutation>(CreateStorageFileDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createStorageFile', 'mutation');
    },
    getStorageFileUrl(variables: GetStorageFileUrlQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: GetStorageFileUrlQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GetStorageFileUrlQuery>(GetStorageFileUrlDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getStorageFileUrl', 'query');
    },
    deleteStorageFile(variables: DeleteStorageFileMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: DeleteStorageFileMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<DeleteStorageFileMutation>(DeleteStorageFileDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteStorageFile', 'mutation');
    },
    getStripeCustomer(variables?: GetStripeCustomerQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: GetStripeCustomerQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GetStripeCustomerQuery>(GetStripeCustomerDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getStripeCustomer', 'query');
    },
    getStripeCheckoutUrl(variables: GetStripeCheckoutUrlQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: GetStripeCheckoutUrlQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GetStripeCheckoutUrlQuery>(GetStripeCheckoutUrlDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getStripeCheckoutUrl', 'query');
    },
    getStripePortalUrl(variables?: GetStripePortalUrlQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: GetStripePortalUrlQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GetStripePortalUrlQuery>(GetStripePortalUrlDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getStripePortalUrl', 'query');
    },
    handleStripeWebhook(variables?: HandleStripeWebhookMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: HandleStripeWebhookMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<HandleStripeWebhookMutation>(HandleStripeWebhookDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'handleStripeWebhook', 'mutation');
    },
    adminImpersonateUser(variables: AdminImpersonateUserMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: AdminImpersonateUserMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<AdminImpersonateUserMutation>(AdminImpersonateUserDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'adminImpersonateUser', 'mutation');
    },
    adminDelete(variables: AdminDeleteMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: AdminDeleteMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<AdminDeleteMutation>(AdminDeleteDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'adminDelete', 'mutation');
    },
    adminUpdate(variables: AdminUpdateMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: AdminUpdateMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<AdminUpdateMutation>(AdminUpdateDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'adminUpdate', 'mutation');
    },
    Tags(variables?: TagsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: TagsQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<TagsQuery>(TagsDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Tags', 'query');
    },
    Tasks(variables?: TasksQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: TasksQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<TasksQuery>(TasksDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Tasks', 'query');
    },
    Task(variables?: TaskQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: TaskQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<TaskQuery>(TaskDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Task', 'query');
    },
    TaskAction(variables?: TaskActionQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: TaskActionQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<TaskActionQuery>(TaskActionDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'TaskAction', 'query');
    },
    upsertTask(variables: UpsertTaskMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: UpsertTaskMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<UpsertTaskMutation>(UpsertTaskDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'upsertTask', 'mutation');
    },
    TemplateDocument(variables: TemplateDocumentQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: TemplateDocumentQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<TemplateDocumentQuery>(TemplateDocumentDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'TemplateDocument', 'query');
    },
    TemplateDocuments(variables?: TemplateDocumentsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: TemplateDocumentsQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<TemplateDocumentsQuery>(TemplateDocumentsDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'TemplateDocuments', 'query');
    },
    createTemplateDocument(variables: CreateTemplateDocumentMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: CreateTemplateDocumentMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<CreateTemplateDocumentMutation>(CreateTemplateDocumentDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createTemplateDocument', 'mutation');
    },
    updateTemplateDocument(variables: UpdateTemplateDocumentMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: UpdateTemplateDocumentMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<UpdateTemplateDocumentMutation>(UpdateTemplateDocumentDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateTemplateDocument', 'mutation');
    },
    handleTemplateDocument(variables: HandleTemplateDocumentMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: HandleTemplateDocumentMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<HandleTemplateDocumentMutation>(HandleTemplateDocumentDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'handleTemplateDocument', 'mutation');
    },
    getActiveUser(variables?: GetActiveUserQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: GetActiveUserQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<GetActiveUserQuery>(GetActiveUserDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getActiveUser', 'query');
    },
    Users(variables?: UsersQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: UsersQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<UsersQuery>(UsersDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Users', 'query');
    },
    UserIncludes(variables: UserIncludesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: UserIncludesQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<UserIncludesQuery>(UserIncludesDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UserIncludes', 'query');
    },
    UsersIncludes(variables: UsersIncludesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: UsersIncludesQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<UsersIncludesQuery>(UsersIncludesDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UsersIncludes', 'query');
    },
    PublicUser(variables: PublicUserQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: PublicUserQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<PublicUserQuery>(PublicUserDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'PublicUser', 'query');
    },
    updateUser(variables: UpdateUserMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: UpdateUserMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<UpdateUserMutation>(UpdateUserDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateUser', 'mutation');
    },
    UserExists(variables: UserExistsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: UserExistsQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<UserExistsQuery>(UserExistsDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UserExists', 'query');
    },
    UserPermissions(variables: UserPermissionsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: UserPermissionsQuery; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<UserPermissionsQuery>(UserPermissionsDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UserPermissions', 'query');
    },
    updateUserPermission(variables: UpdateUserPermissionMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: UpdateUserPermissionMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<UpdateUserPermissionMutation>(UpdateUserPermissionDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateUserPermission', 'mutation');
    },
    updateUsersAccess(variables: UpdateUsersAccessMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<{ data: UpdateUsersAccessMutation; extensions?: any; headers: Dom.Headers; status: number; }> {
        return withWrapper((wrappedRequestHeaders) => client.rawRequest<UpdateUsersAccessMutation>(UpdateUsersAccessDocumentString, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateUsersAccess', 'mutation');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;