import { FormManager } from '@visto-tech/forms';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';

export const ErrorDisplay: FC<
  JSX.IntrinsicElements['div'] & {
    formManager: FormManager<any>;
    answerLabel?: string;
  }
> = observer(({ formManager, answerLabel, className }) => {
  if (!formManager.hasErrors()) return null;

  return (
    <>
      {formManager.errors.map((error, i) => {
        const label = error.path;
        const isLast = formManager.errors.length === i + 1;

        if (answerLabel && label !== answerLabel) return null;

        return (
          <div
            className={`text-center bg-red-200 border border-red-400 rounded-md p-2 ${
              isLast ? '' : 'mb-2'
            } ${className ? className : 'mb-3'}`}
            key={`error-${error.name}-${i}`}
          >
            <p className="fs14 text-black font-semibold">{error.message}</p>
          </div>
        );
      })}
    </>
  );
});

export default ErrorDisplay;
