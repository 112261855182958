import { FC } from 'react';

interface ProfileListItemProps {
  show?: boolean;
  size?: number;
}

export const ProfileListItem: FC<
  ProfileListItemProps & JSX.IntrinsicElements['div']
> = ({ show = true, size = 3, className }) => {
  if (!show) return null;

  return (
    <div className={`animate-pulse ${className}`}>
      {Array(size)
        .fill(0)
        .map((_, i: number) => (
          <div
            key={i}
            className="border border-gray-300 rounded-md p-4 w-full mb-3"
          >
            <div className="flex space-x-4">
              <div className="flex-1 space-y-6 py-1">
                <div className="flex items-center justify-center">
                  <div className="w-10 h-10 min-w-10 rounded-full bg-gray-300"></div>
                  <div className="w-full ml-4">
                    <div className="h-2 mb-4 bg-gray-300 rounded"></div>
                    <div className="h-2 bg-gray-300 rounded"></div>
                  </div>
                </div>
                <div className="space-y-3">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 bg-gray-300 rounded col-span-2"></div>
                    <div className="h-2 bg-gray-300 rounded col-span-1"></div>
                  </div>
                  <div className="h-2 bg-gray-300 rounded"></div>
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

ProfileListItem.displayName = 'ProfileListItem';

export default ProfileListItem;
