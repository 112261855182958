import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

export const useUniqueId = (name: string) => {
  const [outPutUniqueId, setOutPutUniqueId] = useState(`uid-${name}`);
  useEffect(() => {
    if (typeof window === 'undefined') return;
    setOutPutUniqueId(`${uuidv4()}-${name}`);
  }, [name]);
  return outPutUniqueId;
};
