import { QueryGetStripeCheckoutUrlArgs } from 'generated/graphql';
import logger from 'js-logger';

import backendClient from '../backend';

export class Stripe {
  public static async getStripeCustomer() {
    try {
      return (await backendClient.getStripeCustomer()).data?.getStripeCustomer;
    } catch (err) {
      logger.error(err);
    }
  }

  public static async getStripeCheckoutUrl(
    args: QueryGetStripeCheckoutUrlArgs
  ) {
    try {
      return (await backendClient.getStripeCheckoutUrl(args)).data
        ?.getStripeCheckoutUrl;
    } catch (err) {
      logger.error(err);
    }
  }

  public static async getStripePortalUrl() {
    try {
      return (await backendClient.getStripePortalUrl()).data
        ?.getStripePortalUrl;
    } catch (err) {
      logger.error(err);
    }
  }
}
