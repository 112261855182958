import { ToggleExpandArrows } from 'elements/Icon/ToggleExpandedArrows';
import { Text } from 'elements/Text';
import TooltipWithIcon from 'elements/Tooltip/TooltipWithIcon';
import React, { FC, ReactNode } from 'react';
import * as Icons from 'react-feather';

const SIZES = {
  default: 'fs16 font-bold',
  medium: 'fs14 font-semibold',
  large: 'fs18 font-bold',
};

export const BlockHeader: FC<
  {
    setIsHidden?: (isHidden: boolean) => void;
    isHidden?: boolean;
    label?: string | null;
    menu?: ReactNode;
    cta?: ReactNode;
    tip?: string;
    subheading?: string;
    size?: keyof typeof SIZES;
    icon?: {
      icon?: keyof typeof Icons;
      props?: Icons.IconProps;
    };
  } & JSX.IntrinsicElements['div']
> = ({
  setIsHidden,
  isHidden,
  label,
  children,
  className,
  menu,
  tip,
  cta,
  subheading,
  size = 'default',
  icon,
  id,
}) => {
  const menuNode = <Menu Menu={menu} />;
  const ctaNode = <Cta Cta={cta} />;

  let Icon = null;

  if (icon?.icon) Icon = Icons[icon?.icon];

  return (
    <div
      id={id}
      className={`md:flex md:justify-between md:items-center relative w-full ${className}`}
    >
      <div className="mr-2 w-full md:w-auto">
        {label && (
          <Text.Paragraph className="fs14 font-light">{label}</Text.Paragraph>
        )}
        <div className="flex items-center justify-between w-full md:w-auto">
          <div className="flex items-center">
            {Icon && (
              <Icon
                {...icon?.props}
                size={icon?.props?.size ? icon?.props?.size : 18}
                className={`mr-2 ${icon?.props?.className}`}
              />
            )}
            <Text.Paragraph className={SIZES[size]}>{children}</Text.Paragraph>
          </div>
          <div className="md:hidden flex items-center">
            {menu && <div className="mr-3">{menuNode}</div>}
            {tip && <TooltipWithIcon>{tip}</TooltipWithIcon>}
            {setIsHidden && (isHidden === false || isHidden === true) && (
              <ToggleExpandArrows
                isHidden={isHidden}
                setIsHidden={setIsHidden}
                className="-mr-1.5 ml-2"
              />
            )}
          </div>
        </div>
        {subheading && (
          <Text.Paragraph className="fs14 mt-0.5">{subheading}</Text.Paragraph>
        )}
      </div>
      <div className="flex items-center w-full md:w-auto">
        {cta && <div className="mr-3">{ctaNode}</div>}
        <div className="hidden md:flex items-center">
          {menu && <div className="mr-3">{menuNode}</div>}
          {tip && <TooltipWithIcon>{tip}</TooltipWithIcon>}
          {setIsHidden && (isHidden === false || isHidden === true) && (
            <ToggleExpandArrows
              className="ml-2"
              isHidden={isHidden}
              setIsHidden={setIsHidden}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const Menu: FC<{ Menu: ReactNode } & JSX.IntrinsicElements['div']> = ({
  Menu,
  className,
}) => {
  if (!Menu) return null;

  return <div className={className}>{Menu}</div>;
};

const Cta: FC<{ Cta: ReactNode } & JSX.IntrinsicElements['div']> = ({
  Cta,
  className,
}) => {
  if (!Cta) return null;

  return <div className={className}>{Cta}</div>;
};
