import { PriceFrequency } from 'components/Stripe/Pricing/PricingTable';

export const PLAN_DISPLAY_NAME = {
  SOLO: 'Solo',
  GROWING: 'Growing',
  ESTABLISHED: 'Established',
  COPILOT_DRAFT: 'Copilot Chat + Document Drafting',
};

/*******
 * B2C *
 *******/
export const STRIPE_PRODUCT_ID_MAP = {
  dev: {
    STUDY_APP: process.env.NEXT_PUBLIC_STRIPE_PRODUCT || 'prod_MjWbKyxmVFsgI0',
  },
  stg: {
    STUDY_APP: 'prod_MjWbKyxmVFsgI0',
  },
  prod: {
    STUDY_APP: 'prod_NBelBVXpXkcqI0',
  },
};

/*******
 * B2B *
 *******/
// Dev
const B2B_STRIPE_PRICES_DEV_MONTH = {
  SOLO: 'price_1P8SydBeOsymrB7VLIh2dFZK',
  GROWING: 'price_1P8ABbBeOsymrB7V58EBh2nA',
  ESTABLISHED: 'price_1P8ACqBeOsymrB7Vrhm6hM1c',
};

const B2B_STRIPE_PRICES_DEV_6MONTH = {
  SOLO: 'price_1P8Sz6BeOsymrB7VLJBBrKPS',
  GROWING: 'price_1P8ACBBeOsymrB7VsXKzmXro',
  ESTABLISHED: 'price_1P8ADYBeOsymrB7VPL1fv1H9',
};

const B2B_STRIPE_PRICES_DEV_YEAR = {
  SOLO: 'price_1P8SzQBeOsymrB7VJ2Cluj0v',
  GROWING: 'price_1P8ACMBeOsymrB7VEdixPFfH',
  ESTABLISHED: 'price_1P8ADmBeOsymrB7VtOh3dseA',
};

// Prod
const B2B_STRIPE_PRICES_PROD_MONTH = {
  SOLO: 'price_1PCO8PBeOsymrB7VTrE1g7KB',
  GROWING: 'price_1PCO93BeOsymrB7VgCJ7oYZh',
  ESTABLISHED: 'price_1PCO8eBeOsymrB7VQKNEdN5q',
};

const B2B_STRIPE_PRICES_PROD_6MONTH = {
  SOLO: 'price_1PCO8OBeOsymrB7Vxdkei3bh',
  GROWING: 'price_1PCO93BeOsymrB7VYSWUqBEl',
  ESTABLISHED: 'price_1PCO8eBeOsymrB7VIUfHrVua',
};

const B2B_STRIPE_PRICES_PROD_YEAR = {
  SOLO: 'price_1PCO8OBeOsymrB7VEwJQ253a',
  GROWING: 'price_1PCO93BeOsymrB7V6wAT6yF7',
  ESTABLISHED: 'price_1PCO8eBeOsymrB7V6psBlfMD',
};

export const STRIPE_B2B_PRICE_ID_MAP = {
  dev: {
    [PriceFrequency.MONTHLY]: {
      solo: B2B_STRIPE_PRICES_DEV_MONTH.SOLO,
      growing: B2B_STRIPE_PRICES_DEV_MONTH.GROWING,
      established: B2B_STRIPE_PRICES_DEV_MONTH.ESTABLISHED,
    },
    [PriceFrequency.BIYEARLY]: {
      solo: B2B_STRIPE_PRICES_DEV_6MONTH.SOLO,
      growing: B2B_STRIPE_PRICES_DEV_6MONTH.GROWING,
      established: B2B_STRIPE_PRICES_DEV_6MONTH.ESTABLISHED,
    },
    [PriceFrequency.YEARLY]: {
      solo: B2B_STRIPE_PRICES_DEV_YEAR.SOLO,
      growing: B2B_STRIPE_PRICES_DEV_YEAR.GROWING,
      established: B2B_STRIPE_PRICES_DEV_YEAR.ESTABLISHED,
    },
  },
  stg: {
    [PriceFrequency.MONTHLY]: {
      solo: B2B_STRIPE_PRICES_DEV_MONTH.SOLO,
      growing: B2B_STRIPE_PRICES_DEV_MONTH.GROWING,
      established: B2B_STRIPE_PRICES_DEV_MONTH.ESTABLISHED,
    },
    [PriceFrequency.BIYEARLY]: {
      solo: B2B_STRIPE_PRICES_DEV_6MONTH.SOLO,
      growing: B2B_STRIPE_PRICES_DEV_6MONTH.GROWING,
      established: B2B_STRIPE_PRICES_DEV_6MONTH.ESTABLISHED,
    },
    [PriceFrequency.YEARLY]: {
      solo: B2B_STRIPE_PRICES_DEV_YEAR.SOLO,
      growing: B2B_STRIPE_PRICES_DEV_YEAR.GROWING,
      established: B2B_STRIPE_PRICES_DEV_YEAR.ESTABLISHED,
    },
  },
  prod: {
    [PriceFrequency.MONTHLY]: {
      solo: B2B_STRIPE_PRICES_PROD_MONTH.SOLO,
      growing: B2B_STRIPE_PRICES_PROD_MONTH.GROWING,
      established: B2B_STRIPE_PRICES_PROD_MONTH.ESTABLISHED,
    },
    [PriceFrequency.BIYEARLY]: {
      solo: B2B_STRIPE_PRICES_PROD_6MONTH.SOLO,
      growing: B2B_STRIPE_PRICES_PROD_6MONTH.GROWING,
      established: B2B_STRIPE_PRICES_PROD_6MONTH.ESTABLISHED,
    },
    [PriceFrequency.YEARLY]: {
      solo: B2B_STRIPE_PRICES_PROD_YEAR.SOLO,
      growing: B2B_STRIPE_PRICES_PROD_YEAR.GROWING,
      established: B2B_STRIPE_PRICES_PROD_YEAR.ESTABLISHED,
    },
  },
};

/***********
 * COPILOT *
 ***********/
export const COPILOT_STRIPE_PRICES = {
  chat: {
    dev: 'price_1PaRWLBeOsymrB7V631tGXRV',
    stg: 'price_1PaRWLBeOsymrB7V631tGXRV',
    prod: 'price_1Pb349BeOsymrB7VXvb4v00y',
  },
  draft: {
    dev: 'price_1PdvhVBeOsymrB7Vrin4jr8g',
    stg: 'price_1PdvhVBeOsymrB7Vrin4jr8g',
    prod: 'price_1PdyFqBeOsymrB7V1B0Y185e',
  },
};
