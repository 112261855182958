/* eslint-disable @typescript-eslint/no-empty-function */
import React, { Dispatch, SetStateAction } from 'react';

import { User } from './User';

type ActiveUserContextInput = {
  user: null | User;
  setUser: Dispatch<SetStateAction<User | null>>;
};

export const ActiveUserContext = React.createContext<ActiveUserContextInput>({
  user: null,
  setUser: () => {},
});
