import { useActiveUser } from 'hooks/useActiveUser';
import { observer } from 'mobx-react-lite';
import ErrorPage from 'next/error';
import React from 'react';

import CompanyGettingStarted from './CompanyGettingStarted';
import LawyerGettingStarted from './LawyerGettingStarted';

export const GettingStartedRouter: React.FC = observer(() => {
  const user = useActiveUser();

  if (user?.isType('COMPANY')) return <CompanyGettingStarted />;
  if (user?.isType('LAWYER')) return <LawyerGettingStarted />;

  return <ErrorPage statusCode={404} />;
});

GettingStartedRouter.displayName = 'GettingStartedRouter';
