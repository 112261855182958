import { createClient, User } from '@supabase/supabase-js';
import { MfaLevel } from 'generated/graphql';
import jwt_decode from 'jwt-decode';
import { capitalize } from 'lodash';

const SUPABASE_PUBLIC_KEY = process.env.NEXT_PUBLIC_SUPABASE_PUBLIC_KEY;
const SUPABASE_URL = process.env.NEXT_PUBLIC_SUPABASE_URL;

export const SUPABASE_COOKIE_NAME = 'sb:token';
export const SUPABASE_COOKIE_REFRESH_NAME = 'sb:refresh_token';

export const getSupbaseProjectId = (): string | null => {
  const match = SUPABASE_URL?.match(/\/\/(.*?)\./);
  return match ? match[1] : null;
};

export const supabase = createClient(
  SUPABASE_URL as string,
  SUPABASE_PUBLIC_KEY as string
);

// levels description: https://supabase.com/docs/guides/auth/auth-mfa#add-challenge-step-to-login
export type MFALevelsType = {
  currentLevel: MfaLevel.Aal1 | MfaLevel.Aal2;
  nextLevel: MfaLevel.Aal1 | MfaLevel.Aal2;
  is: {
    userNotEnroll: boolean;
    userEnrolledNotVerified: boolean;
    userVerified: boolean;
    userDisabled: boolean;
  };
};

export const getSupabaseClientWithAuthToken = (accessToken: string) => {
  return createClient(SUPABASE_URL as string, SUPABASE_PUBLIC_KEY as string, {
    global: {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  });
};

// taken from https://github.com/supabase/gotrue-js/blob/958d94811fba9ef2ff23e278c85974cb909802da/src/GoTrueClient.ts#L1321
export const getMFALevels = async ({
  token,
  supabaseUser,
}: {
  token: string;
  supabaseUser?: User | null;
}) => {
  const decodeToken: any = jwt_decode(token);

  const currentLevel =
    MfaLevel[capitalize(decodeToken.aal) as keyof typeof MfaLevel];

  let nextLevel = currentLevel;

  if (!supabaseUser) {
    const data = await supabase.auth.getUser(token);

    if (data?.data?.user) {
      supabaseUser = data.data.user;
    }
  }

  const verifiedFactors =
    supabaseUser?.factors?.filter(
      (factor: any) => factor.status === 'verified'
    ) ?? [];

  if (verifiedFactors.length > 0) {
    nextLevel = MfaLevel.Aal2;
  }

  return {
    currentLevel,
    nextLevel,
    is: {
      userNotEnroll: nextLevel === currentLevel && nextLevel === MfaLevel.Aal1,
      userEnrolledNotVerified:
        currentLevel === MfaLevel.Aal1 && nextLevel === MfaLevel.Aal2,
      userVerified: nextLevel === currentLevel && nextLevel === MfaLevel.Aal2,
      userDisabled:
        currentLevel === MfaLevel.Aal2 && nextLevel === MfaLevel.Aal1,
    },
  } as MFALevelsType;
};
