import 'react-datepicker/dist/react-datepicker.css';

import { FormManager } from '@visto-tech/forms';
import { parseISO } from 'date-fns';
import Text from 'elements/Text';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Calendar } from 'react-feather';
import { formatDateForForms } from 'utils/helpers';

interface DatePickerInputPropTypes {
  label: string;
  formManager?: FormManager<any>;
  tooltip?: string;
  onChange?: (args: any) => void; // Used for when DatePicker is not in a FormManager
  initValue?: string; // Used for when DatePicker is not in a FormManager
  dateProps?: { name?: string; groupId?: number }; // Used for when DatePicker is not in a FormManager
  inputError?: boolean | string; // Used for when DatePicker is not in a FormManager
}

export const DatePickerInputSecondary: FC<
  DatePickerInputPropTypes & JSX.IntrinsicElements['div']
> = observer(
  ({
    label,
    formManager,
    tooltip,
    onChange,
    initValue,
    dateProps,
    children,
    inputError = false,
    className,
  }) => {
    const ref: React.Ref<any> = useRef(null);

    let initialValue = initValue ? initValue : null;

    if (!initialValue) {
      initialValue = formManager?.formData[label]
        ? formManager?.formData[label]
        : null;
    }

    const [value, setValue] = useState<Date | null>(
      initialValue ? new Date(parseISO(initialValue)) : null
    );
    const [error, setError] = useState<boolean | string>(inputError);

    const handleChange = (newValue: Date | null) => {
      onChange && onChange({ date: newValue, ...dateProps });

      setError(false);

      formManager &&
        formManager.setFormData(label, formatDateForForms(newValue));

      setValue(newValue);
    };

    useEffect(() => {
      formManager?.errors.map((err: any) => {
        if (err.path === label) setError(err.message);
      });

      if (inputError) setError(inputError);
    }, [formManager?.errors, label, inputError]);

    return (
      <div className={className}>
        <div
          className={`rounded-md relative border w-full group bg-white ${
            error
              ? 'border-red-600 hover:border-red-800'
              : 'border-gray-300 hover:border-gray-500'
          }`}
        >
          {children && (
            <div className="pt-2 pl-2 pr-2">
              <label
                htmlFor={label}
                className="font-semibold fs14 z-20 w-full cursor-pointer rounded-md"
              >
                {children}
              </label>
              <span className="block fs12 font-light mt-1 translate">
                {tooltip}
              </span>
            </div>
          )}
          <div className="flex items-center px-2 mb-1 notranslate">
            <Calendar
              size={20}
              className="text-gray-500 mr-2 cursor-pointer"
              onClick={() =>
                ref?.current?.handleFocus && ref.current.handleFocus()
              }
            />
            <DatePicker
              className="border-0 fs14 mt-1 w-full px-1 py-1 cursor-pointer"
              selected={value}
              onChange={handleChange}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              placeholderText="Click or enter date (yyyy-mm-dd)"
              strictParsing
              dateFormat="yyyy-MM-dd"
              showPopperArrow={false}
              name={label}
              ref={ref}
              onKeyDown={(e) => {
                if (
                  e.key === 'Backspace' ||
                  e.key === 'Delete' ||
                  e.key === 'ArrowRight' ||
                  e.key === 'ArrowLeft'
                ) {
                  return true;
                }

                // Allow numbers and dashes only
                const rgx = /^[0-9]*-?[0-9]*$/;
                if (!rgx.test(e.key)) {
                  e.preventDefault();
                  return false;
                }
              }}
            />
          </div>
        </div>
        {error && (
          <Text.Paragraph className="mt-1 text-red-600 fs12">
            {error}
          </Text.Paragraph>
        )}
      </div>
    );
  }
);
