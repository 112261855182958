/* eslint-disable no-console */
import { attempt, head, isError } from 'lodash';
import { makeAutoObservable } from 'mobx';
import logger from 'utils/logger';

import backendClient from '../backend';
import {
  Answer,
  FormSubmission as RawFormSubmission,
  FormSubmissionGetAction,
  FormSubmissionGetData,
  SubmitFormMutationArgs,
  SubmitFormMutationInput,
} from '../generated/graphql';

export class FormSubmission implements RawFormSubmission {
  id: number;
  answers: Answer[];
  createdAt: any;
  status: string;
  version: number;
  associations: string;
  form: any;

  constructor(formSubmission: RawFormSubmission) {
    this.id = formSubmission.id;
    this.answers = formSubmission.answers;
    this.createdAt = formSubmission.createdAt;
    this.status = formSubmission.status;
    this.version = formSubmission.version;
    this.associations = formSubmission.associations;
    this.form = formSubmission.form;

    makeAutoObservable(this);
  }

  parsedAssociations() {
    const result = attempt(() => JSON.parse(this.associations));
    if (isError(result)) {
      console.error(result);
      return null;
    }
    return result;
  }

  static async create(
    formSubmission: SubmitFormMutationInput,
    targetAccountId?: number,
    args?: SubmitFormMutationArgs
  ) {
    return await backendClient.submitForm({
      input: formSubmission,
      targetAccountId,
      args,
    });
  }

  static async createLeads(
    formSubmission: SubmitFormMutationInput,
    leadEmbedId: string,
    recaptchaToken: string
  ) {
    return await backendClient.submitLeadsForm({
      input: formSubmission,
      leadEmbedId,
      recaptchaToken,
    });
  }

  static async findMostRecent(data: FormSubmissionGetData) {
    try {
      return head(
        (
          await backendClient.FormSubmissions({
            action: FormSubmissionGetAction.MostRecent,
            data,
          })
        ).data?.FormSubmissions
      );
    } catch (err) {
      logger.error(err);
    }
  }
}

export default FormSubmission;
