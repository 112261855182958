/* eslint-disable sonarjs/no-identical-functions */
import { DropdownWithLabel, FormManager } from '@visto-tech/forms';
import { InputWithLabel } from '@visto-tech/forms';
import {
  countryResidenceList,
  provincesList,
  usaStatesList,
} from '@visto-tech/seed';
import { Button } from 'elements/Button';
import { DatePickerInputSecondary } from 'elements/Forms/DatePickerInput/DatePickerInputSecondary';
import Loading from 'elements/Loading';
import { Text } from 'elements/Text';
import { useQuestionRepeater } from 'hooks/useQuestionRepeater';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { Plus } from 'react-feather';
import { EmploymentRepeater } from 'types/QuestionRepeaterManagerTypes';

interface EmploymentInformationRepeaterPropTypes {
  formManager: FormManager<any>;
  label: string;
}

export const EmploymentInformationRepeater: FC<EmploymentInformationRepeaterPropTypes> =
  observer(({ formManager, label, children }) => {
    const initial = formManager._initialData[label];

    const { questionManager } = useQuestionRepeater({
      initialData: initial ? initial : [],
      structure: {
        occupation: '',
        companyName: '',
        city: '',
        country: '',
        stateProvince: '',
        startDate: '',
        endDate: '',
      },
      formManager: formManager,
      label,
    });

    if (!questionManager) return <Loading.Default />;

    formManager.formData[label] = questionManager.stringify();

    return (
      <div className="w-full">
        <Text.Paragraph className="fs14 font-semibold mb-2">
          {children}
        </Text.Paragraph>
        {questionManager.state.length === 0 && (
          <div className="bg-gray-100 p-6 mb-3 rounded-lg flex justify-center">
            <Button
              type="button"
              onClick={() => {
                questionManager.add();
              }}
            >
              <Plus className="mr-2" size={18} /> Add First Job
            </Button>
          </div>
        )}
        {questionManager
          .getState()
          .map((employment: EmploymentRepeater, i: number) => {
            return (
              <div key={i} className="bg-gray-100 p-3 mb-3 rounded-lg">
                <div className="w-full flex justify-between items-start mb-1">
                  <Text.Paragraph className="fs14 font-semibold mb-2">
                    Employment History #{i + 1}
                  </Text.Paragraph>
                  <button
                    className="fs12 text-right text-gray-400 hover:text-gray-800"
                    type="button"
                    onClick={() => questionManager.delete(i)}
                  >
                    Delete
                  </button>
                </div>
                <div className="mb-3">
                  <InputWithLabel
                    key={`id-occupation-${i}-input-${label}`}
                    id={`id-occupation-${i}-input-${label}`}
                    name="occupation"
                    value={employment.occupation.value}
                    error={questionManager.hasError(
                      'occupation',
                      i,
                      formManager
                    )}
                    errorMessage={questionManager.errorMessage(
                      'occupation',
                      i,
                      formManager
                    )}
                    formFieldSetProps={{
                      className: 'w-full',
                    }}
                    inputProps={
                      {
                        'data-group': i,
                        onChange: questionManager.handleOnChange,
                        type: 'text',
                      } as any
                    }
                  >
                    Title or role or occupation
                  </InputWithLabel>
                </div>
                <div className="mb-3">
                  <InputWithLabel
                    key={`id-companyName-${i}-input-${label}`}
                    id={`id-companyName-${i}-input-${label}`}
                    name="companyName"
                    value={employment.companyName.value}
                    error={questionManager.hasError(
                      'companyName',
                      i,
                      formManager
                    )}
                    errorMessage={questionManager.errorMessage(
                      'companyName',
                      i,
                      formManager
                    )}
                    formFieldSetProps={{
                      className: 'w-full',
                    }}
                    inputProps={
                      {
                        'data-group': i,
                        onChange: questionManager.handleOnChange,
                        type: 'text',
                      } as any
                    }
                  >
                    Company Name
                  </InputWithLabel>
                </div>
                <div className="mb-3">
                  <InputWithLabel
                    key={`id-city-${i}-input-${label}`}
                    id={`id-city-${i}-input-${label}`}
                    name="city"
                    value={employment.city.value}
                    error={questionManager.hasError('city', i, formManager)}
                    errorMessage={questionManager.errorMessage(
                      'city',
                      i,
                      formManager
                    )}
                    formFieldSetProps={{
                      className: 'w-full',
                    }}
                    inputProps={
                      {
                        'data-group': i,
                        onChange: questionManager.handleOnChange,
                        type: 'text',
                      } as any
                    }
                  >
                    City
                  </InputWithLabel>
                </div>
                <div className="mb-3">
                  <DropdownWithLabel
                    key={`id-country-${i}-select-${label}`}
                    id={`id-country-${i}-select-${label}`}
                    name="country"
                    value={employment.country.value ?? ''}
                    error={questionManager.hasError('country', i, formManager)}
                    errorMessage={questionManager.errorMessage(
                      'country',
                      i,
                      formManager
                    )}
                    dropdownProps={
                      {
                        'data-group': i,
                        onChange: questionManager.handleOnChange,
                        children: [
                          <option key={`initial-empty-value`} value="" disabled>
                            -
                          </option>,
                          ...countryResidenceList.map((q, index) => (
                            <option key={`opt-${index}`} value={q.label}>
                              {q.label}
                            </option>
                          )),
                        ],
                      } as any
                    }
                  >
                    Country
                  </DropdownWithLabel>
                </div>
                {!['Canada', 'United States of America'].includes(
                  employment.country.value
                ) && (
                  <div className="mb-3">
                    <InputWithLabel
                      key={`id-stateProvince-${i}-input-${label}`}
                      id={`id-stateProvince-${i}-input-${label}`}
                      name="stateProvince"
                      value={employment.stateProvince.value}
                      error={questionManager.hasError(
                        'stateProvince',
                        i,
                        formManager
                      )}
                      errorMessage={questionManager.errorMessage(
                        'stateProvince',
                        i,
                        formManager
                      )}
                      formFieldSetProps={{
                        className: 'w-full',
                      }}
                      inputProps={
                        {
                          'data-group': i,
                          onChange: questionManager.handleOnChange,
                          type: 'text',
                        } as any
                      }
                    >
                      State or Province
                    </InputWithLabel>
                  </div>
                )}
                {employment.country.value === 'Canada' && (
                  <div className="mb-3">
                    <DropdownWithLabel
                      key={`id-stateProvince-${i}-select-${label}`}
                      id={`id-stateProvince-${i}-select-${label}`}
                      name="stateProvince"
                      value={employment.stateProvince.value ?? ''}
                      error={questionManager.hasError(
                        'stateProvince',
                        i,
                        formManager
                      )}
                      errorMessage={questionManager.errorMessage(
                        'stateProvince',
                        i,
                        formManager
                      )}
                      dropdownProps={
                        {
                          'data-group': i,
                          onChange: questionManager.handleOnChange,
                          children: [
                            <option
                              key={`initial-empty-value`}
                              value=""
                              disabled
                            >
                              Select province
                            </option>,
                            ...provincesList.map((q, index) => (
                              <option key={`opt-${index}`} value={q.label}>
                                {q.label}
                              </option>
                            )),
                          ],
                        } as any
                      }
                    >
                      Province
                    </DropdownWithLabel>
                  </div>
                )}
                {employment.country.value === 'United States of America' && (
                  <div className="mb-3">
                    <DropdownWithLabel
                      key={`id-stateProvince-${i}-select-${label}`}
                      id={`id-stateProvince-${i}-select-${label}`}
                      name="stateProvince"
                      value={employment.stateProvince.value ?? ''}
                      error={questionManager.hasError(
                        'stateProvince',
                        i,
                        formManager
                      )}
                      errorMessage={questionManager.errorMessage(
                        'stateProvince',
                        i,
                        formManager
                      )}
                      dropdownProps={
                        {
                          'data-group': i,
                          onChange: questionManager.handleOnChange,
                          children: [
                            <option
                              key={`initial-empty-value`}
                              value=""
                              disabled
                            >
                              Select state
                            </option>,
                            ...usaStatesList.map((q, index) => (
                              <option key={`opt-${index}`} value={q.label}>
                                {q.label}
                              </option>
                            )),
                          ],
                        } as any
                      }
                    >
                      State
                    </DropdownWithLabel>
                  </div>
                )}
                <div className="mb-3">
                  <DatePickerInputSecondary
                    key={`id-startDate-${i}-select-${label}`}
                    label="startDate"
                    initValue={employment.startDate.value}
                    onChange={questionManager.handleOnChange}
                    inputError={questionManager.errorMessage(
                      'startDate',
                      i,
                      formManager
                    )}
                    dateProps={{
                      name: 'startDate',
                      groupId: i,
                    }}
                  >
                    Start Date
                  </DatePickerInputSecondary>
                </div>
                <div className="mb-3">
                  <DatePickerInputSecondary
                    key={`id-endDate-${i}-select-${label}`}
                    label="endDate"
                    initValue={employment.endDate.value}
                    onChange={questionManager.handleOnChange}
                    tooltip="Leave empty if currently working there"
                    inputError={questionManager.errorMessage(
                      'endDate',
                      i,
                      formManager
                    )}
                    dateProps={{
                      name: 'endDate',
                      groupId: i,
                    }}
                  >
                    End Date
                  </DatePickerInputSecondary>
                </div>
              </div>
            );
          })}

        {questionManager.state.length > 0 && (
          <div className="flex justify-end mt-3">
            <Button
              type="button"
              format="outline"
              onClick={() => {
                questionManager.add();
              }}
            >
              Add New Job
            </Button>
          </div>
        )}
      </div>
    );
  });
