import { Menu, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';

import NavigationItem from '../../../elements/NavigationItem/NavigationItem';
import UserImageRound from '../../../elements/UserProfile/UserImageRound';
import { useActiveUser } from '../../../hooks/useActiveUser';
import { userNavigation } from '../../../navigation_config';
import { classNames } from './MainLayout';

export const UserDropdownMenu = () => {
  const user = useActiveUser();

  return (
    <Menu as="div" className="relative">
      {({ open }) => (
        <>
          <div className="flex">
            <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-neutral-200  justify-center">
              <span className="sr-only">Open user menu</span>
              {user && (
                <>
                  <UserImageRound />
                </>
              )}
            </Menu.Button>
          </div>
          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="origin-top absolute mt-2 w-44 right-0 rounded-md border border-visto-gray p-3 bg-white focus:outline-none z-60"
            >
              {userNavigation.map((item) => (
                <Menu.Item key={item.name}>
                  {({ active }) => (
                    <NavigationItem
                      className={classNames(
                        active ? 'bg-gray-100' : '',
                        'block p-2 text-sm justify-center'
                      )}
                      classNames={{
                        menuItem: '!ml-0 text-center',
                      }}
                      icon={null}
                      {...item}
                    />
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};
