import { Transition } from '@headlessui/react';
import Text from 'elements/Text';
import { FC } from 'react';

export const FullScreenLoading: FC<
  {
    show: boolean;
    message?: string | null;
    type?: 'airplane' | 'logo';
  } & JSX.IntrinsicElements['div']
> = ({ show, message, className, type = 'airplane' }) => {
  let Image = (
    <img
      src="https://visto-prod-app-files.s3.amazonaws.com/static/airplane_loader.gif"
      alt="Loading"
    />
  );

  if (type === 'logo') {
    Image = (
      <img
        src="/visto-logo-text-black.png"
        className="w-[200px] mx-auto opacity-20 animate-pulse"
        alt="Loading"
      />
    );
  }

  return (
    <Transition
      show={show}
      enter="transition-opacity duration-75"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        className={`fixed left-0 h-full right-0 bottom-0 flex justify-center items-center z-50 pointer-events-none ${
          className ?? ''
        }`}
        style={{ backgroundColor: '#f4f4f4' }}
      >
        <div>
          {message && (
            <Text.Paragraph className="text-center fs16 lg:fs21 font-semibold mt-4 max-w-2xl">
              {message}
            </Text.Paragraph>
          )}
          {Image}
        </div>
      </div>
    </Transition>
  );
};
