/* eslint-disable sonarjs/cognitive-complexity */
import { faker } from '@faker-js/faker';
import { FormManager } from '@visto-tech/forms';
import Form from 'dataLayer/Form';
import { QuestionInputType } from 'generated/graphql';
import { useActiveUser } from 'hooks/useActiveUser';
import { get, isEmpty } from 'lodash';
import { toJS } from 'mobx';
import React, { FC } from 'react';
import toast from 'react-hot-toast';
import { formatDateForForms } from 'utils/helpers';

interface DynamicFormFakerPropTypes {
  formManager: FormManager<any>;
  form: Form | null;
}

export const DynamicFormFaker: FC<
  DynamicFormFakerPropTypes & JSX.IntrinsicElements['div']
> = ({ formManager, form, className }) => {
  const user = useActiveUser();

  if (!user?.isVistoUser()) {
    return null;
  }

  const handleFillInFakerData = (e: any) => {
    e.preventDefault();

    const formTo = toJS(form);
    const formData = toJS(formManager?.formData);

    if (!formTo) {
      return null;
    }

    formTo.content.map((content: any) => {
      content.questions.map((question: any) => {
        const hasConditions = !isEmpty(
          question.conditionsRequiredToRenderThisQuestion
        );
        const inputType = question.inputType;
        const questionLabel = question.label;
        const questionText = question.question.toLowerCase();
        const questionOptions = question.questionOptions;
        const hasAnswer = get(formData, questionLabel) !== '';
        const allowedConditionalQuestions = [
          'FM_19',
          'FM_20',
          'FM_21',
          'FM_29',
          'FM_30',
          'FM_31',
        ];
        const isConditionalAllowed =
          !hasConditions && allowedConditionalQuestions.includes(questionLabel);

        const skipQuestion =
          hasAnswer ||
          isConditionalAllowed ||
          !questionLabel ||
          !inputType ||
          !questionText;

        if (skipQuestion) {
          return null;
        }

        if (inputType === QuestionInputType.Text) {
          const is = {
            firstName:
              questionText.includes('name') &&
              (questionText.includes('first') ||
                questionText.includes('given')),
            lastName:
              questionText.includes('name') &&
              (questionText.includes('last') ||
                questionText.includes('family')),
            fullName:
              questionText.includes('name') && questionText.includes('full'),
            city:
              questionText.includes('city') ||
              questionText.includes('district'),
            province:
              questionText.includes('province') ||
              questionText.includes('state'),
            postal:
              questionText.includes('postal') || questionText.includes('zip'),
          };

          let value = faker.lorem.word();

          if (is.firstName) {
            value = faker.name.firstName();
          }

          if (is.lastName) {
            value = faker.name.lastName();
          }

          if (is.fullName) {
            value = `${faker.name.firstName()} ${faker.name.lastName()}`;
          }

          if (is.city) {
            value = faker.address.cityName();
          }

          if (is.province) {
            value = faker.address.state();
          }

          if (is.postal) {
            value = faker.address.zipCode();
          }

          formManager?.setFormData(questionLabel, value);
          return null;
        }

        if (inputType === QuestionInputType.Textarea) {
          formManager?.setFormData(questionLabel, faker.lorem.sentence(7));
          return null;
        }

        if (inputType === QuestionInputType.Number) {
          const is = {
            phone: questionText.includes('phone'),
          };

          let value = faker.random.numeric(5);

          if (is.phone) {
            value = faker.phone.number('555#######');
          }

          formManager?.setFormData(questionLabel, value);
          return null;
        }

        if (inputType === QuestionInputType.Select) {
          let value =
            questionOptions[Math.floor(Math.random() * questionOptions.length)]
              ?.option;

          const isYesOrNo =
            questionOptions.length === 2 &&
            !isEmpty(
              questionOptions.filter(
                (question: any) => question.option === 'Yes'
              )
            ) &&
            !isEmpty(
              questionOptions.filter(
                (question: any) => question.option === 'No'
              )
            );

          if (isYesOrNo) {
            const isYes = ['CI_10', 'CR_12', 'CI_31'].includes(questionLabel);

            value = isYes ? 'Yes' : 'No';
          }

          formManager?.setFormData(questionLabel, value);
          return null;
        }

        if (inputType === QuestionInputType.Date) {
          const date = formatDateForForms(
            faker.date.between(
              '1975-01-01T00:00:00.000Z',
              '2023-01-01T00:00:00.000Z'
            )
          );

          formManager?.setFormData(questionLabel, date);

          return null;
        }
      });
    });

    toast.success('Faker data filled');
  };

  const handleClearData = (e: any) => {
    e.preventDefault();

    if (
      confirm(
        'Are you sure you want to clear all the form data? This will erase all of the answers.'
      )
    ) {
      const formData = formManager?.formData;

      Object.keys(formData).forEach((key) => {
        formManager.setFormData(key, '');
      });

      toast.success('Form data reset');
    }
  };

  return (
    <div
      className={`flex rounded-md justify-center items-center w-full ${
        className ?? ''
      }`}
    >
      <button
        className="fake-it font-bold fs12 mx-2 underline"
        onClick={handleFillInFakerData}
      >
        Fake It
      </button>
      <button
        className="reset-data font-bold fs12 mx-2 underline"
        onClick={handleClearData}
      >
        Reset Data
      </button>
    </div>
  );
};

export const DynamicFormFakerAllQuestionGroups = () => {
  const user = useActiveUser();

  if (!user?.isVistoUser()) {
    return null;
  }

  const sleep = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const handleClick = async () => {
    if (confirm('Are you sure you want to fake all the data?')) {
      const id = toast.loading('Faking all groups ');

      const pendingQuestionGroups = Array.from(
        document.querySelectorAll('.dynamic-api-form-group-item-status-empty')
      );

      await pendingQuestionGroups?.reduce(async (prom: Promise<any>) => {
        await prom;

        const newGroup = document.querySelector(
          '.dynamic-api-form-group-item-status-empty'
        );

        const fakeIt = newGroup?.querySelector('.fake-it');

        fakeIt?.dispatchEvent(new MouseEvent('click', { bubbles: true }));

        await sleep(5000);
      }, Promise.resolve());

      toast.remove(id);
      toast.success('Faking all Completed');
    }
  };

  return (
    <div className="text-center">
      <button
        className="text-center mx-auto mb-4 font-semibold underline"
        onClick={() => handleClick()}
      >
        Fake it all
      </button>
    </div>
  );
};
