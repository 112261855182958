import { createContext } from 'react';

import { MultiStageFormController } from '../../MultiStageForm/MultiStageFormController';

export const GettingStartedQuestionGroupContext =
  createContext<MultiStageFormController>({
    createPageId: () => '',
    visiblePageID: null,
    nextPage: () => null,
    prevPage: () => null,
    changePage: () => null,
    maxPage: 0,
    isOnLastPage: false,
    isOnFirstPage: true,
    currentPage: 0,
    setLabels: () => null,
    labels: [],
  } as MultiStageFormController);
