import Account from 'dataLayer/Account';
import { Content as ContentModel } from 'dataLayer/Content';
import Text from 'elements/Text';
import {
  AccountSubscriptionData,
  PermissionOperation,
} from 'generated/graphql';
import { useAsyncEffect } from 'hooks/useAsyncEffect';
import { isEmpty } from 'lodash';
import Link from 'next/link';
import { FC, useEffect, useState } from 'react';

import { useActiveUser } from '../../../hooks/useActiveUser';

export const NavNotificationBar: FC = () => {
  const user = useActiveUser();

  const is = {
    allowed: user?.hasPermission(
      'billing-professionals',
      PermissionOperation.All
    ),
    lawyer: user?.isType('LAWYER'),
    copilot: user?.isType('COPILOT'),
  };

  const [subscription, setSubscription] =
    useState<AccountSubscriptionData | null>(null);
  const [notificationText, setNotificationText] = useState<{
    text_link: '';
    text_basic: '';
    link: '';
  } | null>(null);

  const refreshData = async (status: { isMounted: boolean }) => {
    let subscription = null;
    let notificationText: any = null;

    if (is.allowed || is.lawyer || is.copilot) {
      subscription = await Account.subscription();
    }

    if (!is.copilot) {
      notificationText = await ContentModel.getTopBarNotification();
    }

    if (status.isMounted) {
      setSubscription(subscription as AccountSubscriptionData);

      if (!isEmpty(notificationText)) {
        setNotificationText({
          text_basic: notificationText?.attributes?.text_basic ?? '',
          text_link: notificationText?.attributes?.text_link ?? '',
          link: notificationText?.attributes?.link ?? '',
        });
      } else {
        setNotificationText(null);
      }
    } else {
      setSubscription(null);
      setNotificationText(null);
    }
  };

  useAsyncEffect(refreshData, []);

  useEffect(() => {
    const notAllowed = !is.allowed && isEmpty(notificationText);
    const isCopilotNotAllowed = !is.copilot && !subscription?.isActive;

    if (typeof window === 'undefined' || (notAllowed && isCopilotNotAllowed)) {
      return;
    }

    window.addEventListener('resize', onResize);

    onResize();

    return () => window.removeEventListener('resize', onResize);
  }, [subscription, notificationText]);

  if (
    ((!is.allowed || !subscription || subscription?.isActive) &&
      isEmpty(notificationText) &&
      !is.copilot) ||
    (!is.lawyer && !is.copilot)
  ) {
    return null;
  }

  let Content = (
    <>
      Don't wait!{' '}
      <Link href="/clients?createApplication=true" className="underline">
        Start a new application here
      </Link>
      .
    </>
  );

  if (
    !subscription?.access.applications?.allowed &&
    subscription?.hasFreeTrial !== false &&
    !is.copilot
  ) {
    Content = (
      <>
        You have used up your free applications,{' '}
        <Link href="/account/billing" className="underline">
          upgrade your account to a paid plan here
        </Link>
        .
      </>
    );
  }

  if (subscription?.hasFreeTrial === false && !is.copilot) {
    Content = (
      <>
        Welcome to Visto!{' '}
        <Link href="/account/billing" className="underline">
          Upgrade your account to a paid plan here
        </Link>
        .
      </>
    );
  }

  if (subscription?.isPastDue === true) {
    Content = (
      <>
        ATTENTION: Your last payment failed.{' '}
        <Link href="/account/billing" className="underline">
          Update your credit card information here
        </Link>
        !
      </>
    );
  }

  if (!isEmpty(notificationText) && notificationText?.text_basic) {
    Content = (
      <>
        {notificationText.text_basic}{' '}
        {notificationText?.text_link && notificationText?.link && (
          <Link href={notificationText?.link} className="underline">
            {notificationText?.text_link}
          </Link>
        )}
      </>
    );
  }

  if (is.copilot) {
    if (subscription?.isActive === false) {
      Content = (
        <>
          Your free trial is active.{' '}
          <Link href="/account/billing" className="underline">
            Upgrade your account to a paid plan here
          </Link>
          .
        </>
      );
    } else {
      return null;
    }
  }

  return (
    <div
      className="bg-gradient-to-r from-[#2897f2] to-blue-600 px-4 py-2"
      id="navNotificationBar"
    >
      <Text.Paragraph className="text-white fs13 text-center font-semibold">
        {Content}
      </Text.Paragraph>
    </div>
  );
};

const onResize = () => {
  const mainNav = document.getElementById('mainNav');
  const notificationBar = document.getElementById('navNotificationBar');
  const sideNav: any = document.getElementById('sideNav');
  const contentArea: any = document.getElementById('contentArea');

  const newOffset =
    (mainNav?.offsetHeight ?? 0) + (notificationBar?.offsetHeight ?? 0);

  sideNav.style.top = `${newOffset}px`;
  sideNav.style.height = `calc(100vh - ${newOffset}px)`;
  contentArea.style.height = `calc(100vh - ${newOffset}px)`;
};
