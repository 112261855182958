import { FC } from 'react';

type HTMLParagraphProps =
  | JSX.IntrinsicElements['h1']
  | JSX.IntrinsicElements['h2']
  | JSX.IntrinsicElements['h3']
  | JSX.IntrinsicElements['h4']
  | JSX.IntrinsicElements['h5']
  | JSX.IntrinsicElements['h6'];

interface ParagraphProps {
  as?: 'p' | 'label';
  variant?: 'body1';
}

const getVariantClasses = (variant: ParagraphProps['variant']) => {
  if (!variant) return '';
  if (variant === 'body1') return ['text-md'];
};

export const Paragraph: FC<ParagraphProps & HTMLParagraphProps> = ({
  as = 'p',
  variant,
  children,
  className = '',
}) => {
  const Tag = as;
  const classes = getVariantClasses(variant);
  return <Tag className={classes + ' ' + className}>{children}</Tag>;
};

Paragraph.displayName = 'Paragraph';

export default Paragraph;
