/* eslint-disable sonarjs/no-duplicate-string */
import { observer } from 'mobx-react-lite';
import Link from 'next/link';
import React, { FC } from 'react';
import * as Icons from 'react-feather';

import useClassManager from '../../hooks/useClassManager';
import { LoadingSpinner } from '../Loading/LoadingSpinner';

interface ButtonPropTypes {
  isLoading?: boolean;
  icon?: keyof typeof Icons;
  size?: 'small' | 'medium' | 'large' | 'square' | 'xl';
  as?: keyof JSX.IntrinsicElements;
  format?:
    | 'default'
    | 'basic'
    | 'thin'
    | 'outline'
    | 'outline-dark'
    | 'outline-light'
    | 'opaque'
    | 'square'
    | 'square-grey'
    | 'square-blue'
    | 'square-transparent'
    | 'light-blue'
    | 'white';
  type?: 'button' | 'submit' | 'reset';
  show?: boolean;
  link?: string | null;
  openNewWindow?: boolean;
  linkClassName?: string;
  isPinging?: boolean;
}

// eslint-disable-next-line sonarjs/no-duplicate-string
const BASE_BUTTON_CLASSES = [
  'flex',
  'text-white',
  'font-bold',
  'bg-visto',
  'hover:bg-gray-600',
  'active:bg-opacity-50',
  'disabled:bg-opacity-50',
  'disabled:cursor-not-allowed',
  'disabled:pointer-events-none',
  'rounded-3xl',
  'justify-center',
  'items-center',
];

// eslint-disable-next-line sonarjs/no-duplicate-string
const BASE_BUTTON_LIGHT_BLUE_CLASSES = [
  'flex',
  'text-white',
  'font-bold',
  'bg-visto-light-blue',
  'hover:bg-gray-600',
  'active:bg-opacity-50',
  'disabled:bg-opacity-50',
  'disabled:cursor-not-allowed',
  'disabled:pointer-events-none',
  'rounded-3xl',
  'justify-center',
  'items-center',
];

// eslint-disable-next-line sonarjs/no-duplicate-string
const BASE_BUTTON_BASIC_CLASSES = [
  'flex',
  'text-black',
  'font-bold',
  'hover:underline',
  'disabled:bg-opacity-90',
  'disabled:cursor-not-allowed',
  'disabled:pointer-events-none',
  'justify-center',
  'items-center',
  'disabled:bg-opacity-50',
  'disabled:opacity-50',
  'disabled:cursor-not-allowed',
  'disabled:pointer-events-none',
];

const BASE_BUTTON_OPAQUE_CLASSES = [
  'flex',
  'text-black',
  'font-semibold',
  'opacity-50',
  'hover:opacity-100',
  'disabled:bg-opacity-90',
  'disabled:cursor-not-allowed',
  'disabled:pointer-events-none',
  'justify-center',
  'items-center',
];

const BASE_BUTTON_THIN_CLASSES = [
  'text-black',
  'font-bold',
  'rounded-full',
  'border',
  'border-gray-200',
  'hover:bg-gray-200',
];

const BASE_BUTTON_SQUARE_CLASSES = {
  container: [
    'bg-gray-100',
    'text-black',
    'font-semibold',
    'rounded-lg',
    'hover:bg-gray-200',
    'w-full',
    'p-3',
    'disabled:cursor-not-allowed',
    'disabled:pointer-events-none',
    'flex',
    'items-center',
    'justify-center',
  ],
  link: ['flex', 'items-center', 'justify-between', 'w-full'],
};

const BASE_BUTTON_SQUARE_DARK_GREY_CLASSES = {
  container: [
    'bg-gray-300',
    'text-black',
    'font-semibold',
    'rounded-lg',
    'hover:bg-gray-200',
    'w-full',
    'p-3',
    'disabled:cursor-not-allowed',
    'disabled:pointer-events-none',
    'flex',
    'items-center',
    'justify-center',
  ],
  link: ['flex', 'items-center', 'justify-between', 'w-full'],
};

const BASE_BUTTON_SQUARE_BLUE_CLASSES = {
  container: [
    'text-white',
    'font-bold',
    'bg-visto',
    'hover:bg-gray-600',
    'disabled:bg-opacity-50',
    'disabled:cursor-not-allowed',
    'disabled:pointer-events-none',
    'rounded-lg',
    'w-full',
    'p-3',
    'disabled:cursor-not-allowed',
    'disabled:pointer-events-none',
    'flex',
    'items-center',
    'justify-center',
  ],
  link: ['flex', 'items-center', 'justify-between', 'w-full'],
};

const BASE_BUTTON_SQUARE_TRANSPARENT_CLASSES = {
  container: [
    'font-semibold',
    'bg-transparent',
    'hover:bg-gray-200',
    'disabled:bg-opacity-50',
    'disabled:cursor-not-allowed',
    'disabled:pointer-events-none',
    'rounded-lg',
    'w-full',
    'p-3',
    'disabled:cursor-not-allowed',
    'disabled:pointer-events-none',
    'flex',
    'items-center',
    'justify-center',
  ],
  link: ['flex', 'items-center', 'justify-between', 'w-full'],
};

const BASE_BUTTON_OUTLINE_CLASSES = [
  'flex',
  'text-gray-500',
  'font-bold',
  'bg-transparent',
  'rounded-3xl',
  'justify-center',
  'items-center',
  'border',
  'border-gray-300',
  'hover:border-transparent',
  'hover:bg-visto',
  'hover:text-white',
  'disabled:bg-opacity-50',
  'disabled:opacity-50',
  'disabled:cursor-not-allowed',
  'disabled:pointer-events-none',
];

const BASE_BUTTON_OUTLINE_DARK_CLASSES = [
  'flex',
  'text-gray-800',
  'font-bold',
  'bg-transparent',
  'rounded-3xl',
  'justify-center',
  'items-center',
  'border-2',
  'border-gray-800',
  'hover:border-transparent',
  'hover:bg-visto',
  'hover:text-white',
  'disabled:bg-opacity-50',
  'disabled:opacity-50',
  'disabled:cursor-not-allowed',
  'disabled:pointer-events-none',
];

const BASE_BUTTON_OUTLINE_LIGHT_CLASSES = [
  'flex',
  'text-white',
  'font-bold',
  'bg-transparent',
  'rounded-3xl',
  'justify-center',
  'items-center',
  'border-2',
  'border-white',
  'hover:bg-white',
  'hover:bg-opacity-10',
  'hover:text-white',
  'disabled:bg-opacity-50',
  'disabled:opacity-50',
  'disabled:cursor-not-allowed',
  'disabled:pointer-events-none',
];

const BASE_BUTTON_WHITE_CLASSES = [
  'flex',
  'text-black',
  'font-bold',
  'bg-white',
  'rounded-3xl',
  'justify-center',
  'items-center',
  'hover:bg-white',
  'hover:bg-opacity-90',
  'disabled:bg-opacity-50',
  'disabled:opacity-50',
  'disabled:cursor-not-allowed',
  'disabled:pointer-events-none',
];

const XL_BUTTON_SIZING = ['px-16', 'py-4', 'fs21', 'rounded-[50px]'];
const LARGE_BUTTON_SIZING = ['px-12', 'py-3', 'fs16'];
const MEDIUM_BUTTON_SIZING = ['px-6', 'py-2', 'fs14'];
const SMALL_BUTTON_SIZING = ['px-2', 'py-2', 'fs12'];
const SQUARE_BUTTON_SIZING = ['px-3', 'py-3', 'fs14'];

const getSizeClasses = (size: ButtonPropTypes['size']) => {
  let classes: string[] = [];
  if (size === 'xl') classes = XL_BUTTON_SIZING;
  if (size === 'large') classes = LARGE_BUTTON_SIZING;
  if (size === 'medium') classes = MEDIUM_BUTTON_SIZING;
  if (size === 'small') classes = SMALL_BUTTON_SIZING;
  if (size === 'square') classes = SQUARE_BUTTON_SIZING;

  return classes.join(' ');
};

const getButtonTypeClasses = (
  format: ButtonPropTypes['format'] = 'default'
) => {
  let classes: string[] = [];
  if (format === 'default') classes = BASE_BUTTON_CLASSES;
  if (format === 'basic') classes = BASE_BUTTON_BASIC_CLASSES;
  if (format === 'thin') classes = BASE_BUTTON_THIN_CLASSES;
  if (format === 'outline') classes = BASE_BUTTON_OUTLINE_CLASSES;
  if (format === 'light-blue') classes = BASE_BUTTON_LIGHT_BLUE_CLASSES;
  if (format === 'outline-dark') classes = BASE_BUTTON_OUTLINE_DARK_CLASSES;
  if (format === 'outline-light') classes = BASE_BUTTON_OUTLINE_LIGHT_CLASSES;
  if (format === 'opaque') classes = BASE_BUTTON_OPAQUE_CLASSES;
  if (format === 'white') classes = BASE_BUTTON_WHITE_CLASSES;
  if (format === 'square') classes = BASE_BUTTON_SQUARE_CLASSES.container;
  if (format === 'square-grey')
    classes = BASE_BUTTON_SQUARE_DARK_GREY_CLASSES.container;
  if (format === 'square-blue')
    classes = BASE_BUTTON_SQUARE_BLUE_CLASSES.container;
  if (format === 'square-transparent')
    classes = BASE_BUTTON_SQUARE_TRANSPARENT_CLASSES.container;
  return classes.join(' ');
};

export type AllButtonPropTypes = JSX.IntrinsicElements['button'] &
  ButtonPropTypes;

export const Button: FC<AllButtonPropTypes> = observer(
  ({
    as = 'button',
    className,
    isLoading,
    disabled,
    children,
    icon,
    size = 'medium',
    format = 'default',
    type = 'submit',
    show = true,
    link = null,
    openNewWindow = false,
    linkClassName = '',
    isPinging = false,
    ...props
  }) => {
    const baseClasses = getButtonTypeClasses(format);
    const cssManager = useClassManager(baseClasses);

    const isDisabled =
      isLoading || disabled || props['aria-disabled'] === 'true';

    let Icon: null | Icons.Icon = null;
    if (icon) Icon = Icons[icon];

    const Element: any = as;

    if (!show) return null;

    const Button = (
      <Element
        type={type}
        className={cssManager.toString({
          withClasses: `${className || ''} ${getSizeClasses(size)} relative`,
        })}
        disabled={isDisabled}
        aria-disabled={isDisabled}
        {...props}
      >
        {isPinging && (
          <span className="flex absolute h-3 w-3 top-0.5 right-0.5 -mt-1 -mr-1">
            <span
              className={`animate-ping absolute inline-flex h-full w-full rounded-full opacity-75 ${
                format === 'light-blue' || format === 'white'
                  ? 'bg-visto'
                  : 'bg-visto-light-blue'
              }`}
            ></span>
            <span
              className={`relative inline-flex rounded-full h-3 w-3 border border-white ${
                format === 'light-blue' || format === 'white'
                  ? 'bg-visto'
                  : 'bg-visto-light-blue'
              }`}
            ></span>
          </span>
        )}
        <LoadingSpinner className="mr-3" show={Boolean(isLoading)} />
        {!isLoading && Icon && <Icon className="h-5 w-5 mr-2" />}
        {children}
      </Element>
    );

    if (!link) return Button;

    return (
      <ButtonWithLink
        format={format}
        linkUrl={link}
        linkClassName={linkClassName}
        openNewWindow={openNewWindow}
      >
        {Button}
      </ButtonWithLink>
    );
  }
);

interface ButtonWithLinkPropTypes {
  format?: string;
  link?: string | null;
  openNewWindow?: boolean;
  linkClassName?: string;
  linkUrl: string;
}

const ButtonWithLink: FC<ButtonWithLinkPropTypes> = ({
  linkUrl,
  format,
  linkClassName,
  openNewWindow,
  children,
}) => {
  return (
    <Link
      href={linkUrl}
      className={`${
        format === 'square' ? BASE_BUTTON_SQUARE_CLASSES.link.join(' ') : ''
      } ${linkClassName}`}
      rel="noreferrer"
      target={openNewWindow ? '_blank' : ''}
    >
      {children}
    </Link>
  );
};

Button.displayName = 'Button';

export default Button;
