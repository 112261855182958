/* eslint-disable sonarjs/cognitive-complexity */
import { FormManager } from '@visto-tech/forms';
import { DynamicApiFormValidationSchemaOverride } from 'components/DynamicApiForm/DynamicApiForm';
import { FormSubmissionStatus } from 'generated/graphql';
import { debounce, head } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { processFormValues } from 'utils/processFormValues';

import Form from '../dataLayer/Form';
import logger from '../utils/logger';
import { fromDBForm } from './fromDBForm';
import { useAsyncEffect } from './useAsyncEffect';

type FormDataToAnswersPayloadReturnType =
  | {
      questionLabel: string;
      answer: string;
    }
  | {
      questionLabel: string;
      answer: string;
    }[];

export const formDataToAnswersPayload = (form: Form) => {
  return ([label, value]: [
    string,
    unknown
  ]): FormDataToAnswersPayloadReturnType => {
    if (Array.isArray(value)) {
      return value.flatMap((subFormData, i) => {
        const id = i + 1;

        return Object.entries(subFormData)
          .flatMap(formDataToAnswersPayload(form))
          .map((payload) => ({
            ...payload,
            answerGroupingId: id,
            repeaterFormQuestionLabel: label,
          }));
      });
    }

    return {
      questionLabel: label,
      answer: processFormValues(value),
    };
  };
};

export const useManagedDatabaseForm = ({
  formName,
  groupName,
  targetAccountId,
  associations = {},
  excludeFormId = false,
  formSubmissionStatus,
  validationSchemaOverride,
  questionLabelsToRemove,
  isPreview = false,
  searchTerm,
  onlyMostRecentFormSubmission = false,
  excludeFormSubmissionAnswers = false,
}: {
  formName: string;
  groupName?: string;
  targetAccountId?: number;
  associations?: { [key: string]: string };
  excludeFormId?: boolean;
  formSubmissionStatus?: FormSubmissionStatus;
  validationSchemaOverride?: DynamicApiFormValidationSchemaOverride;
  questionLabelsToRemove?: string[];
  isPreview?: boolean;
  searchTerm?: string;
  onlyMostRecentFormSubmission?: boolean;
  excludeFormSubmissionAnswers?: boolean;
}) => {
  const [form, setForm] = useState<null | Form>(null);
  const [formManager, setFormManager] = useState<FormManager<any> | null>(null);
  const [failed, setFailed] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isFetchingForm, setIsFetchingForm] = useState(true);
  const isLoading = formManager === null && !failed;

  const fetchForm = useCallback(async () => {
    setIsFetchingForm(true);

    let form;

    const FormMethod = !isPreview
      ? Form.getFormByName
      : Form.getFormByNamePreview;

    try {
      form = await FormMethod({
        name: formName,
        groupName,
        targetAccountId: targetAccountId,
        associations,
        excludeFormId,
        formSubmissionStatus,
        questionLabelsToRemove,
        searchTerm,
        onlyMostRecentFormSubmission,
        excludeFormSubmissionAnswers,
      });

      if (!form) {
        setIsFetchingForm(false);
        throw new Error(`Form ${formName} not found`);
      }

      setForm(form);
      setIsFetchingForm(false);
    } catch (err) {
      logger.log('Failed to fetch form ' + formName);
      logger.error(err);
      setFailed(true);
      setIsFetchingForm(false);
    }

    return form;
  }, [associations, formName, targetAccountId, searchTerm]);

  const buildFormManager = useCallback(
    (form?: Form | null) => {
      if (!form) {
        return null;
      }

      const newFormManager = fromDBForm(form, validationSchemaOverride);

      if (formManager) {
        newFormManager.repeatedFormData = formManager.repeatedFormData;
      }

      setFormManager(newFormManager);
    },
    [formManager]
  );

  const refresh = useCallback(async () => {
    const form = await fetchForm();
    buildFormManager(form);
  }, [fetchForm, buildFormManager]);

  const reset = useCallback(async () => {
    setFormManager(null);
    setFailed(false);
    setForm(null);
    await refresh();
  }, [refresh]);

  useAsyncEffect(
    async ({ isMounted }) => {
      if (!isMounted) {
        return;
      }

      await refresh();
    },
    [formName, searchTerm]
  );

  const autoSaveForm = useCallback(
    async (data: any) => {
      if (!data || !form || isSaving) {
        return null;
      }

      await Form.saveDraft({
        accountId: targetAccountId,
        input: {
          answers: Object.entries(data)
            .map(formDataToAnswersPayload(form))
            .flat(Infinity) as {
            questionLabel: string;
            answer: string;
          }[],
          associations: JSON.stringify(associations),
          formName,
          groupName,
        },
      });

      setIsSaving(false);
    },
    [associations, form, formName, targetAccountId]
  );

  const handleFormSave = useMemo(
    () =>
      debounce(
        async (
          refreshForm?: (status: { isMounted: boolean }) => Promise<void>
        ) => {
          if (isSaving || isPreview) {
            return;
          }

          setIsSaving(true);

          const formData = formManager?.getFormData({ withRepeatables: true });

          await autoSaveForm(formData);

          refreshForm && refreshForm({ isMounted: true });

          setIsSaving(false);
        },
        1500,
        { maxWait: 10000 }
      ),
    [autoSaveForm, isSaving, formManager]
  );

  useEffect(() => () => handleFormSave.cancel(), [handleFormSave]);

  // Get the FormSubmissions for this Form only
  const thisFormFormSubmissions = form?.formSubmissions.filter(
    (submission) => submission.form?.name === formName
  );

  // Get the most recent FormSubmission for this Form only
  const newestFormSubmision = head(
    thisFormFormSubmissions?.sort((a, b) => {
      return b.id - a.id;
    })
  );

  return {
    formManager,
    handleFormSave,
    isLoading,
    failed,
    form,
    refresh,
    reset,
    isSaving,
    currentStatus: newestFormSubmision?.status,
    isFetchingForm,
  };
};
