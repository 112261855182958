import React, { FC, useContext } from 'react';

import { GettingStartedQuestionGroupContext } from './GettingStartedQuestionGroupContext';

export const GettingStartedQuestionGroupContainer: FC = ({ children }) => {
  const multiStageFormController = useContext(
    GettingStartedQuestionGroupContext
  );
  return (
    <>
      {React.Children.map(children, (child, i) => {
        const pageID = multiStageFormController.createPageId(i) as any;
        if (React.isValidElement(child)) {
          return React.cloneElement(child as React.ReactElement<any>, {
            pageID,
          });
        }
        return child;
      })}
    </>
  );
};
