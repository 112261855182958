import { makeAutoObservable } from 'mobx';

import { AccountType as RawAccountType } from '../generated/graphql';

export enum AccountTypeName {
  Lawyer = 'LAWYER',
  Talent = 'TALENT',
  Company = 'COMPANY',
  SuperAdmin = 'SUPER_ADMIN',
  Applicant = 'APPLICANT',
  Copilot = 'COPILOT',
}

export class AccountType implements RawAccountType {
  id: number;
  name: string;

  constructor(accountType: RawAccountType) {
    if (!accountType)
      throw new Error(
        'You cannot create a accountType instance without passing in user data.'
      );
    this.id = accountType.id;
    this.name = accountType.name;
    makeAutoObservable(this);
  }
}

export default AccountType;
