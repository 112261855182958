import { FC } from 'react';

interface ThinPropTypes {
  show?: boolean;
  size?: number;
}

export const Thin: FC<ThinPropTypes & JSX.IntrinsicElements['div']> = ({
  show = true,
  size = 5,
  className,
}) => {
  if (!show) return null;

  return (
    <div>
      {Array(size)
        .fill(0)
        .map((_, i: number) => (
          <div
            key={`thin-loading-${i}`}
            className={`animate-pulse w-full ${className}`}
          >
            <div className="border border-gray-300 rounded-md p-2 w-full mb-3">
              <div className="flex space-x-2">
                <div className="flex-1 space-y-2 py-1">
                  <div className="h-1 bg-gray-300 rounded w-full"></div>
                  <div className="h-1 bg-gray-300 rounded w-full"></div>
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

Thin.displayName = 'Thin';

export default Thin;
