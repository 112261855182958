/* eslint-disable sonarjs/no-identical-functions */
import { DropdownWithLabel, FormManager } from '@visto-tech/forms';
import { InputWithLabel } from '@visto-tech/forms';
import {
  childRelationshipList,
  countryResidenceList,
  maritalStatusesList,
} from '@visto-tech/seed';
import { Button } from 'elements/Button';
import { DatePickerInputSecondary } from 'elements/Forms/DatePickerInput/DatePickerInputSecondary';
import Loading from 'elements/Loading';
import { Text } from 'elements/Text';
import { useQuestionRepeater } from 'hooks/useQuestionRepeater';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { Plus } from 'react-feather';
import { ChildInfoRepeater } from 'types/QuestionRepeaterManagerTypes';

const yesNoOption = ['Yes', 'No'];

interface ChildInformationRepeaterPropTypes {
  formManager: FormManager<any>;
  label: string;
}

export const ChildInformationRepeater: FC<ChildInformationRepeaterPropTypes> =
  observer(({ formManager, label, children }) => {
    const initial = formManager._initialData[label];

    let initialUpdated = initial;

    // Since we added in new questions to this repeater, we need to make sure they are existing
    // if we already had data saved with the old ones
    if (!isEmpty(initialUpdated)) {
      initialUpdated = JSON.parse(initial);

      initialUpdated = initialUpdated.map((init: any) => {
        return {
          firstName: '',
          lastName: '',
          childRelationship: '',
          ...init,
        };
      });

      initialUpdated = JSON.stringify(initialUpdated);
    }

    const { questionManager } = useQuestionRepeater({
      initialData: initialUpdated ? initialUpdated : [],
      structure: {
        firstName: '',
        lastName: '',
        childRelationship: '',
        dob: '',
        countryBirth: '',
        accompyingToCanada: '',
        stillLiving: '',
        maritalStatus: '',
        address: '',
        occupation: '',
        cityLivingPassed: '',
        countryLivingPassed: '',
        datePassed: '',
      },
      formManager,
      label,
    });

    if (!questionManager) return <Loading.Default />;

    formManager.formData[label] = questionManager.stringify();

    return (
      <div className="w-full">
        <Text.Paragraph className="fs14 font-semibold mb-2">
          {children}
        </Text.Paragraph>
        {questionManager.state.length === 0 && (
          <div className="bg-gray-100 p-6 mb-3 rounded-lg flex justify-center">
            <Button
              type="button"
              onClick={() => {
                questionManager.add();
              }}
            >
              <Plus className="mr-2" size={18} /> Add First Child
            </Button>
          </div>
        )}
        {questionManager
          .getState()
          .map((child: ChildInfoRepeater, i: number) => {
            return (
              <div key={i} className="bg-gray-100 p-3 mb-3 rounded-lg">
                <div className="w-full flex justify-between items-start mb-1">
                  <Text.Paragraph className="fs14 font-semibold mb-2">
                    Child #{i + 1}
                  </Text.Paragraph>
                  <button
                    className="fs12 text-right text-gray-400 hover:text-gray-800"
                    type="button"
                    onClick={() => questionManager.delete(i)}
                  >
                    Delete
                  </button>
                </div>
                <div className="mb-3">
                  <InputWithLabel
                    key={`id-firstName-${i}-input-${label}`}
                    id={`id-firstName-${i}-input-${label}`}
                    name="firstName"
                    value={child?.firstName?.value ?? ''}
                    tooltip="Additional child's given name"
                    error={questionManager.hasError(
                      'firstName',
                      i,
                      formManager
                    )}
                    errorMessage={questionManager.errorMessage(
                      'firstName',
                      i,
                      formManager
                    )}
                    formFieldSetProps={{
                      className: 'w-full',
                    }}
                    inputProps={
                      {
                        'data-group': i,
                        onChange: questionManager.handleOnChange,
                        type: 'text',
                      } as any
                    }
                  >
                    First name
                  </InputWithLabel>
                </div>
                <div className="mb-3">
                  <InputWithLabel
                    key={`id-lastName-${i}-input-${label}`}
                    id={`id-lastName-${i}-input-${label}`}
                    name="lastName"
                    value={child?.lastName?.value ?? ''}
                    tooltip="Additional child's family name"
                    error={questionManager.hasError('lastName', i, formManager)}
                    errorMessage={questionManager.errorMessage(
                      'lastName',
                      i,
                      formManager
                    )}
                    formFieldSetProps={{
                      className: 'w-full',
                    }}
                    inputProps={
                      {
                        'data-group': i,
                        onChange: questionManager.handleOnChange,
                        type: 'text',
                      } as any
                    }
                  >
                    Last name
                  </InputWithLabel>
                </div>
                <div className="mb-3">
                  <DropdownWithLabel
                    key={`id-childRelationship-${i}-select-${label}`}
                    id={`id-childRelationship-${i}-select-${label}`}
                    name="childRelationship"
                    value={child?.childRelationship?.value ?? ''}
                    error={questionManager.hasError(
                      'childRelationship',
                      i,
                      formManager
                    )}
                    errorMessage={questionManager.errorMessage(
                      'childRelationship',
                      i,
                      formManager
                    )}
                    dropdownProps={
                      {
                        'data-group': i,
                        onChange: questionManager.handleOnChange,
                        children: [
                          <option key={`initial-empty-value`} value="" disabled>
                            Select one
                          </option>,
                          ...childRelationshipList.map(
                            (relationship: any, i: number) => (
                              <option
                                key={i}
                                value={relationship.label}
                                selected={
                                  relationship.label ===
                                  child.childRelationship?.value
                                }
                              >
                                {relationship.label}
                              </option>
                            )
                          ),
                        ],
                      } as any
                    }
                  >
                    Child Relationship
                  </DropdownWithLabel>
                </div>
                <div className="mb-3">
                  <DropdownWithLabel
                    key={`id-countryBirth-${i}-select-${label}`}
                    id={`id-countryBirth-${i}-select-${label}`}
                    name="countryBirth"
                    value={child.countryBirth.value ?? ''}
                    error={questionManager.hasError(
                      'countryBirth',
                      i,
                      formManager
                    )}
                    errorMessage={questionManager.errorMessage(
                      'countryBirth',
                      i,
                      formManager
                    )}
                    dropdownProps={
                      {
                        'data-group': i,
                        onChange: questionManager.handleOnChange,
                        children: [
                          <option key={`initial-empty-value`} value="" disabled>
                            -
                          </option>,
                          ...countryResidenceList.map((q, index) => (
                            <option key={`opt-${index}`} value={q.label}>
                              {q.label}
                            </option>
                          )),
                        ],
                      } as any
                    }
                  >
                    Country of Birth
                  </DropdownWithLabel>
                </div>
                <div className="mb-3">
                  <DatePickerInputSecondary
                    key={`id-dob-${i}-select-${label}`}
                    label="dob"
                    initValue={child.dob.value}
                    onChange={questionManager.handleOnChange}
                    inputError={questionManager.errorMessage(
                      'dob',
                      i,
                      formManager
                    )}
                    dateProps={{
                      name: 'dob',
                      groupId: i,
                    }}
                  >
                    Date of Birth
                  </DatePickerInputSecondary>
                </div>
                <div className="mb-3">
                  <DropdownWithLabel
                    key={`id-accompyingToCanada-${i}-select-${label}`}
                    id={`id-accompyingToCanada-${i}-select-${label}`}
                    name="accompyingToCanada"
                    value={child.accompyingToCanada.value ?? ''}
                    error={questionManager.hasError(
                      'accompyingToCanada',
                      i,
                      formManager
                    )}
                    errorMessage={questionManager.errorMessage(
                      'accompyingToCanada',
                      i,
                      formManager
                    )}
                    dropdownProps={
                      {
                        'data-group': i,
                        onChange: questionManager.handleOnChange,
                        children: [
                          <option key={`initial-empty-value`} value="" disabled>
                            -
                          </option>,
                          ...yesNoOption.map((q, index) => (
                            <option key={`opt-${index}`} value={q}>
                              {q}
                            </option>
                          )),
                        ],
                      } as any
                    }
                  >
                    Will they be accompying you to Canada?
                  </DropdownWithLabel>
                </div>
                <div className="mb-3">
                  <DropdownWithLabel
                    key={`id-stillLiving-${i}-select-${label}`}
                    id={`id-stillLiving-${i}-select-${label}`}
                    name="stillLiving"
                    value={child.stillLiving.value ?? ''}
                    error={questionManager.hasError(
                      'stillLiving',
                      i,
                      formManager
                    )}
                    errorMessage={questionManager.errorMessage(
                      'stillLiving',
                      i,
                      formManager
                    )}
                    dropdownProps={
                      {
                        'data-group': i,
                        onChange: questionManager.handleOnChange,
                        children: [
                          <option key={`initial-empty-value`} value="" disabled>
                            -
                          </option>,
                          ...yesNoOption.map((q, index) => (
                            <option key={`opt-${index}`} value={q}>
                              {q}
                            </option>
                          )),
                        ],
                      } as any
                    }
                  >
                    Are they still living?
                  </DropdownWithLabel>
                </div>
                {child.stillLiving.value === 'Yes' && (
                  <>
                    <div className="mb-3">
                      <InputWithLabel
                        key={`id-address-${i}-input-${label}`}
                        id={`id-address-${i}-input-${label}`}
                        name="address"
                        value={child.address.value}
                        error={questionManager.hasError(
                          'address',
                          i,
                          formManager
                        )}
                        errorMessage={questionManager.errorMessage(
                          'address',
                          i,
                          formManager
                        )}
                        formFieldSetProps={{
                          className: 'w-full',
                          tooltip: undefined,
                        }}
                        inputProps={
                          {
                            'data-group': i,
                            onChange: questionManager.handleOnChange,
                            type: 'text',
                          } as any
                        }
                      >
                        Present full address
                      </InputWithLabel>
                    </div>
                    <div className="mb-3">
                      <InputWithLabel
                        key={`id-occupation-${i}-input-${label}`}
                        id={`id-occupation-${i}-input-${label}`}
                        name="occupation"
                        value={child.occupation.value}
                        error={questionManager.hasError(
                          'occupation',
                          i,
                          formManager
                        )}
                        errorMessage={questionManager.errorMessage(
                          'occupation',
                          i,
                          formManager
                        )}
                        formFieldSetProps={{
                          className: 'w-full',
                          tooltip: undefined,
                        }}
                        inputProps={
                          {
                            'data-group': i,
                            onChange: questionManager.handleOnChange,
                            type: 'text',
                          } as any
                        }
                      >
                        Present occupation
                      </InputWithLabel>
                    </div>
                    <div className="mb-3">
                      <DropdownWithLabel
                        key={`id-maritalStatus-${i}-select-${label}`}
                        id={`id-maritalStatus-${i}-select-${label}`}
                        name="maritalStatus"
                        value={child.maritalStatus.value ?? ''}
                        error={questionManager.hasError(
                          'maritalStatus',
                          i,
                          formManager
                        )}
                        errorMessage={questionManager.errorMessage(
                          'maritalStatus',
                          i,
                          formManager
                        )}
                        dropdownProps={
                          {
                            'data-group': i,
                            onChange: questionManager.handleOnChange,
                            children: [
                              <option
                                key={`initial-empty-value`}
                                value=""
                                disabled
                              >
                                -
                              </option>,
                              ...maritalStatusesList.map((q, index) => (
                                <option key={`opt-${index}`} value={q.label}>
                                  {q.label}
                                </option>
                              )),
                            ],
                          } as any
                        }
                      >
                        Marital status
                      </DropdownWithLabel>
                    </div>
                  </>
                )}
                {child.stillLiving.value === 'No' && (
                  <>
                    <div className="mb-3">
                      <InputWithLabel
                        key={`id-cityLivingPassed-${i}-input-${label}`}
                        id={`id-cityLivingPassed-${i}-input-${label}`}
                        name="cityLivingPassed"
                        value={child.cityLivingPassed.value}
                        error={questionManager.hasError(
                          'cityLivingPassed',
                          i,
                          formManager
                        )}
                        errorMessage={questionManager.errorMessage(
                          'cityLivingPassed',
                          i,
                          formManager
                        )}
                        formFieldSetProps={{
                          className: 'w-full',
                          tooltip: undefined,
                        }}
                        inputProps={
                          {
                            'data-group': i,
                            onChange: questionManager.handleOnChange,
                            type: 'text',
                          } as any
                        }
                      >
                        In which city were they living in when they passed?
                      </InputWithLabel>
                    </div>
                    <div className="mb-3">
                      <DropdownWithLabel
                        key={`id-countryLivingPassed-${i}-select-${label}`}
                        id={`id-countryLivingPassed-${i}-select-${label}`}
                        name="countryLivingPassed"
                        value={child.countryLivingPassed.value ?? ''}
                        error={questionManager.hasError(
                          'countryLivingPassed',
                          i,
                          formManager
                        )}
                        errorMessage={questionManager.errorMessage(
                          'countryLivingPassed',
                          i,
                          formManager
                        )}
                        dropdownProps={
                          {
                            'data-group': i,
                            onChange: questionManager.handleOnChange,
                            children: [
                              <option
                                key={`initial-empty-value`}
                                value=""
                                disabled
                              >
                                -
                              </option>,
                              // eslint-disable-next-line sonarjs/no-identical-functions
                              ...countryResidenceList.map((q, index) => (
                                <option key={`opt-${index}`} value={q.label}>
                                  {q.label}
                                </option>
                              )),
                            ],
                          } as any
                        }
                      >
                        In which country where they living in when they passed?
                      </DropdownWithLabel>
                    </div>
                    <div className="mb-3">
                      <DatePickerInputSecondary
                        key={`id-datePassed-${i}-select-${label}`}
                        label="datePassed"
                        initValue={child.datePassed.value}
                        onChange={questionManager.handleOnChange}
                        inputError={questionManager.errorMessage(
                          'datePassed',
                          i,
                          formManager
                        )}
                        dateProps={{
                          name: 'datePassed',
                          groupId: i,
                        }}
                      >
                        On what date did they pass?
                      </DatePickerInputSecondary>
                    </div>
                  </>
                )}
              </div>
            );
          })}

        <div className="flex justify-end mt-3">
          <Button
            type="button"
            format="outline"
            onClick={() => {
              questionManager.add();
            }}
          >
            Add Child
          </Button>
        </div>
      </div>
    );
  });
