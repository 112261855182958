import Answer from 'dataLayer/Answer';
import { LogoDisplay } from 'elements/Icon/LogoDisplay';
import { DynamicLink } from 'elements/NavigationItem/DynamicLink';
import { NAVIGATION_ITEM_TYPES } from 'elements/NavigationItem/NavigationItem';
import { Text } from 'elements/Text';
import { useActiveUser } from 'hooks/useActiveUser';
import { useAsyncEffect } from 'hooks/useAsyncEffect';
import React, { FC, useState } from 'react';
import { ChevronRight } from 'react-feather';
import logger from 'utils/logger';

export const NavProfile: FC<{ setSidebarOpen?: (args: boolean) => void }> = ({
  setSidebarOpen,
}) => {
  const user = useActiveUser();
  const accountId = user?.account?.id as number;
  const [domain, setDomain] = useState<string>('');

  const is = {
    company: user?.isType('COMPANY'),
    talent: user?.isType('TALENT'),
    lawyer: user?.isType('LAWYER'),
    applicant: user?.isType('APPLICANT'),
    copilot: user?.isType('COPILOT'),
  };

  let name = user?.account?.name;

  if (is.talent || is.applicant) {
    name = user?.firstName ? `${user?.firstName} ${user?.lastName}` : null;
  }

  const refresh = async (status: { isMounted: boolean }) => {
    if (is.lawyer || is.copilot) {
      return;
    }

    try {
      const answer = await Answer.getAnswers({
        questionLabels: ['CG_11'],
        accountId,
      });
      const domain = answer?.get('CG_11');
      if (status.isMounted && domain) {
        setDomain(domain);
      }
    } catch (err) {
      logger.error(err);
    }
  };

  useAsyncEffect(refresh, []);

  return (
    <DynamicLink
      className="side-nav-account flex flex-row mx-6 my-8 py-3 px-2 rounded-md border items-center text-base"
      type={NAVIGATION_ITEM_TYPES.INTERNAL_LINK}
      href="/account/settings"
      onClick={() => setSidebarOpen && setSidebarOpen(false)}
    >
      <div className="flex justify-between items-center w-full">
        <div className="flex justify-center items-center">
          {is.company && domain && <LogoDisplay domain={domain} />}
          <Text.Paragraph className="fs15 mx-3 font-bold font-display text-visto leading-snug">
            {name ?? 'My Account'}
          </Text.Paragraph>
        </div>
        <div>
          <ChevronRight />
        </div>
      </div>
    </DynamicLink>
  );
};
