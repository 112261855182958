import LoadingSpinner from 'elements/Loading/LoadingSpinner';
import { ApplicationDocument } from 'generated/graphql';
import React, { FC } from 'react';
import Dropzone from 'react-dropzone';
import { FileRejection } from 'react-dropzone';
import { AlertTriangle, UploadCloud } from 'react-feather';
import {
  BYTES_IN_MB,
  fileSizeLimitString,
  multipleFileUploadLimit,
  uploadFileMaxSize,
  uploadVideoMaxSize,
  videoSizeLimitString,
} from 'static/ApplicationDocuments';

import Text from '../../elements/Text';

const CLASSES_BASE = `border-2 border-dashed border-gray-300 bg-gray-100 rounded-md cursor-pointer h-full flex items-center justify-center`;

type UploadProps = {
  onDropHandler: (
    acceptedFiles: any[],
    filesRejection: FileRejection[]
  ) => Promise<void | undefined | null>;
  isLoading: boolean;
  applicationDocument?: ApplicationDocument | null;
  isMultiple?: boolean;
  textLoading?: string | null;
  className?: string;
  size?: 'default' | 'small';
  type?: 'video' | 'file' | 'image' | 'doc';
  options?: {
    maxSizeInMb?: number;
  };
};

export const Upload: FC<UploadProps> = ({
  onDropHandler,
  isLoading,
  isMultiple = false,
  textLoading = null,
  className = 'p-4',
  size = 'default',
  type = 'file',
  applicationDocument,
  options,
}: UploadProps) => {
  let maxSize = type === 'file' ? uploadFileMaxSize : uploadVideoMaxSize;
  let maxSizeText =
    type === 'file' ? fileSizeLimitString : videoSizeLimitString;
  let accept = ['.png', '.jpeg', '.jpg', '.pdf'];

  if (type === 'video') {
    accept = ['video/*'];
  } else if (type === 'image') {
    accept = ['.jpeg', '.jpg', '.png'];
  } else if (type === 'doc') {
    accept = ['.doc', '.docx'];
  }

  if (options?.maxSizeInMb) {
    maxSize = options.maxSizeInMb * BYTES_IN_MB;
    maxSizeText = `${options.maxSizeInMb}MB`;
  }

  const hasReachedLimit = applicationDocument?.documents?.length
    ? applicationDocument.documents.length >= multipleFileUploadLimit
    : false;

  let messageHeading = (
    <>
      {isMultiple ? 'Drag and drop multiple files or ' : 'Drag and drop or'}
      <span className="font-semibold border-b-2 border-black whitespace-nowrap">
        {' '}
        {isMultiple ? 'select multiple files to upload' : 'upload here'}
      </span>
    </>
  );

  let messageSubheading = `Max file size: ${maxSizeText}`;

  if (hasReachedLimit) {
    messageHeading = (
      <span className="font-semibold">
        Oops! You have uploaded the maximum number of files (
        {multipleFileUploadLimit}) for this document.
      </span>
    );
    messageSubheading =
      'Try deleting a file in the sidebar under Uploaded Files by clicking on the 3 dots.';
  }

  return (
    <>
      <Dropzone
        accept={accept}
        onDrop={onDropHandler}
        multiple={isMultiple}
        maxSize={maxSize}
        disabled={hasReachedLimit}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps({
              className: `${CLASSES_BASE} ${className}`,
            })}
          >
            <input {...getInputProps()} />
            <div
              className={
                'flex justify-center items-center space-x-4 ' +
                (size === 'default' ? 'py-12' : 'py-4')
              }
            >
              {!isLoading ? (
                <div>
                  {hasReachedLimit ? (
                    <AlertTriangle
                      size={40}
                      className="text-gray-500 hover:text-gray-700 mx-auto mb-3"
                    />
                  ) : (
                    <UploadCloud
                      size={40}
                      className="text-gray-500 hover:text-gray-700 mx-auto mb-3"
                    />
                  )}
                  <Text.Paragraph className="text-center fs16 my-auto pb-3">
                    {messageHeading}
                  </Text.Paragraph>
                  <Text.Paragraph className="text-center fs14 text-gray-400">
                    {messageSubheading}
                  </Text.Paragraph>
                </div>
              ) : (
                <div className="w-full flex flex-col items-center justify-center">
                  <div className="w-12 h-12 flex justify-center items-center">
                    <LoadingSpinner show={true} />
                  </div>
                  <Text.Paragraph className="fs14 text-gray-400">
                    Uploading files...
                  </Text.Paragraph>
                </div>
              )}
            </div>
          </div>
        )}
      </Dropzone>
      <div className="text-center">{textLoading}</div>
    </>
  );
};

export default Upload;
