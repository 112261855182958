import { QUESTION_INPUT_VARIATIONS } from 'dataLayer/Question';
import { FC } from 'react';

import { MultiStageFormPage } from '../MultiStageForm/MultiStageFormPage';

const tmp = [
  'bg-white',
  'w-full',
  'p-4',
  'md:p-6',
  'border',
  'border-visto-gray',
  'rounded-lg',
  'flex',
  'flex-wrap',
  'justify-between',
  'mb-3',
];

const variations: { [Key in QUESTION_INPUT_VARIATIONS]: string[] } = {
  'dynamic-form': tmp,
  'dynamic-form-basic': [],
  standard: tmp,
  'getting-started': ['flex', 'flex-col', 'justify-between'],
  header: [],
  'no-titles': [],
};

export const QuestionGroupRenderer: FC<{
  variation?: QUESTION_INPUT_VARIATIONS;
  pageID?: string;
  labels: string[];
}> = ({
  children,
  variation = QUESTION_INPUT_VARIATIONS.STANDARD,
  pageID,
  labels,
}) => {
  if (variation === QUESTION_INPUT_VARIATIONS.GETTING_STARTED)
    return (
      <MultiStageFormPage pageID={pageID as string} labels={labels}>
        {children}
      </MultiStageFormPage>
    );

  return <div className={variations[variation].join(' ')}>{children}</div>;
};
