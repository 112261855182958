/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable sonarjs/cognitive-complexity */
import { FormManager } from '@visto-tech/forms';
import { get, has, isArray, isEmpty } from 'lodash';
import { makeAutoObservable, toJS } from 'mobx';
import { formatDateForForms } from 'utils/helpers';
import logger from 'utils/logger';

enum CUSTOM_REPEATER_QUESTION_MAP {
  FAMILY_MEMBERS = 'CUS_1',
  FAMILY_INFO_CHILDREN = 'CUS_2',
  EMPLOYMENT_INFO = 'CUS_3',
  PREVIOUS_COUNTRY = 'CUS_4',
  EDUCATION_BACKGROUND = 'CUS_5',
  WORK_EXPERIENCE = 'CUS_6',
  PROJECTS = 'CUS_7',
  MILITARY_SERVICE = 'CUS_8',
  POLITICAL_GROUP = 'CUS_9',
  PRISONER_TREATMENT = 'CUS_10',
}

export const CUSTOM_REPEATER_QUESTIONS = [
  CUSTOM_REPEATER_QUESTION_MAP.FAMILY_MEMBERS,
  CUSTOM_REPEATER_QUESTION_MAP.FAMILY_INFO_CHILDREN,
  CUSTOM_REPEATER_QUESTION_MAP.EMPLOYMENT_INFO,
  CUSTOM_REPEATER_QUESTION_MAP.PREVIOUS_COUNTRY,
  CUSTOM_REPEATER_QUESTION_MAP.EDUCATION_BACKGROUND,
  CUSTOM_REPEATER_QUESTION_MAP.WORK_EXPERIENCE,
  CUSTOM_REPEATER_QUESTION_MAP.PROJECTS,
  CUSTOM_REPEATER_QUESTION_MAP.MILITARY_SERVICE,
  CUSTOM_REPEATER_QUESTION_MAP.POLITICAL_GROUP,
  CUSTOM_REPEATER_QUESTION_MAP.PRISONER_TREATMENT,
];

export const CUSTOM_REPEATER_QUESTION_ERROR_MESSAGE =
  'Please make sure the field has a value';

export class QuestionRepeaterManager {
  state: any[];
  newItem: any;
  formManager: FormManager<any>;
  label: string;
  structure: any;

  constructor(
    initialState: any,
    structure: Record<string, string | Record<string, string | boolean>>,
    formManager: FormManager<any>,
    label: string
  ) {
    this.label = label; // Always initiate before this.state
    this.state = this.transformInitialState(initialState, structure);
    this.newItem = this.transformInitialData(structure);
    this.formManager = formManager;
    this.structure = structure;

    makeAutoObservable(this);
  }

  public add = () => {
    const state = toJS(this.state);
    state.push(toJS(this.newItem));
    this.state = state;
  };

  public delete = (groupId: number) => {
    this.state = this.state.filter((_: any, i: number) => {
      return i !== groupId;
    });
  };

  public getState = () => {
    return this.removeEmptyObjects(this.state);
  };

  public stringify = () => {
    return JSON.stringify(this.removeEmptyObjects(this.state));
  };

  public handleOnChange = (e: any) => {
    let value: string;
    let name: string;
    let groupId: number | string | undefined;

    if (e && has(e, 'date')) {
      // If its the date input
      value = formatDateForForms(e.date);
      name = e.name;
      groupId = e.groupId;
    } else if (e && e?.currentTarget && has(e.currentTarget, 'checked')) {
      // If its a checkbox input
      value = e.currentTarget.checked;
      name = e.currentTarget.name;
      groupId = e.currentTarget.dataset.group;
    } else if (e && has(e, 'wysiwyg')) {
      // If its a WYSIWYG input
      value = e?.wysiwyg;
      name = e?.name;
      groupId = e?.groupId;
    } else {
      value = e.currentTarget.value;
      name = e.currentTarget.name;
      groupId = e.currentTarget.dataset.group;
    }

    if (!groupId && groupId !== 0) return null;

    groupId = Number(groupId);
    this.onChange(name, value, groupId);
  };

  private onChange = (name: string, value: string, groupId: number) => {
    this.state = this.state.map((group: any, i: number) => {
      Object.keys(group).map((key) => {
        if (
          group[key].validate &&
          (!group[key].value || group[key].value.length === 0)
        ) {
          group[key].hasError = true;
        } else {
          group[key].hasError = false;
        }
      });

      this.handleConditionals(name, groupId, value);

      if (i === groupId) {
        return {
          ...group,
          [name]: {
            value: value ?? '',
            validate: has(this.structure[name], 'validate')
              ? this.structure[name]?.validate
              : true,
            hasError: !value || value.length === 0 ? true : false,
          },
        };
      }

      return group;
    });

    // Clear errors for this question only
    this.formManager.errors = this.formManager.errors.filter(
      ({ path }) => path !== this.label
    );
  };

  public hasError = (
    question: any,
    groupId: number,
    formManager: FormManager<any>
  ) => {
    const group = this.state[groupId];

    if (!group) return false;

    const hasError = group[question].hasError;
    const shouldValidate = group[question].validate;

    return formManager.hasErrors() && hasError && shouldValidate;
  };

  public errorMessage = (
    question: any,
    groupId: number,
    formManager: FormManager<any>
  ): string => {
    return this.hasError(question, groupId, formManager)
      ? CUSTOM_REPEATER_QUESTION_ERROR_MESSAGE
      : '';
  };

  // This method gets called when the user Saves (Publishes) the DynamicApiForm
  public static handleFormSave = (formManager: FormManager<any>, data: any) => {
    const repeatersWithErrors: string[] = [];
    const formData = data;

    CUSTOM_REPEATER_QUESTIONS.map((questionLabel) => {
      const hasNoValue = QuestionRepeaterManager.checkIfQuestionHasNoValue(
        formData,
        formManager,
        questionLabel
      );

      const isValidJson = QuestionRepeaterManager.isStringJson(
        formData[questionLabel]
      );

      if (hasNoValue && !isValidJson) {
        return null;
      }

      const questionData: any[] = JSON.parse(formData[questionLabel]);

      const transformedQuestions = isArray(questionData)
        ? questionData.map((group: any) => {
            const obj: Record<any, any> = {};

            Object.keys(group).map((key) => {
              if (
                group[key].validate === true &&
                group[key].hasError === true
              ) {
                repeatersWithErrors.push(questionLabel);
              }

              obj[key] = group[key].value;
            });

            return obj;
          })
        : [];

      // Clean up the answers for the current question to return only the value
      data[questionLabel] = JSON.stringify(transformedQuestions);
    });

    if (!isEmpty(repeatersWithErrors)) {
      repeatersWithErrors.map((label) => {
        formManager.addCustomError(
          CUSTOM_REPEATER_QUESTION_ERROR_MESSAGE,
          label
        );
      });
    }

    return {
      hasErrors: !isEmpty(repeatersWithErrors),
    };
  };

  // This method gets called when the DynamicApiForm does an auto-save
  public static handleFormDraft = (formManager: FormManager<any> | null) => {
    if (!formManager?.formData) return null;

    CUSTOM_REPEATER_QUESTIONS.map((questionLabel) => {
      const hasNoValue = QuestionRepeaterManager.checkIfQuestionHasNoValue(
        formManager.formData,
        formManager,
        questionLabel
      );

      const isValidJson = QuestionRepeaterManager.isStringJson(
        `${formManager.formData[questionLabel]}`
      );

      if (hasNoValue && !isValidJson) {
        return null;
      }

      // remove not used properties only maintain the .value
      const questionData = JSON.parse(`${formManager.formData[questionLabel]}`);
      const questionDataTransformed = isArray(questionData)
        ? questionData.map((group: any) => {
            const obj: Record<any, any> = {};
            Object.keys(group).forEach((key) => {
              const data = group[key];
              const hasValueProp = Object.prototype.hasOwnProperty.call(
                data,
                'value'
              );
              obj[key] = hasValueProp ? data.value : data;
            });
            return obj;
          })
        : [];
      formManager.formData[questionLabel] = JSON.stringify(
        questionDataTransformed
      );
    });
  };

  private transformInitialData = (structure: Record<any, any>) => {
    const obj: Record<any, any> = {};

    Object.keys(structure).map((key) => {
      const isObject = typeof structure[key] === 'object';

      obj[key] = {
        value: '',
        hasError: isObject ? get(structure[key], 'hasError', true) : true,
        validate: isObject ? get(structure[key], 'validate', true) : true,
      };
    });

    return obj;
  };

  private transformInitialState = (initialState: any, structure: any) => {
    if (
      (isArray(initialState) && isEmpty(initialState)) ||
      initialState === '[{}]'
    ) {
      return [];
    }

    let state: any[] = [];

    try {
      state = JSON.parse(initialState);
    } catch (e: any) {
      logger.error(e);
    }

    const transformedState = state.map((group: any) => {
      const obj: Record<any, any> = {};

      Object.keys(group).map((key) => {
        obj[key] = {
          value: group[key],
          hasError: !group[key] || group[key].length === 0,
          validate:
            structure && has(structure[key], 'validate')
              ? structure[key]?.validate
              : true,
        };
      });

      return obj;
    });

    this.state = transformedState;

    this.state.map((group: any, i: number) => {
      Object.keys(group).map((key) => {
        this.handleConditionals(key, i, group[key].value);
      });
    });

    return this.state;
  };

  private static checkIfQuestionHasNoValue = (
    formData: any,
    formManager: FormManager<any>,
    questionLabel: string
  ) => {
    const questionExistsInFormData = Object.prototype.hasOwnProperty.call(
      formData,
      questionLabel
    );

    return (
      !questionExistsInFormData ||
      !formManager.formData[questionLabel] ||
      isEmpty(formManager.formData[questionLabel]) ||
      (formManager.formData[questionLabel] as any[]).length === 0
    );
  };

  private static isStringJson = (jsonString: string) => {
    try {
      const json = JSON.parse(jsonString);

      if (json && typeof json === 'object') {
        return true;
      }
    } catch (e) {
      return false;
    }

    return false;
  };

  private removeEmptyObjects = (arr: any) => {
    return arr.filter((value: any) => Object.keys(value).length !== 0);
  };

  // For some of our repeaters, we have conditional questions, lets handle tracking errors here for those
  private handleConditionals = (
    name: string,
    groupId: number,
    value: string
  ) => {
    if (this.label === CUSTOM_REPEATER_QUESTION_MAP.PREVIOUS_COUNTRY) {
      if (name === 'status') {
        if (this.state[groupId].status.value !== 'Other') {
          this.state[groupId].statusOther.validate = false;
          this.state[groupId].statusOther.value = '';
        } else {
          this.state[groupId].statusOther.validate = true;
        }
      }
    } else if (
      this.label === CUSTOM_REPEATER_QUESTION_MAP.FAMILY_INFO_CHILDREN
    ) {
      if (name === 'stillLiving' && value === 'No') {
        this.state[groupId].address.value = '';
        this.state[groupId].address.validate = false;

        this.state[groupId].occupation.value = '';
        this.state[groupId].occupation.validate = false;

        this.state[groupId].maritalStatus.value = '';
        this.state[groupId].maritalStatus.validate = false;

        this.state[groupId].cityLivingPassed.validate = true;
        this.state[groupId].countryLivingPassed.validate = true;
        this.state[groupId].datePassed.validate = true;
      } else if (name === 'stillLiving' && value === 'Yes') {
        this.state[groupId].cityLivingPassed.value = '';
        this.state[groupId].cityLivingPassed.validate = false;

        this.state[groupId].countryLivingPassed.value = '';
        this.state[groupId].countryLivingPassed.validate = false;

        this.state[groupId].datePassed.value = '';
        this.state[groupId].datePassed.validate = false;

        this.state[groupId].occupation.validate = true;
        this.state[groupId].address.validate = true;
        this.state[groupId].maritalStatus.validate = true;
      }
    } else if (this.label === CUSTOM_REPEATER_QUESTION_MAP.EMPLOYMENT_INFO) {
      this.state[groupId].stateProvince.validate = false;
      this.state[groupId].endDate.validate = false;
    }
  };
}

export default QuestionRepeaterManager;
