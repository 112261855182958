import { FC } from 'react';

interface MiniProps {
  show?: boolean;
}

export const Mini: FC<MiniProps & JSX.IntrinsicElements['div']> = ({
  show = true,
  className,
}) => {
  if (!show) return null;

  return (
    <div className={`animate-pulse w-36 ${className}`}>
      <div className="border border-gray-300 rounded-md p-2 w-full mb-3">
        <div className="flex space-x-2">
          <div className="flex-1 space-y-2 py-1">
            <div className="h-1 bg-gray-300 rounded w-full"></div>
            <div className="h-1 bg-gray-300 rounded w-full"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

Mini.displayName = 'Mini';

export default Mini;
