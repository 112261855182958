/* eslint-disable sonarjs/cognitive-complexity */
import axios, { AxiosResponse } from 'axios';
import backendClient from 'backend';
import { ContentType } from 'generated/graphql';
import logger from 'js-logger';
import { head, isEmpty, set } from 'lodash';
import qs from 'qs';

import { AccountTypeNames } from './User';

export class Content {
  /***********
   * OPEN AI *
   ***********/
  static async generate({
    type,
    targetAccountId,
    applicationId,
    personId,
    content,
    documentTypeName,
  }: {
    type: ContentType;
    targetAccountId?: number;
    applicationId?: number | null;
    personId?: number;
    content?: string;
    documentTypeName?: string;
  }) {
    try {
      return (
        await backendClient.generateContent({
          type,
          targetAccountId,
          applicationId,
          personId,
          content,
          documentTypeName,
        })
      ).data?.generateContent;
    } catch (e) {
      logger.error(e);
    }
  }

  /**************
   * STRAPI CMS *
   **************/
  static async getCategories(accountType: AccountTypeNames) {
    const query = qs.stringify({
      populate: {
        icon: '*',
        articles: {
          populate: '*',
        },
      },
    });

    const response: AxiosResponse | undefined = await Content.fetch(
      `api/categories?${query}`
    );

    const data = response?.data.data;

    if (!data) return null;

    return Content.filterCategoriesAndArticlesByAccountType(data, accountType);
  }

  static async getCategory(slug: string, accountType: AccountTypeNames) {
    const query = qs.stringify(
      {
        filters: {
          slug: {
            $eq: slug,
          },
        },
        populate: {
          articles: {
            populate: '*',
          },
        },
      },
      {
        encodeValuesOnly: true,
      }
    );

    const response: AxiosResponse | undefined = await Content.fetch(
      `api/categories?${query}`
    );

    const data = response?.data.data;

    if (!data) return null;

    return head(
      Content.filterCategoriesAndArticlesByAccountType(data, accountType)
    );
  }

  static async getArticle(slug: string, accountType: AccountTypeNames) {
    const query = qs.stringify(
      {
        filters: {
          slug: {
            $eq: slug,
          },
        },
        populate: '*',
      },
      {
        encodeValuesOnly: true,
      }
    );

    const response: AxiosResponse | undefined = await Content.fetch(
      `api/articles?${query}`
    );

    if (!response?.data.data) return null;

    const article: any = head(response.data.data);

    article.attributes.content = Content.hydrateArticleContent(
      article.attributes.content
    );

    const isAllowed = article.attributes.account_type.filter(
      (type: any) => type.account_type === accountType
    );

    return !isEmpty(isAllowed) ? article : null;
  }

  static async getAnnouncement({
    viewedKeys,
    accountType,
    greaterThanDate,
    sortBy = 'asc',
  }: {
    viewedKeys?: string[];
    accountType?: AccountTypeNames;
    greaterThanDate?: Date;
    sortBy?: 'asc' | 'desc';
  }) {
    const filters = {
      active: true,
    };

    if (viewedKeys) {
      set(filters, 'unique_key', {
        $notIn: viewedKeys,
      });
    }

    if (greaterThanDate) {
      set(filters, 'createdAt', { $gte: greaterThanDate });
    }

    const query = qs.stringify(
      {
        sort: [`createdAt:${sortBy}`],
        filters,
        populate: '*',
      },
      {
        encodeValuesOnly: true,
      }
    );

    let response: AxiosResponse | undefined = undefined;

    try {
      response = await Content.fetch(`api/announcements?${query}`, false);
    } catch (error: any) {
      throw new Error(error);
    }

    if (!response) {
      return null;
    }

    const announcements: any = response?.data?.data;

    if (isEmpty(announcements)) {
      return null;
    }

    // Filter announcements by Account Type
    return announcements.filter((announcement: any) => {
      return announcement.attributes.account_type.some(
        (announcementAccountType: any) => {
          return announcementAccountType.account_type === accountType;
        }
      );
    });
  }

  static async getTopBarNotification() {
    const response: AxiosResponse | undefined = await Content.fetch(
      `api/top-bar-notification`,
      false
    );

    return response?.data?.data;
  }

  private static hydrateArticleContent(articleContent: string) {
    // Regex for getting text between curly brackets - {{ and }}
    const regex = /{{(.*?)}}/g;

    // Replace all occurrences of {{ url="video-url" }} with the html video component
    return articleContent.replace(regex, (match: any) => {
      const typeRegex = /type="([^"]*)"/;
      const typeMatch = match.match(typeRegex);

      // Check to see if we have an attribute of type="video" in the string
      if (!typeMatch || isEmpty(typeMatch) || typeMatch?.[1] !== 'video') {
        return match;
      }

      const urlRegex = /url="([^"]*)"/;
      const urlMatch = match.match(urlRegex);

      if (!urlMatch || !urlMatch?.[1]) {
        return match;
      }

      const url = match.match(urlRegex)?.[1];

      if (!url) {
        return match;
      }

      const defaultPosterUrl =
        'https://visto-prod-app-files.s3.us-east-1.amazonaws.com/static/visto-video-poster-general-3.jpg';

      return `
        <video preload="none" poster="${defaultPosterUrl}" controls="" width="250">
          <source src="${url}" type="video/mp4">
        </video>
      `;
    });
  }

  private static async fetch(query: string, withAuth = true) {
    try {
      return await axios.get(
        `${process.env.NEXT_PUBLIC_STRAPI_BASE_URL}/${query}`,
        withAuth
          ? {
              headers: {
                Authorization: `Bearer ${process.env.STRAPI_API_KEY}`,
              },
            }
          : undefined
      );
    } catch (err) {
      logger.warn(err);
    }
  }

  private static filterCategoriesAndArticlesByAccountType(
    data: any,
    accountType: AccountTypeNames
  ) {
    const filteredCategories: any[] = [];

    // Filter categories by AccountType
    data.map((category: any) => {
      return category.attributes.articles.data.map((article: any) => {
        return article.attributes.account_type.map((type: any) => {
          if (type.account_type === accountType) {
            const categoryAlreadyExists = filteredCategories.filter(
              (cat) => cat.attributes.slug === category.attributes.slug
            );

            if (!isEmpty(categoryAlreadyExists)) return null;
            filteredCategories.push(category);
          }
        });
      });
    });

    // Filter Articles inside Categories by AccountTypes
    const filteredCategoriesAndArticles = filteredCategories.map(
      (category: any) => {
        category.attributes.articles.data =
          category.attributes.articles.data.filter((article: any) => {
            return !isEmpty(
              article.attributes.account_type.filter((type: any) => {
                return type.account_type === accountType;
              })
            );
          });

        return category;
      }
    );

    return !isEmpty(filteredCategoriesAndArticles)
      ? filteredCategoriesAndArticles
      : null;
  }
}
