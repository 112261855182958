import { ManualEntryForm } from 'generated/graphql';
import logger from 'js-logger';

import backendClient from '../backend';

export enum MetaKey {
  'MANUAL_FORMS' = 'ircc_manual_forms',
}

export class Meta {
  public static async get(key: string) {
    try {
      return (await backendClient.Meta({ key })).data?.Meta;
    } catch (err) {
      logger.error(err);
    }
  }

  public static getManualEntryForm({
    manualEntryForms,
    key,
  }: {
    manualEntryForms?: ManualEntryForm[];
    key?: string | null;
  }) {
    return manualEntryForms?.find((form) => form.id === key);
  }
}
