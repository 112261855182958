import { autorun, makeAutoObservable } from 'mobx';

import { formatServerEnv } from '../toDataAttrs';
import logger from '../utils/logger';

export const isServerSide = typeof window === 'undefined';
export const isClientSide = !isServerSide;
const ENV_ELEMENT_ID = 'environment-vars';

class Environment {
  [Key: string]: string | undefined | ((a?: any) => void);
  deploymentEnvironment: 'local' | 'dev' | 'stg' | 'prod' | undefined;
  region: string | undefined;

  constructor() {
    this.refresh();
    makeAutoObservable(this);
  }

  setupServerSideEnv() {
    return formatServerEnv({ ...process.env });
  }

  setupClientSideEnv() {
    const hasData = Boolean(document.getElementById(ENV_ELEMENT_ID)?.dataset);
    let envVars = hasData
      ? {
          ...(document.getElementById(ENV_ELEMENT_ID)?.dataset as DOMStringMap),
        }
      : {};

    envVars = { ...(envVars || {}) };

    return envVars;
  }

  updateEnv(envVars: { [Key: string]: string | number | undefined }) {
    Object.entries(envVars)
      .filter(([, val]) => val !== undefined)
      .forEach(([key, val]) => (this[key] = val as string));
  }

  refresh() {
    let envVars;

    if (isClientSide) {
      envVars = this.setupClientSideEnv();
      const element = document.getElementById(ENV_ELEMENT_ID) as HTMLDivElement;

      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.type == 'attributes') {
            this.updateEnv(this.setupClientSideEnv());
          }
        });
      });

      observer.observe(element, {
        attributes: true, //configure it to listen to attribute changes
      });
    } else {
      envVars = this.setupServerSideEnv();
    }

    this.updateEnv(envVars);
  }

  isDev() {
    return this.deploymentEnvironment === 'dev';
  }

  isStg() {
    return this.deploymentEnvironment === 'stg';
  }

  isProd() {
    return this.deploymentEnvironment === 'prod';
  }

  delete(property: string) {
    if (this[property]) {
      document.getElementById(ENV_ELEMENT_ID)?.removeAttribute(`data-property`);
      Reflect.deleteProperty(this, property);
    }
  }
}

export const environment = new Environment();

if (isClientSide && environment.isDev()) {
  (window as any).Visto = { environment };
  autorun(() => {
    logger.info('Environment updated, check window.Visto.environment');
  });
}

export default environment;
