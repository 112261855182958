import React, { FC } from 'react';
import { ChevronUp } from 'react-feather';

export const ToggleExpandArrows: FC<
  {
    setIsHidden: (isHidden: boolean) => void;
    isHidden: boolean;
    size?: number;
    as?: 'button' | 'div';
  } & JSX.IntrinsicElements['div']
> = ({ setIsHidden, isHidden, size = 30, className, as = 'button' }) => {
  return (
    <>
      {as === 'button' ? (
        <button
          className={`transform transition duration-500 ease-in-out ${
            isHidden ? 'rotate-180' : ''
          } ${className}`}
          onClick={(e) => {
            e.stopPropagation();
            setIsHidden(!isHidden);
          }}
          type="button"
        >
          <ChevronUp size={size} />
        </button>
      ) : (
        <div
          className={`transform transition duration-400 ease-in-out ${
            isHidden ? 'rotate-180' : ''
          } ${className}`}
        >
          <ChevronUp size={size} />
        </div>
      )}
    </>
  );
};
