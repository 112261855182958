import backendClient from 'backend';
import logger from 'js-logger';

export class AccountSetting {
  static async get({
    key,
    targetAccountId,
  }: {
    key: string;
    targetAccountId?: number | null;
  }) {
    try {
      return (await backendClient.AccountSetting({ key, targetAccountId })).data
        ?.AccountSetting;
    } catch (e) {
      logger.error(e);
    }
  }

  static async updateToggle(data: {
    key: string;
    value: string;
    isEmpty?: boolean;
  }) {
    try {
      return await backendClient.updateAccountSettings({
        data: JSON.stringify(data),
      });
    } catch (e) {
      logger.error(e);
    }
  }
}
