/* eslint-disable @typescript-eslint/no-empty-function */
import { LayoutController } from 'hooks/useLayout';
import React from 'react';

export const LayoutContext = React.createContext<LayoutController>({
  Layout: (() => null) as any,
  currentLayout: 'Base',
  setLayout: () => {},
  Header: null,
  setHeader: () => {},
  Sidebar: null,
  setSidebar: () => {},
  Banner: null,
  setBanner: () => {},
  isLoading: false,
  setIsLoading: () => {},
  loadingMessage: null,
  setLoadingMessage: () => {},
  title: null,
  setTitle: () => {},
  sidebarLocation: null,
  setSidebarLocation: () => {},
});
