import { FormManager } from '@visto-tech/forms';
import { DynamicApiFormValidationSchemaOverride } from 'components/DynamicApiForm/DynamicApiForm';
import { get, has, isEmpty, set, uniqBy } from 'lodash';
import * as yup from 'yup';

import Form from '../dataLayer/Form';
import { Question } from '../dataLayer/Question';

type AnswersGroupedByRepeaterFormAndAnswerGrouping = {
  [formName: string]: {
    [groupingId: string]: string[];
  };
};

export const fromDBForm = (
  dbForm: Form,
  validationSchemaOverride?: DynamicApiFormValidationSchemaOverride
) => {
  const allQuestions: Question[] = getAllQuestionsFromDBForm(dbForm);

  const formData = constructInitialFormData({ allQuestions, dbForm });

  const validationSchemaShape = allQuestions.reduce(
    (obj, question) => ({
      ...obj,
      [question.label]: question.validationSchema,
    }),
    {}
  );
  const repeaterQuestions = allQuestions.filter((q) => q.repeaterForm);
  const repeaterForms = uniqBy(
    repeaterQuestions.map((q) => q.repeaterForm),
    'id'
  ) as Form[];

  const validationSchema = {
    ...validationSchemaShape,
    ...validationSchemaOverride?.schema,
  };

  const fm = new FormManager(
    formData,
    yup.object().shape(validationSchema, validationSchemaOverride?.excludes)
  );

  if (isEmpty(repeaterForms)) {
    return fm;
  }

  const answersGroupedByFormAndAnswerGrouping: AnswersGroupedByRepeaterFormAndAnswerGrouping =
    {};

  repeaterForms.forEach((form) => {
    const formName = form.name;
    let formGroup: any = null;

    if (!has(answersGroupedByFormAndAnswerGrouping, formName)) {
      set(answersGroupedByFormAndAnswerGrouping, formName, {});
    }

    formGroup = get(answersGroupedByFormAndAnswerGrouping, formName);

    // Group all the Repeater Form answers by the groupingId and Form Name
    form.formSubmissions.forEach((submission) => {
      submission.answers.forEach((answer) => {
        const groupingId = answer?.groupingId;
        const questionLabel = answer?.question?.label;
        const answerValue = answer?.answer;

        if (!groupingId || !questionLabel) {
          return null;
        }

        const exists = has(formGroup, groupingId);

        if (groupingId && !exists) {
          set(formGroup, groupingId, {
            [questionLabel]: answerValue,
          });
        } else if (groupingId) {
          set(formGroup[groupingId], questionLabel, answerValue);
        }
      });
    });

    const formQuestionKeys = getAllQuestionsFromDBForm(form).map(
      (q) => q.label
    );

    // Transform the Answers into an array of objects
    const answersGrouped = Object.keys(formGroup).map((key) => {
      formQuestionKeys.map((questionKey) => {
        // If the answer doesn't exist, set it to an empty string
        if (!has(formGroup[key], questionKey)) {
          set(formGroup[key], questionKey, '');
        }
      });

      return formGroup[key];
    });

    const repeaterQuestionLabel = repeaterQuestions.find(
      (q) => q.repeaterForm?.id === form.id
    )?.label as string;

    // const allRepeaterFormQuestions: Question[] =
    //   getAllQuestionsFromDBForm(form);

    // const repeaterFormValidationSchemaShape = allRepeaterFormQuestions.reduce(
    //   (obj, question) => ({
    //     ...obj,
    //     [question.label]: question.validationSchema,
    //   }),
    //   {}
    // );

    fm.setRepeatedFormDataByName(repeaterQuestionLabel, answersGrouped);
  });

  return fm;
};

export function getAllQuestionsFromDBForm(dbForm: Form): Question[] {
  return dbForm.content
    .map((contentItem) =>
      contentItem.isQuestion() ? contentItem : (contentItem as any).questions
    )
    .flat(Infinity);
}

function constructInitialFormData({
  allQuestions,
  dbForm,
  blank,
}: {
  allQuestions: Question[];
  dbForm: Form;
  blank?: boolean;
}): any {
  return allQuestions
    .map((question) => ({
      [question.label]: getValueForInitialData({ question, dbForm, blank }),
    }))
    .reduce((acc, obj) => ({ ...acc, ...obj }), {});
}

const getInitialDataForRepeaterForm = (
  form: Form,
  { blank }: { blank?: boolean } = {}
) => {
  return constructInitialFormData({
    dbForm: form,
    allQuestions: getAllQuestionsFromDBForm(form),
    blank,
  });
};

function getValueForInitialData({
  question,
  dbForm,
  blank,
}: {
  question: Question;
  dbForm: Form;
  blank?: boolean;
}) {
  if (blank) return '';

  if (question.repeaterForm) {
    return getInitialDataForRepeaterForm(question.repeaterForm, {
      blank: true,
    });
  }

  return dbForm.getLatestAnswerForQuestion(question.label) || '';
}
