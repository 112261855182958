import { GOOGLE_ANALYTICS_ID } from './constants';

// Log the pageview with their URL
export const gaPageView = (url: string, userId?: number) => {
  const args: any = {
    page_path: url,
  };

  if (userId) {
    args.user_id = userId;
  }

  (window as any).gtag('config', GOOGLE_ANALYTICS_ID, args);
};

// Log specific events happening.
export const gaEvent = ({ action, params }: any) => {
  (window as any).gtag('event', action, params);
};
