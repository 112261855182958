import { DisplayQuestionItem } from 'dataLayer/DisplayDynamicForm';
import Text from 'elements/Text';
import { get } from 'lodash';
import { FC } from 'react';
import { Copy, Hash } from 'react-feather';
import toast from 'react-hot-toast';

const QUESTION_DISPLAY_NAME = {
  jobTitle: 'Job title',
  company: 'Company',
  location: 'Location',
  type: 'Type',
  industry: 'Industry',
  startDate: 'Start date',
  endDate: 'End date',
  description: 'Description',
  currentJob: 'Current job',
  occupation: 'Occupation',
  companyName: 'Company name',
  city: 'City',
  country: 'Country',
  stateProvince: 'State or Province',
  province: 'Province',
  name: 'Name',
  position: 'Position',
  details: 'Details',
};

export const DisplayRepeaterFormQuestion: FC<{
  index: number;
  repeaterQuestions: DisplayQuestionItem;
}> = ({ repeaterQuestions, index }) => {
  return (
    <div className=" bg-gray-200 rounded-md p-4 flex items-start">
      <Text.Paragraph className="font-semibold text-gray-600 col-span-2 flex items-center mr-3 mt-1">
        <Hash size={18} />
        {index + 1}
      </Text.Paragraph>
      <div className="grid md:grid-cols-2 grid-cols-1 gap-3 w-full">
        {Object.keys(repeaterQuestions).map((key: string) => {
          const repeaterQuestion = (repeaterQuestions as any)[key];

          if (!repeaterQuestion?.answer) {
            return null;
          }

          return (
            <div
              className="border border-gray-400 rounded-md p-3"
              key={`repeater-question-items-${index}-${repeaterQuestions.label}-${repeaterQuestion?.label}`}
            >
              <div className="flex items-start justify-between">
                <Text.Paragraph className="fs14 font-semibold">
                  {repeaterQuestion?.question}
                </Text.Paragraph>
                <CopyButton value={repeaterQuestion?.answer} />
              </div>
              <Text.Paragraph className="notranslate">
                {repeaterQuestion?.answer}
              </Text.Paragraph>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const DisplayStaticRepeaterQuestion: FC<{
  index: number;
  repeaterQuestions: any;
}> = ({ repeaterQuestions, index }) => {
  return (
    <div className=" bg-gray-200 rounded-md p-4 flex items-start">
      <Text.Paragraph className="font-semibold text-gray-600 col-span-2 flex items-center mr-3 mt-1">
        <Hash size={18} />
        {index + 1}
      </Text.Paragraph>
      <div className="grid md:grid-cols-2 grid-cols-1 gap-3 w-full">
        {Object.keys(repeaterQuestions).map((key: string) => {
          const answer = (repeaterQuestions as any)[key];
          const question = get(QUESTION_DISPLAY_NAME, key, key);

          return (
            <div
              className="border border-gray-400 rounded-md p-3"
              key={`repeater-question-items-${index}-${repeaterQuestions.label}-${key}`}
            >
              <div className="flex items-start justify-between">
                <Text.Paragraph className="fs14 font-semibold">
                  {question}
                </Text.Paragraph>
                <CopyButton value={answer} />
              </div>
              <Text.Paragraph>{answer}</Text.Paragraph>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const DisplayMultiCheckboxQuestionItem: FC<{
  index: number;
  answer: string;
}> = ({ answer, index }) => {
  return (
    <div className="flex justify-between bg-gray-200 rounded-md p-2">
      <Text.Paragraph className="flex items-center notranslate">
        <span className="flex items-center mr-2 fs14 text-gray-500">
          <Hash size={14} style={{ marginTop: '2px' }} />
          {index + 1}
        </span>
        {answer}
      </Text.Paragraph>
      <CopyButton value={answer} />
    </div>
  );
};

export const CopyButton: FC<{ value?: string }> = ({ value }) => {
  return (
    <button
      className="ml-3 opacity-60 hover:opacity-100 hover:text-visto-light-blue notranslate"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();

        navigator.clipboard.writeText(value ?? '');
        toast.success('Answer copied!');
      }}
    >
      <Copy />
    </button>
  );
};
