import Button from 'elements/Button';
import Text from 'elements/Text';
import { isEmpty } from 'lodash';
import React, { FC } from 'react';

import { Modal, MODAL_IMAGES_MAP } from '../../elements/Modal/Modal';

type ConfirmModalProps = {
  modalOpen: boolean;
  setModalOpen: (status: boolean) => void;
  cta?: string;
  ctaSize?: 'medium' | 'large';
  ctaDisabledMessage?: string;
  onConfirm?: () => void;
  image?: keyof typeof MODAL_IMAGES_MAP;
  title?: string;
  closeCta?: string;
  close?: 'text' | 'button';
  closeOnConfirm?: boolean;
  variation?: 'default' | 'medium' | 'large' | 'xl' | 'full';
  onModalClose?: () => void;
  isLoading?: boolean;
  ctaUrl?: string;
};

export const ConfirmModal: FC<
  ConfirmModalProps & JSX.IntrinsicElements['div']
> = ({
  modalOpen,
  setModalOpen,
  onConfirm,
  className,
  children,
  title,
  cta,
  ctaSize = 'medium',
  closeCta,
  image = null,
  close = 'text',
  closeOnConfirm = true,
  variation = 'default',
  onModalClose,
  isLoading = false,
  ctaDisabledMessage,
  ctaUrl,
}) => {
  return (
    <Modal
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      className={className}
      closeCta={closeCta}
      close={close}
      variation={variation}
      onModalClose={onModalClose}
      image={image ?? undefined}
    >
      <Text.Heading
        variant="none"
        className="fs24 text-center font-body font-bold mb-2"
      >
        {title}
      </Text.Heading>
      {children}
      {cta && (
        <>
          <Button
            disabled={!isEmpty(ctaDisabledMessage)}
            isLoading={isLoading}
            className="mx-auto mt-8"
            size={ctaSize}
            link={ctaUrl}
            onClick={() => {
              if (onConfirm) onConfirm();
              if (closeOnConfirm) setModalOpen(false);
            }}
          >
            {cta}
          </Button>
          {ctaDisabledMessage && (
            <Text.Paragraph className="text-center fs12 text-red-700 mt-1 font-semibold">
              {ctaDisabledMessage}
            </Text.Paragraph>
          )}
        </>
      )}
    </Modal>
  );
};

export default ConfirmModal;
