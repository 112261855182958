import { LayoutProps } from 'components/layouts/BaseLayout';
import { LayoutContext } from 'dataLayer/LayoutContext';
import { FC, ReactNode, useContext, useEffect, useMemo, useState } from 'react';

import LayoutComponents from '../components/layouts';
import { Layout } from '../types/Layout';

export type LayoutController = {
  Layout: FC<LayoutProps>;
  currentLayout: Layout;
  setLayout: (layout: Layout) => void;
  Header: ReactNode;
  setHeader: (header: React.ReactNode) => void;
  Sidebar: ReactNode;
  setSidebar: (sidebar: React.ReactNode) => void;
  Banner: ReactNode;
  setBanner: (banner: React.ReactNode) => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  loadingMessage: string | null | undefined;
  setLoadingMessage: (message: string | null) => void;
  title: string | null;
  setTitle: (title: string) => void;
  sidebarLocation: 'top' | 'bottom' | null;
  setSidebarLocation: (location: 'top' | 'bottom') => void;
};

export enum LayoutStatus {
  PERSIST = 'persist', // PERSIST = do not update state, keep whats there
  CLEAR = 'clear', // CLEAR = clear whats there and make it empty to not display it
}

type UseLayoutInput = {
  layout?: Layout;
  layoutOptions?: {
    header?: ReactNode | LayoutStatus;
    sidebar?: ReactNode | LayoutStatus;
    banner?: ReactNode | LayoutStatus;
  };
  dependency?: any[];
  isLoading?: boolean;
  loadingMessage?: string | null;
  title?: string;
  sidebarLocation?: 'top' | 'bottom';
};

export const useManagedLayout = ({
  layout: _layout,
  layoutOptions = { header: null, sidebar: null, banner: null },
}: UseLayoutInput): LayoutController => {
  const [layout, setLayout] = useState(_layout);
  const [Header, setHeader] = useState<ReactNode>(layoutOptions.header);
  const [Sidebar, setSidebar] = useState<ReactNode>(layoutOptions.sidebar);
  const [Banner, setBanner] = useState<ReactNode>(layoutOptions.banner);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState<string | null>(null);
  const [title, setTitle] = useState<string | null>(null);
  const [sidebarLocation, setSidebarLocation] = useState<
    'top' | 'bottom' | null
  >('bottom');

  const Component = useMemo(() => {
    const _Component: FC<LayoutProps> =
      layout === null ? () => null : LayoutComponents[layout ?? 'Base'];

    _Component.displayName = `useManagedLayout(${layout})`;
    return _Component;
  }, [layout]);

  return {
    Layout: Component,
    currentLayout: layout as any,
    setLayout,
    Header,
    setHeader,
    Sidebar,
    setSidebar,
    Banner,
    setBanner,
    isLoading,
    setIsLoading,
    loadingMessage,
    setLoadingMessage,
    title,
    setTitle,
    sidebarLocation,
    setSidebarLocation,
  };
};

export const useLayout = ({
  layout,
  layoutOptions = {
    header: LayoutStatus.PERSIST,
    sidebar: LayoutStatus.PERSIST,
    banner: LayoutStatus.PERSIST,
  },
  dependency = [],
  isLoading,
  loadingMessage,
  title,
  sidebarLocation = 'bottom',
}: UseLayoutInput) => {
  const layoutController = useContext(LayoutContext);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    if (layout && layoutController.currentLayout !== layout) {
      layoutController.setLayout(layout);
    }

    if (layoutOptions.header !== LayoutStatus.PERSIST) {
      const header =
        layoutOptions.header === LayoutStatus.CLEAR
          ? null
          : layoutOptions.header;
      layoutController.setHeader(header);
    }

    if (layoutOptions.sidebar !== LayoutStatus.PERSIST) {
      const sidebar =
        layoutOptions.sidebar === LayoutStatus.CLEAR
          ? null
          : layoutOptions.sidebar;
      layoutController.setSidebar(sidebar);
    }

    if (layoutOptions.banner !== LayoutStatus.PERSIST) {
      const banner =
        layoutOptions.banner === LayoutStatus.CLEAR
          ? null
          : layoutOptions.banner;
      layoutController.setBanner(banner);
    }

    if (isLoading !== undefined && isLoading !== layoutController.isLoading) {
      layoutController.setIsLoading(isLoading);
    }

    if (
      loadingMessage !== undefined &&
      loadingMessage !== layoutController.loadingMessage
    ) {
      layoutController.setLoadingMessage(loadingMessage);
    }

    if (title) {
      layoutController.setTitle(title);
    }

    if (sidebarLocation) {
      layoutController.setSidebarLocation(sidebarLocation);
    }
  }, dependency);
};
