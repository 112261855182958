import { FormSubmissionStatus, QuestionInputType } from 'generated/graphql';
import { fromDBForm } from 'hooks/fromDBForm';
import logger from 'js-logger';
import { get, has, head, isEmpty, set } from 'lodash';
import { parseJSON } from 'utils/helpers';

import Form from './Form';

export interface DisplayDynamicFormItemType {
  name: string;
  associations?: any;
  url?: string;
}

export interface DisplayQuestionItem {
  question: string;
  answer: string;
  inputType: QuestionInputType;
  label: string;
  repeaterForm?: DisplayQuestionItem[];
  answerOptions?: string[];
  url?: string;
}

export interface DisplayDynamicFormStaticAnswers {
  label: string;
  answer: string;
  question: string;
  inputType: QuestionInputType;
  repeaterForm?: DisplayQuestionItem[];
}

export class DisplayDynamicForm {
  forms: DisplayDynamicFormItemType[];
  targetAccountId: number;
  allQuestions: Record<string, DisplayQuestionItem>;
  staticAnswers?: DisplayDynamicFormStaticAnswers[];

  constructor(
    forms: DisplayDynamicFormItemType[],
    targetAccountId: number,
    staticAnswers?: DisplayDynamicFormStaticAnswers[]
  ) {
    this.forms = forms;
    this.targetAccountId = targetAccountId;
    this.allQuestions = {};
    this.staticAnswers = staticAnswers;
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  public async fetchQuestions() {
    await this.forms.reduce(async (prom, form) => {
      await prom;

      let dbForm;

      try {
        dbForm = await Form.getFormByName({
          name: form.name,
          targetAccountId: this.targetAccountId,
          associations: form.associations,
          formSubmissionStatus: FormSubmissionStatus.Published,
          excludeFormId: false,
        });
      } catch (err) {
        logger.error(err);
      }

      if (!dbForm) {
        return;
      }

      const formManager = fromDBForm(dbForm);

      dbForm?.content.forEach((questionGroup: any) => {
        questionGroup.questions.forEach((question: any) => {
          const shouldAdd =
            question.inputType !== QuestionInputType.Heading &&
            question.optimisticallyMeetsAllConditions(formManager);

          if (shouldAdd) {
            const obj: DisplayQuestionItem = {
              question: question.question,
              answer: (head(question.answers) as any)?.answer,
              inputType: question.inputType,
              label: question.label,
              repeaterForm: undefined,
              url: form.url,
            };

            if (question.inputType === QuestionInputType.Repeater) {
              obj.repeaterForm = DisplayDynamicForm.getRepeaterForm(question);
            }

            if (question.inputType === QuestionInputType.MultiCheckbox) {
              obj.answerOptions =
                DisplayDynamicForm.getMultiCheckboxAnswers(question);
            }

            // If its the first one, assign it to the object
            if (isEmpty(this.allQuestions)) {
              this.allQuestions = {
                [question.label]: obj,
              };

              return null;
            }

            // If already exists, skip
            if (has(this.allQuestions, question.label)) {
              return null;
            }

            // If does not exist, add
            set(this.allQuestions, question.label, obj);
          }
        });
      });
    }, Promise.resolve());

    if (this.staticAnswers) {
      this.staticAnswers.forEach((staticAnswers) => {
        const obj: DisplayQuestionItem = {
          question: staticAnswers.question,
          answer: staticAnswers.answer,
          inputType: staticAnswers.inputType,
          label: staticAnswers.label,
        };

        if (staticAnswers.inputType === QuestionInputType.Repeater) {
          obj.repeaterForm = staticAnswers.repeaterForm;
        }

        // If its the first one, assign it to the object
        if (isEmpty(this.allQuestions)) {
          this.allQuestions = {
            [staticAnswers.label]: obj,
          };

          return null;
        }

        // If already exists, skip
        if (has(this.allQuestions, staticAnswers.label)) {
          return null;
        }

        // If does not exist, add
        set(this.allQuestions, staticAnswers.label, obj);
      });
    }
  }

  static getMultiCheckboxAnswers(question: any) {
    if (isEmpty(question.answers)) {
      return [];
    }

    const answerOptionIds = parseJSON((head(question.answers) as any).answer);
    const questionOptions = question.questionOptions;

    return answerOptionIds.map((answerOptionId: string) => {
      return questionOptions.find(
        (questionOption: any) => questionOption.id === Number(answerOptionId)
      ).option;
    });
  }

  static getRepeaterForm(question: any): DisplayQuestionItem[] {
    const repeaterForm = question.repeaterForm;
    const groupings: any = {};

    // Group all the Repeater Form answers by the groupingId
    repeaterForm?.content?.forEach((question: any) => {
      question.answers
        .slice()
        .sort((a: any, b: any) => a.id - b.id) // This sort is important to put the newest answers first
        .forEach((answer: any) => {
          const groupingId = answer?.groupingId;
          const questionLabel = answer?.question?.label;
          const answerValue = answer?.answer;

          if (!groupingId || !questionLabel) {
            return null;
          }

          const obj = {
            question: question.question,
            answer: answerValue,
            inputType: question.inputType,
            label: questionLabel,
          };

          const exists = has(groupings, groupingId);

          if (groupingId && !exists) {
            set(groupings, groupingId, {
              [questionLabel]: obj,
            });
          } else if (groupingId) {
            set(groupings[groupingId], questionLabel, obj);
          }
        });
    });

    // Convert object of object to array of objects
    return Object.keys(groupings).map((key) => {
      return groupings[key];
    });
  }

  static getStaticRepeaterAnswers(question: any) {
    const newestAnswer: any = head(question?.answers) ?? null;

    if (!newestAnswer) {
      return [];
    }

    return parseJSON((newestAnswer?.answer as string) ?? '');
  }

  public getQuestions() {
    return this.allQuestions;
  }

  public getQuestion(label: string): DisplayQuestionItem | undefined {
    return get(this.allQuestions, label, undefined);
  }
}
