import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { WebSocketLink } from 'apollo-link-ws';
import { GraphQLClient } from 'graphql-request';
import { isEmpty } from 'lodash';
import {
  ALLOWED_UPLOAD_RESOLVERS,
  UPLOAD_ENDPOINT_PATH,
} from 'utils/constants';

import { environment, isServerSide } from './dataLayer/Environment';
import { getSdk } from './generated/graphql';

const getClient = () => {
  const urlPrefix = (environment?.nextauthUrl || '') as string;
  if (isServerSide && environment.isDocker)
    urlPrefix.replace('localhost', 'host.docker.internal');
  const url = urlPrefix + `/gql${environment.isDev() ? '-dev' : ''}`;
  const client = new GraphQLClient(url);
  const link = isServerSide
    ? createHttpLink({
        uri: url,
      })
    : new WebSocketLink({
        uri: `${environment?.nextPublicSocketUrl}`,
        options: {
          reconnect: true,
        },
      });
  const apolloClient = new ApolloClient({
    uri: url,
    cache: new InMemoryCache(),
    link: link as any,
    ssrMode: isServerSide,
  });

  const sdk = getSdk(client);
  const uploadClient = new GraphQLClient(UPLOAD_ENDPOINT_PATH);
  const sdkUpload = getSdk(uploadClient);

  ALLOWED_UPLOAD_RESOLVERS.forEach((e) => {
    (sdk as any)[e] = (sdkUpload as any)[e];
  });

  (sdk as any).setHeader = (string: any, cookie: any) => {
    client.setHeader(string, cookie);
  };

  (sdk as any).apolloClient = apolloClient;

  return sdk as typeof sdk & {
    apolloClient: typeof apolloClient;
  };
};

export const makeHeaders = ({ token }: { token?: string }) => {
  const requestHeaders: {
    Authorization?: string;
  } = {};

  if (token) {
    requestHeaders.Authorization = `Bearer ${token}`;
  }

  return !isEmpty(requestHeaders) ? requestHeaders : undefined;
};

export const backendClient = getClient();

export default backendClient;
