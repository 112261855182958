/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */
import { QUESTION_INPUT_VARIATIONS } from 'dataLayer/Question';
import { Button } from 'elements/Button';
import Loading from 'elements/Loading';
import {
  FormQueryInputOptions,
  SubmitFormMutationArgs,
} from 'generated/graphql';
import { reaction } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { AlertTriangle } from 'react-feather';
import toast from 'react-hot-toast';

import Form from '../../dataLayer/Form';
import FormSubmission from '../../dataLayer/FormSubmission';
import { Text } from '../../elements/Text';
import {
  formDataToAnswersPayload,
  useManagedDatabaseForm,
} from '../../hooks/useManagedDatabaseForm';
import { QuestionGroups } from '../QuestionGroups/QuestionGroups';
import { DynamicFormFaker } from './DynamicApiFormFaker';
import { DynamicApiFormStatus, DynamicFormHeader } from './DynamicFormHeader';
import { FormSubmitModal } from './FormSubmitModal';

const variations: { [Key in QUESTION_INPUT_VARIATIONS]: string[] } = {
  'getting-started': ['flex', 'flex-col', 'justify-between', 'relative'],
  standard: [],
  'dynamic-form': ['flex', 'flex-wrap', 'justify-between'],
  'dynamic-form-basic': ['flex', 'flex-wrap', 'justify-between'],
  header: [],
  'no-titles': [],
};

export interface HideComponents {
  formHeading?: boolean;
  groupHeadings?: boolean;
  cta?: boolean;
  faker?: boolean;
}

export interface DynamicApiFormValidationSchemaOverride {
  schema?: Record<string, any>;
  excludes?: [string, string][];
}

interface DynamicApiFormProps {
  formName: string;
  variation: QUESTION_INPUT_VARIATIONS;
  afterSubmit?: (result: Partial<FormSubmission>) => any;
  targetAccountId?: number;
  associations?: { [key: string]: any };
  disabled?: boolean;
  hide?: HideComponents;
  options?: FormQueryInputOptions;
  submitFormArgs?: SubmitFormMutationArgs;
  validationSchemaOverride?: DynamicApiFormValidationSchemaOverride;
  questionLabelsToRemove?: string[];
  config?: {
    cta?: {
      size?: 'medium' | 'large';
    };
    disableSaveDraft?: boolean;
  };
}

export const DynamicApiForm: FC<
  DynamicApiFormProps & JSX.IntrinsicElements['form']
> = observer(
  ({
    formName,
    afterSubmit,
    associations,
    variation = QUESTION_INPUT_VARIATIONS.STANDARD,
    targetAccountId,
    disabled = false,
    submitFormArgs,
    validationSchemaOverride,
    questionLabelsToRemove,
    hide = {
      groupHeadings: false,
      cta: false,
    },
    options = {
      excludeFormId: false,
    },
    className,
    config,
  }) => {
    const [hasDraft, setHasDraft] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const isGettingStarted =
      variation === QUESTION_INPUT_VARIATIONS.GETTING_STARTED;
    const isBasic = variation === QUESTION_INPUT_VARIATIONS.DYNAMIC_FORM_BASIC;
    const isNoTitles = variation === QUESTION_INPUT_VARIATIONS.NO_TITLES;
    const {
      form,
      formManager,
      failed,
      isLoading,
      isSaving,
      handleFormSave,
      currentStatus,
    } = useManagedDatabaseForm({
      formName,
      targetAccountId,
      associations,
      excludeFormId: options?.excludeFormId ?? false,
      validationSchemaOverride,
      questionLabelsToRemove,
    });

    useEffect(() => {
      if (!formManager) {
        return;
      }

      const _disposer = reaction(
        () => formManager.getFormData({ withRepeatables: true }),
        () => {
          if (!isSaving && config?.disableSaveDraft !== true) {
            handleFormSave();
          }
        }
      );

      if (isSaving || currentStatus === 'DRAFT') {
        setHasDraft(true);
      }

      return () => {
        _disposer();
      };
    }, [formManager, handleFormSave, isSaving]);

    useEffect(() => {
      if (formManager?.hasErrors()) {
        (document as Document)
          .querySelector(`.border-red-600`)
          ?.scrollIntoView({
            behavior: 'smooth',
          });
      }
    }, [formManager?.hasErrors()]);

    const handleSubmit = useMemo(() => {
      if (failed || isLoading || !formManager || !form) {
        return null;
      }

      return formManager.createSubmitHandler(async (_, data) => {
        setIsModalOpen(false);

        const promise = Form.submit(
          {
            formName,
            answers: Object.entries(data).flatMap(
              formDataToAnswersPayload(form)
            ),
            associations: JSON.stringify(associations),
          },
          targetAccountId,
          submitFormArgs
        );

        toast.promise(promise, {
          loading: 'Saving',
          success: 'Saved',
          error: 'Sorry, something went wrong. Please try again.',
        });

        const result = await promise;

        afterSubmit?.(result as Partial<FormSubmission>);

        setHasDraft(false);
      });
    }, [
      afterSubmit,
      associations,
      failed,
      form,
      formManager,
      formName,
      isLoading,
      targetAccountId,
      submitFormArgs,
    ]) as React.FormEventHandler<HTMLFormElement>;

    if (failed) {
      return <FormFailedMessage />;
    }

    if (isLoading) {
      return <Loading.Form />;
    }

    if (!formManager) {
      return null;
    }

    return (
      <>
        <form
          id={`form-${formName}`}
          onSubmit={handleSubmit}
          className={`dynamicApiForm notranslate ${variations[variation].join(
            ' '
          )} ${className ?? ''}`}
        >
          {hide?.faker !== true && (
            <DynamicFormFaker
              className="mb-3 "
              formManager={formManager}
              form={form}
            />
          )}
          {!isGettingStarted && !isNoTitles && hide?.formHeading !== true && (
            <DynamicFormHeader
              formManager={formManager}
              currentStatus={currentStatus}
              hasDraft={hasDraft}
              form={form}
              setIsModalOpen={setIsModalOpen}
              disabled={disabled}
              variation={variation}
            />
          )}
          <fieldset
            className={`w-full ${
              disabled
                ? 'pointer-events-none opacity-70 appearance-none no-appearance'
                : ''
            }`}
            disabled={disabled}
          >
            <QuestionGroups
              variation={variation}
              formManager={formManager}
              formName={formName}
              form={form}
            >
              {form?.render({
                disabled,
                hide,
                formManager,
                variation,
                targetAccountId,
                submitFormArgs,
              })}
            </QuestionGroups>
          </fieldset>
          {isBasic && (
            <div className="w-full flex justify-center translate">
              <Button
                onClick={() => {
                  formManager.clearErrors();
                  setIsModalOpen(true);
                }}
                type="button"
                size={config?.cta?.size ?? 'medium'}
              >
                Save
              </Button>
            </div>
          )}
          {isNoTitles && !hide.cta && (
            <div className="flex justify-between md:justify-end items-center">
              <DynamicApiFormStatus
                hasDraft={hasDraft}
                currentStatus={currentStatus as string}
              />
              <Button
                className={`translate ${
                  currentStatus == 'DRAFT' || hasDraft ? '' : 'md:mx-0'
                } px-10`}
                disabled={!hasDraft}
                isLoading={formManager.isSubmitting}
                value="PUBLISHED"
                type="submit"
                form={`form-${form?.name}`}
                onClick={() => formManager.clearErrors()}
              >
                Save
              </Button>
            </div>
          )}
        </form>

        {!isGettingStarted && (
          <FormSubmitModal
            formManager={formManager}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            formName={formName}
          >
            <Text.Paragraph className="text-center mb-6">
              Please ensure that you have carefully reviewed the information in
              your web form before submitting.
            </Text.Paragraph>
          </FormSubmitModal>
        )}
      </>
    );
  }
);

export const FormFailedMessage: FC<JSX.IntrinsicElements['div']> = ({
  className,
}) => {
  return (
    <div
      className={`border border-gray-300 rounded-md p-8 flex items-center flex-col ${
        className ?? ''
      }`}
    >
      <AlertTriangle className="mb-5 animate-pulse" size={32} />
      <Text.Paragraph className="text-center font-bold fs18 mb-1">
        Form Error
      </Text.Paragraph>
      <Text.Paragraph className="text-center">
        Sorry, we can't load this form right now. Please try reloading the page.
      </Text.Paragraph>
    </div>
  );
};

export const FormEmptyMessage: FC<JSX.IntrinsicElements['div']> = ({
  className,
}) => {
  return (
    <div
      className={`border border-gray-300 rounded-md p-8 flex items-center flex-col ${
        className ?? ''
      }`}
    >
      <AlertTriangle className="mb-5 animate-pulse" size={32} />
      <Text.Paragraph className="text-center font-bold fs18 mb-1">
        Form is Empty
      </Text.Paragraph>
      <Text.Paragraph className="text-center">
        Sorry, we can't load this form right now because there are no linked
        questions.
      </Text.Paragraph>
    </div>
  );
};

DynamicApiForm.displayName = 'DynamicApiForm';
