import { ActiveUserPermission, Application } from 'generated/graphql';
import Link from 'next/link';
import { FC, forwardRef } from 'react';
import type { Icon } from 'react-feather';
import * as Icons from 'react-feather';

import User, { AccountTypeNames } from '../../dataLayer/User';
import { NAVIGATION_ITEM_TYPES } from './NavigationItem';

export interface NavigationItemProps {
  type: NAVIGATION_ITEM_TYPES;
  name: string;
  icon?: Icon | string | null;
  image?: 'BOT';
  allowedRoles: AccountTypeNames[];
  requiredPermissions?: ActiveUserPermission[]; // This works as an ANY, only one needs to be true
  requiredPermissionGroups?: string[]; // This works as an ANY, only one needs to be true
  showIf?: (
    user?: User | null,
    accountSubscription?: any,
    applications?: Application[]
  ) => Promise<boolean | string> | (boolean | string);
}

const InternalLink = forwardRef((props, ref) => {
  const { href, children, ...rest } = props as any;
  return (
    <Link href={href} ref={ref} {...rest}>
      {children}
    </Link>
  );
});
InternalLink.displayName = 'InternalLink';

export const getLinkComponentForType = (type: NAVIGATION_ITEM_TYPES) => {
  if (type === 'external-link') return 'a';
  if (type === 'internal-link') return InternalLink;
  if (type === 'non-link-action') return 'button';
};

export type DynamicLinkProps = (
  | JSX.IntrinsicElements['a']
  | JSX.IntrinsicElements['button']
) & {
  type: NAVIGATION_ITEM_TYPES;
  icon?: keyof typeof Icons;
};

export const DynamicLink: FC<DynamicLinkProps> = ({
  children,
  type,
  ...props
}) => {
  const Component: any = getLinkComponentForType(type);
  return <Component {...props}>{children}</Component>;
};
