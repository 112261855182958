/* eslint-disable sonarjs/no-duplicate-string */
import lodash, { get, isEmpty, isNumber } from 'lodash';
import { computed, makeAutoObservable } from 'mobx';
import { STRIPE_PRODUCT_ID_MAP } from 'static/Payments.static';

import backendClient, { makeHeaders } from '../backend';
import {
  ActiveUserPermission,
  HandleAuthAction,
  Maybe,
  MutationUpdateUserArgs,
  PermissionOperation,
  Scalars,
  UpdateUserAction,
  User as RawUser,
  UsersFilters,
  UsersQueryVariables,
} from '../generated/graphql';
import logger from '../utils/logger';
import { Account } from './Account';
import environment from './Environment';

export type AccountTypeNames =
  | 'SUPER_ADMIN'
  | 'LAWYER'
  | 'TALENT'
  | 'COMPANY'
  | 'APPLICANT'
  | 'SALES'
  | 'COPILOT';

export type AccountSubTypeNames = 'PROSPECT';

export class User implements RawUser {
  id: number;
  active: boolean | null | undefined;
  firstName?: Maybe<string>;
  lastName?: Maybe<string>;
  email: string;
  createdAt: Date;
  updatedAt: string;
  account: Account;
  hasCompletedGettingStarted: boolean | null | undefined;
  hasNewNotifications: boolean | null | undefined;
  avatarKey?: Maybe<string>;
  permissions?: (ActiveUserPermission | null)[] | null | undefined;
  isPrimaryUser?: Maybe<boolean>;
  permissionGroups?: (string | null)[] | null | undefined;
  productsPurchased?: (string | null)[] | null | undefined;
  hasLawyerAssociation?: Maybe<boolean>;
  viewed?: Maybe<Scalars['JSON']>;
  mfaActive?: Maybe<Scalars['Boolean']>;
  userPermissions?: (ActiveUserPermission | null)[] | null | undefined;

  constructor(user: RawUser) {
    if (!user) {
      throw new Error(
        'You cannot create a user instance without passing in user data.'
      );
    }

    this.id = user.id;
    this.active = user.active;
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.email = user.email;
    this.createdAt = user.createdAt;
    this.updatedAt = user.updatedAt;
    this.account = new Account(user.account);
    this.hasCompletedGettingStarted = user.hasCompletedGettingStarted;
    this.hasNewNotifications = user.hasNewNotifications;
    this.avatarKey = user.avatarKey;
    this.permissions = user.permissions;
    this.isPrimaryUser = user.isPrimaryUser;
    this.permissionGroups = user.permissionGroups;
    this.productsPurchased = user.productsPurchased;
    this.hasLawyerAssociation = user.hasLawyerAssociation;
    this.viewed = user.viewed;
    this.mfaActive = user.mfaActive ?? false;
    this.userPermissions = user.userPermissions;

    makeAutoObservable(this, { type: computed });
  }

  get type() {
    return this.account.accountType?.name;
  }

  get subType() {
    return this.account.accountSubType?.name;
  }

  isType(name: AccountTypeNames, subName?: AccountSubTypeNames) {
    if (!subName) {
      return this.type === name;
    }

    return this.type === name && this.isSubType(subName);
  }

  isSubType(subName: AccountSubTypeNames) {
    return this.subType === subName;
  }

  static async getActiveUser({
    token,
    ignoreError,
  }: Partial<{
    token?: string;
    ignoreError?: boolean;
  }> = {}) {
    let requestHeaders = undefined;

    if (token) {
      requestHeaders = {
        Authorization: `Bearer ${token}`,
      };
    }

    try {
      const { data } = await backendClient.getActiveUser({}, requestHeaders);

      if (!data?.User) {
        throw new Error('Failed to fetch active user');
      }

      return new User(data.User as User);
    } catch (err) {
      if (ignoreError) {
        return null;
      }

      logger.error(err);
    }
  }

  static async getAllUsers(args?: UsersQueryVariables) {
    try {
      const users = (await backendClient.Users(args)).data?.Users;

      if (!users) throw new Error('Failed to fetch active user');

      return users.map((user) => new User(user as User));
    } catch (err) {
      logger.error(err);
    }
  }

  static async getUsersIncludes({
    targetAccountId,
    filters,
    includes,
  }: Partial<{
    targetAccountId: number;
    filters: UsersFilters;
    includes: {
      account?: boolean;
    };
  }> = {}) {
    try {
      const users = (
        await backendClient.UsersIncludes({
          targetAccountId,
          filters,
          includeAccount: includes?.account ?? false,
        })
      ).data?.Users;

      if (!users) {
        logger.error('Failed to fetch active user');
        return [];
      }

      return users.map((user) => new User(user as User));
    } catch (err) {
      logger.error(err);
    }
  }

  static async getUserIncludes({
    token,
    includes,
  }: Partial<{
    token?: string;
    includes?: {
      subscription?: boolean;
      permissions?: boolean;
      userPermissions?: boolean;
    };
  }> = {}) {
    let requestHeaders = undefined;

    if (token) {
      requestHeaders = {
        Authorization: `Bearer ${token}`,
      };
    }

    try {
      const user = (
        await backendClient.UserIncludes(
          {
            includesSubscription: includes?.subscription ?? false,
            includesPermissions: includes?.permissions ?? false,
            includesUserPermissions: includes?.userPermissions ?? false,
          },
          requestHeaders
        )
      )?.data?.User;

      if (!user) {
        logger.error('Failed to fetch user');
        return null;
      }

      return new User(user as User);
    } catch (err) {
      logger.error(err);
    }
  }

  static async getPublicUser(userId: number) {
    try {
      return (await backendClient.PublicUser({ userId })).data?.PublicUser;
    } catch (err) {
      logger.error(err);
    }
  }

  static async updateUser(args: MutationUpdateUserArgs) {
    try {
      return (await backendClient.updateUser(args)).data?.updateUser;
    } catch (err) {
      logger.error(err);
    }
  }

  static async requestPasswordReset(email: string) {
    try {
      return (
        await backendClient.handleAuth({
          action: HandleAuthAction.RequestResetPassword,
          data: { email },
        })
      ).data?.handleAuth;
    } catch (err) {
      logger.error(err);
    }
  }

  static async doesUserExist(email: string) {
    try {
      return (await backendClient.UserExists({ email })).data?.UserExists;
    } catch (err) {
      logger.error(err);
    }
  }

  static async resetPassword({
    token,
    newPassword,
    newPasswordConfirm,
  }: {
    token: string;
    newPassword: string;
    newPasswordConfirm: string;
  }) {
    try {
      return (
        await backendClient.handleAuth({
          action: HandleAuthAction.ResetPassword,
          data: {
            recoveryToken: token,
            newPassword,
            newPasswordConfirm,
          },
        })
      ).data?.handleAuth;
    } catch (err) {
      logger.error(err);
    }
  }

  static async resendVerificationEmail({
    token,
    email,
  }: {
    token: string;
    email: string;
  }) {
    const headers = makeHeaders({ token });

    try {
      return (
        await backendClient.handleAuth(
          {
            action: HandleAuthAction.ResendVerificationEmail,
            data: {
              email,
            },
          },
          headers
        )
      ).data?.handleAuth;
    } catch (err) {
      logger.error(err);
    }
  }

  static async updateViewed({
    viewedProperty,
    viewedValue,
  }: {
    viewedProperty: string;
    viewedValue: boolean;
  }) {
    await User.updateUser({
      action: UpdateUserAction.Viewed,
      data: {
        viewedProperty,
        viewedValue,
      },
    });
  }

  isVistoUser(): boolean {
    return lodash.has(this, 'email') && this.email.includes('@visto.ai');
  }

  hasPermission(resource: string, operation: PermissionOperation) {
    const permission = this.permissions?.findIndex(
      (perm) => perm?.resource === resource && perm.operation === operation
    );

    return isNumber(permission) ? permission >= 0 : false;
  }

  hasUserPermission(
    resource:
      | 'admin'
      | 'billing'
      | 'applications'
      | 'copilot-draft-template'
      | 'clients',
    operation: PermissionOperation
  ) {
    const permission = this.userPermissions?.findIndex(
      (perm) => perm?.resource === resource && perm.operation === operation
    );

    return isNumber(permission) ? permission >= 0 : false;
  }

  hasAnyPermissionResource(resources: string[]) {
    const hasAnyResource = this.permissions?.filter((permission) =>
      resources.includes(permission?.resource ?? '')
    );

    return hasAnyResource ? hasAnyResource?.length > 0 : false;
  }

  hasPermissionGroup(name: string) {
    const permissionGroups = this.permissionGroups?.findIndex(
      (permissionGroup) => permissionGroup === name
    );

    return isNumber(permissionGroups) ? permissionGroups >= 0 : false;
  }

  hasPurchased(product: string) {
    const stripeProductIds = get(
      STRIPE_PRODUCT_ID_MAP,
      environment.deploymentEnvironment as string
    );

    const hasPurchased = this.productsPurchased?.findIndex(
      (productId) => productId === get(stripeProductIds, product)
    );

    return isNumber(hasPurchased) ? hasPurchased >= 0 : false;
  }

  hasViewed(property: string) {
    if (isEmpty(this.viewed)) return false;

    return get(this.viewed, property, false) === true;
  }

  isAdmin() {
    // We currently only have UserPermissions for the Lawyer accounts
    if (!this.isType('LAWYER')) {
      return this.isPrimaryUser;
    }

    return this.hasUserPermission('admin', PermissionOperation.All);
  }

  hasFeatureUserPermission(
    feature:
      | 'billing'
      | 'application-create'
      | 'copilot-draft-template'
      | 'clients-all'
  ) {
    if (this.isAdmin()) {
      return true;
    }

    if (feature === 'billing') {
      return this.hasUserPermission('billing', PermissionOperation.All);
    } else if (feature === 'application-create') {
      return this.hasUserPermission('applications', PermissionOperation.Create);
    } else if (feature === 'copilot-draft-template') {
      return this.hasUserPermission(
        'copilot-draft-template',
        PermissionOperation.All
      );
    } else if (feature === 'clients-all') {
      return this.hasUserPermission('clients', PermissionOperation.Read);
    }

    return false;
  }
}

export default User;
