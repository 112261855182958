import {
  CreateLawyerAssociationMutationVariables,
  HandleLawyerAssociationAction,
  HandleLawyerAssociationData,
  LawyerAssociationsQueryVariables,
} from 'generated/graphql';
import logger from 'js-logger';
import { isEmpty } from 'lodash';

import backendClient from '../backend';

export class LawyerAssociation {
  public static async findFirst({
    key,
    accountId,
    email,
    id,
  }: {
    key?: string;
    accountId?: number | null;
    email?: string;
    id?: number;
  }) {
    try {
      return (
        await backendClient.LawyerAssociation({ key, accountId, email, id })
      ).data?.LawyerAssociation;
    } catch (err) {
      logger.error(err);
    }
  }

  public static async findFirstIncludes({
    accountId,
    includes,
    id,
    key,
    email,
    deleted,
  }: {
    accountId?: number;
    id?: number;
    key?: string;
    email?: string;
    deleted?: boolean;
    includes?: {
      assignedUsers?: boolean;
      associationAccount?: boolean;
      lawyerAccount?: boolean;
    };
  }) {
    try {
      return (
        await backendClient.LawyerAssociationIncludes({
          accountId,
          id,
          key,
          email,
          deleted,
          includeAssignedUsers: includes?.assignedUsers ?? false,
          includeAssociationAccount: includes?.associationAccount ?? false,
          includeLawyerAccount: includes?.lawyerAccount ?? false,
        })
      ).data?.LawyerAssociation;
    } catch (err) {
      logger.error(err);
    }
  }

  public static async findAssignedLawyers({
    key,
    accountId,
  }: {
    key?: string;
    accountId?: number | null;
  }) {
    try {
      return (
        await backendClient.LawyerAssociationAssignedLawyers({ key, accountId })
      ).data?.LawyerAssociation;
    } catch (err) {
      logger.error(err);
    }
  }

  public static async findMany(args: LawyerAssociationsQueryVariables) {
    try {
      return (await backendClient.LawyerAssociations(args)).data
        ?.LawyerAssociations;
    } catch (err) {
      logger.error(err);
    }
  }

  public static async delete(id: number) {
    try {
      return (await backendClient.deleteLawyerAssociation({ id })).data
        ?.deleteLawyerAssociation;
    } catch (err) {
      logger.error(err);
    }
  }

  public static async create(args: CreateLawyerAssociationMutationVariables) {
    try {
      return (await backendClient.createLawyerAssociation(args)).data
        ?.createLawyerAssociation;
    } catch (err) {
      logger.error(err);
    }
  }

  public static async handle(
    id: number,
    action: HandleLawyerAssociationAction,
    data?: HandleLawyerAssociationData
  ) {
    try {
      return (
        await backendClient.handleLawyerAssociation({
          lawyerAssociationId: id,
          action,
          data,
        })
      ).data?.handleLawyerAssociation;
    } catch (err) {
      logger.error(err);
    }
  }

  public static async hasAssociation({
    key,
    accountId,
  }: {
    key?: string;
    accountId?: number;
  }) {
    if (!accountId && !key) {
      return false;
    }

    const lawyerAssociation = await LawyerAssociation.findFirst({
      key,
      accountId,
    });

    return !isEmpty(lawyerAssociation);
  }
}
