import { Dialog } from '@headlessui/react';
import Button from 'elements/Button';
import Loading from 'elements/Loading';
import Text from 'elements/Text';
import { FC, useRef } from 'react';
import { X } from 'react-feather';

import useIsMounted from '../../hooks/useIsMounted';

export const MODAL_IMAGES_MAP = {
  magnify: '/magnify-eye-glass.png',
  point: '/finger_pointing_dark.png',
  browserUi: '/browser-ui.png',
  'magnify-red': '/magnify-eye-glass-red.png',
  lightning: '/lighting-bolt.png',
  'hand-raised': '/hand-raised.png',
  'hand-raised-red': '/hand-raised-white.png',
  bot: '/bot-avatar.png',
};

interface ModalPropTypes {
  modalOpen: boolean;
  setModalOpen: (b: boolean) => void;
  variation?: keyof typeof variations;
  close?: 'text' | 'button' | 'none';
  onModalClose?: () => void;
  closeCta?: string;
  image?: keyof typeof MODAL_IMAGES_MAP;
  title?: string;
  subTitle?: string;
  isFullHeight?: boolean;
  isLoading?: boolean;
  cta?: {
    text: string;
    onClick: JSX.IntrinsicElements['button']['onClick'];
    isLoading?: boolean;
    isDisabled?: boolean;
  };
}

const variations = {
  default: ['w-full', 'max-w-xl', 'mx-auto', 'md:p-14', 'p-6'],
  medium: ['max-w-2xl', 'w-full', 'mx-auto', 'md:p-14', 'p-6'],
  large: ['max-w-4xl', 'w-full', 'mx-auto', 'md:p-14', 'p-6'],
  xl: ['w-full', 'max-w-6xl', 'mx-auto', 'md:p-14', 'p-6'],
  full: ['w-full', 'md:m-6', 'md:p-14', 'p-6'],
};

export const Modal: FC<ModalPropTypes & JSX.IntrinsicElements['div']> = ({
  modalOpen,
  setModalOpen,
  children,
  className,
  style,
  variation = 'default',
  close = 'text',
  closeCta = 'Cancel',
  onModalClose,
  image,
  title,
  subTitle,
  cta,
  id,
  isFullHeight = false,
  isLoading = false,
}) => {
  const refDiv = useRef(null);
  const isMounted = useIsMounted();

  if (!isMounted) {
    return null;
  }

  const onClose = () => {
    setModalOpen(false);
    if (onModalClose) onModalClose();
  };

  let Content = (
    <>
      {close === 'button' && (
        <button
          className="absolute top-3 right-3 z-10"
          type="button"
          onClick={() => onClose()}
        >
          <X size={30} className="text-gray-500 hover:text-black" />
        </button>
      )}
      {image && (
        <div
          className={`text-center mb-5 ${
            ['hand-raised-red'].includes(image)
              ? 'bg-red-600 rounded-full w-28 h-28 flex justify-center items-center p-4 mx-auto'
              : ''
          }`}
        >
          <img
            className="mx-auto w-28"
            src={MODAL_IMAGES_MAP[image]}
            alt="Confirm"
          />
        </div>
      )}
      {title && (
        <Text.Heading
          variant="none"
          className="fs24 text-center font-body font-bold mb-2"
        >
          {title}
        </Text.Heading>
      )}
      {subTitle && (
        <Text.Paragraph className="mb-6 text-center">{subTitle}</Text.Paragraph>
      )}
      {children}
      {cta && (
        <Button
          className="mx-auto mt-8"
          type="button"
          onClick={cta.onClick}
          size="large"
          isLoading={cta?.isLoading}
          disabled={cta?.isDisabled}
        >
          {cta.text}
        </Button>
      )}
      <Button
        show={close === 'text'}
        className="text-center w-full mt-1 font-semibold fs14 pb-0"
        type="button"
        onClick={() => onClose()}
        format="basic"
      >
        {closeCta}
      </Button>
    </>
  );

  if (isLoading) {
    Content = <Loading.Default size={3} />;
  }

  return (
    <>
      <Dialog
        initialFocus={refDiv}
        open={modalOpen}
        onClose={() => onClose()}
        className="fixed z-40 inset-0 overflow-y-auto"
      >
        <div className="flex items-center justify-center min-h-screen p-4 md:p-0 max-h-90vh">
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
          <div
            ref={refDiv}
            className={
              `relative bg-white rounded-xl max-h-95vh overflow-auto ${
                isFullHeight ? 'h-[90vh]' : ''
              } ` +
              variations[variation].join(' ') +
              ' ' +
              (`${className || ' '} ` || '') +
              (close === 'button' && ' relative')
            }
            style={style}
            id={id}
          >
            {Content}
          </div>
        </div>
      </Dialog>
    </>
  );
};
