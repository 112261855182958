import backendClient from 'backend';
import User from 'dataLayer/User';
import Upload from 'elements/File/Upload';
import { Modal } from 'elements/Modal/Modal';
import { Text } from 'elements/Text';
import { useActiveUser } from 'hooks/useActiveUser';
import { useAsyncEffect } from 'hooks/useAsyncEffect';
import logger from 'js-logger';
import React, { FC, useState } from 'react';
import { FileRejection } from 'react-dropzone';
import { Edit } from 'react-feather';
import toast from 'react-hot-toast';
import { handleFilesRejected, handleUploadError } from 'utils/helpers';
import { supabase } from 'utils/supabase';

type UserImageRoundProps = {
  className?: string;
  userId?: number;
  size?: 'default' | 'small' | 'medium' | 'large' | 'xl';
  allowEdit?: boolean;
  disabled?: boolean;
};

const getSizeClasses = (size: UserImageRoundProps['size']) => {
  let classes: string[] = [];

  if (size === 'default') classes = ['h-8', 'w-8', 'min-h-8', 'min-w-8'];

  if (size === 'small') classes = ['h-6', 'w-6'];

  if (size === 'medium') classes = ['h-10', 'w-10', 'min-w-10', 'min-h-10'];

  if (size === 'large') classes = ['h-14', 'w-14'];

  if (size === 'xl') classes = ['h-32', 'w-32'];

  return classes.join(' ');
};

export const UserImageRound: FC<UserImageRoundProps> = ({
  userId = null,
  size = 'default',
  allowEdit = false,
  disabled = false,
  className,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const user = useActiveUser();
  const isOwner = userId ? userId === user?.id : true;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [avatarUrl, setAvatarUrl] = useState<string | null>(null);
  const [isUploadModal, setIsUploadModal] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const getAvatarUrl = async (status: { isMounted: boolean }) => {
    if ((!user?.avatarKey && !userId) || disabled) {
      setAvatarUrl(null);
      setIsLoading(false);
      return null;
    }

    let avatarKey = user?.avatarKey;

    if (userId) {
      const user = await User.getPublicUser(userId);

      avatarKey = user?.avatarKey;
    }

    if (!avatarKey) {
      setAvatarUrl(null);
      setIsLoading(false);
      return null;
    }

    try {
      const { data, error } = await supabase.storage
        .from('user-avatars')
        .createSignedUrl(avatarKey, 86400);

      if (status.isMounted && !error) {
        setAvatarUrl(data?.signedUrl as string);
      }

      setIsLoading(false);
    } catch (err) {
      logger.error(err);

      if (status.isMounted) {
        setAvatarUrl(null);
        setIsLoading(false);
      }
    }
  };

  const handleOnDrop = async (
    [file]: any[],
    filesRejected: FileRejection[]
  ) => {
    handleFilesRejected(filesRejected, '2MB');

    if (file) {
      setIsUploading(true);

      try {
        const result = await backendClient.updateAccountSettings({
          data: JSON.stringify({ avatarFile: true }),
          file,
        });

        if (result) {
          toast.success('Profile photo uploaded');
          setIsUploadModal(false);
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        handleUploadError(file.filename, e.message);
        logger.error(e);
      }

      setIsUploading(false);
    }
  };

  useAsyncEffect(getAvatarUrl, [user, userId, disabled]);

  return (
    <>
      <div
        className={`rounded-full border-visto-gray bg-white bg-center bg-cover relative ${
          avatarUrl ? '' : 'border'
        } ${className} ${getSizeClasses(size)}`}
        style={{
          backgroundImage: `url(${
            avatarUrl ? avatarUrl : '/person_icon_default_light.png'
          })`,
          opacity: isLoading ? 0 : 1,
        }}
      >
        {allowEdit && isOwner && (
          <button
            className="absolute bottom-1 right-1 border border-visto-gray p-2 rounded-full bg-white hover:bg-gray-100"
            onClick={() => setIsUploadModal(true)}
          >
            <Edit size={16} />
          </button>
        )}
      </div>
      {allowEdit && isOwner && (
        <Modal
          modalOpen={isUploadModal}
          setModalOpen={setIsUploadModal}
          close="button"
        >
          <Text.Heading variant="form-heading" className="text-center mb-1">
            Upload Profile Photo
          </Text.Heading>
          <Text.Paragraph className="text-center mb-4">
            Upload a new profile photo. If a photo exists, this will update the
            existing photo.
          </Text.Paragraph>
          <Upload
            type="image"
            onDropHandler={handleOnDrop}
            isLoading={isUploading}
            options={{ maxSizeInMb: 2 }}
          />
        </Modal>
      )}
    </>
  );
};

export default UserImageRound;
