export const GOOGLE_TRANSLATE_COOKIE = 'googtrans';

// This is a hacky way to allow Google Translate to work
// Essentially, what this is doing, is just ignoring the error that Google Translate throws (though the error might still get logged in the Console)
// This was the only suggested fix by the creator of React here: https://github.com/facebook/react/issues/11538#issuecomment-417504600
export const ignoreGoogleTranslateError = () => {
  const originalRemoveChild = Node.prototype.removeChild;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Node.prototype.removeChild = function (child) {
    if (child.parentNode !== this) {
      if (console) {
        // eslint-disable-next-line no-console
        console.error(
          'Cannot remove a child from a different parent',
          child,
          this
        );
      }
      return child;
    }
    // eslint-disable-next-line prefer-rest-params
    return originalRemoveChild.apply(this, arguments as any);
  };

  const originalInsertBefore = Node.prototype.insertBefore;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Node.prototype.insertBefore = function (newNode, referenceNode) {
    if (referenceNode && referenceNode.parentNode !== this) {
      if (console) {
        // eslint-disable-next-line no-console
        console.error(
          'Cannot insert before a reference node from a different parent',
          referenceNode,
          this
        );
      }
      return newNode;
    }
    // eslint-disable-next-line prefer-rest-params
    return originalInsertBefore.apply(this, arguments as any);
  };
};
