import { FormFieldSet } from '@visto-tech/forms';
import { FormManager } from '@visto-tech/forms';
import Text from 'elements/Text';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';

import { useUniqueId } from '../../../hooks/useUniqueId';
import { WYSIWYGInput } from './WYSIWYGInput';

interface WYSIWYGInputWithLabelProps {
  formManager: FormManager<any>;
  name: string;
  className?: string;
  tooltip?: string | null;
}

export const WYSIWYGInputWithLabel: FC<WYSIWYGInputWithLabelProps> = observer(
  ({ formManager, children, name, className, tooltip }) => {
    const uniqueId = useUniqueId(name);

    const error = formManager.errors.find((error) => error.path === name);

    return (
      <div>
        <FormFieldSet
          className={'flex-grow w-full relative' + ' ' + (className || '')}
          hasError={!isEmpty(error)}
        >
          <label
            className="font-semibold translate bg-white fs14 p-2 w-full block rounded-md"
            htmlFor={uniqueId}
          >
            <span className="block">{children}</span>
            {tooltip && (
              <span className="block fs12 font-light mt-1 translate">
                {tooltip}
              </span>
            )}
          </label>
          <WYSIWYGInput
            name={name}
            formManager={formManager}
            editorClassname="h-44 overflow-y-scroll bg-white"
          />
        </FormFieldSet>
        {error && (
          <Text.Paragraph className="mt-1 text-red-600 fs12">
            {error.message}
          </Text.Paragraph>
        )}
      </div>
    );
  }
);
