import { FC } from 'react';

interface DefaultProps {
  show?: boolean;
}

export const Heading: FC<DefaultProps & JSX.IntrinsicElements['div']> = ({
  show = true,
  className,
}) => {
  if (!show) return null;

  return (
    <div className={`animate-pulse ${className}`}>
      <div className="h-2 bg-gray-300 rounded mb-3 w-32"></div>
      <div className="h-2 bg-gray-300 rounded mb-3 w-96"></div>
      <div className="h-2 bg-gray-300 rounded mb-3 w-96"></div>
    </div>
  );
};

Heading.displayName = 'Heading';

export default Heading;
