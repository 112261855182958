import { FormDataStructure, FormManager } from '@visto-tech/forms';
import Form from 'dataLayer/Form';
import Question, { QUESTION_INPUT_VARIATIONS } from 'dataLayer/Question';
import { first } from 'lodash';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { removeSuffix } from 'utils/helpers';

export const RepeaterQuestionsRenderer: FC<{
  formManager:
    | FormManager<FormDataStructure<string | number | symbol>>
    | undefined;
  name: string;
  repeaterForm?: Form;
}> = observer(({ formManager, name, repeaterForm }) => {
  const unSuffixedName = removeSuffix(name);

  const interval = first(name.match(/[^-]*$/));

  const q = repeaterForm?.content.find((q) => {
    return (q as Question).label === unSuffixedName;
  }) as Question | undefined;

  if (!q || !formManager) {
    return null;
  }

  if (!q.optimisticallyMeetsAllConditions(formManager, interval)) {
    return null;
  }

  const question = new Question({ ...q, label: name });

  return (
    <div className="mb-4 w-full" data-repeater-form-name={repeaterForm?.name}>
      {question.getInputComponentForQuestion({
        formManager,
        variation: QUESTION_INPUT_VARIATIONS.DYNAMIC_FORM,
      })}
    </div>
  );
});
