import { FC, useContext, useEffect } from 'react';

import { GettingStartedQuestionGroupContext } from '../DynamicApiForm/variation-components/GettingStartedQuestionGroupContext';

export const MultiStageFormPage: FC<{ pageID: any; labels: string[] }> = ({
  children,
  pageID,
  labels,
}) => {
  const multiStageFormController = useContext(
    GettingStartedQuestionGroupContext
  );
  const isActivePage = multiStageFormController.visiblePageID === pageID;

  useEffect(() => {
    if (!isActivePage) return;
    multiStageFormController.setLabels(labels);
    return () => multiStageFormController.setLabels([]);
  }, [isActivePage, labels, multiStageFormController]);

  if (isActivePage) return <>{children}</>;
  return null;
};
