import { Tab } from '@headlessui/react';
import environment from 'dataLayer/Environment';
import Text from 'elements/Text';
import { get, round } from 'lodash';
import { FC, useState } from 'react';
import { STRIPE_B2B_PRICE_ID_MAP } from 'static/Payments.static';

export enum PriceFrequency {
  MONTHLY,
  BIYEARLY,
  YEARLY,
}

const selected = ({ selected }: { selected: boolean }) =>
  `${
    selected ? 'bg-gradient-to-r from-[#2897f2] to-blue-600 text-white' : ''
  } w-32 md:w-40 py-1.5 md:py-2 rounded-full font-bold focus:outline-none`;

const TAB_TO_FREQUENCY = {
  0: PriceFrequency.MONTHLY,
  1: PriceFrequency.BIYEARLY,
  2: PriceFrequency.YEARLY,
};

const PRICES = {
  [PriceFrequency.MONTHLY]: {
    // V1
    ESSENTIALS: 199,
    ADVANCED: 499,
    COMPLETE: 999,

    // V2
    SOLO: 199,
    GROWING: 449,
    ESTABLISHED: 999,
  },
  [PriceFrequency.BIYEARLY]: {
    // V1
    ESSENTIALS: round(1074.6 / 6),
    ADVANCED: round(2694.6 / 6),
    COMPLETE: round(5394.6 / 6),

    // V2
    SOLO: round(1074.6 / 6),
    GROWING: round(2424.6 / 6),
    ESTABLISHED: round(5394.6 / 6),
  },
  [PriceFrequency.YEARLY]: {
    // V1
    ESSENTIALS: round(1910.4 / 12),
    ADVANCED: round(4790.4 / 12),
    COMPLETE: round(9590.4 / 12),

    // V2
    SOLO: round(1910.4 / 12),
    GROWING: round(4310.4 / 12),
    ESTABLISHED: round(9590.4 / 12),
  },
};

import {
  PricingTablePrice,
  PricingTablePricePropTypes,
} from './PricingTablePrice';

export const PricingTable: FC = () => {
  const [tabIndex, setTabIndex] = useState<number>(0);

  const frequency = get(TAB_TO_FREQUENCY, tabIndex);
  const prices = getPricingConfig(frequency);

  return (
    <>
      <div className="relative">
        <Tab.Group
          onChange={(index) => {
            setTabIndex(index);
          }}
        >
          <Tab.List className="flex flex-row justify-center w-full mb-10">
            <div className="border p-1 rounded-full flex">
              <Tab className={selected}>
                <Text.Paragraph className="fs12 md:fs14 font-bold">
                  Monthly
                </Text.Paragraph>
              </Tab>
              <Tab className={selected}>
                <div className="relative">
                  <Text.Paragraph className="fs12 absolute -top-8 transform left-1/2 -translate-x-1/2 font-semibold text-blue-600 ">
                    Save 10%
                  </Text.Paragraph>
                  <Text.Paragraph className="fs12 md:fs14 font-bold">
                    6 Months
                  </Text.Paragraph>
                </div>
              </Tab>
              <Tab className={selected}>
                <div className="relative">
                  <Text.Paragraph className="fs12 absolute -top-8 transform left-1/2 -translate-x-1/2 font-semibold text-blue-600 ">
                    Save 20%
                  </Text.Paragraph>
                  <Text.Paragraph className="fs12 md:fs14 font-bold">
                    Annually
                  </Text.Paragraph>
                </div>
              </Tab>
            </div>
          </Tab.List>
        </Tab.Group>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
        {prices.map((price, i) => {
          return <PricingTablePrice key={`price-${i}`} {...price} />;
        })}
      </div>
      <Text.Paragraph className="fs14 text-gray-600 text-center mt-4">
        All prices in CAD.
      </Text.Paragraph>
    </>
  );
};

const getPricingConfig = (frequency: PriceFrequency) => {
  const STRIPE_PRICE_IDS = get(
    STRIPE_B2B_PRICE_ID_MAP,
    environment.deploymentEnvironment as string
  );

  const PRICE_IDS = get(STRIPE_PRICE_IDS, frequency);

  const prices = get(PRICES, frequency);

  let items: PricingTablePricePropTypes[] = [];

  items = [
    {
      title: 'Solo',
      subTitle: 'Great for small organizations',
      stripePriceId: get(PRICE_IDS, 'solo'),
      price: prices.SOLO,
      details: [
        '10 applications per month',
        '3 users',
        '1 authorized representative',
        '5 custom application templates',
        '30 GB secure cloud storage',
        '50 Visto Copilot messages per user per month',
      ],
    },
    {
      title: 'Growing',
      subTitle: 'Great for mid-size organizations',
      stripePriceId: get(PRICE_IDS, 'growing'),
      price: prices.GROWING,
      details: [
        '25 applications per month',
        '10 users',
        '5 authorized representatives',
        '15 custom application templates',
        '85 GB secure cloud storage',
        '150 Visto Copilot messages per user per month',
      ],
      highlight: true,
    },
    {
      title: 'Established',
      subTitle: 'Great for large organizations',
      stripePriceId: get(PRICE_IDS, 'established'),
      price: prices.ESTABLISHED,
      details: [
        '55 applications per month',
        '20 users',
        '10 authorized representatives',
        '30 custom application templates',
        '265 GB secure cloud storage',
        '350 Visto Copilot messages per user per month',
      ],
    },
  ];

  return items;
};
