import { makeAutoObservable } from 'mobx';

import { AccountSubType as RawAccountSubType } from '../generated/graphql';

export class AccountSubType implements RawAccountSubType {
  id: number;
  name: string;

  constructor(accountSubType: RawAccountSubType) {
    if (!accountSubType) {
      throw new Error(
        'You cannot create a accountSubType instance without passing in user data.'
      );
    }

    this.id = accountSubType.id;
    this.name = accountSubType.name;

    makeAutoObservable(this);
  }
}

export default AccountSubType;
