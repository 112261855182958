import Tooltip from 'rc-tooltip';
import React, { FC } from 'react';
import { HelpCircle } from 'react-feather';

export const TooltipWithIcon: FC<JSX.IntrinsicElements['div']> = ({
  children,
  className,
}) => {
  return (
    <Tooltip placement="top" overlay={<span>{children}</span>}>
      <HelpCircle
        size={17}
        className={`cursor-pointer text-gray-400 hover:text-black ${className}`}
      />
    </Tooltip>
  );
};

export default TooltipWithIcon;
