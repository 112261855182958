/* eslint-disable sonarjs/no-duplicate-string */
import { Menu } from '@headlessui/react';
import { deleteCookie, getCookie, setCookie } from 'cookies-next';
import { Crisp } from 'crisp-sdk-web';
import environment from 'dataLayer/Environment';
import { Meta } from 'dataLayer/Meta';
import Text from 'elements/Text';
import { Meta as MetaGQL } from 'generated/graphql';
import { useAsyncEffect } from 'hooks/useAsyncEffect';
import { isEmpty, isFunction, upperCase } from 'lodash';
import { Fragment, useState } from 'react';
import React, { FC } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { ChevronDown } from 'react-feather';
import toast from 'react-hot-toast';
import { GOOGLE_TRANSLATE_COOKIE } from 'utils/google';

// Language list: https://gist.github.com/sthobis/c52644d079051bc6456c4b20668db813
// Guide: https://aniksharif.medium.com/creating-a-multilingual-react-component-in-next-js-with-google-translate-bf1899f331fe

// Notes
// - To disable translation on an element, use the class: notranslate
// - To force a translation on an element, use the class: translate

type Language = {
  nativeLabel: string;
  languageCode: string;
  englishLabel: string;
  countryCode?: string;
};

const DEFAULT_LANGUAGE = {
  nativeLabel: 'English',
  englishLabel: 'English',
  countryCode: 'CA',
  languageCode: 'en',
};

export const GoogleTranslateSelect: FC<{
  classNames?: {
    container?: string;
    menuItems?: string;
  };
}> = ({ classNames }) => {
  const [languages, setLanguages] = useState<Language[]>([]);
  const [selected, setSelected] = useState<Language | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getCookieDomain = () => {
    if (environment.isProd()) {
      return '.visto.ai';
    } else if (environment.isStg()) {
      return '.visto.link';
    } else {
      return null;
    }
  };

  useAsyncEffect(async () => {
    setIsLoading(true);

    const languagesDb: any = (await Meta.get('localization')) as MetaGQL;

    setLanguages(languagesDb?.value ? languagesDb.value : []);

    const targetWindow = window as any;

    const foundCookie = getCookie(GOOGLE_TRANSLATE_COOKIE);

    if (foundCookie) {
      let cookieValue = decodeURI(foundCookie as string);

      // Get the language code (i.e. EN from the cookie)
      const lastIndex = cookieValue.lastIndexOf('/');

      if (lastIndex === -1) {
        return;
      }

      cookieValue = cookieValue.substring(lastIndex + 1);

      const language: Language = languagesDb?.value?.find(
        (language: any) => language.languageCode === cookieValue
      );

      setSelected(language ?? null);
    } else {
      setSelected(DEFAULT_LANGUAGE);
    }

    const isScripLoaded = isFunction(targetWindow.googleTranslateElementInit);

    if (!isEmpty(languagesDb?.value) && !isScripLoaded) {
      const addScript = document.createElement('script');

      addScript.setAttribute(
        'src',
        '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
      );

      document.body.appendChild(addScript);

      targetWindow.googleTranslateElementInit = () => {
        const targetWindow = window as any;
        const languagesList = languagesDb.value
          ?.map((language: Language) => language.languageCode)
          .join(',');

        new targetWindow.google.translate.TranslateElement(
          {
            pageLanguage: 'auto',
            autoDisplay: false,
            includedLanguages: languagesList,
          },
          'google_translate_element'
        );
      };
    }

    setIsLoading(false);
  }, []);

  if (isLoading || isEmpty(languages)) {
    return null;
  }

  const handleChange = async (language: Language) => {
    toast.loading('Loading new language...');

    // For some reason, 2 cookies of googtrans get added in which different domains
    // so we need to force delete the 2nd one
    const cookieDomain = getCookieDomain();

    if (language.languageCode === 'en') {
      deleteCookie(GOOGLE_TRANSLATE_COOKIE);

      if (cookieDomain) {
        deleteCookie(GOOGLE_TRANSLATE_COOKIE, {
          domain: cookieDomain,
        });
      }

      window.location.reload();
      return null;
    }

    setCookie(GOOGLE_TRANSLATE_COOKIE, `/auto/${language.languageCode}`);

    if (cookieDomain) {
      setCookie(GOOGLE_TRANSLATE_COOKIE, `/auto/${language.languageCode}`, {
        domain: cookieDomain,
      });
    }

    setSelected(language ?? null);

    window.location.reload();
  };

  const getCountryCode = (language: Language) => {
    if (isEmpty(language)) {
      return '';
    }

    if (!isEmpty(language?.countryCode)) {
      return language.countryCode;
    }

    return language.languageCode;
  };

  return (
    <>
      <div
        id="google_translate_element"
        style={{
          width: '0px',
          height: '0px',
          position: 'absolute',
          left: '50%',
          zIndex: -99999,
          display: 'none',
        }}
      ></div>
      <div className={`relative ${classNames?.container ?? ''}`}>
        <Menu>
          <Menu.Button
            className={
              'border border-visto-gray rounded-full px-4 py-1 flex items-center justify-center hover:bg-gray-100'
            }
          >
            <div className="flex justify-between items-center">
              {selected && (
                <ReactCountryFlag
                  className="mr-1 fs21"
                  countryCode={upperCase(getCountryCode(selected))}
                  svg
                />
              )}
              <span className="notranslate fs12 mr-1.5 ml-0.5 font-semibold">
                {selected?.nativeLabel}
              </span>
              <ChevronDown size={20} strokeWidth={3} className="-mr-1.5" />
            </div>
          </Menu.Button>
          <Menu.Items
            className={`absolute z-50 top-10 bg-white border border-gray-200 px-4 py-3 rounded-md w-max max-h-96 overflow-y-auto space-y-1 ${
              classNames?.menuItems ?? ''
            }`}
          >
            {languages
              .sort((a, b) =>
                a.englishLabel > b.englishLabel
                  ? 1
                  : a.englishLabel === b.englishLabel
                  ? 0
                  : -1
              )
              .sort((x, y) =>
                x.englishLabel == 'English'
                  ? -1
                  : y.englishLabel == 'English'
                  ? 1
                  : 0
              )
              .map((language, i) => {
                const countryCode = getCountryCode(language);
                const isSelected =
                  language.languageCode === selected?.languageCode;
                const isFirst = i === 0;

                return (
                  <Menu.Item key={language.languageCode} as={Fragment}>
                    {({ active }) => (
                      <button
                        onClick={async () => {
                          await handleChange(language);
                        }}
                        className={`block w-full ${
                          isFirst ? 'border-b border-gray-300 pb-1' : ''
                        }`}
                      >
                        <span
                          className={`flex items-center justify-start notranslate w-full p-2 rounded-md fs14 ${
                            active || isSelected
                              ? 'bg-gray-200 text-black'
                              : 'bg-white text-black'
                          }`}
                        >
                          {countryCode && (
                            <ReactCountryFlag
                              className="mr-2 fs18"
                              countryCode={upperCase(countryCode)}
                              svg
                            />
                          )}
                          {language.englishLabel}
                          {language?.nativeLabel && (
                            <span className="italic ml-1.5 fs14 text-gray-500">
                              {language.nativeLabel}
                            </span>
                          )}
                        </span>
                      </button>
                    )}
                  </Menu.Item>
                );
              })}
            <Menu.Item as={Fragment}>
              <Text.Paragraph className="bg-gray-100 border border-gray-200 fs12 font-semibold px-2 py-1 rounded-md !mt-2 text-gray-600 notranslate">
                Don't see your language?{' '}
                <button
                  className="font-semibold underline"
                  onClick={() => {
                    Crisp.chat.open();
                  }}
                >
                  Let us know
                </button>
                !
              </Text.Paragraph>
            </Menu.Item>
          </Menu.Items>
        </Menu>
      </div>
    </>
  );
};
