import { Text } from 'elements/Text';
import { FC, useEffect } from 'react';

export const GettingStartedCompleted: FC<JSX.IntrinsicElements['div']> = ({
  className,
}) => {
  useEffect(() => {
    const id = setTimeout(() => {
      window.location.reload();
    }, 4000);
    return () => clearTimeout(id);
  }, []);

  return (
    <div className={`mt-12 max-w-2xl ${className ?? ''}`}>
      <img
        src="/lighting-bolt.png"
        alt="Lightning"
        className="mb-3 w-20 mx-auto"
      />
      <Text.Heading className="text-center mb-2">
        On-boarding Completed!
      </Text.Heading>
      <Text.Paragraph className="text-center fs16 lg:fs21 mb-2">
        Thank you for completing your on-boarding. We are getting your{' '}
        <strong>dashboard ready</strong>, don't go anywhere!
      </Text.Paragraph>
      <Text.Paragraph className="text-center fs14 italic mb-8 text-gray-400">
        (This page will refresh in 4 seconds...)
      </Text.Paragraph>
      <img
        className="pl-16 lg:pl-24"
        src="https://visto-prod-app-files.s3.amazonaws.com/static/airplane_loader.gif"
        alt="Loading"
      />
    </div>
  );
};
