import Answer from 'dataLayer/Answer';
import Loading from 'elements/Loading';
import { useAsyncEffect } from 'hooks/useAsyncEffect';
import React, { FC, useState } from 'react';

export const LogoDisplay: FC<{
  domain?: string;
  className?: string;
  accountId?: number;
}> = ({ domain, className = '', accountId }) => {
  const DEFAULT_URL = '/lighting-bolt.png';
  const [url, setUrl] = useState<string>(DEFAULT_URL);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const setDomain = (domain: string, status: { isMounted: boolean }) => {
    if (
      domain.trim().startsWith('http://') ||
      domain.trim().startsWith('https://')
    ) {
      try {
        const newUrl = new URL(domain).hostname;
        if (status.isMounted) {
          setUrl(`https://logo.clearbit.com/${newUrl}`);
        }
      } catch (e) {
        if (status.isMounted) setUrl(DEFAULT_URL);
      }
    } else {
      setUrl(`https://logo.clearbit.com/${domain}`);
    }
  };

  useAsyncEffect(async (status: { isMounted: boolean }) => {
    if (domain) {
      setDomain(domain, status);
    } else if (accountId) {
      const answer = await Answer.getAnswers({
        questionLabels: ['CG_11'],
        accountId,
      });

      const domainDB = answer?.get('CG_11');

      if (domainDB) {
        setDomain(domainDB, status);
      }
    }

    setIsLoading(false);
  }, []);

  if (isLoading) return <Loading.Circle />;

  return (
    <img
      className={`w-10 object-cover ${className}`}
      src={url}
      alt="Logo"
      onError={() => setUrl(DEFAULT_URL)}
    />
  );
};
