import { FormManager } from '@visto-tech/forms';
import Question, { QUESTION_INPUT_VARIATIONS } from 'dataLayer/Question';
import { QuestionGroup } from 'dataLayer/QuestionGroup';
import { ToggleExpandArrows } from 'elements/Icon/ToggleExpandedArrows';
import { SubmitFormMutationArgs } from 'generated/graphql';
import { get } from 'lodash';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { FC, useEffect, useState } from 'react';

import { Text } from '../../elements/Text';
import { QuestionGroupRenderer } from '../QuestionGroups/QuestionGroup';
import { HideComponents } from './DynamicApiForm';

interface DynamicFormQuestionGroupProps {
  variation: QUESTION_INPUT_VARIATIONS;
  formManager: FormManager<any>;
  group: QuestionGroup;
  pageID?: string;
  disabled?: boolean;
  hide?: HideComponents;
  targetAccountId?: number;
  submitFormArgs?: SubmitFormMutationArgs;
  activeQuestion?: string | string[] | null;
}
export const DynamicFormQuestionGroup: FC<DynamicFormQuestionGroupProps> =
  observer(
    ({
      variation,
      formManager,
      group,
      pageID,
      disabled = false,
      targetAccountId,
      submitFormArgs,
      activeQuestion,
      hide = {
        groupHeadings: false,
      },
    }) => {
      const [isHidden, setIsHidden] = useState<boolean>(false);
      const isGettingStarted =
        variation === QUESTION_INPUT_VARIATIONS.GETTING_STARTED;
      const isBasic =
        variation === QUESTION_INPUT_VARIATIONS.DYNAMIC_FORM_BASIC;
      const groupHeading = group.title;
      const groupSubheading = group.subtitle;

      const filteredQuestions = group.questions?.filter((question) =>
        question.optimisticallyMeetsAllConditions(formManager)
      );

      // Logic for conditionals
      useEffect(() => {
        (
          group.questions
            ?.filter((q) => !q.optimisticallyMeetsAllConditions(formManager))
            .map((q) => q.label.toString()) || []
        ).forEach((label) =>
          formManager.ignoreValidationOnTheseFields.add(label)
        );

        (
          group.questions
            ?.filter((q) => q.optimisticallyMeetsAllConditions(formManager))
            .map((q) => q.label.toString()) || []
        ).forEach((label) =>
          formManager.ignoreValidationOnTheseFields.delete(label)
        );
      }, [
        ...Object.values(toJS(formManager.formData)).sort(),
        group.questions,
      ]);

      useEffect(() => {
        if (activeQuestion && document) {
          (document as Document)
            .querySelector(`[name="${activeQuestion}"]`)
            ?.scrollIntoView({ behavior: 'smooth' });
        }
      }, [filteredQuestions, activeQuestion]);

      return (
        <QuestionGroupRenderer
          variation={variation}
          pageID={pageID}
          labels={filteredQuestions?.map((q) => q.label) || []}
        >
          {!hide.groupHeadings && (
            <div
              className={`flex justify-between items-center w-full ${
                !isHidden && !isGettingStarted && 'mb-3'
              }`}
            >
              <div className="mb-3">
                <Text.Heading
                  variant="none"
                  as="h2"
                  className={`translate ${
                    isGettingStarted ? 'fs18 md:fs20' : 'fs16'
                  } font-body font-bold leading-snug`}
                >
                  {groupHeading}
                </Text.Heading>
                {groupSubheading && (
                  <Text.Paragraph className={`translate fs15 font-light mt-1`}>
                    {groupSubheading}
                  </Text.Paragraph>
                )}
              </div>
              {!isGettingStarted && !isBasic && !disabled && (
                <div className="flex items-center">
                  <ToggleExpandArrows
                    isHidden={isHidden}
                    setIsHidden={setIsHidden}
                  />
                </div>
              )}
            </div>
          )}
          {!isHidden &&
            filteredQuestions?.map((question: Question, i: number) => {
              const isActive = activeQuestion === question.label;
              const hasConditions =
                question.conditionsRequiredToRenderThisQuestion.length > 0;

              // This is required to manually set the validation schema for the DynamicApiLeadsForm validationSchemaOverride
              const validationSchemaFields =
                formManager.getValidationSchema()?.fields;

              const validationSchema = get(
                validationSchemaFields,
                question.label
              );

              if (validationSchema) {
                question.validationSchema = validationSchema;
              }

              return (
                <div
                  key={i}
                  className={`mb-4 w-full flex justify-between ${
                    isActive
                      ? 'border-4 rounded-md border-visto-light-blue'
                      : ''
                  } `}
                >
                  {hasConditions && (
                    <div className="w-[5px] rounded-md bg-gray-100 mr-1 ml-2"></div>
                  )}
                  <div className="w-full">
                    {question.getInputComponentForQuestion({
                      targetAccountId,
                      formManager,
                      variation,
                      submitFormArgs,
                    })}
                  </div>
                </div>
              );
            })}
        </QuestionGroupRenderer>
      );
    }
  );

DynamicFormQuestionGroup.displayName = 'DynamicFormQuestionGroup';
