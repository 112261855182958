import { GoogleTranslateSelect } from 'components/Translate/GoogleTranslate';
import environment from 'dataLayer/Environment';
import { capitalize } from 'lodash';
import { FC } from 'react';

import { useActiveUser } from '../../../hooks/useActiveUser';
import { NotificationsIcon } from './NotificationsIcon';
import { UserDropdownMenu } from './UserDropdownMenu';

const ShowUserType: FC<{ user: any }> = ({ user }) => {
  if (!environment.isProd() && user) {
    return (
      <span className="fs12 hidden md:block border border-visto-gray rounded-full px-3 py-1">
        {`U: ${user.id} | A:${user.account?.id} | ${capitalize(user.type)}`}
      </span>
    );
  }
  return null;
};

export const TopNav: FC = () => {
  const user = useActiveUser();

  return (
    <div className="relative z-10 flex-shrink-0 justify-end flex pl-4">
      <div className="ml-4 flex items-center md:ml-6 space-x-3">
        <ShowUserType user={user} />
        <GoogleTranslateSelect
          classNames={{ container: 'md:block hidden', menuItems: 'right-0' }}
        />
        {!user?.isType('COPILOT') && (
          <NotificationsIcon
            className="md:flex hidden justify-center items-center"
            user={user}
          />
        )}
        <UserDropdownMenu />
      </div>
    </div>
  );
};
