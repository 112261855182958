import { LayoutContext } from 'dataLayer/LayoutContext';
import { observer } from 'mobx-react-lite';
import { AppProps } from 'next/app';
import { FC, useContext } from 'react';
import React from 'react';

import { useActiveUser } from '../hooks/useActiveUser';
import { GettingStartedRouter } from './GettingStarted/GettingStartedRouter';

export const AppLayout: FC<{
  Component: AppProps['Component'] & {
    PageLayout?: React.ComponentType;
  };
  pageProps: any;
}> = observer(({ pageProps, Component }) => {
  const user = useActiveUser();
  const { Layout } = useContext(LayoutContext);

  const is = {
    lawyer: user?.isType('LAWYER'),
    company: user?.isType('COMPANY'),
  };

  let Page = <Component {...pageProps} />;

  const showGettingStarted = is.lawyer && !user?.hasCompletedGettingStarted;

  if (showGettingStarted) {
    Page = <GettingStartedRouter />;
  }

  return <Layout>{Page}</Layout>;
});

AppLayout.displayName = 'AppLayout';
