/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-identical-functions */
import { DropdownWithLabel, FormManager } from '@visto-tech/forms';
import { InputWithLabel } from '@visto-tech/forms';
import { countryResidenceList } from '@visto-tech/seed';
import { Button } from 'elements/Button';
import { DatePickerInputSecondary } from 'elements/Forms/DatePickerInput/DatePickerInputSecondary';
import Loading from 'elements/Loading';
import { Text } from 'elements/Text';
import { useQuestionRepeater } from 'hooks/useQuestionRepeater';
import { observer } from 'mobx-react-lite';
import React, { FC, useEffect } from 'react';
import { MilitaryRepeaterStructure } from 'types/QuestionRepeaterManagerTypes';

interface MilitaryRepeaterPropTypes {
  formManager: FormManager<any>;
  label: string;
}

export const MilitaryRepeater: FC<MilitaryRepeaterPropTypes> = observer(
  ({ formManager, label }) => {
    const initial = formManager._initialData[label];

    const { questionManager } = useQuestionRepeater({
      initialData: initial ? initial : [],
      structure: {
        location: '',
        province: '',
        country: '',
        startDate: '',
        endDate: '',
      },
      formManager,
      label,
    });

    useEffect(() => {
      if (questionManager && questionManager.getState().length === 0) {
        questionManager.add();
      }
    }, [questionManager]);

    if (!questionManager) return <Loading.Default />;

    formManager.formData[label] = questionManager.stringify();

    return (
      <div className="w-full">
        {questionManager
          .getState()
          .map((item: MilitaryRepeaterStructure, i: number) => {
            return (
              <div
                key={i}
                className={`${'border-b border-visto-gray bg-gray-100 p-3 mb-3 rounded-lg'}`}
              >
                <div className="w-full flex justify-between items-start mb-2">
                  <Text.Paragraph className="fs12 text-gray-400">
                    Military Service #{i + 1}
                  </Text.Paragraph>
                  <button
                    className="fs12 text-right text-gray-400 hover:text-gray-800"
                    type="button"
                    onClick={() => questionManager.delete(i)}
                  >
                    Delete
                  </button>
                </div>

                <InputWithLabel
                  key={`id-location-${i}-input-${label}`}
                  id={`id-location-${i}-input-${label}`}
                  name="location"
                  value={item.location.value ?? ''}
                  error={questionManager.hasError('location', i, formManager)}
                  errorMessage={questionManager.errorMessage(
                    'location',
                    i,
                    formManager
                  )}
                  formFieldSetProps={{
                    className: 'w-full mb-3',
                  }}
                  labelProps={{
                    isFocused: true,
                  }}
                  inputProps={
                    {
                      'data-group': i,
                      onChange: questionManager.handleOnChange,
                      type: 'text',
                      isFocused: true,
                      className: 'placeholder:text-slate-400',
                    } as any
                  }
                >
                  City or location where stationed
                </InputWithLabel>

                <InputWithLabel
                  key={`id-province-${i}-input-${label}`}
                  id={`id-province-${i}-input-${label}`}
                  name="province"
                  value={item.province.value ?? ''}
                  error={questionManager.hasError('province', i, formManager)}
                  tooltip="If you are not sure, or there is no province or state, please enter 'N/A'."
                  errorMessage={questionManager.errorMessage(
                    'province',
                    i,
                    formManager
                  )}
                  formFieldSetProps={{
                    className: 'w-full mb-3',
                  }}
                  labelProps={{
                    isFocused: true,
                  }}
                  inputProps={
                    {
                      'data-group': i,
                      onChange: questionManager.handleOnChange,
                      type: 'text',
                      isFocused: true,
                      className: 'placeholder:text-slate-400',
                    } as any
                  }
                >
                  Province or State
                </InputWithLabel>

                <DropdownWithLabel
                  key={`id-country-${i}-select-${label}`}
                  id={`id-country-${i}-select-${label}`}
                  name="country"
                  value={item.country.value ?? ''}
                  error={questionManager.hasError('country', i, formManager)}
                  errorMessage={questionManager.errorMessage(
                    'country',
                    i,
                    formManager
                  )}
                  formFieldSetProps={{
                    className: 'w-full mb-3',
                  }}
                  dropdownProps={
                    {
                      'data-group': i,
                      onChange: questionManager.handleOnChange,
                      children: [
                        <option key={`initial-empty-value`} value="" disabled>
                          -
                        </option>,
                        ...countryResidenceList.map((q, index) => (
                          <option key={`opt-${index}`} value={q.label}>
                            {q.label}
                          </option>
                        )),
                      ],
                    } as any
                  }
                >
                  Country
                </DropdownWithLabel>

                <DatePickerInputSecondary
                  key={`id-startDate-${i}-select-${label}`}
                  label="startDate"
                  initValue={item.startDate.value ?? ''}
                  onChange={questionManager.handleOnChange}
                  inputError={questionManager.errorMessage(
                    'startDate',
                    i,
                    formManager
                  )}
                  dateProps={{
                    name: 'startDate',
                    groupId: i,
                  }}
                  className={'mb-3'}
                >
                  From date
                </DatePickerInputSecondary>

                <DatePickerInputSecondary
                  key={`id-endDate-${i}-select-${label}`}
                  label="endDate"
                  initValue={item?.endDate.value ?? ''}
                  onChange={questionManager.handleOnChange}
                  inputError={questionManager.errorMessage(
                    'endDate',
                    i,
                    formManager
                  )}
                  dateProps={{
                    name: 'endDate',
                    groupId: i,
                  }}
                >
                  To date
                </DatePickerInputSecondary>
              </div>
            );
          })}

        <div className="flex justify-center">
          <Button
            type="button"
            format="outline"
            onClick={() => {
              questionManager.add();
            }}
          >
            Add more military services
          </Button>
        </div>
      </div>
    );
  }
);
