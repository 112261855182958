/* eslint-disable sonarjs/no-duplicate-string */
import React, { ReactHTML } from 'react';

type elementNames = keyof ReactHTML;

const variations: {
  [key: string]: {
    classNames: string[];
  };
} = {
  default: {
    classNames: ['bg-gray-500', 'text-white'],
  },
  'default-light': {
    classNames: ['bg-gray-100', 'border', 'border-gray-200', 'text-gray-600'],
  },
  'default-medium': {
    classNames: ['bg-gray-200', 'border', 'border-gray-300', 'text-gray-600'],
  },
  warning: {
    classNames: ['bg-gold', 'text-gray-600'],
  },
  'warning-border': {
    classNames: ['bg-gold', 'text-gray-600', 'border-yellow-500', 'border'],
  },
  'warning-light': {
    classNames: ['bg-yellow-100', 'text-black', 'border', 'border-yellow-300'],
  },
  success: {
    classNames: ['bg-green-600', 'text-white'],
  },
  blue: {
    classNames: ['bg-visto', 'text-white'],
  },
  'light-blue': {
    classNames: ['bg-blue-50', 'text-black', 'border', 'border-blue-100'],
  },
  'medium-blue': {
    classNames: ['bg-blue-100', 'text-black', 'border', 'border-blue-200'],
  },
  'light-green': {
    classNames: ['bg-green-100', 'text-black', 'border', 'border-green-200'],
  },
  'medium-green': {
    classNames: ['bg-green-200', 'text-black', 'border', 'border-green-300'],
  },
  'light-orange': {
    classNames: ['bg-orange-100', 'text-black', 'border', 'border-orange-300'],
  },
  error: {
    classNames: ['bg-red-200', 'border', 'border-red-300', 'text-black'],
  },
  'blue-to-dark': {
    classNames: [
      'bg-gradient-to-r',
      'from-[#2897f2]',
      'to-blue-600',
      'text-white',
    ],
  },
};

export const Pill = <T extends elementNames>({
  children,
  className,
  as,
  variation = 'default',
  show = true,
  ...props
}: React.HTMLProps<T> & { as: T } & { variation?: string; show?: boolean }) => {
  if (!show) return null;

  const Component: any = as;
  return (
    <Component
      className={
        'w-min py-1 px-3 rounded-full whitespace-nowrap text-sm font-semibold ' +
        variations[variation].classNames.join(' ') +
        ' ' +
        (className || '')
      }
      {...props}
    >
      {children}
    </Component>
  );
};
