import { QUESTION_INPUT_VARIATIONS } from 'dataLayer/Question';
import Button from 'elements/Button';
import { Text } from 'elements/Text';
import { FormSubmission } from 'generated/graphql';
import { useActiveUser } from 'hooks/useActiveUser';
import React, { useState } from 'react';
import { ArrowRight } from 'react-feather';

import { useLayout } from '../../hooks/useLayout';
import { DynamicApiForm } from '../DynamicApiForm/DynamicApiForm';
import { GettingStartedCompleted } from './GettingStartedCompleted';

const LawyerGettingStarted = () => {
  useLayout({ layout: 'Base' });

  const user = useActiveUser();

  const [state, setState] = useState<'intro' | 'form' | 'completed'>('intro');

  const afterFormSubmit = (e: Partial<FormSubmission>) => {
    if (e.status === 'DRAFT') return;
    setState('completed');
  };

  return (
    <>
      {(state === 'intro' || state === 'form') && (
        <div className="w-full flex justify-center mx-4">
          <div className="max-w-xl w-full mx-auto mt-10">
            {state === 'intro' && (
              <div className="bg-white border border-visto-gray p-6 md:p-12 rounded-lg">
                <img
                  src="/browser-ui.png"
                  alt="Browser UI"
                  className="mx-auto w-28 md:w-36 mb-6"
                />
                <Text.Heading
                  variant="none"
                  className="fs20 md:fs24 mb-4 text-center"
                >
                  Welcome to Visto!
                </Text.Heading>
                <Text.Paragraph className="fs16 mb-10 text-center">
                  Please carefully answer a few questions so we can get your
                  account set up.
                </Text.Paragraph>
                <Button onClick={() => setState('form')} className="mx-auto">
                  Get Started <ArrowRight className="ml-2" strokeWidth={3} />
                </Button>
              </div>
            )}
            {state === 'form' && (
              <DynamicApiForm
                formName={'LAWYER_GETTING_STARTED'}
                variation={QUESTION_INPUT_VARIATIONS.GETTING_STARTED}
                afterSubmit={afterFormSubmit}
                associations={{ userId: user?.id }}
              />
            )}
          </div>
        </div>
      )}
      {state === 'completed' && <GettingStartedCompleted />}
    </>
  );
};

export default LawyerGettingStarted;
