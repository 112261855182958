/* eslint-disable sonarjs/no-duplicate-string */
import { AccountTypeName } from 'dataLayer/AccountType';
import { StatusType, StatusValue } from 'generated/graphql';

export const STATUS_PRETTY_NAME = {
  [StatusValue.Registered]: 'Application Created',
  [StatusValue.Preparation]: 'Application Created',
  [StatusValue.Purchased]: 'Purchased',
  [StatusValue.OnboardingCompleted]: 'On-boarding Completed',
  [StatusValue.CompletedDefaultTasks]: 'Completed Tasks',
  [StatusValue.DocumentsGenerated]: 'Documents Generated',
  [StatusValue.InitialReview]: 'Review Documents',
  [StatusValue.SentSigning]: 'Sent for Signing',
  [StatusValue.SkipSigning]: 'Skipped E-signing',
  [StatusValue.Submitted]: 'Submitted',
  [StatusValue.BiometricsSubmitted]: 'Biometrics Submitted',
  [StatusValue.Approved]: 'Approved',
  [StatusValue.Rejected]: 'Rejected',
  [StatusValue.ProfileCreated]: 'Profile Created',
  [StatusValue.ItaReceived]: 'ITA Received',
  [StatusValue.CompletedItaTasks]: 'Completed ITA Tasks',
};

export const STATUS_TYPE_PRETTY_NAME = {
  [StatusType.StudyPermit]: 'Study Permit Outside Canada',
  [StatusType.StudyPermitCanada]: 'Study Permit Inside Canada',
  [StatusType.VisitorVisa]: 'Visitor Visa Outside Canada',
  [StatusType.VisitorVisaCanada]: 'Visitor Visa Inside Canada',
  [StatusType.Lmia]: 'LMIA',
  [StatusType.WorkPermit]: 'Work Permit Outside Canada',
  [StatusType.WorkPermitCanada]: 'Work Permit Inside Canada',
};

export const STATUSES_NOT_GENERATED = [
  StatusValue.Registered,
  StatusValue.OnboardingCompleted,
  StatusValue.CompletedDefaultTasks,
];

export const STATUSES_NOT_SIGNED = [
  StatusValue.Registered,
  StatusValue.OnboardingCompleted,
  StatusValue.CompletedDefaultTasks,
  StatusValue.DocumentsGenerated,
  StatusValue.InitialReview,
];

export interface ApplicationProgressStatusLabels {
  label: string;
  values: StatusValue[];
  classes?: string[];
  defaultHidden?: boolean;
  tip?: string | { [key: string]: string };
}

export const STATUSES_IS_IN_REVIEW = [
  StatusValue.OnboardingCompleted,
  StatusValue.Preparation,
  StatusValue.InitialReview,
];

export const STATUSES_IS_GENERATED = [
  StatusValue.DocumentsGenerated,
  StatusValue.SentSigning,
  StatusValue.Submitted,
  StatusValue.Approved,
  StatusValue.Rejected,
];

/****************
 * GTS STATUSES *
 ****************/
export const STATUSES_GTS: ApplicationProgressStatusLabels[] = [
  {
    label: 'LMIA in preparation',
    values: [StatusValue.Preparation],
  },
  {
    label: 'LMIA under review',
    values: [StatusValue.InitialReview],
  },
  {
    label: 'LMIA in final review',
    values: [StatusValue.DocumentsGenerated, StatusValue.SentSigning],
  },
  {
    label: 'LMIA submitted to government',
    values: [StatusValue.Submitted],
  },
  {
    label: 'LMIA approved!',
    values: [StatusValue.Approved],
  },
];

/****************************
 * GTS WORK PERMIT STATUSES *
 ****************************/
export const STATUSES_WORK_PERMIT: ApplicationProgressStatusLabels[] = [
  {
    label: 'Sign up completed',
    values: [StatusValue.OnboardingCompleted],
  },
  {
    label: 'Work Permit in preparation',
    values: [StatusValue.Preparation],
  },
  {
    label: 'Work Permit under review',
    values: [StatusValue.InitialReview],
  },
  {
    label: 'Work Permit in final review',
    values: [StatusValue.DocumentsGenerated, StatusValue.SentSigning],
  },
  {
    label: 'Work Permit submitted to government',
    values: [StatusValue.Submitted],
  },
  {
    label: 'Work Permit approved!',
    values: [StatusValue.Approved],
  },
];

/**********************
 * APPLICANT STATUSES *
 **********************/
export const APPLICATION_PROGRESS_TRACKER_STATUSES: ApplicationProgressStatusLabels[] =
  [
    {
      label: 'Application created',
      values: [StatusValue.Registered],
      tip: {
        [AccountTypeName.Lawyer]:
          "Now it's time to start the application - you or your client can get the process started by completing their onboarding. Click the Access button above to invite your client to the application, or if you'll be doing it for them, click on Start Application!",
        [AccountTypeName.Applicant]:
          'Congratulations on taking your first step to applying for your application! This is the one-of-a-kind Visto progress tracker that will guide you through the Canadian Immigration process. Lets get started with your onboarding!',
      },
    },
    {
      label: 'On-boarding form completed',
      values: [StatusValue.OnboardingCompleted],
      tip: {
        [AccountTypeName.Lawyer]:
          'On-boarding is completed and the custom document checklist has been generated. You or your client can now be working on the tasks and you will be notified if they complete and submit all of them.',
        [AccountTypeName.Applicant]:
          'Now that your Visto profile is all set up, you can get started on your tasks below. These will help you prepare everything your immigration professional will need so they can review and submit your application!',
      },
    },
    {
      label: 'Completed all required default tasks',
      values: [StatusValue.CompletedDefaultTasks],
      tip: {
        [AccountTypeName.Lawyer]:
          'All tasks have been completed! Please review all of the tasks, intake forms and PDFs thoroughly, and you can edit, comment or request a change if needed. Once finished, click "Generate Government Forms" to auto-fill the forms.',
        [AccountTypeName.Applicant]:
          'Thanks for completing your tasks! We notified your immigration professional, and they will start reviewing your documents. If they need anything else, you will receive notifications by email for any additional tasks or next steps.',
      },
    },
    {
      label: 'Government forms generated',
      values: [StatusValue.DocumentsGenerated],
      tip: {
        [AccountTypeName.Lawyer]:
          "We've generated your government forms! Please scroll down and take a look over all of the government forms that we have generated. Please review them, then you can click above to move the application to the next step.",
        [AccountTypeName.Applicant]:
          "We've generated your government forms! Your immigration professional has reviewed your initial documents and generated your government forms. Once they're finalized, they will send them to you to e-sign - and don't worry, you'll get a notification from us when they do.",
      },
    },
    {
      label: 'Government forms review completed',
      values: [StatusValue.InitialReview],
      tip: {
        [AccountTypeName.Lawyer]:
          "Great work! We've let your client know their documents have been generated and reviewed. When you're ready to send the forms for e-sign, click on “Send for e-signing” and they will automatically be sent to the student (and family, if applicable).",
        [AccountTypeName.Applicant]:
          "Your immigration professional has reviewed your government forms and they're preparing them to send to you for e-signature. You'll get a notification from us as soon as they do!",
      },
    },
    {
      label: 'Government forms sent for e-Signature via email',
      values: [StatusValue.SentSigning],
      tip: {
        [AccountTypeName.Lawyer]:
          "We've sent the forms for e-signature! You will get a notification as soon as they are all signed by your client. Once signed, you can submit the application to government - and make sure to click Submit Application to let the client know it has been submitted.",
        [AccountTypeName.Applicant]:
          "You are almost done! Make sure to check out your tasks below so you can review and e-sign your government forms. Once that's completed, your immigration professional can apply on your behalf.",
      },
    },
    {
      label: 'Application submitted to the government',
      values: [StatusValue.Submitted, StatusValue.SkipBiometrics],
      tip: {
        [AccountTypeName.Lawyer]:
          "Thank you for submitting the application! If biometrics are required, you can upload it at the bottom of this page (or, you can skip the biometrics step). It will automatically send it to your client with instructions on how to complete it, and you'll receive a notification once they do.",
        [AccountTypeName.Applicant]:
          'Congratulations, your immigration professional has submitted your application! Keep an eye out for updates or notifications so they can move your application forward as quickly as possible.',
      },
    },
  ];

export const APPLICATION_PROGRESS_TRACKER_STATUSES_CONDITIONAL: {
  [key: string]: ApplicationProgressStatusLabels;
} = {
  SKIP_SIGNING: {
    label: 'Final application review',
    values: [StatusValue.SkipSigning],
    tip: {
      [AccountTypeName.Applicant]:
        'Your immigration professional is completing one last review before submitting your application to the government!',
      [AccountTypeName.Lawyer]:
        'You have skipped sending the government forms for e-signing. Make sure to get the forms signed before submitting the application to the government.',
    },
  },
  BIOMETRICS: {
    label: 'Biometrics submitted',
    values: [StatusValue.BiometricsSubmitted],
    tip: {
      [AccountTypeName.Lawyer]:
        'Please keep an eye out for a decision from the government, and once you receive it, click on Application Approved (we hope!) or Rejected to let your client know asap. You can also upload any Passport Request letters at the bottom of this page.',
      [AccountTypeName.Applicant]:
        'Great job! Now that your biometrics are submitted, your application will be processed and your immigration professional will let you know as soon as they receive a decision. You will get a notification as soon as they do!',
    },
  },
  APPROVED: {
    label: 'Application approved!',
    values: [StatusValue.Approved],
    tip: {
      [AccountTypeName.Lawyer]:
        "Great job! We hope the process went smoothly. Don't forget that if you haven't already, you can always upload any Passport Request letters at the bottom of this page. Thank you for using Visto!",
      [AccountTypeName.Applicant]:
        "Great news, your application has been approved! Your immigration professional will let you know if there's anything else needed before getting ready to travel to Canada. We're so excited for you to start your new journey in Canada, and hope you had a great experience using Visto!",
    },
  },
};

export const APPLICATION_PROGRESS_TRACKER_STATUSES_EXPRESS_ENTRY: ApplicationProgressStatusLabels[] =
  [
    {
      label: 'Express Entry profile created',
      values: [StatusValue.ProfileCreated],
      tip: {
        [AccountTypeName.Applicant]:
          'Your immigration professional has created your Express Entry profile',
        [AccountTypeName.Lawyer]:
          "Nice work! You have created your client's Express Entry profile!",
      },
    },
    {
      label: 'Invitation to Apply (ITA) received',
      values: [StatusValue.ItaReceived],
      tip: {
        [AccountTypeName.Lawyer]:
          'Once you have received the ITA, make sure your client completes all of their remaining tasks.',
        [AccountTypeName.Applicant]:
          'Your immigration professional has received the invitation to apply (ITA)! Make sure to finishing all of your pending tasks to continue with your application.',
      },
    },
    {
      label: 'Completed all required ITA tasks',
      values: [StatusValue.CompletedItaTasks],
      tip: {
        [AccountTypeName.Lawyer]:
          "Great job! Now its time to submit your client's application to the government. Don't forget to come back to Visto to make this application as submitted.",
        [AccountTypeName.Applicant]:
          'Nice work! You have completed all of your required tasks. Your immigration professional will now submit your application to the government.',
      },
    },
  ];

/*****************
 * LMIA STATUSES *
 *****************/
export const LMIA_PROGRESS_TRACKER_STATUSES: ApplicationProgressStatusLabels[] =
  [
    {
      label: 'Application created',
      values: [StatusValue.Preparation],
      tip: {
        [AccountTypeName.Lawyer]:
          "Now it's time to start the application - you or your client can get the process started by completing their onboarding. Click the Access button above to invite your client to the application, or if you'll be doing it for them, click on Start Application!",
        [AccountTypeName.Company]:
          'Your LMIA application is underway! Make sure to complete all of your tasks, and then click "Submit Tasks" to notify your immigration professional!',
      },
    },
    {
      label: 'Completed all required initial tasks',
      values: [StatusValue.CompletedDefaultTasks],
      tip: {
        [AccountTypeName.Lawyer]:
          'All tasks have been completed! Please review all of the tasks. Do not forget that you can edit, comment or request a change if needed.',
        [AccountTypeName.Company]:
          'Thanks for completing your tasks! We notified your immigration professional, and they will start reviewing your application. If they need anything else, you will receive notifications by email for any additional tasks or next steps.',
      },
    },
    {
      label: 'Information review completed',
      values: [StatusValue.InitialReview],
      tip: {
        [AccountTypeName.Lawyer]:
          "Great work! We've let your client know that you have finished reviewing their application, and are getting ready to submit it.",
        [AccountTypeName.Company]:
          "Your immigration professional has reviewed your application and is getting ready to submit your application. You'll get a notification from us as soon as they do!",
      },
    },
    {
      label: 'Application submitted to the government',
      values: [StatusValue.Submitted],
      tip: {
        [AccountTypeName.Lawyer]:
          'Thank you for submitting the application! When you receive a decision from the government, make sure to update the application to approved!',
        [AccountTypeName.Company]:
          'Congratulations, your immigration professional has submitted your application! Keep an eye out for updates or notifications so they can move your application forward as quickly as possible.',
      },
    },
    {
      label: 'Application approved!',
      values: [StatusValue.Approved],
      tip: {
        [AccountTypeName.Lawyer]:
          'Great job! We hope the process went smoothly. Thank you for using Visto!',
        [AccountTypeName.Company]:
          "Great news, your application has been approved! Your immigration professional will let you know if there's anything else needed and we hope you had a great experience using Visto!",
      },
    },
  ];
