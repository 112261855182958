import { FC } from 'react';

interface NavItemProps {
  size?: number;
  show?: boolean;
}

export const NavItem: FC<NavItemProps & JSX.IntrinsicElements['div']> = ({
  size = 5,
  show = true,
  className,
}) => {
  if (!show) return null;

  return (
    <>
      {Array(size)
        .fill(0)
        .map((_, i: number) => (
          <div
            key={`loading-${i}`}
            className={`animate-pulse w-full ${className}`}
          >
            <div className="border border-gray-300 rounded-md p-2 w-full mb-3">
              <div className="flex space-x-2">
                <div className="w-6 h-6 rounded-full bg-gray-300"></div>
                <div className="flex-1 space-y-2 py-1">
                  <div className="h-1 bg-gray-300 rounded w-full"></div>
                  <div className="h-1 bg-gray-300 rounded w-full"></div>
                </div>
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

NavItem.displayName = 'NavItem';

export default NavItem;
