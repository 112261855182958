/* eslint-disable sonarjs/no-small-switch */
/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import '@draft-js-plugins/static-toolbar/lib/plugin.css';
import '@draft-js-plugins/emoji/lib/plugin.css';
import '@draft-js-plugins/mention/lib/plugin.css';

import {
  BoldButton,
  CodeButton,
  ItalicButton,
  UnderlineButton,
  UnorderedListButton,
} from '@draft-js-plugins/buttons';
import Editor, { EditorPlugin } from '@draft-js-plugins/editor';
import createEmojiPlugin from '@draft-js-plugins/emoji';
import createMentionPlugin, {
  defaultSuggestionsFilter,
  MentionData,
} from '@draft-js-plugins/mention';
import createToolbarPlugin from '@draft-js-plugins/static-toolbar';
import { FormManager } from '@visto-tech/forms';
import {
  CompositeDecorator,
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import { useActiveUser } from 'hooks/useActiveUser';
import { isEmpty } from 'lodash';
import { autorun } from 'mobx';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

interface WYSIWYGInputProps {
  formManager: FormManager<any>;
  name: string;
  reset?: boolean;
  enableEmoji?: boolean;
  editorClassname?: string;
  placeholder?: string;
  mentions?: {
    enabled?: boolean;
    suggestionsList?: MentionData[];
    setMentionedUserIds?: (args: number[]) => void;
  };
}

const CustomMentionComponent = ({ mention }: any) => (
  <span className="custom-mention-style">@{mention.name}</span>
);

// Some solutions for images:
// - https://github.com/draft-js-plugins/draft-js-plugins/issues/1602
// - https://www.altcademy.com/questions/how-to-solve-draft-dot-js-convertfromhtml-converting-image-tags-into-unstyled-contentblock-type-instead-of-atomic-content-block-type

export const WYSIWYGInput = ({
  formManager,
  name,
  reset = false,
  enableEmoji = false,
  className,
  editorClassname,
  placeholder,
  mentions = {
    enabled: false,
  },
}: WYSIWYGInputProps & JSX.IntrinsicElements['div']) => {
  const initBlocksFromHtml = convertFromHTML(formManager.formData[name]);

  const contentState = ContentState.createFromBlockArray(
    initBlocksFromHtml.contentBlocks,
    initBlocksFromHtml.entityMap
  );

  const initEditorState = formManager.formData[name]
    ? EditorState.createWithContent(contentState)
    : EditorState.createEmpty();

  const [editorState, setEditorState] = useState(initEditorState);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [suggestions, setSuggestions] = useState<MentionData[] | undefined>(
    mentions.suggestionsList
  );

  const editor = useRef<Editor>(null);

  const activeUser = useActiveUser();
  const [hasFocus, setHasFocus] = useState<boolean>(false);

  const isMentionsEnabled =
    mentions.enabled &&
    mentions.setMentionedUserIds &&
    !isEmpty(mentions.suggestionsList) &&
    activeUser?.isType('LAWYER');

  const {
    EmojiSuggestions,
    EmojiSelect,
    Toolbar,
    plugins,
    MentionSuggestions,
  } = useMemo(() => {
    const staticToolbarPlugin = createToolbarPlugin({});

    const { Toolbar } = staticToolbarPlugin;

    const emojiPlugin = createEmojiPlugin({
      disableInlineEmojis: enableEmoji,
    });

    const mentionPlugin = createMentionPlugin({
      mentionComponent: CustomMentionComponent,
      supportWhitespace: true,
    });

    const { MentionSuggestions } = mentionPlugin;

    const { EmojiSuggestions, EmojiSelect } = emojiPlugin;

    const plugins = [emojiPlugin, staticToolbarPlugin, mentionPlugin];

    return {
      plugins,
      Toolbar,
      EmojiSuggestions,
      EmojiSelect,
      MentionSuggestions,
    };
  }, []);

  useEffect(() => {
    setEditorState(EditorState.createEmpty());
  }, [reset]);

  useEffect(() => {
    autorun(() => {
      const content = formManager.formData[name];

      const actualState = stateFromHTML(content as string);

      const _decorators = plugins.reduce(
        (acc: any, { decorators = [] }: any) => [...acc, ...decorators],
        []
      );

      const decorator = new CompositeDecorator(_decorators);

      setEditorState(EditorState.createWithContent(actualState, decorator));
    });
  }, [formManager.formData.id]);

  const onOpenChange = useCallback((_open: boolean) => {
    setOpen(_open);
  }, []);

  const onSearchChange = useCallback(
    ({ value }: { value: string }) => {
      suggestions &&
        setSuggestions(defaultSuggestionsFilter(value, suggestions));
    },
    [suggestions]
  );

  const onExtractMentions = (contentState: ContentState) => {
    const raw = convertToRaw(contentState);

    const mentionedUserIds = [];

    for (const key in raw.entityMap) {
      const ent = raw.entityMap[key];

      if (ent.type === 'mention') {
        mentionedUserIds.push(ent.data.mention.id);
      }
    }

    mentions?.setMentionedUserIds &&
      mentions.setMentionedUserIds(mentionedUserIds);
  };

  const onChange = (editorState: any) => {
    const options = {
      entityStyleFn: (entity: any) => {
        const entityType = entity.get('type').toLowerCase();
        if (entityType === 'mention') {
          const data = entity.getData();

          return {
            element: 'span',
            attributes: {
              className: 'wysiwyg-mention',
            },
            text: data.mention.name,
          };
        }
      },
    };

    const contentState = editorState.getCurrentContent();
    const content = stateToHTML(contentState, options);

    formManager.formData[name] = content;

    isMentionsEnabled && onExtractMentions(contentState);

    if (!isLoading) {
      setEditorState(editorState);
    }
  };

  useEffect(() => {
    setIsLoading(false);
  });

  return (
    <div>
      <div
        className={`toolbar block-toolbar-container rounded-md notranslate ${
          className ?? ''
        }`}
      >
        <Toolbar>
          {(externalProps) => (
            <>
              <BoldButton {...externalProps} />
              <ItalicButton {...externalProps} />
              <UnderlineButton {...externalProps} />
              <CodeButton {...externalProps} />
              <UnorderedListButton {...externalProps} />
              {enableEmoji && <EmojiSelect />}
            </>
          )}
        </Toolbar>
        <div className={editorClassname ?? ''}>
          <div
            className={`p-3 h-full ${
              hasFocus
                ? 'border-[2px] border-blue-400 rounded-bl-md rounded-br-md'
                : ''
            }`}
            onClick={() => {
              editor.current!.focus();
            }}
          >
            <Editor
              editorKey={'editor'}
              ref={editor}
              editorState={editorState}
              onFocus={() => setHasFocus(true)}
              onBlur={() => setHasFocus(false)}
              onChange={onChange}
              plugins={plugins as EditorPlugin[]}
              placeholder={placeholder}
            />
            {enableEmoji && <EmojiSuggestions />}
            {isMentionsEnabled && !isEmpty(suggestions) && suggestions && (
              <MentionSuggestions
                open={open}
                onOpenChange={onOpenChange}
                suggestions={suggestions}
                onSearchChange={onSearchChange}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
