import Button from './components/Button';
import Circle from './components/Circle';
import { Default } from './components/Default';
import Form from './components/Form';
import Heading from './components/Heading';
import Input from './components/Input';
import Mini from './components/Mini';
import NavItem from './components/NavItem';
import ProfileListItem from './components/ProfileListItem';
import ProgressTracker from './components/ProgressTracker';
import Thin from './components/Thin';

export const Loading = {
  Default,
  Heading,
  Mini,
  Input,
  NavItem,
  Button,
  ProfileListItem,
  Circle,
  ProgressTracker,
  Form,
  Thin,
};

export default Loading;
