import {
  CreateStorageFileMutationVariables,
  DeleteStorageFileMutationVariables,
  GetStorageFileUrlQueryVariables,
} from 'generated/graphql';
import logger from 'js-logger';

import backendClient from '../backend';

export class StorageFile {
  public static async create(args: CreateStorageFileMutationVariables) {
    try {
      return (await backendClient.createStorageFile(args)).data
        ?.createStorageFile;
    } catch (err) {
      logger.error(err);
    }
  }

  public static async getUrl(args: GetStorageFileUrlQueryVariables) {
    try {
      return (await backendClient.getStorageFileUrl(args)).data
        ?.getStorageFileUrl;
    } catch (err) {
      logger.error(err);
    }
  }

  public static async delete(args: DeleteStorageFileMutationVariables) {
    try {
      return (await backendClient.deleteStorageFile(args)).data
        ?.deleteStorageFile;
    } catch (err) {
      logger.error(err);
    }
  }
}
