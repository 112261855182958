import Account from 'dataLayer/Account';
import { AccountSubscriptionData } from 'generated/graphql';
import { useEffect, useState } from 'react';

export const useAccountSubscription = (dependencies: any[] = []) => {
  const [isFetchingSub, setIsFetchingSub] = useState<boolean>(true);
  const [subscription, setSubscription] =
    useState<AccountSubscriptionData | null>(null);

  useEffect(() => {
    let isMounted = true;

    const fetchSubscription = async () => {
      setIsFetchingSub(true);

      try {
        const sub = await Account.subscription();

        if (isMounted) {
          setSubscription(sub as AccountSubscriptionData);
        }
      } catch (error) {
        setSubscription(null);
      }
      setIsFetchingSub(false);
    };

    fetchSubscription();

    return () => {
      isMounted = false;
    };
  }, dependencies);

  return { subscription, isFetchingSub };
};
