import Text from 'elements/Text';
import { round } from 'lodash';
import { FC, useState } from 'react';

const ProgressBar: FC<
  { value: number; show?: boolean } & JSX.IntrinsicElements['div']
> = ({ value, className, show = true }) => {
  const [style, setStyle] = useState({});

  setTimeout(() => {
    const newStyle = {
      opacity: 1,
      width: value === 0 ? '60px' : `${String(value)}%`,
      minWidth: '50px',
    };

    setStyle(newStyle);
  }, 200);

  if (!show) return null;

  return (
    <div
      className={`progressBar w-full border border-gray-300 notranslate ${
        className || ''
      }`}
    >
      <div
        className={`progressBar__done ${
          value === 100
            ? '!bg-green-500'
            : 'bg-gradient-to-r from-[#2897f2] to-blue-600'
        }`}
        style={style}
      >
        <Text.Paragraph className="font-bold fs12">
          {round(value, 2)}%
        </Text.Paragraph>
      </div>
    </div>
  );
};

export default ProgressBar;
