import { useEffect, useState } from 'react';

import {
  createClassManager,
  UniversalClassesInputs,
} from '../dataLayer/CSSClasses';

export const useClassManager = (...initialClasses: UniversalClassesInputs) => {
  const [classManager, setClassManager] = useState(() =>
    createClassManager(...initialClasses)
  );

  useEffect(() => {
    if (
      initialClasses
        .flat()
        .every((className) => classManager.initialClasses.includes(className))
    )
      return;
    setClassManager(createClassManager(...initialClasses));
  }, [classManager.initialClasses, initialClasses]);

  return classManager;
};

export default useClassManager;
