import backendClient from 'backend';
import logger from 'js-logger';
import { get, isEmpty } from 'lodash';
import { makeAutoObservable } from 'mobx';

import {
  Account as RawAccount,
  AccountAddUsersData,
  AccountPermissionGroup,
  AccountsFilters,
  AccountSubscriptionData,
  AccountTask,
  Application,
  ApplicationDocument,
  CompanyTalent,
  Maybe,
  PublicSignUpMutationVariables,
  Role,
  Scalars,
  User,
} from '../generated/graphql';
import { parseJSON } from '../utils/helpers';
import AccountSubType from './AccountSubType';
import AccountType from './AccountType';
import { AccountSubTypeNames, AccountTypeNames } from './User';

export class Account implements RawAccount {
  id: Maybe<number> | undefined;
  active?: boolean | null | undefined;
  name: Maybe<string> | undefined;
  accountType: Maybe<AccountType>;
  accountSubType: Maybe<AccountSubType>;
  viewed?: string | null | undefined;
  users?: (User | null)[] | null | undefined;
  accountTask?: (AccountTask | null)[] | null | undefined;
  applicationDocuments?: (ApplicationDocument | null)[] | null | undefined;
  roles?: (Role | null)[] | null | undefined;
  companyCompanyTalents?: (CompanyTalent | null)[] | null | undefined;
  companyApplications?: (Application | null)[] | null | undefined;
  primaryUser?: (User | null) | null | undefined;
  createdAt?: string;
  subscription?: Maybe<AccountSubscriptionData>;
  checkAddUser?: Maybe<AccountAddUsersData>;
  accountPermissionGroups?: Maybe<AccountPermissionGroup>;
  meta?: Maybe<Scalars['JSON']>;
  settings?: Maybe<Scalars['JSON']>;
  leadsEmbedId?: string | null | undefined;
  storage?: Maybe<Scalars['JSON']>;

  constructor(account: RawAccount) {
    if (!account)
      throw new Error(
        'You cannot create a Account instance without passing in account data.'
      );

    this.id = account.id;
    this.active = account.active;
    this.name = account.name;
    this.accountType = account.accountType
      ? new AccountType(account.accountType)
      : null;
    this.accountSubType = account.accountSubType
      ? new AccountSubType(account.accountSubType)
      : null;
    this.viewed = account.viewed;
    this.users = account.users;
    this.accountTask = account.accountTask;
    this.applicationDocuments = account.applicationDocuments;
    this.roles = account.roles;
    this.companyCompanyTalents = account.companyCompanyTalents;
    this.companyApplications = account.companyApplications;
    this.primaryUser = account.primaryUser;
    this.createdAt = account.createdAt;
    this.subscription = account.subscription;
    this.checkAddUser = account.checkAddUser;
    this.accountPermissionGroups = account.accountPermissionGroups;
    this.meta = account.meta;
    this.settings = account.settings;
    this.leadsEmbedId = account.leadsEmbedId;
    this.storage = account.storage;

    makeAutoObservable(this);
  }

  get type() {
    return this.accountType?.name;
  }

  get subType() {
    return this.accountSubType?.name;
  }

  isType(name: AccountTypeNames) {
    return this.type === name;
  }

  isSubType(subName: AccountSubTypeNames) {
    return this.subType === subName;
  }

  static async getAll({
    cursor,
    searchTerm,
    filters,
  }: {
    cursor?: number;
    searchTerm?: string;
    filters?: AccountsFilters;
  } = {}) {
    try {
      const accounts = (
        await backendClient.Accounts({ cursor, searchTerm, filters })
      ).data?.Accounts;

      if (!accounts) throw new Error('Failed to fetch active user');

      return {
        data: accounts?.data?.map((account) => new Account(account as Account)),
        ...accounts,
      };
    } catch (err) {
      logger.error(err);
    }
  }

  static async get(args?: { accountId?: number; userId?: number }) {
    const accountId = args?.accountId;
    const userId = args?.userId;

    try {
      const account = (await backendClient.Account({ accountId, userId })).data
        ?.Account;

      if (!account) {
        throw new Error('Failed to fetch account');
      }

      return new Account(account as Account);
    } catch (err) {
      logger.error(err);
    }
  }

  static async getIncludes({
    accountId,
    userId,
    includes,
  }: {
    accountId?: number;
    userId?: number;
    includes?: {
      primaryUser?: boolean;
      subscription?: boolean;
      checkCanAddUser?: boolean;
    };
  }) {
    try {
      const account = (
        await backendClient.AccountIncludes({
          accountId,
          userId,
          includesPrimaryUser: includes?.primaryUser ?? false,
          includesSubscription: includes?.subscription ?? false,
          includesCheckAddUser: includes?.checkCanAddUser ?? false,
        })
      ).data?.Account;

      if (!account) {
        throw new Error('Failed to fetch account');
      }

      return new Account(account as Account);
    } catch (err) {
      logger.error(err);
    }
  }

  static async subscription({ token }: Partial<{ token?: string }> = {}) {
    let requestHeaders = undefined;

    if (token) {
      requestHeaders = {
        Authorization: `Bearer ${token}`,
      };
    }

    try {
      return (
        await backendClient.AccountSubscription(undefined, requestHeaders)
      ).data?.Account?.subscription;
    } catch (e) {
      return null;
    }
  }

  public hasViewed(property: string) {
    if (!this.viewed) return false;

    const viewedItems = parseJSON(this.viewed);

    if (isEmpty(viewedItems)) return false;

    return viewedItems[property] === true;
  }

  static async updateViewed(
    data: {
      viewedProperty: string;
      viewedValue: boolean;
    },
    refreshUser = false
  ) {
    try {
      return await backendClient.updateAccount({
        data: JSON.stringify(data),
        refreshUser,
      });
    } catch (e) {
      logger.error(e);
    }
  }

  static async update(
    data: Record<string, string | number | object>,
    accountId?: number
  ) {
    const jsonData = JSON.stringify(data);

    try {
      return await backendClient.updateAccount({
        data: jsonData,
        accountId,
      });
    } catch (e) {
      logger.error(e);
    }
  }

  public static async publicSignUp(args: PublicSignUpMutationVariables) {
    try {
      return (await backendClient.publicSignUp(args)).data?.publicSignUp;
    } catch (err) {
      logger.error(err);
    }
  }

  public getSettings(settingKey: string) {
    if (!this.settings) {
      return null;
    }

    return get(this.settings, settingKey, null);
  }

  public isSettingTrue(key: string) {
    return this.getSettings(key) === true;
  }
}

export default Account;
